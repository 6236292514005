<template>
  <div v-if="!isMobile" class="blog-pp d-flex flex-column b-grey-black p-4 bdrd-12px mt-3">
    <div class="bb-1px-grey d-flex justify-content-between pb-2">
      <p class="mb-0">Transaction ID: <span class="bold">{{ transactionId }}</span></p>
      <p class="text-dark extrabold mb-2">IDR {{ price }}</p>
    </div>
    <div class="d-flex pt-3">
      <div class="img-src">
        <picture>
          <source :srcset="image">
          <img :src="fallbackImage" :alt="altImage" />
        </picture>
      </div>
      <div class="caption-blog w-75">
        <div class="wrap-text d-flex justify-content-between">
          <h5 class="extrabold ls-0 mb-0 fs-125em">{{ eventName }} - {{ itineraryName }} - {{ packageName }}</h5>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-center mr-2 my-2">
            <i class="fa fa-calendar gamma-color fs-1em"></i
            ><span class="mx-2 gamma-color fs-1em semibold">{{ raceDate }}</span>
          </div>
          <div class="d-flex align-items-center mr-2 my-2">
            <i class="icon-map gamma-color fs-1em"></i
            ><span class="mx-2 gamma-color fs-1em semibold">{{ distance }}</span>
          </div>
        </div>
        <div class="mt-2 pt-3 d-flex bt-1px-grey justify-content-between align-items-center">
          <div class="d-flex flex-column align-items-start pb-2">
            <template v-if="transactionType == 'B'">
              <span v-if="isAllow == 'N'">Waiting for confirmation</span>
              <span v-else-if="baseStatus == 'W'" class="regular">Waiting for {{ numOfPayment }} payment</span>
              <span v-else-if="baseStatus == 'V'" class="regular">{{ numOfPayment }} payment in verification</span>
              <span v-else-if="baseStatus == 'P'" class="regular">Paid</span>
              <span v-else-if="baseStatus == 'C'" class="regular">Canceled</span>
              <span v-else-if="baseStatus == 'E'" class="regular">Expired</span>
              <div class="stepper-wrapper mt-1">
                <div 
                  v-for="(v, k) in historical"
                  :key="k"
                  class="stepper-item"
                  :class="isAllow == 'N' ? '' : v.payment_status"
                >
                  <div class="step-counter bold">{{ v.num_of_payment }}</div>
                </div>
              </div>
            </template>
            <template v-if="transactionType == 'F'">
              <span v-if="isAllow == 'N'">Waiting for confirmation</span>
              <span v-else-if="baseStatus == 'W'" class="regular">Waiting for payment</span>
              <span v-else-if="baseStatus == 'V'" class="regular">Payment in verification</span>
              <span v-else-if="baseStatus == 'P'" class="regular">Paid</span>
              <span v-else-if="baseStatus == 'C'" class="regular">Canceled</span>
              <span v-else-if="baseStatus == 'E'" class="regular">Expired</span>
              <div class="stepper-wrapper mt-1">
                <div 
                  class="stepper-item"
                  :class="isAllow == 'N' ? '' : (historical[0] || {}).payment_status"
                >
                  <div class="step-counter bold">{{ historical[0].num_of_payment }}</div>
                </div>
              </div>
            </template>
          </div>
          <router-link
            :to="{
              name: 'TransactionDetails',
              params: { pageSlug: slug },
            }"
            class="btn btn-primary bold d-flex align-items-center"
            ><span>See Details</span> <i class="icon-arrow-right ml-1"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="relative card-private-page event-card event transaction mt-4 p-2 bdrd-12px"
    :style="`background: url(${isWebp ? image : fallbackImage}); background-size: cover; background-position: center;`"
  >
    <div>
      <div class="top-left">
        <div>
          <p class="bold text-dark mb-0 fs-085em">Transaction ID: {{ transactionId }}</p>
        </div>
      </div>
    </div>
    <div class="description-box">
      <div class="wrap-text d-flex justify-content-between align-items-center pb-2">
        <div class="wrap-title">
          <p class="text-dark ls-0 bold mb-0">{{ eventName }} - {{ itineraryName }} - {{ packageName }}</p>
          <span class="regular grey-color">{{ program }}, {{ country }}</span>
        </div>
        <div class="text-center lh-1">
          <span class="bold fs-085">IDR<br /><b class="fs-1em">{{ price }}</b></span>
        </div>
      </div>
      <div class="wrap-details d-flex bb-1px-grey pb-2">
        <div class="d-flex align-items-center mr-2 mb-2">
          <i class="fa fa-calendar gamma-color fs-085em"></i
          ><span class="mx-2 gamma-color fs-085em semibold">{{ raceDate }}</span>
        </div>
        <div class="d-flex align-items-center mr-2 mb-2">
          <i class="icon-map gamma-color fs-085em"></i
          ><span class="mx-2 gamma-color fs-085em semibold">{{ distance }}</span>
        </div>
      </div>
      <div class="bb-1px-grey pt-2">
        <p class="mb-0">Runner Name: {{ mainRunnerName }}{{ secondRunnerName !== null ? `, ${secondRunnerName}` : '' }}</p>
        <p class="mb-0">Order date: {{ orderDate }}</p>
        <div class="d-flex justify-content-between align-items-center pb-2">
          <template v-if="transactionType == 'B'">
            <span v-if="isAllow == 'N'">Waiting for confirmation</span>
            <span v-else-if="baseStatus == 'W'" class="regular">Waiting for {{ numOfPayment }} payment</span>
            <span v-else-if="baseStatus == 'V'" class="regular">{{ numOfPayment }} payment in verification</span>
            <span v-else-if="baseStatus == 'P'" class="regular">Paid</span>
            <span v-else-if="baseStatus == 'C'" class="regular">Canceled</span>
            <span v-else-if="baseStatus == 'E'" class="regular">Expired</span>
            <div class="stepper-wrapper mt-1">
              <div 
                v-for="(v, k) in historical"
                :key="k"
                class="stepper-item"
                :class="isAllow == 'N' ? '' : v.payment_status"
              >
                <div class="step-counter bold">{{ v.num_of_payment }}</div>
              </div>
            </div>
          </template>
          <template v-if="transactionType == 'F'">
            <span v-if="isAllow == 'N'">Waiting for confirmation</span>
            <span v-else-if="baseStatus == 'W'" class="regular">Waiting for payment</span>
            <span v-else-if="baseStatus == 'V'" class="regular">Payment in verification</span>
            <span v-else-if="baseStatus == 'P'" class="regular">Paid</span>
            <span v-else-if="baseStatus == 'C'" class="regular">Canceled</span>
            <span v-else-if="baseStatus == 'E'" class="regular">Expired</span>
            <div class="stepper-wrapper mt-1">
              <div 
                class="stepper-item"
                :class="isAllow == 'N' ? '' : (historical[0] || {}).payment_status"
              >
                <div class="step-counter bold">{{ historical[0].num_of_payment }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="wrap-cta d-flex mt-3">
        <router-link
          :to="{ name: 'TransactionDetails', params: { pageSlug: slug } }"
          class="btn btn-primary bold w-100 d-flex justify-content-center align-items-center"
          >See Details
        </router-link>
        <button v-if="isDelete && baseStatus == 'P'" @click="$emit('delete')" class="btn btn-outline-main bold w-25">
          <i class="icon-trash-2 fs-125em"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecentOrder',
  props: {
    transactionId: {
      type: String,
    },
    price: {
      type: String,
    },
    image: {
      type: String,
    },
    fallbackImage: {
      type: String,
    },
    altImage: {
      type: String,
    },
    eventName: {
      type: String,
    },
    raceDate: {
      type: String,
    },
    distance: {
      type: String,
    },
    slug: {
      type: String,
    },
    historical: {
      type: Array,
    },
    baseStatus: {
      type: String,
    },
    transactionType: {
      type: String,
    },
    numOfPayment: {
      type: String,
    },
    isAllow: {
      type: String,
    },
    isMobile: {
      type: Boolean,
    },
    isWebp: {
      type: Boolean,
    },
    program: {
      type: String,
    },
    country: {
      type: String,
    },
    orderDate: {
      type: String,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    itineraryName: {
      type: String,
    },
    packageName: {
      type: String,
    },
    mainRunnerName: {
      type: String,
    },
    secondRunnerName: {
      type: String,
    },
  },
}
</script>

<style>

</style>
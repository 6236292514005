<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div class="mb-5 d-flex">
                <a @click="$router.go(-1)">
                  <i class="icon-chevron-left fs-125em pointer"></i>
                </a>
                <h5 class="bold ls-0 mx-2 fs-125em">Write a Review</h5>
              </div>
              <div>
                <ValidationObserver v-slot="{ handleSubmit }" ref="ReviewForm">
                  <b-form ref="ReviewFormElement" @submit.prevent="handleSubmit(submitFormReview)">
                    <div class="mb-5" v-for="(v, k) in (myReviewQuestionData || {}).question_category" :key="k">
                      <h5 class="bold ls-0">{{ v.category_name }}</h5>
                      <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                        <div class="form-group" v-for="(v1, k1) in filterQuestionByCategoryId(v.category_id)" :key="k1">
                          <label
                            for=""
                            class="bold ls-0"
                            >{{ v1.erv_question }}
                              <span v-if="(v1.erv_validation || {}).includes('Required')" class="text-danger">*</span></label
                            </label
                          >
                          <template v-if="v1.erv_type == 'Ratings'">
                            <star-rating v-model="v1.erv_answer"></star-rating>
                          </template>
                          <template v-if="v1.erv_type == 'Text'">
                            <input
                              type="text"
                              class="form-control main-control regular"
                              v-model="v1.erv_answer"
                              :placeholder="v1.erv_placeholder"
                            />
                          </template>
                          <template v-if="v1.erv_type == 'Text Area'">
                            <textarea
                              class="form-control main-control text-area regular"
                              v-model="v1.erv_answer"
                              :placeholder="v1.erv_placeholder"
                            ></textarea>
                          </template>
                          <VValidate :name="v1.erv_question_initial" v-model="v1.erv_answer" :rules="v1.erv_validation || (v1.erv_validation || []).length > 0 ? (v1.erv_validation || []).join('|').toLowerCase() : ''" />
                        </div>
                      </div>
                    </div>
                    <template>
                      <div class="mb-4" v-if="JSON.stringify(formReviewStatic) !== '{}'">
                        <h5 class="bold ls-0">Testimonial</h5>
                        <star-rating v-model="formReviewStatic.erv_overall_ratings"></star-rating>
                        <textarea
                          class="form-control main-control text-area"
                          v-model="formReviewStatic.erv_testimonial"
                          placeholder="Enter Message..."
                        ></textarea>
                        <VValidate name="Testimonial" v-model="formReviewStatic.erv_testimonial" :rules="{required: true, min: 10}" />
                      </div>
                      <div class="mb-4" v-if="JSON.stringify(formReviewStatic) !== '{}'">
                        <div class="form-group">
                          <label class="regular" for="exampleCheck1"
                            >Are you interested in having an interview session to create a story about your running
                            experience in RunDeGlobe?</label
                          >
                          <div class="d-flex mt-3">
                            <b-form-radio v-model="formReviewStatic.erv_is_interview" class="ml-3" name="interview" value="N"
                              >No</b-form-radio
                            >
                            <b-form-radio v-model="formReviewStatic.erv_is_interview" name="interview" value="Y">Yes</b-form-radio>
                          </div>
                          <VValidate name="Interview Session" v-model="formReviewStatic.erv_is_interview" :rules="{required: true}" />
                        </div>
                      </div>
                    </template>
                    <div class="row justify-content-end">
                      <div class="col-md-6 d-flex justify-content-end">
                        <router-link
                          :to="{ name: 'MyReviews' }"
                          class="btn btn-secondary-black semibold mx-2 d-flex align-self-start mt-3 align-items-center text-dark"
                          >Cancel</router-link
                        >
                        <button
                          type="submit"
                          class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <div class="b-grey-black px-4 py-4 bdrd-12px mb-4">
              <div class="d-flex align-items-center">
                <picture class="img-small-fr">
                  <source
                    :src="uploader(((myReviewQuestionData || {}).event_information || {}).trv_preview_img)"
                    type="image/webp"
                  />
                  <img
                    :src="uploader(((myReviewQuestionData || {}).event_information || {}).trv_preview_fallback_img)"
                    :alt="((myReviewQuestionData || {}).event_information || {}).trv_preview_alt_img"
                    class="img-small-fr"
                  />
                </picture>
                <div class="mx-3">
                  <p class="bold text-dark fs-125em lh-125 mb-0">{{ ((myReviewQuestionData || {}).event_information || {}).trv_event_name }}</p>
                  <span class="grey-color regular">{{ ((myReviewQuestionData || {}).event_information || {}).trv_itinerary_name }}, {{ ((myReviewQuestionData || {}).event_information || {}).trv_package_name }} Package</span>
                </div>
              </div>
              <div class="d-flex flex-column mt-3">
                <div class="d-flex align-items-center mt-2">
                  <i class="fa fa-calendar gamma-color fs-1em"></i>
                  <a href=""><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(((myReviewQuestionData || {}).event_information || {}).trv_race_date) }}</span></a>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                  <a href=""><span class="mx-2 gamma-color semibold">{{ ((myReviewQuestionData || {}).event_information || {}).trv_program }}, {{ ((myReviewQuestionData || {}).event_information || {}).trv_country}}</span></a>
                </div>
              </div>
            </div>
            <PPHelpCenter/>
          </div>
        </div>
      </div>
    </template>

    <template #page-title>
      <div class="d-flex align-items-center">
        <a @click="$router.go(-1)">
          <i class="icon-chevron-left fs-125em pointer text-white"></i>
        </a>
        <h2 class="black fs-175em text-white ml-2 fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">
          Write a Review
        </h2>
      </div>
      <div>
        <div class="bg-white mt-4 px-4 py-4 bdrd-12px">
          <div class="d-flex align-items-center">
            <picture class="img-small-fr">
              <source
                :src="uploader(((myReviewQuestionData || {}).event_information || {}).trv_preview_img)"
                type="image/webp"
              />
              <img
                :src="uploader(((myReviewQuestionData || {}).event_information || {}).trv_preview_fallback_img)"
                :alt="((myReviewQuestionData || {}).event_information || {}).trv_preview_alt_img"
                class="img-small-fr"
              />
            </picture>
            <div class="mx-3">
              <p class="bold text-dark fs-125em mb-0 lh-125">{{ ((myReviewQuestionData || {}).event_information || {}).trv_event_name }}</p>
              <span class="grey-color regular">{{ ((myReviewQuestionData || {}).event_information || {}).trv_itinerary_name }}, {{ ((myReviewQuestionData || {}).event_information || {}).trv_package_name }} Package</span>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-3">
            <div class="d-flex align-items-center mt-2">
              <i class="fa fa-calendar gamma-color fs-085em"></i>
              <a href=""><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(((myReviewQuestionData || {}).event_information || {}).trv_race_date) }}</span></a>
            </div>
            <div class="d-flex align-items-center mt-2">
              <i class="fa fa-location-arrow gamma-color fs-085em"></i>
              <a href=""><span class="mx-2 gamma-color semibold">{{ ((myReviewQuestionData || {}).event_information || {}).trv_program }}, {{ ((myReviewQuestionData || {}).event_information || {}).trv_country}}</span></a>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #content-mobile>
      <div class="row">
        <div class="col-lg-4 col-md-12" v-if="!isMobile()">
          <div class="sticky-100px">
            <div class="b-grey-black px-4 py-4 bdrd-12px">
              <div class="d-flex align-items-center">
                <picture class="img-small-fr">
                  <source
                    :src="uploader(((myReviewQuestionData || {}).event_information || {}).trv_preview_img)"
                    type="image/webp"
                  />
                  <img
                    :src="uploader(((myReviewQuestionData || {}).event_information || {}).trv_preview_fallback_img)"
                    :alt="((myReviewQuestionData || {}).event_information || {}).trv_preview_alt_img"
                    class="img-small-fr"
                  />
                </picture>
                <div class="mx-3">
                  <p class="bold text-dark fs-125em mb-0">{{ ((myReviewQuestionData || {}).event_information || {}).trv_event_name }}</p>
                  <span class="grey-color regular">{{ ((myReviewQuestionData || {}).event_information || {}).trv_itinerary_name }}, {{ ((myReviewQuestionData || {}).event_information || {}).trv_package_name }} Package</span>
                </div>
              </div>
              <div class="d-flex flex-wrap mt-3">
                <div class="d-flex align-items-center mt-2">
                  <i class="fa fa-calendar gamma-color fs-085em"></i>
                  <a href=""><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(((myReviewQuestionData || {}).event_information || {}).trv_race_date) }}</span></a>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <i class="fa fa-location-arrow gamma-color fs-085em"></i>
                  <a href=""><span class="mx-2 gamma-color semibold">{{ ((myReviewQuestionData || {}).event_information || {}).trv_program }}, {{ ((myReviewQuestionData || {}).event_information || {}).trv_country}}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 mb-4">
          <div>
            <ValidationObserver v-slot="{ handleSubmit }" ref="ReviewForm">
              <b-form ref="ReviewFormElement" @submit.prevent="handleSubmit(submitFormReview)">
                <div class="mb-5" v-for="(v, k) in (myReviewQuestionData || {}).question_category" :key="k">
                  <h5 class="bold ls-0">{{ v.category_name }}</h5>
                  <div class="form-group" v-for="(v1, k1) in filterQuestionByCategoryId(v.category_id)" :key="k1">
                    <label for="" class="bold ls-0 d-flex"
                      >{{ v1.erv_question }}
                      <span v-if="(v1.erv_validation || {}).includes('Required')" class="text-danger">*</span></label
                    >
                    <template v-if="v1.erv_type == 'Ratings'">
                      <star-rating v-model="v1.erv_answer"></star-rating>
                    </template>
                    <template v-if="v1.erv_type == 'Text'">
                      <input
                        type="text"
                        class="form-control main-control regular"
                        v-model="v1.erv_answer"
                        :placeholder="v1.erv_placeholder"
                      />
                    </template>
                    <template v-if="v1.erv_type == 'Text Area'">
                      <textarea
                        class="form-control main-control text-area regular"
                        v-model="v1.erv_answer"
                        :placeholder="v1.erv_placeholder"
                      ></textarea>
                    </template>
                    <VValidate :name="v1.erv_question_initial" v-model="v1.erv_answer" :rules="v1.erv_validation || (v1.erv_validation || []).length > 0 ? (v1.erv_validation || []).join('|').toLowerCase() : ''" />
                  </div>
                </div>
                <div class="mb-4" v-if="JSON.stringify(formReviewStatic) !== '{}'">
                  <h5 class="bold ls-0">Testimonial</h5>
                  <star-rating v-model="formReviewStatic.erv_overall_ratings"></star-rating>
                  <textarea
                    class="form-control main-control text-area regular"
                    v-model="formReviewStatic.erv_testimonial"
                    placeholder="Enter Message..."
                  ></textarea>
                  <VValidate name="Testimonial" v-model="formReviewStatic.erv_testimonial" :rules="{required: true, min: 10}" />
                </div>
                <div class="mb-4" v-if="JSON.stringify(formReviewStatic) !== '{}'">
                  <div class="form-group">
                    <label class="regular" for="exampleCheck1"
                      >Are you interested in having an interview session to create a story about your running experience
                      in RunDeGlobe?<span class="text-danger">*</span></label
                    >
                    <div class="d-flex mt-3">
                      <b-form-radio v-model="formReviewStatic.erv_is_interview" name="interview" value="Y">Yes</b-form-radio>
                      <b-form-radio v-model="formReviewStatic.erv_is_interview" class="ml-3" name="interview" value="N">No</b-form-radio>
                    </div>
                    <VValidate name="Interview Session" v-model="formReviewStatic.erv_is_interview" :rules="{required: true}" />
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-6 d-flex justify-content-end">
                    <router-link
                      :to="{ name: 'MyReviews' }"
                      class="btn btn-secondary-black semibold mx-2 d-flex align-self-start mt-3 align-items-center text-dark"
                      >Cancel</router-link
                    >
                    <button
                      type="submit"
                      class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </template>

    <template #custom-layout>
      <AppModal :withPageTitle="false" ref="modal-success-review" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/appreciate-vect.svg`" alt="" class="w-50" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Thank you for sharing your review!</p>
                <span class="regular fs-1em"
                  >Your feedback is incredibly valuable to us, and we appreciate you taking the time to share your
                  thoughts and opinions</span
                >
                <button
                  @click="closeModalSuccess"
                  class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
    </template>
  </LayoutPrivatePage>
</template>

<script>
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import Global from '@/libs/Global'
import PPHelpCenter from '@/components/PPHelpCenter'
import AppModal from '@/components/AppModal'

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import StarRating from 'vue-star-rating'

export default {
  extends: Global,
  name: 'PastRaces',
  components: {
    LayoutPrivatePage,
    StarRating,
    AppModal,
    PPHelpCenter
  },
  data() {
    return {
      rating: 3,
      formQuestion: [
        {
          category: 'Event',
          questionList: [
            {
              question: 'How would you rate the overall for RunDeGlobe?',
              type: 'rating',
              placeholder: '',
              validation: ['required', 'min_value:1'],
              answer: 0,
              question_initial: 'Event Rate',
            },
            {
              question: 'Were there any issues with the start or finish line, course markings, or aid stations?',
              type: 'textarea',
              placeholder: 'Enter message...',
              validation: ['required', 'min:3', 'max:254'],
              answer: '',
              question_initial: 'Event Note',
            },
          ],
        },
        {
          category: 'Communication',
          questionList: [
            {
              question: 'How would you rate the communication from RunDeGlobe before, during, and after the marathon',
              type: 'rating',
              placeholder: '',
              validation: ['required', 'min_value:1'],
              answer: 0,
              question_initial: 'Communication Rate',
            },
            {
              question: 'Any suggestion for us to improve our communication?',
              type: 'textarea',
              placeholder: 'Enter message...',
              validation: ['required', 'min:3', 'max:254'],
              answer: '',
              question_initial: 'Communication Note',
            },
          ],
        },
        {
          category: 'Overall Experience',
          questionList: [
            {
              question: 'How would you rate your overall experience at the marathon?',
              type: 'rating',
              placeholder: '',
              validation: ['required', 'min_value:1'],
              answer: 0,
              question_initial: 'Overall Rate',
            },
            {
              question: 'What was the most memorable aspect of the event for you?',
              type: 'textarea',
              placeholder: 'Enter message...',
              validation: ['required', 'min:3', 'max:254'],
              answer: '',
              question_initial: 'Most memorable aspect',
            },
            {
              question: 'Are there any specific areas for improvement you would suggest?',
              type: 'textarea',
              placeholder: 'Enter message...',
              validation: ['required', 'min:3', 'max:254'],
              answer: '',
              question_initial: 'Specific areas for improvement',
            },
          ],
        },
      ],
      testimonial: '',
      is_story_running: '',
    }
  },
  computed: {
    ...mapFields("useMyReviewStore", [
      "myReviewQuestionData",
      "formReviewStatic"
    ])
  },
  created() {
    this.getMyReviewQuestionData(this.$route.params.pageSlug)
    this.$store.commit('SET_STATIC_SEO', {
      title: 'My Reviews'
    })
  },
  methods: {
    ...mapActions("useMyReviewStore", [
      "getMyReviewQuestionData",
      "doSubmitFormReview"
    ]),
    filterQuestionByCategoryId(category_id) {
      return ((this.myReviewQuestionData || {}).question_information || {}).filter(datas => datas.erv_question_category == category_id)
    },
    submitFormReview() {
      this.doSubmitFormReview({
        form: (this.myReviewQuestionData || {}).question_information,
        transactionId: this.$route.params.pageSlug,
        callback: (success) => {
          if (!success) {
            return
          }

          setTimeout(() => {
            this.$refs.ReviewForm.reset()
            this.$refs['modal-success-review'].show()
          }, 100)
        },
      })
    },
    closeModalSuccess() {
      this.$refs['modal-success-review'].hide()
      this.$router.push({ name: 'MyReviews' })
    },
  },
}
</script>

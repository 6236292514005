import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

import router from "../../router/index"

export default {
  namespaced: true,

  state: {
    confirmContent: {
      title: '',
      description: '',
      cta: '',
    },
    content: {
      hero: {
        title: '',
        sub_title: '',
        description: '',
        image: '',
        fallback_image: '',
        alt_image: '',
      },
      tourGuide: {
        title: '',
        description: '',
        image: '',
        image_small: '',
        fallback_image: '',
        fallback_image_small: '',
      },
    },
    formContent: {
      tourGuide: {
        img_background_form: '',
      },
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_CONTENT(state, data) {
      state.content = data.content
    },
    SET_WWU_TOURGUIDE_DATA(state, data) {
      state.formContent = (data.content || {}).tourGuide
    },
    SET_WWU_TOURGUIDE_CONFIRM_DATA(state, data) {
      state.confirmContent = data.content
    }
  },

  actions: {
    async getConfirmContent({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-tourguide/confirm', {
          headers: Gen.apiHeader(),
        })

        commit('SET_WWU_TOURGUIDE_CONFIRM_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDataContent({ rootState, commit }, callback = false) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-tourguide/data', {
          headers: Gen.apiHeader(),
        })

        if(res.data.content.tourGuide.is_active == 'N') {
          if(router.currentRoute.name == 'WWUTourGuide') {
            router.push({
              name: 'WWULPTourGuide'
            })
          }
        }

        if (callback) {
          callback()
        }

        commit('SET_WWU_TOURGUIDE_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        if ((err.response || {}).status === 403) {
          router.push({ name: 'WWULPTourGuide' })
        }
      } finally {
        rootState.loading.screen = false
      }
    },
    
    async getContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/tour-guide`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_CONTENT', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async applyTourGuide({ state, rootState, commit }, data) {
      const { form, callback = false } = data

      try {
        rootState.loading.screen = true

        await Gen.apiRest(
          '/public-pages/tour-guide',
          {
            data: { ...form },
          },
          'POST'
        )

        if (callback) {
          callback(true)
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <div>
    <div class="accordion-menu">
      <ul class="ul-accordion">
        <li class="li-accordion">
          <input type="checkbox" checked />
          <i class="arrow"></i>
          <div class="d-flex align-items-center">
            <h2 class="bold mb-0">1. Profile Information </h2>
            <i v-if="myInformation.profile === 0" class="ml-2 fa fa-exclamation-circle fs-15em text_secondary"></i>
            <i v-else class="ml-2 fa fa-check-circle fs-15em text_success"></i>
          </div>
          <p class="d-flex align-itmes-center mb-0">
            <i class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add first &amp; middle name</span>
          </p>
          <p class="d-flex align-itmes-center mb-0">
            <i class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add last name</span>
          </p>
          <p class="d-flex align-itmes-center mb-0">
            <i class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add phone number</span>
          </p>
          <p class="d-flex align-itmes-center mb-0">
            <i class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add citizenship</span>
          </p>
          <p class="d-flex align-itmes-center mb-0">
            <i class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add gender</span>
          </p>
          <p class="d-flex align-itmes-center mb-0">
            <i class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add date of birth</span>
          </p>
          <p class="d-flex align-itmes-center mb-0">
            <i v-if="user.registrant_passportexp === null" class="fa fa-exclamation-circle fs-15em text_secondary"></i>
            <i v-else class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Add Passport Expiration Date</span>
          </p>
          <p class="mb-0" v-if="myInformation.profile === 0">
            <router-link :to="{ name: 'ProfileInformation', query: {ref: 1} }" class="btn btn-primary">Complete Now</router-link>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myInformation: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
}
</script>
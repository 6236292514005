import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    userDocumentData: {
      data: {
        data: [],
      }
    }
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_USER_DOCUMENT_DATA(state, data) {
      state.userDocumentData = data
    },
  },

  actions: {
    async getAllDocument({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const token = Gen.getCookie("userAuthRdg")
        const res = await http.get("/documents/all-data", {
          headers: Gen.apiHeaderAuth(token),
        })

        commit('SET_USER_DOCUMENT_DATA', res.data.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <section class="bg-grey-blue py-5 relative-index-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div>
            <carousel
              v-if="data.length > 0"
              :items="!isOnlyMobile ? 5 : 2"
              :nav="false"
              :margin="20"
              class="custom-carousel d-flex justify-content-center"
            >
              <div
                v-for="(v, k) in data"
                :key="'partner-'+k"
                class="img-partner bg-white-custom px-3 py-3 bdrd-24px partner d-flex justify-content-center align-items-center"
              >
                <a :href="v.url" target="_blank">
                  <picture>
                    <source :srcset="uploader(v.image)" type="image/webp">
                    <img
                      :src="uploader(v.fallback_image)"
                      alt="Partner Image"
                      class="img-fluid"
                      width="168"
                      height="112"
                    />
                  </picture>
                </a>
              </div>

              <template slot="next" v-if="data.length > 0">
                <div class="arrow-carousel-component right pointer">
                  <i class="icon-arrow-right"></i>
                </div>
              </template>
              <template slot="prev" v-if="data.length > 0">
                <div class="arrow-carousel-component left pointer">
                  <i class="icon-arrow-left"></i>
                </div>
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'PartnersAtom',
  components: {
    carousel,
  },
  props: {
    isOnlyMobile: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    data: {
      type: Array,
    },
    uploader: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-carousel {
  &.d-flex.justify-content-center {
    @media only screen and (min-width: 768px) {
      .owl-stage-outer {
        display: flex;
        justify-content: center;
      }
    }
  }
  &.d-flex.align-items-center {
    @media only screen and (max-width: 768px) {
      .owl-stage {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

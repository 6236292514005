<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbPricesData"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isDesktop()">
        <section class="mt-0 pt-5 overflow-visible">
          <div class="container">
            <div class="row align-items-end bb-1px-grey pb-3">
              <div class="col-md-10">
                <div class="label-custom green d-inline">
                  <span class="black">{{ ((bookingPricesData || {}).content || {}).sub_title }}</span>
                </div>
                <h2 class="black mt-2 fs-275em">{{ ((bookingPricesData || {}).content || {}).title }}</h2>
                <p class="medium grey-color w-75">
                  {{ ((bookingPricesData || {}).content || {}).description }}
                </p>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-md-2 br-1px br-grey-black p-r-30">
                <div class="tab-left sticky-100px">
                  <ul class="component-sticky-main" v-scroll-spy-active v-scroll-spy-link>
                    <li v-for="(v, k) in (bookingPricesData || {}).data" :key="k">
                      <a>
                        <div class="card-icon-title d-flex flex-column align-items-center mb-4">
                          <div class="img">
                            <img :src="uploader(v.bookingprices_img_icon)" :alt="v.bookingprices_alt_img_icon" />
                          </div>
                          <span class="extrabold">{{ v.bookingprices_title }}</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-10 p-l-30">
                <div>
                  <div v-scroll-spy="{ offset: 85 }">
                    <template v-for="(v, k) in (bookingPricesData || {}).data">
                      <div
                        class="pb-5"
                        :class="[
                          k != '0' ? 'pt-5' : '',
                          k + 1 != ((bookingPricesData || {}).data || {}).length ? 'bb-1px-grey' : '',
                        ]"
                        :key="k"
                      >
                        <div class="row align-items-center">
                          <div class="col-md-5">
                            <img :src="uploader(v.bookingprices_img)" :alt="v.bookingprices_alt_img" class="w-100" />
                          </div>
                          <div class="col-md-7">
                            <span class="extrabold">{{ v.bookingprices_sub_title }}</span>
                            <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">
                              {{ v.bookingprices_title }}
                            </h2>
                            <span class="regular">{{ v.bookingprices_description }}</span>
                            <div class="mt-3 d-flex flex-wrap" v-if="(v.bookingprices_benefits || {}).length > 0">
                              <div
                                class="extras-check mb-2 mx-1 w-475"
                                v-for="(v1, k1) in v.bookingprices_benefits"
                                :key="k1"
                              >
                                <div>
                                  <i class="fa fa-check text-white fs-085em"></i>
                                </div>
                                <div>
                                  <p class="mb-0 bold text-dark">{{ v1.item_name }}</p>
                                  <span class="regular">{{ v1.item_desc }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>

      <template v-else-if="isMobile()">
        <div class="mobile-version-view py-5">
          <section class="mt-0 pt-5 bb-1px-grey pb-4 overflow-visible">
            <div class="container">
              <div class="row align-items-end">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <div class="label-custom green d-inline">
                        <span class="black">{{ ((bookingPricesData || {}).content || {}).sub_title }}</span>
                      </div>
                      <h2 class="black mt-3 fs-15em">{{ ((bookingPricesData || {}).content || {}).title }}</h2>
                      <span class="medium grey-color">{{ ((bookingPricesData || {}).content || {}).description }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-0 pt-0 overflow-visible">
            <div>
              <div class="tabs link">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <div
                          class="pb-5 pt-5"
                          v-for="(v, k) in (bookingPricesData || {}).data"
                          :key="k"
                          :class="k + 1 != ((bookingPricesData || {}).data || {}).length ? 'bb-1px-grey' : ''"
                        >
                          <div class="row align-items-center">
                            <div class="col-md-5">
                              <img :src="uploader(v.bookingprices_img)" :alt="v.bookingprices_alt_img" class="w-100" />
                            </div>
                            <div class="col-md-7 mt-4">
                              <span class="extrabold">{{ v.bookingprices_sub_title }}</span>
                              <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">
                                {{ v.bookingprices_title }}
                              </h2>
                              <span class="regular">{{ v.bookingprices_description }}</span>
                              <div class="mt-4 d-flex flex-wrap" v-if="(v.bookingprices_benefits || {}).length > 0">
                                <div
                                  class="extras-check mb-3 mx-1 w-100"
                                  v-for="(v1, k1) in v.bookingprices_benefits"
                                  :key="k1"
                                >
                                  <div>
                                    <i class="fa fa-check text-white fs-085em"></i>
                                  </div>
                                  <div>
                                    <p class="mb-0 bold text-dark">{{ v1.item_name }}</p>
                                    <span class="regular">{{ v1.item_desc }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a
          :href="`https://wa.me/${(bookingPricesData || {}).whatsapp_contact}`"
          target="_blank"
          class="fab fa-whatsapp text-white p-3"
        >
        </a>
      </div>
      <div class="btn-app">
        <div class="fa fa-envelope-square" @click="$router.push({ name: 'ContactUs' })"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'

export default {
  extends: Global,
  name: 'BookingPrices',
  components: {
    Header,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields('useBookingPricesStore', ['bookingPricesData', 'breadcrumbPricesData']),
  },
  created() {
    this.getData()
    
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('useBookingPricesStore', ['getData']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
    changeCategory(cat) {
      this.$router.push({ name: 'BookingAndPrices', params: { pageSlug: cat } }).catch(() => {})
    },
  },
}
</script>

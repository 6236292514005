import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "../../libs/Gen"

export default {
  namespaced: true,
  state: {
    inputDocument: {},
    inputDocumentSearch: "",
    documentId: null,
    documentData: [],
    documentTypeData: null,
    formDocument: {
      input_registrant_id: "",
      input_filename: "",
      input_type: "",
      input_file: "",
      input_filetype: ""
    },
    registeredEvents: {},
    pastEvents: {},
    overviewData: [],
    overviewInput: {
      event_pb: null,
      time_pb: {
        HH: '',
        mm: '',
        ss: ''
      },
    },
    citizenshipData: [],
    countryCodeData: [],
    languageData: [],
    provinceData: [],
    citiesData: [],
    districtData: [],
    subdistrictData: [],
    relationData: [],
    inputChangeProfile: {},
    inputChangePassword: {
      new_password: "",
      confirm_new_password: "",
    },
    inputChangeProfileFoto: null,
    registeredEvents: {},
    pastEvents: {},
    gender: [
      { value: "M", text: "Male" },
      { value: "F", text: "Female" },
      { value: "O", text: "Other" },
    ],
    nationality: [
      { value: "WNI", text: "Indonesian Citizen" },
      { value: "WNA", text: "Foreigner" },
    ],
    bloodType: [
      { value: "A+", text: "A+" },
      { value: "AB+", text: "AB+" },
      { value: "B+", text: "B+" },
      { value: "O+", text: "O+" },
      { value: "A-", text: "A-" },
      { value: "AB-", text: "AB-" },
      { value: "B-", text: "B-" },
      { value: "O-", text: "O-" },
    ],
    dateAPI: null,

    // FILTER
    filterCountry: null,
    filterCitizenship: null,
    filterLanguages: null,
    filterProvinces: null,
    filterCities: null,
    filterDistrict: null,
    filterSubDistrict: null,
    filterRelations: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_DOCUMENT_DATA(state, data) {
      state.documentData = data
    },

    SET_DOCUMENT_TYPE_DATA(state, data) {
      state.documentTypeData = data
    },

    CLEAR_INPUT_DOCUMENT_DATA(state) {
      state.inputDocument.input_type = null
      state.inputDocument.input_file = null
    },

    SET_CITIZENSHIP_DATA(state, data) {
      state.citizenshipData = data
    },

    SET_COUNTRY_CODE_DATA(state, data) {
      state.countryCodeData = data
    },

    SET_PROVINCE_DATA(state, data) {
      state.provinceData = data
    },

    CLEAR_INPUT_CHANGE_PASSWORD(state) {
      state.inputChangePassword.current_password = ""
      state.inputChangePassword.new_password = ""
      state.inputChangePassword.confirm_new_password = ""
    },

    SET_CITIES_DATA(state, data) {
      state.citiesData = data
    },

    SET_DISTRICT_DATA(state, data) {
      state.districtData = data
    },

    SET_SUBDISTRICT_DATA(state, data) {
      state.subdistrictData = data
    },

    SET_LANGUAGE_DATA(state, data) {
      state.languageData = data
    },

    SET_RELATION_DATA(state, data) {
      state.relationData = data
    },

    SET_INFORMATION_ACCOUNT(state, data) {
      try {
        if (!Object.prototype.hasOwnProperty.call(data, "user")) {
          return
        }

        state.inputChangeProfile = data

        let arr = data.user

        arr.registrant_citizenship_flag = data.user.registrant_citizenship_flag
        arr.registrant_citizenship = data.user.registrant_citizenship ? parseInt(data.user.registrant_citizenship) : data.user.registrant_citizenship
        arr.registrant_country_of_residence = data.user_address.registrant_country_of_residence ? parseInt(data.user_address.registrant_country_of_residence) : data.user_address.registrant_country_of_residence
        arr.registrant_country_of_residence_flag = data.user_address.registrant_country_of_residence_flag
        arr.registrant_address = data.user_address.registrant_address
        arr.registrant_address_1 = data.user_address.registrant_address_1
        arr.registrant_address_2 = data.user_address.registrant_address_2
        arr.registrant_address_3 = data.user_address.registrant_address_3
        arr.registrant_city = data.user_address.registrant_city
        arr.registrant_district = data.user_address.registrant_district
        arr.registrant_subdistrict = data.user_address.registrant_subdistrict
        arr.registrant_zip_code = data.user_address.registrant_zip_code
        arr.registrant_state = data.user_address.registrant_state
        arr.registrant_country = data.user_address.registrant_country
        arr.registrant_phone = data.user.registrant_phone
        arr.registrant_citizenship_fo = data.user_address.citizenship_name
        arr.registrant_medical_information = (data.user_last_medical_information || {}).registrant_medical_information
        arr.registrant_medical_confirmation = (data.user_last_medical_information || {}).registrant_medical_confirmation
        arr.registrant_emergency_full_name = data.user_emergency_contact.registrant_emergency_full_name
        arr.registrant_emergency_relation = data.user_emergency_contact.registrant_emergency_relation
        arr.registrant_emergency_email_address = data.user_emergency_contact.registrant_emergency_email_address
        arr.registrant_emergency_phone_number = data.user_emergency_contact.registrant_emergency_phone_number
        arr.registrant_emergency_dial_code = data.user_emergency_contact.registrant_emergency_dial_code
        arr.registrant_emergency_preferred_language = data.user_emergency_contact.registrant_emergency_preferred_language
        arr.registrant_blood_type = data.user.registrant_blood_type

        state.inputChangeProfile = arr
      } catch (error) {
        console.log('eror', error)
        return error
      }
    },

    SET_REGISTERED_EVENTS(state, data) {
      state.registeredEvents = data
    },

    SET_DATE(state, data) {
      state.dateAPI = data
    },

    SET_DOCUMENT_ID(state, data) {
      state.documentId = data
    },

    CLEAR_DOCUMENT_ID(state) {
      state.documentId = null
    },

    CLEAR_INPUT_FORM_DOCUMENT(state) {
      state.formDocument.input_filename = ""
      state.formDocument.input_type = ""
      state.formDocument.input_file = ""
      state.formDocument.input_filetype = ""
    },

    SET_OVERVIEW_DATA(state, data) {
      state.overviewData = data
    },

    SET_OVERVIEW_INPUT(state, data) {
      if (data !== null) {
        state.overviewInput.event_pb = data.overview_event
        state.overviewInput.time_pb = data.overview_time
      } else {
        state.overviewInput.event_pb = null
        state.overviewInput.time_pb = {
          HH: '',
          mm: '',
          ss: ''
        }
      }
    },

    CLEAR_OVERVIEW_INPUT(state) {
      state.overviewInput.event_pb = null
      state.overviewInput.time_pb = {
        HH: '',
        mm: '',
        ss: ''
      }
    },
  },
  actions: {
    async getDocumentType({ commit, rootState }) {
      try {
        rootState.loading.data = true
        const token = Gen.getCookie('userAuthRdg')
        const res = await http.get("/documents/type", {
          headers: Gen.apiHeaderAuth(token)
        })

        if(res.data.data.data.length != 0) {
          commit("SET_DOCUMENT_TYPE_DATA", res.data.data.data)
        }
      } catch (err) {
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async getAllDocument({ state, commit, rootState }, data) {
      const { page, search, callback = false } = data

      try {
        rootState.loading.data = true
        const token = Gen.getCookie("userAuthRdg")
        const res = await http.get("/documents/all-data", {
          headers: Gen.apiHeaderAuth(token),
          params: {
            input_search: search,
            page,
          }
        })

        commit("SET_DOCUMENT_DATA", res.data.data.data)

        if (callback) {
          callback()
        }
      } catch (err) {
        if (callback) {
          callback()
        }
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async getDocuments({ commit, rootState }) {
      try {
        rootState.loading.data = true
        const token = Gen.getCookie("userAuthRdg")
        const res = await http.get("/documents/data", {
          headers: Gen.apiHeaderAuth(token),
        })

        commit("SET_DOCUMENT_DATA", res.data.data.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async deleteDocument({ state, rootState, dispatch }) {
      try {
        rootState.loading.screen = true
        
        const token = Gen.getCookie('userAuthRdg')
        
        const res = await Gen.apiRest(
          "/documents/delete",
          { 
            data: Object.assign({}, { 
              registrant_id: rootState.user.registrant_id,
              document_id: state.documentId,
            }),
            headers: Gen.apiHeaderAuth(token)
          },
          "POST"
        )

        dispatch("getDocuments")

        return res
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    clearDocumentForm({ commit }) {
      commit('CLEAR_INPUT_FORM_DOCUMENT')
    },

    async addDocument({ rootState, commit }, data) {
      const { form, callback = false } = data
      try {
        rootState.loading.screen = true
        const token = Gen.getCookie('userAuthRdg')
        const res = await Gen.apiRest(
          '/documents/add',
          {
            data: { ...form },
            headers: Gen.apiHeaderAuth(token)
          },
          'POST'
        )

        if (callback) {
          callback(true, res)
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getOverview({ commit, rootState }) {
      try {
        rootState.loading.data = true
        const token = Gen.getCookie("userAuthRdg")
        const res = await http.get("/overview/data", {
          headers: Gen.apiHeaderAuth(token),
        })

        commit("SET_OVERVIEW_DATA", res.data.data.data)
        
      } catch (err) {
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async changeOverview({ state, rootState, commit, dispatch }) {
      try {
        rootState.loading.sceen = true
        const res = await Gen.apiRest(
          "/overview/update",
          {
            data: Object.assign({}, state.overviewInput),
            headers: { ...Gen.apiHeaderAuth(rootState.userToken) },
          },
          "POST"
        )

        dispatch("getOverview")

        return res
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getProvince({ commit, state }) {
      try {
        const res = await http.get("/province", {
          headers: Gen.apiHeader(),
          params: {
            state_name: state.filterProvinces
          }
        })
        commit("SET_PROVINCE_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getCities({ commit, state }, province) {
      try {
        const res = await http.get("/cities", {
          headers: Gen.apiHeader(),
          params: {
            province: province,
            city_name: state.filterCities
          },
        })

        commit("SET_CITIES_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getCitizenship({ commit, state }, city) {
      try {
        const res = await http.get("/citizenship", {
          headers: Gen.apiHeader(),
          params: {
            citizenship_name: state.filterCitizenship
          }
        })


        commit("SET_CITIZENSHIP_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getCountryCode({ commit, state, rootState }) {
      try {
        rootState.loading.data = true
        const res = await http.get("/country-code", {
          headers: Gen.apiHeader(),
          params: {
            countrycode_name: state.filterCountry
          }
        })

        if(res.data.data.data.length != 0) {
          commit("SET_COUNTRY_CODE_DATA", res.data.data.data)
        }
      } catch (err) {
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async getLanguages({ commit, rootState, state }) {
      try {
        rootState.loading.data = true
        const res = await http.get("/languages", {
          headers: Gen.apiHeader(),
          params: {
            languages_language: state.filterLanguages
          }
        })

        if(res.data.data.data.length != 0) {
          commit("SET_LANGUAGE_DATA", res.data.data.data)
        }
      } catch (err) {
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async getDistrict({ commit, state }, city) {
      try {
        const res = await http.get("/district", {
          headers: Gen.apiHeader(),
          params: {
            city: city,
            district_name: state.filterDistrict
          },
        })

        commit("SET_DISTRICT_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getRelations({ commit, state }) {
      try {
        const res = await http.get("/relations", {
          headers: Gen.apiHeader(),
          params: {
            relation_name: state.filterRelations
          },
        })

        commit("SET_RELATION_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getSubDistrict({ commit, state }, district) {
      try {
        const res = await http.get("/subdistrict", {
          headers: Gen.apiHeader(),
          params: {
            district: district,
            subdistrict_name: state.filterSubDistrict
          },
        })

        commit("SET_SUBDISTRICT_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async getDateAPI({ commit }) {
      try {
        let resp = await http.get("/dateAPI", {
          headers: Gen.apiHeader(),
        })

        commit("SET_DATE", resp.data.data.date)
      } catch (err) {
        return err
      }
    },

    async _getAllProfile({ commit }, callback = false) {
      try {
        const token = Gen.getCookie("userAuthRdg")
        let resp = await http.get("/auth/profile", {
          headers: Gen.apiHeaderAuth(token),
        })

        if (callback) {
          callback(resp.data)
        }
        
        commit("SET_INFORMATION_ACCOUNT", resp.data)
        // commit("SET_DATE", resp.data.data.date)
      } catch (err) {
        commit("SET_INFORMATION_ACCOUNT", {})
      }
    },

    async getProfile({ dispatch, commit }, callback = false) {
      try {
        dispatch("_getAllProfile", callback)
      } catch (error) {
        commit("SET_INFORMATION_ACCOUNT", {})
        commit("SET_DATE", {})
      }
    },

    async updateProfile({ state, rootState }) {
      try {
        rootState.loading.screen = true
        const res = await Gen.apiRest(
          "/auth/change-profile",
          {
            data: Object.assign({}, state.inputChangeProfile),
            headers: { ...Gen.apiHeaderAuth(rootState.userToken) },
          },
          "POST"
        )
        return res
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async changePassword({ state, rootState, commit }, callback = false) {
      try {
        rootState.loading.screen = true
        const res = await Gen.apiRest(
          "/auth/change-password",
          {
            data: Object.assign({}, state.inputChangePassword),
            headers: { ...Gen.apiHeaderAuth(rootState.userToken) },
          },
          "POST"
        )

        commit("CLEAR_INPUT_CHANGE_PASSWORD")

        callback(true, {})

        return res
      } catch (err) {
        callback(false, err)
      } finally {
        rootState.loading.screen = false
      }
    },

    async changeProfileFoto({ rootState, dispatch, state }) {
      try {
        rootState.loading.screen = true

        await http.put(
          `/auth/change-foto/${state.inputChangeProfile.registrant_id}`,
          state.inputChangeProfileFoto
        )
        dispatch("_getAllProfile")
        return 200
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <section class="bg-custom-dark">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-8">
          <div class="text-white text-center d-flex flex-column align-items-center">
            <span class="sub-title-section white">
              <div class="line-sub-title"></div>
              {{ content.sub_title }}
            </span>
            <h2 class="title-section-left mt-3">{{ content.title }}</h2>
          </div>
        </div>
        <div class="col-md-6" :class="isMobile ? 'order-5' : ''">
          <div class="text-white">
            <div>
              <ul class="timeline">
                <!--Timeline item-->
                <li v-for="(v, k) in data" :key="'how-we-work-'+k" class="timeline-item">
                  <div class="timeline-icon semibold">{{ k + 1 }}</div>
                  <h4 class="extrabold">{{ v.title }}</h4>
                  <p class="regular">
                    {{ v.description }}
                  </p>
                </li>
                <!--end: Timeline item-->
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <picture>
            <source :srcset="uploader(content.image)">
            <img :src="uploader(content.fallback_image)" :alt="content.alt_image" class="w-100" />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowWeWorkAtom',
  components: {},
  props: {
    isMobile: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    content: {
      type: Object,
    },
    uploader: {
      type: Function,
    }
  },
}
</script>

<style></style>

import Vue from 'vue'
import Vuex from 'vuex'

import { getField, updateField } from 'vuex-map-fields'
import Blog from './module/blog'
import Gallery from './module/gallery'
import RunnerSays from './module/runnersays'
import Destination from './module/destination'
import RunningEventDetails from './module/runningeventdetail'
import Legal from './module/legal'
import About from './module/about'
import HowWeWork from './module/howwework'
import BookingPrices from './module/bookingprices'
import Contact from './module/contact'
import TourGuide from './module/tourguide'
import FAQ from './module/faq'
import AnnouncementEvent from './module/announcementevent'
import Footer from './module/footer'
import Newsletter from './module/newsletter'
import Register from './module/register'
import Login from './module/login'
import Profile from './module/profile'

import Gen from '@/libs/Gen'
import { http, httpAthletes} from "../config/http"
import WWUJoinTheTeam from "./module/wwujointheteam"
import WWUHotels from "./module/wwuhotel"
import RunEvents from "./module/runevents"
import Checkout from "./module/checkout"
import PaymentPage from "./module/paymentpage"
import PaymentDetails from "./module/paymentdetails"

import PrivatePage from './module/privatepage'
import HomePage from './module/home'
import Dashboard from './module/dashboard'
import MyPurchase from './module/mypurchase'
import AccountSettings from './module/accountsettings'
import MyNetwork from './module/mynetwork'
import MyReview from './module/myreview'
import Transactions from './module/transactions'
import SupplementaryQuestion from './module/supplementaryquestion'
import Callback from './module/callback'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inputSearch: '',
    resultBlog: [],
    resultRunEvents: [],
    isDev: process.env.VUE_APP_IS_DEV == '1',
    apiUrl: process.env.VUE_APP_API_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    baseUrlAthletes: process.env.VUE_APP_ATHLETES_BASE_URL,
    athletesClientId: process.env.VUE_APP_ATHLETES_CLIENT_ID,
    loading: {
      screen: false,
      data: false,
    },
    setting: {
      tour_guide: 'Y',
      hotel: 'Y',
      join_the_team: 'Y',
    },
    closeModal: null,
    mrGender: ["Male", "Female"],
    staticseo: {},
    userToken: null,
    user: {},
    channels: {
      social_media: {},
      contact: {},
    },
    dashboard_unread_updates: 0,
  },
  getters: {
    getField,
    authenticated(state) {
      return state.userToken !== null
    },
    getBaseUrl(state) {
      return state.baseUrl
    },
  },
  mutations: {
    updateField,
    updateUser(state, data = {}) {
      try {
        if (!Object.prototype.hasOwnProperty.call(data, "user")) {
          return
        }

        let arr = data.user

        arr.registrant_dial_code = data.user_address.registrant_dial_code
        arr.registrant_address = data.user_address.registrant_address
        arr.registrant_address_1 = data.user_address.registrant_address_1
        arr.registrant_address_2 = data.user_address.registrant_address_2
        arr.registrant_address_3 = data.user_address.registrant_address_3
        arr.registrant_city = data.user_address.registrant_city
        arr.registrant_district = data.user_address.registrant_district
        arr.registrant_subdistrict = data.user_address.registrant_subdistrict
        arr.registrant_zip_code = data.user_address.registrant_zip_code
        arr.registrant_state = data.user_address.registrant_state
        arr.registrant_citizenship_fo = data.user_address.citizenship_name
        arr.registrant_country = data.user_address.registrant_country
        arr.registrant_country_of_residence = data.user_address.registrant_country_of_residence
        arr.registrant_country_of_residence_flag = data.user_address.registrant_country_of_residence_flag
        arr.registrant_country_of_residence_fo = data.user_address.registrant_country_of_residence_fo
        arr.registrant_medical_information = (data.user_last_medical_information || {}).registrant_medical_information
        arr.registrant_medical_confirmation = (data.user_last_medical_information || {}).registrant_medical_confirmation
        arr.registrant_emergency_full_name = (data.user_emergency_contact || {}).registrant_emergency_full_name
        arr.registrant_emergency_relation = (data.user_emergency_contact || {}).registrant_emergency_relation
        arr.registrant_emergency_email_address = (data.user_emergency_contact || {}).registrant_emergency_email_address
        arr.registrant_emergency_phone_number = (data.user_emergency_contact || {}).registrant_emergency_phone_number
        arr.registrant_emergency_dial_code = (data.user_emergency_contact || {}).registrant_emergency_dial_code
        arr.registrant_emergency_preferred_language = (data.user_emergency_contact || {}).registrant_emergency_preferred_language
        if (arr.registrant_avatar == null) {
          arr.registrant_avatar = 'avatar/avatar-noimage.webp'
          arr.registrant_avatar_fallback = 'avatar/avatar-noimage.png'
        }

        state.user = arr

        // state.user = data

      } catch (error) {
        return error
      }
    },
    updateUserToken(state, data = null) {
      state.userToken = data
      Gen.setCookie("userAuthRdg", data, 30 * 3, "/8082")
    },
    logoutUserToken(state, data = null) {
      state.userToken = data
      state.user = {}
      Gen.setCookie("userAuthRdg", "")
    },
    SET_CHANNELS_DATA(state, data) {
      state.channels = data.data
    },

    SET_RESULT_BLOG(state, data) {
      state.resultBlog = data
    },

    SET_RESULT_RUNEVENT(state, data) {
      state.resultRunEvents = data
    },

    CLEAR_SUGGESTION_SEARCH(state) {
      state.inputSearch = null
      state.resultBlog = null
      state.resultRunEvents = null
    },
    SET_STATIC_SEO(state, data = {}) {
      state.staticseo = data
    },
    SET_UNREAD_UPDATES(state, data) {
      state.dashboard_unread_updates = data.unread_updates
    },
    SET_SETTINGS_DATA(state, data) {
      state.setting = data.data
    },
  },
  actions: {
    async getChannels({ commit }) {
      const resp = await http.get('/channels', {
        headers: Gen.apiHeader(),
      })

      commit("SET_CHANNELS_DATA", resp.data)
    },

    async getMenuSettings({ commit }) {
      const resp = await http.get('/settings', {
        headers: Gen.apiHeader(),
      })

      commit("SET_SETTINGS_DATA", resp.data)
    },

    async watchMeta({ commit }, path) {
      try {
        const res = await http.get("/meta", {
          headers: Gen.apiHeader(),
          params: {
            uri_path: path,
          },
        })

        commit("SET_STATIC_SEO", res.data[0])
      } catch (err) {
        return err
      }
    },

    async watchAuth({ commit }) {
      if (
        Gen.getCookie("userAuthRdg") === "" ||
        Gen.getCookie("userAuthRdg") === null
      ) {
        return
      }
      commit("updateUserToken", Gen.getCookie("userAuthRdg"))
      try {
        const token = Gen.getCookie("userAuthRdg")
        let resp = await http.get("/auth/profile", {
          headers: Gen.apiHeaderAuth(token),
        })
        resp = resp.data

        if (resp.status == "success") {
          commit("updateUserToken", resp.data.token)
          commit("updateUser", resp.data)
        } else {
          commit("updateUser", {})
        }
      } catch (error) {
        commit("updateUser", {})
        commit("logoutUserToken", null)
      }
    },

    async doSearchNewsArticles({ state, commit }, callback = false) {
      try {
        const res = await Gen.apiRest(
          "/search-newsarticles",
          {
            data: Object.assign({}, { input_title: state.inputSearch }),
            headers: { ...Gen.apiHeader() },
          },
          "POST"
        )

        if (callback) {
          callback()
        }

        commit("SET_RESULT_BLOG", res.data.newsarticles)
      } catch (err) {
        if (callback) {
          callback()
        }
        return err
      }
    },

    async doSearchRunEvents({ state, commit }) {
      try {
        const res = await Gen.apiRest(
          "/search-runevents",
          {
            data: Object.assign({}, { input_title: state.inputSearch }),
            headers: { ...Gen.apiHeader() },
          },
          "POST"
        )

        commit("SET_RESULT_RUNEVENT", res.data.runevents)
      } catch (err) {
        return err
      }
    },
  },
  modules: {
    home: HomePage,
    about: About,
    runnersays: RunnerSays,
    destination: Destination,
    contact: Contact,
    tourguide: TourGuide,
    blog: Blog,
    gallery: Gallery,
    runningeventdetails: RunningEventDetails,
    useLegalStore: Legal,
    useAboutStore: About,
    useHowWeWorkStore: HowWeWork,
    useDestinationStore: Destination,
    useBookingPricesStore: BookingPrices,
    useFAQStore: FAQ,
    useFooterStore: Footer,
    useNewsletterStore: Newsletter,
    register: Register,
    login: Login,
    profile: Profile,
    dashboard: Dashboard,
    mypurchase: MyPurchase,
    useWWUJoinTheTeamStore: WWUJoinTheTeam,
    useWWUHotelStore: WWUHotels,
    useRunEventsStore: RunEvents,
    usePrivatePageStore: PrivatePage,
    useAccountSettingsStore: AccountSettings,
    useMyReviewStore: MyReview,
    useTransactionsStore: Transactions,
    useCheckoutStore: Checkout,
    usePaymentPageStore: PaymentPage,
    usePaymentDetailsStore: PaymentDetails,
    useSupplementaryQuestionStore: SupplementaryQuestion,
    useMyNetworkStore: MyNetwork,
    useAnnouncementEventStore: AnnouncementEvent,
    useCallbackStore: Callback,
  },
})

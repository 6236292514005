import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    termsConditionData: {
      data: {
        data: '',
        updated_date: '',
      },
    },
    privacyPolicyData: {
      data: {
        data: '',
        updated_date: '',
      },
    },
    breadCrumbTermsConditions: [
      {
        text: 'Home',
        to: { name: 'Home' },
      },
      {
        text: 'Terms and Conditions',
        active: true,
      },
    ],
    breadCrumbPrivacyPolicy: [
      {
        text: 'Home',
        to: { name: 'Home' },
      },
      {
        text: 'Privacy Policy',
        active: true,
      },
    ],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_TERMS_CONDITION_DATA(state, data) {
      state.termsConditionData = data
    },
    SET_PRIVACY_POLICY_DATA(state, data) {
      state.privacyPolicyData = data
    },
  },

  actions: {
    async getTermsConditionData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/legal/terms-and-conditions`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_TERMS_CONDITION_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getPrivacyPolicyData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/legal/privacy-policy`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_PRIVACY_POLICY_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

import { getField, updateField } from 'vuex-map-fields'
import { http, httpCurl } from '../../config/http'
import Gen from '@/libs/Gen'

export default {
  namespaced: true,

  state: {
    email_subscribe: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    CLEAR_INPUT_EMAIL_SUBSCRIBE(state) {
      state.email_subscribe = null
    },
  },

  actions: {
    async addSubscriber({ state, rootState, commit }, data) {
      try {
        rootState.loading.screen = true

        let myJSONStr = {
          username: 'RUNDEGLOBE BOT',
          icon_url: 'https://rundeglobe.com/assets/image/favicon/apple-icon-120x120.png',
          channel: '#apps-email',
          attachments: [
            {
              fallback: 'New Subscriber',
              title: `We have new subscriber!`,
              color: '#9C1A22',
              pretext: `${state.email_subscribe}`,
              author_name: 'Support RDG',
              author_link: 'https://rundeglobe.com',
              author_icon: 'https://rundeglobe.com/assets/image/favicon/apple-icon-120x120.png',
              fields: [
                {
                  title: 'Sender',
                  value: `Admin RDG`,
                  short: true,
                },
              ],
              mrkdwn_in: ['text', 'fields'],
              thumb_url: 'http://example.com/thumbnail.jpg',
            },
          ],
        }

        myJSONStr = JSON.stringify(myJSONStr)
        const url = 'https://hooks.slack.com/services/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'

        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        }

        await httpCurl
          .post(url, myJSONStr, {
            timeout: 10000,
            transformRequest(data, headers) {
              delete headers.common['Content-Type']
              return data
            },
            headers,
          })
          .then((res) => res)
          .catch((err) => {
            console.log(err)
          })

        await Gen.apiRest(
          '/register-newsletter',
          {
            data: {
              input_email: state.email_subscribe,
            },
          },
          'POST'
        )
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

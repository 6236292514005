import axios from 'axios'
import Gen from '@/libs/Gen.js'

const baseUrl = process.env.VUE_APP_BASE_URL
const baseUrlAPIWeb = `${process.env.VUE_APP_API_URL}`
const baseUrlAPI = `${process.env.VUE_APP_API_URL}/api/fo/v2`
const baseUrlStrava = `${process.env.VUE_APP_STRAVA_URL}`

// Base URL Athletes ID
const baseUrlAthletes = `${process.env.VUE_APP_ATHLETES_URL}`

const httpCurl = axios.create({
  baseURL: '',
})

const httpWeb = axios.create({
  baseURL: baseUrlAPIWeb,
})

const http = axios.create({
  baseURL: baseUrlAPI,
})

const httpStrava = axios.create({
  baseURL: baseUrlStrava,
})

// Making request to Athletes
const httpAthletes = axios.create({
  baseURL: baseUrlAthletes,
})

function setToken(token) {
  token ? (token = `Bearer ${token}`) : (token = '')

  Gen.apiHeaderAuth(token)
}

function setBaseToken() {
  Gen.apiHeader()
}

export { baseUrl, baseUrlAPI, baseUrlStrava, baseUrlAthletes, http, httpWeb, httpCurl, httpStrava, httpAthletes, setToken, setBaseToken }

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!isMobile()">
        <section class="mt-0 pt-5 overflow-visible">
          <div class="container">
            <div class="row align-items-end bb-1px-grey pb-5">
              <div class="col-md-8">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="label-custom green d-inline">
                      <span class="black">{{ ((FAQData || {}).content || {}).sub_title }}</span>
                    </div>
                    <h2 class="black mt-2 fs-275em">{{ ((FAQData || {}).content || {}).title }}</h2>
                    <span class="medium grey-color">{{ ((FAQData || {}).content || {}).description }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex mb-4 align-items-center">
                  <input
                    type="text"
                    class="mt-0 mx-2 form-control main-control regular"
                    placeholder="Enter your question here..."
                    v-model="inputSearch"
                  />
                  <button
                    class="btn btn-slg btn-primary bold mb-0"
                    @click="openResultSearch"
                    :class="inputSearch ? '' : 'disabled'"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-md-3 br-1px br-grey-black p-r-30">
                <div class="tab-left sticky-100px">
                  <div
                    class="tab-item d-flex align-items-center"
                    @click="changeCategory(v.category_slug)"
                    :class="[k != 0 ? 'my-4' : '', $route.params.pageSlug == v.category_slug ? 'active' : 'grey-color']"
                    v-for="(v, k) in (FAQData || {}).category"
                    :key="k"
                  >
                    <span class="bold text-uppercase pointer">{{ v.category_name }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-9 p-l-30">
                <div class="accordion-menu">
                  <ul class="ul-accordion">
                    <li class="li-accordion" v-for="(v, k) in (FAQData || {}).data" :key="k">
                      <input type="checkbox" checked />
                      <i class="arrow"></i>
                      <h2 class="bold">{{ v.faq_question }}</h2>
                      <p>
                        {{ v.faq_answer }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>

      <template v-else>
        <div class="mobile-version-view height-auto py-5">
          <section class="mt-0 pt-5 bb-1px-grey pb-4 overflow-visible">
            <div class="container">
              <div class="row align-items-end">
                <div class="col-md-8">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <div class="label-custom green d-inline">
                        <span class="black">{{ ((FAQData || {}).content || {}).sub_title }}</span>
                      </div>
                      <h2 class="black mt-2 fs-275em">{{ ((FAQData || {}).content || {}).title }}</h2>
                      <span class="medium grey-color">{{ ((FAQData || {}).content || {}).description }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex mt-4 mb-4 align-items-center" :class="isOnlyMobile() ? 'flex-column' : 'flex-row'">
                    <input
                      type="text"
                      class="mt-0 form-control main-control"
                      placeholder="Enter your question here..."
                      :class="!isTablet() ? 'my-3' : ''"
                      v-model="inputSearch"
                    />
                    <button
                      class="btn btn-slg btn-primary bold mb-0"
                      @click="openResultSearch"
                      :class="[isOnlyMobile() ? 'w-100' : isTablet() ? 'ml-2' : '', inputSearch ? '' : 'disabled']"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-0 pt-0 overflow-visible">
            <div>
              <div class="tabs link">
                <ul class="nav nav-tabs sticky-top-mobile">
                  <li
                    class="nav-item"
                    @click="changeCategory(v.category_slug)"
                    v-for="(v, k) in (FAQData || {}).category"
                    :key="k"
                  >
                    <a
                      class="nav-link"
                      :class="[$route.params.pageSlug == v.category_slug ? 'active' : '']"
                      :id="`tab-${k}`"
                      >{{ v.category_name }}</a
                    >
                  </li>
                </ul>
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="accordion-menu">
                        <ul class="ul-accordion">
                          <li class="li-accordion" v-for="(v, k) in (FAQData || {}).data" :key="k">
                            <input type="checkbox" checked />
                            <i class="arrow"></i>
                            <h2 class="bold">{{ v.faq_question }}</h2>
                            <p>
                              {{ v.faq_answer }}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a
          :href="`https://wa.me/${(FAQData || {}).whatsapp_contact}`"
          target="_blank"
          class="fab fa-whatsapp text-white p-3"
        >
        </a>
      </div>
      <div class="btn-app">
        <div class="fa fa-envelope-square" @click="$router.push({ name: 'ContactUs' })"></div>
      </div>
    </div>

    <AppModal :withPageTitle="true" ref="modal-result-faq" :type="'lg'">
      <template #title-modal>
        <h5>FAQ Detail</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <p>Search: {{ inputSearch }}</p>
            <template v-if="((FAQData || {}).search_result || {}).length > 0">
              <p class="bold fs-125em text-dark mb-0">Below are related questions</p>
              <div class="accordion-menu mt-3">
                <ul class="ul-accordion">
                  <li class="li-accordion" v-for="(v, k) in (FAQData || {}).search_result" :key="k">
                    <input type="checkbox" checked />
                    <i class="arrow"></i>
                    <h2 class="bold">{{ v.faq_question }}</h2>
                    <p>
                      {{ v.faq_answer }}
                    </p>
                  </li>
                </ul>
              </div>
            </template>
            <div v-else>
              <h5 class="semibold ls-0">It seems like there are no questions related to your search.</h5>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
// @ is an alias to /src
import AppModal from '@/components/AppModal'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'

export default {
  extends: Global,
  name: 'Faq',
  components: {
    Header,
    AppModal,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields('useFAQStore', ['FAQData', 'inputSearch']),

    breadcrumbItems() {
      return [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Browse Our FAQ',
          active: true,
        },
      ]
    },
  },
  created() {  
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('useFAQStore', ['getData']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    changeCategory(cat) {
      if (cat != this.$route.params.pageSlug) {
        this.$router.push({ name: 'FAQ', params: { pageSlug: cat } })
        this.getData()
      }
    },

    openResultSearch() {
      this.getData()
      this.$refs['modal-result-faq'].show()
    },
  },
  watch: {
    $route: {
      handler() {
        this.getData()
      },
      immediate: true
    }
  }
}
</script>

<template>
  <div>
    <div class="accordion-menu">
      <ul class="ul-accordion">
        <li class="li-accordion">
          <input type="checkbox" checked />
          <i class="arrow"></i>
          <div class="d-flex align-items-center">
            <h2 class="bold mb-0">4. Medical Information</h2>
            <i v-if="myInformation.medical === 0" class="ml-2 fa fa-exclamation-circle fs-15em text_secondary"></i>
            <i v-else class="ml-2 fa fa-check-circle fs-15em text_success"></i>
          </div>
          <p class="d-flex align-itmes-center mb-0">
            <i v-if="user.registrant_medical_information[0] == '' && user.registrant_medical_confirmation === false" class="fa fa-exclamation-circle fs-15em text_secondary"></i>
            <i v-else class="fa fa-check-circle fs-15em text_success"></i>
            <span class="grey-color regular ml-2">Confirm your medical information</span>
          </p>
          <p class="mb-0"  v-if="myInformation.medical === 0">
            <router-link :to="{ name: 'MedicalInformation', query: {ref: 1} }" class="btn btn-primary">Complete Now</router-link>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myInformation: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
}
</script>
import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    purchases: [],
    purchasesHistorical: [],
    dataLength: 0,
    content: {
      confirmation_title: '',
      confirmation_description: '',
      success_title: '',
      success_description: '',
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    SET_MY_PURCHASES_DATA(state, data) {
      state.purchases = data.purchases
      state.purchasesHistorical = data.purchasesHistorical
      state.dataLength = data.dataLength
      state.content = data.content
    },
  },

  actions: {
    async getMyPurchasesData({ commit, rootState }, data) {
      const { search, category, page, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const token = Gen.getCookie("userAuthRdg")

        const resp = await http.get('/private-pages/my-purchases', {
          headers: Gen.apiHeaderAuth(token),
          params: {
            search,
            category,
            page,
          },
        })

        if (callback) {
          callback()
        }

        commit('SET_MY_PURCHASES_DATA', resp.data)
      } catch (error) {
        if (callback) {
          callback()
        }
        return error
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }

        if (callback) {
          callback()
        }
      }
    },

    async deletePurchase({ commit, rootState }, data) {
      const { transactionId, callback = false} = data
      try {
        rootState.loading.screen = true

        const token = Gen.getCookie("userAuthRdg")

        await http.delete(`/private-pages/my-purchases/${transactionId}`, {
          headers: Gen.apiHeaderAuth(token),
        })

        callback(true)
      } catch (error) {
        callback(false)
        return error
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

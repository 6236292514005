import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'
import router from '../../router/index'

export default {
  namespaced: true,

  state: {
    FAQData: [],
    inputSearch: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_FAQ_DATA(state, data) {
      state.FAQData = data
    },
  },

  actions: {
    async getData({ state, commit, rootState }, callback = false) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/faq/${router.currentRoute.params.pageSlug}`, {
          headers: Gen.apiHeader(),
          params: {
            search: state.inputSearch,
          },
        })

        if (router.currentRoute.params.pageSlug === undefined) {
          router.push({name: router.currentRoute.name, params: {pageSlug: res.data.category[0].category_slug}})
        }

        commit('SET_FAQ_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

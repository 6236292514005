import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'
import router from "../../router/index"

export default {
  namespaced: true,

  state: {
    runningEventData: [],
    runEventDetails: {
      event: {
        name: '',
      }
    },
    allHotels: [],
    hotelDetails: {},
    itineraryDetails: [],
    packageDetails: {
      subPackage: [],
      package: []
    },
    scrollPositionPackage: null,
    isBtnGetSlotNow: true,
    lightboxIndex: null,
    typeOfTransaction: 'partial-payment',
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_RUNNING_EVENT_DATA(state, data) {
      state.runningEventData = data
    },
    SET_RUN_EVENT_DETAILS(state, data) {
      state.runEventDetails = data
    },
    DEFAULT_ONE_TIME_PAYMENT(state, data) {
      state.typeOfTransaction = data
    },
    SET_ALL_HOTEL(state, data) {
      state.allHotels = data
    },
    SET_ITINERARY_DETAILS(state, data) {
      state.itineraryDetails = data
    },
    SET_PACKAGE_DETAILS(state, data) {
      let {subPackage, packageData} = data
      state.packageDetails.subPackage = subPackage
      state.packageDetails.package = packageData
    },
    SET_HOTEL_DETAILS(state, data) {
      state.hotelDetails = data
    },
  },

  actions: {
    async getData({ rootState, commit }, data) {
      const { page } = data

      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/runevents`, {
          headers: Gen.apiHeader(),
          params: {
            page,
          }
        })

        commit('SET_RUNNING_EVENT_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDataDetails({ rootState, commit }, callback = false) {
      try {
        rootState.loading.screen = true

        const eventSlug = router.currentRoute.params.pageSlug

        const res = await http.get(`/public-pages/runevent/${eventSlug}`, {
          headers: Gen.apiHeader()
        })

        let resHotel = res.data.event.itineraries
        let allHotels = []
        let finalAllHotels = []

        for (const el of resHotel) {
          allHotels.push(Object.values(el.event_hotels))
        }
        for (const ea of allHotels) {
          for (let x = 0; x < ea.length; x++) {
            finalAllHotels.push(ea[x])
          }
        }

        finalAllHotels = finalAllHotels.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) => t.event_hotel_name === thing.event_hotel_name
            )
        )

        if (callback) {
          callback(res.data)
        }

        commit("SET_ALL_HOTEL", finalAllHotels)
        commit('SET_RUN_EVENT_DETAILS', res.data)
      } catch (err) {
        if (callback) {
          callback([])
        }
        
        if (err.response.status === 404) {
          return router.push({name: 'NotFound'})
        }
        return err
      } finally {
        rootState.loading.screen = false
      }
    }
  },
}

import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "../../libs/Gen"

export default {
  namespaced: true,
  state: {
    upcomingRacesData: [],
    inputAnnouncement: {
      input_fullname: '',
      input_email: '',
      input_dialcode: '',
      input_phone: '',
      input_races: []
    },
    messageResponse: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_UPCOMING_RACES_DATA(state, data) {
      state.upcomingRacesData = data
    },
    SET_MESSAGE_RESPONSE(state, data) {
      state.messageResponse = data
    },
    CLEAR_INPUT_ANNOUNCEMENT(state) {
      state.inputAnnouncement = {
        input_fullname: '',
        input_email: '',
        input_dialcode: '',
        input_phone: '',
        input_races: []
      }
    },
  },
  actions: {
    async getUpcomingRaces({ commit }) {
      try {
        const res = await http.get("public-pages/announcement-event/upcoming-race-list", {
          headers: Gen.apiHeader()
        })


        commit("SET_UPCOMING_RACES_DATA", res.data.data.data)
      } catch (err) {
        return err
      }
    },

    async registerAnnouncement({ state, rootState, commit }, callback = false) {
      const form = state.inputAnnouncement
      try {
        rootState.loading.screen = true
        const res = await Gen.apiRest(
          '/public-pages/announcement-event/register',
          {
            data: Object.assign({}, form),
          },
          'POST'
        )
        
        commit("SET_MESSAGE_RESPONSE", res.data.message)

        if (callback) {
          callback(true, res)
        }
        
        commit("CLEAR_INPUT_ANNOUNCEMENT")
        return res
      } catch (err) {
        if (callback) {
          callback(false, err)
        }
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  }

}
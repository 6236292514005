<template>
  <section
    class="running-event-section"
    :style="
      'background: linear-gradient(0deg, rgba(16,23,42,0.8786108193277311) 100%, rgba(255,255,255,0) 100%), url(' +
      uploader(isWebpSupport ? content.background_image : content.background_fallback_image) +
      ');background-size:cover;background-position:center'
    "
  >
    <div class="overlay-bg-dark"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-xl-4">
              <div class="text-white" :class="isMobile ? 'text-center d-flex flex-column align-items-center' : ''">
                <span class="sub-title-section white">
                  <div class="line-sub-title"></div>
                  {{ content.sub_title }}
                </span>
                <h2 class="title-section-left mt-3">{{ content.title }}</h2>
                <span class="color-gray regular"
                  >{{ content.description }}</span
                >
              </div>
              <div class="mt-4" v-if="!isMobile">
                <a :href="$router.resolve({name: 'RunningEvents'}).href" class="btn btn-outline white extrabold">{{ content.button }}</a>
              </div>
            </div>
            <div class="col-xl-8" :class="isMobile ? 'mt-4' : ''">
              <template v-if="!isMobile">
                <div id="v-pills-tabContent" class="tab-content">
                  <div
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                    class="tab-pane fade show active"
                  >
                    ...
                  </div>
                  <div id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" class="tab-pane fade">
                    ...
                  </div>
                  <div
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                    class="tab-pane fade"
                  >
                    ...
                  </div>
                  <div
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                    class="tab-pane fade"
                  >
                    ...
                  </div>
                </div>
                <div id="myTabContent4" class="tab-content">
                  <div id="home4" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade show active">
                    <div :class="(data || {}).length > 2 ? 'overflow-scroll' : ''">
                      <div v-for="(v, k) in data" :key="'event-'+k" class="widget card-run-event d-flex p-cb p-0">
                        <div class="ribbon ribbon-top-left" :class="defineEventRegistrationRibbon(v.status)">
                          <span class="bold text-uppercase text-white">{{ defineEventRegistrationStatus(v.status) }}</span>
                        </div>
                        <div class="left no-height">
                          <picture>
                            <source
                              :srcset="uploader(v.image)"
                              type="image/webp"
                            />
                            <img
                              :src="uploader(v.fallback_image)"
                              :alt="v.alt_image"
                              width="351"
                              height="285"
                            />
                          </picture>
                        </div>
                        <div class="right d-flex flex-column justify-content-center p-t-1 p-b-1 p-r-1">
                          <div class="bt-1px-black">
                            <h3 class="bold">{{ v.name }}</h3>
                            <div class="d-flex flex-wrap bt-1px-black pb-2">
                              <div v-if="v.stock_by == 'I'" class="d-flex align-items-center my-2">
                                <i class="fa fa-ticket-alt gamma-color fs-1em"></i
                                ><a href=""><span class="mx-2 gamma-color semibold">{{ stockBySlug(v.slug) }} Slots Left</span></a>
                              </div>
                              <div class="d-flex align-items-center mx-2 my-2">
                                <i class="fa fa-calendar gamma-color fs-1em"></i
                                ><a href=""><span class="mx-2 gamma-color semibold">{{ formatDate(v.race_date) }}</span></a>
                              </div>
                              <div class="d-flex align-items-center my-2">
                                <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                                <span class="mx-2 gamma-color semibold">{{ v.program }}, {{ v.country }}</span>
                              </div>
                            </div>
                            <p class="mt-2 mb-1">
                              {{ v.excerpt }}
                            </p>
                          </div>
                          <div class="mt-3 d-flex justify-content-start">
                            <a
                              :href="
                                $router.resolve({
                                  name: 'RunningEvents',
                                  params: { pageSlug: v.slug },
                                }).href
                              "
                              class="btn btn-primary semibold d-flex align-items-center"
                            >
                              {{ v.status == 'E' ? 'Join Event' : 'See Details'}}
                              <i class="icon-arrow-right bold fs-125em ml-1"></i>
                            </a>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="isMobile">
                <div v-for="(v, k) in data" :key="'revent-'+k" class="widget card-run-event d-flex p-cb p-0">
                  <div class="ribbon ribbon-top-left" :class="defineEventRegistrationRibbon(v.status)">
                    <span class="bold text-uppercase text-white">{{ defineEventRegistrationStatus(v.status) }}</span>
                  </div>
                  <div class="left no-height">
                    <picture>
                      <source
                        :srcset="uploader(v.image)"
                        type="image/webp"
                      />
                      <img
                        :src="uploader(v.fallback_image)"
                        :alt="v.alt_image"
                        width="351"
                        height="285"
                      />
                    </picture>
                  </div>
                  <div class="right d-flex flex-column justify-content-center p-t-1 p-b-1 p-r-1">
                    <div class="bt-1px-black">
                      <h3 class="bold">{{ v.name }}</h3>
                      <div class="d-flex flex-wrap bt-1px-black pb-2">
                        <div class="d-flex align-items-center my-2">
                          <i class="fa fa-ticket-alt gamma-color fs-1em"></i>
                          <span class="mx-2 gamma-color semibold">{{ stockBySlug(v.slug) }} Slots Left</span>
                        </div>
                        <div class="d-flex align-items-center mx-2 my-2">
                          <i class="fa fa-calendar gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ formatDate(v.race_date) }}</span></a>
                        </div>
                        <div class="d-flex align-items-center my-2">
                          <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ v.program }}, {{ v.country }}</span></a>
                        </div>
                      </div>
                      <p class="mt-2 mb-1">
                        {{ v.excerpt }}
                      </p>
                    </div>
                    <div class="mt-3 d-flex justify-content-start">
                      <a
                        :href="
                          $router.resolve({
                            name: 'RunningEventDetails',
                            params: { pageSlug: v.slug },
                          }).href
                        "
                        class="btn btn-primary semibold d-flex align-items-center"
                        :class="isOnlyMobile ? 'w-100 justify-content-center' : ''"
                      >
                        Join Event
                        <i class="icon-arrow-right bold fs-125em ml-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </template>
              <div class="text-center" v-if="isMobile">
                <a :href="$router.resolve({name: 'RunningEvents'}).href" class="mt-4 btn btn-outline white">{{ content.button }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'RunningEventsAtom',
  components: {
    carousel,
  },
  props: {
    isMobile: {
      type: Boolean,
    },
    isOnlyMobile: {
      type: Boolean,
    },
    isOpenMenu: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    content: {
      type: Object,
    },
    data: {
      type: Array,
    },
    stock: {
      type: Array,
    },
    uploader: {
      type: Function,
    },
    isWebpSupport: {
      type: Boolean,
    },
    formatDate: {
      type: Function,
    },
  },
  created() {},
  methods: {
    stockBySlug(slug) {
      const data = (this.stock || [])
        .filter(s => s.slug === slug)
        .map(s => s.stock);

      return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },
    defineEventRegistrationStatus(value) {
      switch (value) {
        case 'U':
          return (this.content.running_events || {}).future
          break;
        
        case 'E':
          return (this.content.running_events || {}).present
          break;

        case 'C':
          return (this.content.running_events || {}).past
          break;

        default:
          return ''
      }
    },
    defineEventRegistrationRibbon(value) {
      switch (value) {
        case 'U':
          return 'yellow'
          break;
        
        case 'E':
          return 'green'
          break;

        case 'C':
          return 'blue'
          break;

        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.overflow-scroll {
  height: 92.5vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: -1px 19px 66px -44px rgba(255, 255, 255, 0.75);
  &::-webkit-scrollbar {
    display: none;
  }
}

.overflow-scroll::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
  margin-left: 10px;
}

.overflow-scroll::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
}

.overflow-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #737272;
  border: 1px solid #000;
  margin-left: 10px;
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <section class="general-form-section" :class="isTablet() ? 'h-auto' : ''">
        <div class="row no-gutters">
          <div class="col-lg-7" :class="isMobile() ? 'order-5' : ''">
            <div
              class="left-section without-justify-content"
              :class="
                !isMobile()
                  ? 'ov-scroll scroll-bar-hidden p-r-100 p-l-100'
                  : isTablet()
                  ? 'd-block h-auto'
                  : 'mt-5 pt-0 px-3'
              "
            >
              <div>
                <step-progress
                  :steps="mySteps"
                  :current-step="currentStep"
                  icon-class="fa fa-check"
                  active-color="#95378E"
                  :active-thickness="3"
                  :passive-thickness="3"
                  :line-thickness="3"
                ></step-progress>
              </div>

              <ValidationObserver v-slot="{ passes }" ref="WWUHotelsForm">
                <b-form ref="WWUHotelsFormElement" @submit.prevent="passes(nextStep)">
                  <div v-if="currentStep == 0">
                    <h4 class="extrabold">General Information</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <span class="sub-title-section lg">
                            <div class="line-sub-title"></div>
                            Contact Information
                          </span>
                          <div class="form-group mt-3">
                            <label for="" class="required bold">Email Address</label>
                            <b-badge
                              variant="info"
                              class="help-badge custom-badge-info ml-3"
                              v-b-tooltip.hover.right="'We need an email so we can contact you'"
                              id="email-tooltip"
                              pill
                              @click.prevent
                            >
                              <b-icon icon="question" class="question-icon" font-scale="1"></b-icon>
                            </b-badge>
                            <input
                              type="email"
                              v-model="inputForm.input_email_contact"
                              class="form-control main-control"
                              placeholder="Email Address"
                            />
                            <VValidate name="Email Address" v-model="inputForm.input_email_contact" :rules="{required: true, max:96, email:true}" vid="ci-email-addr" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <span class="sub-title-section lg">
                            <div class="line-sub-title"></div>
                            Hotel Detail
                          </span>
                          <div class="form-group mt-3">
                            <label for="" class="required bold">Hotel Name</label>
                            <input
                              type="text"
                              v-model="inputForm.input_hotel_name"
                              class="form-control main-control"
                              placeholder="Hotel Name"
                            />
                            <VValidate name="Hotel Name" v-model="inputForm.input_hotel_name" :rules="{required: true, min:3, max:128}" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Describe Your Hotel</label>
                            <textarea
                              type="text"
                              v-model="inputForm.input_hotel_describe"
                              class="form-control main-control text-area"
                              placeholder="Why should our runner choose to stay at your hotel?"
                            ></textarea>
                            <VValidate name="Describe Your Hotel" v-model="inputForm.input_hotel_describe" :rules="{required: true, min:10}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Number of Floors</label>
                            <div class="input-group input-group-main mb-3">
                              <input
                                type="text"
                                v-model="inputForm.input_number_of_floors"
                                class="form-control group-item append main-control"
                                placeholder="e.g. 8"
                                :formatter="v => v.replace(/\D/g, '')"
                              />
                              <div class="input-group-append mt-3">
                                <span class="input-group-text regular" id="basic-addon2">floors</span>
                              </div>
                            </div>
                            <VValidate name="Number of Floors" v-model="inputForm.input_number_of_floors" :rules="{required: true, min_value:1, max:3}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Number of Rooms</label>
                            <div class="input-group input-group-main mb-3">
                              <input
                                type="text"
                                v-model="inputForm.input_number_of_rooms"
                                class="form-control group-item append main-control"
                                placeholder="e.g. 500"
                                :formatter="v => v.replace(/\D/g, '')"
                              />
                              <div class="input-group-append mt-3">
                                <span class="input-group-text regular" id="basic-addon2">rooms</span>
                              </div>
                            </div>
                            <VValidate name="Number of Rooms" v-model="inputForm.input_number_of_rooms" :rules="{required: true, min_value:1, max: 3}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Main Currency</label>
                            <select v-model="inputForm.input_main_currency" class="form-select main-control mt-3">
                              <option value="">-- Select Currency --</option>
                              <option v-for="(v, k) in mrData.mrCurrency" :key="k" :value="v">{{ v }}</option>
                            </select>
                            <VValidate name="Main Currency" v-model="inputForm.input_main_currency" :rules="{required: true}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Cancellation Policy</label>
                            <div class="d-flex flex-wrap">
                              <b-form-radio
                                v-for="(v,k) in mrData.mrCancellationPolicy"
                                :key="k"
                                v-model="inputForm.input_cancellation_policy"
                                class="mr-3"
                                :class="!isMobile() ? 'w-45 my-4' : 'w-100 my-1'"
                                name="cancellation-policy"
                                :value="v"
                              >
                                {{ v.name }}
                                <div>
                                  {{ v.description }}
                                </div>
                              </b-form-radio>
                            </div>
                            <VValidate name="Cancellation Policy" v-model="inputForm.input_cancellation_policy" :rules="{required: true}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <span class="sub-title-section lg">
                            <div class="line-sub-title"></div>
                            Useful Information
                          </span>
                          <div class="row mt-3">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="" class="required bold">Distance From City</label>
                                <div class="input-group input-group-main mb-3">
                                  <input
                                    type="text"
                                    v-model="inputForm.input_distance_from_city"
                                    class="group-item append form-control main-control"
                                    placeholder="e.g. 100"
                                    :formatter="v => v.replace(/\D/g, '')"
                                  />
                                  <div class="input-group-append mt-3">
                                    <span class="input-group-text regular" id="basic-addon2">meters</span>
                                  </div>
                                </div>
                                <VValidate name="Distance From City" v-model="inputForm.input_distance_from_city" :rules="{required: true, max: 6}" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="" class="required bold">Distance From Airport</label>
                                <div class="input-group input-group-main mb-3">
                                  <input
                                    type="text"
                                    v-model="inputForm.input_distance_from_airport"
                                    class="form-control group-item append main-control"
                                    placeholder="e.g. 100"
                                     :formatter="v => v.replace(/\D/g, '')"
                                  />
                                  <div class="input-group-append mt-3">
                                    <span class="input-group-text regular" id="basic-addon2">meters</span>
                                  </div>
                                </div>
                                <VValidate name="Distance From Airport" v-model="inputForm.input_distance_from_airport" :rules="{required: true, max: 6}" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-3">
                          <span class="sub-title-section lg">
                            <div class="line-sub-title"></div>
                            Service
                          </span>
                          <div class="row mt-3">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="" class="required bold">Reception Area</label>
                                <div class="d-flex mt-3">
                                  <b-form-radio 
                                    v-model="inputForm.input_reception_area" 
                                    name="receptionarea" 
                                    :value="v" 
                                    v-for="(v,k) in mrData.mrReceptionArea" 
                                    :key="k"
                                    class="mr-3"
                                  >{{ v }}</b-form-radio
                                  >
                                </div>
                                <VValidate name="Reception Area" v-model="inputForm.input_reception_area" :rules="{required: true}" />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group mt-2">
                                <label class="required bold">When Can Guests Check In?</label>
                                <div class="row align-items-center">
                                  <div class="col-auto">
                                    <input
                                      type="time"
                                      v-model="inputForm.input_checkin_time_start"
                                      class="form-control mr-3 main-control"
                                      placeholder="Start"
                                    />
                                    <VValidate name="Check-In Start" v-model="inputForm.input_checkin_time_start" :rules="{required: true}" />
                                  </div>
                                  <div class="col-1">
                                    <span>until</span>
                                  </div>
                                  <div class="col-auto">
                                    <input
                                      type="time"
                                      v-model="checkinEndTime"
                                      class="form-control mr-3 main-control"
                                      placeholder="Start"
                                    />
                                  </div>
                                  <VValidate name="Check-In End" v-model="inputForm.input_checkin_time_end" :rules="{required: true}" />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group mt-2">
                                <label class="required bold">Guest Can Check Out Until</label>
                                <div class="row align-items-center">
                                  <div class="col-auto">
                                    <input
                                      type="time"
                                      v-model="inputForm.input_checkout_time"
                                      class="form-control mr-3 main-control"
                                      placeholder="Time"
                                    />
                                    <VValidate name="Check-Out" v-model="inputForm.input_checkout_time" :rules="{required: true}" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group mt-2">
                                <label for="" class="required bold"
                                  >Additional Breakfast Charge (Exclude Room Rate)</label
                                >
                                <div class="input-group input-group-main mb-3">
                                  <div class="input-group-append mt-3">
                                    <span class="bdrd-l-24 input-group-text regular" id="basic-addon2">IDR</span>
                                  </div>
                                  <b-form-input
                                    type="text"
                                    :formatter="v => v.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')"
                                    v-model="inputForm.input_breakfast_charge"
                                    class="form-control group-item main-control"
                                    placeholder="e.g. 100.000"
                                  />
                                </div>
                                <VValidate name="Breakfast Charge" v-model="inputForm.input_breakfast_charge" :rules="{required: true, max: 10}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="currentStep == 1">
                    <h4 class="extrabold">Locations</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Country</label>
                            <v-select
                              v-if="mrData.mrCountry"
                              :options="mrData.mrCountry"
                              placeholder="-- Select Country --"
                              :reduce="(v) => v.country_name"
                              v-model="inputForm.input_country"
                              id="countrycode"
                              label="country_name"
                              class="mt-3"
                            ></v-select>
                            <VValidate name="Country" v-model="inputForm.input_country" :rules="{required: true}" />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="" class="required bold">State</label>
                            <input
                              type="text"
                              v-model="inputForm.input_state"
                              class="form-control main-control"
                              placeholder="e.g. Germany"
                            />
                            <VValidate name="State" v-model="inputForm.input_state" :rules="{required: true, max: 64}" />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="" class="required bold">City</label>
                            <input
                              type="text"
                              v-model="inputForm.input_city"
                              class="form-control main-control"
                              placeholder="e.g. Berlin"
                            />
                            <VValidate name="City" v-model="inputForm.input_city" :rules="{required: true, max: 64}" />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="" class="required bold">Postal Code</label>
                            <input
                              type="text"
                              v-model="inputForm.input_postal_code"
                              class="form-control main-control"
                              placeholder="e.g. 12345"
                            />
                            <VValidate name="Postal Code" v-model="inputForm.input_postal_code" :rules="{required: true, max: 11}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Street Address 1</label>
                            <input
                              type="text"
                              v-model="inputForm.input_street_address1"
                              class="form-control main-control"
                              placeholder="Street Address 1"
                            />
                            <VValidate name="Street Address 1" v-model="inputForm.input_street_address1" :rules="{required: true, max: 254}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Sreet Address 2 <span class="small">(optional)</span></label>
                            <input type="text" class="form-control main-control" v-model="inputForm.input_street_address2" placeholder="Street Address 2" />
                            <VValidate name="Street Address 2" v-model="inputForm.input_street_address2" :rules="{max: 254}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Street Address 3 <span class="small">(optional)</span></label>
                            <input type="text" class="form-control main-control" v-model="inputForm.input_street_address3" placeholder="Street Address 3" />
                            <VValidate name="Street Address 3" v-model="inputForm.input_street_address3" :rules="{max: 254}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Google Maps Url</label>
                            <input type="text" class="form-control main-control" v-model="inputForm.input_url_maps" placeholder="https://" />
                            <VValidate name="Google Maps Url" v-model="inputForm.input_url_maps" :rules="{required: true, max: 300}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Official Website Url</label>
                            <input type="text" class="form-control main-control" v-model="inputForm.input_hotel_url_website" placeholder="https://" />
                            <VValidate name="Official Website Url" v-model="inputForm.input_hotel_url_website" :rules="{max: 300}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="currentStep == 2">
                    <h4 class="extrabold">Facilities</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12 my-4">
                          <h5 class="bold">Common</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-common"
                            name="common"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrCommonFacilities"
                            v-model="inputForm.input_common_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>

                        <div class="col-md-12 my-4">
                          <h5 class="bold">Access</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-access"
                            name="access"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrAccessFacilities"
                            v-model="inputForm.input_access_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>

                        <div class="col-md-12 my-4">
                          <h5 class="bold">Business</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-business"
                            name="business"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrBusinessFacilities"
                            v-model="inputForm.input_business_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>

                        <div class="col-md-12 my-4">
                          <h5 class="bold">Facilities</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-area"
                            name="area"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrAreaFacilities"
                            v-model="inputForm.input_area_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>

                        <div class="col-md-12 my-4">
                          <h5 class="bold">Inside Room</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-insideroom"
                            name="insideroom"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrInsideRoomFacilities"
                            v-model="inputForm.input_inside_room_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>

                        <div class="col-md-12 my-4">
                          <h5 class="bold">Nearby Amenities</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-amenities"
                            name="nearbyamenities"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrNearbyAmenities"
                            v-model="inputForm.input_nearby_amenities_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>

                        <div class="col-md-12 my-4">
                          <h5 class="bold">Payment Information</h5>
                          <b-form-checkbox-group
                            id="checkbox-group-payment"
                            name="payme"
                            class="checkbox with-width-150 mt-2"
                            :options="mrData.mrPaymentInformation"
                            v-model="inputForm.input_payment_information_facilities"
                          >
                          </b-form-checkbox-group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="currentStep == 3">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="extrabold">Rooms</h4>
                    </div>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="card-room b-grey-black p-3 bdrd-24px d-flex justify-content-between mt-4 position-relative"
                            v-for="(v, k) in inputForm.input_rooms"
                            :key="k"
                          >
                            <div>
                              <p class="mb-0 bold text-dark">{{ v.room_name }}</p>
                              <span class="grey-color">{{ v.room_type }} - {{ v.bed_type }}</span
                              ><br />
                              <div class="mt-3">
                                Room Rate: {{ v.room_rate }} ({{
                                  v.is_breakfast_included == 'Y' ? 'Include Breakfast' : 'Exclude Breakfast'
                                }})
                              </div>
                            </div>
                            <div>
                              <a href="javascript:;" class="wrap-delete-cta" @click.prevent="deleteRoom(k)">
                                <i class="icon-trash-2 fs-125em"></i>
                              </a>
                            </div>
                          </div>
                          <VValidate name="Hotel Rooms" v-model="inputForm.input_rooms" :rules="{required: true}" />
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 mb-4 pointer" @click="openModalAddRoom">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="card-room b-grey-black p-3 bdrd-24px d-flex justify-content-center align-items-center"
                          >
                            <span><i class="icon-plus fs-125em"></i></span>
                            <span class="ml-1">Add Room Type</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="currentStep == 4">
                    <h4 class="extrabold">Photos</h4>
                    <div class="mt-3 mb-3">
                      <div class="row wrap-hotel-photos">
                        <div class="col-md-4 mb-3 position-relative" v-for="(v, k) in inputForm.input_gallery" :key="k">
                          <a
                            v-if="v['img']"
                            class="wrap-view-cta"
                            :href="uploader(v.img)"
                            data-fancybox="gallery"
                          >
                            <i class="icon-eye fs-125em"></i>
                          </a>
                          <a href="javascript:;" class="wrap-delete-cta" @click.prevent="deletePhoto(k)">
                            <i class="icon-trash-2 fs-125em"></i>
                          </a>
                          <label :for="'uploadPhoto-'+k" class="d-block pointer">
                            <div
                              class="add-component b-grey-black bdrd-24px d-flex justify-content-center align-items-center"
                              :class="!v['img'] ? 'bg-grey' : ''"
                            >
                              <div class="d-flex flex-column align-items-center">
                                <i class="icon-upload fs-125em"></i>
                                <span class="regular d-block mt-2">{{
                                  v['img'] ? 'Update Photo' : 'Upload Photo'
                                }}</span>
                                <span v-if="!v['img']" class="d-block regular">Max file size: 2 MB</span>
                                <ValidationProvider name="Photo" rules="required" ref="fileGallery" v-slot="{ validate, errors }">
                                  <input
                                    ref="fileGallery"
                                    @change="pickFile($event, 'filegallery', 'fileGallery', k)" 
                                    :id="'uploadPhoto-'+k" 
                                    type="file" 
                                    accept=".jpg,.jpeg,.png"
                                    class="d-none" 
                                  />
                                  <b-form-text class="px-3 text-center"> Format accepted: .jpg, .jpeg, .png </b-form-text>
                                  <b-form-text class="px-3 text-center" text-variant="danger">{{ errors[0] }}</b-form-text>
                                </ValidationProvider>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div
                          v-if="inputForm.input_gallery.length < photo_limit"
                          class="col-md-4 mb-3"
                          @click.prevent="addPhoto"
                        >
                          <div
                            class="add-component pointer b-grey-black bdrd-24px d-flex justify-content-center align-items-center"
                          >
                            <div class="d-flex flex-column align-items-center">
                              <i class="icon-plus fs-125em"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-column justify-content-between align-items-center mt-3">
                    <button v-if="currentStep != 4" class="btn btn-slg btn-primary bold w-100">Next</button>
                    <button v-else class="btn btn-slg btn-primary bold w-100">Send</button>
                    <a v-if="currentStep != 0" @click="previousStep" class="text-link medium pointer mt-3"
                      >Back to previous step</a
                    >
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
          <div class="col-lg-5 hotel-hero">
            <div
              class="right-section h-100 background-hero-img"
              :style="sideBackground"
              :class="isMobile() ? '' : 'h-100'"
            >
              <div class="bg-overlay" data-style="13"></div>
              <div>
                <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                  <div>
                    <router-link :to="{ name: 'Home' }">
                      <picture>
                        <source
                          media="(min-width: 992px)"
                          :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                          type="image/webp"
                        />
                        <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                        <img
                          class="brand-logo"
                          v-if="isOnlyMobile()"
                          :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="142"
                          height="43"
                        />
                        <img
                          class="brand-logo"
                          v-else
                          :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="175"
                          height="53"
                        />
                      </picture>
                    </router-link>
                  </div>
                  <div class="mt-5">
                    <div class="label-custom green d-inline">
                      <span class="black">Work With Us</span>
                    </div>
                    <div class="title-content mt-4">
                      <h2 class="text-white">Join Us For</h2>
                      <p style="color: #F7AF2F">Hotel accommodation</p>
                    </div>
                    <span class="text-white bold d-block mt-3">Provide our best runners with comfort and contemporary hotel accommodations for a memorable stay</span>
                  </div>
                  <div class="mt-5">
                    <router-link :to="{ name: 'WWULPHotels' }" class="text-white text-underline bold"
                      >Back to Previous Page</router-link
                    >
                    <div
                      v-if="!isMobile()"
                      class="d-flex justify-content-between"
                      :class="isOnlyMobile() ? 'flex-column text-center' : ''"
                    >
                      <div class="copyright-text text-white mt-5">
                        Copyright &copy; 2023 RunDeGlobe. All Rights Reserved
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <AppAlert
      ref="modal-error"
      vref="modal-error"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
      }"
      icon-size="w-50"
      :title="messageErrTitle"
      :description="messageErrDescription"
      :button="{
        main: {
          text: 'Ok',
        },
      }"
    />

    <AppModal :withPageTitle="true" :isFooter="true" ref="modal-add-room" :type="'lg'" @close="onCloseAddRoomTypeModal">
      <template #title-modal>
        <h5>Add Room Type</h5>
      </template>
      <template #content-modal>
        <ValidationObserver v-slot="{ handleSubmit }" ref="AddRoomForm">
          <b-form ref="AddRoomFormElement" @submit.prevent="handleSubmit(addRoom)">
            <div class="row">
              <div class="col-md-12">
                <span class="sub-title-section lg">
                  <div class="line-sub-title"></div>
                  Room Detail
                </span>
              </div>
              <div class="col-md-12 my-3">
                <div class="form-group">
                  <label for="roomName" class="required bold">Room Name</label>
                  <input
                    id="roomName"
                    type="text"
                    v-model="objRoom.room_name"
                    class="form-control main-control"
                    placeholder="e.g. Double Room"
                  />
                  <VValidate name="Room Name" v-model="objRoom.room_name" :rules="{required: true, max:64}" />
                </div>
              </div>
              <div class="col-md-12">
                <span class="sub-title-section lg">
                  <div class="line-sub-title"></div>
                  Room Specification
                </span>
              </div>
              <div class="col-md-6 my-3">
                <div class="form-group">
                  <label class="required bold">Room Type</label>
                  <select v-model="objRoom.room_type" class="form-select main-control mt-3">
                    <option value="">-- Select Room Type --</option>
                    <option v-for="(v, k) in roomType" :key="k" :value="v.name">{{ v.name }}</option>
                  </select>
                  <VValidate name="Room Type" v-model="objRoom.room_type" :rules="{required: true}" />
                </div>
              </div>
              <div class="col-md-6 my-3">
                <div class="form-group">
                  <label class="required bold">Bed Type</label>
                  <select v-model="objRoom.bed_type" class="form-select main-control mt-3">
                    <option value="">-- Select Bed Type --</option>
                    <option v-for="(v, k) in bedType" :key="k" :value="v.name">{{ v.name }}</option>
                  </select>
                  <VValidate name="Bed Type" v-model="objRoom.bed_type" :rules="{required: true}" />
                </div>
              </div>
              <div class="col-md-12 mt-1 mb-3">
                <div class="form-group">
                  <label for="maxOccupancy" class="required bold">Maximum Occupancy</label>
                  <div class="input-group input-group-main mb-3">
                    <b-form-input
                      id="maxOccupancy"
                      type="text"
                      :formatter="v => v.replace(/\D/g, '')"
                      v-model="objRoom.max_occupancy"
                      class="form-control group-item append main-control"
                      placeholder="e.g. 2"
                    />
                    <div class="input-group-append mt-3">
                      <span class="input-group-text regular" id="basic-addon2">person(s)</span>
                    </div>
                  </div>
                </div>
                <VValidate name="Maximum Occupancy" v-model="objRoom.max_occupancy" :rules="{required: true, max: 2}" />
              </div>
              <div class="col-md-12 mt-1 mb-3">
                <div class="form-group">
                  <label for="roomRate" class="required bold">Room Rate</label>
                  <div class="input-group input-group-main mb-3">
                    <div class="input-group-append mt-3">
                      <span class="bdrd-l-24 input-group-text regular" id="basic-addon2">IDR</span>
                    </div>
                    <b-form-input
                      id="roomRate"
                      type="text"
                      :formatter="v => v.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')"
                      v-model="objRoom.room_rate"
                      class="form-control group-item main-control"
                      placeholder="e.g. 100.000"
                    />
                  </div>
                  <VValidate name="Room Rate" v-model="objRoom.room_rate" :rules="{required: true, max: 10}" />
                </div>
              </div>

              <div class="col-md-6 mt-1 mb-3">
                <div class="form-group">
                  <label class="required bold">Breakfast Included?</label>
                  <div class="d-flex">
                    <b-form-radio
                      v-model="objRoom.is_breakfast_included"
                      class="mt-4 mr-3"
                      name="breakfast-included"
                      value="Y"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="objRoom.is_breakfast_included"
                      class="mt-4 mr-3"
                      name="breakfast-included"
                      value="N"
                    >
                      No
                    </b-form-radio>
                  </div>
                  <VValidate name="Breakfast Included" v-model="objRoom.is_breakfast_included" :rules="{required: true}" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-slg btn-primary bold" type="submit" :class="isMobile() ? 'w-100 mb-4' : ''">
                Save Room
              </button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
      <template #footer-modal> </template>
    </AppModal>

    <AppModal :withPageTitle="false" ref="confirm-data-modal" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img
                :src="`${baseUrl}/assets/images/vector/confirm-vect.svg`"
                alt="Confirmation"
                class="w-25"
              />
              <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
              <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
              <ul>
                <li>Email address: {{ inputForm.input_email_contact || 'abraham.damar@example.com' }}</li>
              </ul>
              <div class="d-flex">
                <button type="button" class="btn btn-outline-main ml-0 bold" @click="onChangeData">Edit data</button>
                <button type="button" class="btn btn-primary bold" @click="onConfirmData">Confirm data</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="false" ref="modal-confirmation-gallery" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/newsletter-vect.svg`" alt="Confirmation" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Is the photo you have entered correct?</p>
              <span class="regular fs-1em lh-125"
                >Please enter the correct image so that the data we receive is valid.</span
              >
              <div class="d-flex">
                <button
                  @click="cancelUpload"
                  class="btn btn-outline-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Cancel
                </button>
                <button
                  @click="submitGallery"
                  class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Yes, Continue Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src  
import StepProgress from 'vue-step-progress'
import Global from '../libs/Global.vue'

import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import AppModal from '../components/AppModal.vue'
import AppAlert from '@/components/AppAlert'

import 'vue-step-progress/dist/main.css'

import '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

import Gen from '@/libs/Gen'

import axios from 'axios'

export default {
  extends: Global,
  name: 'WWUHotels',
  components: {
    'step-progress': StepProgress,
    AppModal,
    AppAlert,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.isSubmitting) {
      if (confirm('Are you sure you want to leave? Your changes may not be saved.')) {
        this.isDirty = false
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  data() {
    return {
      isWebPSupport: false,
      isLoaded: false,
      isSubmitting: false,
      isDirty: false,
      messageErrTitle: '',
      messageErrDescription: '',
      bedType: [
        {
          name: '2 Twin Beds',
        },
        {
          name: '1 Queen Size',
        },
        {
          name: '1 King Size',
        },
      ],
      roomType: [
        {
          name: 'Single',
        },
        {
          name: 'Double',
        },
        {
          name: 'Twin',
        },
        {
          name: 'Triple',
        },
        {
          name: 'Standard',
        },
        {
          name: 'Deluxe',
        },
        {
          name: 'Executive',
        },
        {
          name: 'Superior',
        },
        {
          name: 'Junior Suite',
        },
        {
          name: 'Suite',
        },
        {
          name: 'Studio Room',
        },
      ],
      mySteps: ['1', '2', '3', '4', '5'],
      currentStep: 0,
      isSelectedCertified: 'N',
      tabsOverflow: 0,
      objRoom: {
        room_name: '',
        room_type: '',
        bed_type: '',
        max_occupancy: '',
        room_rate: '',
        is_breakfast_included: '',
      },
      form: {
        wwuh_hotel_name: '',
        wwuh_describe_hotel: '',
        wwuh_number_floors: '',
        wwuh_number_rooms: '',
        wwuh_main_currency: '',
        wwuh_cancellation_policy: '',
        wwuh_distance_from_city: '',
        wwuh_distance_from_airport: '',
        wwuh_receptionist_area: '',
        wwuh_check_in: {
          start: null,
          end: null,
        },
        wwuh_breakfast_charge: '',
        wwuh_country: '',
        wwuh_state: '',
        wwuh_city: '',
        wwuh_postal_code: '',
        wwuh_street_address_1: '',
        wwuh_street_address_2: '',
        wwuh_street_address_3: '',
        wwuh_google_maps: '',
        wwuh_rooms: [],
        wwuh_photos: [],
      },
      photo_limit: 12,
      conf: {
        rule_type: 'jpg,jpeg,png',
        max_image_size: 3000000,
      },
      fileType: null,
      fileData: {
        filegallery: null,
      },
      previewPhotos: [],
      currentIndex: null
    }
  },
  computed: {
    ...mapFields("useWWUHotelStore", [
      "WWUHotelData",
      "inputForm",
      "mrData"
    ]),
    ...mapFields(['channels']),
    checkinEndTime: {
      get() {
        return (this.inputForm || {}).input_checkin_time_end
      },
      set(newValue) {
        (this.inputForm || {}).input_checkin_time_end = newValue
      }
    },
    isLastStep() {
      return this.currentStep === this.mySteps.length - 1
    },
    sideBackground() {
      let src = ''

      if (this.isWebPSupport) {
        src = this.uploader(((this.WWUHotelData || {}).content || {}).banner_img_section)
      } else {
        src = this.uploader(((this.WWUHotelData || {}).content || {}).fallback_banner_img_section)
      }

      if (src == '') {
        return null
      }

      return `background: url("${src}") center center / cover;`
    },
  },
  created() {
    this.getDataContent()
    this.getAllMasterForm()
    this.getChannels()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions("useWWUHotelStore", [
      "getDataContent",
      "getAllMasterForm",
      "applyHotel"
    ]),
    ...mapActions(['getChannels']),

    onCloseAddRoomTypeModal() {
      this.objRoom = {
        room_name: '',
        room_type: '',
        bed_type: '',
        max_occupancy: '',
        room_rate: '',
        is_breakfast_included: '',
      }
      setTimeout(() => {
        this.$refs['AddRoomForm'].reset()
      }, 500)
    },

    addOneHour(currTime) {
      const [hours, minutes] = currTime.split(':');
      const date = new Date();
      date.setHours(parseInt(hours, 10) + 1);
      date.setMinutes(parseInt(minutes, 10));
      return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    },

    async pickFile(e, dataToBeBound, id, index) {
      this.$refs[id][1].validate(e)
      
      this.$refs["modal-confirmation-gallery"].show()

      if (e.target.value === '') {
        return
      }

      const input = this.$refs[id]
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }

      this.fileData[dataToBeBound] = e.target.files[0]

      this.conf.fileType = (this.fileData[dataToBeBound] || {}).type

      this.fileType = ((this.fileData[dataToBeBound] || {}).name || '')
        .substr(((this.fileData[dataToBeBound] || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()

      this.currentIndex = index
    },

    async submitGallery() 
    {
      const filegallery = await this.uploadFormFile('filegallery')
      const currentIndex = this.currentIndex

      const obj = {
        "img": filegallery.data.pathfile,
        "fallback_img": filegallery.data.fallback_pathfile,
        "alt_img": filegallery.data.ori_filename
      }

      this.inputForm.input_gallery[currentIndex] = obj

      this.$refs["modal-confirmation-gallery"].hide()

      this.currentIndex = null

      this.addPhoto()
    },

    uploadFormFile(dataToBeBound) {
      // Validation
      this.error = false

      if (this.fileData[dataToBeBound] === null) {
        this.messageErrTitle = `File is required.`
        this.$refs['modal-error'].show()
        return null
      }

      if (this.conf.rule_type.indexOf(this.fileType) == -1) {
        this.error = `File type must be ${this.conf.rule_type} type.`
      }

      if (this.fileData[dataToBeBound] !== null) {
        if (this.fileData[dataToBeBound].size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          return alert('File too big (> 5MB)')
        }
        if (this.error) {
          return alert(this.error)
        }

        const path = 'galleryhotelapplicants'

        const formData = new FormData()

        formData.set('file', this.fileData[dataToBeBound])

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },

    cancelUpload() {
      this.conf.fileType = ""
      this.fileType = ""
      this.fileData.filegallery = null
      this.$refs["modal-confirmation-gallery"].hide()
    },

    onPhotoChange(k) {
      this.previewPhotos.push(true)
    },

    addPhoto() {
      if (this.inputForm.input_gallery.length > this.photo_limit - 1) {
        return null
      }

      this.inputForm.input_gallery.push(
        {
          "img": null,
          "fallback_img": null,
          "alt_img": null
        }
      )
    },

    deletePhoto(k) {
      this.inputForm.input_gallery.splice(k, 1)
    },

    onChangeData() {
      this.$refs['confirm-data-modal'].hide()
    },

    async onConfirmData() {
      this.isSubmitting = true
      // this.$router.push({ name: 'ConfirmWWUHotels', params: { pageSlug: 'RDG-01230307' } })
      this.applyHotel({
        form: this.inputForm,
        callback: (success, res) => {
          this.$store.state.loading.screen = false

          this.$router.push({ name: 'ConfirmWWUHotels' })
          this.isSubmitting = false
        }
      })
    },

    scrollToLeftSection() {
      const el = document.querySelector('.left-section')

      if (el) {
        const elPosition = el.getBoundingClientRect().top
        const offsetPosition = elPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition - 0,
          behavior: 'smooth',
        })
      }
    },

    scrollToTop() {
      const scrollPosition = document.documentElement.scrollTop

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - (scrollPosition / 8))
      }
    },

    nextStep() {
      if (this.isLastStep) {
        return this.$refs['confirm-data-modal'].show()
      }

      if (this.mySteps.length != this.currentStep + 1) {
        this.currentStep++
      }

      document.querySelector('.ov-scroll').scrollTo({top: 0, behavior: 'smooth'})
    },

    previousStep() {
      if (this.currentStep != 0) {
        this.currentStep--
      }
      this.scrollToLeftSection()
      const el = (document.querySelector('.left-section').scrollTop -= 500)
      this.tabsoverflow = el
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    openModalAddRoom() {
      this.$refs['modal-add-room'].show()
    },

    addRoom() {
      const a = this.inputForm.input_rooms.push(this.objRoom)

      this.$refs['modal-add-room'].hide()

      setTimeout(() => {
        this.objRoom = {}
      }, 3000)
    },

    deleteRoom(k) {
      this.inputForm.input_rooms.splice(k, 1)
    },
  },
  watch: {
    'inputForm': {
      handler() {
        if (this.isLoaded) {
          this.isDirty = true
        }

        this.isLoaded = true
      },
      deep: true,
    },
    'inputForm.input_checkin_time_end'(newTime, oldTime) {
      if (this.inputForm.input_checkin_time_start && newTime <= this.inputForm.input_checkin_time_start) {
        this.$store.commit('useWWUHotelStore/SET_CHECKIN_END_TIME', this.addOneHour(this.inputForm.input_checkin_time_start));
      }
    },
    'inputForm.input_checkin_time_start'(newTime, oldTime) {
      if (this.inputForm.input_checkin_time_end && newTime >= this.inputForm.input_checkin_time_end) {
        this.$store.commit('useWWUHotelStore/SET_CHECKIN_END_TIME', this.addOneHour(newTime));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-select.main-control {
  border-radius: 2em;
  min-height: 45px !important;
  margin-top: 1em;
  font-size: 1em !important;
  font-family: 'Metropolis-Semibold' !important;
}
.form-select.main-control::placeholder {
  color: #cccccc !important;
  opacity: 1;
  font-family: 'Metropolis-Semibold' !important;
}
.wrap-view-cta {
  position: absolute;
  display: inline-flex;
  top: -5px;
  right: 37px;
  border-radius: 50%;
  background-color: #95378e;
  border: 1px solid #95378e;
  padding: 4px;
  cursor: pointer;

  i {
    color: #fff;
  }
}

.wrap-delete-cta {
  position: absolute;
  display: inline-flex;
  top: -5px;
  right: 5px;
  border-radius: 50%;
  background-color: #ce041c;
  border: 1px solid #ce041c;
  padding: 4px;
  cursor: pointer;

  i {
    color: #fff;
  }
}

.input-group-main {
  .input-group-append {
    span {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

.add-component {
  width: 100%;
  height: 200px;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <section class="authentication-section" :class="isTablet() ? 'h-auto' : ''">
        <div class="row no-gutters">
          <div :class="isMobile() ? 'order-5 col-sm-12 reset-password-form' : 'col-lg-7'">
            <div
              class="left-section h-auto"
              :class="!isMobile() ? 'h-100 p-100' : isTablet() ? 'd-block h-auto' : 'mt-5 pt-0 px-4 h-auto'"
            >
              <h4 class="extrabold">Create A Strong Password</h4>
              <span class="grey-color"
                >Your password must be at least 8 characters and should include a combination of numbers and
                letters</span
              >
              <div class="mt-5 mb-4">
                <ValidationObserver ref="ResetPasswordForm" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(doResetPassword)">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password" class="required bold">Password</label>
                          <b-input-group class="position-relative">
                            <input
                              id="password"
                              type="password"
                              class="form-control main-control"
                              placeholder="Password"
                              v-model="formData.password"
                            />
                            <template #append>
                              <b-icon
                                v-if="!showPassword"
                                icon="eye-slash"
                                font-scale="1"
                                class="position-absolute view-password"
                                @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                              ></b-icon>
                              <b-icon
                                v-else
                                icon="eye"
                                font-scale="1"
                                class="position-absolute view-password main-color"
                                @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                              ></b-icon>
                            </template>
                          </b-input-group>
                          <VValidate name="Password" v-model="formData.password" rules="required|min:8" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="" class="required bold">Confirm Password</label>
                          <b-input-group class="position-relative">
                            <input
                              id="confirmPassword"
                              type="password"
                              class="form-control main-control"
                              placeholder="Re-type password"
                              v-model="formData.confirmPassword"
                            />
                            <template #append>
                              <b-icon
                                v-if="!showConfirmPassword"
                                icon="eye-slash"
                                font-scale="1"
                                class="position-absolute view-password"
                                @click="toggleShowPassword(showConfirmPassword, 'showConfirmPassword', 'confirmPassword')"
                              ></b-icon>
                              <b-icon
                                v-else
                                icon="eye"
                                font-scale="1"
                                class="position-absolute view-password main-color"
                                @click="toggleShowPassword(showConfirmPassword, 'showConfirmPassword', 'confirmPassword')"
                              ></b-icon>
                            </template>
                          </b-input-group>
                          <VValidate name="Confirm Password" v-model="formData.confirmPassword" rules="required|confirmed:Password" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="d-flex justify-content-between align-items-center" :class="isMobile() ? 'flex-column' : ''">
                          <button
                            type="submit"
                            class="btn btn-slg btn-primary bold"
                            :class="isMobile() ? 'w-100 mb-4' : ''"
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <div :class="isMobile() ? 'order-1 col-sm-12 reset-password-hero' : 'col-lg-5'">
            <div
              class="right-section h-100"
              :style="`
                background-image: url('${uploader('hero/resetpassword-hero.jpg')}');
                background-position: center;
                background-size: cover;
              `"
              :class="isMobile() ? '' : 'h-100'"
            >
              <div class="bg-overlay" data-style="13"></div>
              <div>
                <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                  <div>
                    <router-link :to="{ name: 'Home' }">
                      <picture>
                        <source
                          media="(min-width: 992px)"
                          :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                          type="image/webp"
                        />
                        <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                        <img
                          class="brand-logo"
                          v-if="isOnlyMobile()"
                          :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="142"
                          height="43"
                        />
                        <img
                          class="brand-logo"
                          v-else
                          :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="175"
                          height="53"
                        />
                      </picture>
                    </router-link>
                  </div>
                  <div :class="isTablet() ? 'mt-5' : ''">
                    <div class="title-content">
                      <h2 class="text-white">Reset My</h2>
                      <p style="color: #f7af2f">Account Password</p>
                    </div>
                    <span class="text-white bold">Enter a new password so you can get back into your account</span>
                  </div>
                  <div :class="isTablet() ? 'mt-5' : ''">
                    <router-link :to="{ name: 'Home' }" class="text-white text-underline bold"
                      >Back to Home</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <AppModal :withPageTitle="false" ref="modal-update-success" :type="'sm'" @close="window.location.href=$router.resolve({name: 'Login'}).href">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/confirmed-vect.svg`" alt="Confirmation" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Password reset successful!</p>
              <span class="regular fs-1em"
                >Your password has been successfully reset. You can now log in with your new password.</span
              >
              <button
                @click="closeModalUpdateSuccess"
                class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
              >
                Log In Now
              </button>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppModal from '@/components/AppModal'
import Global from '../libs/Global.vue'

import { http } from '@/config/http'
import Gen from '@/libs/Gen'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  extends: Global,
  name: 'ResetPassword',
  components: {
    AppModal,
  },
  computed: {
    ...mapFields(['channels']),
  },
  data() {
    return {
      formData: {
        password: '',
        confirmPassword: '',
      },
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  created() {
    this.validateToken()
    this.getChannels()
  },
  methods: {
    ...mapActions(['getChannels']),
     async validateToken() {
      try {
        await http.get(`/reset-password/${this.$route.query.t}`, {
          headers: {
            ...Gen.apiHeader()
          }
        })
      } catch (err) {
        return err
      }
    },

    closeModalUpdateSuccess() {
      this.$refs['modal-update-success'].hide()
      this.$router.push({ name: 'Login' }).catch(() => {})
    },

    toggleShowPassword(value, key, elementId) {
      const toggle = !value
      this.$set(this, key, toggle)
      const el = document.getElementById(elementId)
      el.type = toggle ? 'text' : 'password'
    },

    async doResetPassword() {
      try {
        const resp = await Gen.apiRest(
          `/reset-password/${this.$route.query.t}`, 
          {data: Object.assign({}, this.formData)},
          'POST'
        )

        if (resp.data.message) {
          if (resp.data.message) {
            this.$refs['modal-update-success'].show()
          }
        }
      } catch (err) {
        return err
      }
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style scoped>
.view-password {
  top: 29px;
  right: 17px;
  z-index: 5;
  cursor: pointer;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control.main-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

#password,
#confirmPassword {
  padding-right: 41px !important;
}

.main-color {
  color: #95378e;
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation: landscape) {
  .reset-password-hero {
    max-height: 87vh;
  }
}
</style>

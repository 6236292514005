<template>
  <section>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-4">
          <div :class="isMobile ? 'text-center d-flex flex-column align-items-center' : ''">
            <span class="sub-title-section">
              <div class="line-sub-title"></div>
              {{ content.sub_title }}
            </span>
            <h2 class="title-section-left mt-3">{{ content.title }}</h2>
            <span class="grey-color regular"
              >{{ content.description }}</span
            ><br />
            <a :href="$router.resolve({name: 'RunnerSays'}).href" class="btn btn-outline mt-3 black" v-if="!isMobile">{{ content.button }}</a>
          </div>
        </div>
        <div class="col-md-8" :class="isOnlyMobile ? 'text-center' : isTablet ? 'w-100' : ''">
          <div class="testimonial" :class="isMobile ? 'mt-4' : ''">
            <carousel v-if="data.length > 0" :items="1" :nav="false">
              <div v-for="(v, k) in data" :key="k" class="story-home p-0">
                <div class="row">
                  <div class="col-md-5">
                    <div class="img">
                      <picture>
                        <source :srcset="uploader(v.image)" type="image/webp">
                        <img :src="uploader(v.fallback_image)" :alt="v.alt_image" />
                      </picture>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="content d-flex flex-column justify-content-center">
                      <div class="bg-stick-green" :class="isOnlyMobile ? 'd-none' : 'bdrd-l-full'"></div>
                      <div class="right d-flex flex-column justify-content-center">
                        <p class="grey-color">
                          {{ v.excerpt }}
                        </p>
                      </div>
                      <div
                        class="ml-4 mr-1 d-flex justify-content-between align-items-center"
                        :class="isOnlyMobile ? 'flex-column' : ''"
                      >
                        <div class="d-flex flex-column" :class="isOnlyMobile ? 'align-items-center w-100' : 'w-50'">
                          <span class="extrabold d-flex"> {{ v.full_name }} </span>
                          <span class="grey-color">{{ v.occupation }} - {{ v.country }}</span>
                        </div>
                        <div
                          class="d-flex flex-column"
                          :class="isOnlyMobile ? 'align-items-center mt-2' : 'align-items-end'"
                        >
                          <a
                            :href="$router.resolve({name: 'RunnerSaysDetail', params: {pageSlug: v.slug}}).href"
                            class="btn btn-primary semibold d-flex align-items-center"
                          >
                            SEE DETAILS
                            <i class="icon-arrow-right bold fs-125em ml-1"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <template slot="next" v-if="data.length > 1">
                <div class="arrow-carousel-component right pointer">
                  <i class="icon-arrow-right"></i>
                </div>
              </template>
              <template slot="prev" v-if="data.length > 1">
                <div class="arrow-carousel-component left pointer">
                  <i class="icon-arrow-left"></i>
                </div>
              </template>
            </carousel>
          </div>
          <div class="text-center">
            <a :href="$router.resolve({name: 'RunnerSays'}).href" class="btn btn-outline mt-3 black" v-if="isMobile">{{ content.button }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'RunnerSaysAtom',
  components: {
    carousel,
  },
  props: {
    isMobile: {
      type: Boolean,
    },
    isOnlyMobile: {
      type: Boolean,
    },
    isTablet: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    content: {
      Type: Object,
    },
    data: {
      Type: Array,
    },
    uploader: {
      Type: Function,
    },
    isWebpSupport: {
      Type: Boolean,
    },
    formatDate: {
      Type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
.story-home {
  position: relative;
  .img {
    position: relative;
    width: 100%;
    height: 250px;
    background: #fff;
    border-radius: 24px;
    margin-right: 1.5rem !important;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
      @media only screen and (max-width: 768px) {
        border-radius: 0;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
    }
  }

  .content {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    height: 225px;
    background: #fff;

    @media only screen and (min-width: 768px) {
      transform: translateX(-3.62em) translateY(50%);
      position: absolute;
      top: -40%;
      padding: 1em;
    }

    @media only screen and (max-width: 768px) {
      position: relative;
      transform: translateY(-14px);
      padding: 1em 0 1em 0;
      height: auto !important;
      border-radius: 0;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      p {
        text-align: center;
      }
    }

    .bg-stick-green {
      position: absolute;
      top: 0;
      left: -1px;
      width: 20px;
      height: 100%;
      background: #159f91;

      @media only screen and (max-width: 768px) {
        width: 100%;
        height: 15px;
      }
    }

    .right {
      padding-left: 1.5em;
      @media only screen and (max-width: 768px) {
        padding: 0 1em;
      }
    }
  }
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>

      <template>
        <section class="min-h-100 mt-0 pt-3 m-b-50">
          <div class="bg-top-confirmation"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 d-flex flex-column align-items-center m-t-70">
                <div class="content-confirmation b-grey-black mt-5 px-5 py-5">
                  <div class="row">
                    <slot name="content-layout"></slot>
                    <div class="col-md-12 text-center">
                      <span class="semibold">Contact us if you have any additional questions.</span>
                      <div class="d-flex justify-content-center mt-2">
                        <a :href="`mailto:${channels.contact.email_address}`">
                          <div class="comp-social bdrd-full d-flex justify-content-center align-items-center bg-email">
                            <i class="fa fa-envelope-square text-white"></i>
                          </div>
                        </a>
                        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`">
                          <div
                            class="comp-social bdrd-full d-flex justify-content-center ml-2 align-items-center bg-wa"
                          >
                            <i class="fab fa-whatsapp text-white"></i>
                          </div>
                        </a>
                        <a v-if="[null, '', undefined].indexOf((channels.social_media.instagram || {}).url) === -1" :href="(channels.social_media.instagram || {}).url">
                          <div
                            class="comp-social bdrd-full d-flex justify-content-center align-items-center ml-2 bg-instagram"
                          >
                            <i class="icon-instagram text-white"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapFields } from 'vuex-map-fields'
import Global from '../libs/Global.vue'
import { mapActions } from 'vuex'

import Header from '../components/Header.vue'

export default {
  extends: Global,
  name: 'LayoutConfirmationPage',
  components: {
    Header,
  },
  data() {
    return {
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Running Events',
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapFields(['channels']),
  },
  created() {
    this.getChannels()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions(['getChannels']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-top-confirmation {
  background: #10172a;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55vh;
  z-index: 0;
}

.content-confirmation {
  background: #fff;
  z-index: 1;
  border-radius: 12px;
}
.min-h-100 {
  min-height: 100vh !important;
}
</style>

<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div class="wrap-document-list b-grey-black bdrd-12px p-3">
                <div class="wrap-heading bb-1px-grey pb-2 d-flex justify-content-between align-items-center">
                  <div class="wrap-title w-45">
                    <h5 class="semibold">Document List</h5>
                  </div>
                  <div class="wrap-document-cta d-flex align-items-center w-75">
                    <input
                      id="searchbar"
                      type="search"
                      class="form-control main-control mt-0 mr-2 w-75"
                      placeholder="Search Document"
                      v-model="filter.search"
                      @input="doSearch"
                    />
                    <button type="button" class="btn btn-primary w-25" @click="$refs['modal-upload-document'].show()">
                      Add New
                    </button>
                  </div>
                </div>
                <template v-if="!isLoading">
                  <template v-if="(documentData.data || []).length > 0">
                    <div
                      class="file-list main-font d-flex align-items-center border-bottom-1rem justify-content-between"
                      :class="k+1 != (documentData || {}).length ? 'bb-1px-grey py-4' : 'pt-4 pb-2'"
                      v-for="(v,k) in documentData.data"
                      :key="k"
                    >
                      <div class="d-flex align-items-center">
                        <template v-if="v.document_file_filetype == 'pdf'">
                          <picture class="d-flex">
                            <source :srcset="`${baseUrl}/assets/images/static-icon/icon-pdf.webp`" type="image/webp" />
                            <img :src="`${baseUrl}/assets/images/static-icon/icon-pdf.png`" />
                          </picture>
                        </template>
                        <template v-if="v.document_file_filetype == 'doc' || v.document_file_filetype == 'docx'">
                          <picture class="d-flex">
                            <source :srcset="`${baseUrl}/assets/images/static-icon/icon-doc.webp`" type="image/webp" />
                            <img :src="`${baseUrl}/assets/images/static-icon/icon-doc.png`" />
                          </picture>
                        </template>
                        <template v-if="v.document_file_filetype == 'png'">
                          <picture class="d-flex">
                            <source :srcset="`${baseUrl}/assets/images/static-icon/icon-png.webp`" type="image/webp" />
                            <img :src="`${baseUrl}/assets/images/static-icon/icon-png.png`" />
                          </picture>
                        </template>
                        <template v-if="v.document_file_filetype == 'jpg' || v.document_file_filetype == 'jpeg'">
                          <picture class="d-flex">
                            <source :srcset="`${baseUrl}/assets/images/static-icon/icon-jpg.webp`" type="image/webp" />
                            <img :src="`${baseUrl}/assets/images/static-icon/icon-jpg.png`" />
                          </picture>
                        </template>
                        <div class="d-flex">
                          <span class="mx-3 regular">
                            {{ v.document_file_name }}
                          </span>
                          <div class="label-custom blue d-flex align-items-start" v-if="v.document_is_admin == 'Y'">
                            <span class="black fs-065em">by Admin</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <a
                          class="text-link fw-600 text-underline mx-3"
                          :href="uploader(v.document_file)"
                          target="_blank"
                        >
                          <span>View</span>
                        </a>
                        <a
                          class="text-link fw-600 text-underline"
                          @click="openModalConfirmationDelete(v.document_id)"
                          href="javascript:;"
                        >
                          <span>Delete</span>
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                      <div class="initial-content d-flex flex-column align-items-center">
                        <div class="wrapper-illustration w-50 d-flex justify-content-center">
                          <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="document" class="w-25" />
                        </div>
                        <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No document found</h2>
                        <span>You may add your own document here, so you don't have to find them separately.</span>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="row justify-content-center">
                    <div class="col-12 mt-4 text-center">
                      <PulseLoader :loading="true" color="#333" size="14px" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto mx-auto">
              <div class="wrap-pagination mt-3 d-flex justify-content-end">
                <b-pagination
                  v-model="documentData.current_page"
                  v-if="!isLoading && documentData.data && documentData.last_page > 1" 
                  :total-rows="documentData.total" 
                  :per-page="documentData.per_page" 
                  prev-text="Prev" 
                  next-text="Next"
                  @change="doPaginate"
                  :hide-goto-end-buttons="true"
                  pills
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="sticky-100px">
            <PPHelpCenter/>
          </div>
        </div>
      </div>
    </template>

    <template #page-title>
      <div class="d-flex align-items-center">
        <a @click="$router.go(-1)" v-if="isOnlyMobile()">
          <i class="icon-chevron-left fs-175em pointer text-white mr-2"></i>
        </a>
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">My Documents</h2>
      </div>
    </template>

    <template #content-mobile>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div :class="isOnlyMobile() ? 'col-md-8' : 'col-md-12'">
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between bb-1px-grey pb-3">
                    <h5 class="bold ls-0 fs-125em">My Documents</h5>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="wrap-search">
                    <div class="form-group">
                      <b-input-group class="position-relative">
                        <template #prepend>
                          <div class="wrap-search-icon">
                            <i class="icon-search position-absolute"></i>
                          </div>
                        </template>
                        <input
                          id="searchbar"
                          type="search"
                          class="form-control main-control"
                          placeholder="Search Documents"
                          v-model="filter.search"
                          @input="doSearch"
                        />
                      </b-input-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="wrap-document-list b-grey-black bdrd-12px p-3">
                    <div class="wrap-heading d-flex justify-content-between align-items-center">
                      <div class="wrap-title">
                        <h5 class="semibold">Document List</h5>
                      </div>
                      <div class="wrap-document-cta">
                        <button type="button" class="btn btn-primary" @click="$refs['modal-upload-document'].show()">
                          Add New
                        </button>
                      </div>
                    </div>
                    <template v-if="!isLoading">
                      <template v-if="(documentData.data || []).length > 0">
                        <div
                          class="file-list bb-1px-grey py-4 main-font d-flex align-items-center border-bottom-1rem justify-content-between"
                          v-for="(v,k) in documentData.data"
                          :key="k"
                        >
                          <div class="d-flex align-items-center">
                            <template v-if="v.document_file_filetype == 'pdf'">
                              <picture class="d-flex">
                                <source :srcset="`${baseUrl}/assets/images/static-icon/icon-pdf.webp`" type="image/webp" />
                                <img :src="`${baseUrl}/assets/images/static-icon/icon-pdf.png`" />
                              </picture>
                            </template>
                            <template v-if="v.document_file_filetype == 'doc' || v.document_file_filetype == 'docx'">
                              <picture class="d-flex">
                                <source :srcset="`${baseUrl}/assets/images/static-icon/icon-doc.webp`" type="image/webp" />
                                <img :src="`${baseUrl}/assets/images/static-icon/icon-doc.png`" />
                              </picture>
                            </template>
                            <template v-if="v.document_file_filetype == 'png'">
                              <picture class="d-flex">
                                <source :srcset="`${baseUrl}/assets/images/static-icon/icon-png.webp`" type="image/webp" />
                                <img :src="`${baseUrl}/assets/images/static-icon/icon-png.png`" />
                              </picture>
                            </template>
                            <template v-if="v.document_file_filetype == 'jpg' || v.document_file_filetype == 'jpeg'">
                              <picture class="d-flex">
                                <source :srcset="`${baseUrl}/assets/images/static-icon/icon-jpg.webp`" type="image/webp" />
                                <img :src="`${baseUrl}/assets/images/static-icon/icon-jpg.png`" />
                              </picture>
                            </template>
                            <span class="mx-3 regular">
                              {{ v.document_file_name }}
                            </span>
                            <div class="label-custom blue d-flex align-items-start" v-if="v.document_is_admin == 'Y'">
                              <span class="black fs-065em">by Admin</span>
                            </div>
                          </div>
                          <div>
                            <a
                              class="text-link fw-600 text-underline mx-3"
                              :href="`${baseUrl}/assets/documents/rdg-goes-berlin.pdf`"
                              target="_blank"
                            >
                              <span>View</span>
                            </a>
                            <a
                              class="text-link fw-600 text-underline"
                              @click="openModalConfirmationDelete(1)"
                              href="javascript:;"
                            >
                              <span>Delete</span>
                            </a>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                          <div class="initial-content d-flex flex-column align-items-center">
                            <div class="wrapper-illustration w-50 d-flex justify-content-center">
                              <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="document" class="w-25" />
                            </div>
                            <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No document found</h2>
                            <span class="text-center">You may add your own document here, so you don't have to find them separately.</span>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="row justify-content-center">
                        <div class="col-12 mt-4 text-center">
                          <PulseLoader :loading="true" color="#333" size="14px" />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto mx-auto">
                  <div class="wrap-pagination mt-3 d-flex justify-content-end">
                    <b-pagination
                      v-model="documentData.current_page"
                      v-if="!isLoading && documentData.data && documentData.last_page > 1" 
                      :total-rows="documentData.total" 
                      :per-page="documentData.per_page" 
                      prev-text="Prev" 
                      next-text="Next"
                      @change="doPaginate"
                      :hide-goto-end-buttons="true"
                      pills
                    ></b-pagination>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-6 d-flex justify-content-end">
                  <div class="wrap-cta">
                    <router-link
                      :to="{
                        name: 'MyInformation',
                      }"
                      class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                      >Back</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #custom-layout>
      <AppModal :withPageTitle="true" ref="modal-upload-document" :type="'lg'" @close="resetDocumentForm">
        <template #title-modal>
          <h4 class="main-font text-uppercase">Upload Document</h4>
        </template>
        <template #content-modal>
          <ValidationObserver v-slot="{ handleSubmit }" ref="formDocument">
            <b-form class="form-sec px-1 pb-0 mb-0 main-font" @submit.prevent="handleSubmit(onUploadDocument)">
              <div class="row">
                <div class="col-md-12 border-bottom-1rem">
                  <div class="border-bottom-1rem">
                    <span
                      >Document size must be less than 3MB. Acceptable file types include .jpg, .png, .doc, .docx .pdf.</span
                    >
                  </div>
                  <div class="mt-4 mb-3">
                    <div class="content-2-column column-direction">
                      <div class="d-flex w-100" :class="!isMobile() ? 'flex-row align-items-center' : 'flex-column'">
                        <p class="w-50">Document Type <span class="color-red">*</span></p>
                        <div class="w-100">
                          <b-form-group>
                            <select class="form-select" v-model="formDocument.input_type">
                              <option value="">-- Select Document Type --</option>
                              <option value="Event">Event</option>
                              <option value="Medical Certification">Medical Certification</option>
                              <option value="Membership Community">Membership Community</option>
                              <option value="Others">Others</option>
                            </select>
                            <VValidate
                              name="Document Type"
                              v-model="formDocument.input_type"
                              :rules="{ required: true }"
                            />
                          </b-form-group>
                        </div>
                      </div>
                    </div>

                    <div class="content-2-column column-direction mt-4">
                      <div class="d-flex w-100" :class="!isMobile() ? 'flex-row align-items-center' : 'flex-column'">
                        <p class="w-50">Document File <span class="color-red">*</span></p>
                        <div class="w-100">
                          <b-form-group>
                            <ValidationProvider name="Document File" rules="required" ref="fileRunner" v-slot="{ validate, errors }">
                              <input
                                id="fileRunnerDocument"
                                type="file" 
                                ref="fileRunner"
                                class="form-control" 
                                @change="pickFile($event, 'filerunner', 'fileRunner')"
                                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                              />
                              <b-form-text> Format accepted: .jpg, .jpeg, .png, .doc, .docx, .pdf </b-form-text>
                              <b-form-text text-variant="danger">{{ errors[0] }}</b-form-text>
                            </ValidationProvider>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <button type="submit" class="btn btn-primary text-uppercase">Submit</button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </template>
      </AppModal>

      <AppAlert
        ref="modal-error"
        vref="modal-error"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
        }"
        icon-size="w-50"
        :title="messageErrTitle"
        :description="messageErrDescription"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppModal :withPageTitle="false" ref="modal-confirmation-delete-document" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/option-vect.svg`" alt="" class="w-25" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Delete the documents?</p>
                <span class="regular fs-1em">You won't be able to recover this data.</span>
                <div class="col-md-12 d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    @click="hideModal('modal-confirmation-delete-document')"
                    class="btn btn-outline-main ml-0"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    @click="onDeleteDocument"
                    class="btn btn-primary"
                    :class="isOnlyMobile() ? 'w-100' : ''"
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="false" ref="modal-upload-success" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/confirmed-vect.svg`" alt="" class="w-35-cust" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Document has been successfully uploaded</p>
                <div class="col-md-12 d-flex justify-content-center mt-2">
                  <button
                    type="button"
                    @click="hideModal('modal-upload-success')"
                    class="btn btn-primary"
                    :class="isOnlyMobile() ? 'w-100' : ''"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="false" ref="modal-delete-success" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/throw-vect.svg`" alt="" class="w-50" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Document has been successfully deleted</p>
                <div class="col-md-12 d-flex justify-content-center mt-2">
                  <button
                    type="button"
                    @click="hideModal('modal-delete-success')"
                    class="btn btn-primary"
                    :class="isOnlyMobile() ? 'w-100' : ''"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
    </template>
  </LayoutPrivatePage>
</template>

<script>
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import Global from '@/libs/Global'
import AppModal from '@/components/AppModal'
import AppAlert from '@/components/AppAlert'
import PPHelpCenter from '@/components/PPHelpCenter'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import Gen from '@/libs/Gen'

import axios from 'axios'

export default {
  extends: Global,
  name: 'MyDocuments',
  components: {
    LayoutPrivatePage,
    AppModal,
    AppAlert,
    PPHelpCenter,
    PulseLoader,
  },
  data() {
    return {
      messageErrTitle: '',
      messageErrDescription: '',
      filter: {
        search: '',
      },
      isLoading: false,
      timeoutId: null,
      inputDocument: {},
      documentTypeData: null,
      perPage: 3,
      currentPage: 1,
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
      ],
      conf: {
        rule_type: 'jpg,jpeg,png,doc,docx,pdf',
        max_image_size: 5000000,
      },
      fileType: null,
      fileData: {
        filerunner: null,
      },
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    ...mapFields(['user']),
    ...mapFields("profile", [
      "documentData",
      "documentId",
      "formDocument",
      "inputDocumentSearch"
    ])
  },
  created() {
    // this.getAllDocument()
  },
  methods: {
    ...mapActions("profile", [
      "getAllDocument",
      "deleteDocument",
      "addDocument",
      "clearDocumentForm"
    ]),
    doPaginate(page = 1) {
      this.$set(this.filter, 'page', page)

      this.$router.push({
        name: this.$route.name, 
        params: {...this.$route.params, savePosition: true}, 
        query: {...this.filter}
      }).catch(() => {})
    },
    resetDocumentForm() {
      setTimeout(() => {
        this.clearDocumentForm()
        const inputFile = document.getElementById('fileRunnerDocument')
        inputFile.value = ''
        setTimeout(() => {
          this.$refs['formDocument'].reset()
        }, 300)
      }, 300)
    },
    async onUploadDocument() {
      this.$store.state.loading.screen = true
      const runnerFile = await this.uploadFormFile('filerunner')
      this.formDocument.input_file = ((runnerFile || {}).data || {}).pathfile
      this.formDocument.input_filetype = ((runnerFile || {}).data || {}).filetype
      this.formDocument.input_filename = ((runnerFile || {}).data || {}).ori_filename
      this.formDocument.input_registrant_id = ((this || {}).user || {}).registrant_id

      this.addDocument({
        form: this.formDocument,
        callback: (success, res) => {
          this.$store.state.loading.screen = false

          if(!success) {
            return
          }
          this.$refs['modal-upload-document'].hide()
          this.$refs['modal-upload-success'].show()
          this.isLoading = true
          this.getAllDocument({
            page: this.filter.page,
            callback: () => {
              this.isLoading = false
            }
          })
          this.resetDocumentForm()
        }
      })
    },
    async onDeleteDocument() {
      const res = await this.deleteDocument()

      if(res.data.message) {
        this.$refs['modal-confirmation-delete-document'].hide()
        this.$refs['modal-delete-success'].show()
        this.getAllDocument()
      }
    },
    openModalConfirmationDelete(id) {
      this.documentId = id
      this.$refs['modal-confirmation-delete-document'].show()
    },
    toggleWishlist(e) {
      const selectedEl = e.target
      const changeEl = e.target.closest('.wrap-wish-cta').querySelector('.d-none')

      selectedEl.classList.add('d-none')
      changeEl.classList.remove('d-none')
    },

    async pickFile(e, dataToBeBound, id) {
      this.$refs[id].validate(e)

      if (e.target.value === '') {
        return
      }

      const input = this.$refs[id]
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }

      this.fileData[dataToBeBound] = e.target.files[0]

      this.conf.fileType = (this.fileData[dataToBeBound] || {}).type

      this.fileType = ((this.fileData[dataToBeBound] || {}).name || '')
        .substr(((this.fileData[dataToBeBound] || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()
    },

    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },

    doSearch() {
      if (this.dataLength === 0) {
        return null
      }
      this.isTyping = true
      this.isLoading = true
      this.doDebounce(() => {
        this.isTyping = false

        this.getAllDocument({
          page: 1,
          search: this.filter.search,
          loading: false,
          callback: () => {
            this.isLoading = false
          }
        })
      }, 500)
    },

    uploadFormFile(dataToBeBound) {
      // Validation
      this.error = false

      if (this.fileData[dataToBeBound] === null) {
        this.messageErrTitle = `File is required.`
        this.$refs['modal-error'].show()
        return null
      }

      if (this.conf.rule_type.indexOf(this.fileType) == -1) {
        this.error = `File type must be ${this.conf.rule_type} type.`
      }

      if (this.fileData[dataToBeBound] !== null) {
        if (this.fileData[dataToBeBound].size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          return alert('File too big (> 5MB)')
        }
        if (this.error) {
          return alert(this.error)
        }

        const path = 'runner_documents'

        const formData = new FormData()

        formData.set('file', this.fileData[dataToBeBound])

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },

    getDocumentsFilter() {
      this.debounce(() => {
        this.getAllDocument()
      }, 500)
    }
  },
  watch: {
    $route: {
      handler() {
        this.isLoading = true
        this.getAllDocument({
          page: this.filter.page,
          callback: () => {
            this.isLoading = false
          }
        })
      },
      immediate: true,
    }
  }
}
</script>

import { http } from '@/config/http'
import Gen from '@/libs/Gen'
import { getField, updateField } from 'vuex-map-fields'
// import Gen from '@/libs/Gen'
// import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    transactionDetailsData: [],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_TRANSACTION_DETAILS_DATA(state, data) {
      state.transactionDetailsData = data
    }
  },

  actions: {

    // GET TRANSACTION DETAILS
    async getTransactionDetails({ commit, rootState}, transactionId) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/private-pages/transactions/${transactionId}`, {
          headers: {
            ...Gen.apiHeaderAuth(rootState.userToken)
          }
        })

        commit("SET_TRANSACTION_DETAILS_DATA", res.data)
      } catch(err) {
        return err
      } finally {
        rootState.loading.screen =false
      }
    }

  },
}

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,
  state: {
    homeContent: {
      about: {
        content: {},
        data: [],
      },
      blog: {
        content: {},
        data: [],
      },
      gallery: {
        content: {},
        data: [],
      },
      hero: {
        content: {},
        data: {
          image: '',
          fallback_image: '',
          image_small: '',
          fallback_image_small: '',
          alt_image: '',
          title: '',
          poster: '',
        },
      },
      how_we_work: {
        content: {},
        data: [],
      },
      partners: {
        content: {},
        data: [],
      },
      runner_says: {
        content: {},
        data: [],
      },
      running_events: {
        content: {},
        data: [],
        remaining_stock: [],
      },
      staticseo: {
        content: {},
        data: [],
      },
      ctaevent: {
        content: {},
        data: [],
      },
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_HOME_CONTENT(state, data) {
      state.homeContent = data
    },
  },

  actions: {
    async getHomeContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true
        const resp = await http.get('/home', {
          headers: Gen.apiHeader(),
        })

        commit('SET_HOME_CONTENT', resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

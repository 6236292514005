<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <Header :isOpenMenuProps="true" />
        <section id="page-title" :style="heroBackground">
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="page-title d-flex flex-column align-items-center" :class="isOnlyMobile() ? 'py-5' : ''">
              <div class="label-custom green">
                <span class="black">{{ (content.hero || {}).sub_title }}</span>
              </div>
              <h2 class="black text-medium text-white mt-3" :class="!isMobile() ? 'w-75' : ''">
                {{ (content.hero || {}).title }}
              </h2>
              <span class="semibold text-white" :class="!isMobile() ? 'w-50' : ''">{{
                (content.hero || {}).description
              }}</span>
            </div>
          </div>
        </section>
      </div>

      <template v-if="!isMobile()">
        <div class="bg-grey py-4 d-flex align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </template>

      <section class="content-section paragraph pt-3" v-if="!isMobile()">
        <div class="container">
          <div class="row mt-4 align-items-center">
            <div class="col-md-6" :class="!isMobile() ? 'px-5 py-5' : 'pt-4 pb-4 order-5'">
              <div class="wrap_content left_paragraph">
                <h3 class="bold">{{ (content.tourGuide || {}).title_section }}</h3>
                <p class="mt-4">
                  {{ (content.tourGuide || {}).description_section }}
                </p>
                <button class="btn btn-slg btn-primary bold" @click="$router.push({ name: 'WWUTourGuide' })" v-if="(content.tourGuide || {}).is_active == 'Y'">
                  {{ (content.tourGuide || {}).text_cta }}
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="wrap_img bdrd-24px overflow-hidden">
                <picture>
                  <source :src="uploader((content.tourGuide || {}).img_section)" type="image/webp" />
                  <img
                    :src="uploader((content.tourGuide || {}).fallback_img_section)"
                    :alt="(content.tourGuide || {}).alt_image"
                    width="540"
                    height="360"
                    class="w-100"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>

      <template v-else>
        <div class="mobile-version-view height-auto py-5">
          <div class="container">
            <div class="row mt-0 align-items-center">
              <div class="col-md-6" :class="!isMobile() ? 'px-5 py-5' : isTablet() ? 'pt-4 pb-4' : 'pt-4 pb-4 order-5'">
                <div class="wrap_content left_paragraph" :class="isOnlyMobile() ? 'text-center' : ''">
                  <h3 class="bold">{{ (content.tourGuide || {}).title_section }}</h3>
                  <p class="mt-4">
                    {{ (content.tourGuide || {}).description_section }}
                  </p>
                  <button class="btn btn-slg btn-primary bold" @click="$router.push({ name: 'WWUTourGuide' })" v-if="(content.tourGuide || {}).is_active == 'Y'">
                    {{ (content.tourGuide || {}).text_cta }}
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="wrap_img bdrd-24px overflow-hidden">
                  <picture>
                    <source :src="uploader((content.tourGuide || {}).img_section)" type="image/webp" />
                    <img
                      :src="uploader((content.tourGuide || {}).fallback_img_section)"
                      :alt="content.tourGuide.alt_image_section"
                      width="600"
                      height="400"
                      class="w-100"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Global from '../libs/Global.vue'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import Header from '../components/Header.vue'

export default {
  extends: Global,
  name: 'WWULPTourGuide',
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Home',
          href: this.$router.resolve({ name: 'Home' }).href,
        },
        {
          text: 'Apply As A Tour Guide',
          active: true,
        },
      ],
      openMenuToggle() {
        const menuToggle = document.querySelector('.menu-toggle')
        const menuRound = document.querySelector('.menu-round')

        menuToggle.classList.toggle('open')
        menuRound.classList.toggle('open')
      },
    }
  },
  created() {
    this.getContent()
  },
  computed: {
    ...mapFields('tourguide', ['content']),
    ...mapFields(['channels']),
    heroBackground() {
      let src = '';

      if (this.isOnlyMobile()) {
        src = this.isWebPSupport
          ? this.uploader((this.content.hero || {}).image_small)
          : this.uploader((this.content.hero || {}).fallback_image_small)
      } else {
        src = this.isWebPSupport
          ? this.uploader((this.content.hero || {}).image)
          : this.uploader((this.content.hero || {}).fallback_image)
      }

      if (src === '') {
        return null
      }

      return `
        background: url('${src}') center center / cover;
      `
    },
  },
  methods: {
    ...mapActions('tourguide', ['getContent']),
    ...mapActions(['getChannels']),
  },
}
</script>

<style scoped>
.wrap_input_uploader {
  margin-top: 1.15em;
}
.wrap_input_uploader i {
  font-size: 20px;
}

.wrap_img {
  max-width: 540px;
  max-height: 360px;
}

.wrap_img img {
  height: auto;
  aspect-ratio: auto;
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <Header :isOpenMenuProps="true" />
        <section
          id="page-title"
          class="background-hero-img"
          :style="heroBackground"
        >
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="page-title d-flex flex-column align-items-center">
              <div class="label-custom green">
                <span class="black">{{ ((runningEventData || {}).hero || {}).sub_title }}</span>
              </div>
              <h2 class="black text-medium text-white mt-3" :class="!isMobile() ? 'w-75' : ''">
                {{ ((runningEventData || {}).hero || {}).title  }}
              </h2>
              <span class="semibold text-white" :class="!isMobile() ? 'w-50' : ''"
                >{{ ((runningEventData || {}).hero || {}).description }}</span
              >
            </div>
          </div>
        </section>
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isDesktop()">
        <section class="mt-0 pt-3 spy">
          <div class="container">
            <template v-if="(((runningEventData || {}).runevents || {}).data || {}).length > 0">
              <div class="row mt-4">
                <div class="col-md-4" v-for="(v,k) in ((runningEventData || {}).runevents || {}).data" :key="k">
                  <router-link
                    :to="{ name: 'RunningEventDetails', params: { pageSlug: v.slug } }"
                    class="ev_outer_link"
                  >
                    <div class="widget card-run-event d-flex flex-column p-cb p-0">
                      <div class="ribbon ribbon-top-left green" v-if="v.status == 'E'">
                        <span class="bold text-uppercase text-white">Enrollment</span>
                      </div>
                      <div class="ribbon ribbon-top-left yellow" v-if="v.status == 'U'">
                        <span class="bold text-uppercase text-white">Upcoming</span>
                      </div>
                      <div class="ribbon ribbon-top-left red" v-if="v.status == 'C'">
                        <span class="bold text-uppercase text-white">{{ v.remaining_stock != 0 ? 'Closed' : 'Sold Out'}}</span>
                      </div>
                      <div class="left img-fluid w-100" :class="v.status != 'E' ? 'img-darken' : ''">
                        <picture>
                          <source :srcset="uploader(v.image)" type="image/webp">
                          <img
                            :src="uploader(v.fallback_image)"
                            :alt="uploader(v.alt_image)"
                            class="bdrd-t-24px w-100"
                          />
                        </picture>
                      </div>
                      <div class="right p-3 w-100 d-flex flex-column justify-content-center p-r-1">
                        <div class="bt-1px-black">
                          <h3 class="bold text-dark">{{ v.name }}</h3>
                          <div class="d-flex flex-wrap bt-1px-black pb-2">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-ticket-alt gamma-color fs-1em"></i>
                              <a href=""><span class="mx-2 gamma-color semibold">{{ v.remaining_stock}} Slots Left</span></a>
                            </div>
                            <div class="d-flex align-items-center mx-2">
                              <i class="fa fa-calendar gamma-color fs-1em"></i>
                              <a href=""><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(v.race_date) }}</span></a>
                            </div>
                            <div class="d-flex align-items-center">
                              <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                              <a href=""><span class="mx-2 gamma-color semibold">{{ v.program }}, {{ v.country }}</span></a>
                            </div>
                          </div>
                          <p class="mt-2 mb-1">
                            {{ v.excerpt }}
                          </p>
                        </div>
                        <div class="mt-3 d-flex justify-content-start">
                          <button
                            class="btn btn-primary semibold d-flex align-items-center"
                            :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                          >
                            {{ v.status == 'E' ? 'Join Event' : 'See Details'}}
                            <i class="icon-arrow-right bold fs-125em ml-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="row">
                <div class="col-md-auto d-flex justify-content-center">
                  <b-pagination 
                    v-model="((runningEventData || {}).runevents || {}).current_page"
                    v-if="!isLoading && ((runningEventData || {}).runevents || {}).data && ((runningEventData || {}).runevents || {}).last_page > 1"
                    :total-rows="((runningEventData || {}).runevents || {}).total"
                    :per-page="((runningEventData || {}).runevents || {}).per_page"
                    prev-text="Prev"
                    next-text="Next"
                    @change="doPaginate"
                    :hide-goto-end-buttons="true"
                    limit="3"
                    class="mt-5"
                    pills
                    size="md"
                  >
                  </b-pagination>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row mt-0 justify-content-center py-5">
                <div class="col-md-6 d-flex flex-column align-items-center">
                  <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-45 mb-3" />
                  <h5 class="ls-0">Running Event is not available right now</h5>
                </div>
              </div>
            </template>
          </div>
        </section>
      </template>

      <template v-else-if="isOnlyMobile()">
        <div class="mobile-version-view py-5 spy">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6" v-for="(v,k) in ((runningEventData || {}).runevents || {}).data" :key="k">
                <div class="widget card-run-event d-flex flex-column p-cb p-0">
                  <div class="ribbon ribbon-top-left green" v-if="v.status == 'E'">
                    <span class="bold text-uppercase text-white">Enrollment</span>
                  </div>
                  <div class="ribbon ribbon-top-left yellow" v-if="v.status == 'U'">
                    <span class="bold text-uppercase text-white">Upcoming</span>
                  </div>
                  <div class="left img-fluid w-100" :class="v.status != 'E' ? 'img-darken' : ''">
                    <picture>
                      <source :srcset="uploader(v.image)" type="image/webp">
                      <img
                        :src="uploader(v.fallback_image)"
                        :alt="uploader(v.alt_image)"
                        class="bdrd-t-24px w-100"
                      />
                    </picture>
                  </div>
                  <div class="right p-3 w-100 d-flex flex-column justify-content-center p-r-1">
                    <div class="bt-1px-black">
                      <h3 class="bold">{{ v.name }}</h3>
                      <div class="d-flex flex-wrap bt-1px-black pb-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-ticket-alt gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ v.remaining_stock }} Slots Left</span></a>
                        </div>
                        <div class="d-flex align-items-center mx-2">
                          <i class="fa fa-calendar gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(v.race_date) }} April 2023</span></a>
                        </div>
                        <div class="d-flex align-items-center">
                          <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ v.program }}, {{ v.country }}</span></a>
                        </div>
                      </div>
                      <p class="mt-2 mb-1">
                        {{ v.excerpt }}
                      </p>
                    </div>
                    <div class="mt-3 d-flex justify-content-start">
                      <router-link
                        :to="{ name: 'RunningEventDetails', params: { pageSlug: v.slug } }"
                        class="btn btn-primary semibold d-flex align-items-center"
                        :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                      >
                        {{ v.status == 'E' ? 'Join Event' : 'See Details'}}
                        <i class="icon-arrow-right bold fs-125em ml-1"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-auto d-flex justify-content-center">
                <b-pagination
                  v-model="((runningEventData || {}).runevents || {}).current_page"
                  v-if="!isLoading && ((runningEventData || {}).runevents || {}).data && ((runningEventData || {}).runevents || {}).last_page > 1"
                  :total-rows="((runningEventData || {}).runevents || {}).total"
                  :per-page="((runningEventData || {}).runevents || {}).per_page"
                  prev-text="Prev"
                  next-text="Next"
                  @change="doPaginate"
                  :hide-goto-end-buttons="true"
                  limit="3"
                  class="mt-5"
                  pills
                  size="md"
                >
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="isTablet()">
        <section class="mt-0 pt-3 mt-5 spy">
          <div class="container px-0">
            <div class="row mt-0">
              <div class="col-6" v-for="(v,k) in ((runningEventData || {}).runevents || {}).data" :key="k">
                <div class="widget card-run-event d-flex flex-column p-cb p-0">
                  <div class="ribbon ribbon-top-left green" v-if="v.status == 'U'">
                    <span class="bold text-uppercase text-white">Enrollment</span>
                  </div>
                  <div class="ribbon ribbon-top-left yellow" v-if="v.status == 'P'">
                    <span class="bold text-uppercase text-white">Upcoming</span>
                  </div>
                  <div class="left img-fluid w-100" :class="v.status != 'E' ? 'img-darken' : ''">
                    <picture>
                      <source :srcset="uploader(v.image)" type="image/webp">
                      <img
                        :src="uploader(v.fallback_image)"
                        :alt="uploader(v.alt_image)"
                        class="bdrd-t-24px w-100"
                      />
                    </picture>
                  </div>
                  <div class="right p-3 w-100 d-flex flex-column justify-content-center p-r-1">
                    <div class="bt-1px-black">
                      <h3 class="bold">{{ v.name }}</h3>
                      <div class="d-flex flex-wrap bt-1px-black pb-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-ticket-alt gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ v.remaining_stock }} Slots Left</span></a>
                        </div>
                        <div class="d-flex align-items-center mx-2">
                          <i class="fa fa-calendar gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(v.race_date) }}</span></a>
                        </div>
                        <div class="d-flex align-items-center">
                          <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                          <a href=""><span class="mx-2 gamma-color semibold">{{ v.program }}, {{ v.country }}</span></a>
                        </div>
                      </div>
                      <p class="mt-2 mb-1">
                        {{ v.excerpt }}
                      </p>
                    </div>
                    <div class="mt-3 d-flex justify-content-start">
                      <router-link
                        :to="{ name: 'RunningEventDetails', params: { pageSlug: v.slug } }"
                        class="btn btn-primary semibold d-flex align-items-center"
                        :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                      >
                        {{ v.status == 'E' ? 'Join Event' : 'See Details'}}
                        <i class="icon-arrow-right bold fs-125em ml-1"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-auto d-flex justify-content-center">
                <b-pagination
                  v-model="((runningEventData || {}).runevents || {}).current_page"
                  v-if="!isLoading && ((runningEventData || {}).runevents || {}).data && ((runningEventData || {}).runevents || {}).last_page > 1"
                  :total-rows="((runningEventData || {}).runevents || {}).total"
                  :per-page="((runningEventData || {}).runevents || {}).per_page"
                  prev-text="Prev"
                  next-text="Next"
                  @change="doPaginate"
                  :hide-goto-end-buttons="true"
                  limit="3"
                  class="mt-5"
                  pills
                  size="md"
                >
                </b-pagination>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>
    <FloatingActionButtonAtom :openMenuToggle="openMenuToggle" />
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'
import Global from '../libs/Global.vue'

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import Header from '../components/Header.vue'
import FloatingActionButtonAtom from './atoms/home/FloatingActionButtonAtom.vue'

export default {
  extends: Global,
  name: 'RunningEvents',
  components: {
    carousel,
    Header,
    FloatingActionButtonAtom,
  },
  data() {
    return {
      filter: {},
      isLoading: false,
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Run With Us',
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapFields('useRunEventsStore', ['runningEventData']),
    ...mapFields(['channels']),
    heroBackground() {
      let src = '';

      if (this.isOnlyMobile()) {
        src = this.isWebPSupport
          ? this.uploader((this.runningEventData.hero || {}).image_small)
          : this.uploader((this.runningEventData.hero || {}).fallback_image_small)
      } else {
        src = this.isWebPSupport
          ? this.uploader((this.runningEventData.hero || {}).image)
          : this.uploader((this.runningEventData.hero || {}).fallback_image)
      }

      if (src === '') {
        return null
      }

      return `
        background: url('${src}') center center / cover;
      `
    },
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions("useRunEventsStore", [
      "getData"
    ]),
    ...mapActions(['getChannels']),
    scrollIntoView() {
      const el = document.querySelector('.spy')

      if (el) {
        const elPosition = el.getBoundingClientRect().top
        const offsetPosition = elPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition - 200,
          behavior: 'smooth',
        })
      }
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
    doPaginate(page = 1) {
      this.$set(this.filter, 'page', page)

      this.doFilter(() => {
        this.getData({
          ...this.filter
        })
      })

      this.scrollIntoView()
    },
    doFilter(cb = false, isquery = true) {
      const data = Object.keys(this.filter)
        .filter((k) => this.filter[k] !== null && this.filter[k] !== undefined)
        .reduce((a, k) => ({ ...a, [k]: this.filter[k] }), {})

      if (data.page == 1) {
        delete data.page

        if (isquery) {
          this.$router
            .push({
              name: this.$route.name,
              query: {
                ...data,
              },
              params: {
                ...this.$route.params,
                savePosition: true,
              },
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: this.$route.name,
              params: {
                ...this.$route.params,
                savePosition: true,
              },
            })
            .catch(() => {})
        }
      }

      if (isquery) {
        this.$router
          .push({
            name: this.$route.name,
            query: {
              ...data,
            },
            params: {
              ...this.$route.params,
              savePosition: true,
            },
          })
          .catch(() => {})
      } else {
        this.$router
          .push({
            name: this.$route.name,
            params: {
              ...this.$route.params,
              savePosition: true,
            },
          })
          .catch(() => {})
      }

      if (cb) {
        return cb(data)
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.$set(this.filter, 'page', (this.$route.query || {}).page)

        this.getData({
          ...this.filter
        })
      },
      immediate: true,
    }
  }
}
</script>

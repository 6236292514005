<template>
  <div class="home">
    <div class="body-inner">
      <section class="general-form-section" :class="isTablet() ? 'h-auto' : ''">
        <div class="row no-gutters">
          <div class="col-lg-7" :class="isMobile() ? 'wrap-form order-5' : ''">
            <div
              class="left-section without-justify-content"
              :class="
                !isMobile()
                  ? 'ov-scroll scroll-bar-hidden p-r-100 p-l-100'
                  : isTablet()
                  ? 'd-block h-auto'
                  : 'ov-scroll scroll-bar-hidden mt-5 pt-0 px-4'
              "
            >
              <div>
                <step-progress
                  :steps="mySteps"
                  :current-step="currentStep"
                  icon-class="fa fa-check"
                  active-color="#95378E"
                  :active-thickness="3"
                  :passive-thickness="3"
                  :line-thickness="3"
                ></step-progress>
              </div>
              <ValidationObserver v-slot="{ passes }" ref="CheckoutForm">
                <b-form ref="CheckoutFormElement" @submit.prevent="passes(nextStep)">
                  <div v-if="currentStep == 0">
                    <h4 class="extrabold" :class="isOnlyMobile() ? 'text-center' : ''">{{ (checkoutContent.runner_information_1 || {}).title }}</h4>
                    <span class="regular"
                      >{{ (checkoutContent.runner_information_1 || {}).description }}</span
                    >
                    <div class="mt-3 mb-4">
                      <b-tabs ref="runnertab">
                        <b-tab title="MAIN RUNNER" active>
                          <div class="row">
                            <div class="col-md-6 col-6">
                              <div class="form-group">
                                <label 
                                  for="co1fmidname" 
                                  class="required bold" 
                                >First &amp; Middle Name</label>
                                <input
                                  id="co1fmidname"
                                  type="text"
                                  v-model="(form.co_runners[0] || {}).co_first_name"
                                  class="form-control main-control"
                                  placeholder="e.g. Abraham Damar"
                                />
                                <VValidate name="First &amp; Middle Name" v-model="(form.co_runners[0] || {}).co_first_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                              </div>
                            </div>
                            <div class="col-md-6 col-6" :class="isMobile() ? 'pl-0' : ''">
                              <div class="form-group">
                                <label 
                                  for="co1lname" 
                                  class="required bold"
                                >Last Name</label>
                                <input
                                  id="co1lname"
                                  type="text"
                                  v-model="(form.co_runners[0] || {}).co_last_name"
                                  class="form-control main-control"
                                  placeholder="e.g. Grahita"
                                />
                                <VValidate name="Last Name" v-model="(form.co_runners[0] || {}).co_last_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="co1mail" class="required bold">Email Address</label>
                                <input
                                  id="co1mail"
                                  type="email"
                                  v-model="(form.co_runners[0] || {}).co_email_address"
                                  class="form-control main-control"
                                  placeholder="e.g. abraham.damar@example.com"
                                />
                                <VValidate name="Email Address" v-model="(form.co_runners[0] || {}).co_email_address" :rules="{required: true, email: true}" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="co1pnmb" class="bold">Phone Number</label>
                                <div class="input-group d-flex mt-3">
                                  <v-select
                                    v-model="(form.co_runners[0] || {}).co_country_code"
                                    placeholder="Code"
                                    :options="countryCodeList.data"
                                    :reduce="(v) => v.countrycode_id"
                                    id="countrycode1"
                                    class="pointer"
                                    :class="isOnlyMobile() ? 'no-dropdown' : ''"
                                    @click.native="isOnlyMobile() ? showSearchModal('modal-country-code', 'countrycode1') : ''"
                                    :get-option-label="(o) => o.countrycode_name + ' ' + o.countrycode_dial_code"
                                  >
                                    <template #option="{ countrycode_dial_code, countrycode_name }">
                                      <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                                    </template>
                                    <template #selected-option="{ countrycode_dial_code }">
                                      <span>{{ countrycode_dial_code }}</span>
                                    </template>
                                  </v-select>
                                  <b-form-input
                                    id="co1pnmb"
                                    v-model="(form.co_runners[0] || {}).co_phone_number"
                                    class="form-control main-control group-item mt-0"
                                    placeholder="Phone Number"
                                    :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                                  ></b-form-input>
                                </div>
                                <VValidate vclass="d-block" name="Country Code" v-model="(form.co_runners[0] || {}).co_country_code" :rules="{required: true}" />
                                <VValidate vclass="d-block" name="Phone Number" v-model="(form.co_runners[0] || {}).co_phone_number" :rules="{required: true, min:5, max:16}" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="required bold">Gender</label>
                                <div class="d-flex mt-3">
                                  <b-form-radio
                                    v-model="(form.co_runners[0] || {}).co_gender"
                                    name="cogender"
                                    value="M"
                                    >Male</b-form-radio
                                  >
                                  <b-form-radio
                                    v-model="(form.co_runners[0] || {}).co_gender"
                                    name="cogender"
                                    class="ml-3"
                                    value="F"
                                    >Female</b-form-radio
                                  >
                                </div>
                                <VValidate vid="cogender" name="Gender" v-model="(form.co_runners[0] || {}).co_gender" :rules="{required: true}" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="co1dobb" class="required bold">Date of Birth</label>
                                <v-date-picker
                                  full-width
                                  :popover="{ visibility: 'click' }"
                                  v-model="(form.co_runners[0] || {}).co_date_of_birth"
                                  class="mt-3"
                                  :masks="{ input: 'MM/DD/YYYY' }"
                                  :max-date="maxDateOfBirth"
                                  :to-page="defineToPage((form.co_runners[0] || {}).co_date_of_birth, minimumAgePage, 0)"
                                >
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex flex-column justify-center items-center">
                                      <input
                                        id="co1dobb"
                                        v-if="inputEvents"
                                        :value="formatDate(inputValue)"
                                        placeholder="Choose a date"
                                        v-on="inputEvents"
                                        :onkeydown="false"
                                        class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                      />
                                    </div>
                                  </template>
                                </v-date-picker>
                                <VValidate vid="dob1" name="Date of Birth" v-model="(form.co_runners[0] || {}).co_date_of_birth" :rules="{required: true}" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="copassportno" class="required bold">Passport Number</label>
                                <input
                                  id="copassportno"
                                  type="text"
                                  v-model="(form.co_runners[0] || {}).co_passport_number"
                                  class="form-control main-control"
                                  placeholder="e.g. XXXXXX"
                                />
                                <VValidate name="Passport Number" v-model="(form.co_runners[0] || {}).co_passport_number" :rules="{required: true, min:7, max:11 }" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="co1pasexp" class="required bold">Passport Expiration Date</label>
                                <v-date-picker
                                  full-width
                                  :popover="{ visibility: 'click' }"
                                  v-model="(form.co_runners[0] || {}).co_passport_exp_date"
                                  class="mt-3"
                                  :masks="{ input: 'MM/DD/YYYY' }"
                                  :min-date="minPassportExpiration"
                                >
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex flex-column justify-center items-center">
                                      <input
                                        id="co1pasexp"
                                        v-if="inputEvents"
                                        :value="formatDate(inputValue)"
                                        placeholder="Choose a date"
                                        v-on="inputEvents"
                                        :onkeydown="false"
                                        class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                      />
                                    </div>
                                  </template>
                                </v-date-picker>
                                <VValidate vid="ped1" name="Passport Expiration Date" v-model="(form.co_runners[0] || {}).co_passport_exp_date" :rules="{required: true}" />
                              </div>
                            </div>
                          </div>
                        </b-tab>
                        <b-tab title="SECONDARY RUNNER" v-if="checkoutData.event.package_total_runners > 1">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <b-form-checkbox
                                  id="is_fill_second_runner_data"
                                  v-model="isFillSecondRunnerDetail"
                                  name="is_fill_second_runner_data"
                                  class="mb-3"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  <span class="normal">{{ checkoutContent.check_second_data }}</span>
                                </b-form-checkbox>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6 col-6">
                                  <div class="form-group">
                                    <label for="co2fmn" class="required bold">First &amp; Middle Name</label>
                                    <v-select
                                      :options="checkoutData.network_list"
                                      placeholder="e.g. Abraham Damar"
                                      :reduce="(v) => v"
                                      id="network"
                                      label="first_name"
                                      @input="setSelectedSecondRunner($event)"
                                      class="mt-3"
                                    >
                                      <template #list-header>
                                        <a
                                          href="javascript:;"
                                          class="add_select"
                                          @click.prevent="$bvModal.show('networkModal')"
                                          >[+] Add a Network</a
                                        >
                                      </template>
                                      <template #option="{ first_name, last_name, email }">
                                        <span>{{ first_name }} {{ last_name }}</span>
                                      </template>
                                    </v-select>
                                    <VValidate name="First &amp; Middle Name" v-model="(form.co_runners[1] || {}).co_first_name" :rules="{ required: true, min: 3, max: 96, regex: /^([A-Za-z' ]+)$/ }" />
                                  </div>
                                </div>
                                <div class="col-md-6 col-6">
                                  <div class="form-group">
                                    <label for="co2ln" class="required bold">Last Name</label>
                                    <template v-if="(form.co_runners[1] || {}).is_last_name == 'N'">
                                      <input
                                        type="text"
                                        class="form-control main-control"
                                        placeholder="e.g. Grahita"
                                        :value="(form.co_runners[1] || {}).co_first_name"
                                        disabled
                                      />
                                    </template>
                                    <template v-else>
                                      <input
                                        id="co2ln"
                                        type="text"
                                        v-model="(form.co_runners[1] || {}).co_last_name"
                                        class="form-control main-control"
                                        placeholder="e.g. Grahita"
                                      />
                                      <VValidate vid="ln2" name="Last Name" v-model="(form.co_runners[1] || {}).co_last_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                                    </template>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <b-form-checkbox
                                      id="is_lastname"
                                      v-model="(form.co_runners[1] || {}).is_last_name"
                                      name="is_lastname"
                                      class="mb-3"
                                      value="N"
                                      unchecked-value="Y"
                                      @click.native="checkIsLastNameSecondRunner(form.co_runners[1])"
                                    >
                                      <span class="normal">This person doesn't have family name / last name</span>
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <ValidationObserver v-slot="{ passes }" ref="MembershipStatusForm">
                                    <div class="row">
                                      <div class="col">
                                        <div class="form-group">
                                          <label for="co1mail" class="required bold">Email Address</label>
                                          <div class="wrap_second_email row align-items-center">
                                            <div class="col-9">
                                              <input
                                                id="co1mail"
                                                type="email"
                                                v-model="(form.co_runners[1] || {}).co_email_address"
                                                class="form-control main-control"
                                                placeholder="e.g. abraham.damar@example.com"
                                                @keyup="passes(checkSecondRunnerMemberStatus)"
                                              />
                                            </div>
                                          </div>
                                          <small class="text-danger d-block" v-if="reuseEmailErr">{{ checkoutContent.reuse_email_err }}</small>
                                          <VValidate name="Email Address" v-model="(form.co_runners[1] || {}).co_email_address" :rules="{required: true, email: true}" />
                                        </div>
                                      </div>
                                    </div>
                                  </ValidationObserver>
                                  <div v-if="!isLoading && secondRunnerMemberStatus !== null" class="row mb-3">
                                    <div class="col-md-12">
                                      <div class="wrap_member_status bdrd-12px p-3 alert" :class="secondRunnerMemberStatus ? 'alert-success' : 'alert-grey'">
                                        <h5 :class="secondRunnerMemberStatus ? 'text-light' : 'text-dark'">Member Status</h5>
                                        <p class="m-0 pl-0">Email: <span class="bold">{{ secondRunnerMemberEmail || '-' }}</span></p>
                                        <p class="m-0 pl-0">Name: <span class="bold">{{ secondRunnerMemberName || '-' }}</span></p>
                                        <p class="m-0 pl-0">Status: <span class="bold">{{ secondRunnerMemberStatus ? 'Active Member' : 'Not Member' }}</span></p>
                                        <p v-if="!secondRunnerMemberStatus" class="m-0 pl-0">We'll send register account instructions</span></p>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else-if="isLoading" class="row mb-3">
                                    <div class="col">
                                      <PulseLoader :loading="true" color="#333" size="14px" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="co1pnmb" class="bold">Phone Number</label>
                                    <div class="input-group d-flex mt-3">
                                      <v-select
                                        v-model="(form.co_runners[1] || {}).co_country_code"
                                        placeholder="Code"
                                        :options="countryCodeList.data"
                                        :reduce="(v) => v.countrycode_id"
                                        id="countrycode2"
                                        class="pointer"
                                        :class="isOnlyMobile() ? 'no-dropdown' : ''"
                                        @click.native="isOnlyMobile() ? showSearchModal('modal-country-code', 'countrycode2') : ''"
                                        :get-option-label="(o) => o.countrycode_name + ' ' + o.countrycode_dial_code"
                                      >
                                        <template #option="{ countrycode_dial_code, countrycode_name }">
                                          <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                                        </template>
                                        <template #selected-option="{ countrycode_dial_code }">
                                          <span>{{ countrycode_dial_code }}</span>
                                        </template>
                                      </v-select>
                                      <b-form-input
                                        id="co1pnmb"
                                        v-model="(form.co_runners[1] || {}).co_phone_number"
                                        class="form-control main-control group-item mt-0"
                                        placeholder="Phone Number"
                                        :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                                      ></b-form-input>
                                    </div>
                                    <VValidate vclass="d-block" name="Country Code" v-model="(form.co_runners[1] || {}).co_country_code" :rules="{required: true}" />
                                    <VValidate vclass="d-block" name="Phone Number" v-model="(form.co_runners[1] || {}).co_phone_number" :rules="{required: true, min:5, max:16}" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label class="required bold">Gender</label>
                                    <div class="d-flex mt-3">
                                      <b-form-radio
                                        v-model="(form.co_runners[1] || {}).co_gender"
                                        name="gendersecond"
                                        value="M"
                                        >Male</b-form-radio
                                      >
                                      <b-form-radio
                                        v-model="(form.co_runners[1] || {}).co_gender"
                                        name="gendersecond"
                                        class="ml-3"
                                        value="F"
                                        >Female</b-form-radio
                                      >
                                    </div>
                                    <VValidate vid="gendersecond" name="Gender" v-model="(form.co_runners[1] || {}).co_gender" :rules="{required: true}" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <template v-if="isFillSecondRunnerDetail == 'Y'">
                              <div class="col-md-12">
                                <hr />
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="co2dob" class="required bold">Date of Birth</label>
                                  <v-date-picker
                                    full-width
                                    :popover="{ visibility: 'click' }"
                                    v-model="(form.co_runners[1] || {}).co_date_of_birth"
                                    class="mt-3"
                                    :masks="{ input: 'MM/DD/YYYY' }"
                                    :max-date="maxDateOfBirth"
                                    :to-page="defineToPage((form.co_runners[1] || {}).co_date_of_birth, minimumAgePage, 1)"
                                  >
                                    <template v-slot="{ inputValue, inputEvents }">
                                      <div class="d-flex flex-column justify-center items-center">
                                        <input
                                          id="co2dob"
                                          v-if="inputEvents"
                                          :value="formatDate(inputValue)"
                                          placeholder="Choose a date"
                                          v-on="inputEvents"
                                          :onkeydown="false"
                                          class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                        />
                                        <VValidate vid="dob2" name="Date of Birth" v-model="(form.co_runners[1] || {}).co_date_of_birth" :rules="{required: true}" />
                                      </div>
                                    </template>
                                  </v-date-picker>
                                </div>
                              </div>
                              <div
                                :class="
                                  (form.co_runners[1] || {}).co_is_citizenship_indonesian == 'Y'
                                    ? 'col-md-12'
                                    : 'col-md-4'
                                "
                              >
                                <div class="form-group">
                                  <label for="" class="required bold">Citizenship</label>
                                  <div class="d-flex mt-3">
                                    <b-form-radio
                                      v-model="(form.co_runners[1] || {}).co_is_citizenship_indonesian"
                                      name="citizenship2"
                                      value="Y"
                                      >Indonesia</b-form-radio
                                    >
                                    <b-form-radio
                                      v-model="(form.co_runners[1] || {}).co_is_citizenship_indonesian"
                                      name="citizenship2"
                                      class="ml-3"
                                      value="N"
                                      >Others</b-form-radio
                                    >
                                  </div>
                                  <VValidate vid="isctzid2" name="Citizenship" v-model="(form.co_runners[1] || {}).co_is_citizenship_indonesian" :rules="{required: true}" />
                                </div>
                              </div>
                              <div class="col-md-8" v-if="(form.co_runners[1] || {}).co_is_citizenship_indonesian == 'N'">
                                <div class="form-group">
                                  <label for="co2ctzship" class="bold">Country</label>
                                  <v-select
                                    :options="citizenshipList.data"
                                    placeholder="-- Select Country --"
                                    :reduce="(v) => v.citizenship_id"
                                    v-model="(form.co_runners[1] || {}).co_citizenship"
                                    id="co2ctzship"
                                    label="citizenship_name"
                                    class="mt-3"
                                  ></v-select>
                                  <VValidate vid="ctz2" name="Citizenship" v-model="(form.co_runners[1] || {}).co_citizenship" :rules="{required: true}" />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="copassportno2" class="required bold">Passport Number</label>
                                  <input
                                    id="copassportno2"
                                    type="text"
                                    v-model="(form.co_runners[1] || {}).co_passport_number"
                                    class="form-control main-control"
                                    placeholder="e.g. XXXXXX"
                                  />
                                  <VValidate name="Passport Number" v-model="(form.co_runners[1] || {}).co_passport_number" :rules="{required: true, min:7, max:11 }" />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="co2passpexp" class="required bold">Passport Expiration Date</label>
                                  <v-date-picker
                                    full-width
                                    :popover="{ visibility: 'click' }"
                                    v-model="(form.co_runners[1] || {}).co_passport_exp_date"
                                    class="mt-3"
                                    :masks="{ input: 'MM/DD/YYYY' }"
                                    :min-date="minPassportExpiration"
                                  >
                                    <template v-slot="{ inputValue, inputEvents }">
                                      <div class="d-flex flex-column justify-center items-center">
                                        <input
                                          id="co2passpexp"
                                          :value="formatDate(inputValue)"
                                          placeholder="Choose a date"
                                          v-on="inputEvents"
                                          :onkeydown="false"
                                          class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                        />
                                      </div>
                                    </template>
                                  </v-date-picker>
                                  <VValidate v-if="(form.co_runners[1] || {}).co_passport_exp_date !== undefined" vid="ped2" name="Passport Expiration Date" v-model="(form.co_runners[1] || {}).co_passport_exp_date" :rules="{required: true}" />
                                </div>
                              </div>
                            </template>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                  <div v-if="currentStep == 1">
                    <h4 class="extrabold" :class="isOnlyMobile() ? 'text-center' : ''">{{ checkoutContent.runner_information_2.title }}</h4>
                    <div :class="isOnlyMobile() ? 'text-center' : ''">
                      <span class="regular"
                        >{{ checkoutContent.runner_information_2.description }}</span
                      >
                    </div>
                    <div class="mt-3 mb-4">
                      <b-tabs>
                        <b-tab title="MAIN RUNNER" active>
                          <div class="row">
                            <div
                              :class="
                                (form.co_runners[0] || {}).co_is_citizenship_indonesian == 'Y'
                                  ? 'col-md-12'
                                  : 'col-md-4'
                              "
                            >
                              <div class="form-group">
                                <label class="required bold">Citizenship</label>
                                <div class="d-flex mt-3">
                                  <b-form-radio
                                    v-model="(form.co_runners[0] || {}).co_is_citizenship_indonesian"
                                    name="citizenship1"
                                    value="Y"
                                    >Indonesia</b-form-radio
                                  >
                                  <b-form-radio
                                    v-model="(form.co_runners[0] || {}).co_is_citizenship_indonesian"
                                    name="citizenship1"
                                    class="ml-3"
                                    value="N"
                                    >Others</b-form-radio
                                  >
                                </div>
                                <VValidate vid="iscorid1" name="Citizenship" v-model="(form.co_runners[0] || {}).co_is_citizenship_indonesian" :rules="{required: true}" />
                              </div>
                            </div>
                            <div class="col-md-8" v-if="(form.co_runners[0] || {}).co_is_citizenship_indonesian == 'N'">
                              <div class="form-group">
                                <v-select
                                  :options="citizenshipList.data"
                                  placeholder="-- Select Country --"
                                  :reduce="(v) => v.citizenship_id"
                                  v-model="(form.co_runners[0] || {}).co_citizenship"
                                  id="co1ctry"
                                  label="citizenship_name"
                                  class="mt-3"
                                ></v-select>
                                <VValidate vid="cor1" name="Country of Residence" v-model="(form.co_runners[0] || {}).co_citizenship" :rules="{required: true}" />
                              </div>
                            </div>
                            <div
                              :class="
                                (form.co_runners[0] || {}).co_is_country_of_residence_indonesian == 'Y'
                                  ? 'col-md-12'
                                  : 'col-md-4'
                              "
                            >
                              <div class="form-group">
                                <label class="required bold">Country of Residence</label>
                                <div class="d-flex mt-3">
                                  <b-form-radio
                                    v-model="(form.co_runners[0] || {}).co_is_country_of_residence_indonesian"
                                    name="coresidence"
                                    value="Y"
                                    >Indonesia</b-form-radio
                                  >
                                  <b-form-radio
                                    v-model="(form.co_runners[0] || {}).co_is_country_of_residence_indonesian"
                                    name="coresidence"
                                    class="ml-3"
                                    value="N"
                                    >Others</b-form-radio
                                  >
                                </div>
                                <VValidate vid="isctry1" name="Country Of Residence" v-model="(form.co_runners[0] || {}).co_is_country_of_residence_indonesian" :rules="{required: true}" />
                              <b-form-text text-variant="danger" v-if="(form.co_runners[0] || {}).co_is_country_of_residence_indonesian == 'N' && (form.co_runners[0] || {}).co_is_citizenship_indonesian == 'N'">
                                * Because you are not from Indonesia, please do not make any payment until we can confirm via e-mail that you can run with RunDeGlobe
                              </b-form-text>
                              </div>
                            </div>

                            <div
                              class="col-md-8"
                              v-if="(form.co_runners[0] || {}).co_is_country_of_residence_indonesian == 'N'"
                            >
                              <div class="form-group">
                                <v-select
                                  :options="citizenshipList.data"
                                  placeholder="-- Select Country --"
                                  :reduce="(v) => v.citizenship_id"
                                  v-model="(form.co_runners[0] || {}).co_country_of_residence"
                                  id="co1ctz"
                                  label="citizenship_name"
                                  class="mt-3"
                                ></v-select>
                                <VValidate vid="ctry1" name="Country" v-model="(form.co_runners[0] || {}).co_country_of_residence" :rules="{required: true}" />
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="co1staddr1" class="required bold">Street Address 1</label>
                                <textarea
                                  id="co1staddr1"
                                  type="text"
                                  v-model="(form.co_runners[0] || {}).co_street_address_1"
                                  class="form-control main-control text-area"
                                  placeholder="Street Address 1"
                                ></textarea>
                                <VValidate v-if="submitInterval" name="Street Address 1" v-model="(form.co_runners[0] || {}).co_street_address_1" :rules="{required: true, min: 3}" />
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="co1staddr2" class="bold">Street Address 2 <span class="smal">(optional)</span></label>
                                <textarea
                                  id="co1staddr2"
                                  type="text"
                                  v-model="(form.co_runners[0] || {}).co_street_address_2"
                                  class="form-control main-control text-area"
                                  placeholder="Street Address 2"
                                ></textarea>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="co1staddr3" class="bold">Street Address 3 <span class="small">(optional)</span></label>
                                <textarea
                                  id="co1staddr3"
                                  type="text"
                                  v-model="(form.co_runners[0] || {}).co_street_address_3"
                                  class="form-control main-control text-area"
                                  placeholder="Street Address 3"
                                ></textarea>
                              </div>
                            </div>

                            <template v-if="(form.co_runners[0] || {}).co_is_country_of_residence_indonesian == 'Y'">
                              <div class="col-md-6 col-12">
                                <div class="form-group">
                                  <label class="required bold ls-0">Province</label>
                                  <v-select
                                    v-if="provinceData"
                                    :options="provinceData"
                                    placeholder="-- Select Province / State --"
                                    :reduce="v => v.province_name"
                                    v-model="inputSelectedState"
                                    id="province1"
                                    label="province_name"  
                                    class="mt-3"
                                    @click.native="isOnlyMobile() ? showSearchModal('modal-province', 'province1') : ''"
                                    :class="isOnlyMobile() ? 'no-dropdown' : ''"
                                  ></v-select>
                                  <VValidate
                                    name="Province / State"
                                    v-model="(form.co_runners[0] || {}).co_state"
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6 col-12">
                                <div class="form-group">
                                  <label class="required bold ls-0">City</label>
                                  <v-select
                                    v-if="citiesData"
                                    :options="citiesData"
                                    placeholder="-- Select City --"
                                    :reduce="v => v"
                                    v-model="inputSelectedCity"
                                    label="zip_city"
                                    class="mt-3"
                                    id="city1"
                                    @click.native="isOnlyMobile() ? showSearchModal('modal-cities', 'city1') : ''"
                                    :class="isOnlyMobile() ? 'no-dropdown' : ''"
                                  ></v-select>
                                  <VValidate
                                    name="City"
                                    v-model="(form.co_runners[0] || {}).co_city"
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6 col-12">
                                <div class="form-group">
                                  <label class="required bold ls-0">District</label>
                                  <v-select
                                    v-if="districtData"
                                    :options="districtData"
                                    placeholder="-- Select District --"
                                    :reduce="v => v"
                                    v-model="inputSelectedDistrict"
                                    id="district1"
                                    label="zip_district"
                                    class="mt-3"
                                    @click.native="isOnlyMobile() ? showSearchModal('modal-district', 'district1') : ''"
                                    :class="isOnlyMobile() ? 'no-dropdown' : ''"
                                  ></v-select>
                                  <VValidate
                                    name="District"
                                    v-model="(form.co_runners[0] || {}).co_district"
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6 col-12">
                                <div class="form-group">
                                  <label class="required bold ls-0">Sub District</label>
                                  <v-select
                                    v-if="subdistrictData"
                                    :options="subdistrictData"
                                    placeholder="-- Select Sub District --"
                                    :reduce="v => v"
                                    label="zip_sub_district"
                                    v-model="inputSelectedSubDistrict"
                                    id="subdistrict1"
                                    @click.native="isOnlyMobile() ? showSearchModal('modal-sub-district', 'subdistrict1') : ''"
                                    :class="isOnlyMobile() ? 'no-dropdown' : ''"
                                    class="mt-3"
                                  ></v-select>
                                  <VValidate
                                    name="Sub District"
                                    v-model="(form.co_runners[0] || {}).co_subdistrict"
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="required bold">Postal Code</label>
                                  <input
                                    type="text"
                                    v-model="(form.co_runners[0] || {}).co_zip_code"
                                    disabled
                                    class="form-control disabled main-control"
                                    placeholder="e.g. 1234"
                                  />
                                  <VValidate
                                    name="Postal Code"
                                    v-model="(form.co_runners[0] || {}).co_zip_code"
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </div>
                            </template>
                          </div>
                        </b-tab>
                        <b-tab title="SECONDARY RUNNER" v-if="isFillSecondRunner == 'Y' && checkoutData.event.package_total_runners > 1">
                          <div class="row">
                            <div
                              :class="
                                (form.co_runners[1] || {}).co_is_citizenship_indonesian == 'Y'
                                  ? 'col-md-12'
                                  : 'col-md-4'
                              "
                            >
                              <div class="form-group">
                                <label class="required bold">Citizenship</label>
                                <div class="d-flex mt-3">
                                  <b-form-radio
                                    v-model="(form.co_runners[1] || {}).co_is_citizenship_indonesian"
                                    name="isctzid2"
                                    value="Y"
                                    >Indonesia</b-form-radio
                                  >
                                  <b-form-radio
                                    v-model="(form.co_runners[1] || {}).co_is_citizenship_indonesian"
                                    name="isctzid2"
                                    class="ml-3"
                                    value="N"
                                    >Others</b-form-radio
                                  >
                                </div>
                                <VValidate vid="isctzid2" name="Citizenship" v-model="(form.co_runners[1] || {}).co_is_citizenship_indonesian" :rules="{required: true}" />
                              </div>
                            </div>
                            <div class="col-md-8" v-if="(form.co_runners[1] || {}).co_is_citizenship_indonesian == 'N'">
                              <div class="form-group">
                                <v-select
                                  :options="citizenshipList.data"
                                  placeholder="-- Select Country --"
                                  :reduce="(v) => v.citizenship_id"
                                  v-model="(form.co_runners[1] || {}).co_citizenship"
                                  id="ctz2so"
                                  label="citizenship_name"
                                  class="mt-3"
                                ></v-select>
                                <VValidate vid="ctzs2" name="Country of Residence" v-model="(form.co_runners[0] || {}).co_citizenship" :rules="{required: true}" />
                              </div>
                            </div>
                            <div
                              :class="
                                (form.co_runners[1] || {}).co_is_country_of_residence_indonesian == 'Y'
                                  ? 'col-md-12'
                                  : 'col-md-4'
                              "
                            >
                              <div class="form-group">
                                <label class="required bold">Country of Residence</label>
                                <div class="d-flex mt-3">
                                  <b-form-radio
                                    id="cof2residence-yes"
                                    v-model="(form.co_runners[1] || {}).co_is_country_of_residence_indonesian"
                                    name="cof2residence"
                                    value="Y"
                                    >Indonesia</b-form-radio
                                  >
                                  <b-form-radio
                                    id="cof2residencen-no"
                                    v-model="(form.co_runners[1] || {}).co_is_country_of_residence_indonesian"
                                    name="cof2residence"
                                    class="ml-3"
                                    value="N"
                                    >Others</b-form-radio
                                  >
                                </div>
                                <b-form-text text-variant="danger" v-if="(form.co_runners[1] || {}).co_is_country_of_residence == 'Y' == 'N' && (form.co_runners[1] || {}).co_is_citizenship_indonesian == 'N'">
                                  * Because you are not from Indonesia, please do not make any payment until we can confirm via e-mail that you can run with RunDeGlobe
                                </b-form-text>
                                <VValidate vid="iscorid2" name="Country Of Residence" v-model="(form.co_runners[1] || {}).co_is_country_of_residence_indonesian" :rules="{required: true}" />
                              </div>
                            </div>

                            <div
                              class="col-md-8"
                              v-if="(form.co_runners[1] || {}).co_is_country_of_residence_indonesian == 'N'"
                            >
                              <div class="form-group">
                                <v-select
                                  :options="citizenshipList.data"
                                  placeholder="-- Select Country --"
                                  :reduce="(v) => v.citizenship_id"
                                  v-model="(form.co_runners[1] || {}).co_country_of_residence"
                                  id="co2citizenship"
                                  label="citizenship_name"
                                  class="mt-3"
                                ></v-select>
                                <VValidate vid="cor2" name="Country of Residence" v-model="(form.co_runners[1] || {}).co_country_of_residence" :rules="{required: true}" />
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="staddr1" class="required bold">Street Address 1</label>
                                <textarea
                                  id="staddr1s2"
                                  type="text"
                                  v-model="(form.co_runners[1] || {}).co_street_address_1"
                                  class="form-control main-control text-area"
                                  placeholder="Street Address 1"
                                ></textarea>
                                <VValidate vid="str1s2" name="Street Address 1" v-model="(form.co_runners[1] || {}).co_street_address_1" :rules="{required: true, min: 3}" />
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="staddr2" class="bold">Street Address 2 <span class="smal">(optional)</span></label>
                                <textarea
                                  id="staddr2s2"
                                  type="text"
                                  v-model="(form.co_runners[1] || {}).co_street_address_2"
                                  class="form-control main-control text-area"
                                  placeholder="Street Address 2"
                                ></textarea>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="staddr3" class="bold">Street Address 3 <span class="small">(optional)</span></label>
                                <textarea
                                  id="staddr3s2"
                                  type="text"
                                  v-model="(form.co_runners[1] || {}).co_street_address_3"
                                  class="form-control main-control text-area"
                                  placeholder="Street Address 3"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                  <div v-if="currentStep == 2">
                    <h4 class="extrabold" :class="isOnlyMobile() ? 'text-center' : ''">{{ checkoutContent.room_setup.title }}</h4>
                    <div :class="isOnlyMobile() ? 'text-center' : ''">
                      <span class="regular"
                        >{{ checkoutContent.room_setup.description }}</span
                      >
                    </div>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="bedType" class="required bold">Bed Type</label>
                            <v-select
                              :options="checkoutContent.bedType"
                              placeholder="-- Select Bed Type --"
                              :reduce="(v) => v.name"
                              v-model="form.co_bed_type"
                              id="bedType"
                              label="name"
                              class="mt-3"
                            ></v-select>
                            <VValidate v-if="submitInterval" name="Bed Type" v-model="form.co_bed_type" :rules="{required: true}" />
                          </div>
                        </div>

                        <template v-if="isMobile() && checkoutData.event.package_max_person > 1">
                          <div class="d-flex justify-content-between" :class="isOnlyMobile() ? 'mt-3' : ''">
                            <span class="sub-title-section fs-125em">
                              <div class="line-sub-title"></div>
                              Roommate
                            </span>
                            <div>
                              <a @click="addRoommate" class="text-link alpha-color pointer"><i class="fa fa-plus mr-1"></i> Add Roommate</a>
                            </div>
                          </div>
                          <div class="row mt-3 w-100 pr-0">
                            <div class="bt-1px-grey bb-1px-grey pt-4 pb-3" v-for="(v, k) in form.co_roommate" :key="k">
                              <div class="d-flex justify-content-between" :class="isOnlyMobile() ? 'mb-4' : ''">
                                <p class="bold regular text-dark">Roommate {{ k + 1 }}</p>
                                <div v-if="k > 0">
                                  <a @click="removeRoommate(k)" class="text-link alpha-color pointer"><i class="fa fa-minus mr-1"></i> Remove</a>
                                </div>
                              </div>
                              <div class="row gx-2">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-fmname'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >First &amp; Middle Name</label>
                                    <v-select
                                      :options="checkoutData.network_list"
                                      placeholder="e.g. Abraham Damar"
                                      :reduce="(v) => v"
                                      id="network"
                                      label="first_name"
                                      @input="setSelectedRoommate($event, k)"
                                      class="mt-3"
                                    >
                                      <template #list-header>
                                        <a
                                          href="javascript:;"
                                          class="add_select"
                                          @click.prevent="$bvModal.show('networkModal')"
                                          >[+] Add a Network</a
                                        >
                                      </template>
                                      <template #option="{ first_name, last_name, email }">
                                        <span>{{ first_name }} {{ last_name }}</span>
                                      </template>
                                    </v-select>
                                    <VValidate v-if="checkoutData.event.package_total_runners == 1" name="First Name" v-model="(form.co_roommate[k] || {}).first_name" :rules="{ required: true, min: 3, max: 96, regex: /^([A-Za-z' ]+)$/ }" />
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-ln'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >Last Name</label>
                                    <input
                                      :id="k+'-ln'"
                                      type="text"
                                      v-model="(form.co_roommate[k] || {}).last_name"
                                      class="form-control main-control"
                                      placeholder="e.g. Grahita"
                                    />
                                    <VValidate v-if="checkoutData.event.package_total_runners == 1" name="Last Name" v-model="(form.co_roommate[k] || {}).last_name" :rules="{required: true}" />
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-age'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >Gender</label>
                                    <v-select
                                      :options="checkoutData.gender_options"
                                      placeholder="-- Gender --"
                                      :reduce="(v) => v.label"
                                      v-model="(form.co_roommate[k] || {}).gender"
                                      :id="k+'-gender'"
                                      label="label"
                                      class="mt-3"
                                    ></v-select>
                                    <VValidate v-if="checkoutData.event.package_total_runners == 1" name="Gender" v-model="(form.co_roommate[k] || {}).gender" :rules="{required: true}" />
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-age'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >Age</label>
                                    <input
                                      :id="k+'-age'"
                                      type="number"
                                      v-model="(form.co_roommate[k] || {}).age"
                                      class="form-control main-control"
                                      placeholder="Age"
                                      min="0"
                                    />
                                    <VValidate v-if="checkoutData.event.package_total_runners == 1" name="Age" v-model="(form.co_roommate[k] || {}).age" :rules="{required: true}" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="isDesktop() && checkoutData.event.package_max_person > 1">
                          <div class="d-flex justify-content-between" :class="isOnlyMobile() ? 'mt-3' : ''">
                            <span class="sub-title-section fs-125em">
                              <div class="line-sub-title"></div>
                              Roommate
                            </span>
                            <div>
                              <a @click="addRoommate" class="text-link alpha-color pointer"><i class="fa fa-plus mr-1"></i> Add a Roommate</a>
                            </div>
                          </div>
                          <div class="row mt-3 w-100 pr-0">
                            <div class="bt-1px-grey bb-1px-grey pt-4 pb-3" v-for="(v, k) in form.co_roommate" :key="k">
                              <div class="d-flex justify-content-between" :class="isOnlyMobile() ? 'mb-4' : ''">
                                <p class="bold regular text-dark">Roommate {{ k + 1 }}</p>
                                <div v-if="k > 0">
                                  <a @click="removeRoommate(k)" class="text-link alpha-color pointer"><i class="fa fa-minus mr-1"></i> Remove</a>
                                </div>
                              </div>
                              <div class="row gx-2">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-fmname'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >First &amp; Middle Name</label>
                                    <v-select
                                      :options="checkoutData.network_list"
                                      placeholder="e.g. Abraham Damar"
                                      :reduce="(v) => v"
                                      id="network"
                                      label="first_name"
                                      @input="setSelectedRoommate($event, k)"
                                      class="mt-3"
                                    >
                                      <template #list-header>
                                        <a
                                          href="javascript:;"
                                          class="add_select"
                                          @click.prevent="$bvModal.show('networkModal')"
                                          >[+] Add a Network</a
                                        >
                                      </template>
                                      <template #option="{ first_name, last_name, email }">
                                        <span>{{ first_name }} {{ last_name }}</span>
                                      </template>
                                    </v-select>
                                    <VValidate v-if="checkoutData.event.package_total_runners == 1" name="First Name" v-model="(form.co_roommate[k] || {}).first_name" :rules="{ required: true, min: 3, max: 96, regex: /^([A-Za-z' ]+)$/ }" />
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <b-form-checkbox
                                      id="is_lastname"
                                      v-model="(form.co_roommate[k] || {}).is_last_name"
                                      name="is_lastname"
                                      class="mb-3"
                                      value="N"
                                      unchecked-value="Y"
                                      @click.native="checkIsLastName(form.co_roommate[k], k)"
                                    >
                                      <span class="normal">This person doesn't have family name / last name</span>
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-ln'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >Last Name</label>
                                    <template v-if="(form.co_roommate[k] || {}).is_last_name == 'N'">
                                      <input class="form-control main-control mt-0" placeholder="e.g. Grahita" type="text" :value="(form.co_roommate[k] || {}).first_name" disabled>
                                    </template>
                                    <template v-else>
                                      <input
                                        :id="k+'-ln'"
                                        type="text"
                                        v-model="(form.co_roommate[k] || {}).last_name"
                                        class="form-control main-control"
                                        placeholder="e.g. Grahita"
                                      />
                                      <VValidate v-if="checkoutData.event.package_total_runners == 1" name="Last Name" v-model="(form.co_roommate[k] || {}).last_name" :rules="{ required: true, min: 3, max: 96, regex: /^([A-Za-z' ]+)$/ }" />
                                    </template>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label 
                                      :for="k+'-age'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >Gender</label>
                                    <v-select
                                      :options="checkoutData.gender_options"
                                      placeholder="-- Gender --"
                                      :reduce="(v) => v.label"
                                      v-model="(form.co_roommate[k] || {}).gender"
                                      :id="k+'-gender'"
                                      label="label"
                                      class="mt-3"
                                    ></v-select>
                                    <VValidate v-if="checkoutData.event.package_total_runners == 1" name="Gender" v-model="(form.co_roommate[k] || {}).gender" :rules="{required: true}" />
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">     
                                    <label 
                                      :for="k+'-age'" 
                                      class="bold"
                                      :class="requiredRoommateClass(
                                        checkoutData.event.package_total_runners, 
                                        checkoutData.event.package_max_person
                                      )"
                                    >Age</label>
                                    <div class="row mt-3">
                                      <div class="col-auto">
                                        <b-form-radio v-model="(form.co_roommate[k] || {}).age" :name="`co${k}age`" value="under 12"
                                          >Under 12 years old</b-form-radio
                                        >
                                      </div>
                                      <div class="col-auto">
                                        <b-form-radio
                                          v-model="(form.co_roommate[k] || {}).age"
                                          :name="`co${k}age`"
                                          value="above 12"
                                          >12 years old and above</b-form-radio
                                        >
                                      </div>
                                    </div>
                                    <VValidate :vid="`co${k}age`" v-if="checkoutData.event.package_total_runners == 1" name="Age" v-model="(form.co_roommate[k] || {}).age" :rules="{required: true}" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>

                        <div class="col-md-12 mt-4">
                          <div class="form-group">
                            <label for="addreq" class="bold">Additional Request</label>
                            <textarea
                              id="addreq"
                              v-model="form.co_additional_request"
                              type="text"
                              class="form-control main-control text-area"
                              placeholder="I want to extend my room 2 days after race..."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="currentStep == 3">
                    <h4 class="extrabold" :class="isOnlyMobile() ? 'text-center' : ''">{{ checkoutContent.overview.title }}</h4>
                    <div :class="isOnlyMobile() ? 'text-center' : ''">
                      <span class="regular"
                        >{{ checkoutContent.overview.description }}</span
                      >
                    </div>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <span class="sub-title-section fs-125em">
                            <div class="line-sub-title"></div>
                            {{ checkoutContent.runner_information_1.title }}
                          </span>

                          <b-tabs>
                            <b-tab title="MAIN RUNNER" active>
                              <div class="top d-flex justify-content-between mb-2 align-items-center">
                                <div>
                                  <h5 class="mb-0 bold">{{ (form.co_runners[0] || {}).co_first_name }} {{ (form.co_runners[0] || {}).co_last_name }}</h5>
                                </div>
                              </div>
                              <div class="d-flex flex-column">
                                <span class="regular grey-color">
                                  Email address: {{ (form.co_runners[0] || {}).co_email_address }} ({{ countryCodeById((form.co_runners[0] || {}).co_country_code) }}{{ (form.co_runners[0] || {}).co_phone_number }})
                                </span>
                                <span class="regular grey-color">
                                  Gender: {{ (form.co_runners[0] || {}).co_gender == 'M' ? 'Male' : 'Female' }}
                                </span>
                                <span class="regular grey-color">
                                  Date of birth: {{ formatDateWithoutWeekday((form.co_runners[0] || {}).co_date_of_birth) }}
                                </span>
                                <span class="regular grey-color">
                                  Passport: {{ (form.co_runners[0] || {}).co_passport_no }} (Expired at
                                  {{ formatDateWithoutWeekday((form.co_runners[0] || {}).co_passport_exp_date) }})
                                </span>
                                <span class="regular grey-color">
                                  Citizenship: {{ (form.co_runners[0] || {}).co_citizenship == null ? 'Indonesia' : citizenshipById((form.co_runners[0] || {}).co_citizenship) }}
                                </span>
                                <span class="regular grey-color">
                                  Country of residence: {{ (form.co_runners[0] || {}).co_country_of_residence == null ? 'Indonesia' : citizenshipById((form.co_runners[0] || {}).co_country_of_residence) }}
                                </span>
                                <span class="regular grey-color">
                                  Street Address 1:
                                  {{ (form.co_runners[0] || {}).co_street_address_1 }}
                                </span>
                                <span class="regular grey-color">
                                  Street Address 2: {{ (form.co_runners[0] || {}).co_street_address_2 || '-' }}
                                </span>
                                <span class="regular grey-color">
                                  Street Address 3: {{ (form.co_runners[0] || {}).co_street_address_3 || '-' }}
                                </span>
                                <template v-if="(form.co_runners[0] || {}).co_is_citizenship_indonesian == 'Y'">
                                  <span class="regular grey-color">
                                    Province / State: {{ (form.co_runners[0] || {}).co_state }}
                                  </span>
                                  <span class="regular grey-color">
                                    City: {{ (form.co_runners[0] || {}).co_city }}
                                  </span>
                                  <span class="regular grey-color">
                                    District: {{ (form.co_runners[0] || {}).co_district ?? "-"}}
                                  </span>
                                  <span class="regular grey-color">
                                    Sub District: {{ (form.co_runners[0] || {}).co_subdistrict ?? "-" }}
                                  </span>
                                  <span class="regular grey-color">
                                    Postal Code: {{ (form.co_runners[0] || {}).co_zip_code ?? "-" }}
                                  </span>
                                </template>
                              </div>
                            </b-tab>

                            <template v-if="isFillSecondRunner == 'Y'">
                              <b-tab title="SECONDARY RUNNER" active>
                                <div class="top d-flex justify-content-between mb-2 align-items-center">
                                  <div>
                                    <h5 class="mb-0 bold">{{ (form.co_runners[1] || {}).co_first_name }} {{ (form.co_runners[1] || {}).co_last_name }}</h5>
                                  </div>
                                </div>
                                <div class="d-flex flex-column">
                                  <span class="regular grey-color">
                                    Email address: {{ (form.co_runners[1] || {}).co_email_address }} ({{ countryCodeById((form.co_runners[1] || {}).co_country_code) }}{{ (form.co_runners[1] || {}).co_phone_number }})
                                  </span>
                                  <span class="regular grey-color">
                                    Gender: {{ (form.co_runners[1] || {}).co_gender == 'M' ? 'Male' : 'Female' }}
                                  </span>
                                  <span class="regular grey-color">
                                    Date of birth: {{ formatDateWithoutWeekday((form.co_runners[1] || {}).co_date_of_birth) ?? "-" }}
                                  </span>
                                  <span class="regular grey-color">
                                    Passport: {{ (form.co_runners[1] || {}).co_passport_no ?? "-" }} (Expired at
                                    {{ formatDateWithoutWeekday((form.co_runners[1] || {}).co_passport_exp_date) ?? "-" }})
                                  </span>
                                  <span class="regular grey-color">
                                    Citizenship: {{ (form.co_runners[1] || {}).co_citizenship == null ? 'Indonesia' : citizenshipById((form.co_runners[1] || {}).co_citizenship) }}
                                  </span>
                                  <span class="regular grey-color">
                                    Country of residence: {{ (form.co_runners[1] || {}).co_country_of_residence == null ? 'Indonesia' : citizenshipById((form.co_runners[1] || {}).co_country_of_residence) }}
                                  </span>
                                  <span class="regular grey-color">
                                    Street Address 1:
                                    {{ (form.co_runners[1] || {}).co_street_address_1 }}
                                  </span>
                                  <span class="regular grey-color">
                                    Street Address 2: {{ (form.co_runners[1] || {}).co_street_address_2 || '-' }}
                                  </span>
                                  <span class="regular grey-color">
                                    Street Address 3: {{ (form.co_runners[1] || {}).co_street_address_3 || '-' }}
                                  </span>
                                </div>
                              </b-tab>
                            </template>
                          </b-tabs>
                          
                          <template v-if="checkoutData.event.package_max_person > 1">
                            <div class="mt-4">
                              <span class="sub-title-section fs-125em">
                                <div class="line-sub-title"></div>
                                {{ checkoutContent.room_setup.title }}
                              </span>
                              <div class="d-flex flex-column">
                                <span class="regular grey-color">
                                  Bed type: {{ form.co_bed_type }}
                                </span>
                                <div class="mt-3">
                                  <span class="bold grey-color">
                                    Roommate
                                  </span>
                                  <div v-for="(v, k) in form.co_roommate" :key="k" class="bg-white mt-3 price-box bdrd-12px py-3 px-3">
                                    <span class="regular grey-color d-block">
                                      Name: {{ v.first_name }} {{ v.last_name }}
                                    </span>
                                    <span class="regular grey-color d-block">
                                      Gender: {{ v.gender }}
                                    </span>
                                    <span class="regular grey-color d-block" v-if="v.age !== null">
                                      Age: {{ v.age == 'above 12' ? '12 years old and above' : 'Under 12 years old' }}
                                    </span>
                                    <span class="regular grey-color d-block" v-else>
                                      Age: -
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>

                          <div class="mt-4">
                            <span class="sub-title-section fs-125em">
                              <div class="line-sub-title"></div>
                              Package
                            </span>
                            <div class="bg-white mt-3 price-box bdrd-12px py-3 px-3">
                              <div class="top d-flex justify-content-between mb-3 align-items-center">
                                <div>
                                  <h5 class="mb-0 bold">{{ (checkoutData.event || {}).package_name }}</h5>
                                </div>
                                <div>
                                  <a href="javascript:;" @click.prevent="showPackageDetails" class="text-link"
                                    >What Will I Get?</a
                                  >
                                </div>
                              </div>
                              <div>
                                <span class="regular grey-color">
                                  {{ (checkoutData.event || {}).package_description }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="mt-4">
                            <span class="sub-title-section fs-125em">
                              <div class="line-sub-title"></div>
                              Timeline of Payment
                            </span>
                            <ul class="timeline">
                              <li v-for="(v, k) in checkoutData.installments" :key="'installment-'+k" class="timeline-item">
                                <div class="timeline-icon">1</div>
                                <h5 class="bold">{{ordinal(k+1)}} Payment</h5>
                                <div class="timeline-item-date bold alpha-color fs-1em mb-3">IDR {{ numFormatter(v.price) }}</div>
                                <p>{{ checkoutContent.payment_starts }} {{ formatDateWithHour(v.expiry_date) }}</p>
                              </li>
                            </ul>
                          </div>

                          <div class="mt-4">
                            <span class="sub-title-section fs-125em">
                              <div class="line-sub-title"></div>
                              Confirmation
                            </span>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="" class="bold"
                                    >{{ checkoutContent.terms }}</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center">
                    <button v-if="!isLoading" class="btn btn-slg btn-primary bold w-100">
                      {{ mySteps.length != currentStep + 1 ? 'Next Step' : 'Continue to Payment' }}
                    </button>
                    <button v-else class="btn btn-slg btn-primary bold w-100" disabled>
                      {{ mySteps.length != currentStep + 1 ? 'Next Step' : 'Continue to Payment' }}
                    </button>
                    <a v-if="currentStep != 0" @click="previousStep" class="text-link medium pointer"
                      >Back to previous step</a
                    >
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
          <div class="col-lg-5" :class="isTablet() ? 'checkout-hero' : ''">
            <div
              class="right-section h-100"
              :style="sideBackground"
              :class="isMobile() ? '' : 'h-100'"
            >
              <div class="bg-overlay" data-style="13"></div>
              <div>
                <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                  <div class="d-flex justify-content-center">
                    <router-link :to="{ name: 'Home' }">
                      <picture>
                        <source
                          media="(min-width: 992px)"
                          :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                          type="image/webp"
                        />
                        <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                        <img
                          class="brand-logo"
                          v-if="isOnlyMobile()"
                          :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="142"
                          height="43"
                        />
                        <img
                          class="brand-logo"
                          v-else
                          :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="175"
                          height="53"
                        />
                      </picture>
                    </router-link>
                  </div>
                  <div class="mt-5">
                    <div v-if="checkoutContent.sub_title" class="label-custom green d-inline">
                      <span class="black">{{ checkoutContent.sub_title }}</span>
                    </div>
                    <div class="title-content mt-3">
                      <h2 class="text-white">{{ (checkoutData.event || {}).event_name }}</h2>
                    </div>
                    <!-- <span class="text-white bold">We're looking for passionate tour guide to join us on our mission. We value clear communication, and responsibility</span> -->
                  </div>
                  <div class="mt-5">
                    <router-link
                      :to="{ name: 'RunningEventDetails', params: { pageSlug: (checkoutData.event || {}).event_slug } }"
                      class="text-white text-underline bold"
                      >Back to Previous Page</router-link>
                    <div class="d-flex" :class="!isMobile() ? 'justify-content-between' : 'flex-column text-center'">
                      <div v-if="!isMobile()" class="copyright-text text-white mt-5">
                        Copyright &copy; 2023 RunDeGlobe. All Rights Reserved
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <AppModal :withPageTitle="false" ref="confirm-email-modal" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/confirm-vect.svg`" alt="Confirmation" class="w-25" />
              <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
              <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
              <ul>
                <li>Email address: {{ (form.co_runners[0] || {}).co_email_address }}</li>
              </ul>
              <p class="regular">
                Payment confirmation will be sent to your email, make sure the inbox is not full and you can receive an email
              </p>
              <div class="d-flex">
                <button type="button" class="btn btn-outline-main ml-0 bold" @click="onChangeEmail">Change email</button>
                <button type="button" class="btn btn-primary bold" @click="onConfirmCheckout">Confirm data</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-package-details" :type="'lg'">
      <template #title-modal>
        <h5>Benefits</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <b-tabs>
            <b-tab title="Benefits" active>
              <div class="col-md-12 px-0">
                <template v-for="(v1, k1) in checkoutData.benefit_categories">
                  <div :key="'cat-'+k1" class="mt-3">
                    <p class="bold text-dark">{{ v1.name }}</p>
                    <ul class="list-item ml-4">
                      <li v-for="(v2, k2) in benefitByCategory(v1.id)" :key="'benefit-'+k2">{{ v2.name }}</li>
                    </ul>
                  </div>
                </template>
              </div>
            </b-tab>
            <b-tab title="Hotel Information">
              <div class="col-md-12 px-0 mt-2">
                <div>
                  <p class="medium mb-1">Hotel Name</p>
                  <span class="bold alpha-color">{{ (checkoutData.event || {}).hotel_name }}</span>
                </div>
                <div class="mt-2">
                  <p class="medium mb-1">Hotel Address</p>
                  <span class="bold alpha-color">{{ (checkoutData.event || {}).hotel_address }}</span>
                </div>
                <div class="d-flex justify-content-between b-grey-black px-3 py-3 mt-3 bdrd-12px">
                  <div>
                    <p class="medium">Check-In</p>
                    <span class="bold alpha-color">{{ formatDateWithoutWeekday((checkoutData.event || {}).hotel_checkin_date) }}</span>
                  </div>
                  <div>
                    <p class="medium">Check-Out</p>
                    <span class="bold alpha-color">{{ formatDateWithoutWeekday((checkoutData.event || {}).hotel_checkout_date) }}</span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-province" :type="'lg'">
      <template #title-modal>
        <h4 class="bold ls-0 mb-0">Provinces / States</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <input type="text" class="form-control main-control" @keyup="getProvincesFilter" v-model="filterProvinces" placeholder="Search..." />
            <div class="my-4 h-100vh fade-in">
              <template v-if="!loading.data">
                <div
                  class="d-flex justify-content-between bb-1px-grey py-4"
                  v-for="(v,k) in provinceData" 
                  :key="k" 
                  @click="chooseProvinces(v.province_name)"
                >
                  <p class="light mb-0">{{ v.province_name }}</p>
                  <!-- <p class="mb-0">{{ v.citizenship_code }}</p> -->
                </div>
              </template>
              <template v-else>
              <div class="gooey">
                <span class="dot"></span>
                <div class="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-cities" :type="'lg'">
      <template #title-modal>
        <h4 class="bold ls-0 mb-0">Cities</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <input type="text" class="form-control main-control" @keyup="getCitiesFilter" v-model="filterCities" placeholder="Search..." />
            <div class="my-4 h-100vh fade-in">
              <template v-if="!loading.data">
                <template v-if="citiesData.length > 0">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in citiesData"
                    :key="k" 
                    @click="chooseCity(v)"
                  >
                    <p class="light mb-0">{{ v.zip_city }}</p>
                  </div>
                </template>
                <template v-else>
                  <p>Select province first</p>
                </template>
              </template>
              <template v-else>
              <div class="gooey">
                <span class="dot"></span>
                <div class="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-district" :type="'lg'">
      <template #title-modal>
        <h4 class="bold ls-0 mb-0">Districts</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <input type="text" class="form-control main-control" @keyup="getDistrictFilter" v-model="filterDistrict" placeholder="Search..." />
            <div class="my-4 h-100vh fade-in">
              <template v-if="!loading.data">
                <template v-if="districtData.length > 0">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in districtData"
                    :key="k" 
                    @click="chooseDistrict(v)"
                  >
                    <p class="light mb-0">{{ v.zip_district }}</p>
                  </div>
                </template>
                <template v-else>
                  <p>Select city first</p>
                </template>
              </template>
              <template v-else>
              <div class="gooey">
                <span class="dot"></span>
                <div class="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-sub-district" :type="'lg'">
      <template #title-modal>
        <h4 class="bold ls-0 mb-0">Sub Districts</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <input type="text" class="form-control main-control" @keyup="getSubDistrictFilter" v-model="filterSubDistrict" placeholder="Search..." />
            <div class="my-4 h-100vh fade-in">
              <template v-if="!loading.data">
                <template v-if="subdistrictData.length > 0">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in subdistrictData"
                    :key="k" 
                    @click="chooseSubDistrict(v)"
                  >
                    <p class="light mb-0">{{ v.zip_sub_district }}</p>
                  </div>
                </template>
                <template v-else>
                  <p>Select district first</p>
                </template>
              </template>
              <template v-else>
              <div class="gooey">
                <span class="dot"></span>
                <div class="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-country-code" :type="'lg'">
      <template #title-modal>
        <h4>Country Code</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <input 
              type="text" 
              class="form-control outline-none main-control" 
              placeholder="-- Search Country --" 
              v-model="filter.search"
              @input="doSearch"
            />
            <div class="my-4 h-100vh fade-in">
              <template>
                <div 
                  v-for="(v, k) in countryCodeList" 
                  :key="k" class="d-flex justify-content-between bb-1px-grey py-4" 
                  @click.prevent="onSelectedCountryCode(v)"
                >
                  <p class="light mb-0">{{ v.countrycode_name }}</p>
                  <p class="mb-0">{{ v.countrycode_dial_code }}</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <ValidationObserver v-slot="{ handleSubmit }" ref="MyNetworkForm">
      <b-modal
        id="networkModal"
        size="lg"
        title="Add a Network"
        @ok.prevent="submitFormMyNetwork"
        @hidden="onCloseNetworkModal"
      >
        <template #default>
          <b-form
            ref="MyNetworkFormElement"
            @submit.prevent="handleSubmit(submitFormMyNetwork)"
          >
            <div v-if="showAlert" class="alert" :class="alertType">
              {{ messageResponse.desc }}
            </div>
            <div class="row pb-3">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label class="required bold ls-0">First &amp; Middle Name</label>
                  <input
                    tabindex="1"
                    type="text"
                    v-model="inputNetwork.input_first_name"
                    class="form-control main-control"
                    placeholder="e.g. Abraham Damar"
                  />
                  <VValidate
                    name="First &amp; Middle Name"
                    v-model="inputNetwork.input_first_name"
                    :rules="{
                      required: true,
                      min: 3,
                      max: 128,
                      regex: /^([A-Za-z' ]+)$/,
                    }"
                  />
                </div>
                <b-form-checkbox
                  tabindex="3"
                  id="input_is_lastname"
                  v-model="inputNetwork.input_is_last_name"
                  name="input_is_lastname"
                  class="mb-3"
                  value="N"
                  unchecked-value="Y"
                  @click.native="checkInputIsLastName(inputNetwork.input_is_last_name)"
                >
                  <span class="normal">This person doesn't have family name / last name</span>
                </b-form-checkbox>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label class="required bold ls-0">Last Name</label>
                  <template v-if="inputNetwork.input_is_last_name == 'N'">
                    <input
                      type="text"
                      class="form-control main-control"
                      placeholder="e.g. Grahita"
                      :value="inputNetwork.input_first_name"
                      disabled
                    />
                  </template>
                  <template v-else>
                    <input
                      tabindex="2"
                      type="text"
                      v-model="inputNetwork.input_last_name"
                      class="form-control main-control"
                      placeholder="e.g. Grahita"
                    />
                    <VValidate
                      name="Last Name"
                      v-model="inputNetwork.input_last_name"
                      :rules="{
                        required: inputNetwork.input_is_last_name == 'N' ? false : true,
                        min: 3,
                        max: 96,
                        regex: /^([A-Za-z' ]+)$/,
                      }"
                    />
                  </template>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-group">
                  <label class="required bold ls-0">Gender</label>
                  <div class="d-flex mt-3">
                    <b-form-radio v-model="inputNetwork.input_gender" name="gender" value="M"
                      >Male</b-form-radio
                    >
                    <b-form-radio
                      v-model="inputNetwork.input_gender"
                      class="ml-3"
                      name="gender"
                      value="F"
                      >Women</b-form-radio
                    >
                  </div>
                  <VValidate
                    name="Gender"
                    v-model="inputNetwork.input_gender"
                    :rules="{ required: true }"
                  />
                </div>
              </div>
              <div class="col-md-9 col-12">
                <div class="form-group">
                  <label class="bold ls-0">Phone Number</label>
                  <div class="input-group d-flex mt-3">
                    <v-select
                      v-model="inputNetwork.input_dial_code"
                      placeholder="Code"
                      :options="countryCodeData"
                      :reduce="(v) => v.countrycode_id"
                      id="countrycode"
                      class="pointer w-25"
                      :get-option-label="(o) => o.countrycode_name + ' ' + o.countrycode_dial_code"
                    >
                      <template #option="{ countrycode_dial_code, countrycode_name }">
                        <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                      </template>
                      <template #selected-option="{ countrycode_dial_code }">
                        <span>{{ countrycode_dial_code }}</span>
                      </template>
                    </v-select>
                    <b-form-input
                      tabindex="4"
                      v-model="inputNetwork.input_no_telfon"
                      class="form-control main-control group-item mt-0"
                      placeholder="Phone Number"
                      :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                    ></b-form-input>
                  </div>
                  <VValidate
                    vclass="d-block"
                    name="Country Code"
                    v-model="inputNetwork.input_dial_code"
                    :rules="{ required: true }"
                  />
                  <VValidate
                    vclass="d-block"
                    name="Phone Number"
                    v-model="inputNetwork.input_no_telfon"
                    :rules="{ required: true, min: 5, max: 16 }"
                  />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label class="required bold ls-0">Email Address</label>
                  <input
                    tabindex="5"
                    type="text"
                    v-model="inputNetwork.input_email"
                    class="form-control main-control"
                    placeholder="e.g. abraham.damar@gmail.com"
                  />
                  <VValidate
                    name="Email Address"
                    v-model="inputNetwork.input_email"
                    :rules="{
                      required: true,
                      max: 128,
                      email: true
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-group">
                  <label class="required bold ls-0">Age</label>
                  <div class="d-flex flex-column mt-3">
                    <b-form-radio v-model="inputNetwork.input_age" name="age" value="under 12"
                      >Under 12 years old</b-form-radio
                    >
                    <b-form-radio
                      v-model="inputNetwork.input_age"
                      name="age"
                      value="above 12"
                      >12 years old and above</b-form-radio
                    >
                  </div>
                  <VValidate
                    name="Age"
                    v-model="inputNetwork.input_age"
                    :rules="{ required: true }"
                  />
                </div>
              </div>
            </div>
          </b-form>
        </template>
        <template #modal-footer="{ ok, cancel }">
          <button
            class="btn btn-outline-main mr-2"
            @click="cancel()"
            >Cancel</button
          >
          <button
            type="submit"
            class="btn btn-primary"
            @click="ok()"
            >Submit</button
          >
        </template>
      </b-modal>
    </ValidationObserver>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Global from '../libs/Global.vue'
import StepProgress from 'vue-step-progress'
import 'vue-step-progress/dist/main.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import AppModal from '../components/AppModal.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { http } from '@/config/http'
import Gen from '@/libs/Gen'
import dayjs from 'dayjs'
import AppAlert from '@/components/AppAlert'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  extends: Global,
  name: 'Checkout',
  components: {
    'step-progress': StepProgress,
    VueTimepicker,
    AppModal,
    AppAlert,
    PulseLoader,
  },
  data() {
    return {
      isLoading: false,
      reuseEmailErr: false,
      isFillSecondRunner: 'N',
      isFillSecondRunnerDetail: 'N',
      secondRunnerMemberStatus: null,
      secondRunnerMemberName: null,
      secondRunnerMemberEmail: null,
      showAlert: false,
      messageResponse: {
        title: '',
        desc: '',
      },
      filter: {
        search: '',
      },
      isWebPSupport: false,
      minimumAge: 16,
      timeoutId: null,
      citizenshipList: {
        data: [],
      },
      countryCodeList: {
        data: [],
      },
      submitInterval: false,
      roommateList: [
        {
          first_name: null,
          last_name: null,
          age: null,
        },
      ],
      size: [
        {
          name: 'Women XS',
        },
        {
          name: 'Women S',
        },
        {
          name: 'Women M',
        },
        {
          name: 'Women L',
        },
        {
          name: 'Women XL',
        },
        {
          name: 'Men XS',
        },
        {
          name: 'Men S',
        },
        {
          name: 'Men M',
        },
        {
          name: 'Men L',
        },
        {
          name: 'Men XL',
        },
      ],
      importantFeatures: [
        {
          name: 'Location of the Hotel (it is all about convenience)',
        },
        {
          name: "Price (as cheap as you can be. I don't care about other things!)",
        },
        {
          name: 'Photographer before the race (it is important for my documentation!)',
        },
        {
          name: 'Running with friends (running marathons can be so much fun when done together)',
        },
        {
          name: "Value (It's all about making sure we get slightly more than what we pay for!)",
        },
        {
          name: 'Help with my preparation (training programs, track availability)',
        },
        {
          name: 'Help with nutrition/recovery (deals with nutritions and recovery)',
        },
      ],
      mySteps: ['1', '2', '3', '4'],
      currentStep: 0,
      isSelectedCertified: 'N',
      tabsoverflow: 0,
      isIndonesian: 'Y',
      isCountryOfResidenceIndonesia: 'Y',
      isExtraBed: 'N',
      isMedicalInformation: 'Y',
      isFirstMarathon: 'Y',
      selected_state: '',
      selected_city: '',
      selected_district: '',
      selected_subdistrict: '',
      form: {
        co_runners: [
          {
            co_first_name: '',
            co_last_name: '',
            co_email_address: '',
            co_phone_number: '',
            co_country_code: null,
            co_gender: '',
            co_date_of_birth: '',
            co_is_citizenship_indonesian: 'Y',
            co_citizenship: null,
            co_passport_no: '',
            co_passport_exp_date: '',
            co_is_country_of_residence_indonesian: 'Y',
            co_country_of_residence: null,
            co_street_address_1: '',
            co_street_address_2: '',
            co_street_address_3: '',
            co_state: '',
            co_city: '',
            co_district: '',
            co_subdistrict: '',
            co_zip_code: '',
          },
        ],
        co_bed_type: '',
        co_roommate: [
          {
            first_name: null,
            last_name: null,
            is_last_name: null,
            gender: null,
            age: null,
          },
        ],
        co_additional_request: '',
        co_is_extra_bed: 'N',
      },
    }
  },

  computed: {
    ...mapFields(['user', 'channels', 'loading']),
    ...mapFields('useCheckoutStore', ['checkoutData', 'checkoutContent']),
    ...mapFields('useMyNetworkStore', ['inputNetwork']),
    ...mapFields('profile', [
      'provinceData',
      'citiesData',
      'districtData',
      'subdistrictData',
      'filterCountry',
      'filterCitizenship',
      'filterProvinces',
      'filterCities',
      'filterDistrict',
      'filterSubDistrict',
      'countryCodeData',
    ]),

    inputSelectedState: {
      get() {
        return this.selected_state
      },
      set(newValue) {
        this.selected_state = newValue
        // this.form.co_runners.co_state = newValue
        // this.form.co_runners.co_city = null
        this.$set((this.form || {}).co_runners[0], 'co_state', newValue)
        this.$set((this.form || {}).co_runners[0], 'co_city', null)
        // this.$set(this, 'selected_city', null)
        this.selected_city = null

        this.$set((this.form || {}).co_runners[0], 'co_district', null)
        // this.$set(this, 'selected_district', null)
        this.selected_district = null

        this.$set((this.form || {}).co_runners[0], 'co_subdistrict', null)
        // this.$set(this, 'selected_subdistrict', null)
        this.selected_subdistrict = null

        this.$set((this.form || {}).co_runners[0], 'co_zip_code', null)
        // this.$set(this, 'zip_code', null)
        this.zip_code = null
      },
    },
    inputSelectedCity: {
      get() {
        return this.selected_city
      },
      set(newValue) {
        // this.$set(this, 'selected_city', (newValue || {}).zip_city || null)
        this.selected_city = (newValue || {}).zip_city
        this.$set((this.form || {}).co_runners[0], 'co_city', (newValue || {}).zip_city || null)

        this.$set((this.form || {}).co_runners[0], 'co_district', null)
        // this.$set(this, 'selected_district', null)
        this.selected_district = null

        this.$set((this.form || {}).co_runners[0], 'co_subdistrict', null)
        // this.$set(this, 'selected_subdistrict', null)
        this.selected_subdistrict = null

        this.$set((this.form || {}).co_runners[0], 'co_zip_code', null)
        // this.$set(this, 'zip_code', null)
        this.zip_code = null
      },
    },

    inputSelectedDistrict: {
      get() {
        return this.selected_district
      },
      set(newValue) {
        // this.$set(this, 'selected_district', (newValue || {}).zip_district || null)
        this.selected_district = (newValue || {}).zip_district
        this.$set((this.form || {}).co_runners[0], 'co_district', (newValue || {}).zip_district || null)

        this.$set((this.form || {}).co_runners[0], 'co_subdistrict', null)
        // this.$set(this, 'selected_subdistrict', null)
        this.selected_subdistrict = null

        this.$set((this.form || {}).co_runners[0], 'co_zip_code', null)
        // this.$set(this, 'zip_code', null)
        this.zip_code = null
      },
    },

    inputSelectedSubDistrict: {
      get() {
        return this.selected_subdistrict
      },
      set(newValue) {
        // this.$set(this, 'selected_subdistrict', (newValue || {}).zip_sub_district || null)
        this.selected_subdistrict = (newValue || {}).zip_sub_district
        this.$set((this.form || {}).co_runners[0], 'co_subdistrict', (newValue || {}).zip_sub_district || null)

        // this.$set(this, 'zip_code', (newValue || {}).zip_zipcode || null)
        this.zip_code = (newValue || {}).zip_zipcode
        this.$set((this.form || {}).co_runners[0], 'co_zip_code', (newValue || {}).zip_zipcode || null)
      },
    },

    sideBackground() {
      let src = ''

      src = this.uploader(
        this.isWebPSupport
          ? (this.checkoutData.event || {}).banner_image
          : (this.checkoutData.event || {}).banner_fallback_image
      )

      if (src == '') {
        return null
      }

      return `background-image: url('${src}'); background-position: center; background-size: cover;`
    },

    minimumAgePage() {
      const minimumdate = dayjs().subtract(this.minimumAge, 'year')

      return {
        month: minimumdate.format('MM'),
        year: minimumdate.format('YYYY'),
      }
    },
    isLastStep() {
      return this.currentStep === this.mySteps.length - 1
    },

    minPassportExpiration() {
      return new Date()
    },

    maxDateOfBirth() {
      let dateNow = new Date()

      const year = dateNow.getFullYear()
      const month = dateNow.getMonth()
      const day = dateNow.getDate()

      let conf = new Date(year - 11, month - 1, day)
      return conf
    },
  },

  created() {
    this.getEventData({
      slug: this.$route.params.pageSlug,
      itinerary: this.$route.query.itinerary,
      pkg: this.$route.query.package,
      callback: (checkoutData, checkoutContent) => {
        if (checkoutData.is_closed) {
          this.$router.push({
            name: 'RunningEventDetails',
            params: { pageSlug: ((checkoutData || {}).event || {}).event_slug },
          })
          return null
        }

        if (checkoutData.no_partial && (this.$route.query || {}).po === 'partial-payment') {
          this.$router.push({
            name: 'RunningEventDetails',
            params: { pageSlug: ((checkoutData || {}).event || {}).event_slug },
          })
          return null
        }

        if ((checkoutData.event || {}).package_total_runners > (checkoutData || {}).event.stock) {
          this.$router.push({
            name: 'RunningEventDetails',
            params: { pageSlug: ((checkoutData || {}).event || {}).event_slug },
          })
          return null
        }

        if (checkoutData.event.package_total_runners > 1 && this.form.co_runners.length === 1) {
          const obr = {
            co_first_name: '',
            co_last_name: '',
            co_email_address: '',
            co_phone_number: '',
            co_country_code: checkoutContent.country,
            co_is_citizenship_indonesian: '',
            co_gender: '',
            co_date_of_birth: '',
            co_citizenship: checkoutContent.citizenship,
            co_passport_no: '',
            co_passport_exp_date: '',
            co_is_country_of_residence_indonesian: '',
            co_country_of_residence: checkoutContent.citizenship,
            co_street_address_1: '',
            co_street_address_2: '',
            co_street_address_3: '',
            co_additional_request: '',
            co_is_extra_bed: '',
            co_bed_type: '',
            is_last_name: '',
          }

          this.form.co_runners.push(obr)
        }
      },
    })

    this.getCitizenshipList()
    this.getCountryCodeList()
    this.getCities()
    this.getProvince()
    this.getCountryCode()

    this.form.co_runners[0].co_first_name = (this.user || {}).registrant_first_name
    this.form.co_runners[0].co_last_name = (this.user || {}).registrant_last_name
    this.form.co_runners[0].co_email_address = (this.user || {}).registrant_email_address
    this.form.co_runners[0].co_country_code = (this.user || {}).registrant_country_code || this.checkoutContent.country
    this.form.co_runners[0].co_phone_number = (this.user || {}).registrant_phone
    this.form.co_runners[0].co_gender = (this.user || {}).registrant_gender
    this.form.co_runners[0].co_date_of_birth = (this.user || {}).registrant_birth_date
    this.form.co_runners[0].co_is_citizenship_indonesian = (this.user || {}).registrant_citizenship_flag
    this.form.co_runners[0].co_citizenship = (this.user || {}).registrant_citizenship || this.checkoutContent.country
    this.form.co_runners[0].co_passport_number = (this.user || {}).registrant_passportno
    this.form.co_runners[0].co_passport_exp_date = (this.user || {}).registrant_passportexp
    this.form.co_runners[0].co_street_address_1 = (this.user || {}).registrant_address_1
    this.form.co_runners[0].co_street_address_2 = (this.user || {}).registrant_address_2
    this.form.co_runners[0].co_street_address_3 = (this.user || {}).registrant_address_3
    this.form.co_runners[0].co_is_country_of_residence_indonesian = (
      this.user || {}
    ).registrant_country_of_residence_flag
    this.form.co_runners[0].co_country_of_residence = parseInt((this.user || {}).registrant_country_of_residence)
    this.form.co_runners[0].co_state = (this.user || {}).registrant_state
    this.selected_state = (this.user || {}).registrant_state
    this.form.co_runners[0].co_city = (this.user || {}).registrant_city
    this.selected_city = (this.user || {}).registrant_city
    this.form.co_runners[0].co_district = (this.user || {}).registrant_district
    this.selected_district = (this.user || {}).registrant_district
    this.form.co_runners[0].co_subdistrict = (this.user || {}).registrant_subdistrict
    this.selected_subdistrict = (this.user || {}).registrant_subdistrict
    this.form.co_runners[0].co_zip_code = (this.user || {}).registrant_zip_code
    this.selected_zip_code = (this.user || {}).registrant_zip_code

    this.$store.commit('SET_STATIC_SEO', {
      title: 'Checkout',
    })

    this.getChannels()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },

  methods: {
    ...mapActions('useCheckoutStore', ['getEventData', 'postTransactionPayload', 'checkSecondRunner']),
    ...mapActions('useMyNetworkStore', ['addNewNetwork']),
    ...mapActions(['getChannels']),
    ...mapActions('profile', ['getCities', 'getProvince', 'getDistrict', 'getSubDistrict', 'getCountryCode']),

    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },

    secondRunnerEmailIsEmpty() {
      if ((this.form.co_runners[1] || {}).co_email_address === null) {
        return true
      } else if ((this.form.co_runners[1] || {}).co_email_address === '') {
        return true
      }

      return false
    },

    checkSecondRunnerMemberStatus() {
      this.doDebounce(() => {
        this.isLoading = true

        if (this.secondRunnerEmailIsEmpty()) {
          this.isLoading = false;
          return null
        }
  
        this.checkSecondRunner({
          email: (this.form.co_runners[1] || {}).co_email_address,
          callback: (success, res) => {
            if (success) {
              this.secondRunnerMemberName = res.data.name
              this.secondRunnerMemberEmail = res.data.email
              this.secondRunnerMemberStatus = res.data.status
            } else {
              this.reuseEmailErr = true
            }

            this.isLoading = false;
          },
        })
      }, 500)
    },

    onCloseNetworkModal() {
      this.$store.commit('useMyNetworkStore/CLEAR_INPUT_FORM_NETWORK')
    },

    submitFormMyNetwork() {
      this.$refs.MyNetworkForm.validate().then(async (success) => {
        if (success) {
          this.addNewNetwork((success, res) => {
            if (success) {
              this.alertType = 'alert-success'
              this.messageResponse = res.data.message
              this.showAlert = true
              document.getElementById('networkModal').scrollTop = 0
              ;(this.checkoutData.network_list || []).unshift(res.data.data)

              setTimeout(() => {
                this.showAlert = false
                this.alertType = ''
                this.messageResponse = {
                  title: '',
                  desc: '',
                }
                this.$bvModal.hide('networkModal')
              }, 3000)
            } else {
              this.alertType = 'alert-danger'
              this.messageResponse = res.response.data.message
              this.showAlert = true
              document.getElementById('networkModal').scrollTop = 0

              setTimeout(() => {
                this.showAlert = false
                this.alertType = ''
                this.messageResponse = {
                  title: '',
                  desc: '',
                }
                this.$bvModal.hide('networkModal')
              }, 3000)
            }
          })
        }
      })
    },

    checkIsLastName(data, index) {
      if (data.is_last_name == 'Y') {
        this.form.co_roommate[index].last_name = data.first_name
      } else {
        this.form.co_roommate[index].last_name = ''
      }
    },

    checkIsLastNameSecondRunner(data, index) {
      if (data.is_last_name == 'Y') {
        this.form.co_runners[1].co_last_name = data.first_name
      } else {
        this.form.co_runners[1].co_last_name = ''
      }
    },

    checkInputIsLastName(data, index) {
      // if(data.input_is_last_name == 'Y') {
      //   this.form.co_roommate[index].last_name = data.first_name
      // } else {
      //   this.form.co_roommate[index].last_name = ''
      // }
    },

    setSelectedRoommate(o, index) {
      ;(this.form.co_roommate[index] || {}).first_name = o.first_name
      ;(this.form.co_roommate[index] || {}).last_name = o.last_name
      ;(this.form.co_roommate[index] || {}).gender = o.gender == 'M' ? 'Male' : 'Female'
      ;(this.form.co_roommate[index] || {}).age = o.age
      ;(this.form.co_roommate[index] || {}).is_last_name = o.is_last_name
    },

    setSelectedSecondRunner(o) {
      this.form.co_runners[1].co_first_name = o.first_name
      this.form.co_runners[1].co_last_name = o.last_name
      this.form.co_runners[1].co_gender = o.gender
      this.form.co_runners[1].co_email_address = o.email
      this.form.co_runners[1].co_country_code = parseInt(o.country_code)
      this.form.co_runners[1].co_phone_number = o.phone_number
      this.form.co_runners[1].is_last_name = o.is_last_name
      this.isFillSecondRunner = "Y"
      this.checkSecondRunnerMemberStatus()
    },

    doSearch() {
      this.isTyping = true
      this.isLoading = true
      this.doDebounce(() => {
        this.isTyping = false

        this.getCountryCodeList({
          search: this.filter.search,
          callback: () => {
            this.isLoading = false
          },
          loading: false,
        })
      }, 500)
    },

    getProvincesFilter() {
      this.debounce(() => {
        this.getProvince()
      }, 500)
    },

    getCitiesFilter() {
      const province = ((this.form || {}).co_runners[0] || {}).co_state

      this.debounce(() => {
        this.getCities(province)
      }, 500)
    },

    getDistrictFilter() {
      const city = ((this.form || {}).co_runners[0] || {}).co_city
      this.debounce(() => {
        this.getDistrict(city)
      }, 500)
    },

    getSubDistrictFilter() {
      const district = ((this.form || {}).co_runners[0] || {}).co_district
      this.debounce(() => {
        this.getSubDistrict(district)
      }, 500)
    },

    scrollToTop(qs) {
      const scrollPosition = document.querySelector(qs).scrollTop

      if (scrollPosition === undefined) {
        return null
      }

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - scrollPosition / 8)
      }
    },

    showSearchModal(modalId, inputId) {
      this.$refs[modalId].show()
      document.querySelector(`#${inputId} .vs__search`).blur()
      this.scrollToTop('.second-window .content')
    },

    formatDayJs(date, format, index) {
      return dayjs(date).format(format)
    },
    defineToPage(value, min, index) {
      if (['', null, undefined].indexOf(value) == -1) {
        return {
          month: this.formatDayJs(value, 'M'),
          year: this.formatDayJs(value, 'YYYY'),
        }
      } else if (['', null, undefined].indexOf(min) == -1) {
        return min
      }

      return undefined
    },

    citizenshipById(id) {
      return ((this.citizenshipList.data || []).find((c) => c.citizenship_id === id) || {}).citizenship_name
    },
    requiredRoommateClass(totalRunners, maxPerson) {
      const isRequired = totalRunners == 1 && maxPerson > 1
      return isRequired ? 'required' : ''
    },
    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },
    countryCodeById(id) {
      return ((((this || {}).countryCodeList || {}).data || []).find((c) => c.countrycode_id === id) || {}).countrycode_dial_code
    },
    ordinal(number) {
      let ordinalNumber = ''

      switch (number) {
        case 1:
          ordinalNumber = number + 'st'
          break

        case 2:
          ordinalNumber = number + 'nd'
          break

        case 3:
          ordinalNumber = number + 'rd'
          break

        default:
          ordinalNumber = number + 'th'
          break
      }

      return ordinalNumber
    },
    benefitByCategory(categoryId) {
      return (this.checkoutData.benefit_items || []).filter((b) => b.categoryId === categoryId)
    },
    async getCitizenshipList() {
      const res = await http.get('/citizenship', {
        headers: Gen.apiHeader(),
      })

      this.citizenshipList = res.data.data
    },
    async getCountryCodeList() {
      const res = await http.get('/country-code', {
        headers: Gen.apiHeader(),
      })

      this.countryCodeList = res.data.data
    },
    showPackageDetails() {
      this.$refs['modal-package-details'].show()
    },
    scrollToLeftSection() {
      const el = document.querySelector('.left-section')

      if (el) {
        const elPosition = el.getBoundingClientRect().top
        const offsetPosition = elPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition - 0,
          behavior: 'smooth',
        })
      }
    },

    addRoommate() {
      const obj = {
        first_name: null,
        last_name: null,
        age: null,
      }

      this.form.co_roommate.push(obj)
    },

    removeRoommate(k) {
      this.form.co_roommate.splice(k, 1)
    },

    onChangeEmail() {
      this.$refs['confirm-email-modal'].hide()
    },

    onConfirmCheckout() {
      this.$store.state.loading.screen = true
      return this.doDebounce(this.checkoutOrder, 300)
    },

    nextStep() {
      this.submitInterval = false
      
      if ((this.form_co_runners || []).length > 1) {
        if (this.form.co_runners[0].co_email_address == this.form.co_runners[1].co_email_address) {
          this.reuseEmailErr = true
          return null
        } else {
          this.reuseEmailErr = false
        }
      }

      if (this.isLastStep) {
        this.$refs['confirm-email-modal'].show()
      }

      if (this.currentStep == 0 && this.form.co_runners[1] !== undefined) {
        setTimeout(() => {
          this.$set(this.form.co_runners[1], 'co_is_citizenship_indonesian', 'Y')
          this.$set(this.form.co_runners[1], 'co_is_country_of_residence_indonesian', 'Y')
        }, 300)
      }

      if (this.mySteps.length != this.currentStep + 1) {
        this.currentStep++
        this.scrollToLeftSection()
        const el = (document.querySelector('.left-section').scrollTop -= 900)
        this.tabsoverflow = el

        setTimeout(() => {
          this.submitInterval = true
        }, 500)
      } else {
      }
    },

    async checkoutOrder() {
      const payload_2 = {
        first_name: (this.form.co_runners[1] || {}).co_first_name,
        last_name: (this.form.co_runners[1] || {}).co_last_name,
        email_address: (this.form.co_runners[1] || {}).co_email_address,
        phone_number: (this.form.co_runners[1] || {}).co_phone_number,
        country_code: (this.form.co_runners[1] || {}).co_country_code,
        gender: (this.form.co_runners[1] || {}).co_gender,
        date_of_birth: (this.form.co_runners[1] || {}).co_date_of_birth,
        citizenship_flag: (this.form.co_runners[1] || {}).co_is_citizenship_indonesian,
        citizenship: (this.form.co_runners[1] || {}).co_citizenship,
        no_passport: (this.form.co_runners[1] || {}).co_passport_no,
        expiration_passport: (this.form.co_runners[1] || {}).co_passport_exp_date,
        country_of_residence_flag: (this.form.co_runners[1] || {}).co_is_country_of_residence_indonesian,
        country_of_residence: (this.form.co_runners[1] || {}).co_country_of_residence,
        street_address_1: (this.form.co_runners[1] || {}).co_street_address_1,
        street_address_2: (this.form.co_runners[1] || {}).co_street_address_2,
        street_address_3: (this.form.co_runners[1] || {}).co_street_address_3,
      }

      const payload_1 = {
        first_name: (this.form.co_runners[0] || {}).co_first_name,
        last_name: (this.form.co_runners[0] || {}).co_last_name,
        email_address: (this.form.co_runners[0] || {}).co_email_address,
        country_code: (this.form.co_runners[0] || {}).co_country_code,
        phone_number: (this.form.co_runners[0] || {}).co_phone_number,
        gender: (this.form.co_runners[0] || {}).co_gender,
        date_of_birth: (this.form.co_runners[0] || {}).co_date_of_birth,
        citizenship_flag: (this.form.co_runners[0] || {}).co_is_citizenship_indonesian,
        citizenship: (this.form.co_runners[0] || {}).co_citizenship,
        no_passport: (this.form.co_runners[0] || {}).co_passport_no,
        expiration_passport: (this.form.co_runners[0] || {}).co_passport_exp_date,
        country_of_residence_flag: (this.form.co_runners[0] || {}).co_is_country_of_residence_indonesian,
        country_of_residence: (this.form.co_runners[0] || {}).co_country_of_residence,
        street_address_1: (this.form.co_runners[0] || {}).co_street_address_1,
        street_address_2: (this.form.co_runners[0] || {}).co_street_address_2,
        street_address_3: (this.form.co_runners[0] || {}).co_street_address_3,
        state: (this.form.co_runners[0] || {}).co_state,
        city: (this.form.co_runners[0] || {}).co_city,
        district: (this.form.co_runners[0] || {}).co_district,
        subdistrict: (this.form.co_runners[0] || {}).co_subdistrict,
        zip_code: (this.form.co_runners[0] || {}).co_zip_code,
        transaction_type: this.$route.query.po,
        itinerary: this.$route.query.itinerary,
        package: this.$route.query.package,
        slug: this.$route.params.pageSlug,
        bed_type: (this.form || {}).co_bed_type,
        roommate: (this.form || {}).co_roommate,
        is_extra_bed: (this.form || {}).co_is_extra_bed,
        additional_request: (this.form || {}).co_additional_request,
        second_runner: payload_2,
      }

      this.postTransactionPayload({
        payload: payload_1,
        callback: () => {
          this.form = {
            co_runners: [
              {
                co_first_name: '',
                co_last_name: '',
                co_email_address: '',
                co_phone_number: '',
                co_country_code: null,
                co_gender: '',
                co_date_of_birth: '',
                co_is_citizenship_indonesian: 'Y',
                co_citizenship: null,
                co_passport_no: '',
                co_passport_exp_date: '',
                co_is_country_of_residence_indonesian: 'Y',
                co_country_of_residence: null,
                co_street_address_1: '',
                co_street_address_2: '',
                co_street_address_3: '',
              },
            ],
            co_bed_type: '',
            co_roommate: [
              {
                first_name: null,
                last_name: null,
                is_last_name: null,
                gender: null,
                age: null,
              },
            ],
            co_additional_request: '',
            co_is_extra_bed: 'N',
          }
        },
      })
    },

    previousStep() {
      if (this.currentStep != 0) {
        this.currentStep--
      }
      this.scrollToLeftSection()
      const el = (document.querySelector('.left-section').scrollTop -= 900)
      this.tabsoverflow = el
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
    chooseProvinces(data) {
      this.selected_state = data
      this.$set((this.form || {}).co_runners[0], 'co_state', data)
      this.$set((this.form || {}).co_runners[0], 'co_city', null)
      this.selected_city = null

      this.$set((this.form || {}).co_runners[0], 'co_district', null)
      this.selected_district = null

      this.$set((this.form || {}).co_runners[0], 'co_subdistrict', null)
      this.selected_subdistrict = null

      this.$set((this.form || {}).co_runners[0], 'co_zip_code', null)
      this.zip_code = null

      this.$refs['modal-province'].hide()
    },
    chooseCity(data) {
      this.selected_city = (data || {}).zip_city
      this.$set((this.form || {}).co_runners[0], 'co_city', (data || {}).zip_city || null)

      this.$set((this.form || {}).co_runners[0], 'co_district', null)
      this.selected_district = null

      this.$set((this.form || {}).co_runners[0], 'co_subdistrict', null)
      this.selected_subdistrict = null

      this.$set((this.form || {}).co_runners[0], 'co_zip_code', null)
      this.zip_code = null

      this.$refs['modal-cities'].hide()
    },
    chooseDistrict(data) {
      this.$set((this.form || {}).co_runners[0], 'co_district', (data || {}).zip_district || null)
      this.selected_district = (data || {}).zip_district

      this.$set((this.form || {}).co_runners[0], 'co_subdistrict', null)
      this.selected_subdistrict = null

      this.$set((this.form || {}).co_runners[0], 'co_zip_code', null)
      this.zip_code = null

      this.$refs['modal-district'].hide()
    },
    chooseSubDistrict(data) {
      this.$set((this.form || {}).co_runners[0], 'co_subdistrict', (data || {}).zip_sub_district || null)
      this.selected_subdistrict = (data || {}).zip_sub_district

      this.$set((this.form || {}).co_runners[0], 'co_zip_code', (data || {}).zip_zipcode || null)
      this.zip_code = (data || {}).zip_zipcode

      this.$refs['modal-sub-district'].hide()
    },
  },

  watch: {
    selected_state(old) {
      this.getCities(old)
    },
    selected_city(old) {
      this.getDistrict(old)
    },

    selected_district(old) {
      this.getSubDistrict(old)
    },
  },
}
</script>
<style scoped>
.form-select.main-control {
  border-radius: 2em;
  min-height: 45px !important;
  margin-top: 1em;
  font-size: 1em !important;
  font-family: 'Metropolis-Semibold' !important;
}
.form-select.main-control::placeholder {
  color: #cccccc !important;
  opacity: 1;
  font-family: 'Metropolis-Semibold' !important;
}
</style>

<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background-color: #95378e;
}
.v-select.b-rd-r-0 .vs__dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.checkout-hero {
  max-height: 40vh;
}
</style>

import { getField, updateField } from 'vuex-map-fields'
import { http } from '@/config/http'
import Gen from '@/libs/Gen'

export default {
  namespaced: true,
  state: {
    paymentData: {
      payment_link: '',
      installments: [],
      payment_categories: [],
      payment_channels: [],
      due_text: '',
      due_text_bg: '',
      active_payment_text: '',
      active_payment_bg: '',
      sub_title_1: '',
      sub_title_2: '',
    },
    paymentStatusData: {
      payment_status: '',
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_PAYMENT_DATA(state, data = {}) {
      state.paymentData = data.data
    },
    SET_PAYMENT_STATUS_DATA(state, data = {}) {
      state.paymentStatusData = data.data
    },
  },

  actions: {
    async getPaymentData({ state, commit, rootState }, param = {}) {
      const { path, callback = false } = param

      try {
        if (JSON.stringify(param) === "{}") {
          return
        }

        rootState.loading.screen = true

        const token = Gen.getCookie("userAuthRdg")

        const res = await http.get(`/transaction/${path}/summary`, {
          headers: {
            ...Gen.apiHeaderAuth(token),
          },
        })

        if (callback) {
          callback(res.data)
        }

        commit("SET_PAYMENT_DATA", res.data)
      } catch (err) {
        if (callback) {
          callback({})
        }
        if ((err.response || {}).status === 400) {
          router.push({ name: "Home" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
        if ((err.response || {}).status === 301) {
          router.push({ name: err.response.data.redirect_to, params: {pageSlug: err.response.data.trx_id} })
        }
        if ((err.response || {}).status === 401) {
          router.push({
            name: "Login",
            query: { ref: router.currentRoute.fullPath },
          })
        }
      } finally {
        rootState.loading.screen = false
      }
    },
    async getPaymentStatus({ state, commit, rootState }, param = {}) {
      const { path, callback = false } = param

      try {
        if (JSON.stringify(param) === "{}") {
          return
        }

        rootState.loading.screen = true

        const token = Gen.getCookie("userAuthRdg")

        const res = await http.get(`/payment/${path}/status`, {
          headers: {
            ...Gen.apiHeaderAuth(token),
          },
        })

        if (callback) {
          callback(res.data)
        }

        commit("SET_PAYMENT_STATUS_DATA", res.data)
      } catch (err) {
        if (callback) {
          callback({})
        }
        if ((err.response || {}).status === 400) {
          router.push({ name: "Home" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
        if ((err.response || {}).status === 301) {
          router.push({ name: err.response.data.redirect_to, params: {pageSlug: err.response.data.trx_id} })
        }
        if ((err.response || {}).status === 401) {
          router.push({
            name: "Login",
            query: { ref: router.currentRoute.fullPath },
          })
        }
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <footer id="footer">
    <div class="footer-content text-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="widget">
              <div class="widget-title">
                <router-link :to="{ name: 'Home' }" class="mr-5">
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                      type="image/webp"
                    />
                    <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                    <img
                      class="brand-logo"
                      v-if="isOnlyMobile()"
                      :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                      alt="RunDeGlobe Logo"
                      width="142"
                      height="43"
                    />
                    <img
                      class="brand-logo"
                      v-else
                      :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                      alt="RunDeGlobe Logo"
                      width="209"
                      height="63"
                    />
                  </picture>
                </router-link>
              </div>
              <p class="mb-5">
                {{ (((footerData || {}).content || {}).data || {}).description }}
              </p>
              <div class="d-flex align-items-center">
                <i class="icon-phone-call yellow-color fs-1em"></i>
                <a target="_blank" :href="`tel:${((((footerData || {}).contact || {}).data || {}).telephone || '').replaceAll('-', '')}`"
                  ><span class="mx-3">{{ (((footerData || {}).contact || {}).data || {}).telephone }}</span></a
                >
              </div>
              <div class="d-flex align-items-center">
                <i class="fab fa-whatsapp yellow-color fs-1em"></i>
                <a target="_blank" :href="`https://wa.me/${((((footerData || {}).contact || {}).data || {}).mobile_phone || '').replaceAll(/\D/g, '')}`"
                  ><span class="mx-3">{{ (((footerData || {}).contact || {}).data || {}).mobile_phone }}</span></a
                >
              </div>
              <div class="d-flex align-items-start">
                <i class="icon-map-pin yellow-color fs-1em mt-2"></i>
                <span class="mx-3">
                  <a target="_blank" :href="(((footerData.address || {}).data || {}).link || '').replaceAll('\/', '/')" class="d-flex flex-column">
                    <span v-for="(v, k) in (((footerData || {}).address || {}).data || {}).description" :key="k">{{
                      v
                    }}</span>
                  </a>
                </span>
              </div>
              <div class="d-flex align-items-center">
                <i class="icon-mail yellow-color fs-1em"></i>
                <a target="_blank" :href="`mailto:${(((footerData || {}).contact || {}).data || {}).email_address}`"
                  ><span class="mx-3">{{ (((footerData || {}).contact || {}).data || {}).email_address }}</span></a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-6 pr-0">
                <div class="widget">
                  <div class="widget-title bold">Explore Further</div>
                  <ul class="list">
                    <li><router-link :to="{ name: 'RunningEvents' }">Run With Us</router-link></li>
                    <li><router-link :to="{ name: 'Blog' }">Blog</router-link></li>
                    <li><router-link :to="{ name: 'RunnerSays' }">Runner Says</router-link></li>
                    <li><router-link :to="{ name: 'About' }">Learn About Us</router-link></li>
                    <li><router-link :to="{ name: 'HowWeWork' }">How We Work</router-link></li>
                    <li><router-link :to="{ name: 'Destination' }">Where We Go</router-link></li>
                    <li><router-link :to="{ name: 'Gallery' }">Browse Gallery</router-link></li>
                    <li><router-link :to="{ name: 'BookingAndPrices' }">See Booking & Prices</router-link></li>
                    <li><router-link :to="{ name: 'FAQ' }">Browse Our FAQ</router-link></li>
                    <li><router-link :to="{ name: 'ContactUs' }">Contact Us</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6">
                <div class="widget">
                  <div class="widget-title bold">Work With Us</div>
                  <ul class="list">
                    <li><router-link :to="{ name: 'WWULPTourGuide' }">Apply As A Tour Guide</router-link></li>
                    <li><router-link :to="{ name: 'WWULPHotels' }">Become Hotel Partner</router-link></li>
                    <li><router-link :to="{ name: 'WWULPJoinTheTeam' }">Join The Team</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="widget">
                  <div class="widget-title bold">Newsletter</div>
                  <ValidationObserver v-slot="{ handleSubmit }" ref="NewsletterForm">
                    <b-form ref="NewsletterFormElement" @submit.prevent="handleSubmit(submitNewsletter)">
                      <div>
                        <div class="form-group w-100">
                          <div class="input-group">
                            <input
                              type="email"
                              v-model="email_subscribe"
                              class="form-control bdrd-12px border-0 mr-2"
                              placeholder="Enter your email"
                            />
                            <div class="input-group-append">
                              <button class="bdrd-r-12 btn btn-beta text-dark extrabold" type="submit">
                                Subscribe
                              </button>
                            </div>
                          </div>
                          <div class="d-flex flex-column">
                            <VValidate name="Email Address" v-model="email_subscribe" rules="required|email" />
                            <span>Get all the latest deals and special offers, first.</span>
                          </div>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                  <div class="d-flex align-items-start">
                    <div class="widget-title mt-1">Follow Us:</div>
                    <div class="d-flex ml-4">
                      <a
                        :href="((((footerData || {}).socialmedia || {}).data || {}).facebook || {}).url"
                        target="_blank"
                        v-if="[null, '', undefined].indexOf(((((footerData || {}).socialmedia || {}).data || {}).facebook || {}).url) === -1"
                      >
                        <div class="comp-social bdrd-full d-flex justify-content-center align-items-center bg-facebook">
                          <i class="icon-facebook"></i>
                        </div>
                      </a>
                      <a
                        :href="((((footerData || {}).socialmedia || {}).data || {}).twitter || {}).url"
                        target="_blank"
                        v-if="[null, '', undefined].indexOf(((((footerData || {}).socialmedia || {}).data || {}).twitter || {}).url) === -1"
                      >
                        <div
                          class="comp-social bdrd-full d-flex justify-content-center align-items-center mx-2 bg-twitter"
                        >
                          <i class="icon-twitter"></i>
                        </div>
                      </a>
                      <a
                        :href="((((footerData || {}).socialmedia || {}).data || {}).instagram || {}).url"
                        target="_blank"
                        v-if="[null, '', undefined].indexOf(((((footerData || {}).socialmedia || {}).data || {}).instagram || {}).url) === -1"
                      >
                        <div
                          class="comp-social bdrd-full d-flex justify-content-center align-items-center bg-instagram"
                        >
                          <i class="icon-instagram"></i>
                        </div>
                      </a>
                      <a
                        :href="((((footerData || {}).socialmedia || {}).data || {}).tumblr || {}).url"
                        target="_blank"
                        v-if="[null, '', undefined].indexOf(((((footerData || {}).socialmedia || {}).data || {}).tumblr || {}).url) === -1"
                      >
                        <div
                          class="comp-social bdrd-full d-flex justify-content-center align-items-center mx-2 bg-tumblr"
                        >
                          <i class="fab fa-tumblr"></i>
                        </div>
                      </a>
                      <a
                        :href="((((footerData || {}).socialmedia || {}).data || {}).pinterest || {}).url"
                        target="_blank"
                        v-if="[null, '', undefined].indexOf(((((footerData || {}).socialmedia || {}).data || {}).pinterest || {}).url) === -1"
                      >
                        <div
                          class="comp-social bdrd-full d-flex justify-content-center align-items-center bg-pinterest"
                        >
                          <i class="fab fa-pinterest"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-content text-white bt-1px-white-03">
      <div class="container">
        <div class="d-flex justify-content-between" :class="isOnlyMobile() ? 'flex-column text-center mb-4' : ''">
          <div class="copyright-text">
            Copyright &copy; {{ new Date().getFullYear() }} RunDeGlobe. All Rights Reserved
          </div>
          <div :class="isOnlyMobile() ? 'd-flex justify-content-center' : ''">
            <ul class="list d-flex">
              <li :class="!isOnlyMobile() ? 'mx-4' : 'mr-4'">
                <router-link :to="{ name: 'TermsAndConditions' }">Terms and Conditions</router-link>
              </li>
              <li><router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <AppModal :withPageTitle="false" ref="modal-subscribe" :type="'sm'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/newsletter-vect.svg`" alt="Newsletter" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Thank you for subscribing!</p>
              <span class="regular fs-1em lh-125"
                >You will now receive regular updates and news from us. We're glad to have you as part of our
                community.</span
              >
              <button
                @click="$refs['modal-subscribe'].hide()"
                class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </footer>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import AppModal from '@/components/AppModal'
import Global from '../libs/Global.vue'

export default {
  extends: Global,
  name: 'Footer',
  components: {
    AppModal,
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapFields('useFooterStore', ['footerData']),
    ...mapFields('useNewsletterStore', ['email_subscribe']),
  },
  methods: {
    ...mapActions('useFooterStore', ['getData']),
    ...mapActions('useNewsletterStore', ['addSubscriber']),
    async submitNewsletter() {
      await this.addSubscriber()
      this.$refs['modal-subscribe'].show()
      this.email_subscribe = null
    },
  },
}
</script>

import { getField, updateField } from "vuex-map-fields"
import { http } from "../../config/http"
import Gen from "../../libs/Gen"

export default {
  namespaced: true,
  state: {
    inputNetwork: {
      input_first_name: "",
      input_is_last_name: "Y",
      input_last_name: "",
      input_dial_code: "",
      input_no_telfon: "",
      input_email: "",
      input_gender: "",
      input_age: ""
    },
    inputNetworkSearch: "",
    networkId: null,
    networkData: [],
    messageResponse: ''
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_NETWORK_DATA(state, data) {
      state.networkData = data
    },

    SET_NETWORK_ID(state, data) {
      state.networkId = data
    },

    CLEAR_NETWORK_ID(state) {
      state.networkId = null
    },

    CLEAR_INPUT_FORM_NETWORK(state) {
      state.inputNetwork.input_first_name = ""
      state.inputNetwork.input_is_last_name = "Y"
      state.inputNetwork.input_last_name = ""
      state.inputNetwork.input_email = ""
      state.inputNetwork.input_dial_code = ""
      state.inputNetwork.input_no_telfon = ""
      state.inputNetwork.input_gender = ""
      state.inputNetwork.input_age = ""
    },
    
    SET_INPUT_FORM_NETWORK(state, data) {
      state.inputNetwork.input_first_name = data.first_name
      state.inputNetwork.input_is_last_name = data.is_last_name
      state.inputNetwork.input_last_name = data.last_name
      state.inputNetwork.input_email = data.email
      state.inputNetwork.input_dial_code = data.dial_code
      state.inputNetwork.input_no_telfon = data.no_telfon
      state.inputNetwork.input_gender = data.gender
      state.inputNetwork.input_age = data.age
    },

    SET_MESSAGE_RESPONSE(state, data) {
      state.messageResponse = data
    }
  },
  actions: {

    async getAllNetwork({ commit, rootState }, data) {
      const { page, search, callback = false } = data

      try {
        rootState.loading.data = true
        const token = Gen.getCookie("userAuthRdg")
        const res = await http.get("/auth/user-network", {
          headers: Gen.apiHeaderAuth(token),
          params: {
            input_search: search,
            page,
          }
        })

        commit("SET_NETWORK_DATA", res.data.data.data)

        if (callback) {
          callback()
        }
      } catch (err) {
        if (callback) {
          callback()
        }
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async getNetworkById({ commit, rootState }, data) {
      const { id, callback = false } = data

      try {
        rootState.loading.data = true
        const token = Gen.getCookie("userAuthRdg")
        const res = await http.get(`/auth/user-network/${id}`, {
          headers: Gen.apiHeaderAuth(token)
        })

        commit("SET_INPUT_FORM_NETWORK", res.data.data.data)

        if (callback) {
          callback()
        }
      } catch (err) {
        if (callback) {
          callback()
        }
        return err
      } finally {
        rootState.loading.data = false
      }
    },

    async deleteNetwork({ state, rootState, commit }) {
      try {
        rootState.loading.screen = true
        
        const token = Gen.getCookie('userAuthRdg')
        
        const res = await Gen.apiRest(
          "/auth/user-network/delete",
          { 
            data: Object.assign({}, { 
              network_id: state.networkId,
            }),
            headers: Gen.apiHeaderAuth(token)
          },
          "POST"
        )

        commit("SET_MESSAGE_RESPONSE", res.data.message)

        return res
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async addNewNetwork({ state, rootState, commit }, callback = false) {
      const form = state.inputNetwork
      try {
        rootState.loading.screen = true
        const token = Gen.getCookie('userAuthRdg')
        const res = await Gen.apiRest(
          '/auth/user-network/add',
          {
            data: Object.assign({}, form),
            headers: Gen.apiHeaderAuth(token)
          },
          'POST'
        )
        
        commit("SET_MESSAGE_RESPONSE", res.data.message)

        if (callback) {
          callback(true, res)
        }

        return res
      } catch (err) {
        if (callback) {
          callback(false, err)
        }
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
    async updateNetwork({ state, rootState, commit }, callback = false) {
      const form = state.inputNetwork
      try {
        rootState.loading.screen = true
        const token = Gen.getCookie('userAuthRdg')
        const res = await Gen.apiRest(
          `/auth/user-network/${state.networkId}/update`,
          {
            data: Object.assign({}, form),
            headers: Gen.apiHeaderAuth(token)
          },
          'POST'
        )

        commit("SET_MESSAGE_RESPONSE", res.data.message)

        if (callback) {
          callback(true, res)
        }

        return res
      } catch (err) {
        if (callback) {
          callback(false, err)
        }
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <div class="body-inner" v-if="verifyingEmail">
    <div
      v-if="emailAlertSuccess"
      class="wrapper_alert bg-success text-white p-3 px-4"
      ref="send-new-code-success-alert"
    >
      <p class="mb-0">We have sent an email to {{ outFormData.email }} containing a code to verify your email.</p>
    </div>
    <div
      v-if="emailAlertDanger"
      class="wrapper_alert bg-danger text-white p-3 px-4"
      ref="send-new-code-danger-alert"
    >
      <p class="mb-0">{{ errMessageText }}</p>
    </div>
    <div v-if="!isShowAlertEmail" class="p-3 px-4">
      <p class="mb-0">&nbsp;</p>
    </div>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="wrapper_illusatration">
                <img :src="`${baseUrl}/assets/images/vector/otp-code-vect.svg`" alt="Verification" class="w-35-cust" />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Email verification</h2>
              <span class="regular grey-color fs-1em"
                >An email with a verification code has been sent to
                <b class="text-dark semibold">{{ outFormData.email }}</b
                >,</span
              >
              <span class="d-block regular grey-color fs-1em"
                >please enter the code to proceed. The code will valid for {{ validity }}</span
              >
              <div class="my-4 d-flex flex-column align-items-center">
                <otp-input
                  :isValid="isCodeValidEmail"
                  @on-complete="handleVerifyEmail"
                  @on-changed="onChangedHandlerEmail"
                  @on-paste="onPasteHandlerEmail"
                  placeholder=""
                  @value="(v) => (verifEmailInputCode = v)"
                >
                  <template #errorMessage>
                    <div class="mt-2">Verification code incorrect, please try again.</div>
                  </template>
                </otp-input>
              </div>
              <p v-if="disabledKirimUlang" class="regular">Resend code in {{ countdownDetik }}</p>
              <span>Didn't get an email?</span>
              <template v-if="!isLoading && !disabledKirimUlang">
                <a @click="handleSendNewCodeEmail" href="javascript:;" class="text-link text-decoration-none bold mt-2"
                  >Send a new code</a
                >
              </template>
              <template v-else-if="!isLoading && disabledKirimUlang">
                <a href="javascript:;" class="text-link text-decoration-none bold mt-2"
                  >Send a new code</a
                >
              </template>
              <template v-else>
                <PulseLoader :loading="true" color="#333" size="12px" />
              </template>
              <div class="divider">or</div>
              <a href="javascript:;" @click="handleVerifyChangeEmail" class="text-link text-decoration-none mb-4 bold">
                Change email address
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="changingEmail">
    <section>
      <div class="container my-5">
        <div class="row my-5 justify-content-center">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center">
              <div class="wrapper_illustration d-flex justify-content-center">
                <img
                  :src="`${baseUrl}/assets/images/vector/otp-code-vect.svg`"
                  alt="Confirmation"
                  :class="isOnlyMobile() ? 'w-25' : 'w-50'"
                />
              </div>
              <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Enter your email address</h2>
              <span class="regular grey-color fs-1em d-block text-center"
                >You will receive an email with a verification code
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <ValidationObserver ref="RegisterForm" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onContinueVerifyChangeEmail)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        id="verifyChangeEmail"
                        type="email"
                        class="form-control main-control"
                        placeholder="Email Address"
                        v-model="formData.changeEmail"
                      />
                      <VValidate name="Email Address" v-model="formData.changeEmail" rules="required|email" />
                    </div>
                    <div class="d-flex justify-content-center">
                      <button
                        type="button"
                        class="btn btn-outline-main ml-0 bold"
                        :class="isOnlyMobile() ? 'w-100' : ''"
                        @click="handleCancelVerifyChangeEmail"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary bold" :class="isOnlyMobile() ? 'w-100' : ''">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>

    <AppModal :withPageTitle="false" ref="confirm-data-modal-x" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/otp-code-vect.svg`" alt="Data Confirmation" class="w-25" />
              <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
              <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
              <ul>
                <li>Email: {{ formData.changeEmail }}</li>
              </ul>
              <p>
                A verification code will be sent to your email, make sure the inbox is not full and you can receive the
                code
              </p>
              <div class="d-flex">
                <button type="button" class="btn btn-outline-main ml-0" @click="onConfirmCancelVerifyChangeEmail">
                  Change email
                </button>
                <button type="button" class="btn btn-primary bold" @click.prevent="handleRegister">
                  Confirm data
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </div>

  <div class="body-inner" v-else-if="verifyingEmailSuccess">
    <section>
      <div class="container my-5">
        <div class="row my-5">
          <div class="col-md-12" :class="isOnlyMobile() ? 'my-5' : ''">
            <div class="d-flex flex-column align-items-center text-center" :class="isOnlyMobile() ? 'my-5' : ''">
              <div class="wrapper_illustration">
                <img :src="`${baseUrl}/assets/images/vector/main-sent.svg`" alt="Verified" class="w-35-cust" />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Email verified!</h2>
              <span class="regular grey-color fs-1em d-block mb-5">Great! next, we'll verify your phone number</span>
              <button
                type="button"
                @click.prevent="handleEmailVerified"
                class="btn btn-primary bold"
                :class="isOnlyMobile() ? 'w-100' : ''"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="verifyingPhoneInput">
    <section>
      <div class="container my-5">
        <div class="row my-5">
          <div class="col-12">
            <ValidationObserver v-slot="{ handleSubmit }" ref="VerifyPhoneForm">
              <form @submit.prevent="handleSubmit(onVerifyPhoneNumberInput)">
                <div class="col-md-12">
                  <div class="d-flex flex-column align-items-center">
                    <div class="wrapper_illustration d-flex justify-content-center">
                      <img
                        :src="`${baseUrl}/assets/images/vector/mobile-call-vect.svg`"
                        alt="Verify Phone Number"
                        class="w-35-cust"
                      />
                    </div>
                    <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Verify your phone number</h2>
                    <span class="regular grey-color fs-1em d-block text-center">Enter an active phone number </span>
                    <div class="my-4">
                      <div class="form-group">
                        <div class="input-group d-flex">
                          <v-select
                            v-model="formData.country_code"
                            placeholder="Code"
                            :options="countryCodeList"
                            :reduce="(v) => v.countrycode_id"
                            id="countrycode"
                            class="b-rd-r-0"
                            :class="isOnlyMobile() ? 'no-dropdown' : ''"
                            @click.native="isOnlyMobile() ? showSearchModal('modal-country-code', 'countrycode') : ''"
                            :getOptionLabel="(v) => v.countrycode_name + ' ' + v.countrycode_dial_code"
                          >
                            <template #option="{ countrycode_dial_code, countrycode_name }">
                              <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                            </template>
                            <template #selected-option="{ countrycode_dial_code }">
                              <span>{{ countrycode_dial_code }}</span>
                            </template>
                          </v-select>
                          <b-form-input
                            class="form-control main-control mt-0 group-item"
                            placeholder="Phone number"
                            v-model="formData.phone"
                            :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                          ></b-form-input>
                        </div>
                        <VValidate
                          vclass="d-block"
                          name="Country Code"
                          v-model="formData.country_code"
                          :rules="{ required: true }"
                        />
                        <VValidate
                          vclass="d-block"
                          name="Phone Number"
                          v-model="formData.phone"
                          :rules="{ required: true, min: 5, max: 16 }"
                        />
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary bold" :class="isOnlyMobile() ? 'w-100' : ''">
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>

    <AppModal :withPageTitle="false" ref="confirm-phone-modal" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/confirm-vect.svg`" alt="Confirmation" class="w-25" />
              <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
              <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
              <ul>
                <li>Phone: {{ ccode }}{{ formData.phone }}</li>
              </ul>
              <p class="regular">
                A verification code will be sent to your phone number, make sure the number is active
              </p>
              <div class="d-flex">
                <button type="button" class="btn btn-outline-main ml-0 bold" @click="onChangeNumber">
                  Change number
                </button>
                <button type="button" class="btn btn-primary bold" @click="handleConfirmInputNumber">Confirm data</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-country-code" :type="'lg'">
      <template #title-modal>
        <h4>Country Code</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <input
              type="text"
              class="form-control outline-none main-control"
              placeholder="-- Search Country --"
              v-model="filter.search"
              @input="doSearch"
            />
            <div class="my-4 h-100vh fade-in">
              <template>
                <div
                  v-for="(v, k) in countryCodeList"
                  :key="k"
                  class="d-flex justify-content-between bb-1px-grey py-4"
                  @click.prevent="onSelectedCountryCode(v)"
                >
                  <p class="light mb-0">{{ v.countrycode_name }}</p>
                  <p class="mb-0">{{ v.countrycode_dial_code }}</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </div>

  <div class="body-inner" v-else-if="verifyingPhoneMethods">
    <section>
      <div class="container my-5">
        <div class="row my-5">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="wrapper_illustration">
                <img :src="`${baseUrl}/assets/images/vector/choose-vect.svg`" alt="Verify Phone" class="w-35-cust" />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Verify your phone number</h2>
              <span class="regular grey-color fs-1em">Choose how to get the OTP code </span>
            </div>
          </div>
          <div class="col-md-12 cursor-pointer" @click.prevent="(v) => (formData.method = 'wa')">
            <div class="my-2">
              <div class="row justify-content-center">
                <div class="col-md-6 border-bottom-1px">
                  <div class="wrap_methods d-flex justify-content-between align-items-center">
                    <div class="wrap_method left d-flex align-items-center">
                      <div class="wrap_method_icon p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                          />
                        </svg>
                      </div>
                      <div class="wrap_method_name">
                        <span class="regular">Whatsapp</span>
                      </div>
                    </div>
                    <div class="wrap_check" v-if="formData.method == 'wa'">
                      <b-icon icon="check" scale="2" variant="success"></b-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 cursor-pointer" @click.prevent="(v) => (formData.method = 'tele')">
            <div class="my-2">
              <div class="row justify-content-center">
                <div class="col-md-6 border-bottom-1px">
                  <div class="wrap_methods d-flex justify-content-between align-items-center">
                    <div class="wrap_method left d-flex align-items-center">
                      <div class="wrap_method_icon p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.11-8.83l.013-.007.87 2.87c.112.311.266.367.453.341.188-.025.287-.126.41-.244l1.188-1.148 2.55 1.888c.466.257.801.124.917-.432l1.657-7.822c.183-.728-.137-1.02-.702-.788l-9.733 3.76c-.664.266-.66.638-.12.803l2.497.78z"
                          />
                        </svg>
                      </div>
                      <div class="wrap_method_name">
                        <span class="regular">Telegram</span>
                      </div>
                    </div>
                    <div class="wrap_check" v-if="formData.method == 'tele'">
                      <b-icon icon="check" scale="2" variant="success"></b-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <button
                  type="submit"
                  class="btn btn-primary bold"
                  :class="isOnlyMobile() ? 'w-100' : ''"
                  @click.prevent="handleSendPhoneVerificationCode"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="verifyingPhoneCode">
    <div
      v-if="phoneAlertSuccess"
      class="wrapper_alert bg-success text-white p-3 px-4"
      ref="send-new-code-success-alert"
    >
      <p class="mb-0">
        We have sent a Whatsapp message to {{ ccode }}{{ outFormData.phone }} containing a code to verify your number.
      </p>
    </div>
    <div
      v-if="phoneAlertDanger"
      class="wrapper_alert bg-danger text-white p-3 px-4"
      ref="send-new-code-danger-alert"
    >
      <p class="mb-0">{{ errMessage }}</p>
    </div>
    <div v-if="!isShowAlertPhone" class="p-3 px-4">
      <p class="mb-0">&nbsp;</p>
    </div>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="wrapper_illustration">
                <img
                  :src="`${baseUrl}/assets/images/vector/otp-code-phone.svg`"
                  alt="Phone Verification"
                  class="w-25"
                />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Phone number verification</h2>
              <span class="regular grey-color fs-1em"
                >Enter the 5-digits code we sent by {{ selectedMethod }} to
                <b class="text-dark semibold">{{ ccode }}{{ outFormData.phone }}</b
                >,</span
              >
              <span class="d-block regular grey-color fs-1em"
                >please enter the code to proceed. The code will valid for {{ validity }}</span
              >
              <div class="my-4 d-flex flex-column align-items-center">
                <otp-input
                  :isValid="isCodeValidPhone"
                  @on-complete="handleVerifyPhone"
                  @on-changed="onChangedHandlerPhone"
                  @on-paste="onPasteHandlerPhone"
                  placeholder=""
                  @value="(v) => (verifPhoneInputCode = v)"
                  type="text"
                >
                  <template #errorMessage>
                    <div class="mt-2">Verification code incorrect, please try again.</div>
                  </template>
                </otp-input>
              </div>
              <p v-if="disabledKirimUlang" class="regular">Resend code in {{ countdownDetik }}</p>
              <span>Didn't get the code?</span>
              <template v-if="!isLoading && !disabledKirimUlang">
                <a @click="handleSendNewCodePhone" href="javascript:;" class="text-link text-decoration-none mt-2 bold"
                  >Send a new code</a
                >
              </template>
              <template v-else-if="!isLoading && disabledKirimUlang">
                <a href="javascript:;" class="text-link text-decoration-none mt-2 bold"
                  >Send a new code</a
                >
              </template>
              <template v-else>
                <PulseLoader :loading="true" color="#333" size="14px" />
              </template>
              <div class="divider d-flex mt-2" :class="isOnlyMobile() ? 'w-100' : 'w-50'">
                <div class="left-line border-bottom-1px w-100 mb-2"></div>
                <span class="mx-2">or</span>
                <div class="right-line border-bottom-1px w-100 mb-2"></div>
              </div>
              <a
                href="javascript:;"
                @click.prevent="handleVerifyChangePhone"
                class="mt-2 text-link text-decoration-none mb-4 bold"
              >
                Change phone number
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="verifyingPhoneSuccess">
    <section>
      <div class="container my-5">
        <div class="row my-5">
          <div class="col-md-12 my-5">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="wrapper_illustration">
                <img
                  :src="`${baseUrl}/assets/images/vector/confirmed-vect.svg`"
                  alt="Verified Phone"
                  class="w-35-cust"
                />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Phone number verified!</h2>
              <span class="regular grey-color fs-1em d-block" :class="isOnlyMobile() ? 'mb-5' : ''"
                >Great! next, we need a few information of your basic profile</span
              >
            </div>
          </div>
          <div class="col-md-12" :class="isOnlyMobile() ? 'text-right' : 'text-center'">
            <button
              type="button"
              @click="handlePhoneVerified"
              class="btn btn-primary bold"
              :class="isOnlyMobile() ? 'w-100' : ''"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="fillingCitizenship">
    <section>
      <div class="container my-5">
        <div class="row my-5 justify-content-center">
          <div class="col-md-12 mb-5">
            <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Your citizenship</h2>
          </div>
          <div
            class="col-lg-4 col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.citizenship_flag === 'Y' ? 'active' : ''"
            @click.prevent="formData.citizenship_flag = 'Y'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_country d-flex flex-column align-items-center">
                  <div class="wrap_country_img">
                    <img
                      :src="`${baseUrl}/assets/images/static-icon/indonesian-flag.png`"
                      alt="Indonesia"
                      class="img-fluid"
                    />
                  </div>
                  <div class="wrap_country_name mt-4">
                    <span class="bold">Indonesia</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.citizenship_flag === 'N' ? 'active' : ''"
            @click.prevent="formData.citizenship_flag = 'N'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_country d-flex flex-column align-items-center">
                  <div class="wrap_country_img">
                    <img
                      :src="`${baseUrl}/assets/images/static-icon/global-flag.png`"
                      alt="Other Countries"
                      class="img-fluid"
                    />
                  </div>
                  <div class="wrap_country_name mt-4">
                    <span class="bold">Others</span>
                  </div>
                  <div class="wrap_select_county" v-if="formData.citizenship_flag === 'N'">
                    <div class="row">
                      <div class="col-12">
                        <v-select
                          v-model="formData.citizenship"
                          placeholder="Country"
                          :options="citizenship_list"
                          :reduce="(v) => v.citizenship_id"
                          id="citizenshipfill"
                          :getOptionLabel="(v) => v.citizenship_name"
                        >
                          <template #option="{ citizenship_name }">
                            <span>{{ citizenship_name }}</span>
                          </template>
                          <template #selected-option="{ citizenship_name }">
                            <span>{{ citizenship_name }}</span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button
              type="button"
              @click="handleCitizenshipFilled"
              class="btn btn-primary bold"
              :class="isOnlyMobile() ? 'w-100' : ''"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="fillingGender">
    <section>
      <div class="container my-5">
        <div class="row my-5 justify-content-center">
          <div class="col-md-12 mb-5">
            <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Your gender</h2>
          </div>
          <div
            class="col-lg-4 col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.gender === 'M' ? 'active' : ''"
            @click.prevent="formData.gender = 'M'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_gender d-flex flex-column align-items-center">
                  <div class="wrap_gender_img">
                    <img :src="`${baseUrl}/assets/images/vector/male-vect.svg`" alt="Male" class="img-fluid w-100" />
                  </div>
                  <div class="wrap_gender_name mt-4">
                    <span class="bold">Male</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.gender === 'F' ? 'active' : ''"
            @click.prevent="formData.gender = 'F'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_gender d-flex flex-column align-items-center">
                  <div class="wrap_gender_img">
                    <img
                      :src="`${baseUrl}/assets/images/vector/female-vect.svg`"
                      alt="Female"
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="wrap_gender_name mt-4">
                    <span class="bold">Female</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button
              type="button"
              @click="handleGenderFilled"
              class="btn btn-primary bold"
              :class="isOnlyMobile() ? 'w-100' : ''"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="fillingBirthday">
    <section style="padding-bottom: 100px">
      <div class="container my-5">
        <div class="row my-5 py-5">
          <div class="col-md-12 mt-5">
            <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Your birthday</h2>
          </div>
          <div class="col-md-12">
            <ValidationObserver ref="BirthdayForm">
              <form>
                <div class="row justify-content-center">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <v-date-picker
                        v-model="formData.date_of_birth"
                        full-width
                        :popover="{ visibility: 'click' }"
                        :masks="{ input: 'MM/DD/YYYY' }"
                        :to-page="defineToPage(formData.date_of_birth, minimumAgePage, 0)"
                        :max-date="maxDateOfBirth"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="d-flex flex-column justify-center items-center">
                            <input
                              v-if="inputEvents"
                              :value="formatDate(inputValue)"
                              placeholder="Date of birth"
                              v-on="inputEvents"
                              :onkeydown="false"
                              class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                            />
                          </div>
                        </template>
                      </v-date-picker>
                      <VValidate
                        vid="dob1"
                        name="Date of Birth"
                        v-model="formData.date_of_birth"
                        :rules="{ required: true }"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <button
                  type="button"
                  @click="handleBirthdayFilled"
                  class="btn btn-primary bold"
                  :class="isOnlyMobile() ? 'w-100' : ''"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="fillingAge">
    <section>
      <div class="container" :class="isOnlyMobile() ? 'my-5' : ''">
        <div class="row">
          <div class="col-md-12 my-5">
            <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Your age</h2>
          </div>
          <div
            class="col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.age_range === '< 16' ? 'active' : ''"
            @click.prevent="formData.age_range = '< 16'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_item d-flex align-items-center">
                  <div class="wrap_item_name">
                    <span class="bold">&lt; 16</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.age_range === '16 - 25' ? 'active' : ''"
            @click.prevent="formData.age_range = '16 - 25'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_item d-flex align-items-center">
                  <div class="wrap_item_name">
                    <span class="bold">16 - 25</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.age_range === '26 - 35' ? 'active' : ''"
            @click.prevent="formData.age_range = '26 - 35'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_item d-flex align-items-center">
                  <div class="wrap_item_name">
                    <span class="bold">26 - 35</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.age_range === '36 - 45' ? 'active' : ''"
            @click.prevent="formData.age_range = '36 - 45'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_item d-flex align-items-center">
                  <div class="wrap_item_name">
                    <span class="bold">36 - 45</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 cursor-pointer wrap_card_selection"
            :class="formData.age_range === '46 - 60+' ? 'active' : ''"
            @click.prevent="formData.age_range = '46 - 60+'"
          >
            <div class="card">
              <div class="card-body">
                <div class="wrap_item d-flex align-items-center">
                  <div class="wrap_item_name">
                    <span class="bold">46 - 60+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button
              type="button"
              @click="handleAgeFilled"
              class="btn btn-primary bold"
              :class="isOnlyMobile() ? 'w-100' : ''"
            >
              Continue
            </button>
            <a href="javascript:;" @click.prevent="handleBackBirthday" class="mt-3 d-block text-link bold">Go back</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="underAge">
    <section>
      <div class="container my-5">
        <div class="row my-5">
          <div class="col-md-12 my-5">
            <div class="d-flex flex-column align-items-center text-center" :class="isOnlyMobile() ? 'my-5' : ''">
              <div class="wrapper_illustration">
                <img :src="`${baseUrl}/assets/images/vector/team-1-vect.svg`" alt="Working" class="w-25" />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Looks like you're underage</h2>
              <span class="regular grey-color fs-1em d-block mb-5">
                <template v-if="openDateValue !== ''">
                  You may go back here again on <span class="font-weight-bold">{{ openDateValue }}</span
                  >.
                </template>
                However, you can still read our interesting articles
              </span>
              <button
                type="button"
                @click="onHandleUnderAgeRedirect"
                class="btn btn-primary bold"
                :class="isOnlyMobile() ? 'w-100' : ''"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="fillingAvatar">
    <section>
      <div class="container" :class="isOnlyMobile() ? 'my-5' : ''">
        <div class="row justify-content-center" :class="isOnlyMobile() ? 'my-5 py-5' : ''">
          <div class="col-md-12 my-5">
            <h2 class="text-dark text-center fs-125em bold mb-1 ls-0 mt-4 lh-125">Your avatar</h2>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <div class="wrap_avatar wrap_img d-flex justify-content-center align-items-center">
                  <label for="upload-avatar" class="cursor-pointer mb-0">
                    <img
                      v-if="!showAvatarPreview"
                      :src="uploader('avatar/avatar-noimage.png')"
                      alt="avatar"
                      class="img-fluid p-10"
                    />
                    <img
                      v-else
                      id="avatarPreviewImage"
                      src=""
                      alt="avatar preview"
                      class="rounded-circle img-fluid"
                      :class="!isOnlyMobile() ? 'p-2' : ''"
                    />
                  </label>
                </div>
                <div class="wrap_upload_btn d-flex justify-content-center my-4">
                  <label class="btn btn-outline" for="upload-avatar">Choose Photo</label>
                </div>
                <ValidationProvider name="Photo" rules="required" ref="fileAvatar" v-slot="{ validate, errors }">
                  <input
                    ref="fileAvatar"
                    @change="pickFile($event, 'fileavatar', 'fileAvatar')"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    class="d-none"
                    id="upload-avatar"
                  />
                  <b-form-text class="px-3 text-center"> Format accepted: .jpg, .jpeg, .png </b-form-text>
                  <b-form-text class="px-3 text-center" text-variant="danger">{{ errors[0] }}</b-form-text>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <div class="wrap_requirement_file d-flex flex-column align-items-center bg-grey py-4 mb-4">
                  <p class="regular">Format: JPEG, JPG, PNG</p>
                  <p class="regular mb-0">Max File Size: 2MB</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <button
                  type="button"
                  @click="handleAvatarFilled"
                  class="btn btn-primary bold"
                  :class="isOnlyMobile() ? 'w-100' : ''"
                >
                  Continue
                </button>
                <a href="javascript:;" @click.prevent="handleAvatarSkipped" class="mt-3 d-block text-link bold"
                  >Skip this</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-inner" v-else-if="fillingBasicSuccess">
    <section>
      <div class="container my-5">
        <div class="row my-5">
          <div class="col-md-12 my-5">
            <div class="d-flex my-5 flex-column align-items-center text-center">
              <div class="wrapper_illustration">
                <img
                  :src="`${baseUrl}/assets/images/vector/joined-vect.svg`"
                  alt="Full Member Joined"
                  class="w-35-cust"
                />
              </div>
              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">You are now a full member!</h2>
              <span class="regular grey-color fs-1em d-block mb-5">Now you can enjoy our interesting features</span>
              <button
                type="button"
                @click="handleSurveyCompleted"
                class="btn btn-primary bold"
                :class="isOnlyMobile() ? 'w-100' : ''"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Gen from '@/libs/Gen'
import AuthSharedVariables from '@/libs/AuthSharedVariables.vue'
import OtpInput from '@/libs/otp-input.vue'
import dayjs from 'dayjs'
import axios from 'axios'

export default {
  extends: AuthSharedVariables,
  components: {
    OtpInput,
  },
  props: {
    emailAlertDanger: {
      type: Boolean,
    },
    emailAlertSuccess: {
      type: Boolean,
    },
    isShowAlertEmail: {
      type: Boolean,
    },
    phoneAlertDanger: {
      type: Boolean,
    },
    phoneAlertSuccess: {
      type: Boolean,
    },
    isShowAlertPhone: {
      type: Boolean,
    },
    errMessageText: {
      type: String,
    },
    verifyingEmail: {
      type: Boolean,
    },
    changingEmail: {
      type: Boolean,
    },
    verifyingEmailSuccess: {
      type: Boolean,
    },
    verifyingPhoneInput: {
      type: Boolean,
    },
    verifyingPhoneMethods: {
      type: Boolean,
    },
    verifyingPhoneCode: {
      type: Boolean,
    },
    verifyingPhoneSuccess: {
      type: Boolean,
    },
    fillingCitizenship: {
      type: Boolean,
    },
    fillingGender: {
      type: Boolean,
    },
    fillingBirthday: {
      type: Boolean,
    },
    fillingAge: {
      type: Boolean,
    },
    underAge: {
      type: Boolean,
    },
    fillingAvatar: {
      type: Boolean,
    },
    fillingBasicSuccess: {
      type: Boolean,
    },
    outFormData: {
      type: Object,
    }
  },
  data() {
    return {
      minimumAge: 16,
      isLoading: false,
      disabledKirimUlang: false,
      countdownDetik: 0,
      intervalCountdown: null,
    }
  },
  computed: {
    minimumAgePage() {
      const minimumdate = dayjs().subtract(this.minimumAge, 'year')

      return {
        month: minimumdate.format('MM'),
        year: minimumdate.format('YYYY'),
      }
    },
    maxDateOfBirth() {
      let dateNow = new Date()

      const year = dateNow.getFullYear()
      const month = dateNow.getMonth()
      const day = dateNow.getDate()

      let conf = new Date(year - 11, month - 1, day)
      return conf
    },
  },
  methods: {
    handleVerifyChangePhone() {
      this.$emit('verify-change-phone')
    },
    handleRegister() {
      this.$emit('register', this.formData.changeEmail)
    },
    handleCancelVerifyChangeEmail() {
      this.$emit('cancel-verify-change-email')
      this.formData.changeEmail = ''
    },
    formatDayJs(date, format, index) {
      return dayjs(date).format(format)
    },
    defineToPage(value, min, index) {
      if (['', null, undefined].indexOf(value) == -1) {
        return {
          month: this.formatDayJs(value, 'M'),
          year: this.formatDayJs(value, 'YYYY'),
        }
      } else if (['', null, undefined].indexOf(min) == -1) {
        return min
      }

      return undefined
    },
    onChangeEmail() {
      this.$refs['confirm-email-modal'].hide()
    },
    onVerifyPhoneNumberInput() {
      this.$refs['confirm-phone-modal'].show()
    },
    onChangeNumber() {
      this.$refs['confirm-phone-modal'].hide()
    },
    onContinueVerifyChangeEmail() {
      this.$refs['confirm-data-modal-x'].show()
    },
    handleVerifyEmail() {
      console.log('verifyemailll')
      this.$emit('verify-email', this.verifEmailInputCode)
    },
    handleVerifyChangeEmail() {
      this.$emit('verify-change-email')
    },
    onConfirmCancelVerifyChangeEmail() {
      this.$refs['confirm-data-modal-x'].hide()
    },
    handleEmailVerified() {
      this.$emit('email-verified')
    },
    handleVerifyPhone() {
      this.$emit('verify-phone', this.verifPhoneInputCode)
    },
    handlePhoneVerified() {
      this.$emit('phone-verified')
    },
    handleVerifyPhoneNumberInput() {
      this.$emit('verify-phone-input')
    },
    async handleSendNewCodeEmail() {
      this.$emit('send-new-code-email')
      // set waktu jeda selama 30 detik sebelum dapat mengirim ulang kode verifikasi lagi
      let waktuJeda = 30
      this.countdownDetik = waktuJeda
      this.disabledKirimUlang = true
      this.intervalCountdown = setInterval(() => {
        if (this.countdownDetik > 0) {
          this.countdownDetik--
        } else {
          clearInterval(this.intervalCountdown)
          this.disabledKirimUlang = false
        }
      }, 1000)
      await new Promise((resolve) => setTimeout(resolve, waktuJeda * 1000))
      clearInterval(this.intervalCountdown)
      this.disabledKirimUlang = false
    },
    handleCitizenshipFilled() {
      this.$emit('citizenship-filled', this.formData.citizenship_flag)
    },
    async handleSendNewCodePhone() {
      this.$emit('send-new-code-phone')
      // set waktu jeda selama 30 detik sebelum dapat mengirim ulang kode verifikasi lagi
      let waktuJeda = 30
      this.countdownDetik = waktuJeda
      this.disabledKirimUlang = true
      this.intervalCountdown = setInterval(() => {
        if (this.countdownDetik > 0) {
          this.countdownDetik--
        } else {
          clearInterval(this.intervalCountdown)
          this.disabledKirimUlang = false
        }
      }, 1000)
      await new Promise((resolve) => setTimeout(resolve, waktuJeda * 1000))
      clearInterval(this.intervalCountdown)
      this.disabledKirimUlang = false
    },
    handleChangeNumber() {
      this.$emit('change-number')
    },
    handleGenderFilled() {
      this.$emit('gender-filled', this.formData.gender)
    },
    handleBirthdayFilled() {
      this.$emit('birthday-filled', this.formData.date_of_birth)
    },
    handleAgeFilled() {
      this.$emit('age-filled')
    },
    handleBackBirthday() {
      this.$emit('back-birthday')
    },
    onHandleUnderAgeRedirect() {
      this.$emit('under-age-redirect')
    },
    async handleAvatarFilled() {
      this.loading.screen = true
      const avatar = await this.uploadFormFile('fileavatar')
      this.$emit('avatar-filled', avatar)
    },
    uploadFormFile(dataToBeBound) {
      // Validation
      this.error = false

      if (this.fileData[dataToBeBound] === null) {
        this.messageErrTitle = `File is required.`
        this.$refs['modal-error'].show()
        return null
      }

      if (this.conf.rule_type.indexOf(this.fileType) == -1) {
        this.messageErrTitle = `File type must be ${this.conf.rule_type} type.`
        this.$refs['modal-error'].show()
        return null
      }

      if (this.fileData[dataToBeBound] !== null) {
        if (this.fileData[dataToBeBound].size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          this.messageErrTitle = 'File is too big (Max file size is 2MB)'
          this.$refs['modal-error'].show()
          return null
        }
        // if (this.error) {
        //   // return alert(this.error)
        // }

        const path = 'avatar'

        const formData = new FormData()

        formData.set('file', this.fileData[dataToBeBound])

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },
    handleAvatarSkipped() {
      this.$emit('avatar-skipped')
    },
    handleSurveyCompleted() {
      this.$emit('survey-completed')
    },
    handleConfirmInputNumber() {
      this.$emit('confirm-input-number', this.formData.phone)
    },
  }
}
</script>

<style>
</style>
import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {},

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },

  actions: {
    async doCallback({ rootState, commit }, data) {
      const { form, callback = false } = data
      try {
        rootState.loading.screen = true

        const res = await Gen.apiRest(
          '/oauth/cb',
          {
            data: { ...form },
          },
          'POST',
        )

        if (callback) {
          callback(true, res.data)
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    }
  },
}

<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="overview" v-if="!$route.params.pageSlug">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 class="bold ls-0">Network List</h5>
                    <span class="normal ">You can add up to 15 network</span>
                  </div>
                  <router-link
                    v-if="((networkData || {}).data || {}).length > 0"
                    :to="{
                      name: 'MyNetwork',
                      params: {
                        pageSlug: 'add'
                      },
                      query: {...$route.query}
                    }"
                    class="text-link"
                    ><i class="fa fa-plus mr-1"></i> Add a New Network</router-link
                  >
                </div>
                <div class="mt-4">
                  <template v-if="((networkData || {}).data || {}).length > 0">
                    <div class="b-grey-black mt-3 px-3 py-3 bdrd-12px d-flex justify-content-between" v-for="(v,k) in (networkData || {}).data" :key="k">
                      <div>
                        <p class="text-dark normal fs-115em mb-0">{{(networkData.per_page*(networkData.current_page-1))+k+1}}. {{ v.first_name }} {{ v.last_name }}</p>
                      </div>
                      <div>
                        <router-link
                          :to="{
                            name: 'MyNetwork',
                            params: {
                              pageSlug: v.id
                            }
                          }"
                          class="text-link mx-3"
                          >Edit</router-link
                        >
                        <a
                          @click="openModalConfirmationDelete(v.id)"
                          href="javascript:;"
                          class="text-link"
                          >Remove</a
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="b-grey-black mt-3 px-3 py-3 bdrd-12px d-flex justify-content-between">
                      <div class="relative card-private-page p-4 bdrd-12px mt-3">
                        <div class="initial-content d-flex flex-column align-items-center">
                          <div class="wrapper-illustration w-50 d-flex justify-content-center">
                            <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="document" class="w-50" />
                          </div>
                          <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No network found</h2>
                          <span>You don't have a network yet, let's create a new one now</span>
                          <router-link
                            :to="{
                              name: 'MyNetwork',
                              params: {
                                pageSlug: 'add'
                              },
                              query: {...$route.query}
                            }"
                            class="btn btn-primary semibold d-flex mt-3 align-items-center d-block"
                            ><i class="fa fa-plus mr-1"></i> Add a New Network</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="wrap-pagination mt-1 d-flex justify-content-end">
                <b-pagination
                  v-model="networkData.current_page"
                  v-if="!isLoading && networkData.data && networkData.last_page > 1" 
                  :total-rows="networkData.total" 
                  :per-page="networkData.per_page" 
                  prev-text="Prev" 
                  next-text="Next"
                  @change="doPaginate"
                  :hide-goto-end-buttons="true"
                  pills
                ></b-pagination>
              </div>
          </div>
          <div class="overview" v-else>
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 class="bold ls-0">{{ $route.params.pageSlug != 'add' ? 'Edit' : 'Add a New'}} Network</h5>
                  </div>
                </div>
                <div class="b-grey-black mt-4 px-3 py-3 bdrd-12px d-flex justify-content-between">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="MyNetworkForm">
                    <b-form
                      ref="MyNetworkFormElement"
                      @submit.prevent="handleSubmit(submitFormMyNetwork)"
                    >
                      <div class="row bb-1px-grey pb-3">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">First &amp; Middle Name</label>
                            <input
                              tabindex="1"
                              type="text"
                              v-model="inputNetwork.input_first_name"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate
                              name="First & Middle Name"
                              v-model="inputNetwork.input_first_name"
                              :rules="{
                                required: true,
                                min: 3,
                                max: 128,
                                regex: /^([A-Za-z' ]+)$/,
                              }"
                            />
                          </div>
                          <b-form-checkbox
                            tabindex="3"
                            id="is_lastname"
                            v-model="inputNetwork.input_is_last_name"
                            name="is_lastname"
                            class="mb-3"
                            value="N"
                            unchecked-value="Y"
                            @click.native="checkIsLastName(inputNetwork.input_is_last_name)"
                          >
                            <span class="normal">This person doesn't have family name / last name</span>
                          </b-form-checkbox>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Last Name</label>
                            <template v-if="inputNetwork.input_is_last_name == 'Y'">
                              <input
                                tabindex="2"
                                type="text"
                                v-model="inputNetwork.input_last_name"
                                class="form-control main-control"
                                placeholder="e.g. Grahita"
                              />
                              <VValidate
                                name="Last Name"
                                v-model="inputNetwork.input_last_name"
                                :rules="{
                                  required: inputNetwork.input_is_last_name == 'N' ? false : true,
                                  min: 3,
                                  max: 96,
                                  regex: /^([A-Za-z' ]+)$/,
                                }"
                              />
                            </template>
                            <template v-else>
                              <input
                                type="text"
                                class="form-control main-control"
                                placeholder="e.g. Grahita"
                                :value="inputNetwork.input_first_name"
                                disabled
                              />
                            </template>
                          </div>
                        </div>
                        <div class="col-md-3 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Gender</label>
                            <div class="d-flex mt-3">
                              <b-form-radio v-model="inputNetwork.input_gender" name="gender" value="M"
                                >Male</b-form-radio
                              >
                              <b-form-radio
                                v-model="inputNetwork.input_gender"
                                class="ml-3"
                                name="gender"
                                value="F"
                                >Women</b-form-radio
                              >
                            </div>
                            <VValidate
                              name="Gender"
                              v-model="inputNetwork.input_gender"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                        <div class="col-md-9 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Phone Number</label>
                            <div class="input-group d-flex mt-3">
                              <v-select
                                v-if="inputNetwork.input_dial_code"
                                v-model="inputNetwork.input_dial_code"
                                placeholder="Code"
                                :options="countryCodeData"
                                :reduce="(v) => v.countrycode_id"
                                id="countrycode"
                                class="pointer w-25"
                                :get-option-label="(o) => o.countrycode_name + ' ' + o.countrycode_dial_code"
                              >
                                <template #option="{ countrycode_dial_code, countrycode_name }">
                                  <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                                </template>
                                <template #selected-option="{ countrycode_dial_code }">
                                  <span>{{ countrycode_dial_code }}</span>
                                </template>
                              </v-select>
                              <b-form-input
                                tabindex="4"
                                v-model="inputNetwork.input_no_telfon"
                                class="form-control main-control group-item mt-0"
                                placeholder="Phone Number"
                                :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                              ></b-form-input>
                            </div>
                            <VValidate
                              vclass="d-block"
                              name="Country Code"
                              v-model="inputNetwork.input_dial_code"
                              :rules="{ required: true }"
                            />
                            <VValidate
                              vclass="d-block"
                              name="Phone Number"
                              v-model="inputNetwork.input_no_telfon"
                              :rules="{ required: true, min: 5, max: 16 }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">Email Address</label>
                            <input
                              tabindex="5"
                              type="text"
                              v-model="inputNetwork.input_email"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@gmail.com"
                            />
                            <VValidate
                              name="Email Address"
                              v-model="inputNetwork.input_email"
                              :rules="{
                                required: true,
                                max: 128,
                                email: true
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Age</label>
                            <div class="d-flex flex-column mt-3">
                              <b-form-radio v-model="inputNetwork.input_age" name="age" value="under 12"
                                >Under 12 years old</b-form-radio
                              >
                              <b-form-radio
                                v-model="inputNetwork.input_age"
                                name="age"
                                value="above 12"
                                >12 years old and above</b-form-radio
                              >
                            </div>
                            <VValidate
                              name="Age"
                              v-model="inputNetwork.input_age"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end pt-3">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyNetwork',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            type="submit"
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <div class="b-grey-black px-4 py-4 bdrd-12px">
              <div class="d-flex mb-3">
                <i class="icon-help-circle"></i>
                <h5 class="bold ml-2">Help Center</h5>
              </div>
              <div class="wrap-help-nav mb-3">
                <router-link :to="{ name: 'FAQ' }" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">FAQ</span>
                      <p class="d-block">
                        Don't know where to turn? Our FAQ section is here to help with any questions.
                      </p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="wrap-help-nav mb-3">
                <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">Chat Whatsapp</span>
                      <p class="d-block">Get in touch with us via WhatsApp for fast and easy communication.</p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </a>
              </div>

              <div class="wrap-help-nav mb-3">
                <router-link :to="{ name: 'ContactUs' }" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">Send a Message</span>
                      <p class="d-block">
                        Send us a message via our contact form and we'll respond as soon as possible.
                      </p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #page-title>
      <div class="d-flex align-items-center">
        <router-link :to="$route.params.pageSlug ? { name: 'MyNetwork' } : {name: 'Dashboard'}">
          <i class="icon-chevron-left fs-175em pointer text-white mr-2"></i>
        </router-link>
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">My Network</h2>
      </div>
    </template>

    <template #content-mobile>
      <div class="row mb-5 mt-2">
        <div class="col-md-12">
          <div class="overview" v-if="!$route.params.pageSlug">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div>
                  <div class="mb-2">
                    <h5 class="bold ls-0">List of Network</h5>
                    <span class="normal ">You can add up to 15 network</span>
                  </div>
                  <router-link
                    v-if="((networkData || {}).data || {}).length > 0"
                    :to="{
                      name: 'MyNetwork',
                      params: {
                        pageSlug: 'add'
                      },
                      query: {...$route.query}
                    }"
                    class="text-link"
                    ><i class="fa fa-plus mr-1"></i> Add a New Network</router-link
                  >
                </div>
                <div class="mt-4">
                  <template v-if="((networkData || {}).data || {}).length > 0">
                    <div class="b-grey-black mt-3 px-3 py-3 bdrd-12px d-flex justify-content-between" v-for="(v,k) in (networkData || {}).data" :key="k">
                      <div>
                        <p class="text-dark normal fs-115em mb-0">{{(networkData.per_page*(networkData.current_page-1))+k+1}}. {{ v.first_name }} {{ v.last_name }}</p>
                      </div>
                      <div>
                        <router-link
                          :to="{
                            name: 'MyNetwork',
                            params: {
                              pageSlug: v.id
                            }
                          }"
                          class="text-link mx-3"
                          >Edit</router-link
                        >
                        <a
                          @click="openModalConfirmationDelete(v.id)"
                          href="javascript:;"
                          class="text-link"
                          >Remove</a
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="b-grey-black mt-3 px-3 py-3 bdrd-12px d-flex justify-content-between">
                      <div class="relative card-private-page p-4 bdrd-12px mt-3">
                        <div class="initial-content d-flex flex-column align-items-center text-center">
                          <div class="wrapper-illustration w-50 d-flex justify-content-center">
                            <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="document" class="w-50" />
                          </div>
                          <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No network found</h2>
                          <span>You don't have a network yet, let's create a new one now</span>
                          <router-link
                            :to="{
                              name: 'MyNetwork',
                              params: {
                                pageSlug: 'add'
                              },
                              query: {...$route.query}
                            }"
                            class="btn btn-primary semibold d-flex mt-3 align-items-center d-block"
                            ><i class="fa fa-plus mr-1"></i> Add a New Network</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="wrap-pagination mt-1 d-flex justify-content-end">
                <b-pagination
                  v-model="networkData.current_page"
                  v-if="!isLoading && networkData.data && networkData.last_page > 1" 
                  :total-rows="networkData.total" 
                  :per-page="networkData.per_page" 
                  prev-text="Prev" 
                  next-text="Next"
                  @change="doPaginate"
                  :hide-goto-end-buttons="true"
                  pills
                ></b-pagination>
              </div>
          </div>
          <div class="overview" v-else>
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between bb-1px-grey pb-3">
                  <div>
                    <h5 class="bold ls-0">{{ $route.params.pageSlug != 'add' ? 'Edit' : 'Add a New'}} Network</h5>
                  </div>
                </div>
                <div class="mt-4 d-flex justify-content-between">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="MyNetworkForm">
                    <b-form
                      ref="MyNetworkFormElement"
                      @submit.prevent="handleSubmit(submitFormMyNetwork)"
                    >
                      <div class="row bb-1px-grey pb-3">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">First &amp; Middle Name</label>
                            <input
                              tabindex="1"
                              type="text"
                              v-model="inputNetwork.input_first_name"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate
                              name="First &amp; Middle Name"
                              v-model="inputNetwork.input_first_name"
                              :rules="{
                                required: true,
                                min: 3,
                                max: 128,
                                regex: /^([A-Za-z' ]+)$/,
                              }"
                            />
                          </div>
                          <b-form-checkbox
                            tabindex="3"
                            id="is_lastname"
                            v-model="inputNetwork.input_is_last_name"
                            name="is_lastname"
                            value="N"
                            unchecked-value="Y"
                            @click.native="checkIsLastName(inputNetwork.input_is_last_name)"
                          >
                            <span class="normal">This person doesn't have family name / last name</span>
                          </b-form-checkbox>
                        </div>
                        <div class="col-md-6 col-12 mt-3">
                          <div class="form-group">
                            <label class="required bold ls-0">Last Name</label>
                            <template v-if="inputNetwork.input_is_last_name == 'Y'">
                              <input
                                tabindex="2"
                                type="text"
                                v-model="inputNetwork.input_last_name"
                                class="form-control main-control"
                                placeholder="e.g. Grahita"
                              />
                              <VValidate
                                name="Last Name"
                                v-model="inputNetwork.input_last_name"
                                :rules="{
                                  required: inputNetwork.input_is_last_name == 'N' ? false : true,
                                  min: 3,
                                  max: 96,
                                  regex: /^([A-Za-z' ]+)$/,
                                }"
                              />
                            </template>
                            <template v-else>
                              <input
                                type="text"
                                class="form-control main-control"
                                placeholder="e.g. Grahita"
                                :value="inputNetwork.input_first_name"
                                disabled
                              />
                            </template>
                          </div>
                        </div>
                        <div class="col-md-3 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Gender</label>
                            <div class="d-flex mt-3">
                              <b-form-radio v-model="inputNetwork.input_gender" name="gender" value="M"
                                >Male</b-form-radio
                              >
                              <b-form-radio
                                v-model="inputNetwork.input_gender"
                                class="ml-3"
                                name="gender"
                                value="F"
                                >Women</b-form-radio
                              >
                            </div>
                            <VValidate
                              name="Gender"
                              v-model="inputNetwork.input_gender"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                        <div class="col-md-9 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Phone Number</label>
                            <div class="input-group d-flex mt-3">
                              <v-select
                                v-model="inputNetwork.input_dial_code"
                                placeholder="Code"
                                :options="countryCodeData"
                                :reduce="(v) => v.country_code_id"
                                id="countrycode"
                                class="pointer w-25"
                                :get-option-label="(o) => o.countrycode_name + ' ' + o.countrycode_dial_code"
                              >
                                <template #option="{ countrycode_dial_code, countrycode_name }">
                                  <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                                </template>
                                <template #selected-option="{ countrycode_dial_code }">
                                  <span>{{ countrycode_dial_code }}</span>
                                </template>
                              </v-select>
                              <b-form-input
                                tabindex="4"
                                v-model="inputNetwork.input_no_telfon"
                                class="form-control main-control group-item mt-0"
                                placeholder="Phone Number"
                                :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                              ></b-form-input>
                            </div>
                            <VValidate
                              vclass="d-block"
                              name="Country Code"
                              v-model="inputNetwork.input_dial_code"
                              :rules="{ required: true }"
                            />
                            <VValidate
                              vclass="d-block"
                              name="Phone Number"
                              v-model="inputNetwork.input_no_telfon"
                              :rules="{ required: true, min: 5, max: 16 }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Email Address</label>
                            <input
                              tabindex="5"
                              type="text"
                              v-model="inputNetwork.input_email"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@gmail.com"
                            />
                            <VValidate
                              name="Email Address"
                              v-model="inputNetwork.input_email"
                              :rules="{
                                required: true,
                                max: 128,
                                email: true
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Age</label>
                            <div class="d-flex flex-column mt-3">
                              <b-form-radio v-model="inputNetwork.input_age" name="age" value="under 12"
                                >Under 12 years old</b-form-radio
                              >
                              <b-form-radio
                                v-model="inputNetwork.input_age"
                                name="age"
                                value="above 12"
                                >12 years old and above</b-form-radio
                              >
                            </div>
                            <VValidate
                              name="Gender"
                              v-model="inputNetwork.input_age"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end pt-3">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyNetwork',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            type="submit"
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #custom-layout>
      <AppAlert
        ref="modal-success"
        vref="modal-success"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        :title="messageResponse.title"
        :description="messageResponse.desc"
        :button="{
          main: {
            text: 'Ok',
            handle: $route.query.ref ? onConfirmWithRef : onConfirmWithoutRef,
          },
        }"
        @close="(id) => $route.query.ref ? onConfirmWithRef(id) : onConfirmWithoutRef(id)"
      />

      <AppAlert
        ref="modal-error"
        vref="modal-error"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
        }"
        icon-size="w-50"
        :title="messageErrTitle"
        :description="messageErrDescription"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppModal :withPageTitle="false" ref="modal-confirmation-delete" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/option-vect.svg`" alt="" class="w-25" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Delete the network?</p>
                <span class="regular fs-1em">You won't be able to recover this data.</span>
                <div class="col-md-12 d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    @click="$refs['modal-confirmation-delete'].hide()"
                    class="btn btn-outline-main ml-0"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    @click="onDeleteNetwork"
                    class="btn btn-primary"
                    :class="isOnlyMobile() ? 'w-100' : ''"
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

    </template>
  </LayoutPrivatePage>
</template>

<script>
// @ is an alias to /src
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'

import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'
import AppAlert from '@/components/AppAlert'
import AppModal from '@/components/AppModal'

export default {
  extends: Global,
  name: 'MyNetwork',
  components: {
    LayoutPrivatePage,
    AppAlert,
    AppModal
  },
  data() {
    return {
      messageErrTitle: '',
      messageErrDescription: '',
      formInputAvatar: {
        registrant_image: '',
        registrant_fallback_image: '',
      },
      perPage: 3,
      currentPage: 1,
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
      ],
      filter: {
        search: '',
      },
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Running Events',
          active: true,
        },
      ],
      isLoading: false,
    }
  },
  computed: {
    ...mapFields(['user','loading','channels']),
    ...mapFields("useMyNetworkStore", ["networkData", "inputNetwork", "networkId", "messageResponse"]),
    ...mapFields("profile",[
      "countryCodeData",
    ]),
    ...mapGetters(['authenticated']),
    rows() {
      return this.items.length
    },
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })

    this.$store.commit('SET_STATIC_SEO', {
      title: 'My Network'
    })

    this.getCountryCode()
  },
  methods: {
    ...mapActions(["watchAuth", "getChannels"]),

    ...mapActions("useMyNetworkStore", [
      "getAllNetwork",
      "getNetworkById",
      "addNewNetwork",
      "updateNetwork",
      "deleteNetwork"
    ]),
    
    ...mapActions("profile", [
      "getCountryCode"
    ]),

    onConfirmWithRef(modalId) {
      this.$refs[modalId].hide()
      console.log('reswith', !this.$route.query.ref)
      console.log('refqwith', this.$route.query.ref)
      console.log('backrefwithref', this.$route.meta.backRef)
      this.$router.push({ name: this.$route.meta.backRef }).catch(() => {})
    },

    onConfirmWithoutRef(modalId) {
      this.$refs[modalId].hide()
      console.log('reswithout', !this.$route.query.ref)
      console.log('refqwithout', this.$route.query.ref)
      console.log('backpagewithoutref', this.$route.meta.backPage)
      this.$router.push({ name: this.$route.meta.backPage }).catch(() => {})
    },

    scrollToTop(qs) {
      const scrollPosition = document.querySelector(qs).scrollTop

      if (scrollPosition === undefined) {
        return null
      }

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - (scrollPosition / 8))
      }
    },

    showSearchModal(modalId, inputId) {
      this.$refs[modalId].show()
      document.querySelector(`#${inputId} .vs__search`).blur()
      this.scrollToTop('.second-window .content')
    },

    async submitFormMyNetwork() {
      try{
        if(this.$route.params.pageSlug != 'add'){
          const res = await this.updateNetwork((success, res) => {
            if(success) {
              this.$refs["modal-success"].show()
            } else {
              this.messageErrTitle = res.response.data.message.title
              this.messageErrDescription = res.response.data.message.desc
              this.$refs['modal-error'].show()
            }
          })

        } else {
          const res = await this.addNewNetwork((success, res) => {

            if (success) {
              this.$refs["modal-success"].show()
            } else {
              this.messageErrTitle = res.response.data.message.title
              this.messageErrDescription = res.response.data.message.desc
              this.$refs['modal-error'].show()
            }
          })

        }
      } catch(err) {
        return err
      }
    },

    openModalConfirmationDelete(id) {
      this.$store.commit("useMyNetworkStore/SET_NETWORK_ID", id)
      this.$refs['modal-confirmation-delete'].show()
    },

    async onDeleteNetwork() {
      const res = await this.deleteNetwork()

      if(res.data.message) {
        this.$refs['modal-confirmation-delete'].hide()
        this.$refs['modal-success'].show()
        this.$store.commit("useMyNetworkStore/CLEAR_NETWORK_ID")

        this.getAllNetwork({
          page: this.filter.page,
          callback: () => {
            this.isLoading = false
          }
        })
      }
    },

    doPaginate(page = 1) {
      this.$set(this.filter, 'page', page)

      this.$router.push({
        name: this.$route.name, 
        params: {...this.$route.params, savePosition: true}, 
        query: {...this.filter}
      }).catch(() => {})
    },

    checkIsLastName(isLastName) {
      if(isLastName == 'Y') {
        this.inputNetwork.input_last_name = this.inputNetwork.input_first_name
      } else {
        this.inputNetwork.input_last_name = ''
      }
    }

  },
  watch: {
    $route: {
      handler() {
        this.isLoading = true

        if(this.$route.params.pageSlug) {
          if(this.$route.params.pageSlug != 'add') {
            this.getNetworkById({
              id: this.$route.params.pageSlug,
              callback: () => {
                this.isLoading = false
              }
            })
            this.$store.commit("useMyNetworkStore/SET_NETWORK_ID", this.$route.params.pageSlug)
          } else {
            this.$store.commit("useMyNetworkStore/CLEAR_INPUT_FORM_NETWORK")
            this.$store.commit("useMyNetworkStore/CLEAR_NETWORK_ID")
          }
        } else {
          this.getAllNetwork({
            page: this.filter.page,
            callback: () => {
              this.isLoading = false
            }
          })
        }
      },
      immediate: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.view-password {
  top: 29px;
  right: 17px;
  z-index: 5;
  cursor: pointer;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control.main-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
}

.control-group {
  border-top-left-radius: 99px;
  border-bottom-left-radius: 99px;
}

#password,
#confirmPassword {
  padding-right: 41px !important;
}

.profile-mobile {
  .img {
    width: 65px;
    height: 65px;
    background: #fff;
    position: relative;
    border-radius: 50%;
    img {
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .icon-cust {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: #fff;
      width: 20px;
      height: 20px;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }
}

.main-color {
  color: #95378e;
}
</style>

import { getField, updateField } from 'vuex-map-fields'
import { http } from '../../config/http'
import Gen from '@/libs/Gen'

export default {
  namespaced: true,

  state: {
    WWUHotelData: [],
    confirmContent: {
      title: '',
      description: '',
      cta: '',
    },
    inputForm: {
      "input_email_contact": "",
      "input_hotel_name": "",
      "input_hotel_describe": "",
      "input_number_of_floors": "",
      "input_number_of_rooms": "",
      "input_main_currency": "",
      "input_cancellation_policy": "",
      "input_distance_from_city": "",
      "input_distance_from_airport": "",
      "input_country": "",
      "input_state": "",
      "input_city": "",
      "input_postal_code": "",
      "input_street_address1": "",
      "input_street_address2": "",
      "input_street_address3": "",
      "input_url_maps": "",
      "input_reception_area": "",
      "input_checkin_time_start": "15:00",
      "input_checkin_time_end": "23:59",
      "input_checkout_time": "12:00",
      "input_hotel_url_website": "",
      "input_checkout_time": "",
      "input_breakfast_charge": "",
      "input_common_facilities": [],
      "input_access_facilities": [],
      "input_business_facilities": [],
      "input_area_facilities": [],
      "input_inside_room_facilities": [],
      "input_nearby_amenities_facilities": [],
      "input_payment_information_facilities": [],
      "input_rooms": [],
      "input_gallery":[]
    },
    mrData: []
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_WWU_HOTEL_DATA(state, data) {
      state.WWUHotelData = data
    },
    SET_WWU_HOTEL_CONFIRM_DATA(state, data) {
      state.confirmContent = data.content
    },
    SET_MASTER_DATA(state, data) {
      state.mrData = data
    },
    CLEAR_INPUT_FORM(state) {
      state.inputForm.input_email_contact = ""
      state.inputForm.input_hotel_name = ""
      state.inputForm.input_hotel_describe = ""
      state.inputForm.input_number_of_floors = ""
      state.inputForm.input_number_of_rooms = ""
      state.inputForm.input_main_currency = ""
      state.inputForm.input_cancellation_policy = ""
      state.inputForm.input_distance_from_city = ""
      state.inputForm.input_distance_from_airport = ""
      state.inputForm.input_country = ""
      state.inputForm.input_state = ""
      state.inputForm.input_city = ""
      state.inputForm.input_postal_code = ""
      state.inputForm.input_street_address1 = ""
      state.inputForm.input_street_address2 = ""
      state.inputForm.input_street_address3 = ""
      state.inputForm.input_url_maps = ""
      state.inputForm.input_reception_area = ""
      state.inputForm.input_checkin_time_start = "15:00"
      state.inputForm.input_checkin_time_end = "23:59"
      state.inputForm.input_checkout_time = "12:00"
      state.inputForm.input_hotel_url_website = ""
      state.inputForm.input_breakfast_charge = ""
      state.inputForm.input_common_facilities = []
      state.inputForm.input_access_facilities = []
      state.inputForm.input_business_facilities = []
      state.inputForm.input_area_facilities = []
      state.inputForm.input_inside_room_facilities = []
      state.inputForm.input_nearby_amenities_facilities = []
      state.inputForm.input_payment_information_facilities = []
      state.inputForm.input_rooms = []
      state.inputForm.input_gallery = []
    },
    SET_CHECKIN_END_TIME(state, data) {
      state.inputForm.input_checkin_time_end = data
    },
  },

  actions: {
    async getConfirmContent({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-hotel/confirm', {
          headers: Gen.apiHeader(),
        })

        commit('SET_WWU_HOTEL_CONFIRM_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDataContent({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-hotel/data', {
          headers: Gen.apiHeader(),
        })

        if(res.data.content.is_active == 'N') {
          if(router.currentRoute.name == 'WWUHotels') {
            router.push({
              name: 'WWULPHotels'
            })
          }
        }

        commit('SET_WWU_HOTEL_DATA', res.data)
        commit('CLEAR_INPUT_FORM')
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        if ((err.response || {}).status === 403) {
          router.push({ name: 'WWULPHotels' })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async getAllMasterForm({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-hotel/all-master-form', {
          headers: Gen.apiHeader(),
        })

        commit('SET_MASTER_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        if ((err.response || {}).status === 403) {
          router.push({ name: 'WWULPHotels' })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async applyHotel({ rootState, commit }, data) {
      const { form, callback = false } = data
      try {
        rootState.loading.screen = true

        const res = await Gen.apiRest(
          '/public-pages/wwu-hotel/add',
          {
            data: { ...form },
          },
          'POST'
        )

        if (callback) {
          callback(true, res)
          commit("CLEAR_INPUT_FORM")
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    }
  },
}

import { getField, updateField } from 'vuex-map-fields'
import { http } from '../../config/http'
import Gen from '@/libs/Gen'

export default {
  namespaced: true,

  state: {
    confirmContent: {
      title: '',
      description: '',
      cta: '',
    },
    WWUJoinTheTeamData: [],
    inputForm: {
      input_email_address: "",
      input_title: "",
      input_first_name: "",
      input_last_name: "",
      input_phone_number: "",
      input_country: "",
      input_city: "",
      input_resume: "",
      input_personal_background: ""
    }
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_WWU_JOIN_THE_TEAM_DATA(state, data) {
      state.WWUJoinTheTeamData = data
    },
    CLEAR_INPUT_FORM(state) {
      state.inputForm.input_email_address = ""
      state.inputForm.input_title = ""
      state.inputForm.input_first_name = ""
      state.inputForm.input_last_name = ""
      state.inputForm.input_phone_number = ""
      state.inputForm.input_country = ""
      state.inputForm.input_city = ""
      state.inputForm.input_resume = ""
      state.inputForm.input_personal_background = ""
    },
    SET_WWU_JOIN_THE_TEAM_CONFIRM_DATA(state, data) {
      state.confirmContent = data.content
    }
  },

  actions: {
    async getConfirmContent({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-join-the-team/confirm', {
          headers: Gen.apiHeader(),
        })

        commit('SET_WWU_JOIN_THE_TEAM_CONFIRM_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
    async getDataContent({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/wwu-join-the-team', {
          headers: Gen.apiHeader(),
        })

        if(res.data.content.is_active == 'N') {
          if(router.currentRoute.name == 'WWUJoinTheTeam') {
            router.push({
              name: 'WWULPJoinTheTeam'
            })
          }
        }

        commit('SET_WWU_JOIN_THE_TEAM_DATA', res.data)
        commit('CLEAR_INPUT_FORM')
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        if ((err.response || {}).status === 403) {
          router.push({ name: 'WWULPJoinTheTeam' })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async applyJoinTheTeam({ rootState, commit }, data) {
      const { form, callback = false } = data
      try {
        rootState.loading.screen = true

        const res = await Gen.apiRest(
          '/public-pages/wwu-join-the-team',
          {
            data: { ...form },
          },
          'POST'
        )

        if (callback) {
          callback(true, res)
          commit("CLEAR_INPUT_FORM")
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    }
  },
}

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'
import router from "../../router/index"

export default {
  namespaced: true,

  state: {
    destinationData: [],
    destinationContent: {
      hero: {
        title: '',
        sub_title: '',
        image: '',
        image_small: '',
        fallback_image: '',
        fallback_image_small: '',
      },
      destination: {
        sub_title: '',
        share: '',
        other: '',
      },
      running_events: {
        future: '',
        present: '',
        past: '',
      },
    },
    destinationDetails: {
      destination: {
        name: '',
        title: '',
        description: '',
        short_description: '',
        slug: '',
        keywords: [],
        geography: {
          title: '',
          description: '',
          image: '',
          fallback_image: '',
        },
        culture: {
          title: '',
          description: '',
          image: '',
          fallback_image: '',
        },
        accommodation: {
          title: '',
          description: '',
          image: '',
          fallback_image: '',
        },
        transportation: {
          title: '',
          description: '',
          image: '',
          fallback_image: '',
        },
        nature: {
          title: '',
          description: '',
          image: '',
          fallback_image: '',
        },
      },
      running_events: {
        title: '',
        description: '',
        data: [],
      },
    },
    otherDestinations: [],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_DESTINATION_DATA(state, data) {
      state.destinationData = data.data
      state.dataLength = data.dataLength
    },
    SET_DESTINATION_CONTENT(state, data) {
      state.destinationContent = data
    },
    SET_DESTINATION_DETAILS(state, data) {
      state.destinationDetails = data
    },
  },

  actions: {
    async getDestinationData({ commit, rootState }, data) {
      const { page } = data

      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/destinations', {
          headers: Gen.apiHeader(),
          params: {
            page,
          }
        })

        commit('SET_DESTINATION_DATA', res.data)
        commit('SET_DESTINATION_CONTENT', res.data.content)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    async getDestinationDetails({ commit, rootState }, slug) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/destinations/${slug}`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_DESTINATION_CONTENT', res.data.content)
        commit('SET_DESTINATION_DETAILS', res.data.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        if (err.response.status === 404) {
          return router.push({name: 'NotFound'})
        }
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"accordion-menu"},[_c('ul',{staticClass:"ul-accordion"},[_c('li',{staticClass:"li-accordion"},[_c('input',{attrs:{"type":"checkbox","checked":""}}),_c('i',{staticClass:"arrow"}),_c('div',{staticClass:"d-flex align-items-center"},[_c('h2',{staticClass:"bold mb-0"},[_vm._v("1. Profile Information ")]),(_vm.myInformation.profile === 0)?_c('i',{staticClass:"ml-2 fa fa-exclamation-circle fs-15em text_secondary"}):_c('i',{staticClass:"ml-2 fa fa-check-circle fs-15em text_success"})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',{staticClass:"d-flex align-itmes-center mb-0"},[(_vm.user.registrant_passportexp === null)?_c('i',{staticClass:"fa fa-exclamation-circle fs-15em text_secondary"}):_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add Passport Expiration Date")])]),(_vm.myInformation.profile === 0)?_c('p',{staticClass:"mb-0"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'ProfileInformation', query: {ref: 1} }}},[_vm._v("Complete Now")])],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"d-flex align-itmes-center mb-0"},[_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add first & middle name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"d-flex align-itmes-center mb-0"},[_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add last name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"d-flex align-itmes-center mb-0"},[_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add phone number")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"d-flex align-itmes-center mb-0"},[_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add citizenship")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"d-flex align-itmes-center mb-0"},[_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add gender")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"d-flex align-itmes-center mb-0"},[_c('i',{staticClass:"fa fa-check-circle fs-15em text_success"}),_c('span',{staticClass:"grey-color regular ml-2"},[_vm._v("Add date of birth")])])
}]

export { render, staticRenderFns }
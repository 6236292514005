<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
        <section id="page-title" :style="heroBackground">
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="page-title d-flex flex-column align-items-center" :class="isOnlyMobile() ? 'py-5' : ''">
              <div class="label-custom green">
                <span class="black">{{ (destinationContent.hero || {}).sub_title }}</span>
              </div>
              <h2 class="black text-medium text-white mt-3" :class="!isMobile() ? 'w-75' : ''">
                {{ (destinationContent.hero || {}).title }}
              </h2>
              <span class="semibold text-white" :class="!isMobile() ? 'w-50' : ''">
                {{ (destinationContent.hero || {}).description }}</span
              >
            </div>
          </div>
        </section>
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isOnlyMobile()">
        <div class="mobile-version-view height-auto pt-4 pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <template v-if="(destinationData.data || []).length > 0">
                    <CountryItemAtom
                      v-for="(v, k) in destinationData.data"
                      :key="k"
                      :image="v.image"
                      :fallback-image="v.fallback_image"
                      :country="v.name"
                      :slug="v.slug"
                      :uploader="uploader"
                      col="col-6"
                      variant="small"
                    />
                  </template>
                  <template v-else>
                    <div class="row mt-0 justify-content-center py-5">
                      <div class="col-md-6 d-flex flex-column align-items-center">
                        <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-45 mb-3" />
                        <h5 class="ls-0">Destination is not available right now</h5>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto mx-auto">
                <div class="wrap-pagination mt-3 d-flex justify-content-end">
                  <b-pagination
                    v-model="destinationData.current_page"
                    v-if="destinationData.data && destinationData.last_page > 1" 
                    :total-rows="destinationData.total" 
                    :per-page="destinationData.per_page" 
                    prev-text="Prev" 
                    next-text="Next"
                    @change="doPaginate"
                    :hide-goto-end-buttons="true"
                    pills
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="isTablet()">
        <div class="mobile-version-view height-auto py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <template v-if="(destinationData.data || []).length > 0">
                    <CountryItemAtom
                      v-for="(v, k) in destinationData.data"
                      :key="k"
                      :image="v.image"
                      :fallback-image="v.fallback_image"
                      :country="v.name"
                      :slug="v.slug"
                      :uploader="uploader"
                      col="col-6"
                    />
                  </template>
                  <template v-else>
                    <div class="row mt-0 justify-content-center py-5">
                      <div class="col-md-6 d-flex flex-column align-items-center">
                        <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-45 mb-3" />
                        <h5 class="ls-0">Destination is not available right now</h5>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto mx-auto">
                <div class="wrap-pagination mt-3 d-flex justify-content-end">
                  <b-pagination
                    v-model="destinationData.current_page"
                    v-if="destinationData.data && destinationData.last_page > 1" 
                    :total-rows="destinationData.total" 
                    :per-page="destinationData.per_page" 
                    prev-text="Prev" 
                    next-text="Next"
                    @change="doPaginate"
                    :hide-goto-end-buttons="true"
                    pills
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="isDesktop()">
        <section class="mt-0 pt-3">
          <div class="container">
            <div class="row mt-0">
              <div class="col-md-12">
                <div class="row justify-content-center">
                  <template v-if="(destinationData.data || []).length > 0">
                    <CountryItemAtom
                      v-for="(v, k) in destinationData.data"
                      :key="k"
                      :image="v.image"
                      :fallback-image="v.fallback_image"
                      :country="v.name"
                      :slug="v.slug"
                      :uploader="uploader"
                      col="col-md-4"
                    />
                  </template>
                  <template v-else>
                    <div class="row mt-0 justify-content-center py-5">
                      <div class="col-md-6 d-flex flex-column align-items-center">
                        <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-45 mb-3" />
                        <h5 class="ls-0">Destination is not available right now</h5>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto mx-auto">
                <div class="wrap-pagination mt-3 d-flex justify-content-end">
                  <b-pagination
                    v-model="destinationData.current_page"
                    v-if="destinationData.data && destinationData.last_page > 1" 
                    :total-rows="destinationData.total" 
                    :per-page="destinationData.per_page" 
                    prev-text="Prev" 
                    next-text="Next"
                    @change="doPaginate"
                    :hide-goto-end-buttons="true"
                    pills
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'
import CountryItemAtom from '@/views/atoms/destination/CountryItemAtom.vue'

export default {
  extends: Global,
  name: 'Destination',
  components: {
    carousel,
    Header,
    CountryItemAtom,
  },
  data() {
    return {
      isWebPSupport: false,
      filter: {
        page: undefined,
      },
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Where We Go',
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapFields('useDestinationStore', ['destinationData', 'destinationContent']),
    ...mapFields(['channels']),
    heroBackground() {
      let src = '';
      if (this.isOnlyMobile()) {
        src = this.isWebPSupport
          ? this.uploader((this.destinationContent.hero || {}).image_small)
          : this.uploader((this.destinationContent.hero || {}).fallback_image_small)
      } else {
        src = this.isWebPSupport
          ? this.uploader((this.destinationContent.hero || {}).image)
          : this.uploader((this.destinationContent.hero || {}).fallback_image)
      }

      if (src === '') {
        return null
      }

      return `
        background: url('${src}') center center / cover;
      `
    },
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('useDestinationStore', ['getDestinationData']),
    ...mapActions(['getChannels']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
    doPaginate(page = 1) {
      this.$set(this.filter, 'page', page)

      this.$router.push({
        name: this.$route.name, 
        params: {...this.$route.params, savePosition: true}, 
        query: {...this.filter}
      }).catch(() => {})
    },
  },
  watch: {
    $route: {
      handler() {
        this.getDestinationData({
          page: this.filter.page
        })
      },
      immediate: true,
    }
  }
}
</script>

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'
import router from '../../router/index'

export default {
  namespaced: true,
  state: {
    featured: [
      {
        thumbnail: '',
        type: [],
        slug: '',
      },
      {
        thumbnail: '',
        type: [],
        slug: '',
      },
      {
        thumbnail: '',
        type: [],
        slug: '',
      },
      {
        thumbnail: '',
        type: [],
        slug: '',
      },
    ],
    content: {
      hero: {
        title: '',
      },
      featured: {
        title: '',
      },
      stories: {
        title: '',
      },
      tags: {
        title: '',
        other_title: '',
        selected_title: '',
      },
      initial: {
        title: '',
      },
      search: {
        title: '',
      },
      sidebanner: {
        image: '',
        fallback_image: '',
        alt_image: '',
        link: '',
        target: '',
      },
    },
    data: {
      data: [],
    },
    details: {},
    other_stories: [],
    dataLength: 1,
    tags: [],
    selectedTag: {
      tag: '',
      tag_slug: '',
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_RUNNER_SAY_CONTENT(state, data) {
      state.featured = data.featured
      state.content = data.content
      state.data = data.data
      state.tags = data.tags
      state.selectedTag = data.selectedTag
      state.editorPicks = data.editorPicks
      state.dataLength = data.dataLength
      state.categories = data.categories
    },
    SET_RUNNER_SAY_DETAILS_CONTENT(state, data) {
      state.details = data.data
      state.other_stories = data.other_stories
    },
  },

  actions: {
    async getRunnerSaysData({ commit, rootState }, data) {
      const { page, search, tag, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const resp = await http.get('/public-pages/runner-says', {
          headers: Gen.apiHeader(),
          params: {
            page,
            search,
            tag,
          },
        })

        commit('SET_RUNNER_SAY_CONTENT', resp.data)
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        return error
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }

        if (callback) {
          callback()
        }
      }
    },
    async getRunnerSayDetailsData({ commit, rootState }, data) {
      const { slug, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const resp = await http.get(`/public-pages/runner-says/${slug}`, {
          headers: Gen.apiHeader(),
          params: {
            slug,
          },
        })

        if (callback) {
          callback(resp.data)
        }

        commit('SET_RUNNER_SAY_DETAILS_CONTENT', resp.data)
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        if (error.response.status === 404) {
          return router.push({name: 'NotFound'})
        }

        return error
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }

        if (callback) {
          callback()
        }
      }
    },
  },
}

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
        <section id="page-title" :style="heroBackground">
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="page-title d-flex flex-column align-items-center" :class="isOnlyMobile() ? 'py-5' : ''">
              <div class="label-custom green">
                <span class="black">{{ (contactData.hero || {}).sub_title }}</span>
              </div>
              <h2 class="black text-medium text-white mt-3" :class="!isMobile() ? 'w-75' : ''">
                {{ (contactData.hero || {}).title }}
              </h2>
              <span class="semibold text-white" :class="!isMobile() ? 'w-50' : ''">{{
                (contactData.hero || {}).description
              }}</span>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isDesktop()">
        <section class="mt-0">
          <div class="container">
            <div class="row">
              <div class="col-md-6 pr-5">
                <span class="sub-title-section">
                  <div class="line-sub-title"></div>
                  {{ (contactData.contact.data || {}).sub_title }}
                </span>
                <h2 class="title-section-left mt-3">{{ (contactData.contact.data || {}).title }}</h2>
                <span class="grey-color regular">{{ (contactData.contact.data || {}).description }}</span>
                <div class="my-3">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="d-flex align-items-center my-2">
                        <i class="icon-phone-call gamma-color fs-1em"></i>
                        <a target="_blank" :href="`https://wa.me/${((contactData.contact.data || {}).telephone || '').replace(/[+\-]/g, '')}`"
                          ><span class="mx-3 text-dark text-underline">{{
                            (contactData.contact.data || {}).telephone
                          }}</span></a
                        >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex align-items-center my-2">
                        <i class="icon-mail gamma-color fs-1em"></i>
                        <a target="_blank" :href="`mailto:${(contactData.contact.data || {}).email_address}`"
                          ><span class="mx-3 text-dark text-underline">{{
                            (contactData.contact.data || {}).email_address
                          }}</span></a
                        >
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="d-flex align-items-center my-2">
                        <i class="icon-map-pin gamma-color fs-1em"></i>
                        <a target="_blank" :href="(((contactData.address || {}).data || {}).link || '').replaceAll('\/', '/')"
                          ><span class="mx-3 text-dark text-underline">{{
                            ((contactData.address.data || {}).description || []).join(' ')
                          }}</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <iframe class="bdrd-24px" height="295" :src="iframeSrc" frameborder="0"></iframe>
                </div>
              </div>
              <div class="col-md-6 pl-3">
                <div class="b-grey-black p-4 bdrd-24px">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="ContactUsForm">
                    <b-form ref="ContactFormElement" @submit.prevent="handleSubmit(submitContact)">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="fllname" class="required bold">Full Name</label>
                            <input
                              id="fllname"
                              type="text"
                              v-model="form.fullname"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate name="Full Name" v-model="form.fullname" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="emailAddr" class="required bold">Email Address</label>
                            <input
                              id="emailAddr"
                              type="email"
                              v-model="form.email_address"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@example.com"
                            />
                            <VValidate name="Email Address" v-model="form.email_address" :rules="{required: true, email: true, max: 96}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="phonenumm" class="bold">Phone Number</label>
                            <b-form-input
                              id="phonenum"
                              type="tel"
                              v-model="form.phone_number"
                              class="form-control main-control"
                              placeholder="e.g. Phone Number"
                              :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                            ></b-form-input>
                             <VValidate name="Phone Number" v-model="form.phone_number" :rules="{min: 5, max: 16}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="subjectField" class="required bold">Subject</label>
                            <input
                              id="subjectField"
                              type="text"
                              v-model="form.subject"
                              class="form-control main-control"
                              placeholder="e.g. Berlin Marathon Registration"
                            />
                            <VValidate name="Subject" v-model="form.subject" :rules="{required: true, min: 3, max: 128}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="messageField" class="required bold">Message</label>
                            <textarea
                              id="messageField"
                              type="text"
                              v-model="form.message"
                              class="form-control main-control text-area"
                              placeholder="Enter message..."
                            ></textarea>
                            <VValidate name="Message" v-model="form.message" :rules="{required: true, min: 10, max: 500}" />
                          </div>
                        </div>

                        <!-- COMPONENT :: RECAPTCHA -->
                        <div class="col-md-12">
                          <vue-recaptcha
                            @verify="verifyCaptcha"
                            :sitekey="
                              isDev
                                ? '6LdqGFMgAAAAAPSPlIxQLapQeYqsrVaGzaPFUE3Q'
                                : '6LesGVMgAAAAAGsBOggH-VY00_u0taSceZHCwe2p'
                            "
                            ref="Captcha"
                          ></vue-recaptcha>
                        </div>
                        <!-- END -->

                        <div class="col-md-12 mt-3">
                          <template v-if="activeSubmit">
                            <button class="btn btn-slg btn-primary bold w-100" type="submit">Send Message</button>
                          </template>
                          <template v-else>
                            <button class="btn btn-slg btn-primary bold w-100" type="button" disabled>Send Message</button>
                          </template>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>

      <template v-else-if="isOnlyMobile()">
        <div class="mobile-version-view py-5">
          <div class="container">
            <div class="row">
              <span class="sub-title-section">
                <div class="line-sub-title"></div>
                {{ (contactData.contact.data || {}).sub_title }}
              </span>
              <h2 class="title-section-left mt-3">{{ (contactData.contact.data || {}).title }}</h2>
              <span class="grey-color">{{ (contactData.contact.data || {}).description }}</span>
              <div class="my-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex align-items-center my-2">
                      <i class="icon-phone-call gamma-color fs-1em"></i>
                      <a href=""
                        ><span class="mx-3 text-dark text-underline">{{
                          (contactData.contact.data || {}).telephone
                        }}</span></a
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex align-items-center my-2">
                      <i class="icon-mail gamma-color fs-1em"></i>
                      <a href=""
                        ><span class="mx-3 text-dark text-underline">{{
                          (contactData.contact.data || {}).email_address
                        }}</span></a
                      >
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex align-items-center my-2">
                      <i class="icon-map-pin gamma-color fs-1em"></i>
                      <a href="javascript:;"
                        ><span class="mx-3 text-dark text-underline">{{
                          ((contactData.address.data || {}).description || []).join(' ')
                        }}</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="bt-1px-black pb-5">
                <iframe class="bdrd-24px" height="295" :src="iframeSrc" frameborder="0"></iframe>
              </div>
              <div class="my-5">
                <div class="row">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="ContactUsForm">
                    <b-form ref="ContactFormElement" @submit.prevent="handleSubmit(submitContact)">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="fullName" class="required bold">Full Name</label>
                          <input
                            id="fullName"
                            type="text"
                            v-model="form.fullname"
                            class="form-control main-control"
                            placeholder="e.g. Abraham Damar"
                          />
                          <VValidate name="Full Name" v-model="form.fullname" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="emailAddress" class="required bold">Email Address</label>
                          <input
                            id="emailAddress"
                            type="email"
                            v-model="form.email_address"
                            class="form-control main-control"
                            placeholder="e.g. abraham.damar@example.com"
                          />
                          <VValidate name="Email Address" v-model="form.email_address" :rules="{required: true, email: true, max: 96}" />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="phoneNumb" class="bold">Phone Number</label>
                          <b-form-input
                            id="phoneNumb"
                            type="tel"
                            v-model="form.phone_number"
                            class="form-control main-control"
                            placeholder="e.g. Phone Number"
                            :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                          ></b-form-input>
                           <VValidate name="Phone Number" v-model="form.phone_number" :rules="{min: 5, max: 16}" />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="subjectText" class="required bold">Subject</label>
                          <input
                            id="subjectText"
                            type="text"
                            v-model="form.subject"
                            class="form-control main-control"
                            placeholder="e.g. Berlin Marathon Registration"
                          />
                          <VValidate name="Subject" v-model="form.subject" :rules="{required: true, min: 3, max: 128}" />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="messageText" class="required bold">Message</label>
                          <textarea
                            id="messageText"
                            type="text"
                            v-model="form.message"
                            class="form-control main-control text-area"
                            placeholder="Enter message..."
                          ></textarea>
                          <VValidate name="Message" v-model="form.message" :rules="{required: true, min: 10}" />
                        </div>
                      </div>

                      <!-- COMPONENT :: RECAPTCHA -->
                      <div class="col-md-12">
                        <vue-recaptcha
                          @verify="verifyCaptcha"
                          :sitekey="
                            isDev
                              ? '6LdqGFMgAAAAAPSPlIxQLapQeYqsrVaGzaPFUE3Q'
                              : '6LesGVMgAAAAAGsBOggH-VY00_u0taSceZHCwe2p'
                          "
                          ref="Captcha"
                        ></vue-recaptcha>
                      </div>
                      <!-- END -->

                      <div class="col-md-12 mt-3">
                        <template v-if="activeSubmit">
                          <button class="btn btn-slg btn-primary bold w-100" type="submit">Send Message</button>
                        </template>
                        <template v-else>
                          <button class="btn btn-slg btn-primary bold w-100" type="button" disabled>Send Message</button>
                        </template>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="isTablet()">
        <section class="mt-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-6 pr-3">
                <span class="sub-title-section">
                  <div class="line-sub-title"></div>
                  {{ (contactData.contact.data || {}).sub_title }}
                </span>
                <h2 class="title-section-left mt-3">{{ (contactData.contact.data || {}).title }}</h2>
                <span class="grey-color regular">{{ (contactData.contact.data || {}).description }}</span>
                <div class="my-3">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex align-items-center my-2">
                        <i class="icon-phone-call gamma-color fs-1em"></i>
                        <a href=""
                          ><span class="mx-3 text-dark text-underline">{{
                            (contactData.contact.data || {}).telephone
                          }}</span></a
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center my-2">
                        <i class="icon-mail gamma-color fs-1em"></i>
                        <a href=""
                          ><span class="mx-3 text-dark text-underline">{{
                            (contactData.contact.data || {}).email_address
                          }}</span></a
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center my-2">
                        <i class="icon-map-pin gamma-color fs-1em"></i>
                        <a href="javascript:;"
                          ><span class="mx-3 text-dark text-underline">{{
                            ((contactData.address.data || {}).description || []).join(' ')
                          }}</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <iframe class="bdrd-24px" height="295" :src="iframeSrc" frameborder="0"></iframe>
                </div>
              </div>
              <div class="col-6 pl-3">
                <div class="b-grey-black p-4 bdrd-24px">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="ContactUsForm">
                    <b-form ref="ContactFormElement" @submit.prevent="handleSubmit(submitContact)">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="fullN" class="required bold">Full Name</label>
                            <input
                              id="fullN"
                              type="text"
                              v-model="form.fullname"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate name="Full Name" v-model="form.fullname" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="emAddr" class="required bold">Email Address</label>
                            <input
                              id="emAddr"
                              type="email"
                              v-model="form.email_address"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@example.com"
                            />
                            <VValidate name="Email Address" v-model="form.email_address" :rules="{required: true, email: true, max: 96}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="phoneNmb" class="bold">Phone Number</label>
                            <b-form-input
                              id="phoneNmb"
                              type="tel"
                              v-model="form.phone_number"
                              class="form-control main-control"
                              placeholder="e.g. Phone Number"
                              :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                            ></b-form-input>
                             <VValidate name="Phone Number" v-model="form.phone_number" :rules="{min: 5, max: 16}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="subjectTxt" class="required bold">Subject</label>
                            <input
                              id="SubjectTxt"
                              type="text"
                              v-model="form.subject"
                              class="form-control main-control"
                              placeholder="e.g. Berlin Marathon Registration"
                            />
                            <VValidate name="Subject" v-model="form.subject" :rules="{required: true, min: 3, max: 128}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="messageTxt" class="required bold">Message</label>
                            <textarea
                              id="messageTxt"
                              type="text"
                              v-model="form.message"
                              class="form-control main-control text-area"
                              placeholder="Enter message..."
                            ></textarea>
                            <VValidate name="Message" v-model="form.message" :rules="{required: true, min: 10}" />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <template v-if="activeSubmit">
                            <button class="btn btn-slg btn-primary bold w-100" type="submit">Send Message</button>
                          </template>
                          <template v-else>
                            <button class="btn btn-slg btn-primary bold w-100" type="button" disabled>Send Message</button>
                          </template>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>

    <AppModal :withPageTitle="false" ref="modal-email-sent" :type="'sm'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/main-sent.svg`" alt="Confirmation" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Your message has been successfully sent!</p>
              <span class="regular fs-1em"
                >Thank you for contacting us! Your message has been received and we will do our best to respond
                promptly.</span
              >
              <button
                @click="$refs['modal-email-sent'].hide()"
                class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'
import AppModal from '@/components/AppModal'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'

import { VueRecaptcha } from 'vue-recaptcha'

export default {
  extends: Global,
  name: 'ContactUs',
  components: {
    carousel,
    Header,
    AppModal,
    VueRecaptcha,
  },
  data() {
    return {
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Contact Us',
          active: true,
        },
      ],
      form: {
        fullname: '',
        email_address: '',
        phone_number: '',
        subject: '',
        message: '',
      },
      activeSubmit: false,
    }
  },
  computed: {
    ...mapFields(['isDev', 'channels']),
    ...mapFields('contact', ['contactData']),
    heroBackground() {
      let src = '';

      if (this.isOnlyMobile()) {
        src = this.isWebPSupport
          ? this.uploader((this.contactData.hero || {}).image_small)
          : this.uploader((this.contactData.hero || {}).fallback_image_small)
      } else {
        src = this.isWebPSupport
          ? this.uploader((this.contactData.hero || {}).image)
          : this.uploader((this.contactData.hero || {}).fallback_image)
      }

      if (src === '') {
        return null
      }

      return `
        background: url('${src}') center center / cover;
      `
    },
    iframeSrc() {
      return this.contactData.address.data.url
    },
  },
  created() {
    this.getData()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('contact', ['getData', 'addContact']),
    ...mapActions(['getChannels']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    verifyCaptcha() {
      this.activeSubmit = true
    },

    submitContact() {
      if (!this.activeSubmit) {
        return null
      }

      this.activeSubmit = false

        this.addContact({
          form: this.form,
          callback: (success) => {
            if (!success) {
              this.activeSubmit = true
              return
            }

            this.$set(this.form, 'fullname', '')
            this.$set(this.form, 'email_address', '')
            this.$set(this.form, 'phone_number', '')
            this.$set(this.form, 'subject', '')
            this.$set(this.form, 'message', '')

            setTimeout(() => {
              this.$refs.ContactUsForm.reset()
              this.$refs['modal-email-sent'].show()
              this.$refs.Captcha.reset()
              this.activeSubmit = true
            }, 100)
          },
        })
    },
  },
}
</script>

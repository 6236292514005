import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Destination from '../views/Destination.vue'
import DestinationDetail from '../views/DestinationDetail.vue'
import FAQ from '../views/Faq.vue'
import BookingAndPrices from '../views/BookingPrices.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import ContactUs from '../views/ContactUs.vue'
import HowWeWork from '../views/HowWeWork.vue'
import WWUTourGuide from '../views/WWUTourGuide.vue'
import WWULPTourGuide from '../views/WWULPTourGuide.vue'
import WWULPHotels from '../views/WWULPHotels.vue'
import WWUHotels from '../views/WWUHotels.vue'
import WWUJoinTheTeam from '../views/WWUJoinTheTeam.vue'
import RunningEvents from '../views/RunningEvents.vue'
import RunningEventDetails from '../views/RunningEventDetails.vue'
import Checkout from '../views/Checkout.vue'
import PaymentDetails from '../views/PaymentDetails.vue'
import TransactionDetails from '../views/TransactionDetails.vue'
import Dashboard from '../views/Dashboard.vue'
import MyInformation from '../views/MyInformation.vue'
import MyReviews from '../views/MyReviews.vue'
import MyNetwork from '../views/MyNetwork.vue'
import MyPurchases from '../views/MyPurchases.vue'
import MyDocuments from '../views/MyDocuments.vue'
import MyWishlist from '../views/MyWishlist.vue'
import FormReview from '../views/ReviewForm.vue'
import MenuMobile from '../views/MenuMobile.vue'
import ConfirmWWUHotels from '../views/ConfirmWWUHotels.vue'
import ConfirmWWUTourGuide from '../views/ConfirmWWUTourGuide.vue'
import ConfirmWWUJoinTheTeam from '../views/ConfirmWWUJoinTheTeam.vue'
import ConfirmPayment from '../views/ConfirmPayment.vue'
import PaymentHistory from '../views/PaymentHistory.vue'
import Notification from '../views/Notification.vue'
import DetailNotification from '../views/DetailNotification.vue'
import PromoDetails from '../views/PromoDetails.vue'
import NotFound from '../views/NotFound.vue'
import SupplementaryQuestion from '../views/SupplementaryQuestion.vue'
import CallbackComponent from '../views/CallbackComponent.vue'

import store from "@/store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      isPublicFacing: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register/:pageSlug?',
    name: 'Register',
    component: Register,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('../views/Gallery.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/runner-says',
    name: 'RunnerSays',
    component: () => import('../views/RunnerSays.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/runner-says/country/:pageSlug',
    name: 'RunnerSaysByCountry',
    component: () => import('../views/RunnerSays.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/runner-says/:pageSlug',
    name: 'RunnerSaysDetail',
    component: () => import('../views/RunnerSaysDetail.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/blog/:pageSlug?',
    name: 'BlogDetail',
    component: () => import('../views/BlogDetail.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/blog/category/:categorySlug?',
    name: 'BlogCategory',
    component: () => import('../views/Blog.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/blog/tag/:tagSlug?',
    name: 'BlogTag',
    component: () => import('../views/Blog.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/blog/author/:pageSlug?',
    name: 'BlogAuthor',
    component: () => import('../views/BlogAuthor.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },

  {
    path: '/runner-says/tags/:tagSlug?',
    name: 'RunnerSaysTag',
    component: () => import('../views/RunnerSays.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/runner-says/countries/:pageSlug?',
    name: 'RunnerSaysCountries',
    component: () => import('../views/RunnerSays.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/blog/author/:pageSlug/category/:categorySlug?',
    name: 'AuthorBlogCategory',
    component: () => import('../views/BlogAuthor.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/blog/author/:pageSlug/tag/:tagSlug?',
    name: 'AuthorBlogTag',
    component: () => import('../views/BlogAuthor.vue'),
    meta: {
      isFooter: true,
    },
  },
  {
    path: '/destinations',
    name: 'Destination',
    component: Destination,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/destinations/:pageSlug',
    name: 'DestinationDetail',
    component: DestinationDetail,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/faq/:pageSlug?',
    name: 'FAQ',
    component: FAQ,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/booking-prices',
    name: 'BookingAndPrices',
    component: BookingAndPrices,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/how-we-work',
    name: 'HowWeWork',
    component: HowWeWork,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/work-with-us/tour-guide',
    name: 'WWULPTourGuide',
    component: WWULPTourGuide,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/work-with-us/tour-guide/register',
    name: 'WWUTourGuide',
    component: WWUTourGuide,
    meta: {
      isFooter: false,
      isBottomNavigation: false,
    },
  },
  {
    path: '/work-with-us/hotels',
    name: 'WWULPHotels',
    component: WWULPHotels,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/work-with-us/hotels/register',
    name: 'WWUHotels',
    component: WWUHotels,
    meta: {
      isFooter: false,
      isBottomNavigation: false,
    },
  },
  {
    path: '/join-the-team',
    name: 'WWULPJoinTheTeam',
    component: () => import('../views/WWULPJoinTheTeam.vue'),
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/work-with-us/join-the-team',
    name: 'WWUJoinTheTeam',
    component: WWUJoinTheTeam,
    meta: {
      isFooter: false,
      isBottomNavigation: false,
    },
  },
  {
    path: '/running-events',
    name: 'RunningEvents',
    component: RunningEvents,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      isPublicFacing: true,
    },
  },
  {
    path: '/running-events/:pageSlug',
    name: 'RunningEventDetails',
    component: RunningEventDetails,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/check-out/:pageSlug',
    name: 'Checkout',
    component: Checkout,
    meta: {
      isFooter: false,
      isBottomNavigation: false,
      redirectIfNotLogin: true,
      isSignInRef: true,
    },
  },
  {
    path: '/payment/:pageSlug',
    name: 'PaymentDetails',
    component: PaymentDetails,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
      redirectIfNotLogin: true,
      noWatchMeta: true,
    },
  },
  {
    path: '/transaction/:pageSlug',
    name: 'TransactionDetails',
    component: TransactionDetails,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
      redirectIfNotLogin: true,
      noWatchMeta: true,
    },
  },

  {
    path: '/user/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },

  {
    path: '/user/notification',
    name: 'Notification',
    component: Notification,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
      isNotificationPage: true,
    },
  },

  {
    path: '/updates/:pageSlug',
    name: 'DetailNotification',
    component: DetailNotification,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
    },
  },

  {
    path: '/promo/:pageSlug',
    name: 'PromoDetails',
    component: PromoDetails,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
    },
  },

  {
    path: '/user/my-information',
    name: 'MyInformation',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'My Information',
      backCta: false,
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-information/profile-information',
    name: 'ProfileInformation',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'Profile Information',
      backCta: true,
      backPage: 'MyInformation',
      backRef: 'Dashboard',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    name: 'AccountInformation',
    path: '/user/my-information/account-information',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'Account Information',
      backCta: true,
      backPage: 'MyInformation',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    name: 'ChangePassword',
    path: '/user/my-information/account-information/change-password',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'Change Password',
      backCta: true,
      backPage: 'AccountInformation',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-information/mailing-address',
    name: 'MailingAddress',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'Mailing Address',
      backCta: true,
      backPage: 'MyInformation',
      backRef: 'Dashboard',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-information/medical-information',
    name: 'MedicalInformation',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'Medical Information',
      backCta: true,
      backPage: 'MyInformation',
      backRef: 'Dashboard',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-information/emergency-contact',
    name: 'EmergencyContact',
    component: MyInformation,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'Emergency Contact',
      backCta: true,
      backPage: 'MyInformation',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-information/my-documents',
    name: 'MyDocuments',
    component: MyDocuments,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'My Documents',
      backCta: true,
      backPage: 'MyInformation',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-reviews',
    name: 'MyReviews',
    component: MyReviews,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-network/:pageSlug?',
    name: 'MyNetwork',
    component: MyNetwork,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      pageTitle: 'My Network',
      backRef: 'Dashboard',
      backPage: 'MyNetwork',
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: '/user/my-purchases',
    name: 'MyPurchases',
    component: MyPurchases,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
      noWatchMeta: true,
      isPrivatePage: true,
    },
  },
  {
    path: "/purchase/completing-data/:token",
    name: "SupplementaryQuestion",
    component: SupplementaryQuestion,
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: false,
    },
  },
  {
    path: '/user/my-purchases/:pageSlug',
    name: 'MyPurchasesFilter',
    component: MyPurchases,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
      noWatchMeta: true,
    },
  },
  {
    path: '/user/my-wishlist',
    name: 'MyWishlist',
    component: MyWishlist,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
    },
  },
  {
    path: '/user/my-reviews/write/:pageSlug',
    name: 'FormReview',
    component: FormReview,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
      isSignInRef: true,
    },
  },
  {
    path: '/user',
    name: 'MenuMobile',
    component: MenuMobile,
    meta: {
      isFooter: true,
      isBottomNavigation: false,
    },
  },
  {
    path: '/confirmation/hotel',
    name: 'ConfirmWWUHotels',
    component: ConfirmWWUHotels,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
    },
  },
  {
    path: '/confirmation/tour-guide',
    name: 'ConfirmWWUTourGuide',
    component: ConfirmWWUTourGuide,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
    },
  },
  {
    path: '/confirmation/join-the-team',
    name: 'ConfirmWWUJoinTheTeam',
    component: ConfirmWWUJoinTheTeam,
    meta: {
      isFooter: true,
      isBottomNavigation: true,
    },
  },
  {
    path: '/confirmation/payment/:pageSlug/succeed',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
    meta: {
      isFooter: false,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
    },
  },
  {
    path: '/confirmation/payment/:pageSlug',
    name: 'PaymentHistory',
    component: PaymentHistory,
    meta: {
      isFooter: false,
      isBottomNavigation: true,
      redirectIfNotLogin: true,
    },
  },
  {
    path: '/oauth/cb/athletescallback',
    name: 'CallbackComponent',
    component: CallbackComponent,
    meta: {
      isFooter: false,
      isHeader: false,
      isHalfBanner: false,
      isBottomNavigation: false,
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      isHeader: true,
      isHalfBanner: false,
      isFooter: true,
      mobileNavigation: true,
    },
  },
  {
    path: '*',
    redirect: {
      name: 'NotFound',
    },
    meta: {
      isHeader: true,
    },
  },
]

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  }
  // scroll to anchor by returning the selector
  if (to.params.savePosition) {
    return false
  }

  if (to.meta) {
    // specify offset of the element
    if (Object.prototype.hasOwnProperty.call(to.meta, 'scrollToTop')) {
      return to.meta.scrollToTop
    }

    return { y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["authenticated"]

  const baseUrlAthletes = store.state.baseUrlAthletes

  const baseUrl = store.state.baseUrl

  const clientId = store.state.athletesClientId
  
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: "Dashboard" })
  }

  if (to.meta.redirectIfNotLogin && !isLoggedIn) {
    if (to.meta.isSignInRef) {
      return window.location = `${baseUrlAthletes}/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${baseUrl}/oauth/cb/athletescallback`
    }

    return window.location = `${baseUrlAthletes}/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${baseUrl}/oauth/cb/athletescallback`
  }

  return next()
})

export default router

<template>
  <LayoutConfirmationPage>
    <template #content-layout>
      <div class="col-md-12 bb-1px-grey pb-4 d-flex flex-column align-items-center">
        <img :src="`${baseUrl}/assets/images/vector/collab-vect.svg`" alt="registration success" class="w-50" />
        <h5 class="bold ls-0 mt-3">{{ confirmContent.title }}</h5>
      </div>
      <div class="col-md-12 text-center py-4">
        <span class="regular"
          >{{ confirmContent.description }}</span
        >
      </div>
      <div class="col-md-12 text-center pb-4">
        <router-link :to="{ name: 'Home' }" class="btn btn-primary">{{ confirmContent.cta }}</router-link>
      </div>
    </template>
  </LayoutConfirmationPage>
</template>

<script>
// @ is an alias to /src
import LayoutConfirmationPage from '@/layouts/LayoutConfirmationPage'

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

export default {
  extends: Global,
  name: 'ConfirmWWUHotels',
  components: {
    LayoutConfirmationPage,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields('useWWUHotelStore', ['confirmContent']),
  },
  created() {
    this.getConfirmContent()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })

    this.$store.commit('SET_STATIC_SEO', {
      title: 'Thank you for submitting - Hotel Partner'
    })
  },
  methods: {
    ...mapActions('useWWUHotelStore', ['getConfirmContent'])
  },
}
</script>

<style scoped></style>

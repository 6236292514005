import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    isWebPSupport: false,
    scrollPositionPackage: null,
    isBtnGetSlotNow: true,
    lightboxIndex: null,
    typeOfTransaction: 'partial-payment',
    breadcrumbItems: [
      {
        text: 'Home',
        to: { name: 'Home' },
      },
      {
        text: 'Running Events',
        to: { name: 'RunningEvents' },
      },
      {
        text: 'RDG London Marathon 2023',
        active: true,
      },
    ],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },

  actions: {},
}

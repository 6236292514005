import { getField, updateField } from 'vuex-map-fields'
import { http, httpCurl } from '../../config/http'
import Gen from '@/libs/Gen'
import router from "../../router/index"

export default {
  namespaced: true,

  state: {
    contactData: {
      address: {
        data: {
          url: '',
        },
      },
      contact: {
        data: {
          title: '',
        },
      },
    },
    inputContact: {
      input_full_name: '',
      input_email: '',
      input_phone: '',
      input_subject: '',
      input_message: '',
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_CONTACT_DATA(state, data) {
      state.contactData = data
    },
    CLEAR_INPUT_CONTACT(state) {
      state.inputContact = {
        input_full_name: '',
        input_email: '',
        input_phone: '',
        input_subject: '',
        input_message: '',
      }
    },
  },

  actions: {
    async getData({ rootState, commit }) {
      try {
        rootState.loading.screen = true

        const res = await http.get('/public-pages/contact', {
          headers: Gen.apiHeader(),
        })

        commit('SET_CONTACT_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        if ((err.response || {}).status === 403) {
          router.push({ name: 'Contact' })
        }
      } finally {
        rootState.loading.screen = false
      }
    },

    async addContact({ state, rootState, commit }, data) {
      const { form, callback = false } = data

      const inputContact = {
        input_full_name: form.fullname,
        input_email: form.email_address,
        input_phone: form.phone_number,
        input_subject: form.subject,
        input_message: form.message,
      }

      try {
        rootState.loading.screen = true

        let myJSONStr = {
          username: 'RUNDEGLOBE BOT',
          icon_url: 'https://rundeglobe.com/assets/image/favicon/apple-icon-120x120.png',
          channel: '#apps-email',
          attachments: [
            {
              fallback: 'We have new message from our runner',
              title: `${inputContact.input_subject}`,
              color: '#9C1A22',
              pretext: `We have new message!`,
              author_name: 'Support RDG',
              author_link: 'https://rundeglobe.com',
              author_icon: 'https://rundeglobe.com/assets/image/favicon/apple-icon-120x120.png',
              fields: [
                {
                  title: 'Sender',
                  value: `${inputContact.input_email}\n${inputContact.input_full_name}\n`,
                  short: true,
                },
              ],
              mrkdwn_in: ['text', 'fields'],
              text: `${inputContact.input_message}`,
              thumb_url: 'http://example.com/thumbnail.jpg',
            },
          ],
        }

        myJSONStr = JSON.stringify(myJSONStr)
        const url = 'https://hooks.slack.com/services/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'

        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        }

        await httpCurl
          .post(url, myJSONStr, {
            timeout: 10000,
            transformRequest(data, headers) {
              delete headers.common['Content-Type']
              return data
            },
            headers,
          })
          .then((res) => res)
          .catch((err) => {
            console.log(err)
          })

        await Gen.apiRest(
          '/contact',
          {
            data: { ...inputContact },
          },
          'POST'
        )

        if (callback) {
          callback(true)
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbHowWeWork"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!isMobile()">
        <section class="mt-0 pt-5 overflow-visible">
          <div class="container">
            <div class="row align-items-end bb-1px-grey pb-3">
              <div class="col-md-10">
                <div class="label-custom green d-inline">
                  <span class="black">{{ ((howWeWorkData || {}).content || {}).sub_title }}</span>
                </div>
                <h2 class="black mt-2 fs-275em">{{ ((howWeWorkData || {}).content || {}).title }}</h2>
                <p class="medium grey-color w-75">
                  {{ ((howWeWorkData || {}).content || {}).description }}
                </p>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-md-8">
                <div
                  class="pb-5"
                  v-for="(v, k) in (howWeWorkData || {}).steps_data"
                  :key="k"
                  :class="[
                    k != '0' ? 'pt-5' : '',
                    k + 1 != ((howWeWorkData || {}).steps_data || {}).length ? 'bb-1px-grey' : '',
                  ]"
                >
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#{{ k + 1 }}</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">
                        {{ v.title }}
                      </h2>
                      <span class="regular">{{ v.description }}</span>
                      <div class="mt-3 d-flex flex-wrap" v-if="(v.extras_check || {}).length != 0">
                        <div class="extras-check mb-2 mx-1 w-475" v-for="(v1, k1) in v.extras_check" :key="k1">
                          <div>
                            <i class="fa fa-check text-white fs-085em"></i>
                          </div>
                          <div>
                            <p class="mb-0 bold text-dark">{{ v1.title }}</p>
                            <span class="regular">{{ v1.description }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>

      <template v-else>
        <div class="mobile-version-view py-5">
          <section class="mt-0 pt-5 bb-1px-grey pb-4 overflow-visible">
            <div class="container">
              <div class="row align-items-end">
                <div class="col-md-8">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <div class="label-custom green d-inline">
                        <span class="black">{{ ((howWeWorkData || {}).content || {}).sub_title }}</span>
                      </div>
                      <h2 class="black mt-2 fs-175em">{{ ((howWeWorkData || {}).content || {}).title }}</h2>
                      <span class="medium grey-color">{{ ((howWeWorkData || {}).content || {}).description }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-0 pt-0 overflow-visible">
            <div>
              <div class="tabs link large">
                <div class="container">
                  <div>
                    <div
                      class="pb-5 pt-5"
                      v-for="(v, k) in (howWeWorkData || {}).steps_data"
                      :key="k"
                      :class="k + 1 != ((howWeWorkData || {}).steps_data || {}).length ? 'bb-1px-grey' : ''"
                    >
                      <div class="row align-items-center">
                        <div class="col-md-12">
                          <span class="extrabold">#{{ k + 1 }}</span>
                          <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">
                            {{ v.title }}
                          </h2>
                          <span class="regular">{{ v.description }}</span>
                          <div class="mt-3 d-flex flex-column" v-if="(v.extras_check || {}).length != 0">
                            <div class="extras-check mb-2 mx-1 my-2" v-for="(v1, k1) in v.extras_check" :key="k1">
                              <div>
                                <i class="fa fa-check text-white fs-085em"></i>
                              </div>
                              <div>
                                <p class="mb-0 bold text-dark">{{ v1.title }}</p>
                                <span class="regular">{{ v1.description }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a
          :href="`https://wa.me/${howWeWorkData.whatsapp_contact}`"
          target="_blank"
          class="fab fa-whatsapp text-white p-3"
        >
        </a>
      </div>
      <div class="btn-app">
        <div class="fa fa-envelope-square" @click="$router.push({ name: 'ContactUs' })"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Global from '../libs/Global.vue'
import Header from '../components/Header.vue'

import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  extends: Global,
  name: 'HowWeWork',
  components: {
    Header,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields('useHowWeWorkStore', ['howWeWorkData', 'breadcrumbHowWeWork']),
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
    this.getHowWeWorkData()
  },
  methods: {
    ...mapActions('useHowWeWorkStore', ['getHowWeWorkData']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<template>
  <div class="my-3" :class="col">
    <a :href="$router.resolve({ name: 'DestinationDetail', params: { pageSlug: slug } }).href"
      ><div class="card-simple-bg px-2 text-center bdrd-24px pointer" :class="variant">
        <picture>
          <source :srcset="uploader(image)" type="image/webp" />
          <img :src="uploader(fallbackImage)" :alt="country" />
        </picture>
        <h2 class="black text-white text-uppercase">{{ country }}</h2>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'CountryItemAtom',
  props: {
    image: {
      type: String,
    },
    fallbackImage: {
      type: String,
    },
    slug: {
      type: String,
    },
    country: {
      type: String,
    },
    col: {
      type: String,
    },
    uploader: {
      type: Function,
    },
    variant: {
      type: String,
      default: 'large',
    },
  },
}
</script>

<style></style>

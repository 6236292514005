import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'
import router from '../../router/index'

export default {
  namespaced: true,
  state: {
    featured: {},
    content: {
      hero: {},
      featured: {},
      search: {},
    },
    data: [],
    hero: {},
    author: {},
    blogDetail: {
      category_slug: '',
      category: '',
      title: '',
      author_image: '',
      author_fallback_image: '',
      author_name: '',
      author_slug: '',
      published_date: '',
      excerpt: '',
      description: '',
      image: '',
      fallback_image: '',
      alt_image: '',
      tags: '',
      other_articles: '',
    },
    blogDetailContent: {
      social_media_links: {
        facebook: '',
        twitter: '',
        instagram: '',
      },
    },
    categories: [],
    tags: [],
    dataLength: 0,
    editorPicks: [],
    selectedCategory: '',
    selectedTag: '',
    otherArticles: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_BLOG_CONTENT(state, data) {
      state.featured = data.featured
      state.editorPicks = data.editorPicks
      state.data = data.data
      state.dataLength = data.dataLength
      state.content = data.content
      state.categories = data.categories
      state.tags = data.tags
      state.selectedCategory = data.selectedCategory
      state.selectedTag = data.selectedTag
    },
    SET_BLOG_DETAIL_CONTENT(state, data) {
      state.blogDetail = data.data
      state.blogDetailContent = data.content
    },
    SET_BLOG_AUTHOR_CONTENT(state, data) {
      state.data = data.data
      state.dataLength = data.dataLength
      state.content = data.content
      state.categories = data.categories
      state.tags = data.tags
      state.selectedCategory = data.selectedCategory
      state.selectedTag = data.selectedTag
      state.author = data.author
      state.hero = data.hero
    },
  },

  actions: {
    async getBlogContent({ commit, rootState }, data) {
      const { page, search, category, tag, author, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const resp = await http.get('/blogs', {
          headers: Gen.apiHeader(),
          params: {
            page,
            search,
            category,
            tag,
            author,
          },
        })

        commit('SET_BLOG_CONTENT', resp.data)
        rootState.staticseo = resp.data.staticseo
        if (callback) {
          callback(resp.data)
        }
      } catch (error) {
        console.log(error)

        if (callback) {
          callback(error)
        }
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }
      }
    },

    async getBlogDetailContent({ commit, rootState }, data) {
      const { slug, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const resp = await http.get(`/blogs/${slug}`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_BLOG_DETAIL_CONTENT', resp.data)
        rootState.staticseo = resp.data.staticseo
        if (callback) {
          callback(resp.data)
        }
      } catch (error) {
        if (error.response.status === 404) {
          return router.push({name: 'NotFound'})
        }

        if (callback) {
          callback(error)
        }
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }
      }
    },

    async getBlogAuthorContent({ commit, rootState }, data) {
      const { page, search, category, tag, author, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const resp = await http.get(`/blogs/author/${author}`, {
          headers: Gen.apiHeader(),
          params: {
            page,
            search,
            category,
            tag,
          },
        })

        commit('SET_BLOG_AUTHOR_CONTENT', resp.data)
        rootState.staticseo = resp.data.staticseo
        if (callback) {
          callback(resp.data)
        }
      } catch (error) {
        console.log(error)

        if (callback) {
          callback(error)
        }
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }
      }
    },
  },
}

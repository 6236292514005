<template>
  <LayoutPrivatePage>
    <template #page-title>
      <div class="page-title d-flex flex-column align-items-center">
        <div class="img-profile-mobile mb-4">
          <img src="https://rundeglobe.com/api/public/uploader/ourteam/dickie---r-1647689363.webp" alt="" />
          <div class="edit-profile">
            <i class="fa fa-pencil-alt text-white"></i>
          </div>
        </div>
        <span class="semibold text-white grey-color" :class="!isMobile() ? 'w-50' : ''">UR-220322-001</span>
        <h2 class="black text-medium text-white mt-3 fs-125em mb-1" :class="!isMobile() ? 'w-75' : ''">
          Abraham Damar Grahita
        </h2>
        <span class="semibold text-white" :class="!isMobile() ? 'w-50' : ''">cryan@rundeglobe.com</span>
      </div>
    </template>

    <template #content-mobile>
      <div class="menu-mobile bb-1px-grey pb-2">
        <router-link :to="{ name: 'Dashboard' }" class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img :src="`${baseUrl}/assets/images/static-icon/dashboard.svg`" alt="" class="icon" />
            </div>
            <div class="mx-3">
              <span class="text-dark bold">Dashboard</span>
            </div>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em text-dark"></i>
          </div>
        </router-link>
      </div>
      <div class="menu-mobile bb-1px-grey pb-2 pt-4">
        <router-link :to="{ name: 'RegisteredRaces' }" class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img :src="`${baseUrl}/assets/images/static-icon/registered.svg`" alt="" class="icon" />
            </div>
            <div class="mx-3">
              <span class="text-dark bold">Orders</span>
            </div>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em text-dark"></i>
          </div>
        </router-link>
      </div>
      <div class="menu-mobile bb-1px-grey pb-2 pt-4">
        <router-link :to="{ name: 'MyReviews' }" class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img :src="`${baseUrl}/assets/images/static-icon/review.svg`" alt="" class="icon" />
            </div>
            <div class="mx-3">
              <span class="text-dark bold">My Review</span>
            </div>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em text-dark"></i>
          </div>
        </router-link>
      </div>
      <div class="menu-mobile bb-1px-grey pb-2 pt-4">
        <router-link :to="{ name: 'MyReviews' }" class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img :src="`${baseUrl}/assets/images/static-icon/review.svg`" alt="" class="icon" />
            </div>
            <div class="mx-3">
              <span class="text-dark bold">Wishlist</span>
            </div>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em text-dark"></i>
          </div>
        </router-link>
      </div>
      <div class="menu-mobile bb-1px-grey pb-2 pt-4">
        <router-link :to="{ name: 'MyInformation' }" class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img :src="`${baseUrl}/assets/images/static-icon/profile_svg.svg`" alt="" class="icon" />
            </div>
            <div class="mx-3">
              <span class="text-dark bold">My Information</span>
            </div>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em text-dark"></i>
          </div>
        </router-link>
      </div>
      <div class="menu-mobile bb-1px-grey pb-2 pt-4">
        <a @click="$refs['modal-logout'].show()" class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img :src="`${baseUrl}/assets/images/static-icon/logout.svg`" alt="" class="icon" />
            </div>
            <div class="mx-3">
              <span class="text-dark bold">Log Out</span>
            </div>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em text-dark"></i>
          </div>
        </a>
      </div>
    </template>

    <template #custom-layout>
      <AppModal :withPageTitle="false" ref="modal-welcome" :type="'sm'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/welcome.svg`" alt="" class="w-100" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">
                  Thank you for signing in for the first time.
                </p>
                <span class="regular fs-1em"
                  >We're excited to have you as a part of our community. Your account is now activated and ready for you
                  to explore.</span
                >
                <button
                  @click="closeModalWelcome"
                  class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="false" ref="modal-logout" :type="'sm'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/logout-1.svg`" alt="" class="w-25" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Confirm log out?</p>
                <span class="regular fs-1em">Are you sure you want to end this session?</span>
                <button
                  @click="$router.push({ name: 'Login' })"
                  class="btn btn-primary semibold d-flex text-center justify-content-center mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Yes, Log Out
                </button>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
    </template>
  </LayoutPrivatePage>
</template>

<script>
// @ is an alias to /src
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import AppModal from '@/components/AppModal'

import { mapFields } from 'vuex-map-fields'
import Global from '../libs/Global.vue'

export default {
  extends: Global,
  name: 'MenuMobile',
  components: {
    LayoutPrivatePage,
    AppModal,
  },
  data() {
    return {
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Running Events',
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapFields('home', ['homeContent']),
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },

  watch: {
    '$route.query.redirectFrom': {
      handler() {
        this.showModalWelcome()
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    showModalWelcome() {
      if (this.$route.query.redirectFrom == 'register') {
        const that = this
        window.setTimeout(() => {
          that.$refs['modal-welcome'].show()
        }, 300)
      }
    },

    closeModalWelcome() {
      this.$router.push({ name: 'MenuMobile' })
      this.$refs['modal-welcome'].hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.img-profile-mobile {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .edit-profile {
    background: #2f2e41;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff;
    border-radius: 50%;
  }
}

.menu-mobile {
  .icon {
    width: 21px;
    height: 21px;
  }
}
</style>

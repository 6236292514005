<template>
  <AppModal :vModalId="vref" :withPageTitle="true" :ref="vref" :type="'md'" @close="onHandleClose">
    <template #content-modal>
      <div class="container-fluid x-modal-container">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-center">
              <picture class="d-flex justify-content-center">
                <source
                  v-if="source.src && source.src.length > 0"
                  :srcset="source.src || `${baseUrl}/assets/images/static-icon/${type}.webp`"
                  :type="source.type || 'webp'"
                />
                <img
                  :src="source.fallbackSrc || `${baseUrl}/assets/images/static-icon/${type}.png`"
                  alt="Alert Icon"
                  class="align-self-start"
                  :class="iconSize"
                />
              </picture>
            </div>
            <h5 class="bold text-center mt-4 mb-3">{{ title }}</h5>
            <p class="fs-1em regular text-center">
              {{ description }}
            </p>
          </div>
          <div class="col-md-12 d-flex justify-content-center">
            <button
              v-if="(button.secondary || {}).text !== undefined"
              type="button"
              @click.prevent="handleClick((button.secondary || {}).handle)"
              class="btn btn-outline-main ml-0"
            >
              {{ (button.secondary || {}).text }}
            </button>
            <button
              v-if="(button.main || {}).text !== undefined"
              type="button"
              @click.prevent="handleClick((button.main || {}).handle)"
              class="btn btn-primary"
              :class="isOnlyMobile() && (button.secondary || {}).text === undefined ? 'w-100' : ''"
            >
              {{ (button.main || {}).text }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </AppModal>
</template>

<script>
import Global from '@/libs/Global.vue'
import AppModal from '@/components/AppModal'
import { mapFields } from 'vuex-map-fields'

export default {
  extends: Global,
  name: 'AppAlert',
  components: {
    AppModal,
  },
  computed: {
    ...mapFields(['baseUrl']),
  },
  methods: {
    onHandleClose() {
      this.$emit('close', this.vref)
    },
    show() {
      this.$refs[this.vref].show()
    },
    hide() {
      this.$refs[this.vref].hide()
    },
    handleClick(callback) {
      if (callback) {
        return callback(this.vref)
      }

      this.hide()
    },
  },
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
    iconSize: {
      type: String,
      default: 'w-25',
    },
    fallbackSrc: '',
    type: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    vref: {
      type: String,
      default: '',
    },
  },
}
</script>

<style></style>

<template>
  <LayoutConfirmationPage>
    <template #content-layout>
      <div class="col-md-12 mb-5">
        <div>
          <span class="sub-title-section fs-125em">
            <div class="line-sub-title"></div>
            Current Payment
          </span>
          <div :class="!isMobile() ? 'b-grey-black px-3 py-3 bdrd-12px mt-3' : 'bb-1px-grey py-3'">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex" :class="!isMobile() ? 'justify-content-between' : 'flex-column'">
                  <p class="mb-0">Order ID</p>
                  <p class="mb-0 bold text-dark">{{ paymentData.order_id }}</p>
                </div>
              </div>
            </div>
          </div>
          <div :class="!isMobile() ? 'py-3 bb-1px-grey' : 'bb-1px-grey pb-3'">
            <div>
              <div 
                v-for="(v, k) in paymentData.installments" 
                :key="k" 
                class="content-2-column between" 
                :class="!isMobile() ? '' : 'flex-column'"
              >
                <div class="d-flex align-items-center">
                  <span class="text-dark regular">{{ paymentData.transaction_type == 'F' ? 'One Time' : ordinal(v.num_of_payment) }} Payment</span>
                  <div v-if="v.active == 'Y'" class="label-custom d-inline mx-2" :class="paymentData.active_payment_bg">
                    <span class="black fs-085em align-self-start">{{ paymentData.active_payment_text }}</span>
                  </div>
                  <div v-else class="label-custom d-inline mx-2" :class="paymentData.paid_payment_bg">
                    <span class="black fs-085em align-self-start">{{ paymentData.paid_payment_text }}</span>
                  </div>
                </div>
                <span class="text-dark bold">IDR {{ numFormatter(v.amount) }}</span>
              </div>
            </div>
          </div>
          <span class="sub-title-section fs-125em mt-4">
            <div class="line-sub-title"></div>
            Transaction Information
          </span>
          <div :class="!isMobile() ? 'py-3' : 'pb-3'">
            <h5 class="semibold ls-0">{{ paymentData.sub_title_2 }}</h5>
            <div class="mt-3" :class="!isMobile() ? '' : 'pb-3'">
              <div class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                <span class="text-dark regular">1 Slot Package</span>
                <span class="text-dark bold">IDR {{ numFormatter(paymentData.price) }}</span>
              </div>

              <template v-if="paymentData.transaction_type == 'F'">
                <template v-if="(parseInt(paymentData.full_payment_price) - parseInt(paymentData.price)) > 0">
                  <div class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                    <span class="text-dark regular">Discount</span>
                    <span class="text-dark bold">-IDR {{ numFormatter((parseInt(paymentData.full_payment_price) - parseInt(paymentData.price))) }}</span>
                  </div>
                </template>
              </template>
              <template v-if="paymentData.transaction_type == 'B'">
                <template v-if="(parseInt(paymentData.booking_payment_price) - parseInt(paymentData.price)) > 0">
                  <div class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                    <span class="text-dark regular">Discount</span>
                    <span class="text-dark bold">-IDR {{ numFormatter((parseInt(paymentData.booking_payment_price) - parseInt(paymentData.price))) }}</span>
                  </div>
                </template>
              </template>

              <div class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                <span class="text-dark regular bold">Final Price</span>
                <span class="text-dark bold" v-if="(paymentData || {}).transaction_type == 'F'">IDR {{ numFormatter((paymentData || {}).full_payment_price) }}</span>
                <span class="text-dark bold" v-if="(paymentData || {}).transaction_type == 'B'">IDR {{ numFormatter((paymentData || {}).booking_payment_price) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center mb-4">
        <router-link
          :to="{ name: 'TransactionDetails', params: {pageSlug: paymentData.transaction_id} }"
          class="btn btn-outline-main ml-0"
          >Go Back</router-link
        >
      </div>
    </template>
  </LayoutConfirmationPage>
</template>

<script>
// @ is an alias to /src
import LayoutConfirmationPage from '@/layouts/LayoutConfirmationPage'

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

export default {
  extends: Global,
  name: 'ConfirmPayment',
  components: {
    LayoutConfirmationPage,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields('usePaymentDetailsStore', ['paymentData']),
    discount() {
      return this.paymentData.price - this.paymentData.full_payment_price
    },
  },
  created() {
    this.getPaymentDetails({
      path: ((this.$route || {}).params || {}).pageSlug,
    })

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })

    this.$store.commit('SET_STATIC_SEO', {
      title: 'Payment Success'
    })
  },
  methods: {
    ...mapActions('usePaymentDetailsStore', ['getPaymentDetails']),
    ordinal(number) {
      let ordinalNumber = '';

      switch(number) {
        case 1:
          ordinalNumber = 'First';
          break;

        case 2:
          ordinalNumber = 'Second';
          break;

        case 3:
          ordinalNumber = 'Third';
          break;

        case 4:
          ordinalNumber = 'Fourth';
          break;

        case 5:
          ordinalNumber = 'Fifth';
          break;

        case 6:
          ordinalNumber = 'Sixth';
          break;

        case 7:
          ordinalNumber = 'Seventh';
          break;

        case 8:
          ordinalNumber = 'Eighth';
          break;

        case 9:
          ordinalNumber = 'Ninth';
          break;

        case 10:
          ordinalNumber = 'Tenth';
          break;

        default:
          ordinalNumber = number;
          break;
      }

      return ordinalNumber;
    },
  },
}
</script>

<style scoped></style>

<template>
  <div class="home">
    <div class="body-inner">
      <template v-if="!isMobile()">
        <div>
          <!-- Header -->
          <Header :isPrivatePage="true" :isOpenMenuProps="true" />
        </div>

        <section class="overflow-visible">
          <div class="container">
            <UserIdentity 
              :user-name="(user || {}).registrant_full_name"
              :user-first-name="(user || {}).registrant_first_name"
              :user-last-name="(user || {}).registrant_last_name"
              :user-email="(user || {}).registrant_email_address"
              :user-phone="(user || {}).registrant_phone"
              :base-url="baseUrl"
            />

            <div class="row my-5">
              <div class="col-md-12">
                <div class="tab-left d-flex justify-content-between sticky-80px bg-white-custom bb-1px-grey">
                  <div>
                    <ul class="component-sticky-main runningevent d-flex">
                      <router-link :to="{ name: 'Dashboard', params: { savePosition: true } }" class="mr-3">
                        <li>
                          <a>
                            <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                              <span class="extrabold">Dashboard</span>
                            </div>
                          </a>
                        </li>
                      </router-link>
                      <router-link :to="{ name: 'MyNetwork', params: { savePosition: true } }" class="mr-3">
                        <li>
                          <a>
                            <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                              <span class="extrabold">My Network</span>
                            </div>
                          </a>
                        </li>
                      </router-link>
                      <router-link :to="{ name: 'MyPurchases', params: { savePosition: true } }" class="mr-3">
                        <li>
                          <a>
                            <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                              <span class="extrabold">My Purchases</span>
                            </div>
                          </a>
                        </li>
                      </router-link>
                      <router-link
                        :to="{ name: 'MyReviews' }"
                        class="mr-3"
                        :class="childReview.includes($route.name) ? 'router-link-exact-active router-link-active' : ''"
                      >
                        <li>
                          <a>
                            <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                              <span class="extrabold">My Reviews</span>
                            </div>
                          </a>
                        </li>
                      </router-link>
                    </ul>
                  </div>
                  <div>
                    <ul class="component-sticky-main runningevent d-flex">
                      <a @click="$refs['modal-logout'].show()" class="mr-3">
                        <li>
                          <a>
                            <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                              <span class="extrabold">Log Out</span>
                            </div>
                          </a>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <slot name="main-content"></slot>
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <Header :isPrivatePage="true" :isOpenMenuProps="false" />
        <section id="page-title">
          <div class="container">
            <slot name="page-title"></slot>
          </div>
        </section>

        <div class="mobile-version-view pt-4 pb-5">
          <div class="container px-3">
            <div class="row">
              <div class="col-md-12">
                <slot name="content-mobile"></slot>
              </div>
            </div>
          </div>
          <slot name="content-mobile-nocontainer"></slot>
        </div>
      </template>
    </div>

    <slot name="custom-layout"></slot>

    <template v-if="!isMobile()">
      <div
        class="menu-toggle"
        :class="$route.meta.isBottomNavigation ? '' : 'without-bottom-navigation'"
        @click="openMenuToggle"
      >
        <span class="fa fa-comments"></span>
      </div>
      <div class="menu-round">
        <div class="btn-app">
          <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
        </div>
        <div class="btn-app">
          <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
            <i class="fa fa-envelope-square"></i>
          </a>
        </div>
      </div>
    </template>

    <AppModal :withPageTitle="false" ref="modal-logout" :type="'md'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/logout-1.svg`" alt="Confirmation" class="w-35-cust" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Confirm log out?</p>
              <span class="regular fs-1em">Are you sure you want to end this session?</span>
              <div class="d-flex">
                <button
                  @click="$refs['modal-logout'].hide()"
                  class="btn btn-outline-main ml-0 semibold d-flex text-center justify-content-center mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  No, Stay Here
                </button>
                <button
                  @click="doLogout"
                  class="btn btn-primary semibold d-flex text-center justify-content-center mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Yes, Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
import Header from '@/components/Header'
import UserIdentity from '@/components/UserIdentity'
import Global from '@/libs/Global'
import AppModal from '@/components/AppModal'
import AppAlert from '@/components/AppAlert'
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapActions } from 'vuex'

export default {
  extends: Global,
  name: 'LayoutPrivatePage',
  components: {
    Header,
    UserIdentity,
    AppAlert,
    AppModal,
  },
  data() {
    return {
      childReview: ['FormReview'],
      childAccountSettings: ['MyDocuments'],
    }
  },
  computed: {
    ...mapFields(['user', 'baseUrl', 'channels']),
    ...mapGetters(['authenticated']),
  },
  methods: {
    ...mapActions(['getChannels']),
    doLogout(){
      this.$store.commit('logoutUserToken')
      if(!this.authenticated) {
        this.$router.push({ name: 'Home' })
      }
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style lang="scss" scoped>
#page-title {
  background: linear-gradient(to bottom right, #250e23, #81317b);
}
</style>

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    howWeWorkData: [],
    breadcrumbHowWeWork: [
      {
        text: 'Home',
        to: { name: 'Home' },
      },
      {
        text: 'How We Work',
        active: true,
      },
    ],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_HOW_WE_WORK_DATA(state, data) {
      state.howWeWorkData = data
    },
  },

  actions: {
    async getHowWeWorkData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/how-we-work`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_HOW_WE_WORK_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

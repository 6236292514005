<template>
  <div class="home">
    <div class="body-inner">
      <div class="custom-content-bottom">
        <Header />

        <FullBannerAtom 
          :data="(homeContent.hero || {}).data"
          :banner-type="(homeContent.hero || {}).banner_type"
          :uploader="uploader"
          :is-only-mobile="isOnlyMobile()" 
          :is-mobile="isMobile()" 
        />

        <HighlightsDesktopAtom 
          :isMobile="isMobile()" 
          :isTablet="isTablet()" 
          :baseUrl="baseUrl" 
          :data="(homeContent.hero || {}).highlights"
          :uploader="uploader"
        />
      </div>

      <HighlightsMobileAtom 
        :isMobile="isMobile()" 
        :baseUrl="baseUrl" 
        :data="(homeContent.hero || {}).highlights"
        :uploader="uploader"
      />

      <RunningEventsAtom
        v-if="((homeContent.running_events || {}).content || {}).show_section == 'Y'"
        :isMobile="isMobile()"
        :isOnlyMobile="isOnlyMobile()"
        :baseUrl="baseUrl"
        :isOpenMenu="isOpenMenu"
        :content="(homeContent.running_events || {}).content"
        :data="(homeContent.running_events || {}).data"
        :stock="(homeContent.running_events || {}).remaining_stock"
        :uploader="uploader"
        :is-webp-support="isWebPSupport"
        :format-date="formatDateWithoutWeekday"
      />

      <RunnerSaysAtom
        v-if="((homeContent.runner_says || {}).content || {}).show_section == 'Y'"
        :isMobile="isMobile()"
        :isOnlyMobile="isOnlyMobile()"
        :isTablet="isTablet()"
        :baseUrl="baseUrl"
        :isOpenMenu="isOpenMenu"
        :content="(homeContent.runner_says || {}).content"
        :data="(homeContent.runner_says || {}).data"
        :uploader="uploader"
        :is-webp-support="isWebPSupport"
        :format-date="formatDateWithoutWeekday"
      />

      <HowWeWorkAtom 
        v-if="((homeContent.how_we_work || {}).content || {}).show_section == 'Y'"
        :isMobile="isMobile()" 
        :data="(homeContent.how_we_work || {}).data"
        :content="(homeContent.how_we_work || {}).content"
        :uploader="uploader"
      />

      <BlogAtom 
        v-if="((homeContent.blog || {}).content || {}).show_section == 'Y'"
        :isMobile="isMobile()" 
        :isOnlyMobile="isOnlyMobile()" 
        :baseUrl="baseUrl" 
        :content="(homeContent.blog || {}).content"
        :data="(homeContent.blog || {}).data"
        :uploader="uploader"
        :formatDate="formatDateWithoutWeekday"
      />

      <AboutAtom
        v-if="((homeContent.about || {}).content || {}).show_section == 'Y'"
        :isMobile="isMobile()"
        :isOnlyMobile="isOnlyMobile()"
        :isLargeDevice="isLargeDevice"
        :baseUrl="baseUrl"
        :content="(homeContent.about || {}).content"
        :uploader="uploader"
      />

      <GalleryAtom 
        v-if="((homeContent.gallery || {}).content || {}).show_section == 'Y'"
        :isMobile="isMobile()" 
        :truncateText="truncateText" 
        :content="(homeContent.gallery || {}).content"
        :data="(homeContent.gallery || {}).data"
        :uploader="uploader"
        :is-webp-support="isWebPSupport"
      />

      <section v-if="((homeContent.partners || {}).content || {}).show_section == 'Y'" class="bg-grey-blue py-5 relative-index-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 py-4">
              <div class="relative mt-4">
                <carousel v-if="((homeContent.partners || {}).data || []).length > 0" :items="3" :nav="false" :margin="20">
                  <template v-for="(v, k) in (homeContent.partners || {}).data" class="">
                    <div
                      :key="'partner-'+k"
                      class="img-partner bg-white-custom px-3 py-3 bdrd-24px partner d-flex justify-content-center align-items-center"
                    >
                      <a :href="v.url" target="_blank">
                        <picture>
                          <source :srcset="uploader(v.image)" type="image/webp">
                          <img
                            :src="uploader(v.fallback_image)"
                            alt="Partner Image"
                            width="168"
                            height="112"
                          />
                        </picture>
                      </a>
                    </div>
                  </template>
                  <template slot="next" v-if="((homeContent.partners || {}).data || []).length > 3">
                    <div class="arrow-carousel-component with-indicator private-page right pointer">
                      <i class="icon-arrow-right"></i>
                    </div>
                  </template>
                  <template slot="prev" v-if="((homeContent.partners || {}).data || []).length > 3">
                    <div class="arrow-carousel-component with-indicator private-page left pointer">
                      <i class="icon-arrow-left"></i>
                    </div>
                  </template>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <RunningEventsCTAAtom 
        v-if="((homeContent.ctaevent || {}).content || {}).show_section == 'Y'"
        :content="(homeContent.ctaevent || {}).content"
      />
    </div>

    <FloatingActionButtonAtom :openMenuToggle="openMenuToggle" :no-channel="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

import carousel from 'vue-owl-carousel'
import Global from '../libs/Global.vue'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import Header from '../components/Header.vue'
import FullBannerAtom from './atoms/home/FullBannerAtom.vue'
import HighlightsDesktopAtom from './atoms/home/HighlightsDesktopAtom.vue'
import HighlightsMobileAtom from './atoms/home/HighlightsMobileAtom.vue'
import RunningEventsAtom from './atoms/home/RunningEventsAtom.vue'
import RunnerSaysAtom from './atoms/home/RunnerSaysAtom.vue'
import HowWeWorkAtom from './atoms/home/HowWeWorkAtom.vue'
import BlogAtom from './atoms/home/BlogAtom.vue'
import AboutAtom from './atoms/home/AboutAtom.vue'
import GalleryAtom from './atoms/home/GalleryAtom.vue'
import PartnersAtom from './atoms/home/PartnersAtom.vue'
import RunningEventsCTAAtom from './atoms/home/RunningEventsCtaAtom.vue'
import FloatingActionButtonAtom from './atoms/home/FloatingActionButtonAtom.vue'

export default {
  extends: Global,
  name: 'Home',
  components: {
    carousel,
    Header,
    FullBannerAtom,
    HighlightsDesktopAtom,
    HighlightsMobileAtom,
    RunningEventsAtom,
    RunnerSaysAtom,
    HowWeWorkAtom,
    BlogAtom,
    AboutAtom,
    GalleryAtom,
    PartnersAtom,
    RunningEventsCTAAtom,
    FloatingActionButtonAtom,
  },
  data() {
    return {
      activeTabHWW: 0,
      isWebPSupport: false,
      lightboxIndex: null,
    }
  },
  created() {
    this.getHomeContent()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  computed: {
    ...mapFields('home', ['homeContent']),
  },
  methods: {
    ...mapActions('home', ['getHomeContent']),
    truncateText(text, count, isHTML = false) {
      let str = text

      if (isHTML) {
        let html = str

        html = (html || '').replace(/&nbsp;/g, ' ')

        const div = document.createElement('div')

        div.innerHTML = html

        str = div.textContent || div.innerText || ''
      }

      // Split the text into an array of words
      const words = (str || '').split(' ')

      // Initialize the truncated text and word count
      let truncatedText = ''
      let wordCount = 0

      // Iterate through the words in the array
      for (const word of words) {
        // Increment the word count
        wordCount++

        // If the word count is more than 8, add an ellipsis and break the loop
        if (wordCount > count) {
          truncatedText += '...'
          break
        }

        // Add the current word to the truncated text
        truncatedText += `${word} `
      }

      return truncatedText
    },
    changeTabHWW(index) {
      this.activeTabHWW = index
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style scoped>
.img-partner {
  background-color: #ffffff;
}

.img-partner img {
  object-fit: cover;
  aspect-ratio: auto;
}
</style>

<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="overview" v-if="$route.name == 'MyInformation'">
            <div class="row">
              <div class="col-md-12">
                <div class="wrap_profile_progress">
                  <b-progress
                    height="1.5rem"
                    :max="100"
                    class="mb-3"
                  >
                    <b-progress-bar :value="defineProfileProgress()" :label="`${(defineProfileProgress())}%`"></b-progress-bar>
                  </b-progress>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <div class="row gx-3 align-items-center">
                    <div class="col">
                      <h5 class="bold ls-0">Profile Information</h5>
                    </div>
                  </div>
                  <router-link
                    :to="{
                      name: 'ProfileInformation',
                    }"
                    class="text-link"
                    ><i class="fa fa-pencil-alt mr-1"></i> Edit</router-link
                  >
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <div class="w-100 d-flex bb-1px-grey pb-4">
                    <span class="w-45 regular">First &amp; Middle Name</span>
                    <span class="w-50 bold">{{ (user || {}).registrant_first_name }}</span>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <span class="w-45 regular">Last Name</span>
                    <span class="w-50 bold">{{ (user || {}).registrant_last_name}}</span>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <span class="w-45 regular">Gender</span>
                    <span class="w-50 bold">{{ (user || {}).registrant_gender == 'M' ? 'Male' : 'Female' }}</span>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <span class="w-45 regular">Date of Birth</span>
                    <span class="w-50 bold">{{ formatDateWithoutWeekday((user || {}).registrant_birth_date) }}</span>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <span class="w-45 regular">Phone Number</span>
                    <span class="w-50 bold">{{ (user || {}).registrant_dial_code }}{{ (user || {}).registrant_phone }} <span class="badge badge-success ml-2">Verified</span></span>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <span class="w-45 regular">Citizenship</span>
                    <span class="w-50 bold">{{ (user || {}).registrant_citizenship_fo }}</span>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <div class="w-45 d-flex align-items-center">
                      <span class=" regular">Passport Expiration Date</span>
                      <i v-if="user.registrant_passportexp === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                    </div>
                    <div class="w-50">
                      <span v-if="(user || {}).registrant_passportexp !== null" class="w-50 bold">{{ formatDateWithoutWeekday((user || {}).registrant_passportexp) }}</span>
                      <span v-else class="bold">-</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <div class="row gx-3 align-items-center">
                    <div class="col">
                      <h5 class="bold ls-0">Account Information</h5>
                    </div>
                  </div>
                  <router-link
                    :to="{
                      name: 'AccountInformation',
                    }"
                    class="text-link"
                    ><i class="fa fa-pencil-alt mr-1"></i> Edit</router-link
                  >
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <div class="w-100 d-flex bb-1px-grey pb-4">
                    <span class="w-45 regular">Email Address</span>
                    <span class="w-50 bold">{{ (user || {}).registrant_email_address }} <span class="badge badge-success ml-2">Verified</span></span>
                  </div>
                  <div class="w-100 d-flex pt-4">
                    <span class="w-45 regular">Password</span>
                    <span class="w-50 bold">********</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <h5 class="bold ls-0">Mailing Address</h5>
                  <router-link
                    :to="{
                      name: 'MailingAddress',
                    }"
                    class="text-link"
                    ><i class="fa fa-pencil-alt mr-1"></i> Edit</router-link
                  >
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <div class="w-100 d-flex bb-1px-grey pb-4">
                    <div class="w-45">
                      <span class="regular">Country of Residence</span>
                      <i v-if="(user || {}).registrant_country_of_residence_fo === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                    </div>
                    <div class="w-50">
                      <span class="bold">{{ (user || {}).registrant_country_of_residence_fo || '-' }}</span>
                    </div>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <div class="w-45">
                      <span class="regular">Street Address 1</span>
                      <i v-if="(user || {}).registrant_address_1 === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                    </div>
                    <div class="w-50">
                      <span class="bold">{{ (user || {}).registrant_address_1 || '-' }}</span>
                    </div>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <div class="w-45">
                      <span class="regular">Street Address 2 <span class="small">(optional)</span></span>
                    </div>
                    <div class="w-50">
                      <span class="bold">{{ (user || {}).registrant_address_2 ? (user || {}).registrant_address_2 : '-' }}</span>
                    </div>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <div class="w-45">
                      <span class="regular">Street Address 3 <span class="small">(optional)</span></span>
                    </div>
                    <div class="w-50">
                      <span class="bold">{{ (user || {}).registrant_address_3 ? (user || {}).registrant_address_3 : '-' }}</span>
                    </div>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <div class="w-45">
                      <span class="regular">Province / State</span>
                      <i v-if="(user || {}).registrant_state === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                    </div>
                    <div class="w-50">
                      <span class="bold">{{ (user || {}).registrant_state ? (user || {}).registrant_state : '-' }}</span>
                    </div>
                  </div>
                  <div class="w-100 d-flex" :class="user.registrant_country == '102' ? 'bb-1px-grey py-4' : 'pt-4'">
                    <div class="w-45">
                      <span class="regular">City</span>
                      <i v-if="(user || {}).registrant_city === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                    </div>
                    <div class="w-50">
                      <span class="bold">{{ (user || {}).registrant_city ? (user || {}).registrant_city : '-' }}</span>
                    </div>
                  </div>
                  <template v-if="user.registrant_country == '102'">
                    <div class="w-100 d-flex bb-1px-grey py-4">
                      <div class="w-45">
                        <span class="regular">District</span>
                        <i v-if="(user || {}).registrant_district === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                      </div>
                    <div class="w-50">
                        <span class="bold">{{ (user || {}).registrant_district ? (user || {}).registrant_district : '-' }}</span>
                    </div>
                    </div>
                    <div class="w-100 d-flex bb-1px-grey py-4">
                      <div class="w-45">
                        <span class="regular">Sub District</span>
                        <i v-if="(user || {}).registrant_subdistrict === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                      </div>
                    <div class="w-50">
                        <span class="bold">{{ (user || {}).registrant_subdistrict ? (user || {}).registrant_subdistrict : '-' }}</span>
                    </div>
                    </div>
                    <div class="w-100 d-flex pt-4">
                      <div class="w-45">
                        <span class="regular">Postal Code</span>
                        <i v-if="(user || {}).registrant_zip_code === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                      </div>
                    <div class="w-50">
                        <span class="bold">{{ (user || {}).registrant_zip_code ? (user || {}).registrant_zip_code : '-' }}</span>
                    </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <div class="row gx-3 align-items-center">
                    <div class="col">
                      <h5 class="bold ls-0">Medical Information</h5>
                    </div>
                  </div>
                  <router-link
                    :to="{
                      name: 'MedicalInformation',
                    }"
                    class="text-link"
                    ><i class="fa fa-pencil-alt mr-1"></i> Edit</router-link
                  >
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <div class="w-100 d-flex bb-1px-grey pb-4">
                    <div class="w-45">
                      <span class="regular">Blood Type</span>
                      <i v-if="(user || {}).registrant_blood_type === null" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                    </div>
                    <div class="w-50">
                      <span class="w-50 bold">{{ (user || {}).registrant_blood_type || '-' }}</span>
                    </div>
                  </div>
                  <div class="w-100 d-flex bb-1px-grey py-4">
                    <div class="w-45">
                      <span class="regular">Allergies or Medical Histories</span>
                      <template v-if="Array.isArray((user || {}).registrant_medical_information)">
                        <i v-if="(user || {}).registrant_medical_confirmation === false && (user || {}).registrant_medical_information[0] === ''" class="fa ml-2 fa-exclamation-circle fs-15em text_secondary"></i>
                      </template>
                    </div>
                    <div class="w-50">
                      <span v-if="Array.isArray((user || {}).registrant_medical_information)" class="w-50 bold">{{ (user || {}).registrant_medical_information[0] !== '' ? ((user || {}).registrant_medical_information || []).join(', ')  : '-' }}</span>
                      <span v-else class="bold">{{ (user || {}).registrant_medical_information ? ((user || {}).registrant_medical_information || []).join(', ')  : '-' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between">
                  <div class="row gx-3 align-items-center">
                    <div class="col">
                      <h5 class="bold ls-0">My Documents</h5>
                    </div>
                  </div>
                  <router-link
                    v-if="(userDocumentData.data.data || []).length > 0"
                    :to="{
                      name: 'MyDocuments',
                    }"
                    class="text-link"
                    >See All Documents</router-link
                  >
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <div class="row">
                    <div v-if="(userDocumentData.data.data || []).length > 0" class="col-md-12">
                      <div
                        class="file-list main-font d-flex align-items-center border-bottom-1rem justify-content-between"
                        v-for="(v,k) in userDocumentData.data.data"
                        :class="[(k+1 != (((userDocumentData || {}).data ||{}).data || []).length ? 'bb-1px-grey py-4' : 'pt-4'), (k == 0) ? 'pt-0' : '']"
                        :key="k"
                      >
                        <div class="d-flex align-items-center">
                          <template v-if="v.document_file_filetype == 'pdf'">
                            <picture class="d-flex">
                              <source :srcset="`${baseUrl}/assets/images/static-icon/icon-pdf.webp`" type="image/webp" />
                              <img :src="`${baseUrl}/assets/images/static-icon/icon-pdf.png`" />
                            </picture>
                          </template>
                          <template v-if="v.document_file_filetype == 'doc' || v.document_file_filetype == 'docx'">
                            <picture class="d-flex">
                              <source :srcset="`${baseUrl}/assets/images/static-icon/icon-doc.webp`" type="image/webp" />
                              <img :src="`${baseUrl}/assets/images/static-icon/icon-doc.png`" />
                            </picture>
                          </template>
                          <template v-if="v.document_file_filetype == 'png'">
                            <picture class="d-flex">
                              <source :srcset="`${baseUrl}/assets/images/static-icon/icon-png.webp`" type="image/webp" />
                              <img :src="`${baseUrl}/assets/images/static-icon/icon-png.png`" />
                            </picture>
                          </template>
                          <template v-if="v.document_file_filetype == 'jpg' || v.document_file_filetype == 'jpeg'">
                            <picture class="d-flex">
                              <source :srcset="`${baseUrl}/assets/images/static-icon/icon-jpg.webp`" type="image/webp" />
                              <img :src="`${baseUrl}/assets/images/static-icon/icon-jpg.png`" />
                            </picture>
                          </template>
                          <span class="mx-3">{{ v.document_file_name}}</span>
                        </div>
                        <div>
                          <a
                            class="text-link fw-600 text-underline mx-3"
                            :href="uploader(v.document_file)"
                            target="_blank"
                          >
                            <span>View</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else class="col-12">
                      <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                        <div class="initial-content d-flex flex-column align-items-center">
                          <div class="wrapper-illustration w-50 d-flex justify-content-center">
                            <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="document" class="w-25" />
                          </div>
                          <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No document found</h2>
                          <span>Your confirmation letter, invitation letter will appear here.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="overview" v-if="$route.name == 'ProfileInformation'">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex align-items-center mb-4">
                  <router-link
                    v-if="$route.meta.backCta"
                    :to="{ name: $route.meta.backPage, params: { savePosition: true } }"
                  >
                    <i class="icon-chevron-left fs-125em text-dark"></i>
                  </router-link>
                  <h5 class="bold ls-0 fs-15em ml-2 mb-0">{{ $route.meta.pageTitle }}</h5>
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="GeneralInformationForm">
                    <b-form
                      ref="GeneralInformationFormElement"
                      @submit.prevent="handleSubmit(submitFormProfileInformation)"
                    >
                      <div class="row bb-1px-grey pb-4">
                        <div class="col-md-6 col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">First &amp; Middle Name</label>
                            <input
                              type="text"
                              v-model="inputChangeProfile.registrant_first_name"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate
                              name="First & Middle Name"
                              v-model="inputChangeProfile.registrant_first_name"
                              :rules="{
                                required: true,
                                min: 3,
                                max: 96,
                                regex: /^([A-Za-z' ]+)$/,
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-md-6 col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">Last Name</label>
                            <input
                              type="text"
                              v-model="inputChangeProfile.registrant_last_name"
                              class="form-control main-control"
                              placeholder="e.g. Grahita"
                            />
                            <VValidate
                              name="Last Name"
                              v-model="inputChangeProfile.registrant_last_name"
                              :rules="{
                                required: true,
                                min: 3,
                                max: 96,
                                regex: /^([A-Za-z' ]+)$/,
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">Gender</label>
                            <div class="d-flex mt-3">
                              <b-form-radio v-model="inputChangeProfile.registrant_gender" name="gender" value="M"
                                >Male</b-form-radio
                              >
                              <b-form-radio
                                v-model="inputChangeProfile.registrant_gender"
                                class="ml-3"
                                name="gender"
                                value="W"
                                >Women</b-form-radio
                              >
                            </div>
                            <VValidate
                              name="Gender"
                              v-model="inputChangeProfile.registrant_gender"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">Phone Number</label>
                            <input
                              :value="`${inputChangeProfile.registrant_dial_code}${inputChangeProfile.registrant_phone}`"
                              class="form-control main-control mt-0"
                              disabled
                            ></input>
                            <p>
                              To change phone number, please contact
                              <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-link"
                                >Customer Services</a
                              >
                            </p>
                          </div>
                        </div>

                        <div class="col-md-6 col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">Date of Birth</label>
                            <v-date-picker
                              full-width
                              :popover="{ visibility: 'click' }"
                              v-model="inputChangeProfile.registrant_birth_date"
                              class="mt-3"
                              :masks="{ input: 'MM/DD/YYYY' }"
                              :max-date="maxDateOfBirth"
                              :to-page="defineToPage(inputChangeProfile.registrant_birth_date, minimumAgePage, 0)"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <div class="d-flex flex-column justify-center items-center">
                                  <input
                                    v-if="inputEvents"
                                    :value="formatDate(inputValue)"
                                    placeholder="Choose a date"
                                    v-on="inputEvents"
                                    :onkeydown="false"
                                    class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                  />
                                  <VValidate
                                    name="Date of Birth"
                                    v-model="
                                      inputChangeProfile.registrant_birth_date
                                    "
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </template>
                            </v-date-picker>
                          </div>
                        </div>
                        <div
                          class="col-md-4"
                        >
                          <div class="form-group">
                            <label class="required bold">Citizenship</label>
                            <div class="d-flex mt-3">
                              <b-form-radio
                                v-model="(inputChangeProfile || {}).registrant_citizenship_flag"
                                name="citizenship2"
                                value="Y"
                                >Indonesia</b-form-radio
                              >
                              <b-form-radio
                                v-model="(inputChangeProfile || {}).registrant_citizenship_flag"
                                name="citizenship2"
                                class="ml-3"
                                value="N"
                                >Others</b-form-radio
                              >
                            </div>
                            <VValidate vid="isctzid2" name="Citizenship" v-model="(inputChangeProfile || {}).registrant_citizenship_flag" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-md-8" v-if="(inputChangeProfile || {}).registrant_citizenship_flag == 'N'">
                          <div class="form-group">
                            <label for="co2ctzship" class="bold">Country</label>
                            <v-select
                              :options="citizenshipData"
                              placeholder="-- Select Country --"
                              :reduce="(v) => v.citizenship_id"
                              v-model="(inputChangeProfile || {}).registrant_citizenship"
                              id="co2ctzship"
                              label="citizenship_name"
                              class="mt-3"
                            ></v-select>
                            <VValidate vid="ctz2" name="Citizenship" v-model="(inputChangeProfile || {}).registrant_citizenship" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-md-6 col-6">
                          <div class="form-group">
                            <label class="required bold ls-0">Passport Expiration Date</label>
                            <v-date-picker
                              full-width
                              :popover="{ visibility: 'click' }"
                              v-model="inputChangeProfile.registrant_passportexp"
                              class="mt-3"
                              :masks="{ input: 'MM/DD/YYYY' }"
                              :min-date="minPassportExpiration"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <div class="d-flex flex-column justify-center items-center">
                                  <input
                                    v-if="inputEvents"
                                    :value="formatDate(inputValue)"
                                    placeholder="Choose a date"
                                    v-on="inputEvents"
                                    :onkeydown="false"
                                    class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                  />
                                  <VValidate
                                    name="Passport Expiration Date"
                                    v-model="
                                      inputChangeProfile.registrant_passportexp
                                    "
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </template>
                            </v-date-picker>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            type="submit"
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>

          <div
            class="overview"
            v-if="['AccountInformation', 'ChangeEmail', 'ChangePassword'].indexOf($route.name) > -1"
          >
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex align-items-center mb-4">
                  <router-link
                    v-if="$route.meta.backCta"
                    :to="{ name: $route.meta.backPage, params: { savePosition: true } }"
                  >
                    <i class="icon-chevron-left fs-125em text-dark"></i>
                  </router-link>
                  <h5 class="bold ls-0 fs-15em ml-2 mb-0">{{ $route.meta.pageTitle }}</h5>
                </div>

                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <div class="row">
                    <template v-if="$route.name == 'AccountInformation'">
                      <div class="col-md-12 bb-1px-grey d-flex justify-content-between pt-2 pb-4">
                        <div class="row">
                          <div class="col-md-12 col-6">
                            <div class="form-group">
                              <label class="required bold ls-0">Email Address</label>
                              <input
                                type="email"
                                v-model="user.registrant_email_address"
                                disabled
                                class="form-control main-control disabled"
                                placeholder="e.g. abraham.damar@example.com"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <p>
                              To change email address, please contact
                              <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-link"
                                >Customer Services</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 d-flex justify-content-between pt-4 pb-2">
                        <div class="form-group">
                          <label class="bold ls-0">Password</label>
                        </div>
                        <router-link :to="{ name: 'ChangePassword' }" class="alpha-color text-link">Change</router-link>
                      </div>
                    </template>
                  </div>
                  <div v-if="$route.name == 'ChangePassword'" class="row">
                    <div class="col-md-12">
                      <ValidationObserver v-slot="{ handleSubmit }" ref="ChangePasswordForm">
                        <b-form
                          ref="ChangePasswordFormElement"
                          @submit.prevent="handleSubmit(submitFormChangePassword)"
                        >
                          <div class="row bb-1px-grey py-4">
                            <div class="col-md-12 col-sm-12">
                              <div class="form-group">
                                <label class="required bold ls-0">Current Password</label>
                                <b-input-group class="position-relativ">
                                  <input
                                    type="password"
                                    v-model="inputChangePassword.current_password"
                                    class="form-control main-control disabled"
                                    placeholder="Current Password"
                                    id="currentPassword"
                                  />
                                  <template #append>
                                    <b-icon
                                      v-if="!showCurrentPassword"
                                      icon="eye-slash"
                                      font-scale="1"
                                      class="position-absolute view-password"
                                      @click="
                                        toggleShowPassword(
                                          showCurrentPassword,
                                          'showCurrentPassword',
                                          'currentPassword'
                                        )
                                      "
                                    ></b-icon>
                                    <b-icon
                                      v-else
                                      icon="eye"
                                      font-scale="1"
                                      class="position-absolute view-password main-color"
                                      @click="
                                        toggleShowPassword(
                                          showCurrentPassword,
                                          'showCurrentPassword',
                                          'currentPassword'
                                        )
                                      "
                                    ></b-icon>
                                  </template>
                                </b-input-group>
                                <VValidate
                              name="Current Password"
                              v-model="inputChangePassword.current_password"
                              :rules="{ required: true }"
                            />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="password" class="required bold">Password</label>
                                <b-input-group class="position-relative">
                                  <input
                                    id="password"
                                    type="password"
                                    class="form-control main-control control-group"
                                    placeholder="Password"
                                    v-model="inputChangePassword.new_password"
                                  />
                                  <template #append>
                                    <b-icon
                                      v-if="!showPassword"
                                      icon="eye-slash"
                                      font-scale="1"
                                      class="position-absolute view-password"
                                      @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                    ></b-icon>
                                    <b-icon
                                      v-else
                                      icon="eye"
                                      font-scale="1"
                                      class="position-absolute view-password main-color"
                                      @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                    ></b-icon>
                                  </template>
                                </b-input-group>
                                <VValidate
                              name="Password"
                              v-model="inputChangePassword.new_password"
                              rules="required|min:8"
                            />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="required bold">Confirm Password</label>
                                <b-input-group class="position-relative">
                                  <input
                                    id="confirmPassword"
                                    type="password"
                                    class="form-control main-control control-group"
                                    placeholder="Re-type Password"
                                    v-model="inputChangePassword.confirm_new_password"
                                  />
                                  <template #append>
                                    <b-icon
                                      v-if="!showConfirmPassword"
                                      icon="eye-slash"
                                      font-scale="1"
                                      class="position-absolute view-password"
                                      @click="
                                        toggleShowPassword(
                                          showConfirmPassword,
                                          'showConfirmPassword',
                                          'confirmPassword'
                                        )
                                      "
                                    ></b-icon>
                                    <b-icon
                                      v-else
                                      icon="eye"
                                      font-scale="1"
                                      class="position-absolute view-password main-color"
                                      @click="
                                        toggleShowPassword(
                                          showConfirmPassword,
                                          'showConfirmPassword',
                                          'confirmPassword'
                                        )
                                      "
                                    ></b-icon>
                                  </template>
                                </b-input-group>
                                <VValidate
                              name="Confirm Password"
                              v-model="inputChangePassword.confirm_new_password"
                              rules="required|confirmed:Password"
                            />
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-end pt-4">
                            <div class="col-md-6 d-flex justify-content-end">
                              <router-link
                                :to="{
                                  name: 'MyInformation',
                                }"
                                class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                                >Cancel</router-link
                              >
                              <button
                                type="submit"
                                class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </b-form>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="overview" v-if="$route.name == 'MailingAddress'">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex align-items-center mb-4">
                  <router-link
                    v-if="$route.meta.backCta"
                    :to="{ name: $route.meta.backPage, params: { savePosition: true } }"
                  >
                    <i class="icon-chevron-left fs-125em text-dark"></i>
                  </router-link>
                  <h5 class="bold ls-0 fs-15em ml-2 mb-0">{{ $route.meta.pageTitle }}</h5>
                </div>
                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="MailingAddressForm">
                    <b-form ref="MailingAddressFormElement" @submit.prevent="handleSubmit(submitFormMailingAddress)">
                      <div class="row bb-1px-grey pb-4">
                        <div class="col-md-12 col-sm-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Country of Residence</label>
                            <v-select
                              v-if="citizenshipData"
                              :options="citizenshipData"
                              placeholder="-- Select Country --"
                              :reduce="v => v.citizenship_id"
                              v-model="inputChangeProfile.registrant_country_of_residence"
                              id="country_of_residence"
                              label="citizenship_name"
                              class="mt-3"
                            ></v-select>
                            <VValidate
                              name="Country of Residence"
                              v-model="
                                inputChangeProfile.registrant_country_of_residence
                              "
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="required bold">Street Address 1</label>
                            <textarea
                              type="text"
                              v-model="inputChangeProfile.registrant_address_1"
                              class="form-control main-control text-area"
                              placeholder="Street Address 1"
                            ></textarea>
                            <VValidate
                              name="Street Address 1"
                              v-model="inputChangeProfile.registrant_address_1"
                              :rules="{ required: true, min: 3 }"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="bold">Street Address 2 <span class="small">(optional)</span></label>
                            <textarea
                              type="text"
                              v-model="inputChangeProfile.registrant_address_2"
                              class="form-control main-control text-area"
                              placeholder="Street Address 2"
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="bold">Street Address 3 <span class="small">(optional)</span></label>
                            <textarea
                              type="text"
                              v-model="inputChangeProfile.registrant_address_3"
                              class="form-control main-control text-area"
                              placeholder="Street Address 3"
                            ></textarea>
                          </div>
                        </div>

                        <template v-if="inputChangeProfile.registrant_country_of_residence != '102'">
                          <div class="col-md-5">
                            <div class="form-group">
                              <label class="required bold ls-0">Province / State</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_state"
                                class="form-control main-control"
                                placeholder="Province"
                              />
                              <VValidate
                                name="Province / State"
                                v-model="inputChangeProfile.registrant_state"
                                :rules="{ required: true, min: 3 }"
                              />
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="form-group">
                              <label class="required bold ls-0">City</label>
                              <!-- <v-select
                                :options="citiesData"
                                placeholder="-- Select City --"
                                :reduce="v => v"
                                v-model="inputSelectedCity"
                                label="zip_city"
                                class="mt-3"
                              ></v-select> -->
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_city"
                                class="form-control main-control"
                                placeholder="City"
                              />
                              <VValidate
                                name="City"
                                v-model="inputChangeProfile.registrant_city"
                                :rules="{ required: true, min: 3 }"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label class="required bold">Postal Code</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_zip_code"
                                class="form-control main-control"
                                placeholder="e.g. 1234"
                              />
                              <VValidate
                                name="Postal Code"
                                v-model="inputChangeProfile.registrant_zip_code"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div class="col-md-6 col-6">
                            <div class="form-group">
                              <label class="required bold ls-0">Province</label>
                              <v-select
                                v-if="provinceData"
                                :options="provinceData"
                                placeholder="-- Select Province / State --"
                                :reduce="v => v.province_name"
                                v-model="inputSelectedState"
                                id="countrycode"
                                label="province_name"  
                                class="mt-3"
                              ></v-select>
                              <VValidate
                                name="Province / State"
                                v-model="inputChangeProfile.registrant_state"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>

                          <div class="col-md-6 col-6">
                            <div class="form-group">
                              <label class="required bold ls-0">City</label>
                              <v-select
                                v-if="citiesData"
                                :options="citiesData"
                                placeholder="-- Select City --"
                                :reduce="v => v"
                                v-model="inputSelectedCity"
                                label="zip_city"
                                class="mt-3"
                              ></v-select>
                              <VValidate
                                name="Country of Residence"
                                v-model="inputChangeProfile.registrant_city"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>

                          <div class="col-md-5 col-5">
                            <div class="form-group">
                              <label class="required bold ls-0">District</label>
                              <v-select
                                v-if="districtData"
                                :options="districtData"
                                placeholder="-- Select District --"
                                :reduce="v => v"
                                v-model="inputSelectedDistrict"
                                id="district"
                                label="zip_district"
                                class="mt-3"
                              ></v-select>
                              <VValidate
                                name="District"
                                v-model="inputChangeProfile.registrant_district"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                          <div class="col-md-5 col-5">
                            <div class="form-group">
                              <label class="required bold ls-0">Sub District</label>
                              <v-select
                                v-if="subdistrictData"
                                :options="subdistrictData"
                                placeholder="-- Select Sub District --"
                                :reduce="v => v"
                                label="zip_sub_district"
                                v-model="inputSelectedSubDistrict"
                                id="district"
                                class="mt-3"
                              ></v-select>
                              <VValidate
                                name="District"
                                v-model="inputChangeProfile.registrant_subdistrict"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label class="required bold">Postal Code</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_zip_code"
                                disabled
                                class="form-control disabled main-control"
                                placeholder="e.g. 1234"
                              />
                              <VValidate
                                name="Postal Code"
                                v-model="inputChangeProfile.registrant_zip_code"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            type="submit"
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>

          <div class="overview" v-if="$route.name == 'MedicalInformation'">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex align-items-center mb-4">
                  <router-link
                    v-if="$route.meta.backCta"
                    :to="{ name: $route.meta.backPage, params: { savePosition: true } }"
                  >
                    <i class="icon-chevron-left fs-125em text-dark"></i>
                  </router-link>
                  <h5 class="bold ls-0 fs-15em ml-2 mb-0">{{ $route.meta.pageTitle }}</h5>
                </div>

                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="MedicalInformationForm">
                    <b-form
                      ref="MedicalInformationFormElement"
                      @submit.prevent="handleSubmit(submitFormMedicalInformation)"
                    >
                      <div class="row pb-4">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="bloodType" class="bold">Blood Type</label>
                            <v-select
                              :options="bloodType"
                              placeholder="-- Select Blood Type --"
                              :reduce="(v) => v.value"
                              v-model="(inputChangeProfile || {}).registrant_blood_type"
                              id="bloodType"
                              label="text"
                              class="mt-3"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-12">
                          <label class="required bold ls-0">Allergies or Medical Histories</label>
                        </div>
                        <div class="col-12 mt-2">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              id="confirmMedicalInformation"
                              name="confirmMedicalInformation"
                              class="form-check-input mb-3"
                              v-model="isNoMedicalInfo"
                              @change="onMedicalConfirmationChanged"
                            />
                            <label for="confirmMedicalInformation">I have no allergies or medical histories</label>
                          </div>
                        </div>
                        <template v-if="isNoMedicalInfo === false">
                          <div v-for="(medicalInfo, k) in medicalInformation" :key="k" class="col-md-12 col-12">
                            <div class="form-group">
                              <div class="d-flex align-items-center mt-3">
                                <input
                                  v-model="medicalInformation[k]"
                                  type="text"
                                  class="form-control main-control mt-0"
                                  placeholder="e.g. Allergy to peanuts / Latex allergy / Asthma / Diabetes"
                                />
                                <button @click="addMedicalInfo(k, 'medicalInformation')" type="button" class="btn btn-success ml-2 mb-0"><i class="icon-plus fs-1em"></i></button>
                                <button v-if="medicalInformation.length > 1" @click="removeMedicalInfo(k, medicalInformation[k], 'medicalInformation')" type="button" class="btn btn-danger ml-2 mb-0"><i class="icon-trash-2 fs-1em"></i></button>
                              </div>
                              <VValidate
                                name="Medical Information"
                                v-model="medicalInformation[k]"
                                :rules="{
                                  required: true,
                                  min: 3,
                                  max: 50,
                                }"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="col-12 mt-4">
                            <div class="form-group">
                              <input
                                disabled
                                type="text"
                                class="form-control main-control mt-0"
                                placeholder="e.g. Allergy to peanuts / Latex allergy / Asthma / Diabetes"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <div class="b-grey-black px-4 py-4 bdrd-12px">
              <div class="d-flex mb-3">
                <i class="icon-help-circle"></i>
                <h5 class="bold ml-2">Help Center</h5>
              </div>
              <div class="wrap-help-nav mb-3">
                <router-link :to="{ name: 'FAQ' }" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">FAQ</span>
                      <p class="d-block">
                        Don't know where to turn? Our FAQ section is here to help with any questions.
                      </p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="wrap-help-nav mb-3">
                <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">Chat Whatsapp</span>
                      <p class="d-block">Get in touch with us via WhatsApp for fast and easy communication.</p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </a>
              </div>

              <div class="wrap-help-nav mb-3">
                <router-link :to="{ name: 'ContactUs' }" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">Send a Message</span>
                      <p class="d-block">
                        Send us a message via our contact form and we'll respond as soon as possible.
                      </p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #page-title>
      <div class="d-flex align-items-center">
        <router-link :to="{ name: $route.meta.backPage }" v-if="isMobile() && $route.meta.backCta">
          <i class="icon-chevron-left fs-175em pointer text-white mr-2"></i>
        </router-link>
        <h2 class="black fs-175em text-white ml-2 fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">
          {{ $route.meta.pageTitle }}
        </h2>
      </div>
      <div class="d-flex align-items-center mt-4" v-if="$route.name == 'MyInformation'">
        <div class="profile-mobile d-flex align-items-center">
          <div class="img mr-3">
            <img :src="uploader(user.registrant_avatar)" :alt="user.registrant_full_name" />
            <div class="icon-cust" @click="$refs['modal-change-profile-picture'].show()">
              <i class="icon-edit-2 fs-085em"></i>
            </div>
          </div>
          <div class="text-white mr-3">
            <p class="fs-1em semibold mb-0">{{ truncateTextByLength(user.registrant_full_name, 30) }}</p>
            <p class="fs-1em semibold mb-0">{{ truncateTextByLength(user.registrant_email_address, 30) }}</p>
          </div>
        </div>
      </div>
    </template>

    <template #content-mobile>
      <div class="row mb-5">
        <div class="col-md-12">
          <div class="overview" v-if="$route.name === 'MyInformation'">
            <div class="row">
              <div
                class="d-flex justify-content-between align-items-center bb-1px-grey pb-1 mb-3"
                @click="
                  $router
                    .push({
                      name: 'ProfileInformation',
                    })
                    .catch(() => {})
                "
              >
                <div class="pr-4">
                  <div>
                    <span class="bold text-dark-custom fs-1em">Profile Information</span>
                    <i v-if="(user.registrant_information || {}).profile === 0" class="ml-2 fa fa-exclamation-circle fs-15em text_secondary"></i>
                  </div>
                  <p>General information that relates to you</p>
                </div>
                <div>
                  <i class="icon-chevron-right fs-125em"></i>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center bb-1px-grey pb-1 mb-3"
                @click="
                  $router
                    .push({
                      name: 'AccountInformation',
                    })
                    .catch(() => {})
                "
              >
                <div class="pr-4">
                  <div>
                    <span class="bold text-dark-custom fs-1em">Account Information</span>
                  </div>
                  <p>Used to sign in to your account</p>
                </div>
                <div>
                  <i class="icon-chevron-right fs-125em"></i>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center bb-1px-grey pb-1 mb-3"
                @click="
                  $router
                    .push({
                      name: 'MailingAddress',
                    })
                    .catch(() => {})
                "
              >
                <div class="pr-4">
                  <div>
                    <span class="bold text-dark-custom fs-1em">Mailing Address</span>
                    <i v-if="(user.registrant_information || {}).mailing === 0" class="ml-2 fa fa-exclamation-circle fs-15em text_secondary"></i>
                  </div>
                  <p>Represents the address where you live</p>
                </div>
                <div>
                  <i class="icon-chevron-right fs-125em"></i>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center bb-1px-grey pb-1 mb-3"
                @click="
                  $router
                    .push({
                      name: 'MyNetwork',
                    })
                    .catch(() => {})
                "
              >
                <div class="pr-4">
                  <div>
                    <span class="bold text-dark-custom fs-1em">My Network</span>
                    <i v-if="(user.registrant_information || {}).network === 0" class="ml-2 fa fa-exclamation-circle fs-15em text_secondary"></i>
                  </div>
                  <p>People you connect with</p>
                </div>
                <div>
                  <i class="icon-chevron-right fs-125em"></i>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center bb-1px-grey pb-1 mb-3"
                @click="
                  $router
                    .push({
                      name: 'MedicalInformation',
                    })
                    .catch(() => {})
                "
              >
                <div class="pr-4">
                  <div>
                    <span class="bold text-dark-custom fs-1em">Medical Information</span>
                    <i v-if="(user.registrant_information || {}).medical === 0" class="ml-2 fa fa-exclamation-circle fs-15em text_secondary"></i>
                  </div>
                  <p>Allergies or medical histories</p>
                </div>
                <div>
                  <i class="icon-chevron-right fs-125em"></i>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center bb-1px-grey pb-1 mb-3"
                @click="
                  $router
                    .push({
                      name: 'MyDocuments',
                    })
                    .catch(() => {})
                "
              >
                <div class="pr-4">
                  <span class="bold text-dark-custom fs-1em">My Documents</span>
                  <p>Your invitation letter, hotel confirmation, etc</p>
                </div>
                <div>
                  <i class="icon-chevron-right fs-125em"></i>
                </div>
              </div>
              <div>
                <button
                  class="btn btn-outline-main ml-0 semibold d-flex justify-content-center align-self-start mt-3 align-items-center w-100"
                  @click="$refs['modal-logout'].show()"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>

          <div class="overview" v-if="$route.name == 'ProfileInformation'">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between bb-1px-grey pb-3">
                  <h5 class="bold ls-0 fs-125em">Profile Information</h5>
                </div>
                <div class="mt-4">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="GeneralInformationForm">
                    <b-form
                      ref="GeneralInformationFormElement"
                      @submit.prevent="handleSubmit(submitFormProfileInformation)"
                    >
                      <div class="row bb-1px-grey pb-4">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="required bold ls-0">First &amp; Middle Name</label>
                            <input
                              type="text"
                              v-model="inputChangeProfile.registrant_first_name"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate
                              name="First & Middle Name"
                              v-model="inputChangeProfile.registrant_first_name"
                              :rules="{
                                required: true,
                                min: 3,
                                max: 96,
                                regex: /^([A-Za-z' ]+)$/,
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Last Name</label>
                            <input
                              type="text"
                              v-model="inputChangeProfile.registrant_last_name"
                              class="form-control main-control"
                              placeholder="e.g. Grahita"
                            />
                            <VValidate
                              name="Last Name"
                              v-model="inputChangeProfile.registrant_last_name"
                              :rules="{
                                required: true,
                                min: 3,
                                max: 96,
                                regex: /^([A-Za-z' ]+)$/,
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-md-12 col-3">
                          <div class="form-group">
                            <label class="required bold ls-0">Gender</label>
                            <div class="d-flex mt-3">
                              <b-form-radio v-model="inputChangeProfile.registrant_gender" name="citizenship1" value="M"
                                >Male</b-form-radio
                              >
                              <b-form-radio
                                v-model="inputChangeProfile.registrant_gender"
                                class="ml-3"
                                name="citizenship1"
                                value="W"
                                >Women</b-form-radio
                              >
                            </div>
                            <VValidate
                              name="Gender"
                              v-model="inputChangeProfile.registrant_gender"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="countrycode" class="required bold ls-0">Phone Number</label>
                            <input
                              type="text"
                              :value="inputChangeProfile.registrant_dial_code + inputChangeProfile.registrant_phone"
                              disabled
                              class="form-control main-control disabled"
                              placeholder="e.g. abraham.damar@example.com"
                            />
                            <p>
                              To change phone number, please contact
                              <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-link"
                                >Customer Services</a
                              >
                            </p>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Date of Birth</label>
                            <v-date-picker
                              full-width
                              :popover="{ visibility: 'click' }"
                              v-model="inputChangeProfile.registrant_birth_date"
                              class="mt-3"
                              :masks="{ input: 'MM/DD/YYYY' }"
                              :max-date="maxDateOfBirth"
                              :to-page="defineToPage(inputChangeProfile.registrant_birth_date, minimumAgePage, 0)"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <div class="d-flex flex-column justify-center items-center">
                                  <input
                                    v-if="inputEvents"
                                    :value="formatDate(inputValue)"
                                    placeholder="Choose a date"
                                    v-on="inputEvents"
                                    :onkeydown="false"
                                    class="form-control main-control px-3 w-32 focus:outline-none focus:border-indigo-300"
                                  />
                                  <VValidate
                                    name="Date of Birth"
                                    v-model="
                                      inputChangeProfile.registrant_birth_date
                                    "
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </template>
                            </v-date-picker>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="bold">Citizenship</label>
                            <v-select
                              v-if="citizenshipData"
                              :options="citizenshipData"
                              placeholder="-- Select Country --"
                              :reduce="v => v.citizenship_id"
                              v-model="(inputChangeProfile || {}).registrant_citizenship"
                              id="citizenship"
                              label="citizenship_name"
                              class="mt-3"
                              @click.native="showSearchModal('modal-citizenship', 'citizenship')"
                              :class="isOnlyMobile() ? 'no-dropdown' : ''"
                            ></v-select>
                            <VValidate
                              name="Citizenship"
                              v-model="(inputChangeProfile || {}).registrant_citizenship"
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                            type="submit"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>

          <div
            class="overview"
            v-if="['AccountInformation', 'ChangeEmail', 'ChangePassword'].indexOf($route.name) > -1"
          >
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between bb-1px-grey pb-3">
                  <h5 class="bold ls-0 fs-125em">
                    {{ $route.meta.pageTitle }}
                  </h5>
                </div>
                <template v-if="$route.name == 'AccountInformation'">
                  <div class="row">
                    <div class="col-md-12 col-12 mt-4">
                      <div class="form-group">
                        <label class="required bold ls-0">Email Address</label>
                        <input
                          type="email"
                          v-model="user.registrant_email_address"
                          disabled
                          class="form-control main-control disabled"
                          placeholder="e.g. abraham.damar@example.com"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <p>
                        To change email address, please contact
                        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-link"
                          >Customer Services</a
                        >
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center bb-1px-grey py-4 mb-3"
                    @click="
                      $router.push({
                        name: 'ChangePassword',
                      })
                    "
                  >
                    <div class="pr-4">
                      <span class="bold text-dark-custom fs-1em">Change Password</span>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-6 d-flex justify-content-end">
                      <router-link
                        :to="{
                          name: 'MyInformation',
                        }"
                        class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                        >Back</router-link
                      >
                    </div>
                  </div>
                </template>
                <div class="mt-2" v-if="$route.name == 'ChangePassword'">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="ChangePasswordForm">
                    <b-form ref="ChangePasswordFormElement" @submit.prevent="handleSubmit(submitFormChangePassword)">
                      <div class="row bb-1px-grey py-4">
                        <div class="col-md-12 col-sm-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Current Password</label>
                            <b-input-group class="position-relativ">
                              <input
                                type="password"
                                v-model="inputChangePassword.current_password"
                                class="form-control main-control disabled"
                                placeholder="Current Password"
                                id="currentPassword"
                              />
                              <template #append>
                                <b-icon
                                  v-if="!showCurrentPassword"
                                  icon="eye-slash"
                                  font-scale="1"
                                  class="position-absolute view-password"
                                  @click="
                                    toggleShowPassword(showCurrentPassword, 'showCurrentPassword', 'currentPassword')
                                  "
                                ></b-icon>
                                <b-icon
                                  v-else
                                  icon="eye"
                                  font-scale="1"
                                  class="position-absolute view-password main-color"
                                  @click="
                                    toggleShowPassword(showCurrentPassword, 'showCurrentPassword', 'currentPassword')
                                  "
                                ></b-icon>
                              </template>
                            </b-input-group>
                            <VValidate
                              name="Current Password"
                              v-model="inputChangePassword.current_password"
                              :rules="{ required: true, min: 3 }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="password" class="required bold">New Password</label>
                            <b-input-group class="position-relative">
                              <input
                                id="password"
                                type="password"
                                class="form-control main-control control-group"
                                placeholder="Password"
                                v-model="inputChangePassword.new_password"
                              />
                              <template #append>
                                <b-icon
                                  v-if="!showPassword"
                                  icon="eye-slash"
                                  font-scale="1"
                                  class="position-absolute view-password"
                                  @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                ></b-icon>
                                <b-icon
                                  v-else
                                  icon="eye"
                                  font-scale="1"
                                  class="position-absolute view-password main-color"
                                  @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                ></b-icon>
                              </template>
                            </b-input-group>
                            <VValidate
                              name="Password"
                              v-model="inputChangePassword.new_password"
                              rules="required|min:8"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="required bold">Confirm Password</label>
                            <b-input-group class="position-relative">
                              <input
                                id="confirmPassword"
                                type="password"
                                class="form-control main-control control-group"
                                placeholder="Re-type password"
                                v-model="inputChangePassword.confirm_new_password"
                              />
                              <template #append>
                                <b-icon
                                  v-if="!showConfirmPassword"
                                  icon="eye-slash"
                                  font-scale="1"
                                  class="position-absolute view-password"
                                  @click="
                                    toggleShowPassword(showConfirmPassword, 'showConfirmPassword', 'confirmPassword')
                                  "
                                ></b-icon>
                                <b-icon
                                  v-else
                                  icon="eye"
                                  font-scale="1"
                                  class="position-absolute view-password main-color"
                                  @click="
                                    toggleShowPassword(showConfirmPassword, 'showConfirmPassword', 'confirmPassword')
                                  "
                                ></b-icon>
                              </template>
                            </b-input-group>
                            <VValidate
                              name="Confirm Password"
                              v-model="inputChangePassword.confirm_new_password"
                              rules="required|confirmed:Password"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'AccountInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            type="submit"
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>

          <div class="overview" v-if="$route.name == 'MailingAddress'">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex justify-content-between bb-1px-grey pb-3">
                  <h5 class="bold ls-0 fs-125em">Mailing Address</h5>
                </div>
                <div class="mt-4">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="MailingAddressForm">
                    <b-form ref="MailingAddressFormElement" @submit.prevent="handleSubmit(submitFormMailingAddress)">
                      <div class="row bb-1px-grey pb-4">
                        <div class="col-md-12 col-sm-12">
                          <div class="form-group">
                            <label class="required bold ls-0">Country of Residence</label>
                            <v-select
                              v-if="citizenshipData"
                              :options="citizenshipData"
                              placeholder="-- Select Country --"
                              :reduce="v => v.citizenship_id"
                              v-model="inputChangeProfile.registrant_country_of_residence"
                              id="countryofresidence"
                              @click.native="showSearchModal('modal-country-of-residence', 'countryofresidence')"
                              :class="isOnlyMobile() ? 'no-dropdown' : ''"
                              label="citizenship_name"
                              class="mt-3"
                            ></v-select>
                            <VValidate
                              name="Country of Residence"
                              v-model="
                                inputChangeProfile.registrant_country_of_residence
                              "
                              :rules="{ required: true }"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="required bold">Street Address 1</label>
                            <textarea
                              type="text"
                              v-model="inputChangeProfile.registrant_address_1"
                              class="form-control main-control text-area"
                              placeholder="Street Address 1"
                            ></textarea>
                            <VValidate
                              name="Street Address 1"
                              v-model="inputChangeProfile.registrant_address_1"
                              :rules="{ required: true, min: 3 }"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="bold">Street Address 2 <span class="small">(optional)</span></label>
                            <textarea
                              type="text"
                              v-model="inputChangeProfile.registrant_address_2"
                              class="form-control main-control text-area"
                              placeholder="Street Address 2"
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="bold">Street Address 3 <span class="small">(optional)</span></label>
                            <textarea
                              type="text"
                              v-model="inputChangeProfile.registrant_address_3"
                              class="form-control main-control text-area"
                              placeholder="Street Address 3"
                            ></textarea>
                          </div>
                        </div>

                        <template v-if="inputChangeProfile.registrant_country_of_residence != '102'">
                          <div class="col-md-5">
                            <div class="form-group">
                              <label class="required bold">Province / State</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_state"
                                class="form-control main-control"
                                placeholder="e.g. DKI Jakarta"
                              />
                              <VValidate
                                name="Province / State"
                                v-model="inputChangeProfile.registrant_state"
                                :rules="{ required: true, min: 3 }"
                              />
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="form-group">
                              <label class="required bold">City</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_city"
                                class="form-control main-control"
                                placeholder="e.g. Jakarta"
                              />
                              <VValidate
                                name="City"
                                v-model="inputChangeProfile.registrant_city"
                                :rules="{ required: true, min: 3 }"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label class="required bold">Postal Code</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_zip_code"
                                class="form-control main-control"
                                placeholder="e.g. 1234"
                              />
                              <VValidate
                                name="Postal Code"
                                v-model="inputChangeProfile.registrant_zip_code"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <label class="required bold ls-0">Province</label>
                              <v-select
                                v-if="provinceData"
                                :options="provinceData"
                                placeholder="-- Select Province / State --"
                                :reduce="v => v.province_name"
                                v-model="inputSelectedState"
                                id="province"
                                label="province_name"  
                                class="mt-3"
                                @click.native="showSearchModal('modal-province', 'province')"
                                :class="isOnlyMobile() ? 'no-dropdown' : ''"
                              ></v-select>
                              <VValidate
                                name="Province / State"
                                v-model="inputChangeProfile.registrant_state"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>

                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <label class="required bold ls-0">City</label>
                              <v-select
                                v-if="citiesData"
                                :options="citiesData"
                                placeholder="-- Select City --"
                                :reduce="(v) => v.zip_city"
                                v-model="inputChangeProfile.registrant_city"
                                id="city"
                                label="zip_city"
                                class="mt-3"
                                @click.native="showSearchModal('modal-cities', 'city')"
                                :class="isOnlyMobile() ? 'no-dropdown' : ''"
                              ></v-select>
                              <VValidate
                                name="Country of Residence"
                                v-model="inputChangeProfile.registrant_city"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>

                          <div class="col-md-5 col-12">
                            <div class="form-group">
                              <label class="required bold ls-0">District</label>
                              <v-select
                                v-if="districtData"
                                :options="districtData"
                                placeholder="-- Select District --"
                                :reduce="(v) => v.zip_district"
                                v-model="inputChangeProfile.registrant_district"
                                id="district"
                                label="zip_district"
                                class="mt-3"
                                @click.native="showSearchModal('modal-district', 'district')"
                                :class="isOnlyMobile() ? 'no-dropdown' : ''"
                              ></v-select>
                              <VValidate
                                name="District"
                                v-model="inputChangeProfile.registrant_district"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                          <div class="col-md-5 col-12">
                            <div class="form-group">
                              <label class="required bold ls-0">Sub District</label>
                              <v-select
                                v-if="subdistrictData"
                                :options="subdistrictData"
                                placeholder="-- Select Sub District --"
                                :reduce="(v) => v"
                                v-model="inputChangeProfile.registrant_subdistrict"
                                id="subdistrict"
                                label="zip_sub_district"
                                class="mt-3"
                                @click.native="showSearchModal('modal-sub-district', 'subdistrict')"
                                :class="isOnlyMobile() ? 'no-dropdown' : ''"
                              ></v-select>
                              <VValidate
                                name="Sub District"
                                v-model="inputChangeProfile.registrant_subdistrict"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label class="required bold">Postal Code</label>
                              <input
                                type="text"
                                v-model="inputChangeProfile.registrant_zip_code"
                                disabled
                                class="form-control disabled main-control"
                                placeholder="e.g. 1234"
                              />
                              <VValidate
                                name="Postal Code"
                                v-model="inputChangeProfile.registrant_zip_code"
                                :rules="{ required: true }"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button
                            type="submit"
                            class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>

          <div class="overview" v-if="$route.name == 'MedicalInformation'">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div class="d-flex align-items-center mb-4">
                  <h5 class="bold ls-0 fs-15em ml-2 mb-0">{{ $route.meta.pageTitle }}</h5>
                </div>

                <div class="b-grey-black mt-3 px-4 py-4 bdrd-12px">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="MedicalInformationForm">
                    <b-form
                      ref="MedicalInformationFormElement"
                      @submit.prevent="handleSubmit(submitFormMedicalInformation)"
                    >
                      <div class="row pb-4">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="bloodType" class="required bold">Blood Type</label>
                            <v-select
                              :options="bloodType"
                              placeholder="-- Select Blood Type --"
                              :reduce="(v) => v.value"
                              v-model="(inputChangeProfile || {}).registrant_blood_type"
                              id="bloodType"
                              label="text"
                              class="mt-3"
                            ></v-select>
                            <VValidate vid="bloodType" name="Blood Type" v-model="(inputChangeProfile || {}).registrant_blood_type" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-12">
                          <label class="required bold ls-0">Allergies or Medical Histories</label>
                        </div>
                        <div class="col-12 mt-2">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              id="confirmMedicalInformation"
                              name="confirmMedicalInformation"
                              class="form-check-input mb-3"
                              v-model="isNoMedicalInfo"
                              @change="onMedicalConfirmationChanged"
                            />
                            <label for="confirmMedicalInformation">I have no allergies or medical histories</label>
                          </div>
                        </div>
                        <template v-if="isNoMedicalInfo === false">
                          <div v-for="(medicalInfo, k) in medicalInformation" :key="k" class="col-md-12 col-12">
                            <div class="form-group">
                              <div class="d-flex align-items-center mt-3">
                                <input
                                  v-model="medicalInformation[k]"
                                  type="text"
                                  class="form-control main-control mt-0"
                                  placeholder="e.g. Allergy to peanuts / Latex allergy / Asthma / Diabetes"
                                />
                                <button @click="addMedicalInfo(k, 'medicalInformation')" type="button" class="btn btn-success ml-2 mb-0"><i class="icon-plus fs-1em"></i></button>
                                <button v-if="medicalInformation.length > 1" @click="removeMedicalInfo(k, medicalInformation[k], 'medicalInformation')" type="button" class="btn btn-danger ml-2 mb-0"><i class="icon-trash-2 fs-1em"></i></button>
                              </div>
                              <VValidate
                                name="Medical Information"
                                v-model="medicalInformation[k]"
                                :rules="{
                                  required: true,
                                  min: 3,
                                  max: 50,
                                }"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="col-12 mt-4">
                            <div class="form-group">
                              <input
                                disabled
                                type="text"
                                class="form-control main-control mt-0"
                                placeholder="e.g. Allergy to peanuts / Latex allergy / Asthma / Diabetes"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row justify-content-end pt-4">
                        <div class="col-md-6 d-flex justify-content-end">
                          <router-link
                            :to="{
                              name: 'MyInformation',
                            }"
                            class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                            >Cancel</router-link
                          >
                          <button class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #custom-layout>
      <AppModal :withPageTitle="true" ref="modal-country-code" :type="'lg'">
        <template #title-modal>
          <h4>Country Code</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control outline-none main-control" placeholder="-- Search Country --" />
              <div class="my-4 h-100vh fade-in">
                <template>
                  <div class="d-flex justify-content-between bb-1px-grey py-4">
                    <p class="light mb-0">United States</p>
                    <p class="mb-0">+1</p>
                  </div>
                  <div class="d-flex justify-content-between bb-1px-grey py-4">
                    <p class="light mb-0">Hong Kong</p>
                    <p class="mb-0">+60</p>
                  </div>
                  <div class="d-flex justify-content-between bb-1px-grey py-4">
                    <p class="light mb-0">Indonesia</p>
                    <p class="mb-0">+62</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-change-profile-picture" :type="'n'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Profile Picture</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="wrap_avatar wrap_img d-flex justify-content-center align-items-center">
                <label for="upload-avatar" class="cursor-pointer mb-0">
                  <img
                    id="previewUploadAvatar"
                    :src="uploader(user.registrant_avatar)"
                    alt="avatar preview"
                    class="rounded-circle img-fluid"
                    :class="!isOnlyMobile() ? 'p-2' : ''"
                  />
                </label>
              </div>
              <div class="wrap_upload_btn d-flex justify-content-center my-4">
                <label class="btn btn-outline" for="upload-avatar">Choose Photo</label>
              </div>
              <ValidationProvider name="Photo" rules="required" ref="fileInput" v-slot="{ validate, errors }">
                <input
                  ref="fileInput"
                  @change="pickFile" 
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  class="d-none" 
                  id="upload-avatar" 
                />
                <b-form-text class="px-3 text-center"> Format accepted: .jpg, .jpeg, .png </b-form-text>
                <b-form-text class="px-3 text-center" text-variant="danger">{{ errors[0] }}</b-form-text>
              </ValidationProvider>
            </div>
            <div class="col-md-12">
              <div class="b-grey-black px-3 py-3 bdrd-12px mt-2">
                <p class="fs-1rem bold">Requirement</p>
                <p class="fs-1rem normal">Format: JPEG, JPG, PNG</p>
                <p class="fs-1rem normal">Max file size 2 MB</p>
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-slg btn-primary bold w-100 mt-4" @click="doChangeProfilePicture">
                Save Changes
              </button>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-citizenship" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Citizenship</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getCitizenshipFilter" v-model="filterCitizenship" placeholder="Search..." />
              <div class="my-4 h-100vh fade-in">
                <template v-if="!loading.data">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in citizenshipData" 
                    :key="k" 
                    @click="chooseCitizenship(v.citizenship_id)"
                  >
                    <p class="light mb-0">{{ v.citizenship_name }}</p>
                    <p class="mb-0">{{ v.citizenship_code }}</p>
                  </div>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-province" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Provinces / States</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getProvincesFilter" v-model="filterProvinces" placeholder="Search..." />
              <div class="my-4 h-100vh fade-in">
                <template v-if="!loading.data">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in provinceData" 
                    :key="k" 
                    @click="chooseProvinces(v.province_name)"
                  >
                    <p class="light mb-0">{{ v.province_name }}</p>
                    <!-- <p class="mb-0">{{ v.citizenship_code }}</p> -->
                  </div>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-cities" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Cities</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getCitiesFilter" v-model="filterCities" placeholder="Search..." />
              <div class="my-4 h-100vh fade-in">
                <template v-if="!loading.data">
                  <template v-if="citiesData.length > 0">
                    <div
                      class="d-flex justify-content-between bb-1px-grey py-4"
                      v-for="(v,k) in citiesData"
                      :key="k" 
                      @click="chooseCity(v)"
                    >
                      <p class="light mb-0">{{ v.zip_city }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <p>Select province first</p>
                  </template>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-district" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Districts</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getDistrictFilter" v-model="filterDistrict" placeholder="Search..." />
              <div class="my-4 h-100vh fade-in">
                <template v-if="!loading.data">
                  <template v-if="districtData.length > 0">
                    <div
                      class="d-flex justify-content-between bb-1px-grey py-4"
                      v-for="(v,k) in districtData"
                      :key="k" 
                      @click="chooseDistrict(v)"
                    >
                      <p class="light mb-0">{{ v.zip_district }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <p>Select city first</p>
                  </template>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-sub-district" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Sub Districts</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getSubDistrictFilter" v-model="filterSubDistrict" placeholder="Search..." />
              <div class="my-4 h-100vh fade-in">
                <template v-if="!loading.data">
                  <template v-if="subdistrictData.length > 0">
                    <div
                      class="d-flex justify-content-between bb-1px-grey py-4"
                      v-for="(v,k) in subdistrictData"
                      :key="k" 
                      @click="chooseSubDistrict(v)"
                    >
                      <p class="light mb-0">{{ v.zip_sub_district }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <p>Select district first</p>
                  </template>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-code-number" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Country Code</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getCountryCodeFilter" v-model="filterCountry" placeholder="Search..." />
            </div>
            <div class="col-md-12">
              <div class="my-2 h-100vh fade-in">
                <template v-if="!loading.data">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in countryCodeData" 
                    :key="k" 
                    @click="chooseCountryCode(v)"
                  >
                    <p class="light mb-0">{{ v.countrycode_name }}</p>
                    <p class="mb-0">{{ v.countrycode_dial_code }}</p>
                  </div>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="false" ref="modal-logout" :type="'md'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/logout-1.svg`" alt="Confirmation" class="w-35-cust" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Confirm log out?</p>
                <span class="regular fs-1em">Are you sure you want to end this session?</span>
                <div class="d-flex w-100 justify-content-center">
                  <button
                    @click="$refs['modal-logout'].hide()"
                    class="btn btn-outline-main ml-0 semibold d-flex text-center justify-content-center mt-3 align-items-center"
                    :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                  >
                    No, Stay Here
                  </button>
                  <button
                    @click="doLogout"
                    class="btn btn-primary semibold d-flex text-center justify-content-center mt-3 align-items-center"
                    :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                  >
                    Yes, Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-relation" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Relation</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getRelationsFilter" v-model="filterRelations" placeholder="Search..." />
            </div>
            <div class="col-md-12">
              <div class="my-2 h-100vh fade-in">
                <template v-if="!loading.data">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in relationData"
                    :key="k" 
                    @click="chooseRelation(v.text)"
                  >
                    <p class="light mb-0">{{ v.text }}</p>
                    <!-- <p class="mb-0">{{ v.citizenship_code }}</p> -->
                  </div>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="true" ref="modal-country-of-residence" :type="'lg'">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Country of Residence</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control main-control" @keyup="getCitizenshipFilter" v-model="filterCitizenship" placeholder="Search..." />
              <div class="my-4 h-100vh fade-in">
                <template v-if="!loading.data">
                  <div
                    class="d-flex justify-content-between bb-1px-grey py-4"
                    v-for="(v,k) in citizenshipData" 
                    :key="k" 
                    @click="chooseCountryOfResidence(v.citizenship_id)"
                  >
                    <p class="light mb-0">{{ v.citizenship_name }}</p>
                    <p class="mb-0">{{ v.citizenship_code }}</p>
                  </div>
                </template>
                <template v-else>
                <div class="gooey">
                  <span class="dot"></span>
                  <div class="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </template>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppAlert
        ref="modal-success-gi"
        vref="modal-success-gi"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Profile information has been successfully updated!"
        description="Thank you for keeping your information up-to-date."
        :button="{
          main: {
            text: 'Ok',
            handle: $route.query.ref ? onConfirmWithRef : onConfirmWithoutRef,
          },
        }"
        @close="(id) => $route.query.ref ? onConfirmWithRef(id) : onConfirmWithoutRef(id)"
      />

      <AppAlert
        ref="modal-success-ai"
        vref="modal-success-ai"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Account information has been successfully updated!"
        description="Thank you for keeping your information up-to-date."
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppAlert
        ref="modal-success-ma"
        vref="modal-success-ma"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Mailing address information has been successfully updated!"
        description="Thank you for keeping your information up-to-date."
        :button="{
          main: {
            text: 'Ok',
            handle: $route.query.ref ? onConfirmWithRef : onConfirmWithoutRef,
          },
        }"
        @close="(id) => $route.query.ref ? onConfirmWithRef(id) : onConfirmWithoutRef(id)"
      />

      <AppAlert
        ref="modal-success-mi"
        vref="modal-success-mi"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Medical information has been successfully updated!"
        description="Thank you for keeping your information up-to-date."
        :button="{
          main: {
            text: 'Ok',
            handle: $route.query.ref ? onConfirmWithRef : onConfirmWithoutRef,
          },
        }"
        @close="(id) => $route.query.ref ? onConfirmWithRef(id) : onConfirmWithoutRef(id)"
      />

      <AppModal :withPageTitle="false" ref="modal-success-profile-picture" :type="'md'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/accept-confirmation-vect.svg`" alt="Information" class="w-50" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">
                  Profile picture has been successfully updated!
                </p>
                <span class="regular fs-1em">Thank you for keeping your information up-to-date</span>
                <div class="d-flex">
                  <button
                    @click="$refs['modal-success-profile-picture'].hide()"
                    class="btn btn-primary semibold d-flex text-center justify-content-center mt-3 align-items-center"
                    :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppAlert
        ref="modal-error"
        vref="modal-error"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
        }"
        icon-size="w-50"
        :title="messageErrTitle"
        :description="messageErrDescription"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppAlert 
        ref="confirmation-delete-medical-info" 
        vref="confirmation-delete-medical-info" 
        type="warning"
        title="Delete this medical information?" 
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`
        }"
        description="You won't be able to recover this medical information."
        :button="{
          main: {
            text: 'Yes, Delete',
            handle: onConfirmRemoveMedicalInfo
          },
          secondary: {
            text: 'Cancel'
          }
        }" 
      />

      <AppAlert 
        ref="confirmation-no-medical-info" 
        vref="confirmation-no-medical-info" 
        type="warning"
        title="Are you sure you have no allergies or medical histories?" 
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`
        }"
        description="You won't be able to recover the medical information that you have filled."
        :button="{
          main: {
            text: 'Yes, Delete',
            handle: onConfirmNoMedicalInfo
          },
          secondary: {
            text: 'Cancel'
          }
        }" 
      />
    </template>
  </LayoutPrivatePage>
</template>

<script>
// @ is an alias to /src
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import AppModal from '@/components/AppModal'
import AppAlert from '@/components/AppAlert'

import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'
import axios from 'axios'
import Gen from '@/libs/Gen'
import dayjs from 'dayjs'

export default {
  extends: Global,
  name: 'MyInformation',
  components: {
    LayoutPrivatePage,
    AppModal,
    AppAlert,
  },
  data() {
    return {
      isNoMedicalInfo: false,
      minimumAge: 16,
      selectedMedicalInfoIndex: null,
      selectedMedicalFieldName: null,
      messageErrTitle: '',
      messageErrDescription: '',
      formInputAvatar: {
        registrant_image: '',
        registrant_fallback_image: '',
      },
      perPage: 3,
      currentPage: 1,
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
      ],
      filter: {
        search: '',
      },
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Running Events',
          active: true,
        },
      ],

      relations: [
        {
          name: 'Friend',
        },
        {
          name: 'Spouse',
        },
      ],

      formGeneralInformations: {
        as_first_name: '',
        as_last_name: '',
        as_gender: '',
        as_birth_date: '',
        as_citizenship: '',
        as_country_code: '',
        as_phone_number: '',
      },

      formChangePassword: {
        as_current_password: '',
        as_password: '',
        as_confirm_password: '',
      },

      formMailingAddress: {
        as_country_of_residence: '',
        as_street_address_1: '',
        as_street_address_2: '',
        as_street_address_3: '',
        as_province: '',
        as_city: '',
        as_district: '',
        as_sub_district: '',
        as_postal_code: '',
      },

      formEmergencyContact: {
        as_first_name: '',
        as_last_name: '',
        as_relation: '',
        as_email: '',
        as_country_code: '',
        as_number_phone: '',
      },

      formDemographicInformations: {
        as_marital_status: '',
        as_ethnicity: '',
        as_educational_level: '',
        as_industry: '',
        as_occupation: '',
        as_annual_household_income: '',
      },

      previewImage: null,
      fileProfilePicture: null,
      fileType: null,
      conf: {
        rule_type: 'jpeg,jpg,png',
        max_image_size: 5000000,
      },

      showCurrentPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      selected_state: '',
      selected_city: '',
      selected_district: '',
      selected_subdistrict: '',
      medicalInformation: [''],
    }
  },
  computed: {
    ...mapFields(['user','loading','channels']),
    ...mapFields("useAccountSettingsStore",[
      'userDocumentData'
    ]),
    ...mapFields("profile",[
      "inputChangeProfile",
      "countryCodeData",
      "citizenshipData",
      "languageData",
      "inputChangePassword",
      "provinceData",
      "citiesData",
      "districtData",
      "subdistrictData",
      "filterCountry",
      "filterCitizenship",
      "filterLanguages",
      "filterProvinces",
      "filterCities",
      "filterDistrict",
      "filterSubDistrict",
      "relationData",
      "filterRelations",
      "bloodType",
    ]),
    ...mapGetters(['authenticated']),

    minPassportExpiration() {
      return new Date()
    },
    rows() {
      return this.items.length
    },
    minimumAgePage() {
      const minimumdate = dayjs().subtract(this.minimumAge, 'year')

      return {
        month: minimumdate.format('MM'),
        year: minimumdate.format('YYYY'),
      }
    },
    maxDateOfBirth() {
      let dateNow = new Date()

      const year = dateNow.getFullYear()
      const month = dateNow.getMonth()
      const day = dateNow.getDate()

      let conf = new Date((year - 11), (month - 1), day)
      return conf
    },
    inputSelectedState: {
      get() {
        this.selected_state = this.inputChangeProfile.registrant_state
        return this.selected_state
      },
      set(newValue) {
        this.$set(this, 'selected_state', newValue)
        this.$set(this.inputChangeProfile, 'registrant_state', newValue)
        this.$set(this.inputChangeProfile, 'registrant_city', null)
        this.$set(this, 'selected_city', null)

        this.$set(this.inputChangeProfile, 'registrant_district', null)
        this.$set(this, 'selected_district', null)

        this.$set(this.inputChangeProfile, 'registrant_subdistrict', null)
        this.$set(this, 'selected_subdistrict', null)

        this.$set(this.inputChangeProfile, 'registrant_zip_code', null)
        this.$set(this, 'zip_code', null)
      }
    },
    inputSelectedCity: {
      get() {
        this.selected_city = (this.inputChangeProfile || {}).registrant_city
        return this.selected_city
      },
      set(newValue) {
        this.$set(this, 'selected_city', (newValue || {}).zip_city || null)
        this.$set(this.inputChangeProfile, 'registrant_city', (newValue || {}).zip_city || null)

        this.$set(this.inputChangeProfile, 'registrant_district', null)
        this.$set(this, 'selected_district', null)

        this.$set(this.inputChangeProfile, 'registrant_subdistrict', null)
        this.$set(this, 'selected_subdistrict', null)

        this.$set(this.inputChangeProfile, 'registrant_zip_code', null)
        this.$set(this, 'zip_code', null)
      }
    },

    inputSelectedDistrict: {
      get() {
        this.selected_district = this.inputChangeProfile.registrant_district
        return this.selected_district
      },
      set(newValue) {
        this.$set(this, 'selected_district', (newValue || {}).zip_district || null)
        this.$set(this.inputChangeProfile, 'registrant_district', (newValue || {}).zip_district || null)

        this.$set(this.inputChangeProfile, 'registrant_subdistrict', null)
        this.$set(this, 'selected_subdistrict', null)

        this.$set(this.inputChangeProfile, 'registrant_zip_code', null)
        this.$set(this, 'zip_code', null)
      }
    },

    inputSelectedSubDistrict: {
      get() {
        this.selected_subdistrict = this.inputChangeProfile.registrant_subdistrict
        return this.selected_subdistrict
      },
      set(newValue) {
        this.$set(this, 'selected_subdistrict', (newValue || {}).zip_sub_district || null)
        this.$set(this.inputChangeProfile, 'registrant_subdistrict', (newValue || {}).zip_sub_district || null)

        this.$set(this, 'zip_code', (newValue || {}).zip_zipcode || null)
        this.$set(this.inputChangeProfile, 'registrant_zip_code', (newValue || {}).zip_zipcode || null)
      }
    },
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })

    this.$store.commit('SET_STATIC_SEO', {
      title: 'My Information'
    })
    this.getAllDocument()
    this.getProfile((data) => {
      this.medicalInformation = data.user_last_medical_information.registrant_medical_information
      this.isNoMedicalInfo = data.user_last_medical_information.registrant_medical_confirmation
    })
  },
  methods: {
    ...mapActions(["watchAuth", "getChannels"]),
    ...mapActions("useAccountSettingsStore", [
      "getAllDocument"
    ]),
    ...mapActions("profile", [
      "getProfile",
      "getCountryCode",
      "getCitizenship",
      "getLanguages",
      "updateProfile",
      "changePassword",
      "getCities",
      "getProvince",
      "getDistrict",
      "getSubDistrict",
      "getRelations"
    ]),

    defineProfileProgress() {
      let progress = 0

      progress += this.user.registrant_first_name !== null ? 1 : 0
      progress += this.user.registrant_last_name !== null ? 1 : 0
      progress += this.user.registrant_phone !== null ? 1 : 0
      progress += this.user.registrant_citizenship !== null ? 1 : 0
      progress += this.user.registrant_gender !== null ? 1 : 0
      progress += this.user.registrant_birth_date !== null ? 1 : 0
      progress += this.user.registrant_passportno !== null ? 1 : 0
      progress += this.user.registrant_passportexp !== null ? 1 : 0
      progress += this.user.registrant_country !== null ? 1 : 0
      progress += this.user.registrant_address_1 !== null ? 1 : 0
      progress += this.user.registrant_state !== null ? 1 : 0
      progress += this.user.registrant_city !== null ? 1 : 0
      progress += this.user.registrant_district !== null ? 1 : 0
      progress += this.user.registrant_subdistrict !== null ? 1 : 0
      progress += this.user.registrant_zip_code !== null ? 1 : 0
      progress += (this.user.registrant_information || {}).network
      progress += (this.user.registrant_information || {}).medical

      return parseInt((progress / 16) * 100)
    },

    onMedicalConfirmationChanged(e) {
      if (e.target.checked === true && this.medicalInformation[0] !== '') {
        const checkBox = document.getElementById('confirmMedicalInformation')
        checkBox.checked = false
        this.isNoMedicalInfo = false
        this.$refs['confirmation-no-medical-info'].show()
        return null
      }

      this.medicalInformation = ['']

    },

    onConfirmNoMedicalInfo() {
      this.medicalInformation = ['']
      this.isNoMedicalInfo = !this.isNoMedicalInfo
      this.$refs['confirmation-no-medical-info'].hide()
    },

    onConfirmWithRef(modalId) {
      this.$refs[modalId].hide()
      this.$router.push({ name: this.$route.meta.backRef }).catch(() => {})
    },

    onConfirmWithoutRef(modalId) {
      this.$refs[modalId].hide()
      this.$router.push({ name: this.$route.meta.backPage }).catch(() => {})
    },

    formatDayJs(date, format, index) {
      return dayjs(date).format(format)
    },

    defineToPage(value, min, index) {
      if (['', null, undefined].indexOf(value) == -1) {
        return {
          month: this.formatDayJs(value, 'M'),
          year: this.formatDayJs(value, 'YYYY')
        }
      } else if (['', null, undefined].indexOf(min) == -1) {
        return min
      }

      return undefined
    },

    addMedicalInfo(index, fieldName) {
      (this[fieldName] || []).splice(index + 1, 0, '')
    },

    removeMedicalInfo(index, value, fieldName) {
      this.selectedMedicalInfoIndex = index
      this.selectedMedicalFieldName = fieldName
    
      if (value.length > 0) {
        return this.$refs['confirmation-delete-medical-info'].show()
      }

      return this.onConfirmRemoveMedicalInfo()
    },

    onConfirmRemoveMedicalInfo() {
      (this[this.selectedMedicalFieldName] || []).splice(this.selectedMedicalInfoIndex, 1)
      this.$refs['confirmation-delete-medical-info'].hide()
    },

    scrollToTop(qs) {
      const scrollPosition = document.querySelector(qs).scrollTop

      if (scrollPosition === undefined) {
        return null
      }

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - (scrollPosition / 8))
      }
    },

    showSearchModal(modalId, inputId) {
      this.$refs[modalId].show()
      document.querySelector(`#${inputId} .vs__search`).blur()
      this.scrollToTop('.second-window .content')
    },

    truncateTextByLength(text, length) {
      // If the text is shorter than the length, return the text as is
      if (text.length <= length) {
        return text
      }

      // Truncate the text to the specified length
      const truncatedText = text.substring(0, length)

      // Concatenate the truncated text with an ellipsis
      return truncatedText.concat('...')
    },

    doLogout(){
      this.$store.commit('logoutUserToken')
      if(!this.authenticated) {
        this.$router.push({ name: 'Login' })
      }
    },
    
    async submitFormMailingAddress() {
      try{
        const res = await this.updateProfile()
        this.watchAuth()
  
        if(res.data.message) {
          this.$refs['modal-success-ma'].show()

          if (!$route.query.ref) {
            this.$router.push({ name: 'MyInformation'})
          } else {
            this.$router.push({ name: $route.meta.backRef })
          }
        }
      } catch(err) {
        if (((err.response || {}).data || {}).message) {
          this.messageErrTitle = (err.response.data || {}).title;
          this.messageErrDescription = (err.response.data || {}).message;

          this.$refs["modal-error"].show()
        }
      }
    },

    async submitFormMedicalInformation() {
      try{
        this.inputChangeProfile.registrant_medical_information = this.medicalInformation
        this.inputChangeProfile.registrant_medical_confirmation = this.isNoMedicalInfo

        const res = await this.updateProfile()
  
        if(res.data.message) {
          this.$refs['modal-success-mi'].show()
          this.watchAuth()
          
          if (!$route.query.ref) {
            this.$router.push({ name: 'MyInformation'})
          } else {
            this.$router.push({ name: $route.meta.backRef })
          }
        }
      } catch(err) {
        if (((err.response || {}).data || {}).message) {
          this.messageErrTitle = (err.response.data || {}).title;
          this.messageErrDescription = (err.response.data || {}).message;

          this.$refs["modal-error"].show()
        }
      }
    },

    async submitFormEmergencyContact() {
      try{
        const res = await this.updateProfile()
  
        if(res.data.message) {
          this.$refs['modal-success-mi'].show()
          this.watchAuth()
          this.$router.push({ name: 'MyInformation'})
        }
      } catch(err) {
        if (((err.response || {}).data || {}).message) {
          this.messageErrTitle = (err.response.data || {}).title;
          this.messageErrDescription = (err.response.data || {}).message;

          this.$refs["modal-error"].show()
        }
      }
    },

    uploadFormFile() {
      // Validation
      this.error = false

      if (this.fileProfilePicture !== null) {
        if (this.conf.rule_type.indexOf(this.fileType) == -1) {
          this.messageErrTitle = `File type must be ${this.conf.rule_type} type.`
          this.$refs['modal-error'].show()
          return null
        }

        if (this.fileProfilePicture.size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          this.messageErrTitle = 'File too big (> 5MB)'
          this.$refs['modal-error'].show()
          return null
        }
        
        if (this.error) {
          return alert(this.error)
        }

        const path = 'avatar'

        const formData = new FormData()

        formData.set('file', this.fileProfilePicture)

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },

    async pickFile(e) {
      this.$refs['fileInput'].validate(e)

      if (e.target.value === '') {
        return
      }

      const input = this.$refs.fileInput
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.addEventListener("load", function() {
          (document.getElementById("previewUploadAvatar") || {}).src = reader.result
        }, false)
        this.$emit('input', file[0])
      }

      this.fileProfilePicture = e.target.files[0]

      this.conf.fileType = (this.fileProfilePicture || {}).type

      this.fileType = ((this.fileProfilePicture || {}).name || '')
        .substr(((this.fileProfilePicture || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()
    },

    async doChangeProfilePicture() {
      this.$store.state.loading.screen = true
      const avatar = await this.uploadFormFile()

      this.formInputAvatar.registrant_image = ((avatar || {}).data || {}).pathfile
      this.formInputAvatar.registrant_fallback_image = ((avatar || {}).data || {}).fallback_pathfile

      const token = Gen.getCookie("userAuthRdg")

      const req = await Gen.apiRest('/change-avatar', { 
        data: { ...this.formInputAvatar},
        headers: Gen.apiHeaderAuth(token),
      }, 'POST')
      
      if (req.status == 200) {
        if (this.formInputAvatar.registrant_image !== '' && this.formInputAvatar.registrant_image !== undefined) {
          this.$store.state.user.registrant_avatar = this.formInputAvatar.registrant_image
          this.$store.state.user.registrant_fallback_avatar = this.formInputAvatar.registrant_fallback_image
        }

        this.$store.state.loading.screen = false
        this.$refs['modal-change-profile-picture'].hide()
        this.$refs['modal-success-profile-picture'].show()
      }
    },

    toggleShowPassword(value, key, elementId) {
      const toggle = !value
      this.$set(this, key, toggle)
      const el = document.getElementById(elementId)
      el.type = toggle ? 'text' : 'password'
    },

    async submitFormProfileInformation() {
      try{
        const res = await this.updateProfile()
        this.watchAuth()
  
        if(res.data.message) {
          this.$refs["modal-success-gi"].show()

          if (!this.$route.query.ref) {
            this.$router.push({ name: 'MyInformation'})
          }
        }
      } catch(err) {
        if (((err.response || {}).data || {}).message) {
          this.messageErrTitle = (err.response.data || {}).title;
          this.messageErrDescription = (err.response.data || {}).message;

          this.$refs["modal-error"].show()
        }
      }
    },

    async submitFormChangePassword () {
      await this.changePassword((success, data) => {
        if(success) {
          this.$refs["modal-success-ai"].show()
          this.$router.push({ name: 'AccountInformation'})
          return null
        }

        // if error
        if (((data.response || {}).data || {}).message) {
          this.messageErrTitle = (data.response.data || {}).title;
          this.messageErrDescription = (data.response.data || {}).message;

          this.$refs["modal-error"].show()
        }
      })
    },

    getCountryCodeFilter() {
      this.debounce(() => {
        this.getCountryCode()
      }, 500)
    },

    getCitizenshipFilter() {
      this.debounce(() => {
        this.getCitizenship()
      }, 500)
    },

    getProvincesFilter() {
      this.debounce(() => {
        this.getProvince()
      }, 500)
    },

    getCitiesFilter() {
      const province = this.inputChangeProfile.registrant_state

      this.debounce(() => {
        this.getCities(province)
      }, 500)
    },

    getDistrictFilter() {
      const city = this.inputChangeProfile.registrant_city
      this.debounce(() => {
        this.getDistrict(city)
      }, 500)
    },

    getSubDistrictFilter() {
      const district = this.inputChangeProfile.registrant_district
      this.debounce(() => {
        this.getSubDistrict(district)
      }, 500)
    },

    getLanguagesFilter() {
      this.debounce(() => {
        this.getLanguages()
      }, 500)
    },

    getRelationsFilter() {
      this.debounce(() => {
        this.getRelations()
      }, 500)
    },

    chooseCountryCode(data) {
      if(this.$route.name == 'EmergencyContact') {
        (this.inputChangeProfile || {}).registrant_emergency_dial_code = data.countrycode_dial_code
      } else {
        (this.inputChangeProfile || {}).registrant_country_code = data.countrycode_id
      }
        this.$refs['modal-code-number'].hide()
    },
    chooseCitizenship(data) {
      (this.inputChangeProfile || {}).registrant_citizenship = data
      this.$refs['modal-citizenship'].hide()
    },
    chooseCountryOfResidence(data) {
      (this.inputChangeProfile || {}).registrant_country_of_residence = data
      this.$refs['modal-country-of-residence'].hide()
    },
    chooseRelation(data) {
      (this.inputChangeProfile || {}).registrant_emergency_relation = data
      this.$refs['modal-relation'].hide()
    },
    chooseProvinces(data) {
      this.selected_state = data
      this.inputChangeProfile.registrant_state = data
      this.inputChangeProfile.registrant_city = null
      this.selected_city = null

      this.inputChangeProfile.registrant_district = null
      this.selected_district = null

      this.inputChangeProfile.registrant_subdistrict = null
      this.selected_subdistrict = null

      this.inputChangeProfile.registrant_zip_code = null
      this.zip_code = null

      this.$refs['modal-province'].hide()
    },
    chooseCity(data) {
      this.inputChangeProfile.registrant_city = (data || {}).zip_city
      this.selected_city = (data || {}).zip_city

      this.inputChangeProfile.registrant_district = null
      this.selected_district = null

      this.inputChangeProfile.registrant_subdistrict = null
      this.selected_subdistrict = null

      this.inputChangeProfile.registrant_zip_code = null
      this.zip_code = null

      this.$refs['modal-cities'].hide()
    },
    chooseDistrict(data) {
      this.inputChangeProfile.registrant_district = (data || {}).zip_district
      this.selected_district = (data || {}).zip_district

      this.inputChangeProfile.registrant_subdistrict = null
      this.selected_subdistrict = null

      this.inputChangeProfile.registrant_zip_code = null
      this.zip_code = null

      this.$refs['modal-district'].hide()
    },
    chooseSubDistrict(data) {
      this.inputChangeProfile.registrant_subdistrict = (data || {}).zip_sub_district
      this.selected_subdistrict = (data || {}).zip_sub_district

      this.inputChangeProfile.registrant_zip_code = (data || {}).zip_zipcode
      this.zip_code = (data || {}).zip_zipcode

      this.$refs['modal-sub-district'].hide()
    },
  },
  watch: {
    selected_state(old) {
      this.getCities(old)
    },
    selected_city(old) {
      this.getDistrict(old)
    },

    selected_district(old) {
      this.getSubDistrict(old)
    },
    $route: {
      handler() {
        if (this.$route.name == 'ProfileInformation' || this.$route.name == 'MyNetwork') {
          this.getCountryCode()
        }

        this.getCitizenship()

        if (this.$route.name == 'MailingAddress') {
          this.getCities()
          this.getProvince()
        }
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.view-password {
  top: 29px;
  right: 17px;
  z-index: 5;
  cursor: pointer;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control.main-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
}

.control-group {
  border-top-left-radius: 99px;
  border-bottom-left-radius: 99px;
}

#password,
#confirmPassword {
  padding-right: 41px !important;
}

.profile-mobile {
  .img {
    width: 65px;
    height: 65px;
    background: #fff;
    position: relative;
    border-radius: 50%;
    img {
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .icon-cust {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: #fff;
      width: 20px;
      height: 20px;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }
}

.main-color {
  color: #95378e;
}
</style>

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    countryCodeList: [
      {
        countrycode_id: 99,
        countrycode_name: 'Indonesia',
        countrycode_dial_code: '+62',
        countrycode_code: 'ID',
        countrycode_status: 'Y',
      }
    ],
    terms: {
      title: '',
      description: '',
    },
    privacy: {
      title: '',
      description: '',
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_COUNTRY_CODE_LIST(state, data) {
      state.countryCodeList = data.data
    },
    SET_REGISTER_CONTENT(state, data) {
      state.terms = data.terms
      state.privacy = data.privacy
    }
  },

  actions: {
    async getCountryCodeList({ commit, rootState }, data) {
      const { search, loading = true, callback = false } = data

      try {
        if (loading) {
          rootState.loading.screen = true
        }

        const resp = await http.get('/register/countries/code-list', {
          headers: Gen.apiHeader(),
          params: {
            search,
          },
        })

        commit('SET_COUNTRY_CODE_LIST', resp.data)
      } catch (error) {
        console.log(error)
      } finally {
        if (loading) {
          rootState.loading.screen = false
        }

        if (callback) {
          callback()
        }
      }
    },

    async getRegisterContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/register`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_REGISTER_CONTENT', res.data)
        
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="row">
            <div class="col-md-12">
              <div class="wrap-search">
                <div class="form-group">
                  <b-input-group class="position-relative">
                    <template #prepend>
                      <div class="wrap-search-icon">
                        <i class="icon-search position-absolute" :class="!isOnlyMobile() ? 'large-breakpoint' : ''"></i>
                      </div>
                    </template>
                    <input
                      id="searchbar"
                      type="search"
                      class="form-control main-control mt-0 b-grey-black"
                      placeholder="Search Transaction ID, Event Name"
                      v-model="filter.search"
                      @input="doSearch"
                    />
                  </b-input-group>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-4">
              <div class="b-grey-black p-4 bdrd-12px">
                <template v-if="dataLength > 0">
                  <div class="row">
                    <div class="col-12 d-flex">
                      <div class="tabs capsul">
                        <div class="nav-item">
                          <router-link class="wrap_filter nav-link" :to="{name: 'MyPurchases', params: {savePosition: true}, query: {...$route.query}}">All</router-link>
                        </div>
                      </div>
                      <div class="tabs capsul">
                        <div class="nav-item">
                          <router-link class="wrap_filter nav-link" :to="{name: 'MyPurchasesFilter', params: {pageSlug: 'current', savePosition: true}, query: {...$route.query}}">Current</router-link>
                        </div>
                      </div>
                      <div class="tabs capsul">
                        <div class="nav-item">
                          <router-link class="wrap_filter nav-link" :to="{name: 'MyPurchasesFilter', params: {pageSlug: 'completed', savePosition: true}, query: {...$route.query}}">Completed</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="!isLoading">
                  <template v-if="dataLength > 0">
                    <template v-if="(purchases.data || []).length > 0">
                      <div class="row">
                        <div class="col-12">
                          <PurchaseItem 
                            v-for="(v, k) in purchases.data"
                            :key="'transaction-'+k"
                            :transactionId="v.transaction_id"
                            :price="numFormatter(v.price)"
                            :image="uploader(v.image)"
                            :fallbackImage="uploader(v.fallback_image)"
                            :altImage="v.alt_image"
                            :eventName="v.event_name"
                            :itinerary-name="v.itinerary_name"
                            :package-name="v.package_name"
                            :main-runner-name="v.runner_full_name"
                            :second-runner-name="v.second_runner_full_name"
                            :raceDate="formatDateWithoutWeekday(v.race_date)"
                            :distance="v.distance"
                            :slug="v.transaction_id"
                            :historical="purchasesHistorical.filter(i => i.transaction_id === v.transaction_id)"
                            :transaction-type="v.transaction_type"
                            :base-status="v.base_status"
                            :num-of-payment="ordinal(v.num_of_payment)"
                            :is-allow="v.is_allow"
                            :is-mobile="isOnlyMobile()"
                            :is-webp="isWebPSupport"
                            :program="v.event_program_name"
                            :country="v.event_program_country"
                            :order-date="formatDateWithoutWeekday(v.order_date)"
                            @delete="onDeletePurchase(v.transaction_id)"
                            :is-delete="false"
                          />
                          <div class="wrap-pagination mt-3 d-flex justify-content-end">
                            <b-pagination
                              v-model="purchases.current_page"
                              v-if="!isLoading && purchases.data && purchases.last_page > 1" 
                              :total-rows="purchases.total" 
                              :per-page="purchases.per_page" 
                              prev-text="Prev" 
                              next-text="Next"
                              @change="doPaginate"
                              :hide-goto-end-buttons="true"
                              pills
                            ></b-pagination>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="row">
                        <div class="col-12">
                          <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                            <div class="initial-content d-flex flex-column align-items-center">
                              <div class="wrapper-illustration w-50 d-flex justify-content-center">
                                <img :src="`${baseUrl}${content.empty_image}`" alt="purchase" class="w-25" />
                              </div>
                              <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">{{ content.empty_title }}</h2>
                              <span>{{ content.empty_description }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="row">
                      <div class="col-12">
                        <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                          <div class="initial-content d-flex flex-column align-items-center">
                            <div class="wrapper-illustration w-50 d-flex justify-content-center">
                              <img :src="`${baseUrl}${content.initial_image}`" alt="" class="w-50" />
                            </div>
                            <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">{{ content.initial_title }}</h2>
                            <span>{{ content.initial_description }}</span>
                            <router-link :to="{ name: 'RunningEvents' }" class="btn btn-primary mt-3"
                              >{{ content.initial_cta }}</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="row justify-content-center">
                    <div class="col-12 mt-4 text-center">
                      <PulseLoader :loading="true" color="#333" size="14px" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <div class="b-grey-black px-4 py-4 bdrd-12px">
              <div class="d-flex mb-3">
                <i class="icon-help-circle"></i>
                <h5 class="bold ml-2">Help Center</h5>
              </div>
              <div class="wrap-help-nav mb-3">
                <router-link :to="{ name: 'FAQ' }" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">FAQ</span>
                      <p class="d-block">
                        Don't know where to turn? Our FAQ section is here to help with any questions.
                      </p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="wrap-help-nav mb-3">
                <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">Chat Whatsapp</span>
                      <p class="d-block">Get in touch with us via WhatsApp for fast and easy communication.</p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </a>
              </div>

              <div class="wrap-help-nav">
                <router-link :to="{ name: 'ContactUs' }" class="text-dark-custom">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="wrap-text pr-4">
                      <span class="bold d-block">Send a Message</span>
                      <p class="d-block">
                        Send us a message via our contact form and we'll respond as soon as possible.
                      </p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #page-title>
      <div class="d-flex flex-column justify-content-center">
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">My Purchases</h2>
      </div>
    </template>

    <template #content-mobile>
      <div class="row">
        <div class="col-md-12 mb-4">
          <div class="wrap-search">
            <div class="form-group">
              <b-input-group class="position-relative">
                <template #prepend>
                  <div class="wrap-search-icon">
                    <i class="icon-search position-absolute"></i>
                  </div>
                </template>
                <input
                  id="searchbar"
                  type="search"
                  class="form-control main-control"
                  placeholder="Search Transaction ID, Event Name"
                  v-model="filter.search"
                  @input="doSearch"
                />
              </b-input-group>
            </div>
          </div>
          
        </div>
        <div class="col-md-12">
          <template v-if="dataLength > 0">
            <div class="row">
              <div class="col-12 d-flex">
                <div class="tabs capsul">
                  <div class="nav-item">
                    <router-link class="wrap_filter nav-link" :to="{name: 'MyPurchases', params: {savePosition: true}, query: {...$route.query}}">All</router-link>
                  </div>
                </div>
                <div class="tabs capsul">
                  <div class="nav-item">
                    <router-link class="wrap_filter nav-link" :to="{name: 'MyPurchasesFilter', params: {pageSlug: 'current', savePosition: true}, query: {...$route.query}}">Current</router-link>
                  </div>
                </div>
                <div class="tabs capsul">
                  <div class="nav-item">
                    <router-link class="wrap_filter nav-link" :to="{name: 'MyPurchasesFilter', params: {pageSlug: 'completed', savePosition: true}, query: {...$route.query}}">Completed</router-link>
                  </div>
                </div>
              </div>
            </div>
          </template>
            
          <template v-if="!isLoading">
            <template v-if="dataLength > 0">
              <template v-if="(purchases.data || []).length > 0">
                <div class="row">
                  <div class="col-12">
                    <PurchaseItem 
                      v-for="(v, k) in purchases.data"
                      :key="'transaction-'+k"
                      :transactionId="v.transaction_id"
                      :price="numFormatter(v.price)"
                      :image="uploader(v.image)"
                      :fallbackImage="uploader(v.fallback_image)"
                      :altImage="v.alt_image"
                      :eventName="v.event_name"
                      :itinerary-name="v.itinerary_name"
                      :package-name="v.package_name"
                      :main-runner-name="v.runner_full_name"
                      :second-runner-name="v.second_runner_full_name"
                      :raceDate="formatDateWithoutWeekday(v.race_date)"
                      :distance="v.distance"
                      :slug="v.transaction_id"
                      :historical="purchasesHistorical.filter(i => i.transaction_id === v.transaction_id)"
                      :transaction-type="v.transaction_type"
                      :base-status="v.base_status"
                      :num-of-payment="ordinal(v.num_of_payment)"
                      :is-allow="v.is_allow"
                      :is-mobile="isOnlyMobile()"
                      :is-webp="isWebPSupport"
                      :program="v.event_program_name"
                      :country="v.event_program_country"
                      :order-date="formatDateWithoutWeekday(v.order_date)"
                      @delete="onDeletePurchase(v.transaction_id)"
                      :is-delete="false"
                    />
                    <div class="wrap-pagination mt-3 d-flex justify-content-end">
                      <b-pagination
                        v-model="purchases.current_page"
                        v-if="!isLoading && purchases.data && purchases.last_page > 1" 
                        :total-rows="purchases.total" 
                        :per-page="purchases.per_page" 
                        prev-text="Prev" 
                        next-text="Next"
                        @change="doPaginate"
                        :hide-goto-end-buttons="true"
                        pills
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-12">
                    <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                      <div class="initial-content d-flex flex-column align-items-center">
                        <div class="wrapper-illustration w-50 d-flex justify-content-center">
                          <img :src="`${baseUrl}${content.empty_image}`" alt="" class="w-25" />
                        </div>
                        <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">{{ content.empty_title }}</h2>
                        <span>{{ content.empty_description }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12">
                  <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                    <div class="initial-content d-flex flex-column align-items-center">
                      <div class="wrapper-illustration w-50 d-flex justify-content-center">
                        <img :src="`${baseUrl}${content.initial_image}`" alt="" class="w-25" />
                      </div>
                      <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">{{ content.initial_title }}</h2>
                      <span>{{ content.initial_description }}</span>
                      <router-link :to="{ name: 'RunningEvents' }" class="btn btn-primary mt-3"
                        >{{ content.initial_cta }}</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="row justify-content-center">
              <div class="col-12 mt-4 text-center">
                <PulseLoader :loading="true" color="#333" size="14px" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template #custom-layout>
      <AppModal :withPageTitle="false" ref="modal-confirm-delete" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}${content.confirmation_image}`" alt="" class="w-25" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">{{ content.confirmation_title }}</p>
                <span class="regular fs-1em">{{ content.confirmation_description }}</span>
                <div class="col-md-12 d-flex justify-content-center mt-4">
                  <button type="button" @click="hideModal('modal-confirm-delete')" class="btn btn-outline-main ml-0">
                    {{ content.confirmation_cancel }}
                  </button>
                  <button
                    type="button"
                    @click.prevent="submitDeletePurchase(deleteTransactionId)"
                    class="btn btn-primary"
                    :class="isOnlyMobile() ? 'w-100' : ''"
                  >
                    {{ content.confirmation_ok }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>

      <AppAlert
        ref="modal-success-delete"
        vref="modal-success-delete"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}${content.success_image}`,
        }"
        :title="content.success_title"
        :description="content.success_description"
        :button="{
          main: {
            text: content.success_ok,
          },
        }"
      />
    </template>
  </LayoutPrivatePage>
</template>

<script>
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import Global from '@/libs/Global'
import AppModal from '@/components/AppModal'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import AppAlert from '@/components/AppAlert'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import PurchaseItem from '@/views/atoms/dashboard/PurchaseItem.vue'

export default {
  extends: Global,
  name: 'MyPurchases',
  components: {
    LayoutPrivatePage,
    AppModal,
    PurchaseItem,
    AppAlert,
    PulseLoader,
  },
  data() {
    return {
      isLoading: false,
      isWebPSupport: false,
      filter: {
        search: undefined,
        page: undefined,
      },
      timeoutId: null,
    }
  },
  created() {
    this.$store.commit('SET_STATIC_SEO', {
      title: 'My Purchases'
    })

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  computed: {
    ...mapFields(['baseUrl', 'channels']),
    ...mapFields('mypurchase', ['purchases', 'purchasesHistorical', 'dataLength', 'content']),
  },
  methods: {
    ...mapActions('mypurchase', ['getMyPurchasesData', 'deletePurchase']),

    onDeletePurchase(id) {
      this.deleteTransactionId = id
      this.$refs['modal-confirm-delete'].show()
    },

    submitDeletePurchase(id) {
      this.deletePurchase({
        transactionId: id,
        callback: (success) => {
          if (success) {
            this.$refs['modal-success-delete'].show()
            this.$refs['modal-confirm-delete'].hide()
            this.getMyPurchasesData({
              search: this.filter.search,
              category: this.$route.params.pageSlug,
              page: this.$route.query.page,
              callback: () => {
                if (this.purchases.currentPage > this.purchases.last_page) {
                  this.$router.push({name: this.$route.name, params: {...this.$route.params} }).catch(() => {})
                }
              }
            })
          }
        }
      })
    },

    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },
    doSearch() {
      if (this.dataLength === 0) {
        return null
      }
      this.isTyping = true
      this.isLoading = true
      this.doDebounce(() => {
        this.isTyping = false

        this.getMyPurchasesData({
          search: this.filter.search,
          category: this.$route.params.pageSlug,
          page: this.$route.query.page,
          loading: false,
          callback: () => {
            this.isLoading = false
          }
        })
      }, 500)
    },

    doPaginate(page = 1) {
      this.$set(this.filter, 'page', page)

      this.$router.push({
        name: this.$route.name, 
        params: {...this.$route.params, savePosition: true}, 
        query: {...this.filter}
      }).catch(() => {})
    },
    
    ordinal(number) {
      let ordinalNumber = '';

      switch(number) {
        case 1:
          ordinalNumber = number + 'st';
          break;

        case 2:
          ordinalNumber = number + 'nd';
          break;

        case 3:
          ordinalNumber = number + 'rd';
          break;

        default:
          ordinalNumber = number + 'th';
          break;
      }

      return ordinalNumber;
    },

    toggleWishlist(e) {
      const selectedEl = e.target
      const changeEl = e.target.closest('.wrap-wish-cta').querySelector('.d-none')

      selectedEl.classList.add('d-none')
      changeEl.classList.remove('d-none')
    },
  },
  watch: {
    '$route.params.pageSlug': {
      handler() {
        this.$set(this.filter, 'page', undefined)
        this.isLoading = true
        this.getMyPurchasesData({
          search: this.filter.search,
          category: this.$route.params.pageSlug,
          page: this.$route.query.page,
          callback: () => {
            this.isLoading = false
            if (this.purchases.currentPage > this.purchases.last_page) {
              this.$router.push({name: this.$route.name, params: {...this.$route.params} }).catch(() => {})
            }
          },
          loading: false
        })
      },
      immediate: true,
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap-capsul {
  .nav.nav-tabs {
    .nav-item {
      margin-bottom: 10px;
    }
  }
}

a.wrap_filter.nav-link.router-link-exact-active.router-link-active {
  background-color: #95378E !important;
  color: #fff !important;
  font-family: "Metropolis-Bold" !important;
}

a.wrap_filter.nav-link.router-link-exact-active.router-link-active:hover {
  color: #fff !important;
}

.tabs.capsul .nav-item a.wrap_filter.nav-link {
  color: rgba(72, 72, 72, 0.8);
}

.tabs.capsul .nav-item a.wrap_filter.nav-link:hover {
  color: rgba(72, 72, 72, 0.8);
}
</style>

<template>
  <div class="navigation-bottom-bar" v-if="childPrivatePage.includes($route.name)">
    <template>
      <router-link :to="{ name: 'Dashboard' }" class="nav-list">
        <i class="icon-home"></i>
        <span class="regular">Dashboard</span>
      </router-link>
      <router-link :to="{ name: 'MyNetwork' }" class="nav-list">
        <i class="icon-list"></i>
        <span class="regular">My Network</span>
      </router-link>
      <router-link :to="{ name: 'MyPurchases' }" class="nav-list">
        <i class="icon-shopping-bag"></i>
        <span class="regular">Purchases</span>
      </router-link>
      <router-link :to="{ name: 'MyReviews' }" class="nav-list">
        <i class="icon-edit"></i>
        <span class="regular">Reviews</span>
      </router-link>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BottomNavigation',
  data() {
    return {
      childPrivatePage: [
        'Dashboard',
        'MyInformation',
        'MyReviews',
        'FormReview',
        'MyWishlist',
        'MyPurchases',
        'MyPurchasesFilter',
        'RDGMastery',
        'ProfileInformation',
        'AccountInformation',
        'ChangeEmail',
        'ChangePassword',
        'MailingAddress',
        'EmergencyContact',
        'MyDocuments',
        'MyNetwork',
      ],

      childMyInformation: [
        'AccountSettings',
        'ProfileInformation',
        'AccountInformation',
        'ChangeEmail',
        'ChangePassword',
        'MailingAddress',
        'MyDocuments',
        'MyNetwork'
      ]
    }
  },
  computed: {
    ...mapGetters(['authenticated']),
  },
}
</script>

<style lang="scss" scoped>
.navigation-bottom-bar {
  background: #fff;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 3px;
  box-shadow: 1px -2px 9px -9px #000;

  .nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #9896a6;

    i {
      font-size: 1.35em;
      margin-bottom: 4px;
    }

    &.router-link-exact-active,
    &.router-link-active {
      i {
        color: #95378e;
      }
      span {
        color: #000000;
        font-family: 'Metropolis-Medium' !important;
      }
    }
    @media only screen and (max-width: 768px) {
      span {
        font-size: 13px !important;
      }
    }
  }
}
</style>

<script>
import Gen from '@/libs/Gen'
import dayjs from 'dayjs'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import AppModal from '@/components/AppModal.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import AppAlert from '@/components/AppAlert'

export default {
  components: {
    AppModal,
    PulseLoader,
    AppAlert,
  },
  data() {
    return {
      isCodeValidEmail: true,
      isCodeValidPhone: true,
      verifEmailInputCode: '',
      verifPhoneInputCode: '',
      isExtraSmallDevice: false,
      isLargeDevice: false,
      deviceType: '',
      selectedMethod: '',
      isLoading: false,
      disabledKirimUlang: false,
      countdownDetik: 0,
      intervalCountdown: null,
      isDirty: false,
      validity: '',
      conf: {
        rule_type: 'jpg,jpeg,png',
        max_image_size: 3000000,
        fileType: '',
      },
      fileData: {
        fileavatar: null,
      },
      showAvatarPreview: false,
      avatarPreview: '',
      minimumAge: 16,
      openDateValue: '',
      errMessage: '',
      inputChangeEmail: '',
      filter: {
        search: '',
      },
      selectVerifyPhoneMethod: '',
      submitInterval: false,
      formData: {
        isChangeEmail: false,
        is_last_name: 'Y',
        changeEmail: '',
        token: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        country_code: 99,
        phone: '',
        method: '',
        citizenship_flag: '',
        citizenship: null,
        gender: '',
        date_of_birth: '',
        age_range: '',
        avatar: '',
        running_distance: '',
        running_frequency: '',
        running_motivation: '',
        marathon_participation: '',
        majors: [],
      },
      showPassword: false,
      showConfirmPassword: false,
      scrollProgress: 0,
      legalStep: 0,
      isChangingEmail: false,
      isSuccessCodeEmail: false,
      isSuccessCodePhone: false,
      isVerifyingEmail: false,
      isVerifyingEmailSuccess: false,
      isVerifyingPhoneInput: false,
      isVerifyingPhoneMethods: false,
      isVerifyingPhoneCode: false,
      isVerifyingPhoneSuccess: false,
      isFillingCitizenship: false,
      isFillingGender: false,
      isFillingBirthday: false,
      isFillingAge: false,
      isUnderAge: false,
      isFillingAvatar: false,
      isFillingBasicSuccess: false,
      isFillingRunningFrequency: false,
      isFillingRunningMotivation: false,
      isFillingMarathonParticipation: false,
      isFillingMajor: false,
      isFillingSurveySuccess: false,
      showSuccessAlertEmail: false,
      showSuccessAlertPhone: false,
      showDangerAlertEmail: false,
      showDangerAlertPhone: false,
      isShowingAlertEmail: false,
      isShowingAlertPhone: false,
      sendNewCodeAttemptEmail: 0,
      sendNewCodeAttemptPhone: 0,
      isSubmitted: false,
      timeoutId: null,
      messageErrTitle: '',
      messageErrDescription: '',
      citizenship_list: [
        {
          citizenship_id: 1,
          citizenship_name: 'Afghanistan',
          citizenship_code: 'AF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 2,
          citizenship_name: 'Åland Islands',
          citizenship_code: 'AX',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 3,
          citizenship_name: 'Albania',
          citizenship_code: 'AL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 4,
          citizenship_name: 'Algeria',
          citizenship_code: 'DZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 5,
          citizenship_name: 'American Samoa',
          citizenship_code: 'AS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 6,
          citizenship_name: 'Andorra',
          citizenship_code: 'AD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 7,
          citizenship_name: 'Angola',
          citizenship_code: 'AO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 8,
          citizenship_name: 'Anguilla',
          citizenship_code: 'AI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 9,
          citizenship_name: 'Antarctica',
          citizenship_code: 'AQ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 10,
          citizenship_name: 'Antigua and Barbuda',
          citizenship_code: 'AG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 11,
          citizenship_name: 'Argentina',
          citizenship_code: 'AR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 12,
          citizenship_name: 'Armenia',
          citizenship_code: 'AM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 13,
          citizenship_name: 'Aruba',
          citizenship_code: 'AW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 14,
          citizenship_name: 'Australia',
          citizenship_code: 'AU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 15,
          citizenship_name: 'Austria',
          citizenship_code: 'AT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 16,
          citizenship_name: 'Azerbaijan',
          citizenship_code: 'AZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 17,
          citizenship_name: 'Bahamas',
          citizenship_code: 'BS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 18,
          citizenship_name: 'Bahrain',
          citizenship_code: 'BH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 19,
          citizenship_name: 'Bangladesh',
          citizenship_code: 'BD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 20,
          citizenship_name: 'Barbados',
          citizenship_code: 'BB',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 21,
          citizenship_name: 'Belarus',
          citizenship_code: 'BY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 22,
          citizenship_name: 'Belgium',
          citizenship_code: 'BE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 23,
          citizenship_name: 'Belize',
          citizenship_code: 'BZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 24,
          citizenship_name: 'Benin',
          citizenship_code: 'BJ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 25,
          citizenship_name: 'Bermuda',
          citizenship_code: 'BM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 26,
          citizenship_name: 'Bhutan',
          citizenship_code: 'BT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 27,
          citizenship_name: 'Bolivia',
          citizenship_code: 'BO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 28,
          citizenship_name: 'Bosnia and Herzegovina',
          citizenship_code: 'BA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 29,
          citizenship_name: 'Botswana',
          citizenship_code: 'BW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 30,
          citizenship_name: 'Bouvet Island',
          citizenship_code: 'BV',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 31,
          citizenship_name: 'Brazil',
          citizenship_code: 'BR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 32,
          citizenship_name: 'British Indian Ocean Territory',
          citizenship_code: 'IO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 33,
          citizenship_name: 'Brunei Darussalam',
          citizenship_code: 'BN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 34,
          citizenship_name: 'Bulgaria',
          citizenship_code: 'BG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 35,
          citizenship_name: 'Burkina Faso',
          citizenship_code: 'BF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 36,
          citizenship_name: 'Burundi',
          citizenship_code: 'BI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 37,
          citizenship_name: 'Cambodia',
          citizenship_code: 'KH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 38,
          citizenship_name: 'Cameroon',
          citizenship_code: 'CM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 39,
          citizenship_name: 'Canada',
          citizenship_code: 'CA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 40,
          citizenship_name: 'Cape Verde',
          citizenship_code: 'CV',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 41,
          citizenship_name: 'Cayman Islands',
          citizenship_code: 'KY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 42,
          citizenship_name: 'Central African Republic',
          citizenship_code: 'CF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 43,
          citizenship_name: 'Chad',
          citizenship_code: 'TD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 44,
          citizenship_name: 'Chile',
          citizenship_code: 'CL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 45,
          citizenship_name: 'China',
          citizenship_code: 'CN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 46,
          citizenship_name: 'Christmas Island',
          citizenship_code: 'CX',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 47,
          citizenship_name: 'Cocos (Keeling) Islands',
          citizenship_code: 'CC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 48,
          citizenship_name: 'Colombia',
          citizenship_code: 'CO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 49,
          citizenship_name: 'Comoros',
          citizenship_code: 'KM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 50,
          citizenship_name: 'Congo',
          citizenship_code: 'CG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 51,
          citizenship_name: 'Congo, The Democratic Republic of the',
          citizenship_code: 'CD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 52,
          citizenship_name: 'Cook Islands',
          citizenship_code: 'CK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 53,
          citizenship_name: 'Costa Rica',
          citizenship_code: 'CR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 54,
          citizenship_name: "Cote D'Ivoire",
          citizenship_code: 'CI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 55,
          citizenship_name: 'Croatia',
          citizenship_code: 'HR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 56,
          citizenship_name: 'Cuba',
          citizenship_code: 'CU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 57,
          citizenship_name: 'Cyprus',
          citizenship_code: 'CY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 58,
          citizenship_name: 'Czech Republic',
          citizenship_code: 'CZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 59,
          citizenship_name: 'Denmark',
          citizenship_code: 'DK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 60,
          citizenship_name: 'Djibouti',
          citizenship_code: 'DJ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 61,
          citizenship_name: 'Dominica',
          citizenship_code: 'DM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 62,
          citizenship_name: 'Dominican Republic',
          citizenship_code: 'DO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 63,
          citizenship_name: 'Ecuador',
          citizenship_code: 'EC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 64,
          citizenship_name: 'Egypt',
          citizenship_code: 'EG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 65,
          citizenship_name: 'El Salvador',
          citizenship_code: 'SV',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 66,
          citizenship_name: 'Equatorial Guinea',
          citizenship_code: 'GQ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 67,
          citizenship_name: 'Eritrea',
          citizenship_code: 'ER',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 68,
          citizenship_name: 'Estonia',
          citizenship_code: 'EE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 69,
          citizenship_name: 'Ethiopia',
          citizenship_code: 'ET',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 70,
          citizenship_name: 'Falkland Islands (Malvinas)',
          citizenship_code: 'FK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 71,
          citizenship_name: 'Faroe Islands',
          citizenship_code: 'FO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 72,
          citizenship_name: 'Fiji',
          citizenship_code: 'FJ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 73,
          citizenship_name: 'Finland',
          citizenship_code: 'FI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 74,
          citizenship_name: 'France',
          citizenship_code: 'FR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 75,
          citizenship_name: 'French Guiana',
          citizenship_code: 'GF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 76,
          citizenship_name: 'French Polynesia',
          citizenship_code: 'PF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 77,
          citizenship_name: 'French Southern Territories',
          citizenship_code: 'TF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 78,
          citizenship_name: 'Gabon',
          citizenship_code: 'GA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 79,
          citizenship_name: 'Gambia',
          citizenship_code: 'GM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 80,
          citizenship_name: 'Georgia',
          citizenship_code: 'GE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 81,
          citizenship_name: 'Germany',
          citizenship_code: 'DE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 82,
          citizenship_name: 'Ghana',
          citizenship_code: 'GH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 83,
          citizenship_name: 'Gibraltar',
          citizenship_code: 'GI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 84,
          citizenship_name: 'Greece',
          citizenship_code: 'GR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 85,
          citizenship_name: 'Greenland',
          citizenship_code: 'GL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 86,
          citizenship_name: 'Grenada',
          citizenship_code: 'GD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 87,
          citizenship_name: 'Guadeloupe',
          citizenship_code: 'GP',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 88,
          citizenship_name: 'Guam',
          citizenship_code: 'GU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 89,
          citizenship_name: 'Guatemala',
          citizenship_code: 'GT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 90,
          citizenship_name: 'Guernsey',
          citizenship_code: 'GG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 91,
          citizenship_name: 'Guinea',
          citizenship_code: 'GN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 92,
          citizenship_name: 'Guinea-Bissau',
          citizenship_code: 'GW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 93,
          citizenship_name: 'Guyana',
          citizenship_code: 'GY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 94,
          citizenship_name: 'Haiti',
          citizenship_code: 'HT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 95,
          citizenship_name: 'Heard Island and Mcdonald Islands',
          citizenship_code: 'HM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 96,
          citizenship_name: 'Holy See (Vatican City State)',
          citizenship_code: 'VA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 97,
          citizenship_name: 'Honduras',
          citizenship_code: 'HN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 98,
          citizenship_name: 'Hong Kong',
          citizenship_code: 'HK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 99,
          citizenship_name: 'Hungary',
          citizenship_code: 'HU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 100,
          citizenship_name: 'Iceland',
          citizenship_code: 'IS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 101,
          citizenship_name: 'India',
          citizenship_code: 'IN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 102,
          citizenship_name: 'Indonesia',
          citizenship_code: 'ID',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 103,
          citizenship_name: 'Iran, Islamic Republic Of',
          citizenship_code: 'IR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 104,
          citizenship_name: 'Iraq',
          citizenship_code: 'IQ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 105,
          citizenship_name: 'Ireland',
          citizenship_code: 'IE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 106,
          citizenship_name: 'Isle of Man',
          citizenship_code: 'IM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 107,
          citizenship_name: 'Israel',
          citizenship_code: 'IL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 108,
          citizenship_name: 'Italy',
          citizenship_code: 'IT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 109,
          citizenship_name: 'Jamaica',
          citizenship_code: 'JM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 110,
          citizenship_name: 'Japan',
          citizenship_code: 'JP',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 111,
          citizenship_name: 'Jersey',
          citizenship_code: 'JE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 112,
          citizenship_name: 'Jordan',
          citizenship_code: 'JO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 113,
          citizenship_name: 'Kazakhstan',
          citizenship_code: 'KZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 114,
          citizenship_name: 'Kenya',
          citizenship_code: 'KE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 115,
          citizenship_name: 'Kiribati',
          citizenship_code: 'KI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 116,
          citizenship_name: "Korea, Democratic People'S Republic of",
          citizenship_code: 'KP',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 117,
          citizenship_name: 'Korea, Republic of',
          citizenship_code: 'KR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 118,
          citizenship_name: 'Kuwait',
          citizenship_code: 'KW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 119,
          citizenship_name: 'Kyrgyzstan',
          citizenship_code: 'KG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 120,
          citizenship_name: "Lao People'S Democratic Republic",
          citizenship_code: 'LA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 121,
          citizenship_name: 'Latvia',
          citizenship_code: 'LV',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 122,
          citizenship_name: 'Lebanon',
          citizenship_code: 'LB',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 123,
          citizenship_name: 'Lesotho',
          citizenship_code: 'LS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 124,
          citizenship_name: 'Liberia',
          citizenship_code: 'LR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 125,
          citizenship_name: 'Libyan Arab Jamahiriya',
          citizenship_code: 'LY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 126,
          citizenship_name: 'Liechtenstein',
          citizenship_code: 'LI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 127,
          citizenship_name: 'Lithuania',
          citizenship_code: 'LT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 128,
          citizenship_name: 'Luxembourg',
          citizenship_code: 'LU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 129,
          citizenship_name: 'Macao',
          citizenship_code: 'MO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 130,
          citizenship_name: 'Macedonia, The Former Yugoslav Republic of',
          citizenship_code: 'MK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 131,
          citizenship_name: 'Madagascar',
          citizenship_code: 'MG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 132,
          citizenship_name: 'Malawi',
          citizenship_code: 'MW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 133,
          citizenship_name: 'Malaysia',
          citizenship_code: 'MY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 134,
          citizenship_name: 'Maldives',
          citizenship_code: 'MV',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 135,
          citizenship_name: 'Mali',
          citizenship_code: 'ML',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 136,
          citizenship_name: 'Malta',
          citizenship_code: 'MT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 137,
          citizenship_name: 'Marshall Islands',
          citizenship_code: 'MH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 138,
          citizenship_name: 'Martinique',
          citizenship_code: 'MQ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 139,
          citizenship_name: 'Mauritania',
          citizenship_code: 'MR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 140,
          citizenship_name: 'Mauritius',
          citizenship_code: 'MU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 141,
          citizenship_name: 'Mayotte',
          citizenship_code: 'YT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 142,
          citizenship_name: 'Mexico',
          citizenship_code: 'MX',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 143,
          citizenship_name: 'Micronesia, Federated States of',
          citizenship_code: 'FM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 144,
          citizenship_name: 'Moldova, Republic of',
          citizenship_code: 'MD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 145,
          citizenship_name: 'Monaco',
          citizenship_code: 'MC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 146,
          citizenship_name: 'Mongolia',
          citizenship_code: 'MN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 147,
          citizenship_name: 'Montserrat',
          citizenship_code: 'MS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 148,
          citizenship_name: 'Morocco',
          citizenship_code: 'MA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 149,
          citizenship_name: 'Mozambique',
          citizenship_code: 'MZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 150,
          citizenship_name: 'Myanmar',
          citizenship_code: 'MM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 151,
          citizenship_name: 'Namibia',
          citizenship_code: 'NA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 152,
          citizenship_name: 'Nauru',
          citizenship_code: 'NR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 153,
          citizenship_name: 'Nepal',
          citizenship_code: 'NP',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 154,
          citizenship_name: 'Netherlands',
          citizenship_code: 'NL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 155,
          citizenship_name: 'Netherlands Antilles',
          citizenship_code: 'AN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 156,
          citizenship_name: 'New Caledonia',
          citizenship_code: 'NC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 157,
          citizenship_name: 'New Zealand',
          citizenship_code: 'NZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 158,
          citizenship_name: 'Nicaragua',
          citizenship_code: 'NI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 159,
          citizenship_name: 'Niger',
          citizenship_code: 'NE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 160,
          citizenship_name: 'Nigeria',
          citizenship_code: 'NG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 161,
          citizenship_name: 'Niue',
          citizenship_code: 'NU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 162,
          citizenship_name: 'Norfolk Island',
          citizenship_code: 'NF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 163,
          citizenship_name: 'Northern Mariana Islands',
          citizenship_code: 'MP',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 164,
          citizenship_name: 'Norway',
          citizenship_code: 'NO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 165,
          citizenship_name: 'Oman',
          citizenship_code: 'OM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 166,
          citizenship_name: 'Pakistan',
          citizenship_code: 'PK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 167,
          citizenship_name: 'Palau',
          citizenship_code: 'PW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 168,
          citizenship_name: 'Palestinian Territory, Occupied',
          citizenship_code: 'PS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 169,
          citizenship_name: 'Panama',
          citizenship_code: 'PA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 170,
          citizenship_name: 'Papua New Guinea',
          citizenship_code: 'PG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 171,
          citizenship_name: 'Paraguay',
          citizenship_code: 'PY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 172,
          citizenship_name: 'Peru',
          citizenship_code: 'PE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 173,
          citizenship_name: 'Philippines',
          citizenship_code: 'PH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 174,
          citizenship_name: 'Pitcairn',
          citizenship_code: 'PN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 175,
          citizenship_name: 'Poland',
          citizenship_code: 'PL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 176,
          citizenship_name: 'Portugal',
          citizenship_code: 'PT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 177,
          citizenship_name: 'Puerto Rico',
          citizenship_code: 'PR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 178,
          citizenship_name: 'Qatar',
          citizenship_code: 'QA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 179,
          citizenship_name: 'Reunion',
          citizenship_code: 'RE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 180,
          citizenship_name: 'Romania',
          citizenship_code: 'RO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 181,
          citizenship_name: 'Russian Federation',
          citizenship_code: 'RU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 182,
          citizenship_name: 'RWANDA',
          citizenship_code: 'RW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 183,
          citizenship_name: 'Saint Helena',
          citizenship_code: 'SH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 184,
          citizenship_name: 'Saint Kitts and Nevis',
          citizenship_code: 'KN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 185,
          citizenship_name: 'Saint Lucia',
          citizenship_code: 'LC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 186,
          citizenship_name: 'Saint Pierre and Miquelon',
          citizenship_code: 'PM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 187,
          citizenship_name: 'Saint Vincent and the Grenadines',
          citizenship_code: 'VC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 188,
          citizenship_name: 'Samoa',
          citizenship_code: 'WS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 189,
          citizenship_name: 'San Marino',
          citizenship_code: 'SM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 190,
          citizenship_name: 'Sao Tome and Principe',
          citizenship_code: 'ST',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 191,
          citizenship_name: 'Saudi Arabia',
          citizenship_code: 'SA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 192,
          citizenship_name: 'Senegal',
          citizenship_code: 'SN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 193,
          citizenship_name: 'Serbia and Montenegro',
          citizenship_code: 'CS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 194,
          citizenship_name: 'Seychelles',
          citizenship_code: 'SC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 195,
          citizenship_name: 'Sierra Leone',
          citizenship_code: 'SL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 196,
          citizenship_name: 'Singapore',
          citizenship_code: 'SG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 197,
          citizenship_name: 'Slovakia',
          citizenship_code: 'SK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 198,
          citizenship_name: 'Slovenia',
          citizenship_code: 'SI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 199,
          citizenship_name: 'Solomon Islands',
          citizenship_code: 'SB',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 200,
          citizenship_name: 'Somalia',
          citizenship_code: 'SO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 201,
          citizenship_name: 'South Africa',
          citizenship_code: 'ZA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 202,
          citizenship_name: 'South Georgia and the South Sandwich Islands',
          citizenship_code: 'GS',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 203,
          citizenship_name: 'Spain',
          citizenship_code: 'ES',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 204,
          citizenship_name: 'Sri Lanka',
          citizenship_code: 'LK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 205,
          citizenship_name: 'Sudan',
          citizenship_code: 'SD',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 206,
          citizenship_name: 'Suriname',
          citizenship_code: 'SR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 207,
          citizenship_name: 'Svalbard and Jan Mayen',
          citizenship_code: 'SJ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 208,
          citizenship_name: 'Swaziland',
          citizenship_code: 'SZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 209,
          citizenship_name: 'Sweden',
          citizenship_code: 'SE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 210,
          citizenship_name: 'Switzerland',
          citizenship_code: 'CH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 211,
          citizenship_name: 'Syrian Arab Republic',
          citizenship_code: 'SY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 212,
          citizenship_name: 'Taiwan, Province of China',
          citizenship_code: 'TW',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 213,
          citizenship_name: 'Tajikistan',
          citizenship_code: 'TJ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 214,
          citizenship_name: 'Tanzania, United Republic of',
          citizenship_code: 'TZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 215,
          citizenship_name: 'Thailand',
          citizenship_code: 'TH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 216,
          citizenship_name: 'Timor-Leste',
          citizenship_code: 'TL',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 217,
          citizenship_name: 'Togo',
          citizenship_code: 'TG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 218,
          citizenship_name: 'Tokelau',
          citizenship_code: 'TK',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 219,
          citizenship_name: 'Tonga',
          citizenship_code: 'TO',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 220,
          citizenship_name: 'Trinidad and Tobago',
          citizenship_code: 'TT',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 221,
          citizenship_name: 'Tunisia',
          citizenship_code: 'TN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 222,
          citizenship_name: 'Turkey',
          citizenship_code: 'TR',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 223,
          citizenship_name: 'Turkmenistan',
          citizenship_code: 'TM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 224,
          citizenship_name: 'Turks and Caicos Islands',
          citizenship_code: 'TC',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 225,
          citizenship_name: 'Tuvalu',
          citizenship_code: 'TV',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 226,
          citizenship_name: 'Uganda',
          citizenship_code: 'UG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 227,
          citizenship_name: 'Ukraine',
          citizenship_code: 'UA',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 228,
          citizenship_name: 'United Arab Emirates',
          citizenship_code: 'AE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 229,
          citizenship_name: 'United Kingdom',
          citizenship_code: 'GB',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 230,
          citizenship_name: 'United States',
          citizenship_code: 'US',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 231,
          citizenship_name: 'United States Minor Outlying Islands',
          citizenship_code: 'UM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 232,
          citizenship_name: 'Uruguay',
          citizenship_code: 'UY',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 233,
          citizenship_name: 'Uzbekistan',
          citizenship_code: 'UZ',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 234,
          citizenship_name: 'Vanuatu',
          citizenship_code: 'VU',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 235,
          citizenship_name: 'Venezuela',
          citizenship_code: 'VE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 236,
          citizenship_name: 'Viet Nam',
          citizenship_code: 'VN',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 237,
          citizenship_name: 'Virgin Islands, British',
          citizenship_code: 'VG',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 238,
          citizenship_name: 'Virgin Islands, U.S.',
          citizenship_code: 'VI',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 239,
          citizenship_name: 'Wallis and Futuna',
          citizenship_code: 'WF',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 240,
          citizenship_name: 'Western Sahara',
          citizenship_code: 'EH',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 241,
          citizenship_name: 'Yemen',
          citizenship_code: 'YE',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 242,
          citizenship_name: 'Zambia',
          citizenship_code: 'ZM',
          citizenship_status: 'Y',
        },
        {
          citizenship_id: 243,
          citizenship_name: 'Zimbabwe',
          citizenship_code: 'ZW',
          citizenship_status: 'Y',
        },
      ],
    }
  },
  computed: {
    ...mapFields(['apiUrl', 'baseUrl', 'channels', 'loading']),
    ...mapFields('register', ['countryCodeList', 'terms', 'privacy']),
    minimumAgePage() {
      const minimumdate = dayjs().subtract(this.minimumAge, 'year')

      return {
        month: minimumdate.format('MM'),
        year: minimumdate.format('YYYY'),
      }
    },
    ccode() {
      return (this.countryCodeList.find((i) => i.countrycode_id === this.formData.country_code) || {})
        .countrycode_dial_code
    },
    isLocked() {
      return this.scrollProgress < 100
    },
  },

  created() {
    if (Gen.getCookie('tnc') == '1') {
      this.tnc = true
    }

    const screenWidth = window.innerWidth

    if (screenWidth < 546) {
      this.isExtraSmallDevice = true
    }

    if (screenWidth < 768) {
      this.deviceType = 'mobile'
    } else if (screenWidth < 1025) {
      this.deviceType = 'tablet'

      if (screenWidth >= 992) {
        this.isLargeDevice = true
      }
    } else {
      this.deviceType = 'desktop'

      this.isLargeDevice = true
    }
  },

  methods: {
    ...mapActions('profile', ['getProfile']),
    ...mapActions('login', ['getLoginContent']),
    ...mapActions('register', ['getCountryCodeList', 'getRegisterContent']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions(['getChannels']),

       formatDate(date) {
      if (!date) {
        return
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        const usd = date.split('/')

        const formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1])

        return formatted.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1

      // kalo formatnya 2022-01-01 15:00:00 di ubah jadi 2022-01-01T15:00:00
      const d = !isParsed ? date.replace(/ /g, 'T') : date

      return new Date(d).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },

    formatTime(time) {
      let date = new Date(parseInt(time));
      return date.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute:'2-digit'
      });
    },

    formatDateWithoutWeekday(date) {
      if (!date) {
        return
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        const usd = date.split('/')

        const formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1])

        return formatted.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1

      const d = !isParsed ? date.replace(/ /g, 'T') : date

      return new Date(d).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },

    formatDateWithHour(date) {
      if (!date) {
        return;
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        let usd = date.split('/');
        
        let formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1]);

        return formatted.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1;

      const d = !isParsed ? date.replace(/ /g, 'T') : date;

      return new Date(d).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },

    completingProcess() {
      return [
        this.isVerifyingEmail,
        this.isChangingEmail,
        this.isVerifyingEmailSuccess,
        this.isVerifyingPhoneInput,
        this.isVerifyingPhoneMethods,
        this.isVerifyingPhoneCode,
        this.isVerifyingPhoneSuccess,
        this.isFillingCitizenship,
        this.isFillingGender,
        this.isFillingBirthday,
        this.isFillingAge,
        this.isUnderAge,
        this.isFillingAvatar,
        this.isFillingBasicSuccess
      ].indexOf(true) > -1
    },

    onChangedHandlerEmail(lastEnteredCode) {
      try {
        this.isCodeValid = true
      } catch (error) {
        return error
      }
    },

    onPasteHandlerEmail(code) {
      // code pasted
    },

    onChangedHandlerPhone(lastEnteredCode) {
      try {
        this.isCodeValid = true
      } catch (error) {
        return error
      }
    },

    onPasteHandlerPhone(code) {
      // code pasted
    },

    async onVerifyEmail(code) {
      if ((code || '').length < 5) {
        return null
      }

      this.isSubmitted = true

      try {
        const resp = await Gen.apiRest('/register/email/verify', { data: { code, email: this.formData.email } }, 'POST')

        this.isCodeValidEmail = true
        this.isSuccessCodeEmail = true
        setTimeout(() => {
          this.$store.state.loading.screen = true
          setTimeout(() => {
            this.isSuccessCodeEmail = false
            this.$store.state.loading.screen = false
            this.isVerifyingEmailSuccess = true
            this.isVerifyingEmail = false
          }, 1000)
        }, 500)
      } catch (error) {
        this.isSubmitted = false

        if (error.response.status == 400) {
          this.doSetAlertDanger((error.response.data || {}).message, 'showDangerAlertEmail')
        }
      }
    },

    async onVerifyPhone(code) {
      if ((code || '').length < 5) {
        return null
      }

      this.isSubmitted = true

      try {
        const resp = await Gen.apiRest('/register/phone/verify', { data: { ...this.formData, code } }, 'POST')

        this.isCodeValidPhone = true
        setTimeout(() => {
          this.$store.state.loading.screen = true
          setTimeout(() => {
            this.isSubmitted = false
            this.$store.state.loading.screen = false
            this.isVerifyingPhoneSuccess = true
            this.isVerifyingPhoneCode = false
          }, 1000)
        }, 500)
      } catch (error) {
        if (error.response.status == 400) {
          this.doSetAlertDanger((error.response.data || {}).message, 'showDangerAlertPhone')
        }
      }
    },

    isTablet() {
      if (this.deviceType == 'tablet') {
        return true
      }
      return false
    },

    isOnlyMobile() {
      if (this.deviceType == 'mobile') {
        return true
      }
      return false
    },

    isMobile() {
      if (this.deviceType == 'mobile' || this.deviceType == 'tablet') {
        return true
      }
      return false
    },

    uploader(filename) {
      if (!filename) {
        return ''
      }
      return `${this.apiUrl}/uploader/${filename}`
    },

    checkIsLastName(isLastName) {
      if (isLastName == 'Y') {
        this.formData.last_name = this.formData.first_name
      } else {
        this.formData.last_name = ''
      }
    },

    /**
     * Scroll the `window` to the top
     * using `requestAnimationFrame`
     * for a smooth scroll.
     *
     * It works recursively.
     */
    scrollToTop() {
      const scrollPosition = document.documentElement.scrollTop

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - scrollPosition / 8)
      }
    },

    scrollToTopSelector(qs) {
      const scrollPosition = document.querySelector(qs).scrollTop

      if (scrollPosition === undefined) {
        return null
      }

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - scrollPosition / 8)
      }
    },

    showSearchModal(modalId, inputId) {
      this.$refs[modalId].show()
      document.querySelector(`#${inputId} .vs__search`).blur()
      this.scrollToTopSelector('.second-window .content')
    },

    async pickFile(e, dataToBeBound, id) {
      this.$refs[id].validate(e)

      if (e.target.value === '') {
        return
      }

      const input = this.$refs[id]
      const file = input.value
      if (file && file[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.addEventListener(
          'load',
          function () {
            document.getElementById('avatarPreviewImage').src = reader.result
          },
          false
        )
      }

      this.fileData[dataToBeBound] = e.target.files[0]

      this.conf.fileType = (this.fileData[dataToBeBound] || {}).type

      this.fileType = ((this.fileData[dataToBeBound] || {}).name || '')
        .substr(((this.fileData[dataToBeBound] || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()

      this.showAvatarPreview = true
    },
    onConfirmCancelVerifyChangeEmail() {
      this.$refs['confirm-data-modal-x'].hide()
    },
    isChecked(value, key) {
      if ((this.formData[key] || []).length === 0) {
        return false
      }

      return (
        this.formData[key].find((i) => {
          return i === value
        }) !== undefined
      )
    },
    toggleSurveyMultipleCheck(value, key) {
      const index = (this.formData[key] || []).findIndex((m) => m === value)

      if (index !== -1) {
        ;(this.formData[key] || []).splice(index, 1)
        return null
      }

      this.formData.majors.push(value)
    },
    async onSendNewCodePhone() {
      if (this.disabledKirimUlang) {
        return null
      }

      if (this.isShowingAlert) {
        return null
      }

      this.isLoading = true

      try {
        const resp = await Gen.apiRest('/register/new-code/phone', { data: { ...this.formData } }, 'POST')

        if (resp.status == 200) {
          this.scrollToTop()
          this.showSuccessAlertPhone = true
          this.isShowingAlert = true

          setTimeout(() => {
            this.showSuccessAlertPhone = false
            this.isShowingAlert = false
          }, 4000)
        }
      } catch (error) {
        return error
      } finally {
        this.isLoading = false
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onSelectedCountryCode(v) {
      this.formData.country_code = v.countrycode_id

      this.$refs['modal-country-code'].hide()

      this.filter.search = ''

      this.doSearch()
    },
    onVerifyPhoneNumberInput() {
      this.$refs['confirm-phone-modal'].show()
    },
    onChangeNumber() {
      this.$refs['confirm-phone-modal'].hide()
    },
    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },
    doSearch() {
      this.isTyping = true
      this.isLoading = true
      this.doDebounce(() => {
        this.isTyping = false

        this.getCountryCodeList({
          search: this.filter.search,
          callback: () => {
            this.isLoading = false
          },
          loading: false,
        })
      }, 500)
    },

    onVerifyChangeEmail() {
      this.verifEmailInputCode = ''
      this.isChangingEmail = true
      this.isVerifyingEmail = false
    },

    onVerifyChangePhone() {
      this.verifPhoneInputCode = ''
      this.isVerifyingPhoneInput = true
      this.isVerifyingPhoneCode = false
    },

    onCancelVerifyChangeEmail() {
      this.inputChangeEmail = ''
      this.isVerifyingEmail = true
      this.isChangingEmail = false
    },

    onRegister() {
      this.$refs.RegisterForm.validate().then((success) => {
        if (success) {
          this.$refs['confirm-email-modal'].show()
        }
      })
    },

    doSetAlertDanger(message, key) {
      this.scrollToTop()
      this.errMessage = message
      this.$set(this, key, true)
      this.isShowingAlert = true

      setTimeout(() => {
        this.$set(this, key, false)
        this.isShowingAlert = false
      }, 4000)
    },

    onChangeEmail() {
      this.$refs['confirm-email-modal'].hide()
    },

    async doRegister(changeEmail = false) {
      try {
        this.$store.state.loading.screen = true

        if (this.$refs['confirm-email-modal'] !== undefined) {
          this.$refs['confirm-email-modal'].hide()
        }

        if (changeEmail !== false) {
          this.formData.isChangeEmail = true
          this.formData.changeEmail = changeEmail
        }

        const resp = await Gen.apiRest('/register', { data: { ...this.formData } }, 'POST')

        if (resp.status == 200) {
          this.formData.token = (resp.data.data || {}).token
          this.formData.email = (resp.data.data || {}).email
          this.validity = (resp.data.data || {}).validity

          this.isDirty = true

          if ((resp.data.data || {}).avatarFilled == 'Y') {
            this.isFillingBasicSuccess = true
            this.scrollToTop()
            return
          } else if ((resp.data.data || {}).birthDayFilled == 'Y') {
            this.isFillingAvatar = true
            this.scrollToTop()
            return
          } else if ((resp.data.data || {}).genderFilled == 'Y') {
            this.isFillingBirthday = true
            this.scrollToTop()
            return
          } else if ((resp.data.data || {}).citizenshipFilled == 'Y') {
            this.isFillingGender = true
            this.scrollToTop()
            return
          } else if ((resp.data.data || {}).phoneVerified == 'Y') {
            this.isFillingCitizenship = true
            this.scrollToTop()
            return
          } else if ((resp.data.data || {}).emailVerified == 'Y') {
            this.isVerifyingPhoneInput = true
            this.scrollToTop()
            return
          }
          this.isVerifyingEmail = true
          this.formData.changeEmail = ''
          this.isChangingEmail = false
        }
      } catch (err) {
        if (changeEmail) {
          this.$refs['confirm-data-modal-x'].hide()
        }
        if ((err.response || {}).status === 400) {
          this.messageErrTitle = err.response.data.message
          this.$refs['modal-error'].show()
        }
      } finally {
        this.$store.state.loading.screen = false
      }
    },

    async onConfirmInputNumber(phone) {
      // this.isVerifyingPhoneMethods = true
      // this.isVerifyingPhoneInput = false
      this.formData.method = 'wa'
      this.onSendPhoneVerificationCode(phone)
    },

    async onSendPhoneVerificationCode(phone) {
      this.formData.phone = phone

      const resp = await Gen.apiRest(
        '/register/phone/verify/send',
        { data: Object.assign({}, this.formData, { dial_code: this.ccode }) },
        'POST'
      )

      if (resp.status == 200) {
        this.formData.phone = (resp.data.data || {}).phone
        this.isVerifyingPhoneCode = true
        this.isVerifyingPhoneInput = false
        console.log('phonecode', this.isVerifyingPhoneCode)
        console.log('phonemethods', this.isVerifyingPhoneInput)
        this.selectedMethod = ((resp.data || {}).data || {}).channel
      }
    },

    handleUnderAgeRedirect() {
      this.$router.push({ name: 'Blog' })
    },
    toggleShowPassword(value, key, elementId) {
      const toggle = !value
      this.$set(this, key, toggle)
      const el = document.getElementById(elementId)
      el.type = toggle ? 'text' : 'password'
    },
    handleActivatedTermsTab(newTabIndex, oldTabIndex, bvEvent) {
      if (this.isLocked) {
        bvEvent.preventDefault()
      }
    },
    legalNextStep() {
      const el = document.querySelector('.scrolling-content')
      if (this.legalStep < 1) {
        el.scrollTo({
          top: 0,
        })

        this.$refs.TermsTab.activate()
      } else {
        const body = document.querySelector('body')
        const html = document.querySelector('html')

        body.classList.remove('modal-open')
        html.classList.remove('modal-open')

        this.hideLegal()
      }
    },
    onContentScrolled(e) {
      const { scrollHeight } = e.target
      const footerWindow = document.querySelector('.bottom-progress')
      const maxScrollTop = scrollHeight - (footerWindow.offsetTop - 110)
      let lastScrollTop = 0

      let progress = this.scrollProgress

      if (this.tnc == null) {
        progress = e.target.scrollTop <= 0 ? 0 : Math.round((e.target.scrollTop / maxScrollTop) * 100)

        if (progress > 100) {
          progress = 100
        } else if (progress == 99) {
          progress++
        }
      }

      this.scrollProgress = progress

      lastScrollTop = e.target.scrollTop <= 0 ? 0 : e.target.scrollTop // for mobile or negative scrolling
    },
    showLegalModal(e) {
      e.preventDefault()
      if (this.tnc == null) {
        this.tnc = null
      } else {
        this.scrollProgress = 100
      }

      this.$refs['legal-modal'].show()

      const body = document.querySelector('body')
      const html = document.querySelector('html')
      body.classList.add('modal-open')
      html.classList.add('modal-open')
    },
    hideLegal() {
      this.tnc = true
      Gen.setCookie('tnc', '1', 400)
      this.$refs['legal-modal'].hide()
      this.$refs.PrivacyTab.activate()

      const el = document.querySelector('.content.mb-5')

      el.scrollTo({
        top: 0,
      })
    },
    onReRegister() {
      this.$router.push({ name: 'Register' })
    },
    async doLogin(changeEmail = false) {
      try {

        // if (changeEmail !== false) {
        //   this.formData.isChangeEmail = true
        //   this.formData.changeEmail = changeEmail
        // }

        // this.$store.state.loading.screen = true

        // const resp = await Gen.apiRest('/login', { data: Object.assign({}, this.formData) }, 'POST')

        // if (resp.status == 200) {
        //   if (resp.data.message == 'Verification code sent') {
        //     this.formData.token = (resp.data.data || {}).token
        //     this.formData.email = (resp.data.data || {}).email
        //     this.validity = (resp.data.data || {}).validity

        //     this.isDirty = true

        //     if ((resp.data.data || {}).avatarFilled == 'Y') {
        //       this.isFillingBasicSuccess = true
        //       this.scrollToTop()
        //       return
        //     } else if ((resp.data.data || {}).birthDayFilled == 'Y') {
        //       this.isFillingAvatar = true
        //       this.scrollToTop()
        //       return
        //     } else if ((resp.data.data || {}).genderFilled == 'Y') {
        //       this.isFillingBirthday = true
        //       this.scrollToTop()
        //       return
        //     } else if ((resp.data.data || {}).citizenshipFilled == 'Y') {
        //       this.isFillingGender = true
        //       this.scrollToTop()
        //       return
        //     } else if ((resp.data.data || {}).phoneVerified == 'Y') {
        //       this.isFillingCitizenship = true
        //       this.scrollToTop()
        //       return
        //     } else if ((resp.data.data || {}).emailVerified == 'Y') {
        //       this.isVerifyingPhoneInput = true
        //       this.scrollToTop()
        //       return
        //     }
        //     this.isVerifyingEmail = true
        //     this.formData.changeEmail = ''
        //     this.isChangingEmail = false
        //   } else {
        //     this.$store.commit('updateUserToken', ((resp.data || {}).data || {}).token)

        //     this.getProfile()

        //     if (this.$route.query.ref) {
        //       window.location = this.baseUrl + this.$route.query.ref
        //     } else {
        //       window.location = this.baseUrl + '/user/dashboard'
        //     }
        //   }
        // }
      } catch (err) {
        if (((err.response || {}).data || {}).message) {
          this.messageErrTitle = (err.response.data || {}).title
          this.messageErrDescription = (err.response.data || {}).message
          if (Object.prototype.hasOwnProperty.call(err.response.data, 'register')) {
            return this.$refs['modal-register'].show()
          }
          this.$refs['modal-error'].show()
        }
      } finally {
        this.$store.state.loading.screen = false
      }
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    async onSendNewCodeEmail() {
      if (this.disabledKirimUlang) {
        return null
      }

      if (this.isShowingAlert) {
        return null
      }

      this.isLoading = true

      try {
        const resp = await Gen.apiRest('/register/new-code/email', { data: { ...this.formData } }, 'POST')

        if (resp.status == 200) {
          this.scrollToTop()
          this.showSuccessAlertEmail = true
          this.isShowingAlert = true

          setTimeout(() => {
            this.showSuccessAlertEmail = false
            this.isShowingAlert = false
          }, 4000)
        }
      } catch (error) {
        return error
      } finally {
        this.isLoading = false
      }
    },

    onEmailVerified() {
      this.isVerifyingPhoneInput = true
      this.isVerifyingEmailSuccess = false
    },

    onPhoneVerified() {
      this.isFillingCitizenship = true
      this.isVerifyingPhoneSuccess = false
    },

    async onCitizenshipFilled(citizenship_flag) {
      if (citizenship_flag == '') {
        return null
      }

      this.formData.citizenship_flag = citizenship_flag

      const resp = await Gen.apiRest('/register/basic/citizenship', { data: { ...this.formData } }, 'POST')
      
      if (resp.status == 200) {
        this.isFillingGender = true
        this.isFillingCitizenship = false
      }
    },

    async onGenderFilled(gender) {
      if (gender == '') {
        return null
      }

      this.formData.gender = gender

      const resp = await Gen.apiRest('/register/basic/gender', { data: { ...this.formData } }, 'POST')

      if (resp.status == 200) {
        this.isFillingBirthday = true
        this.isFillingGender = false
      }
    },

    onBirthdaySkipped() {
      this.isFillingAge = true
      this.isFillingBirthday = false
    },

    onBackBirthday() {
      this.formData.age_range = ''
      this.isFillingBirthday = true
      this.isFillingAge = false
    },

    onAgeFilled() {
      if (this.formData.age_range == '') {
        return null
      }

      if (this.formData.age_range == '< 16') {
        this.isUnderAge = true
        this.isFillingAge = false
        return null
      }

      this.isFillingAvatar = true
      this.isFillingAge = false
      Gen.apiRest('/register/basic/age', { data: { ...this.formData } }, 'POST')
    },

    onBirthdayFilled(date_of_birth) {
      if (date_of_birth == '') {
        return null
      }

      this.formData.date_of_birth = date_of_birth

      const dob = dayjs(this.formData.date_of_birth)
      const now = dayjs()
      const age = now.diff(dob, 'year')

      if (age < this.minimumAge) {
        // minimum age by default is 16
        const openDate = dob.add(this.minimumAge, 'year')
        this.openDateValue = openDate.format('MMMM D, YYYY')
        this.isUnderAge = true
        this.isFillingBirthday = false
        return null
      }

      this.isFillingAvatar = true
      this.isFillingBirthday = false

      Gen.apiRest('/register/basic/birthday', { data: { ...this.formData } }, 'POST')
    },

    async onAvatarFilled(avatar) {
      try {
        this.formData.avatar = ((avatar || {}).data || {}).pathfile
        this.formData.fallback_avatar = ((avatar || {}).data || {}).fallback_pathfile

        if (this.formData.avatar === '' || this.formData.avatar === undefined) {
          return null
        }

        const resp = await Gen.apiRest('/register/basic/avatar', { data: { ...this.formData } }, 'POST')

        if (resp.status == 200) {
          this.isFillingBasicSuccess = true
          this.isFillingAvatar = false
        }
      } catch (err) {
        return err
      } finally {
        setTimeout(() => {
          this.loading.screen = false
        }, 1000)
      }
    },

    onAvatarSkipped() {
      this.formData.avatar = 'avatar/avatar-noimage.png'
      this.isFillingBasicSuccess = true
      this.isFillingAvatar = false

      Gen.apiRest('/register/basic/avatar', { data: { ...this.formData } }, 'POST')
    },

    onBirthdaySkipped() {
      this.isFillingAge = true
      this.isFillingBirthday = false
    },

    onBasicCompleted() {
      this.isFillingRunningFrequency = true
      this.isFillingBasicSuccess = false
    },

    onRunningFrequencyFilled() {
      if (this.formData.running_frequency === '') {
        return null
      }

      this.isFillingRunningMotivation = true
      this.isFillingRunningFrequency = false

      this.scrollToTop()

      // Gen.apiRest('/register/survey/running-frequency', { data: { ...this.formData } }, 'POST')
    },

    onRunningMotivationFilled() {
      if (this.formData.running_motivation == '') {
        return null
      }

      this.isFillingMarathonParticipation = true
      this.isFillingRunningMotivation = false

      this.scrollToTop()

      // Gen.apiRest('/register/survey/running-motivation', { data: { ...this.formData } }, 'POST')
    },

    onRunningMotivationFilledPrevious() {
      this.isFillingRunningFrequency = true
      this.isFillingRunningMotivation = false

      this.scrollToTop()
    },

    onMarathonParticipationFilled() {
      if (this.formData.marathon_participation == '') {
        return null
      }

      this.isFillingMajor = true
      this.isFillingMarathonParticipation = false

      this.scrollToTop()

      // Gen.apiRest('/register/survey/marathon-participation', { data: { ...this.formData } }, 'POST')
    },

    onMarathonParticipationFilledPrevious() {
      this.isFillingRunningMotivation = true
      this.isFillingMarathonParticipation = false

      this.scrollToTop()
    },

    onMajorFilled() {
      if ((this.formData.majors || []).length === 0) {
        return null
      }

      this.isFillingSurveySuccess = true
      this.isFillingMajor = false

      this.scrollToTop()

      Gen.apiRest('/register/survey/majors', { data: { ...this.formData } }, 'POST')
    },

    onMajorFilledPrevious() {
      this.isFillingMarathonParticipation = true
      this.isFillingMajor = false
      this.scrollToTop()
    },

    onSurveyCompleted() {
      this.doLogin()
    },
  },
  watch: {
    isVerifyingPhoneInput(value) {
      if (value === true) {
        this.getCountryCodeList({
          search: this.filter.search,
          callback: () => {
            this.isLoading = false
          },
          loading: false,
        })
      }
    },
  },
}
</script>
<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <Header :isOpenMenuProps="true" />
      </div>
      <div class="bg-grey-blue py-3 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isDesktop()">
        <DesktopVerEventInformation 
          :uploader="uploader"
          :run-event-details="runEventDetails"
          :is-mobile="isMobile"
          :is-btn-get-slot-now="isBtnGetSlotNow"
          :is-dev="isDev"
          :base-url="baseUrl"
          :title-slots-available="titleSlotsAvailable"
          :description-slots-available="descriptionSlotsAvailable"
          :format-date-without-weekday="formatDateWithoutWeekday"
          :format-date="formatDate"
          :format-time="formatTime"
          :define-package-remaining-slots-text="definePackageRemainingSlotsText"
          :type-of-transaction="typeOfTransaction"
          :ordinal="ordinal"
          :num-formatter="numFormatter"
          :format-date-with-hour="formatDateWithHour"
          :all-hotels="allHotels"
          :scroll-to-package="scrollToPackage"
          :choose-package="choosePackage"
          :open-hotel="openHotel"
          :open-announcement-list="openAnnouncementList"
        />
      </template>

      <template v-else-if="isOnlyMobile()">
        <MobileVerEventInformation 
          :uploader="uploader"
          :run-event-details="runEventDetails"
          :is-mobile="isMobile"
          :is-btn-get-slot-now="isBtnGetSlotNow"
          :is-dev="isDev"
          :title-slots-available="titleSlotsAvailable"
          :description-slots-available="descriptionSlotsAvailable"
          :format-date-without-weekday="formatDateWithoutWeekday"
          :format-date="formatDate"
          :format-time="formatTime"
          :define-package-remaining-slots-text="definePackageRemainingSlotsText"
          :type-of-transaction="typeOfTransaction"
          :ordinal="ordinal"
          :num-formatter="numFormatter"
          :format-date-with-hour="formatDateWithHour"
          :all-hotels="allHotels"
          :choose-package="choosePackage"
        />
      </template>

      <template v-else-if="isTablet()">
        <TabletVerEventInformation 
          :uploader="uploader"
          :run-event-details="runEventDetails"
          :is-mobile="isMobile"
          :is-btn-get-slot-now="isBtnGetSlotNow"
          :is-dev="isDev"
          :title-slots-available="titleSlotsAvailable"
          :description-slots-available="descriptionSlotsAvailable"
          :format-date-without-weekday="formatDateWithoutWeekday"
          :format-date="formatDate"
          :format-time="formatTime"
          :define-package-remaining-slots-text="definePackageRemainingSlotsText"
          :type-of-transaction="typeOfTransaction"
          :ordinal="ordinal"
          :num-formatter="numFormatter"
          :format-date-with-hour="formatDateWithHour"
          :all-hotels="allHotels"
          :choose-package="choosePackage"
        />
      </template>
    </div>

    <AppModal :withPageTitle="true" ref="modal-detail-package" :type="'lg'">
      <template #title-modal>
        <h5>Benefits</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <b-tabs>
            <b-tab title="Benefits" active>
              <div class="col-md-12 px-0">
                <div class="mt-3" v-for="(v, k) in ((packageDetails || {}).subPackage || {}).package_benefits">
                  <p class="bold text-dark">{{ v.category }}</p>
                  <ul class="list-item ml-4" v-for="(v1, k1) in v.benefits">
                    <li
                      v-if="
                        v1.packages[(((packageDetails || {}).subPackage || {}).package_name || '').toLowerCase()]
                          .checked === 'Y'
                      "
                    >
                      {{ v1.value }}
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="Excluded Benefits">
              <div class="col-md-12 px-0">
                <div class="mt-3">
                  <div
                    class="list-item text-dark"
                    v-html="((runEventDetails || {}).event || {}).excluded_benefits"
                  ></div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Hotel Information">
              <div class="col-md-12 px-0 mt-2">
                <div class="d-flex justify-content-between b-grey-black px-3 py-3 mt-3 bdrd-12px">
                  <div>
                    <p class="medium">Check-In</p>
                    <span class="bold alpha-color">{{
                      formatDate(((packageDetails || {}).package || {}).event_hotel_checkin_date)
                    }}</span>
                  </div>
                  <div>
                    <p class="medium">Check-Out</p>
                    <span class="bold alpha-color">{{
                      formatDate(((packageDetails || {}).package || {}).event_hotel_checkout_date)
                    }}</span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-announcement-list" :type="'lg'">
      <template #title-modal>
        <h5>Announcement List</h5>
      </template> 
      <template #content-modal>
        <ValidationObserver v-slot="{ handleSubmit }" ref="AnnouncementListForm">
          <b-form
            ref="AnnouncementListFormElement"
            @submit.prevent="handleSubmit(submitFormAnnouncementList)"
          >
            <div class="row bb-1px-grey pb-3">
              <div class="col-md-12">
                <div class="form-group">
                  <label 
                    for="fullname" 
                    class="required bold" 
                  >Full Name</label>
                  <input
                    id="fullname"
                    type="text"
                    v-model="inputAnnouncement.input_fullname"
                    class="form-control main-control"
                    placeholder="e.g. Abraham Damar"
                  />
                  <VValidate name="Full Name" v-model="inputAnnouncement.input_fullname" :rules="{required: true, min:3, max:128, regex: /^([A-Za-z' ]+)$/}" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email" class="required bold">Email Address</label>
                  <input
                    id="email"
                    type="email"
                    class="form-control main-control"
                    v-model="inputAnnouncement.input_email"
                    placeholder="e.g. abraham.damar@example.com"
                  />
                  <VValidate name="Email Address" v-model="inputAnnouncement.input_email" :rules="{required: true, email: true}" />
                </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="co1pnmb" class="required bold">Phone Number</label>
                    <div class="input-group d-flex mt-3">
                      <v-select
                        placeholder="Code"
                        :options="countryCodeData"
                        :reduce="(v) => v.countrycode_dial_code"
                        id="countrycode1"
                        v-model="inputAnnouncement.input_dialcode"
                        class="pointer"
                        :class="isOnlyMobile() ? 'no-dropdown' : ''"
                        @click.native="isOnlyMobile() ? showSearchModal('modal-country-code', 'countrycode1') : ''"
                        :get-option-label="(o) => o.countrycode_name + ' ' + o.countrycode_dial_code"
                      >
                        <template #option="{ countrycode_dial_code, countrycode_name }">
                          <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                        </template>
                        <template #selected-option="{ countrycode_dial_code }">
                          <span>{{ countrycode_dial_code }}</span>
                        </template>
                      </v-select>
                      <b-form-input
                        id="co1pnmb"
                        class="form-control main-control group-item mt-0"
                        placeholder="Phone Number"
                        v-model="inputAnnouncement.input_phone"
                        :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                      ></b-form-input>
                    </div>
                    <VValidate vclass="d-block" name="Country Code" v-model="inputAnnouncement.input_dialcode" :rules="{required: true}" />
                    <VValidate vclass="d-block" name="Phone Number" v-model="inputAnnouncement.input_phone" :rules="{required: true, min:5, max:16}" />
                  </div>
                </div>
              <div class="col-md-12">
                <fieldset class="checkbox-group">
                  <legend class="checkbox-group-legend">Choose your interest race</legend>
                  <div class="checkbox" v-for="(v,k) in upcomingRacesData" :key="k">
                    <label class="checkbox-wrapper">
                      <input type="checkbox" class="checkbox-input" v-model="inputAnnouncement.input_races" :value="v.race_name" />
                      <span class="checkbox-tile">
                        <span class="checkbox-label">{{ v.race_name}}</span>
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row justify-content-end pt-3">
              <div class="col-md-6 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                  >Cancel</button
                >
                <button
                  type="submit"
                  class="btn btn-primary semibold d-flex align-self-start mt-3 align-items-center"
                >
                  Submit
                </button>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-itinerary" :type="'lg'">
      <template #title-modal>
        <h5>Itineraries</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <ul class="timeline">
              <li class="timeline-item" v-for="(v, k) in itineraryDetails" :key="k">
                <template v-if="v.text !== '' && v.text !== null">
                  <div class="timeline-icon">{{ k + 1 }}</div>
                  <h5 class="bold">{{ v.title }}</h5>
                  <div class="timeline-item-date bold alpha-color fs-1em mb-3">{{ v.date }}</div>
                  <div v-html="v.text"></div>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-hotel" :type="'lg'">
      <template #title-modal>
        <h5>Hotel Details</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div>
              <h5 class="alpha-color bold">{{ hotelDetails.event_hotel_name }}</h5>
              <span class="regular" v-html="hotelDetails.event_hotel_description"></span>
              <div class="row my-4">
                <div class="col-md-6">
                  <div class="item-detail-text-icon d-flex align-items-start">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold">Location</span><br />
                      <span class="regular grey-color">{{ hotelDetails.event_hotel_address }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="item-detail-text-icon d-flex align-items-start">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-plus gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold">Quick Fact</span><br />
                      <span class="regular grey-color">{{ hotelDetails.event_hotel_location }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-tabs>
                    <b-tab title="Photos" active>
                      <div class="row">
                        <div
                          :class="isMobile() ? 'col-6' : 'col-md-3'"
                          v-for="(v, k) in hotelDetails.event_hotel_gallery"
                          :key="k"
                        >
                          <div class="card-hover small bdrd-24px overflow-hidden mb-3">
                            <picture>
                              <source :srcset="uploader(v.img)" type="image/webp" />
                              <img :src="uploader(v.fallback_img)" alt="" class="bdrd-24px" />
                            </picture>
                            <div class="detail">
                              <a :href="uploader(v.fallback_img)" data-fancybox="gallery">
                                <div class="social-bullet">
                                  <i class="icon-eye"></i>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Amenities">
                      <div class="regular wrap_hotel_info" v-html="hotelDetails.event_hotel_amenities"></div>
                    </b-tab>
                    <b-tab title="Rooms">
                      <div class="regular wrap_hotel_info" v-html="hotelDetails.event_hotel_rooms"></div>
                    </b-tab>
                    <b-tab title="Cares">
                      <div class="regular wrap_hotel_info" v-html="hotelDetails.event_hotel_cares"></div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a
          :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(
            /\D/g,
            ''
          )}&text&type=phone_number`"
          target="_blank"
          class="fab fa-whatsapp text-white p-3"
        >
        </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>

    <AppAlert
      ref="modal-success"
      vref="modal-success"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
      }"
      :title="messageResponse.title"
      :description="messageResponse.desc"
      :button="{
        main: {
          text: 'Ok',
          handle: closeAnnouncementModal,
        },
      }"
      @close="closeAnnouncementModal"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'
import AppAlert from '@/components/AppAlert'
import AppModal from '../components/AppModal.vue'

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import DesktopVerEventInformation from '@/views/atoms/runningeventdetails/DesktopVerEventInformation.vue'
import MobileVerEventInformation from '@/views/atoms/runningeventdetails/MobileVerEventInformation.vue'
import TabletVerEventInformation from '@/views/atoms/runningeventdetails/TabletVerEventInformation.vue'

import '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

export default {
  extends: Global,
  name: 'RunningEventDetails',
  components: {
    Header,
    AppAlert,
    AppModal,
    DesktopVerEventInformation,
    MobileVerEventInformation,
    TabletVerEventInformation,
  },
  data() {
    return {
      selected: '',
      isWebPSupport: false,
      titleSlotsAvailable: '',
      descriptionSlotsAvailable: '',
      breadcrumbItems: [],
      messageErrTitle: '',
      messageErrDescription: '',
    }
  },
  computed: {
    ...mapFields('useRunEventsStore', [
      'scrollPositionPackage',
      'isBtnGetSlotNow',
      'lightboxIndex',
      'typeOfTransaction',
      'runEventDetails',
      'allHotels',
      'hotelDetails',
      'itineraryDetails',
      'packageDetails',
    ]),
    ...mapFields(['channels', 'isDev']),
    ...mapFields('profile', ['countryCodeData']),
    ...mapFields('useAnnouncementEventStore', ['upcomingRacesData', 'inputAnnouncement', 'messageResponse']),
  },
  created() {
    this.getCountryCode()
    this.getUpcomingRaces()
    this.getDataDetails((runEventDetails) => {
      this.defineTicketsAvailable(runEventDetails)

      this.breadcrumbItems = [
        {
          text: 'Home',
          href: this.$router.resolve({ name: 'Home' }).href,
        },
        {
          text: 'Run With Us',
          href: this.$router.resolve({ name: 'RunningEvents' }).href,
        },
        {
          text: ((runEventDetails || {}).event || {}).name,
          active: true,
        },
      ]
    })
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('useRunEventsStore', ['getDataDetails']),
    ...mapActions('profile', ['getCountryCode']),
    ...mapActions('useAnnouncementEventStore', ['getUpcomingRaces', 'registerAnnouncement']),
    ...mapActions(['getChannels']),

    countryCodeById(id) {
      return (this.countryCodeData.data || []).find((c) => c.countrycode_id === id).countrycode_dial_code
    },

    defineTicketsAvailable(runEventDetails) {
      try {
        const closed = []

        ;(((runEventDetails || {}).event || {}).pricings || []).forEach((pr) => {
          Object.values(pr.prices).forEach((p) => {
            closed.push(p.is_closed)
          })
        })

        const isFullyClosed = closed.every((c) => c === true)
        const tickets_available = ((runEventDetails || {}).event || {}).available_tickets
        const total_stock = ((runEventDetails || {}).event || {}).total_stock

        if (isFullyClosed) {
          this.titleSlotsAvailable = '0 Slot Left'
          this.descriptionSlotsAvailable = `There are 0 ticket left out of the ${total_stock}`
          return null
        }

        this.titleSlotsAvailable =
          tickets_available > 1 ? `${tickets_available} Slots Left` : `${tickets_available} Slot Left`

        this.descriptionSlotsAvailable =
          tickets_available > 1
            ? `There are  ${tickets_available} tickets left out of the ${total_stock}`
            : `There are  ${tickets_available} ticket left out of the ${total_stock}`
      } catch (error) {
        return error
      }
    },

    definePackageRemainingSlotsText(stock, prices) {
      if (Object.values(prices).every((p) => p.is_closed === true)) {
        return 'Closed'
      }

      if (stock > 0) {
        return `${stock} Slots Left`
      }

      return 'Sold Out'
    },

    ordinal(number) {
      let ordinalNumber = ''

      switch (number) {
        case 1:
          ordinalNumber = number + 'st'
          break

        case 2:
          ordinalNumber = number + 'nd'
          break

        case 3:
          ordinalNumber = number + 'rd'
          break

        default:
          ordinalNumber = number + 'th'
          break
      }

      return ordinalNumber
    },
    detailPackage(subPackage, packageData) {
      this.$store.commit('useRunEventsStore/SET_PACKAGE_DETAILS', { subPackage, packageData })
      this.$refs['modal-detail-package'].show()
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    choosePackage(index, idEl, itinerary, package_name, no_partial, typeoftrans) {
      const el = document.querySelector(`#open-${index}-${idEl}`)
      const elButton = document.querySelector(`#btn-open-${index}-${idEl}`)

      if (!el.classList.contains('d-none')) {
        this.$router.push({
          name: 'Checkout',
          params: { pageSlug: this.$route.params.pageSlug },
          query: { itinerary, package: package_name, po: typeoftrans },
        })
      } else {
        el.classList.toggle('d-none')
        elButton.classList.toggle('d-none')
      }

      if (no_partial) {
        this.$store.commit('useRunEventsStore/DEFAULT_ONE_TIME_PAYMENT', 'one-time-payment')
      } else {
        this.$store.commit('useRunEventsStore/DEFAULT_ONE_TIME_PAYMENT', 'partial-payment')
      }
    },

    cancelChoosePackage(index, idEl) {
      const el = document.querySelector(`#open-${index}-${idEl}`)
      const elButton = document.querySelector(`#btn-open-${index}-${idEl}`)
      el.classList.add('d-none')
      elButton.classList.add('d-none')
    },

    scrollToPackage() {
      const el = document.querySelector('.package-catalog')

      if (el) {
        const elPosition = el.getBoundingClientRect().top
        const offsetPosition = elPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition - 125,
          behavior: 'smooth',
        })
      }
    },

    openItineraries(data) {
      this.$store.commit('useRunEventsStore/SET_ITINERARY_DETAILS', data)
      this.$refs['modal-itinerary'].show()
    },

    openAnnouncementList() {
      this.$refs['modal-announcement-list'].show()
    },

    openHotel(data) {
      this.$store.commit('useRunEventsStore/SET_HOTEL_DETAILS', data)
      this.$refs['modal-hotel'].show()
    },

    async submitFormAnnouncementList() {
      try {
        const res = await this.registerAnnouncement((success, res) => {
          if(success) {
            this.$refs['modal-announcement-list'].hide()
            this.$refs['modal-success'].show()
          }
        })
      } catch (e) {
        return e
      }
    },
    closeAnnouncementModal() {
      this.$refs['modal-success'].hide()
    }
  },
}
</script>

<style lang="scss" scoped>
.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	// justify-content: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	user-select: none;
	& > * {
		margin: .5rem 0.5rem;
	}
}



.checkbox-group-legend {
	font-size: 1rem;
	font-weight: 700;
	color: #777777;
	// text-align: center;
	line-height: 1.125;
	margin-bottom: 1.25rem;
}

.checkbox-input {
	// Code to hide the input
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	&:checked + .checkbox-tile {
		border-color: #95378E;
		box-shadow: 0 5px 10px rgba(#000, 0.1);
		color: #95378E;
		&:before {
			transform: scale(1);
			opacity: 1;
			background-color: #95378E;
			border-color: #95378E;
		}
		
		.checkbox-icon, .checkbox-label {
			color: #95378E;
		}
	}
	
	&:focus + .checkbox-tile {
		border-color: #95378E;
		box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 15.85rem;
	min-height: 4.5rem;
	border-radius: 0.5rem;
	border: 1px solid #b5bfd9;
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(#000, 0.1);
	transition: 0.15s ease;
	cursor: pointer;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 1.25rem;
		height: 1.25rem;
		border: 2px solid #b5bfd9;
		background-color: #fff;
		border-radius: 50%;
		top: 0.25rem;
		left: 0.25rem;
		opacity: 0;
		transform: scale(0);
		transition: 0.25s ease;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
		background-size: 12px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	&:hover {
		border-color: #95378E;
		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-icon {
	transition: .375s ease;
	color: #494949;
	svg {
		width: 3rem;
		height: 3rem;
	}
}

.checkbox-label {
	color: #707070;
	transition: .375s ease;
	text-align: center;
}

</style>
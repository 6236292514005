<template>
  <section v-if="bannerType === 'V'" class="fullscreen">
    <div class="html5vid video-loaded" id="video-uuzge">
      <video
        playsinline
        autoplay="autoplay"
        muted
        loop
        :poster="uploader(data.poster)"
      >
        <source
          v-if="isOnlyMobile"
          :src="uploader(data.image_small)"
          type="video/webm"
        />
        <source
          v-else
          :src="uploader(data.image)"
          type="video/webm"
        />
        <source
          v-if="isOnlyMobile"
          :src="uploader(data.fallback_image_small)"
          type="video/mp4"
        />
        <source
          v-else
          :src="uploader(data.fallback_image)"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center" :class="!isOnlyMobile ? 'p-60' : ''">
        <div class="col-md-9">
          <div class="heading-text text-light text-center">
            <h1 class="black">
              {{ data.title }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="bannerType === 'H'" class="fullscreen" :style="heroBackground">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center" :class="!isOnlyMobile ? 'p-60' : ''">
        <div class="col-md-9">
          <div class="heading-text text-light text-center">
            <h1 class="black">
              {{ data.title }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FullBannerAtom',
  props: {
    isOnlyMobile: {
      type: Boolean,
    },
    isMobile: {
      type: Boolean,
    },
    uploader: {
      type: Function,
    },
    data: {
      type: Object,
    },
    bannerType: {
      type: String,
    }
  },
  computed: {
    heroBackground() {
      let src = '';
      if (this.isOnlyMobile) {
        src = this.isWebPSupport
          ? this.uploader((this.data || {}).image_small)
          : this.uploader((this.data || {}).fallback_image_small)
      } else {
        src = this.isWebPSupport
          ? this.uploader((this.data || {}).image)
          : this.uploader((this.data || {}).fallback_image)
      }

      if (src === '') {
        return null
      }

      return `
        background: url('${src}') center center / cover;
      `
    },
  }
}
</script>

<style lang="scss" scoped>
.heading-text {
  h1 {
    font-size: 55px;
    line-height: 1;
    @media screen and (max-width: 768px) {
      font-size: 32.5px;
    }
  }
}
</style>

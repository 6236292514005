import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    bookingPricesData: [],
    breadcrumbPricesData: [
      {
        text: 'Home',
        to: { name: 'Home' },
      },
      {
        text: 'See Booking & Prices',
        active: true,
      },
    ],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_BOOKING_PRICES_DATA(state, data) {
      state.bookingPricesData = data
    },
  },

  actions: {
    async getData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/booking-prices`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_BOOKING_PRICES_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <div class="home">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="`${((transactionDetailsData || {}).about || {}).trx_event_name} - Itinerary`"
      :paginate-elements-by-height="2500"
      :pdf-quality="2"
      pdf-format="a4"
      :html-to-pdf-options="{
        filename: `${((transactionDetailsData || {}).about || {}).trx_event_name} - Itinerary`,
        margin: [0, 0, 0.35, 0],
        image: {
          type: 'jpeg',
          quality: 1,
        },
        pagebreak: {
          mode: ['css', 'legacy'],
          avoid: '.page-breaker',
        },
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          allowTaint: true,
          useCORS: true,
        },
        jsPDF: {
          format: 'letter',
          orientation: 'portrait',
        },
      }"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      ref="documentItineraries"
    >
      <section slot="pdf-content" class="py-0 my-0" style="width: 100%">
        <section class="report-info py-0">
          <section class="pdf-item py-0 my-0" style="position: relative; height: 100%">
            <div class="page-pdf-with-footer">
              <div class="container p-l-60 p-r-60">
                <div class="row">
                  <div class="col-md-12 bb-1px-grey pb-3">
                    <div class="d-flex justify-content-between">
                      <img :src="`${baseUrl}/assets/images/logo/black_rdg.png`" style="width: 250px; height: 75px" alt="">
                      <img :src="`${baseUrl}/assets/images/header-template.png`" style="width:100px" alt="">
                    </div>
                  </div>
                  <div class="col-md-12 mb-2 pt-3">
                    <h2 class="text-uppercase extrabold mb-1">{{ (((transactionDetailsData || {}).about || {}).trx_hotel || {}).itinerary_description || '-' }}</h2>
                    <span class="regular d-block mb-2">Arrive in {{ ((transactionDetailsData.about || {}).trx_run_event || {}).event_program_name || '-' }} by {{ formatDate(((transactionDetailsData.about || {}).trx_hotel || {}  ).hotel_checkin_date) || '-' }}</span>
                  </div>
                  <div class="col-md-12">
                    <div class="row mb-2">
                      <div class="col-4">
                        <span class="regular">Check in:</span>
                      </div>
                      <div class="col-4">
                        <span class="regular">Check out:</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="row mb-2">
                      <div class="col-4">
                        <span class="bold">{{ formatDate((((transactionDetailsData || {}).about || {}).trx_hotel || {}).hotel_checkin_date) || '-' }}</span>
                      </div>
                      <div class="col-4">
                        <span class="bold">{{ formatDate((((transactionDetailsData || {}).about || {}).trx_hotel || {}).hotel_checkout_date) || '-' }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <table class="table">
                      <thead>
                        <tr class="bg-grey-blue">
                          <th scope="col" class="bold py-2 b-grey-black">Day</th>
                          <th scope="col" class="bold py-2 b-grey-black" colspan="2">Date</th>
                          <th scope="col" class="bold py-2 b-grey-black">What to Do</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (((transactionDetailsData || {}).about || {}).trx_hotel || {}).event_itinerary_descriptions" :key="k">
                          <th class="b-grey-black regular">{{ k + 1}}</th>
                          <td class="b-grey-black regular" colspan="2">{{ v.date || '-' }}</td>
                          <td class="b-grey-black regular" v-html="v.text">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row" style="margin-top: 150px">
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <img :src="`${baseUrl}/assets/images/static-icon/checklist.png`" alt="" style="width:25px; height: 25px">
                        <h5 class="mx-2 mb-0">Included in the price</h5>
                      </div>
                      <div class="mt-3" v-for="(v,k) in ((transactionDetailsData || {}).about || {}).trx_benefit_values">
                        <p>{{ v.category }}</p>
                        <ul>
                          <template v-for="(v1,k1) in v.benefits">
                            <li>{{v1.value}}</li>
                          </template>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <img :src="`${baseUrl}/assets/images/static-icon/x.png`" alt="" style="width:25px; height: 25px">
                        <h5 class="mx-2 mb-0">Not included in the price</h5>
                      </div>
                      <div class="mt-3" v-html="(((transactionDetailsData || {}).about || {}).trx_run_event || {}).excluded_benefits"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </vue-html2pdf>

    <div class="body-inner">
      <div>
        <Header :isOpenMenuProps="true" />
      </div>

      <section class="pt-5" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="bt-1px-black pb-5" :class="!isMobile() ? 'd-flex align-items-end' : ''">
                <div class="w-85">
                  <p class="regular bold pointer" @click="$router.push({name: 'MyPurchases'})">&lt; Back</p>
                  <h4 class="extrabold" :class="!isMobile() ? 'fs-275em' : 'fs-225em'">{{ ((transactionDetailsData || {}).about || {}).trx_event_name || '-' }}</h4>
                  <div class="d-flex align-items-center" :class="!isMobile() ? '' : 'flex-wrap'">
                    <span class="regular grey-color">#{{ $route.params.pageSlug || '-' }}</span>
                    <div class="label-custom yellow d-inline" :class="!isMobile() ? 'mx-2' : 'mt-2 mb-3'">
                      <span class="black fs-085em">Non-Refundable</span>
                    </div>
                  </div>
                  <span
                    >By
                    <a
                      :href="(((transactionDetailsData || {}).about || {}).trx_run_event || {}).event_partner_url"
                      target="_blank"
                      class="text-link semibold text-decoration-none"
                      >{{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_partner_name || '-' }}</a
                    ></span
                  >
                  <div class="row" :class="!isMobile() ? 'pt-5' : 'py-4'">
                    <div class="col-md-4">
                      <div
                        class="item-detail-text-icon d-flex align-items-start"
                        :class="!isMobile() ? 'mx-2' : 'mb-2'"
                      >
                        <div class="icon d-flex justify-content-center align-items-center">
                          <i class="fa fa-calendar gamma-color fs-1em"></i>
                        </div>
                        <div class="mx-2 w-75">
                          <span class="mt-2 gamma-color semibold">{{ formatDateWithoutWeekday((((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_race_date) || '-' }}</span><br />
                          <span class="regular grey-color">{{ formatDate((((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_race_date) || '-' }}, {{ formatTime((((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_race_time) || '-' }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="item-detail-text-icon d-flex align-items-start">
                        <div class="icon d-flex justify-content-center align-items-center">
                          <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                        </div>
                        <div class="mx-2 w-75">
                          <span class="mt-2 gamma-color semibold">{{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_program_name || '-' }}, {{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_program_country || '-' }}</span><br />
                          <span class="regular grey-color">{{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_program_race_village || '-' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="btn btn-slg btn-primary bold w-100 my-2" @click="$router.push({name: 'RunningEventDetails', params: {pageSlug: (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_slug || '-' }})">See Event Details</button>
                  <button class="btn btn-slg btn-light bold w-100 my-2 ml-0" @click="$router.push({name: 'ContactUs'})">Contact Us</button>
                </div>
              </div>
            </div>
            <div class="col-md-8 pt-4" :class="!isMobile() ? 'br-1px br-grey-black p-r-30' : ''">
              <div class="row">
                <div class="col-md-12 mt-4">
                  <span class="sub-title-section fs-125em">
                    <div class="line-sub-title"></div>
                    Package
                  </span>
                  <div class="d-flex justify-content-between b-grey-black px-3 py-3 bdrd-12px mt-3">
                    <div class="w-75">
                      <p class="fs-115em text-dark bold mb-0">{{ ((transactionDetailsData ||{}).about || {}).trx_itinerary_name || '-' }}, {{ ((transactionDetailsData ||{}).about || {}).trx_package_name || '-' }}</p>
                      <div class="d-flex flex-column">
                        <span class="grey-color regular"
                          >{{ (((transactionDetailsData ||{}).about || {}).trx_packages || {}).package_description || '-' }}</span
                        >
                      </div>
                    </div>
                    <div class="w-25 text-end">
                      <a @click="doOpenModalDetailPackage" class="pointer alpha-color text-link">See Benefits</a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-4">
                  <span class="sub-title-section fs-125em">
                    <div class="line-sub-title"></div>
                    Runner Information
                  </span>
                  <div class="d-flex justify-content-between b-grey-black px-3 py-3 bdrd-12px mt-3">
                    <div>
                      <p class="fs-115em text-dark bold mb-0">{{ ((transactionDetailsData || {}).about || {}).trx_full_name || '-' }}</p>
                      <div class="d-flex flex-column">
                        <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_email_address || '-' }}</span>
                        <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_dial_code || '-' }}{{ ((transactionDetailsData || {}).about || {}).trx_phone_number }}</span>
                      </div>
                    </div>
                    <div>
                      <a @click="doOpenModalDetailRunner()" class="alpha-color pointer text-link">See Details</a>
                    </div>
                  </div>
                  <div v-if="((transactionDetailsData || {}).about || {}).trx_second_full_name !== null" class="d-flex justify-content-between b-grey-black px-3 py-3 bdrd-12px mt-3">
                    <div>
                      <p class="fs-115em text-dark bold mb-0">{{ ((transactionDetailsData || {}).about || {}).trx_second_full_name || '-' }}</p>
                      <div class="d-flex flex-column">
                        <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_second_email_address || '-' }}</span>
                        <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_second_dial_code || '-' }}{{ ((transactionDetailsData || {}).about || {}).trx_second_phone_number }}</span>
                      </div>
                    </div>
                    <div>
                      <a @click="doOpenModalDetailRunner('modal-detail-second-runner')" class="alpha-color pointer text-link">See Details</a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-4" v-if="((transactionDetailsData.about || {}).trx_packages || {}).package_max_person > 1">
                  <span class="sub-title-section fs-125em">
                    <div class="line-sub-title"></div>
                    Roommate
                  </span>
                  <div class="d-flex flex-column">
                    <div>
                      <div v-for="(v, k) in ((transactionDetailsData || {}).about || {}).trx_roommate" :key="k" class="bg-white mt-3 price-box bdrd-12px py-3 px-3" v-if="k > 0">
                        <p v-if="v.first_name !== ''" class="regular grey-color mb-0">Name: {{ v.first_name || '-' }} {{ v.last_name }}</p>
                        <p v-else class="regular grey-color mb-0">Name: -</p>
                        <span v-if="v.gender !== ''" class="regular grey-color d-block">
                          Gender: {{ v.gender || '-' }}
                        </span>
                        <span v-else class="regular grey-color d-block">
                          Gender: -
                        </span>
                        <span v-if="v.age !== ''" class="regular grey-color d-block">
                          Age: {{ v.age || '-' }} years old
                        </span>
                        <span v-else class="regular grey-color d-block">
                          Age: -
                        </span>
                        <small v-if="v.first_name === ''" class="regular grey-color mt-2">* Please confirm to <a :href="$router.resolve({name: 'ContactUs'}).href">Admin</a> about your roommate information.</small>
                      </div>

                      <div class="bg-white mt-3 price-box bdrd-12px py-3 px-3" v-else>
                        <span class="normal">Admin will determine your roommate later</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-4">
                  <span class="sub-title-section fs-125em">
                    <div class="line-sub-title"></div>
                    Hotel
                  </span>
                  <div class="d-flex justify-content-between b-grey-black px-3 py-3 bdrd-12px mt-3">
                    <div>
                      <p class="fs-115em text-dark bold mb-0">{{ (((transactionDetailsData || {}).about || {}).trx_hotel || {}).hotel_name || '-' }}</p>
                      <div class="d-flex flex-column">
                        <span class="grey-color regular">{{ (((transactionDetailsData || {}).about || {}).trx_hotel || {}).hotel_address || '-' }}</span>
                      </div>
                    </div>
                    <div>
                      <a class="alpha-color pointer text-link" @click="openHotel(((transactionDetailsData || {}).about || {}).trx_hotel)">See Details</a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-4">
                  <span class="sub-title-section fs-125em">
                    <div class="line-sub-title"></div>
                    My Documents
                  </span>
                  <span class="regular grey-color"
                    >Your documents will be updated when we receive new documents for you, so stay connected.</span
                  >
                  <div>
                    <div
                      class="card-document d-flex align-items-center justify-content-between b-grey-black px-3 py-3 bdrd-12px mt-3"
                    >
                      <div>
                        <div class="icon-ext b-grey-black px-3 py-2 bdrd-12px">
                          <i class="fa fa-file-pdf"></i>
                        </div>
                        <span class="mx-3 regular">{{ `${((transactionDetailsData || {}).about || {}).trx_event_name} - Itinerary` || '-' }}</span>
                      </div>
                      <a @click="exportItinerariesToPDF" class="alpha-color pointer text-link">Download</a>
                    </div>
                    <template v-if="((transactionDetailsData || {}).about || {}).trx_documents">
                      <div
                        v-for="(v,k) in ((transactionDetailsData || {}).about || {}).trx_documents"
                        :key="k"
                        class="card-document d-flex align-items-center justify-content-between b-grey-black px-3 py-3 bdrd-12px mt-3"
                      >
                        <div>
                          <div class="icon-ext b-grey-black px-3 py-2 bdrd-12px">
                            <i :class="`fa fa-file-${v.filetype}`"></i>
                          </div>
                          <span class="mx-3 regular">{{ v.file_name}}</span>
                        </div>
                        <a :href="uploader(v.file)" target="_blank" class="text-link">Download</a>
                      </div>
                    </template>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-4 pt-5 p-l-30">
              <span class="sub-title-section fs-125em">
                <div class="line-sub-title"></div>
                My Bills
              </span>
              <div>
                <template v-if="JSON.stringify(active_payment) != '{}'">
                  <div class="b-grey-black px-3 py-4 bdrd-12px mt-3">
                    <div>
                      <p class="fs-115em text-dark bold mb-0">{{ ordinal((active_payment || {}).num_of_payment) || '-' }} Payment</p>
                      <span class="bold alpha-color">IDR {{ numFormatter((active_payment || {}).amount) || '-' }}</span>
                      <div class="d-flex flex-column mt-3">
                        <div class="d-flex justify-content-between my-2">
                          <span class="regular grey-color">Payment ID</span>
                          <span class="bold text-end">{{ (active_payment || {}).order_id || '-' }}</span>
                        </div>
                        <div class="d-flex justify-content-between my-2">
                          <span class="regular grey-color">Expired at</span>
                          <span class="bold text-end">{{ formatDate((active_payment || {}).expiry) || '-' }}</span>
                        </div>
                        <div class="d-flex justify-content-between my-2">
                          <span class="regular grey-color">Status</span>
                          <template v-if="(active_payment || {}).is_allow == 'P'">
                            <div class="label-custom grey d-inline text-end">
                              <span class="black fs-085em">Waiting for Confirmation</span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="label-custom yellow d-inline text-end" v-if="(active_payment || {}).payment_status == 'Pending'">
                              <span class="black fs-085em">Waiting for Payment</span>
                            </div>
                            <div class="label-custom blue d-inline text-end" v-if="(active_payment || {}).payment_status == 'Verifying'">
                              <span class="black fs-085em">Payment in Verification</span>
                            </div>
                            <div class="label-custom yellow d-inline text-end" v-if="(active_payment || {}).payment_status == 'Reupload'">
                              <span class="black fs-085em">Reupload Proof of Transfer</span>
                            </div>
                            <div class="label-custom green d-inline text-end" v-if="(active_payment || {}).payment_status == 'Success'">
                              <span class="black fs-085em">Paid</span>
                            </div>
                            <div class="label-custom red d-inline text-end" v-if="(active_payment || {}).payment_status == 'Reject'">
                              <span class="black fs-085em">Rejected</span>
                            </div>
                            <div class="label-custom red d-inline text-end" v-if="(active_payment || {}).payment_status == 'Cancel'">
                              <span class="black fs-085em">Canceled</span>
                            </div>
                            <div class="label-custom red d-inline text-end" v-if="(active_payment || {}).payment_status == 'Expired'">
                              <span class="black fs-085em">Expired</span>
                            </div>
                          </template>
                        </div>
                      </div>
                      <a
                        :href="
                          $router.resolve({
                            name: 'PaymentDetails',
                            params: { pageSlug: active_payment.order_id },
                          }).href
                        "
                        class="btn btn-slg btn-primary bold w-100 my-2"
                        >Pay Now</a
                      >
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="b-grey-black px-3 py-4 bdrd-12px mt-3 d-flex flex-column align-items-center">
                    <img :src="`${baseUrl}/assets/images/static-icon/checklist-icon.png`" alt="Check" class="w-25">
                    <p class="bold fs-115em mb-0 text-dark mt-3">No remaining payment</p>
                  </div>
                </template>
                <b-tabs>
                  <b-tab title="Upcoming" active>
                    <template v-if="(upcoming_payment || {}).length > 0">
                      <div class="row">
                        <div class="col-md-12">
                          <template v-for="(v,k) in upcoming_payment">
                            <div v-if="v.is_active == 'N'" class="b-grey-black px-3 py-3 bdrd-12px mb-2" :key="k">
                              <div>
                                <p class="fs-115em text-dark bold mb-0">{{ ordinal(v.num_of_payment) || '-' }} Payment</p>
                                <span class="bold alpha-color">IDR {{ numFormatter(v.amount) || '-' }}</span>
                                <div class="d-flex justify-content-between my-2">
                                  <span class="regular grey-color">Expired at</span>
                                  <span class="bold text-end">{{ formatDate((v || {}).expiry) || '-' }}</span>
                                </div>
                                <div class="d-flex justify-content-between my-2">
                                  <span class="regular grey-color">Status</span>
                                  <template v-if="(v || {}).is_allow == 'P'">
                                    <div class="label-custom grey d-inline text-end">
                                      <span class="black fs-085em">Waiting for Confirmation</span>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div class="label-custom yellow d-inline text-end" v-if="(v || {}).payment_status == 'Pending'">
                                      <span class="black fs-085em">Waiting for Payment</span>
                                    </div>
                                    <div class="label-custom blue d-inline text-end" v-if="(v || {}).payment_status == 'Verifying'">
                                      <span class="black fs-085em">Payment in Verification</span>
                                    </div>
                                    <div class="label-custom yellow d-inline text-end" v-if="(v || {}).payment_status == 'Reupload'">
                                      <span class="black fs-085em">Reupload Proof of Transfer</span>
                                    </div>
                                    <div class="label-custom green d-inline text-end" v-if="(v || {}).payment_status == 'Success'">
                                      <span class="black fs-085em">Paid</span>
                                    </div>
                                    <div class="label-custom red d-inline text-end" v-if="(v || {}).payment_status == 'Reject'">
                                      <span class="black fs-085em">Rejected</span>
                                    </div>
                                    <div class="label-custom red d-inline text-end" v-if="(v || {}).payment_status == 'Cancel'">
                                      <span class="black fs-085em">Canceled</span>
                                    </div>
                                    <div class="label-custom red d-inline text-end" v-if="(v || {}).payment_status == 'Expired'">
                                      <span class="black fs-085em">Expired</span>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                            <template v-else>
                              <span class="regular">No upcoming payment</span>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="row">
                        <div class="col-md-12">
                          <span class="regular">No upcoming payment</span>
                        </div>
                      </div>
                    </template>
                  </b-tab>
                  <b-tab title="History">
                    <template v-if="(history_payment || {}).length > 0">
                      <div class="row">
                        <p>Your Payment History</p>
                        <div class="col-md-12">
                          <div class="b-grey-black px-3 py-3 bdrd-12px mb-2" v-for="(v,k) in history_payment" :key="k">
                            <div>
                              <p class="fs-115em text-dark bold mb-0">{{ ordinal(v.num_of_payment) || '-' }} Payment</p>
                              <div class="d-flex justify-content-between">
                                <span class="bold alpha-color">IDR {{ numFormatter(v.amount) || '-' }}</span>
                                <router-link :to="{name: 'PaymentHistory', params: {pageSlug: v.order_id}}" class="text-link regular pointer">See Details</router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="row">
                        <span class="regular">You don't have a payment history yet</span>
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

      <template v-else-if="isMobile()">
        <section id="page-title">
          <div class="container">
            <div class="d-flex flex-column justify-content-center">
              <h2 class="black fs-175em text-white fs-125em mb-1 ls-0 d-flex align-items-center" :class="!isMobile() ? 'w-75' : ''">
                <i class="icon-chevron-left fs-1 pointer text-white mr-2" @click="$router.push({name: 'MyPurchases'})"></i>
                Transaction Details
              </h2>
            </div>
          </div>
        </section>
        <div class="mobile-version-view py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h4 class="extrabold fs-15em">{{ ((transactionDetailsData || {}).about || {}).trx_event_name || '-' }}</h4>
                <div class="mb-2 d-flex align-items-center flex-wrap">
                  <span class="regular grey-color mr-3">#{{ $route.params.pageSlug || '-' }}</span>
                  <div class="label-custom yellow d-inline mt-2 mb-3">
                    <span class="black fs-085em">Non-Refundable</span>
                  </div>
                </div>
                <span
                  >By
                  <a
                    :href="(((transactionDetailsData || {}).about || {}).trx_run_event || {}).event_partner_url"
                    target="_blank"
                    class="text-link semibold text-decoration-none"
                    >{{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_partner_name || '-' }}</a
                  ></span
                >
                <div class="row bb-1px-grey pb-4" :class="!isMobile() ? 'pt-5' : 'py-4'">
                  <div class="col-md-4">
                    <div class="item-detail-text-icon d-flex align-items-start" :class="!isMobile() ? 'mx-2' : 'mb-2'">
                      <div class="icon d-flex justify-content-center align-items-center">
                        <i class="fa fa-calendar gamma-color fs-1em"></i>
                      </div>
                      <div class="mx-2 w-75">
                        <span class="mt-2 gamma-color semibold">{{ formatDateWithoutWeekday((((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_race_date) || '-' }}</span><br />
                        <span class="regular grey-color">{{ formatDate((((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_race_date) || '-' }}, {{ formatTime((((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_race_time) || '-' }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="item-detail-text-icon d-flex align-items-start">
                      <div class="icon d-flex justify-content-center align-items-center">
                        <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                      </div>
                      <div class="mx-2 w-75">
                        <span class="mt-2 gamma-color semibold">{{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_program_name || '-' }}, {{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_program_country || '-' }}</span><br />
                        <span class="regular grey-color">{{ (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_program_race_village || '-' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row bb-1px-grey py-4">
                  <div class="col">
                    <p class="regular grey-color mb-1 semibold fs-115em">Package</p>
                    <div class="d-flex">
                      <div class="w-75">
                        <p class="text-dark bold mb-0">{{ ((transactionDetailsData ||{}).about || {}).trx_itinerary_name || '-' }}, {{ ((transactionDetailsData ||{}).about || {}).trx_package_name || '-' }}</p>
                      </div>
                      <div class="w-25 text-end">
                        <a @click="doOpenModalDetailPackage" class="text-link pointer alpha-color">See Benefits</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row bb-1px-grey py-4">
                  <div>
                    <p class="regular grey-color mb-1 semibold fs-115em">Runner Information</p>
                    <div class="d-flex">
                      <div class="w-75">
                        <p class="text-dark bold mb-0">{{ ((transactionDetailsData || {}).about || {}).trx_full_name || '-' }}</p>
                        <div class="d-flex flex-column">
                          <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_email_address || '-' }}</span>
                          <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_dial_code || '-' }}{{ ((transactionDetailsData || {}).about || {}).trx_phone_number }}</span>
                        </div>
                      </div>
                      <div class="w-25 text-end">
                        <a @click="doOpenModalDetailRunner()" class="text-link pointer alpha-color">See Details</a>
                      </div>
                    </div>
                    <div v-if="((transactionDetailsData || {}).about || {}).trx_second_full_name !== null" class="d-flex mt-2">
                      <div class="w-75">
                        <p class="text-dark bold mb-0">{{ ((transactionDetailsData || {}).about || {}).trx_second_full_name || '-' }}</p>
                        <div class="d-flex flex-column">
                          <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_second_email_address || '-' }}</span>
                          <span class="grey-color regular">{{ ((transactionDetailsData || {}).about || {}).trx_second_dial_code || '-' }}{{ ((transactionDetailsData || {}).about || {}).trx_second_phone_number }}</span>
                        </div>
                      </div>
                      <div class="w-25 text-end">
                        <a @click="doOpenModalDetailRunner('modal-detail-second-runner')" class="text-link pointer alpha-color">See Details</a>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="row bb-1px-grey py-4" v-if="((transactionDetailsData.about || {}).trx_packages || {}).package_max_person > 1">
                  <div class="col">
                    <p class="regular grey-color mb-1 semibold fs-115em">Roommate</p>
                    <div  v-for="(v, k) in ((transactionDetailsData || {}).about || {}).trx_roommate" :key="k" class="d-flex mb-2" v-if="k > 0">
                      <div class="w-75">
                        <p v-if="v.first_name !== ''" class="text-dark bold grey-color mb-0">{{ v.first_name || '-' }} {{ v.last_name }}</p>
                        <p v-else class="regular grey-color mb-0">Name: -</p>
                        <div class="d-flex flex-column">
                          <span v-if="v.gender !== ''" class="grey-color regular">{{ v.gender || '-' }}</span>
                          <span v-else class="grey-color regular">Gender: -</span>
                          <span v-if="v.age !== ''" class="grey-color regular">{{ v.age || '-' }} years old</span>
                          <span v-else class="grey-color regular">Age: -</span>
                        </div>
                        <small v-if="v.first_name === ''" class="regular grey-color mt-2">*Please confirm to <a :href="$router.resolve({name: 'ContactUs'}).href">Admin</a> about your roommate information.</small>
                      </div>
                    </div>
                    <div v-else>
                      <span class="normal">Admin will determine your roommate later</span>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bb-1px-grey py-4">
                  <div class="col-md-12">
                    <p class="regular grey-color mb-1 semibold fs-115em">Hotel</p>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="w-75">
                        <p class="text-dark bold mb-0">{{ (((transactionDetailsData || {}).about || {}).trx_hotel || {}).hotel_name || '-' }}</p>
                        <span class="grey-color regular"
                          >{{ (((transactionDetailsData || {}).about || {}).trx_hotel || {}).hotel_address || '-' }}</span
                        >
                      </div>
                      <div class="w-25 text-end">
                        <a class="alpha-color pointer text-link" @click="openHotel(((transactionDetailsData || {}).about || {}).trx_hotel)">See Details</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row bb-1px-grey py-4">
                  <div class="col">
                    <p class="regular grey-color mb-1 semibold fs-115em">My Bills</p>
                    <template v-if="JSON.stringify(active_payment) != '{}'">
                      <div class="b-grey-black px-3 py-3 bdrd-12px mt-3">
                        <div>
                          <p class="fs-115em text-dark bold mb-0">{{ ordinal((active_payment || {}).num_of_payment) || '-' }} Payment</p>
                          <span class="bold alpha-color">IDR {{ numFormatter((active_payment || {}).amount) || '-' }}</span>
                          <div class="d-flex flex-column mt-3">
                            <div class="d-flex justify-content-between my-2">
                              <span class="regular grey-color">Order ID</span>
                              <span class="bold text-end">{{ (active_payment || {}).order_id || '-' }}</span>
                            </div>
                            <div class="d-flex justify-content-between my-2">
                              <span class="regular grey-color">Expired at</span>
                              <span class="bold text-end">{{ formatDate((active_payment || {}).expiry) || '-' }}</span>
                            </div>
                            <div class="d-flex justify-content-between my-2">
                              <span class="regular grey-color">Status</span>
                              <template v-if="(active_payment || {}).is_allow == 'P'">
                                <div class="label-custom grey d-inline text-end">
                                  <span class="black fs-085em">Waiting for Confirmation</span>
                                </div>
                              </template>
                              <template v-else>
                                <div class="label-custom yellow d-inline text-end" v-if="(active_payment || {}).payment_status == 'Pending'">
                                  <span class="black fs-085em">Waiting for Payment</span>
                                </div>
                                <div class="label-custom blue d-inline text-end" v-if="(active_payment || {}).payment_status == 'Verifying'">
                                  <span class="black fs-085em">Payment in Verification</span>
                                </div>
                                <div class="label-custom yellow d-inline text-end" v-if="(active_payment || {}).payment_status == 'Reupload'">
                                  <span class="black fs-085em">Reupload Proof of Transfer</span>
                                </div>
                                <div class="label-custom green d-inline text-end" v-if="(active_payment || {}).payment_status == 'Success'">
                                  <span class="black fs-085em">Paid</span>
                                </div>
                                <div class="label-custom red d-inline text-end" v-if="(active_payment || {}).payment_status == 'Reject'">
                                  <span class="black fs-085em">Rejected</span>
                                </div>
                                <div class="label-custom red d-inline text-end" v-if="(active_payment || {}).payment_status == 'Cancel'">
                                  <span class="black fs-085em">Canceled</span>
                                </div>
                                <div class="label-custom red d-inline text-end" v-if="(active_payment || {}).payment_status == 'Expired'">
                                  <span class="black fs-085em">Expired</span>
                                </div>
                              </template>
                            </div>
                          </div>
                          <a
                            :href="
                              $router.resolve({
                                name: 'PaymentDetails',
                                params: { pageSlug: active_payment.order_id },
                              }).href
                            "
                            class="btn btn-slg btn-primary bold w-100 my-2"
                            >Pay Now</a
                          >
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="b-grey-black px-3 py-4 bdrd-12px mt-3 d-flex flex-column align-items-center">
                        <img :src="`${baseUrl}/assets/images/static-icon/checklist-icon.png`" alt="Check" class="w-25">
                        <p class="bold fs-115em mb-0 text-dark mt-3">No remaining payment</p>
                      </div>
                    </template>
                    <b-tabs>
                      <b-tab title="Upcoming" active>
                        <template v-if="(upcoming_payment || {}).length > 0">
                          <div class="row">
                            <p>Your Upcoming Payment</p>
                            <div class="col-md-12">
                              <template v-for="(v,k) in upcoming_payment">
                                <div v-if="v.is_active == 'N'" class="b-grey-black px-3 py-3 bdrd-12px mb-2" :key="k">
                                  <div>
                                    <p class="fs-115em text-dark bold mb-0">{{ ordinal(v.num_of_payment) || '-' }} Payment</p>
                                    <span class="bold alpha-color">IDR {{ numFormatter(v.amount) || '-' }}</span>
                                    <div class="d-flex justify-content-between my-2">
                                      <span class="regular grey-color">Expired at</span>
                                      <span class="bold text-end">{{ formatDate((v || {}).expiry) || '-' }}</span>
                                    </div>
                                    <div class="d-flex justify-content-between my-2">
                                      <span class="regular grey-color">Status</span>
                                      <template v-if="(v || {}).is_allow == 'P'">
                                        <div class="label-custom grey d-inline text-end">
                                          <span class="black fs-085em">Waiting for Confirmation</span>
                                        </div>
                                      </template>
                                      <template v-else>
                                        <div class="label-custom yellow d-inline text-end" v-if="(v || {}).payment_status == 'Pending'">
                                          <span class="black fs-085em">Waiting for Payment</span>
                                        </div>
                                        <div class="label-custom blue d-inline text-end" v-if="(v || {}).payment_status == 'Verifying'">
                                          <span class="black fs-085em">Payment in Verification</span>
                                        </div>
                                        <div class="label-custom yellow d-inline text-end" v-if="(v || {}).payment_status == 'Reupload'">
                                          <span class="black fs-085em">Reupload Proof of Transfer</span>
                                        </div>
                                        <div class="label-custom green d-inline text-end" v-if="(v || {}).payment_status == 'Success'">
                                          <span class="black fs-085em">Paid</span>
                                        </div>
                                        <div class="label-custom red d-inline text-end" v-if="(v || {}).payment_status == 'Reject'">
                                          <span class="black fs-085em">Rejected</span>
                                        </div>
                                        <div class="label-custom red d-inline text-end" v-if="(v || {}).payment_status == 'Cancel'">
                                          <span class="black fs-085em">Canceled</span>
                                        </div>
                                        <div class="label-custom red d-inline text-end" v-if="(v || {}).payment_status == 'Expired'">
                                          <span class="black fs-085em">Expired</span>
                                        </div>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="row">
                            <span class="regular">No upcoming payment</span>
                          </div>
                        </template>
                      </b-tab>
                      <b-tab title="History">
                        <template v-if="(history_payment || {}).length > 0">
                          <div class="row">
                            <p>Your Payment History</p>
                            <div class="col-md-12">
                              <div class="b-grey-black px-3 py-3 bdrd-12px mb-2" v-for="(v,k) in history_payment" :key="k">
                                <div>
                                  <p class="fs-115em text-dark bold mb-0">{{ ordinal(v.num_of_payment) || '-' }} Payment</p>
                                  <div class="d-flex justify-content-between">
                                    <span class="bold alpha-color">IDR {{ numFormatter(v.amount) || '-' }}</span>
                                    <router-link :to="{name: 'PaymentDetails', params: {pageSlug: v.order_id}}" class="text-link regular pointer">See Details</router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="row">
                            <span class="regular">You don't have a payment history yet</span>
                          </div>
                        </template>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>

                <div class="row bb-1px-grey py-4">
                  <div class="col">
                    <p class="regular grey-color mb-1 semibold fs-115em">My Documents</p>
                    <div class="card-document b-grey-black px-3 py-3 bdrd-12px mt-3">
                      <div class="d-flex mb-2">
                        <div class="icon-ext b-grey-black px-3 py-2 bdrd-12px">
                          <i class="fa fa-file-pdf"></i>
                        </div>
                        <span class="mx-3 regular">{{ `${((transactionDetailsData || {}).about || {}).trx_event_name} - Itinerary` || '-' }}</span>
                      </div>
                      <a @click="exportItinerariesToPDF" class="text-link alpha-color">Download</a>
                    </div>
                    <template v-if="((transactionDetailsData || {}).about || {}).trx_documents">
                      <div 
                        v-for="(v,k) in ((transactionDetailsData || {}).about || {}).trx_documents"
                        :key="k"
                        class="card-document b-grey-black px-3 py-3 bdrd-12px mt-3"
                      >
                        <div class="d-flex mb-2">
                          <div class="icon-ext b-grey-black px-3 py-2 bdrd-12px">
                            <i :class="`fa fa-file-${v.filetype}`"></i>
                          </div>
                          <span class="mx-3 regular">{{ v.file_name}}</span>
                        </div>
                        <a :href="uploader(v.file)" target="_blank" class="text-link">Download</a>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="row py-4">
                  <div class="col-12">
                    <button class="btn btn-slg btn-primary bold w-100 my-2" @click="$router.push({name: 'RunningEventDetails', params: {pageSlug: (((transactionDetailsData ||{}).about || {}).trx_run_event || {}).event_slug || '-' }})">See Event Details</button>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-slg btn-light bold w-100 my-2" @click="$router.push({name: 'ContactUs'})">Contact Us</button>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-slg btn-light bold w-100 my-2" @click="$router.push({name: 'MyPurchases'})">Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://wa.me/${transactionDetailsData.whatsapp_contact}`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <div class="fa fa-envelope-square" @click="$router.push({ name: 'ContactUs' })"></div>
      </div>
    </div>

    <AppModal :withPageTitle="true" ref="modal-hotel" :type="'lg'">
      <template #title-modal>
        <h5>Hotel Details</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div>
              <h5 class="alpha-color bold">{{ hotelDetails.hotel_name }}</h5>
              <span class="regular" v-html="hotelDetails.hotel_description"></span>
              <div class="row my-4">
                <div class="col-md-6">
                  <div class="item-detail-text-icon d-flex align-items-start">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold">Location</span><br />
                      <span class="regular grey-color"
                        >{{ hotelDetails.hotel_address }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="item-detail-text-icon d-flex align-items-start">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-plus gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold">Quick Fact</span><br />
                      <span class="regular grey-color">{{ hotelDetails.hotel_location }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-tabs>
                    <b-tab title="Photos" active>
                      <div class="row">
                        <div :class="isMobile() ? 'col-6' : 'col-md-3'" v-for="(v,k) in hotelDetails.hotel_gallery" :key="k">
                          <div class="card-hover small bdrd-24px overflow-hidden mb-3">
                            <picture>
                              <source
                                :srcset="
                                  uploader(v.img)
                                "
                                type="image/webp"
                              />
                              <img
                                :src="uploader(v.fallback_img)"
                                :alt="v.alt_img"
                                class="bdrd-24px"
                              />
                            </picture>
                            <div class="detail">
                              <a
                                :href="uploader(v.fallback_img)"
                                data-fancybox="gallery"
                              >
                                <div class="social-bullet">
                                  <i class="icon-eye"></i>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Amenities">
                      <div class="regular wrap_hotel_info" v-html="hotelDetails.hotel_amenities"></div>
                    </b-tab>
                    <b-tab title="Rooms">
                      <div class="regular wrap_hotel_info" v-html="hotelDetails.hotel_rooms"></div>
                    </b-tab>
                    <b-tab title="Cares">
                      <div class="regular wrap_hotel_info" v-html="hotelDetails.hotel_cares"></div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="true" ref="modal-detail-package" :type="'lg'">
      <template #title-modal>
        <h5>Benefits</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3" v-for="(v,k) in ((transactionDetailsData || {}).about || {}).trx_benefit_values" :class="k == 0 ? 'mt-3' : ''">
              <p class="bold text-dark">{{ v.category || '-' }}</p>
              <ul class="list-item ml-4" v-for="(v1,k1) in v.benefits" :key="k1">
                <li>{{ v1.value || '-' }}</li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
    <AppModal :withPageTitle="true" ref="modal-detail-runner" :type="'lg'">
      <template #title-modal>
        <h5>Runner Information</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">First Name:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_first_name || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Last Name:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_last_name || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Contact:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_email_address || '-' }} ({{ ((transactionDetailsData || {}).about || {}).trx_dial_code || '-' }}{{ ((transactionDetailsData || {}).about || {}).trx_phone_number }})</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Date of Birth:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ formatDateWithoutWeekday(((transactionDetailsData || {}).about || {}).trx_birth_date) || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Citizenship:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_citizenship || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Passport:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_passport_no || '-' }} (Expired at {{ formatDateWithoutWeekday(((transactionDetailsData || {}).about || {}).trx_passport_expiration) || '-' }})</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Country of Residence:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_country_of_residence || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Street Address 1:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_address_1 ? ((transactionDetailsData || {}).about || {}).trx_address_1 : '-' || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Street Address 2:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_address_2 ? ((transactionDetailsData || {}).about || {}).trx_address_2 : '-' || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Street Address 3:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_address_3 ? ((transactionDetailsData || {}).about || {}).trx_address_3 : '-' || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
    <AppModal :withPageTitle="true" ref="modal-detail-second-runner" :type="'lg'">
      <template #title-modal>
        <h5>Runner Information</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">First Name:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_first_name || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Last Name:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_last_name || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Contact:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_email_address || '-' }} ({{ ((transactionDetailsData || {}).about || {}).trx_second_dial_code || '-' }}{{ ((transactionDetailsData || {}).about || {}).trx_second_phone_number }})</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Date of Birth:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ formatDateWithoutWeekday(((transactionDetailsData || {}).about || {}).trx_second_birth_date) || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Citizenship:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_citizenship || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <span class="regular">Passport:</span>
              </div>
              <div class="col-md-8">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_passport_no || '-' }} (Expired at {{ formatDateWithoutWeekday(((transactionDetailsData || {}).about || {}).trx_second_passport_expiration) || '-' }})</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Country of Residence:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_country_of_residence || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Street Address 1:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_address_1 ? ((transactionDetailsData || {}).about || {}).trx_second_address_1 : '-' || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Street Address 2:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_address_2 ? ((transactionDetailsData || {}).about || {}).trx_second_address_2 : '-' || '-' }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-5">
                <span class="regular">Street Address 3:</span>
              </div>
              <div class="col-md-7">
                <span class="bold">{{ ((transactionDetailsData || {}).about || {}).trx_second_address_3 ? ((transactionDetailsData || {}).about || {}).trx_second_address_3 : '-' || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import html2pdf from 'html2pdf.js'
import VueHtml2pdf from 'vue-html2pdf'
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'
import AppModal from '../components/AppModal.vue'

export default {
  extends: Global,
  name: 'TransactionDetails',
  components: {
    Header,
    VueHtml2pdf,
    AppModal,
  },
  data() {
    return {
      hotelDetails: {},
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields('useTransactionsStore', [
      'transactionDetailsData'
    ]),

    active_payment() {
      let data = (((this || []).transactionDetailsData || []).transactions || []).filter(obj => {
        if(obj.state == "Y" && obj.transaction_status != "P") {
          return obj
        }
      })

      if(data.length > 0) {
        return data[0]
      } else {
        return {}
      }
    },

    history_payment() {
      let data = (((this || []).transactionDetailsData || []).transactions || []).filter(obj => {
        if(obj.payment_status == "Success") {
          return obj
        }
      })

      if(data.length > 0) {
        return data
      } else {
        return []
      }
    },

    upcoming_payment() {
      let data = (((this || []).transactionDetailsData || []).transactions || []).filter(obj => {
        if(obj.payment_status != "Success") {
          return obj
        }
      })

      if(data.length > 0) {
        return data
      } else {
        return []
      }
    }
  },
  created() {
    this.getTransactionDetails(this.$route.params.pageSlug)

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })

    this.$store.commit('SET_STATIC_SEO', {
      title: 'Transaction Details'
    })
  },
  mounted() {},
  methods: {
    ...mapActions("useTransactionsStore", [
      "getTransactionDetails"
    ]),
    openHotel(data) {
      this.hotelDetails = data
      this.$refs['modal-hotel'].show()
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
    exportItinerariesToPDF() {
      this.$refs.documentItineraries.generatePdf()
    },
    doOpenModalDetailPackage() {
      this.$refs['modal-detail-package'].show()
    },
    doOpenModalDetailRunner(modalId = 'modal-detail-runner') {
      this.$refs[modalId].show()
    },
    ordinal(number) {
      let ordinalNumber = '';

      switch(number) {
        case 1:
          ordinalNumber = number + 'st';
          break;

        case 2:
          ordinalNumber = number + 'nd';
          break;

        case 3:
          ordinalNumber = number + 'rd';
          break;

        default:
          ordinalNumber = number + 'th';
          break;
      }

      return ordinalNumber;
    },
  },
}
</script>

<style lang="scss" scoped>
#page-title {
  background: linear-gradient(to bottom right, #250e23, #81317b);
}
.image-footer-document {
  position: absolute;
  bottom: 0 !important;
  right: -20px;
  z-index: 99;
}
</style>

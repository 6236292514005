import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    footerData: [],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_FOOTER_DATA(state, data) {
      state.footerData = data
    },
  },

  actions: {
    async getData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/footer`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_FOOTER_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

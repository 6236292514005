<template>
  <LayoutPrivatePage>
    <template #page-title>
      <div class="d-flex align-items-center">
        <router-link :to="{name: 'Dashboard'}">
          <i class="icon-chevron-left fs-125em pointer text-white mr-2"></i>
        </router-link>
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">Notification</h2>
      </div>
    </template>

    <template #content-mobile-nocontainer>
      <!-- <div class="container"> -->
      <div class="row m-0">
        <div class="col-md-12 px-0">
          <b-tabs class="capsul two-tab custom-scroll fh">
            <b-tab active>
              <template #title>
                <div class="d-flex justify-content-center">
                  <span>Updates</span>
                  <div v-if="dashboard_unread_updates > 0" class="bullet-number ml-1">
                    <span>{{ dashboard_unread_updates }}</span>
                  </div>
                </div>
              </template>
              <template v-if="updates.length > 0">
                <div class="wrapper-notification-list mt-4 pt-3">
                  <div
                    class="card-notification-list px-4 py-4"
                    :class="v.is_read == null ? 'new' : ''"
                    v-for="(v, k) in updates" 
                    :key="'info-list-'+k"
                    @click="
                      $router.push({
                        name: 'DetailNotification',
                        params: { pageSlug: v.slug },
                      })
                    "
                  >
                    <picture>
                      <source :srcset="uploader(v.image)" type="image/webp">
                      <img :src="uploader(v.fallback_image)" :alt="v.title" class="w-100 mb-3" />
                    </picture>
                    <p class="bold text-dark mb-1 fs-1em">
                      {{ v.title }}
                    </p>
                    <span class="regular grey-color"
                      >{{ v.description }}</span
                    >
                    <p class="mt-3 mb-0">Promo - {{ formatDateWithoutWeekday(v.published_date) }}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="relative p-4 mt-3 mb-1">
                  <div class="initial-content d-flex flex-column align-items-center">
                    <div class="wrapper-illustration w-50 d-flex justify-content-center">
                      <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="goal" class="w-25" />
                    </div>
                    <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No Update Yet</h2>
                    <p class="text-center">Stay connected for more interesting update</p>
                  </div>
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <!-- </div> -->
    </template>
  </LayoutPrivatePage>
</template>

<script>
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import Global from '@/libs/Global'
import { mapFields } from 'vuex-map-fields'

export default {
  extends: Global,
  name: 'Notification',
  components: {
    LayoutPrivatePage,
  },
  data() {
    return {
      filter: {
        search: '',
      },
    }
  },
  computed: {
    ...mapFields(['channels', 'dashboard_unread_updates']),
    ...mapFields('usePrivatePageStore', ['updates', 'notifications']),
  }
}
</script>

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,
  state: {
    featured: {},
    galleryContent: {
      hero: {
        title: '',
        sub_title: '',
        image: '',
        image_small: '',
        fallback_image: '',
        fallback_image_small: '',
        selectedEvent: '',
      },
      views: [],
      categories: [],
    },
    galleryData: [],
    dataLength: 0,
    editorPicks: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_GALLERY_CONTENT(state, data) {
      state.galleryData = data.data
      state.dataLength = data.dataLength
      state.galleryContent = data.content
    },
  },

  actions: {
    async getGalleryContent({ commit, rootState }, data) {
      const { page, category, view, callback = false } = data
      
      try {
        const resp = await http.get('/gallery', {
          headers: Gen.apiHeader(),
          params: {
            page,
            category,
            view
          }
        })

        if (callback) {
          callback()
        }

        commit('SET_GALLERY_CONTENT', resp.data)
        rootState.staticseo = resp.data.staticseo
      } catch (error) {
        if (callback) {
          callback()
        }
        console.log(error)
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="row">
            <div class="col-md-12">
              <div class="wrap-search">
                <div class="form-group">
                  <b-input-group class="position-relative">
                    <template #prepend>
                      <div class="wrap-search-icon">
                        <i class="icon-search position-absolute" :class="!isOnlyMobile() ? 'large-breakpoint' : ''"></i>
                      </div>
                    </template>
                    <input
                      id="searchbar"
                      type="search"
                      class="form-control main-control mt-0"
                      placeholder="Search Wishlist"
                      v-model="filter.search"
                    />
                  </b-input-group>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4 mb-4">
              <b-card class="card-tab bs-none b-grey-black bdrd-12px" no-body>
                <b-tabs card>
                  <b-tab title="All" active>
                    <div class="relative card-private-page mt-3">
                      <div class="blog-pp d-flex">
                        <div class="img-src">
                          <img :src="`${baseUrl}/assets/images/sample-static/london_thumbnail_large.jpeg`" alt="" />
                        </div>
                        <div class="caption-blog w-75">
                          <div class="wrap-text d-flex justify-content-between">
                            <h5 class="extrabold ls-0 mb-0 fs-125em">London Marathon 2023</h5>
                            <div class="wrap-wish-cta pt-1 ml-2">
                              <i class="icon-heart d-none" @click.stop.prevent="toggleWishlist"></i>
                              <i class="fa fa-heart fill" @click.stop.prevent="toggleWishlist"></i>
                            </div>
                          </div>
                          <div class="d-flex flex-wrap">
                            <div class="d-flex align-items-center mr-2 my-2">
                              <i class="fa fa-calendar gamma-color fs-1em"></i
                              ><a href=""><span class="mx-2 gamma-color fs-1em semibold">23 April 2023</span></a>
                            </div>
                            <div class="d-flex align-items-center mr-2 my-2">
                              <i class="icon-map gamma-color fs-1em"></i
                              ><a href=""><span class="mx-2 gamma-color fs-1em semibold">42 KM</span></a>
                            </div>
                          </div>
                          <div class="mt-2 pt-3 d-flex bt-1px-grey justify-content-between align-items-center">
                            <span class="text-danger semibold">Only 9 Slots Left</span>
                            <router-link
                              :to="{
                                name: 'RunningEventDetails',
                                params: { pageSlug: 'london-marathon-2023' },
                              }"
                              class="btn btn-primary bold d-flex align-items-center"
                              ><span>Join Event</span> <i class="icon-arrow-right ml-1"></i
                            ></router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <div class="b-grey-black px-4 py-4 bdrd-12px">
              <h5 class="bold">Need Help?</h5>
              <span
                ><a href="" class="text-link">Browse Our FAQ</a> or <a href="" class="text-link">Contact Us</a></span
              >
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #page-title>
      <div class="d-flex flex-column justify-content-center">
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">My Wishlist</h2>
      </div>
    </template>

    <template #content-mobile>
      <div class="row">
        <div class="col-md-12 mt-3 mb-4">
          <div class="wrap-wish-search">
            <div class="form-group">
              <b-input-group class="position-relative">
                <template #prepend>
                  <div class="wrap-search-icon">
                    <i class="icon-search position-absolute"></i>
                  </div>
                </template>
                <input
                  id="searchbar"
                  type="search"
                  class="form-control main-control"
                  placeholder="Search Wishlist"
                  v-model="filter.search"
                />
              </b-input-group>
            </div>
          </div>
          <div class="relative card-private-page event-card mt-3">
            <div class="card">
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-5 pr-3">
                    <div class="img-src bdrd-12px overflow-hidden">
                      <img
                        :src="`${baseUrl}/assets/images/sample-static/london_thumbnail_large.jpeg`"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-7 pl-0 pr-3">
                    <div class="wrap-text d-flex">
                      <div class="wrap-title">
                        <span class="bold">RDG London Marathon 2023</span>
                      </div>
                      <div class="wrap-wish-cta pt-1 ml-2">
                        <i class="icon-heart d-none" @click.stop.prevent="toggleWishlist"></i>
                        <i class="fa fa-heart fill" @click.stop.prevent="toggleWishlist"></i>
                      </div>
                    </div>
                    <div class="wrap-details">
                      <div class="d-flex align-items-center mr-2 my-2">
                        <i class="fa fa-calendar gamma-color fs-085em"></i
                        ><a href=""><span class="mx-2 gamma-color fs-085em semibold">23 April 2023</span></a>
                      </div>
                      <div class="d-flex align-items-center mr-2 my-2">
                        <i class="icon-map gamma-color fs-085em"></i
                        ><a href=""><span class="mx-2 gamma-color fs-085em semibold">42 KM</span></a>
                      </div>
                    </div>
                    <div class="wrap-cta bt-1px-grey pt-3">
                      <span class="text-danger semibold d-block mb-2">Only 9 Slots Left</span>
                      <router-link
                        :to="{
                          name: 'RunningEventDetails',
                          params: { pageSlug: 'london-marathon-2023' },
                        }"
                        class="btn btn-primary bold w-100"
                        >See Details</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LayoutPrivatePage>
</template>

<script>
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import Global from '@/libs/Global'

export default {
  extends: Global,
  name: 'PastRaces',
  components: {
    LayoutPrivatePage,
  },
  data() {
    return {
      filter: {
        search: '',
      },
    }
  },
  methods: {
    toggleWishlist(e) {
      const selectedEl = e.target
      const changeEl = e.target.closest('.wrap-wish-cta').querySelector('.d-none')

      selectedEl.classList.add('d-none')
      changeEl.classList.remove('d-none')
    },
  },
}
</script>

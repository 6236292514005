<template>
  <div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  name: 'FloatingActionButtonAtom',
  props: {
    openMenuToggle: {
      type: Function,
    },
    noChannel: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapFields(['channels']),
  },
  methods: {
    ...mapActions(['getChannels']),
  },
  created() {
    if (!this.noChannel) {
      this.getChannels()
    }
  },
}
</script>

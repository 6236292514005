<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="d-flex align-items-center">
          <!-- <div class="img-profile relative">
            <img :src="uploader(userAvatar)" :alt="userName" />
            <div class="icon-cust pointer" @click="$refs['modal-change-profile-picture'].show()">
              <i class="icon-edit-2 fs-1em"></i>
            </div>
          </div> -->
          <div>
            <h2 v-if="userLastName !== ''" class="extrabold ls-0 fs-3em">Hi, {{ userName }}</h2>
            <h2 v-else class="extrabold ls-0 fs-3em">Hi, {{ userFirstName }}</h2>
            <p>
              Welcome to dashboard. You can easily view your purchase
              history through a secure user dashboard and keeps your privacy.
            </p>
            <div class="d-flex">
              <div class="item-detail-text-icon d-flex align-items-start">
                <div>
                  <span class="mt-2 gamma-color semibold">Email Address <span class="badge badge-success ml-2">Verified</span></span><br />
                  <span class="regular grey-color">{{ userEmail }}</span>
                </div>
              </div>

              <div class="item-detail-text-icon d-flex align-items-start mx-5">
                <div>
                  <span class="mt-2 gamma-color semibold">Phone Number <span class="badge badge-success ml-2">Verified</span></span><br />
                  <span class="regular grey-color">{{ userPhone || '-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AppModal :withPageTitle="true" ref="modal-change-profile-picture" :type="'n mt-5'" @close="onModalChangeAvatarClosed">
      <template #title-modal>
        <h4 class="bold ls-0 mb-0">Profile Picture</h4>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="wrap_avatar wrap_img d-flex justify-content-center align-items-center">
              <label for="upload-avatar" class="cursor-pointer mb-0">
                <!-- <img
                  id="previewUploadAvatar"
                  :src="uploader(userAvatar)"
                  alt="avatar preview"
                  class="rounded-circle img-fluid"
                  :class="!isOnlyMobile() ? 'p-2' : ''"
                /> -->
              </label>
            </div>
            <div class="wrap_upload_btn d-flex justify-content-center my-4">
              <label class="btn btn-outline" for="upload-avatar">Choose Photo</label>
            </div>
            <input
              ref="fileInput"
              @change="pickFile" 
              type="file"
              accept=".jpg,.jpeg,.png"
              class="d-none" 
              id="upload-avatar" 
            />
            <b-form-text> Format accepted: .jpg, .jpeg, .png </b-form-text>
          </div>
          <div class="col-md-12">
            <div class="b-grey-black px-3 py-3 bdrd-12px mt-2">
              <p class="fs-1rem bold">Requirement</p>
              <p class="fs-1rem normal">Format: JPEG, JPG, PNG</p>
              <p class="fs-1rem normal">Max file size 2 MB</p>
            </div>
          </div>
          <div class="col-md-12">
            <button class="btn btn-slg btn-primary bold w-100 mt-4" @click="doChangeProfilePicture">
              Save Changes
            </button>
          </div>
        </div>
      </template>
    </AppModal>

    <AppModal :withPageTitle="false" ref="modal-success-profile-picture" :type="'md'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/accept-confirmation-vect.svg`" alt="Information" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">
                Profile picture has been successfully updated!
              </p>
              <span class="regular fs-1em">Thank you for keeping your information up-to-date</span>
              <div class="d-flex">
                <button
                  @click="$refs['modal-success-profile-picture'].hide()"
                  class="btn btn-primary semibold d-flex text-center justify-content-center mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppAlert
      ref="modal-error"
      vref="modal-error"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
      }"
      icon-size="w-50"
      :title="messageErrTitle"
      :description="messageErrDescription"
      :button="{
        main: {
          text: 'Ok',
        },
      }"
    />
  </div>
</template>

<script>
import Global from '@/libs/Global'
import AppModal from '@/components/AppModal'
import axios from 'axios'
import Gen from '@/libs/Gen'
import AppAlert from '@/components/AppAlert'

export default {
  extends: Global,
  name: 'UserIdentity',
  components: {
    AppModal,
    AppAlert,
  },
  props: {
    userName: {
      type: String,
    },
    userEmail: {
      type: String,
    },
    userPhone: {
      type: [String, Number],
    },
    userFirstName: {
      type: String,
    },
    userLastName: {
      type: String,
    },
  },
  data() {
    return {
      messageErrTitle: '',
      messageErrDescription: '',
      previewImage: null,
      fileProfilePicture: null,
      fileType: null,
      conf: {
        rule_type: 'jpeg,jpg,png',
        max_image_size: 5000000,
      },
      formInputAvatar: {
        registrant_image: '',
        registrant_fallback_image: '',
      }
    }
  },
  methods: {
    onModalChangeAvatarClosed() {
      (document.getElementById("previewUploadAvatar") || {}).src = this.uploader(this.userAvatar)
      this.$refs.fileInput.value = ''
    },
    uploadFormFile() {
      // Validation
      this.error = false

      if (this.fileProfilePicture !== null) {
        if (this.conf.rule_type.indexOf(this.fileType) == -1) {
          this.messageErrTitle = `File type must be ${this.conf.rule_type} type.`
          this.$refs['modal-error'].show()
          return null
        }

        if (this.fileProfilePicture.size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          this.messageErrTitle = 'File too big (> 5MB)'
          this.$refs['modal-error'].show()
          return null
        }

        if (this.error) {
          return alert(this.error)
        }

        const path = 'avatar'

        const formData = new FormData()

        formData.set('file', this.fileProfilePicture)

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },
    async pickFile(e) {
      if (e.target.value === '') {
        return
      }

      const input = this.$refs.fileInput
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.addEventListener("load", function(e) {
          (document.getElementById("previewUploadAvatar") || {}).src = reader.result
        }, false)
        this.$emit('input', file[0])
      }

      this.fileProfilePicture = e.target.files[0]

      this.conf.fileType = (this.fileProfilePicture || {}).type

      this.fileType = ((this.fileProfilePicture || {}).name || '')
        .substr(((this.fileProfilePicture || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()
    },

    async doChangeProfilePicture() {
      this.$store.state.loading.screen = true
      const avatar = await this.uploadFormFile()

      this.formInputAvatar.registrant_image = ((avatar || {}).data || {}).pathfile
      this.formInputAvatar.registrant_fallback_image = ((avatar || {}).data || {}).fallback_pathfile

      const token = Gen.getCookie("userAuthRdg")

      const req = await Gen.apiRest('/change-avatar', { 
        data: { ...this.formInputAvatar},
        headers: Gen.apiHeaderAuth(token),
      }, 'POST')
      
      if (req.status == 200) {
        if (this.formInputAvatar.registrant_image !== '' && this.formInputAvatar.registrant_image !== undefined) {
          this.$store.state.user.registrant_avatar = this.formInputAvatar.registrant_image
          this.$store.state.user.registrant_fallback_avatar = this.formInputAvatar.registrant_fallback_image
        }

        this.$store.state.loading.screen = false
        this.$refs['modal-change-profile-picture'].hide()
        this.$refs['modal-success-profile-picture'].show()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-cust {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <section class="general-form-section
      " :class="isTablet() ? 'h-auto' : ''">
        <div class="row no-gutters">
          <div class="col-lg-7" :class="isMobile() ? 'order-5' : ''">
            <div
              class="left-section without-justify-content"
              :class="
                !isMobile()
                  ? 'ov-scroll scroll-bar-hidden p-r-100 p-l-100'
                  : isTablet()
                  ? 'd-block h-auto'
                  : 'mt-5 pt-0 px-3'
              "
            >
              <div>
                <step-progress
                  :steps="mySteps"
                  :current-step="currentStep"
                  icon-class="fa fa-check"
                  active-color="#95378E"
                  :active-thickness="3"
                  :passive-thickness="3"
                  :line-thickness="3"
                ></step-progress>
              </div>
              <ValidationObserver v-slot="{ passes }" ref="WWUTourGuideForm">
                <b-form ref="WWUTourGuideFormElement" @submit.prevent="passes(nextStep)">
                  <div v-if="currentStep == 0">
                    <h4 class="extrabold">Basic Information</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Email Address</label>
                            <b-badge
                              variant="info"
                              class="help-badge custom-badge-info ml-3"
                              v-b-tooltip.hover.right="'We need an email so we can contact you'"
                              id="email-tooltip"
                              pill
                              @click.prevent
                            >
                              <b-icon icon="question" class="question-icon" font-scale="1"></b-icon>
                            </b-badge>
                            <input
                              type="email"
                              v-model="form.email_address"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@example.com"
                            />
                            <VValidate name="Email Address" v-model="form.email_address" :rules="{required: true, email: true, max:96}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Title</label>
                            <div class="d-flex mt-3">
                              <b-form-radio v-model="form.title" name="title" value="Mr">Mr</b-form-radio>
                              <b-form-radio v-model="form.title" name="title" class="ml-3" value="Mrs"
                                >Mrs</b-form-radio
                              >
                              <b-form-radio v-model="form.title" name="title" class="ml-3" value="Ms">Ms</b-form-radio>
                            </div>
                            <VValidate name="Title" v-model="form.title" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">First & Middle Name</label>
                            <input
                              type="text"
                              v-model="form.first_name"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate name="First Name" v-model="form.first_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Last Name</label>
                            <input
                              type="text"
                              v-model="form.last_name"
                              class="form-control main-control"
                              placeholder="e.g. Grahita"
                            />
                            <VValidate name="Last Name" v-model="form.last_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Phone Number</label>
                            <b-form-input
                              type="tel"
                              :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                              v-model="form.phone_number"
                              class="form-control main-control"
                              placeholder="Phone Number"
                            ></b-form-input>
                            <VValidate name="Phone Number" v-model="form.phone_number" :rules="{min:5, max:16}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Country Guided</label>
                            <b-badge
                              variant="info"
                              class="help-badge custom-badge-info ml-3"
                              v-b-tooltip.hover.right="'Country where you give guided tours'"
                              id="country-tooltip"
                              pill
                              @click.prevent
                            >
                              <b-icon icon="question" class="question-icon" font-scale="1"></b-icon>
                            </b-badge>
                            <input
                              type="text"
                              v-model="form.country"
                              class="form-control main-control"
                              placeholder="e.g. Chicago"
                            />
                            <VValidate 
                              name="Country Guided"
                              v-model="form.country"
                              :rules="{required: true, max: 128}"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Region or City Guided</label>
                            <b-badge
                              variant="info"
                              class="help-badge custom-badge-info ml-3"
                              v-b-tooltip.hover.right="'Region or city where you give guided tours'"
                              id="region-tooltip"
                              pill
                              @click.prevent
                            >
                              <b-icon icon="question" class="question-icon" font-scale="1"></b-icon>
                            </b-badge>
                            <input
                              type="text"
                              v-model="form.city"
                              class="form-control main-control"
                              placeholder="e.g. Chicago"
                            />
                            <VValidate name="Region or City" v-model="form.city" :rules="{required: true, max:128}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group add-remove">
                            <label for="" class="required bold">Tour Languages</label>
                            <div>
                              <input
                                type="text"
                                v-model="form.tour_languages"
                                class="form-control main-control mr-2"
                                placeholder="e.g. English, Indonesia"
                              />
                            </div>
                            <VValidate name="Tour Languages" v-model="form.tour_languages" :rules="{required: true, min: 3, max: 128}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="currentStep == 1">
                    <h4 class="extrabold">Additional Information</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="bold">Website</label>
                            <input
                              type="text"
                              v-model="form.website"
                              class="form-control main-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Facebook</label>
                            <input
                              type="text"
                              v-model="form.facebook"
                              class="form-control main-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Twitter</label>
                            <input
                              type="text"
                              v-model="form.twitter"
                              class="form-control main-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Instagram</label>
                            <input
                              type="text"
                              v-model="form.instagram"
                              class="form-control main-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Linkedin</label>
                            <input
                              type="text"
                              v-model="form.linkedin"
                              class="form-control main-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="bold">Youtube Channel</label>
                            <input
                              type="text"
                              v-model="form.youtube"
                              class="form-control main-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Presentation</label>
                            <textarea
                              type="text"
                              v-model="form.presentation"
                              class="form-control main-control text-area"
                              placeholder="Write a few lines about yourself that will feature on your profile..."
                            ></textarea>
                            <VValidate name="Presentation" v-model="form.presentation" :rules="{required: true, min: 10, max: 500}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="currentStep == 2">
                    <h4 class="extrabold">Information Detail</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="tphoto" class="required bold">Upload the Tour Guide photo</label>
                            <ValidationProvider name="Tour Guide Photo" rules="required" ref="fileTourGuide" v-slot="{ validate, errors }">
                              <input
                                id="tphoto"
                                type="file"
                                ref="fileTourGuide"
                                class="form-control main-control"
                                placeholder="https://"
                                @change="pickFile($event, 'filetourguide', 'fileTourGuide')"
                                accept=".jpg,.jpeg,.png"
                              />
                              <b-form-text> Format accepted: .jpg, .jpeg, .png </b-form-text>
                              <b-form-text text-variant="danger">{{ errors[0] }}</b-form-text>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div :class="form.is_certified == 'Y' ? 'col-md-6' : 'col-md-12'">
                          <div class="form-group">
                            <label for="" class="required bold">I am a certified tour guide</label>
                            <div class="d-flex mt-3">
                              <b-form-radio v-model="form.is_certified" name="certified-tour" value="N"
                                >No</b-form-radio
                              >
                              <b-form-radio v-model="form.is_certified" class="ml-3" name="certified-tour" value="Y"
                                >Yes</b-form-radio
                              >
                            </div>
                            <VValidate name="Certified Tour Guide" v-model="form.is_certified" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group mt-2">
                            <label for="mfu" class="required bold">Message For Us</label>
                            <textarea
                              id="mfu"
                              v-model="form.message"
                              type="text"
                              class="form-control main-control text-area"
                              placeholder="Enter message..."
                            ></textarea>
                            <VValidate name="Message For Us" v-model="form.message" :rules="{required: true, min: 10, max: 500}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center">
                    <button v-if="currentStep != 2" class="btn btn-slg btn-primary bold w-100">Next</button>
                    <button v-else class="btn btn-slg btn-primary bold w-100">Send</button>
                    <a v-if="currentStep != 0" @click="previousStep" class="text-link medium pointer mt-3"
                      >Back to previous step</a
                    >
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
          <div class="col-lg-5 tour-guide-hero">
            <div
              class="right-section h-100"
              :style="sideBackground"
              :class="isMobile() ? '' : 'h-100'"
            >
              <div class="bg-overlay" data-style="13"></div>
              <div>
                <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                  <div>
                    <router-link :to="{ name: 'Home' }">
                      <picture>
                        <source
                          media="(min-width: 992px)"
                          :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                          type="image/webp"
                        />
                        <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                        <img
                          class="brand-logo"
                          v-if="isOnlyMobile()"
                          :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="142"
                          height="43"
                        />
                        <img
                          class="brand-logo"
                          v-else
                          :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="175"
                          height="53"
                        />
                      </picture>
                    </router-link>
                  </div>
                  <div class="mt-5">
                    <div class="label-custom green d-inline">
                      <span class="black">Work With Us</span>
                    </div>
                    <div class="title-content mt-4">
                      <h2 class="text-white">Join Us As A</h2>
                      <p style="color: #f7af2f">Tour Guide</p>
                    </div>
                    <span class="text-white bold d-block mt-4"
                      >We are looking for passionate tour guides to assist our runners. We value clear communication and
                      responsibility</span
                    >
                  </div>
                  <div class="mt-5">
                    <router-link :to="{ name: 'WWULPTourGuide' }" class="text-white text-underline bold"
                      >Back to Previous Page</router-link
                    >
                    <div
                      v-if="!isMobile()"
                      class="d-flex justify-content-between"
                      :class="isOnlyMobile() ? 'flex-column text-center' : ''"
                    >
                      <div class="copyright-text text-white mt-5">
                        Copyright &copy; 2023 RunDeGlobe. All Rights Reserved
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>

    <AppModal :withPageTitle="false" ref="confirm-data-modal" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img
                :src="`${baseUrl}/assets/images/vector/confirm-vect.svg`"
                alt="Confirmation"
                class="w-25"
              />
              <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
              <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
              <ul>
                <li>Email: {{ form.email_address || 'abraham.damar@example.com' }}</li>
              </ul>
              <div class="d-flex">
                <button type="button" class="btn btn-outline-main ml-0 bold" @click="onChangeData">Edit data</button>
                <button type="button" class="btn btn-primary bold" @click="onConfirmData">Confirm data</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <AppAlert
      ref="modal-error"
      vref="modal-error"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
      }"
      icon-size="w-50"
      :title="messageErrTitle"
      :description="messageErrDescription"
      :button="{
        main: {
          text: 'Ok',
        },
      }"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import StepProgress from 'vue-step-progress'
import Global from '../libs/Global.vue'
import 'vue-step-progress/dist/main.css'
import AppModal from '../components/AppModal.vue'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import axios from 'axios'
import AppAlert from '@/components/AppAlert'

export default {
  extends: Global,
  name: 'WWUTourGuide',
  components: {
    'step-progress': StepProgress,
    AppModal,
    AppAlert,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.isSubmitting) {
      if (confirm('Are you sure you want to leave? Your changes may not be saved.')) {
        this.isDirty = false
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  data() {
    return {
      isWebPSupport: false,
      isLoaded: false,
      isSubmitting: false,
      isDirty: false,
      messageErrTitle: '',
      messageErrDescription: '',
      countries: [
        {
          name: 'Indonesia',
        },
        {
          name: 'United States',
        },
        {
          name: 'Germany',
        },
      ],
      mySteps: ['1', '2', '3'],
      currentStep: 0,
      isSelectedCertified: 'N',
      tabsOverflow: 0,
      form: {
        title: '',
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        country: '',
        city: '',
        tour_languages: '',
        website: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        youtube: '',
        presentation: '',
        is_certified: '',
        message: '',
        tour_guide_photo: '',
        guide_card: '',
      },
      conf: {
        rule_type: 'jpeg,jpg,png',
        max_image_size: 5000000,
      },
      previewImage: null,
      fileType: null,
      fileData: {
        filetourguide: null,
        fileguidecard: null,
      },
    }
  },
  computed: {
    ...mapFields('tourguide', ['formContent']),
    ...mapFields(['channels']),
    isLastStep() {
      return this.currentStep === this.mySteps.length - 1
    },
    sideBackground() {
      let src = ''
      
      if (this.isWebPSupport) {
        src = this.uploader((this.formContent || {}).banner_img_section)
      } else {
        src = this.uploader((this.formContent || {}).banner_fallback_img_section)
      }

      if (src == '') {
        return null
      }

      return `background: url("${src}") center center / cover;`
    },
  },
  created() {
    this.getDataContent(() => {
      this.form = {
        title: '',
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        country: '',
        city: '',
        tour_languages: '',
        website: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        youtube: '',
        presentation: '',
        is_certified: '',
        message: '',
        tour_guide_photo: '',
        guide_card: '',
      }
    })

    this.getChannels()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('tourguide', ['applyTourGuide', 'getDataContent']),
    ...mapActions(['getChannels']),
    scrollToLeftSection() {
      const el = document.querySelector('.left-section')

      if (el) {
        const elPosition = el.getBoundingClientRect().top
        const offsetPosition = elPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition - 0,
          behavior: 'smooth',
        })
      }
    },

    onChangeData() {
      this.$refs['confirm-data-modal'].hide()
    },

    onConfirmData() {
      this.submitWWUTourGuideForm()
    },

    nextStep() {
      if (this.isLastStep) {
        return this.$refs['confirm-data-modal'].show()
      }

      if (this.mySteps.length != this.currentStep + 1) {
        this.currentStep++
      }
      
      document.querySelector('.ov-scroll').scrollTo({top: 0, behavior: 'smooth'})
    },

    previousStep() {
      if (this.currentStep != 0) {
        this.currentStep--
      }
      this.scrollToLeftSection()
      const el = (document.querySelector('.left-section').scrollTop -= 200)
      this.tabsoverflow = el
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    async pickFile(e, dataToBeBound, id) {
      this.$refs[id].validate(e);

      if (e.target.value === '') {
        return
      }

      const input = this.$refs[id]
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }

      this.fileData[dataToBeBound] = e.target.files[0]

      this.conf.fileType = (this.fileData[dataToBeBound] || {}).type

      this.fileType = ((this.fileData[dataToBeBound] || {}).name || '')
        .substr(((this.fileData[dataToBeBound] || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()
    },

    uploadFormFile(dataToBeBound) {
      // Validation
      this.error = false

      if (this.fileData[dataToBeBound] === null) {
        this.messageErrTitle = `File is required.`
        this.$refs['modal-error'].show()
        return null
      }

      if (this.conf.rule_type.indexOf(this.fileType) == -1) {
        this.messageErrTitle = `File type must be ${this.conf.rule_type} type.`
        this.$refs['modal-error'].show()
      }

      if (this.fileData[dataToBeBound] !== null) {
        if (this.fileData[dataToBeBound].size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          this.messageErrTitle = 'File too big (> 5MB)'
          this.$refs['modal-error'].show()
          return null
        }
        if (this.error) {
          return alert(this.error)
        }

        const path = 'tour_guide'

        const formData = new FormData()

        formData.set('file', this.fileData[dataToBeBound])

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },

    async submitWWUTourGuideForm() {
      this.isSubmitting = true
      this.$refs['confirm-data-modal'].hide()

      this.$store.state.loading.screen = true

      const tour_guide_photo = await this.uploadFormFile('filetourguide')
      const guide_card = await this.uploadFormFile('fileguidecard')

      this.form.tour_guide_photo = ((tour_guide_photo || {}).data || {}).fallback_pathfile
      this.form.guide_card = ((guide_card || {}).data || {}).fallback_pathfile

      this.applyTourGuide({
        form: this.form,
        callback: (success) => {
          this.$store.state.loading.screen = false

          if (!success) {
            return
          }

          this.$router.push({ name: 'ConfirmWWUTourGuide' })
          this.isSubmitting = false
        },
      })
    },
  },
  watch: {
    'form': {
      handler() {
        if (this.isLoaded) {
          this.isDirty = true
        }

        this.isLoaded = true
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.form-select.main-control {
  border-radius: 2em;
  min-height: 45px !important;
  margin-top: 1em;
  font-size: 1em !important;
  font-family: 'Metropolis-Semibold' !important;
}
.form-select.main-control::placeholder {
  color: #cccccc !important;
  opacity: 1;
  font-family: 'Metropolis-Semibold' !important;
}

.badge.help-badge {
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0;
  font-size: 9px;
  margin-left: 4px;
  vertical-align: top;
  border-radius: 50% !important;
}

.badge.help-badge .question-icon {
  font-size: 1.875em;
}
</style>

<template>
  <div>
    <p class="d-none">OK</p>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'CallbackComponent',
  created () {
    this.onCallback()
  },
  methods: {
    ...mapActions('useCallbackStore', [
      'doCallback'
    ]),

    ...mapActions('login', [
      'doLoginWithAthletes'
    ]),
    onCallback() {
      try {
        const authorizationCode = this.$route.query.code
        const res = this.doCallback({
          form: {
            code: authorizationCode
          },
          callback: (success, res) => {
            if(!success) {
              return
            }
            this.doLoginWithAthletes(res)
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

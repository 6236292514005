<template>
  <div>
    <section id="eventlist">
      <div class="titlepage-background grey d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div :class="!isMobile() ? 'header-notbanner' : 'header-notbanner mb-5'">
                <h3 class="title-notbanner">{{ (((aboutFollowupQuestions || {}).text || {}).form || {}).title }}</h3>
                <div class="mt-3" v-if="isMobile()">
                  <div class="sec-icon sticky_10 payment bg-white">
                    <p>
                      Transaction ID:
                      <span class="title fw-600">{{
                        ((aboutFollowupQuestions || []).transaction_detail || {}).transaction_id
                      }}</span>
                    </p>
                    <hr class="border-grey" />
                    <div class="sec-icon mt-0 d-flex pb-3">
                      <div class="desc">
                        <p class="fw-bold">{{ ((aboutFollowupQuestions || []).event_detail || {}).event_name }}</p>
                        <span
                          >{{ ((aboutFollowupQuestions || []).transaction_detail || {}).itinerary_name }},
                          {{ ((aboutFollowupQuestions || []).transaction_detail || {}).package_name }}</span
                        >
                        <br />
                        <span class="text-gray light"
                          >Race Date:
                          {{ formatDate(((aboutFollowupQuestions || []).event_detail || {}).event_race_date) }}</span
                        >
                      </div>
                    </div>
                    <p class="my-4">
                      {{ (((aboutFollowupQuestions || {}).text || {}).legal || {}).text }}
                      <router-link class="color-main underline pointer" :to="{ name: 'PrivacyPolicy' }"
                        >Privacy Policy</router-link
                      >
                      and
                      <router-link class="color-main underline pointer" :to="{ name: 'TermsConditions' }"
                        >Terms & Conditions</router-link
                      >.
                    </p>
                  </div>
                </div>
                <div class="mt-3" :class="isMobile() ? 'bg-transparent-white p-3 rounded-1rem' : ''">
                  <span class="light" :class="isMobile() ? 'fs-1rem color-main' : 'color-grey'">{{
                    (((aboutFollowupQuestions || {}).text || {}).form || {}).description
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="!isMobile() ? 'mt-5 mb-10' : 'content-mobile mb-5'">
        <div class="container">
          <div class="row d-flex justify-content-between">
            <div class="col-md-7" v-if="(aboutFollowupQuestions || {}).is_followupquestion == 'Y'">
              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <b-form @submit.prevent="handleSubmit(saveAll)">
                  <div class="sec-icon with-button">
                    <div class="top">
                      <div class="icon">
                        <i class="icon-user fs-125em"></i>
                      </div>
                      <h5 class="bold">Personal Information <span class="text-danger">*</span></h5>
                    </div>
                    <div :class="!isMobile() ? 'mt-3' : 'justify-content-end'">
                      <a class="link-button" v-b-modal="'modal-personal-information'">
                        <span>Edit</span>
                      </a>
                    </div>
                  </div>
                  <div class="sec-icon with-button">
                    <div class="top">
                      <div class="icon">
                        <i class="icon-phone fs-125em"></i>
                      </div>
                      <h5 class="bold">Contact Information <span class="text-danger">*</span></h5>
                    </div>
                    <div :class="!isMobile() ? 'mt-3' : 'justify-content-end'">
                      <a class="link-button" v-b-modal="'modal-contact-information'">
                        <span>Edit</span>
                      </a>
                    </div>
                  </div>
                  <div class="sec-icon with-button">
                    <div class="top">
                      <div class="icon">
                        <i class="icon-user-plus fs-125em"></i>
                      </div>
                      <h5 class="bold">Emergency Contact <span class="text-danger">*</span></h5>
                    </div>
                    <div :class="!isMobile() ? 'mt-3' : 'justify-content-end'">
                      <a class="link-button" v-b-modal="'modal-emergency-contact'">
                        <span>Edit</span>
                      </a>
                    </div>
                  </div>

                  <template v-if="(followUpQuestions || {}).confirmation_package_questions">
                    <div
                      class="sec-icon pb-0"
                      v-for="(v, k) in (followUpQuestions || {}).confirmation_package_questions"
                      :key="k"
                    >
                      <div class="top">
                        <div class="icon">
                          <i class="icon-user-plus fs-125em"></i>
                        </div>
                        <h5 class="bold">{{ v.category }}</h5>
                      </div>
                      <div class="content-2-column column-direction" v-for="(v1, k1) in v.questions" :key="k1">
                        <div class="w-100">
                          <div class="d-flex flex-column">
                            <span class="w-100"
                              >{{ v1.question }}
                              <b
                                class="text-danger"
                                v-if="
                                  v1.validations || (v1.validations || []).length > 0
                                    ? (v1.validations || []).includes('Required') ||
                                      (v1.validations || []).includes('required')
                                    : false
                                "
                                >*</b
                              ></span
                            >
                            <picture v-if="(v1 || {}).image" class="mb-4">
                              <source :srcset="uploader((v1 || {}).image)" type="image/webp" />
                              <img
                                :src="uploader((v1 || {}).fallback_image)"
                                :alt="(v1 || {}).alt_image"
                                class="w-100 border-grey mt-3"
                              />
                            </picture>
                          </div>
                          <div class="w-100 mt-1" v-if="v1.type === 'Text'">
                            <b-form-input
                              type="text"
                              v-model="v1.answer"
                              :placeholder="v1.placeholder"
                              class="mt-2"
                            ></b-form-input>
                            <span
                              v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                              :class="v1.description ? 'mb-3' : ''"
                              class="d-block w-100 light mt-3 color-grey"
                              >{{ v1.description }}</span
                            >
                            <VValidate
                              :name="v1.short"
                              v-model="v1.answer"
                              :rules="
                                v1.validations || (v1.validations || []).length > 0
                                  ? (v1.validations || []).join('|').toLowerCase()
                                  : ''
                              "
                            />
                          </div>

                          <div class="w-100 mt-1" v-if="v1.type == 'Time'">
                            <vue-timepicker
                              manual-input
                              hide-clear-button
                              v-model="v1.answer"
                              format="HH:mm:ss"
                              input-class="px-3 form-control"
                              readonly="false"
                              drop-direction="auto"
                              input-width="100%"
                            ></vue-timepicker>
                            <span
                              v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                              :class="v1.description ? 'mb-3' : ''"
                              class="d-block w-100 light mt-3 color-grey"
                              >{{ v1.description }}</span
                            >
                            <VValidate
                              :name="v1.short"
                              v-model="v1.answer"
                              :rules="
                                v1.validations || (v1.validations || []).length > 0
                                  ? (v1.validations || []).join('|').toLowerCase()
                                  : ''
                              "
                            />
                          </div>

                          <div class="w-100 mt-1" v-if="v1.type === 'Select Option'">
                            <b-form-group class="normal">
                              <v-select
                                v-model="v1.answer"
                                :placeholder="'-- Select ' + v1.short + ' --'"
                                :options="v1.options"
                                :reduce="(v) => v"
                                class="mt-2"
                              />
                              <span
                                v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                                :class="v1.description ? 'mb-3' : ''"
                                class="d-block w-100 light mt-3 color-grey"
                                >{{ v1.description }}</span
                              >
                              <VValidate
                                :name="v1.short"
                                v-model="v1.answer"
                                :rules="
                                  v1.validations || (v1.validations || []).length > 0
                                    ? (v1.validations || []).join('|').toLowerCase()
                                    : ''
                                "
                              />
                            </b-form-group>
                          </div>

                          <div class="w-100 mt-1" v-if="v1.type === 'Roommate'">
                            <b-form-group class="normal" v-if="!isMobile()">
                              <div>
                                <div v-for="(v2, k2) in v1.answer" :key="k2" class="mb-3">
                                  <div>
                                    <span
                                      v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                                      :class="v1.description ? 'mb-3' : ''"
                                      class="d-block w-100 light mt-3 color-grey"
                                      >{{ v1.description }}</span
                                    >
                                    <div class="d-flex mb-3">
                                      <b-form-input
                                        type="text"
                                        v-model="((v1.answer || [])[k2] || {}).full_name"
                                        placeholder="Full Name"
                                      ></b-form-input>
                                      <v-select
                                        v-model="((v1.answer || [])[k2] || {}).gender"
                                        placeholder="-- Select Gender --"
                                        :options="mrGender"
                                        :reduce="(v) => v"
                                        class="w-50 mx-2"
                                      />
                                      <b-form-input
                                        type="text"
                                        v-model.number="(v1.answer[k2] || {}).age"
                                        placeholder="Age"
                                        class="w-25"
                                        maxlength="3"
                                        :formatter="(v) => v.replace(/\D/, '')"
                                      ></b-form-input>
                                      <button
                                        v-if="followUpQuestions.package_max_person > 1"
                                        type="button"
                                        class="button-custom success mx-2"
                                        @click="addInput(v1.answer, k2)"
                                      >
                                        <i class="icon-plus"></i>
                                      </button>
                                      <button
                                        type="button"
                                        v-if="(v1.answer || {}).length > 1"
                                        class="button-custom danger mx-1"
                                        @click="removeInput(v1.answer, k2)"
                                      >
                                        <i class="icon-trash-2"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <span
                                    v-if="k2 + 1 > followUpQuestions.package_max_person - 1"
                                    class="w-100 light fs-1rem mt-3 color-yellow"
                                    >* This may costs an extra bed</span
                                  >
                                  <div class="d-block mt-3">
                                    <VValidate
                                      name="Full Name"
                                      v-model="((v1.answer || [])[k2] || {}).full_name"
                                      rules="required"
                                    />
                                  </div>
                                  <div class="d-block mt-3">
                                    <VValidate
                                      name="Gender"
                                      v-model="((v1.answer || [])[k2] || {}).gender"
                                      rules="required"
                                    />
                                  </div>
                                  <div class="d-block mt-3">
                                    <VValidate
                                      name="Age"
                                      v-model="((v1.answer || [])[k2] || {}).age"
                                      rules="required"
                                    />
                                  </div>
                                </div>
                              </div>
                            </b-form-group>
                            <b-form-group v-if="isMobile()">
                              <div v-for="(v2, k2) in v1.answer" :key="k2" class="mb-3">
                                <div>
                                  <div class="d-flex mb-3">
                                    <div class="d-flex flex-column">
                                      <span
                                        v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                                        :class="v1.description ? 'mb-3' : ''"
                                        class="d-block w-100 light mt-3 color-grey"
                                        >{{ v1.description }}</span
                                      >
                                      <div class="d-flex">
                                        <b-form-input
                                          type="text"
                                          v-model="(v1.answer[k2] || {}).full_name"
                                          placeholder="Full Name"
                                        ></b-form-input>
                                        <button
                                          v-if="followUpQuestions.package_max_person > 1"
                                          type="button"
                                          class="button-custom success align-self-start mx-2"
                                          @click="addInput(v1.answer, k2)"
                                        >
                                          <i class="icon-plus"></i>
                                        </button>
                                        <button
                                          type="button"
                                          v-if="(v1.answer || []).length > 1"
                                          class="button-custom danger align-self-start mx-1"
                                          @click="removeInput(v1.answer, k2)"
                                        >
                                          <i class="icon-trash-2"></i>
                                        </button>
                                      </div>
                                      <div class="d-flex mt-3">
                                        <v-select
                                          v-model="(v1.answer[k2] || {}).gender"
                                          placeholder="Gender"
                                          :options="mrGender"
                                          :reduce="(v) => v"
                                          class="w-100"
                                        />
                                        <b-form-input
                                          type="text"
                                          v-model.number="(v1.answer[k2] || {}).age"
                                          placeholder="Age"
                                          :formatter="(v) => v.replace(/\D/, '')"
                                          class="w-75 mx-2"
                                        ></b-form-input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <span
                                  v-if="k2 + 1 > followUpQuestions.package_max_person - 1"
                                  class="w-100 light fs-1rem mt-3 color-yellow"
                                  >* This may cost an extra bedsss</span
                                >
                                <div class="d-block mt-3">
                                  <VValidate
                                    name="Full Name"
                                    v-model="((v1.answer || [])[k2] || {}).full_name"
                                    rules="required"
                                  />
                                </div>
                                <div class="d-block mt-3">
                                  <VValidate
                                    name="Gender"
                                    v-model="((v1.answer || [])[k2] || {}).gender"
                                    rules="required"
                                  />
                                </div>
                                <div class="d-block mt-3">
                                  <VValidate name="Age" v-model="((v1.answer || [])[k2] || {}).age" rules="required" />
                                </div>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div
                    class="sec-icon pb-0"
                    v-for="(v, k) in (followUpQuestions || {}).confirmation_questions"
                    :key="'confirmation-question-' + k"
                  >
                    <div class="top">
                      <div class="icon">
                        <i class="icon-list fs125em"></i>
                      </div>
                      <h5 class="bold">{{ v.category }}</h5>
                    </div>
                    <div class="content-2-column column-direction" v-for="(v1, k1) in v.questions" :key="k1">
                      <div class="w-100">
                        <div class="d-flex flex-column">
                          <span class="w-100"
                            >{{ v1.question }}
                            <b
                              class="text-danger"
                              v-if="
                                v1.validations || (v1.validations || []).length > 0
                                  ? (v1.validations || []).includes('Required') ||
                                    (v1.validations || []).includes('required')
                                  : false
                              "
                              >*</b
                            ></span
                          >
                          <picture v-if="(v1 || {}).image" class="mb-4">
                            <source :srcset="uploader((v1 || {}).image)" type="image/webp" />
                            <img
                              :src="uploader((v1 || {}).fallback_image)"
                              :alt="(v1 || {}).alt_image"
                              class="w-100 border-grey mt-3"
                            />
                          </picture>
                        </div>
                        <div class="w-100 mt-1" v-if="v1.type === 'Text'">
                          <b-form-input
                            type="text"
                            v-model="v1.answer"
                            :placeholder="v1.placeholder"
                            class="mt-2"
                          ></b-form-input>
                          <span
                            v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                            :class="v1.description ? 'mb-3' : ''"
                            class="d-block w-100 light mt-3 color-grey"
                            >{{ v1.description }}</span
                          >
                          <VValidate
                            :name="v1.short"
                            v-model="v1.answer"
                            :rules="
                              v1.validations || (v1.validations || []).length > 0
                                ? (v1.validations || []).join('|').toLowerCase()
                                : ''
                            "
                          />
                        </div>

                        <div class="w-100 mt-1" v-if="v1.type == 'Time'">
                          <vue-timepicker
                            manual-input
                            hide-clear-button
                            v-model="v1.answer"
                            format="HH:mm:ss"
                            input-class="form-control"
                            readonly="false"
                            drop-direction="auto"
                            input-width="100%"
                          ></vue-timepicker>
                          <VValidate
                            :name="v1.short"
                            v-model="v1.answer"
                            :rules="
                              v1.validations || (v1.validations || []).length > 0
                                ? (v1.validations || []).join('|').toLowerCase()
                                : ''
                            "
                          />
                        </div>

                        <div class="w-100 mt-1" v-if="v1.type === 'Select Option'">
                          <b-form-group class="normal">
                            <v-select
                              v-model="v1.answer"
                              :placeholder="'-- Select ' + v1.short + ' --'"
                              :options="v1.options"
                              :reduce="(v) => v"
                              class="mt-2"
                            />
                            <VValidate
                              :name="v1.short"
                              v-model="v1.answer"
                              :rules="
                                v1.validations || (v1.validations || []).length > 0
                                  ? (v1.validations || []).join('|').toLowerCase()
                                  : ''
                              "
                            />
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="my-5 d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary bold">
                      <span>Save</span>
                    </button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
            <div class="col-md-7" v-if="(aboutFollowupQuestions || {}).is_followupquestion == 'N'">
              <div class="sec-icon with-button">
                <div class="top">
                  <div class="icon">
                    <i class="icon-user"></i>
                  </div>
                  <h5 class="bold">Personal Information <span class="text-danger">*</span></h5>
                </div>
                <div :class="!isMobile() ? 'mt-3' : 'justify-content-end'">
                  <a class="link-button" v-b-modal="'modal-personal-information'">
                    <span>See Detail</span>
                  </a>
                </div>
              </div>
              <div class="sec-icon with-button">
                <div class="top">
                  <div class="icon">
                    <i class="icon-phone fs-125em"></i>
                  </div>
                  <h5 class="bold">Contact Information <span class="text-danger">*</span></h5>
                </div>
                <div :class="!isMobile() ? 'mt-3' : 'justify-content-end'">
                  <a class="link-button" v-b-modal="'modal-contact-information'">
                    <span>See Detail</span>
                  </a>
                </div>
              </div>
              <div class="sec-icon with-button">
                <div class="top">
                  <div class="icon">
                    <i class="icon-phone fs-125em"></i>
                  </div>
                  <h5 class="bold">Emergency Contact <span class="text-danger">*</span></h5>
                </div>
                <div :class="!isMobile() ? 'mt-3' : 'justify-content-end'">
                  <a class="link-button" v-b-modal="'modal-emergency-contact'">
                    <span>See Detail</span>
                  </a>
                </div>
              </div>

              <template v-if="(followUpQuestions || {}).confirmation_package_questions">
                <div
                  class="sec-icon pb-0"
                  v-for="(v, k) in (followUpQuestions || {}).confirmation_package_questions"
                  :key="k"
                >
                  <div class="top">
                    <div class="icon">
                      <i class="icon-list fs-125em"></i>
                    </div>
                    <h5 class="bold">{{ v.category }}</h5>
                  </div>
                  <div class="content-2-column column-direction" v-for="(v1, k1) in v.questions" :key="k1">
                    <div class="w-100">
                      <div class="d-flex flex-column">
                        <span class="w-100 light"
                          >{{ v1.question }}
                          <b
                            class="text-danger"
                            v-if="
                              v1.validations || (v1.validations || []).length > 0
                                ? (v1.validations || []).includes('Required') ||
                                  (v1.validations || []).includes('required')
                                : false
                            "
                            >*</b
                          ></span
                        >
                        <span
                          v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                          :class="v1.description ? 'mb-3' : ''"
                          class="d-block w-100 light mt-3 color-grey"
                          >{{ v1.description }}</span
                        >
                        <picture v-if="(v1 || {}).image" class="mb-4">
                          <source :srcset="uploader((v1 || {}).image)" type="image/webp" />
                          <img
                            :src="uploader((v1 || {}).fallback_image)"
                            :alt="(v1 || {}).alt_image"
                            class="w-100 border-grey mt-3"
                          />
                        </picture>
                        <div class="w-100 mt-1">
                          <template v-if="typeof v1.answer == 'object'">
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead class="thead-light">
                                  <tr>
                                    <td v-for="(value, name) in v1.answer[0]" :key="name">{{ humanize(name) }}</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(list, indexList) in v1.answer" :key="indexList">
                                    <td v-for="(value, name) in list" :key="name" class="light">{{ value }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </template>
                          <p v-else class="w-100">
                            Answer: <span class="bold">{{ v1.answer != '' || v1.answer != null ? v1.answer : '-' }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="sec-icon pb-0" v-for="(v, k) in (followUpQuestions || {}).confirmation_questions" :key="k">
                <div class="top">
                  <div class="icon">
                    <i class="icon-list fs-125em"></i>
                  </div>
                  <h5 class="bold">{{ v.category }}</h5>
                </div>
                <div class="content-2-column column-direction" v-for="(v1, k1) in v.questions" :key="k1">
                  <div class="w-100">
                    <div class="d-flex flex-column">
                      <span class="w-100 light"
                        >{{ v1.question }}
                        <b
                          class="text-danger"
                          v-if="
                            v1.validations || (v1.validations || []).length > 0
                              ? (v1.validations || []).includes('Required') ||
                                (v1.validations || []).includes('required')
                              : false
                          "
                          >*</b
                        ></span
                      >
                      <span
                        v-if="Object.prototype.hasOwnProperty.call(v1, 'description')"
                        :class="v1.description ? 'mb-3' : ''"
                        class="d-block w-100 light mt-3 color-grey"
                        >{{ v1.description }}</span
                      >
                      <picture v-if="(v1 || {}).image" class="mb-4">
                        <source :srcset="uploader((v1 || {}).image)" type="image/webp" />
                        <img
                          :src="uploader((v1 || {}).fallback_image)"
                          :alt="(v1 || {}).alt_image"
                          class="w-100 border-grey mt-3"
                        />
                      </picture>
                      <div class="w-100 mt-1">
                        <p class="w-100">Answer: <span class="bold">{{ v1.answer != '' || v1.answer != null ? v1.answer : '-' }}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal
                ref="modal-personal-information"
                id="modal-personal-information"
                scrollable
                :centered="!isMobile()"
                title="Personal Information"
                size="lg"
                :no-fade="isMobile()"
              >
                <template #modal-header="{ close }">
                  <!-- Emulate built in modal header close button action -->
                  <b-button
                    class="icon-modal w-100 d-flex justify-content-between align-items-center"
                    size="md"
                    @click="close()"
                  >
                    <h5 class="text-center text-dark mt-2 ml-4">Personal Information</h5>
                    <i class="icon-x"></i>
                  </b-button>
                </template>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="sec-icon p-0 border-none">
                        <div class="content-2-column">
                          <p class="light">Gender:</p>
                          <p>{{ followUpQuestions.confirmation_gender }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">Nationality:</p>
                          <p>
                            {{
                              followUpQuestions.confirmation_nationality == 'WNI' ? 'Indonesian Citizen' : 'Foreigner'
                            }}
                          </p>
                        </div>
                        <div class="content-2-column" v-if="followUpQuestions.confirmation_nationality == 'WNA'">
                          <p class="light">Citizenship:</p>
                          <p>{{ followUpQuestions.confirmation_citizenship_name }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">First & Middle Name:</p>
                          <p>{{ followUpQuestions.confirmation_first_name }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">Last Name:</p>
                          <p>{{ followUpQuestions.confirmation_last_name }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">Passport Expiration Date:</p>
                          <p>{{ formatDate(followUpQuestions.confirmation_passport_exp) }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">Date of Birth:</p>
                          <p>{{ formatDate(followUpQuestions.confirmation_birth_date) }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">Blood Type:</p>
                          <p>{{ followUpQuestions.confirmation_blood_type || '-' }}</p>
                        </div>
                        <div class="content-2-column">
                          <p class="light">Instagram:</p>
                          <p>
                            {{
                              followUpQuestions.confirmation_instagram ? followUpQuestions.confirmation_instagram : '-'
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #modal-footer>
                  <div class="d-flex justify-content-end">
                    <button
                      @click="hideModal('modal-personal-information')"
                      class="main-button linear-primary text-uppercase"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </template>
              </b-modal>

              <b-modal
                ref="modal-contact-information"
                id="modal-contact-information"
                scrollable
                :centered="!isMobile()"
                size="lg"
                :no-fade="isMobile()"
              >
                <template #modal-header="{ close }">
                  <!-- Emulate built in modal header close button action -->
                  <b-button
                    class="icon-modal w-100 d-flex justify-content-between align-items-center"
                    size="md"
                    @click="close()"
                  >
                    <h5 class="text-center text-dark mt-2 ml-4">Contact Information</h5>
                    <i class="icon-x"></i>
                  </b-button>
                </template>
                <div class="container-fluid">
                  <div class="row">
                    <div class="sec-icon p-0 border-none">
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Email Address:</p>
                          <p>{{ followUpQuestions.confirmation_email_address }}</p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Phone Number:</p>
                          <p>{{ followUpQuestions.confirmation_mobile_phone }}</p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Country of Residence:</p>
                          <p>
                            {{
                              followUpQuestions.confirmation_country_of_residence_fo
                                ? followUpQuestions.confirmation_country_of_residence_fo
                                : '-'
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Street Address 1:</p>
                          <p>
                            {{
                              followUpQuestions.confirmation_address_1 ? followUpQuestions.confirmation_address_1 : '-'
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Street Address 2 <span class="small">(optional)</span>:</p>
                          <p>
                            {{
                              followUpQuestions.confirmation_address_2 ? followUpQuestions.confirmation_address_2 : '-'
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Street Address 3 <span class="small">(optional)</span>:</p>
                          <p>
                            {{
                              followUpQuestions.confirmation_address_3 ? followUpQuestions.confirmation_address_3 : '-'
                            }}
                          </p>
                        </div>
                      </div>
                      <template v-if="followUpQuestions.confirmation_country_of_residence_flag == 'WNI'">
                        <div class="col-md-12">
                          <div class="content-2-column">
                            <p class="light">Province:</p>
                            <p>{{ followUpQuestions.confirmation_state }}</p>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="content-2-column">
                            <p class="light">City:</p>
                            <p>{{ followUpQuestions.confirmation_city }}</p>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="content-2-column">
                            <p class="light">District:</p>
                            <p>{{ followUpQuestions.confirmation_district }}</p>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="content-2-column">
                            <p class="light">Sub District:</p>
                            <p>{{ followUpQuestions.confirmation_subdistrict }}</p>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="content-2-column">
                            <p class="light">Zip Code:</p>
                            <p>{{ followUpQuestions.confirmation_zip_code }}</p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <template #modal-footer>
                  <div class="d-flex justify-content-end">
                    <button
                      @click="hideModal('modal-contact-information')"
                      class="main-button linear-primary text-uppercase"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </template>
              </b-modal>

              <b-modal
                ref="modal-emergency-contact"
                class="visible"
                id="modal-emergency-contact"
                scrollable
                :centered="!isMobile()"
                size="lg"
                :no-fade="isMobile()"
              >
                <template #modal-header="{ close }">
                  <!-- Emulate built in modal header close button action -->
                  <b-button
                    class="icon-modal w-100 d-flex justify-content-between align-items-center"
                    size="md"
                    @click="close()"
                  >
                    <h5 class="text-center text-dark mt-2 ml-4">Emergency Contact Information</h5>
                    <i class="icon-x"></i>
                  </b-button>
                </template>
                <div class="container-fluid">
                  <div class="row">
                    <div class="sec-icon p-0 border-none">
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Full Name:</p>
                          <p>{{ followUpQuestions.confirmation_emergency_full_name }}</p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Phone Number:</p>
                          <p>{{ followUpQuestions.confirmation_emergency_mobile_phone }}</p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="content-2-column">
                          <p class="light">Relation:</p>
                          <p class="capitalize">{{ followUpQuestions.confirmation_emergency_relation }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #modal-footer>
                  <div class="d-flex justify-content-end">
                    <button @click="hideModal('modal-emergency-contact')" class="main-button linear-primary text-uppercase">
                      <span>Close</span>
                    </button>
                  </div>
                </template>
              </b-modal>
              <div class="help_book mt-4">
                <h5 class="title_mobile">Need help?</h5>
                <a
                  :href="
                    'https://api.whatsapp.com/send?phone=' +
                    ((footerData.contact || {}).data || {}).mobile_phone.replaceAll('-', '') +
                    '&text=Hello, I need your assistance for filling up the survey'
                  "
                  target="_blank"
                >
                  <div class="highlight_link mt-3">
                    <div class="sec_instruction">
                      <span>Whatsapp Customer Care</span>
                    </div>
                    <div>
                      <i class="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-4 mb-10 position-relative" :class="!isMobile() ? '' : 'mb-10'" v-if="!isMobile()">
              <div class="sec-icon payment">
                <p>
                  Transaction ID:
                  <span class="title fw-600">{{
                    ((aboutFollowupQuestions || []).transaction_detail || {}).transaction_id
                  }}</span>
                </p>
                <hr class="border-grey" />
                <div class="sec-icon d-flex">
                  <div class="img_modal_preview">
                    <img
                      :src="uploader(((aboutFollowupQuestions || []).event_detail || {}).event_preview_img)"
                      alt=""
                    />
                    <picture>
                      <source
                        :srcset="uploader(((aboutFollowupQuestions || []).event_detail || {}).event_preview_img)"
                        type="image/webp"
                      />
                      <img
                        :src="uploader(((aboutFollowupQuestions || []).event_detail || {}).event_preview_fallback_img)"
                        :alt="uploader(((aboutFollowupQuestions || []).event_detail || {}).event_preview_alt_img)"
                      />
                    </picture>
                  </div>
                  <div class="desc">
                    <p class="fw-bold">{{ ((aboutFollowupQuestions || []).event_detail || {}).event_name }}</p>
                    <span
                      >{{ ((aboutFollowupQuestions || []).transaction_detail || {}).itinerary_name }},
                      {{ ((aboutFollowupQuestions || []).transaction_detail || {}).package_name }}</span
                    >
                    <br />
                    <span class="text-gray light"
                      >Race Date:
                      {{ formatDate(((aboutFollowupQuestions || []).event_detail || {}).event_race_date) }}</span
                    >
                  </div>
                </div>
                <div class="content-2-column price border-bottom-1rem">
                  <p class="light">Runner Name</p>
                  <p>{{ followUpQuestions.confirmation_full_name }}</p>
                </div>
                <p class="my-4">
                  By pressing the button, you agree to our
                  <router-link class="color-main underline pointer" :to="{ name: 'PrivacyPolicy' }"
                    >Privacy Policy</router-link
                  >
                  and
                  <router-link class="color-main underline pointer" :to="{ name: 'TermsConditions' }"
                    >Terms & Conditions</router-link
                  >.
                </p>
              </div>
              <div class="help_book mt-4">
                <h5 class="title_mobile">Need help?</h5>
                <a
                  :href="'https://api.whatsapp.com/send?phone=' + ((footerData.contact || {}).data || {}).mobile_phone.replaceAll('-', '') + '&text=Hello, I need your assistance for filling up the survey'"
                  target="_blank"
                >
                  <div class="highlight_link mt-3">
                    <div class="sec_instruction">
                      <span>Whatsapp Customer Care</span>
                    </div>
                    <div>
                      <i class="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AppModal :withPageTitle="false" ref="modal-success" type="n" @close="$router.push({name: 'MyPurchases'})">
      <template #content-modal>
        <div class="d-flex justify-content-center">
          <picture class="d-flex justify-content-center">
            <source :srcset="`${baseUrl}/assets/images/static-icon/success-alert.webp`" type="image/webp" />
            <img :src="`${baseUrl}/assets/images/static-icon/success-alert.png`" alt="Success Icon" class="w-50 align-self-start" />
          </picture>
        </div>
        <h5 class="text-center mt-5">
          {{ ((((aboutFollowupQuestions || {}).text || {}).alert || {}).success || {}).title }}
        </h5>
        <p class="text-center mt-3 mb-5">
          {{ ((((aboutFollowupQuestions || {}).text || {}).alert || {}).success || {}).description }}
        </p>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <router-link
              :to="{name: 'MyPurchases'}"
              class="main-button linear-success"
              >{{ ((((aboutFollowupQuestions || {}).text || {}).alert || {}).success || {}).button }}
            </router-link>
          </div>
        </div>
      </template>
    </AppModal>
    <AppModal :withPageTitle="false" ref="modal-warning" type="n">
      <template #content-modal>
        <div class="d-flex justify-content-center">
          <picture class="d-flex justify-content-center">
            <source :srcset="`${baseUrl}/assets/images/static-icon/warning-alert.webp`" type="image/webp" />
            <img :src="`${baseUrl}/assets/images/static-icon/warning-alert.png`" alt="Warning Icon" class="w-50 align-self-start" />
          </picture>
        </div>
        <h5 class="text-center mt-5">Some fields are required</h5>
        <p class="text-center mt-3 mb-5">Please fill out all the required fields.</p>
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <b-button @click="hideModal('modal-warning')" class="main-button linear-warning">Ok</b-button>
          </div>
        </div>
      </template>
    </AppModal>

    <ValidationObserver ref="PersonalInformationForm" v-if="(aboutFollowupQuestions || {}).is_followupquestion == 'Y'">
      <b-modal
        ref="modal-personal-information"
        id="modal-personal-information"
        scrollable
        :centered="!isMobile()"
        title="Personal Information"
        size="lg"
        :no-fade="isMobile()"
      >
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <b-button
            class="icon-modal w-100 d-flex justify-content-between align-items-center"
            size="md"
            @click="close()"
          >
            <h5 class="text-center text-dark mt-2 ml-4">Personal Information</h5>
            <i class="icon-x"></i>
          </b-button>
        </template>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Date of Birth"
                label-for="birth"
                class="my-2 normal mandatory w-100"
              >
                <v-date-picker
                  :popover="{ visibility: 'click' }"
                  v-model="followUpQuestions.confirmation_birth_date"
                  class="mt-3"
                  :max-date="maxdate"
                  :masks="{ input: 'MM/DD/YYYY' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="d-flex flex-column justify-center items-center">
                      <input
                        :value="formatDate(inputValue)"
                        v-on="inputEvents"
                        placeholder="Choose a date"
                        class="form-control px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                      />
                    </div>
                  </template>
                </v-date-picker>
                <VValidate
                  name="Date of Birth"
                  v-model="followUpQuestions.confirmation_birth_date"
                  :rules="{ required: true }"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Passport Expiration Date"
                label-for="passportexp"
                class="mb-2 normal mandatory w-100"
              >
                <v-date-picker
                  :popover="{ visibility: 'click' }"
                  v-model="followUpQuestions.confirmation_passport_exp"
                  class="mt-3"
                  :min-date="new Date()"
                  :masks="{ input: 'MM/DD/YYYY' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="d-flex flex-column justify-center items-center">
                      <input
                        :value="formatDate(inputValue)"
                        v-on="inputEvents"
                        placeholder="Choose a date"
                        class="form-control px-2 w-32 mt-2 rounded focus:outline-none focus:border-indigo-300"
                      />
                    </div>
                  </template>
                </v-date-picker>
                <VValidate
                  name="Passport Expiration Date"
                  v-model="followUpQuestions.confirmation_passport_exp"
                  :rules="{ required: true }"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="input-group-1" label="Gender" label-for="gender" class="my-2 normal mandatory">
                <b-form-select
                  v-model="followUpQuestions.confirmation_gender"
                  :options="gender"
                  id="gender"
                  class="mt-2"
                >
                  <template #first>
                    <b-form-select-option :value="null">-- Select Gender --</b-form-select-option>
                  </template>
                </b-form-select>
                <VValidate name="Gender" v-model="followUpQuestions.confirmation_gender" rules="required" />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="First & Middle Name"
                label-for="firstname"
                class="mb-3 normal mandatory"
              >
                <b-form-input
                  id="firstname"
                  type="text"
                  placeholder="e.g. Abraham"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_first_name"
                  readonly
                ></b-form-input>
                <VValidate
                  name="First & Middle Name"
                  v-model="followUpQuestions.confirmation_first_name"
                  :rules="{ required: true }"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="input-group-1" label="Last Name" label-for="lastname" class="mb-3 normal mandatory">
                <b-form-input
                  id="lastname"
                  type="text"
                  placeholder="e.g. Damar"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_last_name"
                  readonly
                ></b-form-input>
                <VValidate
                  name="Last Name"
                  v-model="followUpQuestions.confirmation_last_name"
                  :rules="{ required: true }"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <p class="light">Citizenship <span class="color-red">*</span></p>
              <b-form-group v-slot="{ ariaDescribedby }" class="d-flex my-3">
                <b-form-radio
                  v-model="followUpQuestions.confirmation_nationality"
                  :aria-describedby="ariaDescribedby"
                  name="confirmation_nationality"
                  value="WNI"
                >
                  Indonesia
                </b-form-radio>
                <b-form-radio
                  v-model="followUpQuestions.confirmation_nationality"
                  :aria-describedby="ariaDescribedby"
                  name="confirmation_nationality"
                  value="WNA"
                >
                  Others
                </b-form-radio>
              </b-form-group>
              <VValidate
                name="Citizenship"
                v-model="followUpQuestions.confirmation_nationality"
                :rules="{ required: true }"
              />

              <template v-if="followUpQuestions.confirmation_nationality == 'WNA'">
                <v-select
                  v-if="citizenshipData"
                  v-model="followUpQuestions.confirmation_citizenship"
                  placeholder="-- Select Country --"
                  :options="citizenshipData"
                  :reduce="(v) => v.citizenship_id"
                  label="citizenship_name"
                  class="mt-2 mb-4"
                  id="confirmation_citizenship"
                />
                <VValidate
                  name="Citizenship"
                  v-model="followUpQuestions.confirmation_citizenship"
                  :rules="{ required: true }"
                />
              </template>
            </div>

            <div class="col-md-6" :class="isMobile() ? 'mt-3' : ''">
              <b-form-group id="input-group-1" label="Blood Type" label-for="blood_type" class="mb-3 normal mandatory">
                <b-form-select
                  v-model="followUpQuestions.confirmation_blood_type"
                  :options="bloodtype"
                  id="blood_type"
                  class="mt-2"
                >
                  <template #first>
                    <b-form-select-option :value="null">-- Select Blood Type --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>

            <div :class="followUpQuestions.confirmation_nationality == 'WNI' ? 'col-md-12' : 'col-md-12'">
              <b-form-group id="input-group-1" class="mb-3 normal">
                <label for="instagram">Instagram Account <small>(Optional)</small></label>
                <b-form-input
                  id="instagram"
                  type="text"
                  placeholder="e.g. @yoshiola"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_instagram"
                ></b-form-input>
                <VValidate name="Instagram Account" v-model="followUpQuestions.confirmation_instagram" />
              </b-form-group>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <button
              @click="handleSubmitFQ('modal-personal-information', 'PersonalInformationForm')"
              class="main-button linear-primary text-uppercase"
            >
              <span>Save</span>
            </button>
          </div>
        </template>
      </b-modal>
    </ValidationObserver>

    <ValidationObserver ref="ContactInformationForm" v-if="(aboutFollowupQuestions || {}).is_followupquestion == 'Y'">
      <b-modal
        ref="modal-contact-information"
        id="modal-contact-information"
        scrollable
        :centered="!isMobile()"
        size="lg"
        :no-fade="isMobile()"
      >
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <b-button
            class="icon-modal w-100 d-flex justify-content-between align-items-center"
            size="md"
            @click="close()"
          >
            <h5 class="text-center text-dark mt-2 ml-4">Contact Information</h5>
            <i class="icon-x"></i>
          </b-button>
        </template>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="email_adddress"
                label="Email Address"
                label-for="email_address"
                class="mb-4 normal mandatory"
              >
                <b-form-input
                  id="email_address"
                  type="email"
                  placeholder="e.g. abraham.damar@mail.com"
                  class="mt-2 normal mandatory"
                  v-model="followUpQuestions.confirmation_email_address"
                  readonly
                ></b-form-input>
                <VValidate
                  name="Email Address"
                  v-model="followUpQuestions.confirmation_email_address"
                  rules="required|email|email_value|max:50"
                />
              </b-form-group>
            </div>

            <div class="col-md-12">
              <b-form-group
                id="input-group-1"
                label="Phone Number"
                label-for="phone_number"
                class="mb-4 normal mandatory input-with-select"
              >
                <div class="mt-2">
                  <v-select
                    v-model="followUpQuestions.confirmation_country_code"
                    placeholder="Code"
                    v-if="countryCodeData"
                    :options="countryCodeData"
                    :reduce="(v) => v.countrycode_id"
                    label="countrycode_name"
                    id="countrycode"
                    @click.native="isOnlyMobile() ? showSearchModal('modal-country-code', 'countrycode') : ''"
                  >
                    <template #option="{ countrycode_dial_code, countrycode_name }">
                      <span>{{ countrycode_name }} {{ countrycode_dial_code }}</span>
                    </template>
                    <template #selected-option="{ countrycode_dial_code }">
                      <span>{{ countrycode_dial_code }}</span>
                    </template>
                  </v-select>
                  <b-form-input
                    id="phone_number"
                    placeholder="08xx-xxxx-xxxx"
                    v-model="followUpQuestions.confirmation_mobile_phone"
                  ></b-form-input>
                </div>
                <div class="d-flex flex-column">
                  <VValidate
                    name="Country Code"
                    v-model="followUpQuestions.confirmation_country_code"
                    rules="required"
                  />
                  <VValidate
                    name="Phone Number"
                    v-model="followUpQuestions.confirmation_mobile_phone"
                    :rules="{ regex: /^[0-9\+]+$/, required: true, min: 5, max: 16 }"
                  />
                </div>
              </b-form-group>
            </div>

            <AppModal :withPageTitle="true" ref="modal-country-code" :type="'lg'">
              <template #title-modal>
                <h4 class="bold ls-0 mb-0">Country Code</h4>
              </template>
              <template #content-modal>
                <div class="row">
                  <div class="col-md-12">
                    <input type="text" class="form-control main-control" @keyup="getCountryCodeFilter" v-model="filterCountry" placeholder="Search..." />
                  </div>
                  <div class="col-md-12">
                    <div class="my-2 h-100vh fade-in">
                      <template v-if="!loading.data">
                        <div
                          class="d-flex justify-content-between bb-1px-grey py-4"
                          v-for="(v,k) in countryCodeData" 
                          :key="k" 
                          @click="chooseCountryCode(v)"
                        >
                          <p class="light mb-0">{{ v.countrycode_name }}</p>
                          <p class="mb-0">{{ v.countrycode_dial_code }}</p>
                        </div>
                      </template>
                      <template v-else>
                      <div class="gooey">
                        <span class="dot"></span>
                        <div class="dots">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </template>
                    </div>
                  </div>
                </div>
              </template>
            </AppModal>

            <div class="col-md-12">
              <p class="light">Country of Residence <span class="color-red">*</span></p>
              <b-form-group v-slot="{ ariaDescribedby }" class="d-flex my-3">
                <b-form-radio
                  v-model="followUpQuestions.confirmation_country_of_residence_flag"
                  :aria-describedby="ariaDescribedby"
                  name="confirmation_country_of_residence_flag"
                  value="WNI"
                >
                  Indonesia
                </b-form-radio>
                <b-form-radio
                  v-model="followUpQuestions.confirmation_country_of_residence_flag"
                  :aria-describedby="ariaDescribedby"
                  name="confirmation_country_of_residence_flag"
                  value="WNA"
                >
                  Others
                </b-form-radio>
              </b-form-group>
              <VValidate
                name="Country of Residence"
                v-model="followUpQuestions.confirmation_country_of_residence_flag"
                :rules="{ required: true }"
              />

              <template v-if="followUpQuestions.confirmation_country_of_residence_flag == 'WNA'">
                <v-select
                  v-if="citizenshipData"
                  v-model="followUpQuestions.confirmation_country_of_residence"
                  placeholder="-- Select Country --"
                  :options="citizenshipData"
                  :reduce="(v) => v.citizenship_id"
                  label="citizenship_name"
                  class="mt-2 mb-4"
                  id="country_of_residence"
                />
                <VValidate
                  name="Country of Residence"
                  v-model="followUpQuestions.confirmation_country_of_residence"
                  :rules="{ required: true }"
                />
              </template>
            </div>

            <div class="col-md-12">
              <b-form-group
                id="adress_1"
                label="Street Address 1"
                label-for="address_1"
                class="mb-4 mt-2 normal mandatory"
              >
                <b-form-input
                  id="adress_1"
                  type="text"
                  placeholder="STreet Address 1"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_address_1"
                ></b-form-input>
                <VValidate
                  name="Street Address 1"
                  v-model="followUpQuestions.confirmation_address_1"
                  :rules="{ required: true }"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="adress_2" label="Street Address 2" label-for="address_2" class="mb-4 normal">
                <b-form-input
                  id="adress_2"
                  type="text"
                  placeholder="Street Address 2"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_address_2"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="adress_3" label="Street Address 3" label-for="address_3" class="mb-4 normal">
                <b-form-input
                  id="adress_3"
                  type="text"
                  placeholder="Street Address 3"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_address_3"
                ></b-form-input>
              </b-form-group>
            </div>

            <template v-if="followUpQuestions.confirmation_country_of_residence_flag == 'WNI'">
              <div class="col-md-6">
                <b-form-group id="input-group-1" label="Province" label-for="state" class="mb-3 normal mt-2 mandatory">
                  <v-select
                    v-model="inputSelectedState"
                    placeholder="Select State"
                    :options="provinceData"
                    :reduce="(v) => v.province_name"
                    label="province_name"
                    class="mt-2"
                  />
                  <VValidate name="Province" v-model="inputSelectedState" :rules="{ required: true }" />
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group id="input-group-1" label="City" label-for="city" class="mb-3 normal mandatory">
                  <v-select
                    v-model="inputSelectedCity"
                    placeholder="Select City"
                    :options="citiesData"
                    :reduce="(v) => v"
                    label="zip_city"
                    class="mt-3"
                  />
                  <VValidate name="City" v-model="inputSelectedCity" :rules="{ required: true }" />
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group id="input-group-1" label="District" label-for="district" class="mb-3 normal mandatory">
                  <v-select
                    v-model="inputSelectedDistrict"
                    placeholder="Select District"
                    :options="districtData"
                    :reduce="(v) => v"
                    label="zip_district"
                    class="mt-2"
                  />
                  <VValidate name="District" v-model="inputSelectedDistrict" :rules="{ required: true }" />
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group
                  id="input-group-1"
                  label="Sub District"
                  label-for="subdistrict"
                  class="mb-3 normal mandatory"
                >
                  <v-select
                    v-model="inputSelectedSubDistrict"
                    placeholder="Select Sub District"
                    :options="subdistrictData"
                    :reduce="(v) => v"
                    label="zip_sub_district"
                    class="mt-2"
                  />
                  <VValidate name="Sub District" v-model="inputSelectedSubDistrict" :rules="{ required: true }" />
                </b-form-group>
              </div>

              <div class="col-md-12">
                <b-form-group
                  id="input-group-1"
                  label="Zip Code"
                  label-for="zipcode"
                  class="mb-4 mt-2 normal mandatory"
                >
                  <b-form-input
                    readonly
                    id="city"
                    type="text"
                    placeholder="e.g. 300971"
                    class="mt-2"
                    v-model="followUpQuestions.confirmation_zip_code"
                  ></b-form-input>
                  <VValidate
                    name="Zip Code"
                    v-model="followUpQuestions.confirmation_zip_code"
                    :rules="{ required: true, min: 5, max: 7 }"
                  />
                </b-form-group>
              </div>
            </template>
          </div>
        </div>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <button
              @click="handleSubmitFQ('modal-contact-information', 'ContactInformationForm')"
              class="main-button linear-primary text-uppercase"
            >
              <span>Save</span>
            </button>
          </div>
        </template>
      </b-modal>
    </ValidationObserver>

    <ValidationObserver ref="EmergencyContactForm" v-if="(aboutFollowupQuestions || {}).is_followupquestion == 'Y'">
      <b-modal
        ref="modal-emergency-contact"
        id="modal-emergency-contact"
        scrollable
        :centered="!isMobile()"
        size="lg"
        :no-fade="isMobile()"
      >
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <b-button
            class="icon-modal w-100 d-flex justify-content-between align-items-center"
            size="md"
            @click="close()"
          >
            <h5 class="text-center text-dark mt-2 ml-4">Emergency Contact Information</h5>
            <i class="icon-x"></i>
          </b-button>
        </template>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <b-form-group id="input-group-1" class="mb-3 normal mandatory">
                <label for="full_name">Full Name</label>
                <b-form-input
                  id="fullname_emergency"
                  type="text"
                  placeholder="e.g. Abraham Damar"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_emergency_full_name"
                ></b-form-input>
                <VValidate
                  name="Emergency Full Name"
                  v-model="followUpQuestions.confirmation_emergency_full_name"
                  rules="required|min:3|max:100|alpha_spaces"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="input-group-1" class="mb-4 normal mandatory">
                <label for="phone_number">Phone Number</label>
                <b-form-input
                  id="phone_number"
                  type="tel"
                  placeholder="08xx-xxxx-xxxx"
                  class="mt-2"
                  v-model="followUpQuestions.confirmation_emergency_mobile_phone"
                ></b-form-input>
                <VValidate
                  name="Emergency Phone Number"
                  v-model="followUpQuestions.confirmation_emergency_mobile_phone"
                  :rules="{ required: true, regex: /^[0-9\+]+$/, min: 5, max: 16 }"
                />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="relation" label="Relation" label-for="relation" class="mb-3 normal mandatory">
                <v-select
                  v-model="followUpQuestions.confirmation_emergency_relation"
                  placeholder="-- Select Relation --"
                  :options="followUpQuestions.relation"
                  :reduce="(v) => v.value"
                  label="text"
                  class="mt-2"
                />
                <VValidate
                  name="Emergency Relation"
                  v-model="followUpQuestions.confirmation_emergency_relation"
                  :rules="{ required: true }"
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <button
              @click="handleSubmitFQ('modal-emergency-contact', 'EmergencyContactForm')"
              class="main-button linear-primary text-uppercase"
            >
              <span>Save</span>
            </button>
          </div>
        </template>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
// import HeaderNavigation from '@/layouts/HeaderNavigation'
// import ButtonBottomNavi from '@/components/ButtonBottomNavi'
import AppModal from '@/components/AppModal'
import { extend } from 'vee-validate'

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  extends: GlobalVue,
  name: 'SupplementaryQuestion',
  components: {
    AppModal,
    VueTimepicker,
  },
  data() {
    return {
      timeoutId: null,
      selected_state: '',
      selected_city: '',
      selected_district: '',
      selected_subdistrict: '',
      zip_code: '',
    }
  },
  computed: {
    ...mapFields(['loading']),
    ...mapFields('useSupplementaryQuestionStore', ['followUpQuestions', 'aboutFollowupQuestions']),

    ...mapFields("profile", [
      "citizenshipData",
      "provinceData",
      "citiesData",
      "districtData",
      "subdistrictData",
      "inputChangeProfile",
      "gender",
      "bloodtype",
      "nationality",
      "dateAPI",
      "countryCodeData",
      "filterCountry",
    ]),

    ...mapFields("useFooterStore", [
      "footerData"
    ]),

    inputSelectedState: {
      get() {
        this.selected_state = (this.followUpQuestions || {}).confirmation_state
        return this.selected_state
      },
      set(newValue) {
        this.$set(this, 'selected_state', newValue)
        this.$set(this.followUpQuestions, 'confirmation_state', newValue)

        this.$set(this.followUpQuestions, 'confirmation_city', null)
        this.$set(this.followUpQuestions, 'confirmation_district', null)
        this.$set(this.followUpQuestions, 'confirmation_subdistrict', null)
        this.$set(this.followUpQuestions, 'confirmation_zip_code', null)
      }
    },

    inputSelectedDistrict: {
      get() {
        this.selected_district = (this.followUpQuestions || {}).confirmation_district
        return this.selected_district
      },
      set(newValue) {
        this.$set(this, 'selected_district', (newValue || {}).zip_district || null)
        this.$set(this.followUpQuestions, 'confirmation_district', (newValue || {}).zip_district || null)

        this.$set(this.followUpQuestions, 'confirmation_subdistrict', null)
        this.$set(this.followUpQuestions, 'confirmation_zip_code', null)
      }
    },

    inputSelectedSubDistrict: {
      get() {
        if((this.followUpQuestions || {}).confirmation_subdistrict === null){
          this.$set(this, 'zip_code', null)
          this.$set(this.followUpQuestions, 'confirmation_zip_code', null)  
        }
        this.selected_subdistrict = (this.followUpQuestions || {}).confirmation_subdistrict
        return this.selected_subdistrict
      },
      set(newValue) {
        this.$set(this, 'selected_subdistrict', (newValue || {}).zip_sub_district || null)
        this.$set(this.followUpQuestions, 'confirmation_subdistrict', (newValue || {}).zip_sub_district || null)
        this.$set(this, 'zip_code', (newValue || {}).zip_zipcode || null)
        this.$set(this.followUpQuestions, 'confirmation_zip_code', (newValue || {}).zip_zipcode || null)
      }
    },

    inputSelectedCity: {
      get() {
        this.selected_city = (this.followUpQuestions || {}).confirmation_city
        return this.selected_city
      },
      set(newValue) {
        this.$set(this, 'selected_city', (newValue || {}).zip_city || null)
        this.$set(this.followUpQuestions, 'confirmation_city', (newValue || {}).zip_city || null)

        this.$set(this.followUpQuestions, 'confirmation_district', null)
        this.$set(this.followUpQuestions, 'confirmation_subdistrict', null)
        this.$set(this.followUpQuestions, 'confirmation_zip_code', null)
      }
    },

    maxdate() {
      const year = (this.dateAPI || {}).year
      const month = (this.dateAPI || {}).month
      const day = (this.dateAPI || {}).day

      let conf = new Date((year - 11), (month - 1), day)
      return conf
    },

    maxdatePassport() {
      let year = parseInt((this.dateAPI || {}).year) + 10
      const month = (this.dateAPI || {}).month
      const day = (this.dateAPI || {}).day

      let conf = new Date(year, (month - 1), day)
      return conf
    },
  },
  created() {
    this.getFollowUpQuestions(this.$route.params.token)
    this.getDateAPI()
    this.getProvince()
    this.getCitizenship()
    this.getCountryCode()

    extend('email_value', {
        message: 'The {_field_} field can only contain letters (a-z), numbers (0-9), and periods(.)',
        validate: value => {
          let exp = /^[A-Z0-9\.@]+$/gi
          let regex = new RegExp(exp)
          return value.match(regex)
        }
      })
  },
  methods: {
    ...mapActions('useSupplementaryQuestionStore', ['getFollowUpQuestions', 'submitFollowupQuestions']),
    ...mapActions('profile', [
      'getDateAPI',
      'getCountryCode',
      'getProvince',
      'getCitizenship',
      'getCities',
      'getDistrict',
      'getSubDistrict',
    ]),

    scrollToTop(qs) {
      const scrollPosition = document.querySelector(qs).scrollTop

      if (scrollPosition === undefined) {
        return null
      }

      if (scrollPosition > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollPosition - (scrollPosition / 8))
      }
    },

    showSearchModal(modalId, inputId) {
      this.$refs[modalId].show()
      document.querySelector(`#${inputId} .vs__search`).blur()
      this.scrollToTop('.second-window .content')
    },

    chooseCountryCode(data) {
      if(this.$route.name == 'EmergencyContact') {
        (this.inputChangeProfile || {}).registrant_emergency_dial_code = data.countrycode_dial_code
      } else {
        (this.inputChangeProfile || {}).registrant_country_code = data.countrycode_id
      }
        this.$refs['modal-code-number'].hide()
    },

    hideModal(MODALNAME) {
      this.$refs[MODALNAME].hide()
    },
    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },
    getCountryCodeFilter() {
      this.doDebounce(() => {
        this.getCountryCode()
      }, 500)
    },
    async handleSubmitFQ(id = null, IDFORM = "") {
      const is_valid = await this.$refs[IDFORM].validate()

      if (!is_valid) {
        return
      } else {
        this.$refs[id].hide()
      }
    },

    async saveAll() {
      try {
        const isValid = await ((this.$refs || {}).VForm || {}).validate()

        if (!isValid) {
          return
        }

        const res = await this.submitFollowupQuestions(this.$route.params.token)
        if (res == 200) {
          this.$refs['modal-success'].show()
          this.closeModal = true

          // this.watchAuth()
        } else {
          this.$refs['modal-warning'].show()
        }
      } catch (err) {
        this.$refs['modal-warning'].show()
      }
    },
  },
  watch: {
    selected_state(old) {
      this.getCities(old)
    },

    selected_city(old) {
      this.getDistrict(old)
    },

    selected_district(old) {
      this.getSubDistrict(old)
    },
  },
}
</script>

<style scoped>
body {
  font-family: "Metropolis-Regular" !important; 
}


section {
  padding: 0;
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);.container-fluid[data-v-3f08949f] {
    width: 95%!important;
    margin: auto
}

.rdg_button .button button[data-v-3f08949f] {
    padding: 1rem 1.5rem;
    border-radius: 99rem;
    border: none;
    outline: none
}

.rdg_button .button button.sm[data-v-3f08949f] {
    padding: .5rem 1rem!important
}

.rdg_button .button a[data-v-3f08949f] {
    padding: 1rem 1.5rem;
    border-radius: 99rem;
    border: none;
    outline: none
}

.rdg_button .button.secondary_white button[data-v-3f08949f] {
    background: transparent;
    border: 1px solid #fff;
    color: #fff
}

.rdg_button .button.primary button[data-v-3f08949f] {
    background: linear-gradient(90deg,#95378e,#0287b4);
    color: #fff
}

.rdg_button .button.primary button[data-v-3f08949f]:hover {
    background: linear-gradient(90deg,#5f1f5b,#027297)
}

.rdg_button .button.primary a[data-v-3f08949f] {
    background: linear-gradient(90deg,#95378e,#0287b4);
    padding: 1rem;
    color: #fff
}

.rdg_button .button.primary a[data-v-3f08949f]:hover {
    background: linear-gradient(90deg,#5f1f5b,#027297)
}

.rdg_button .button.primary_icon button[data-v-3f08949f] {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg,#95378e,#0287b4);
    color: #fff
}

.rdg_button .button.primary_icon button span[data-v-3f08949f] {
    margin-right: 1rem
}

.rdg_button .button.primary_icon button .icon[data-v-3f08949f] {
    line-height: 25px;
    width: 25px;
    height: 25px;
    border-radius: 99rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.rdg_button .button.primary_icon button .icon i[data-v-3f08949f] {
    color: #000
}

.rdg_button .button.primary_icon button.full[data-v-3f08949f] {
    justify-content: center
}

.rdg_button .button.secondary_icon button[data-v-3f08949f] {
    display: flex;
    align-items: center;
    background: #fff
}

.rdg_button .button.secondary_icon button span[data-v-3f08949f] {
    margin-right: 1rem;
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.rdg_button .button.secondary_icon button .icon[data-v-3f08949f] {
    width: 25px;
    height: 25px;
    border-radius: 99rem;
    line-height: 25px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    display: flex;
    justify-content: center;
    align-items: center
}

.rdg_button .button.secondary_icon button .icon i[data-v-3f08949f] {
    color: #fff
}

.rdg_button .button.secondary_icon_bgnone button[data-v-3f08949f] {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid #fff
}

.rdg_button .button.secondary_icon_bgnone button span[data-v-3f08949f] {
    margin-right: 1rem;
    color: #fff
}

.rdg_button .button.secondary_icon_bgnone button .icon[data-v-3f08949f] {
    width: 25px;
    height: 25px;
    border-radius: 99rem;
    line-height: 25px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    display: flex;
    justify-content: center;
    align-items: center
}

.rdg_button .button.secondary_icon_bgnone button .icon i[data-v-3f08949f] {
    color: #fff
}

.rdg_button .button.secondary_icon_bgnone button:hover span[data-v-3f08949f] {
    text-decoration: underline
}

.rdg_button .button.secondary_border_icon[data-v-3f08949f] {
    border-radius: 99rem;
    width: 100%
}

.rdg_button .button.secondary_border_icon button[data-v-3f08949f] {
    display: flex;
    width: 100%;
    background: #fff;
    align-items: center;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 99rem!important;
    position: relative
}

.rdg_button .button.secondary_border_icon button span[data-v-3f08949f] {
    margin-right: 1rem;
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.rdg_button .button.secondary_border_icon button .icon[data-v-3f08949f] {
    width: 25px;
    height: 25px;
    border-radius: 99rem;
    line-height: 25px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    display: flex;
    justify-content: center;
    align-items: center
}

.rdg_button .button.secondary_border_icon button .icon i[data-v-3f08949f] {
    color: #fff
}

.rdg_button .button.secondary_border_icon button[data-v-3f08949f]:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(90deg,#95378e,#0287b4)
}

.rdg_button .button.nobg_icon button[data-v-3f08949f] {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 1rem 0
}

.rdg_button .button.nobg_icon button span[data-v-3f08949f] {
    margin-right: 1rem;
    color: #fff
}

.rdg_button .button.nobg_icon button .icon[data-v-3f08949f] {
    width: 25px;
    height: 25px;
    border-radius: 99rem;
    line-height: 25px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.rdg_button .button.nobg_icon button .icon i[data-v-3f08949f] {
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.rdg_button .button.nobg_black button[data-v-3f08949f] {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 1rem 0
}

.rdg_button .button.nobg_black button span[data-v-3f08949f] {
    margin-right: 1rem;
    color: #000
}

.rdg_button .button.nobg_linear button[data-v-3f08949f] {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 1rem 1rem
}

.rdg_button .button.nobg_linear button span[data-v-3f08949f] {
    text-decoration: underline;
    -webkit-text-decoration-color: #000!important;
    text-decoration-color: #000!important;
    margin-right: 1rem;
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.rdg_button .button.disabled button[data-v-3f08949f] {
    background: #ddd;
    color: #fff;
    cursor: not-allowed
}

img[data-v-7c8210e5] {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
}

#page-loader[data-v-7c8210e5] {
    position: fixed;
    z-index: 9999!important;
    height: 100vh;
    top: 0;
    width: 100%
}

.holder[data-v-7c8210e5] {
    position: absolute;
    left: 0;
    z-index: 99!important;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    background-color: #fff
}

.preloader[data-v-7c8210e5] {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-animation: rotatePreloader-data-v-7c8210e5 2s ease-in infinite;
    animation: rotatePreloader-data-v-7c8210e5 2s ease-in infinite
}

@-webkit-keyframes rotatePreloader-data-v-7c8210e5 {
    0% {
        transform: translateX(-50%) translateY(-50%) rotate(0deg)
    }

    to {
        transform: translateX(-50%) translateY(-50%) rotate(-1turn)
    }
}

@keyframes rotatePreloader-data-v-7c8210e5 {
    0% {
        transform: translateX(-50%) translateY(-50%) rotate(0deg)
    }

    to {
        transform: translateX(-50%) translateY(-50%) rotate(-1turn)
    }
}

.preloader div[data-v-7c8210e5] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0
}

.preloader div[data-v-7c8210e5]:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 10%;
    height: 10%;
    background-color: #2d2f48;
    transform: translateX(-50%);
    border-radius: 50%
}

.preloader div[data-v-7c8210e5]:first-child {
    transform: rotate(0deg);
    -webkit-animation: rotateCircle1-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle1-data-v-7c8210e5 2s linear infinite;
    z-index: 9
}

@-webkit-keyframes rotateCircle1-data-v-7c8210e5 {
    0% {
        opacity: 0
    }

    0% {
        opacity: 1;
        transform: rotate(36deg)
    }

    7% {
        transform: rotate(0deg)
    }

    57% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle1-data-v-7c8210e5 {
    0% {
        opacity: 0
    }

    0% {
        opacity: 1;
        transform: rotate(36deg)
    }

    7% {
        transform: rotate(0deg)
    }

    57% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(2) {
    transform: rotate(36deg);
    -webkit-animation: rotateCircle2-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle2-data-v-7c8210e5 2s linear infinite;
    z-index: 8
}

@-webkit-keyframes rotateCircle2-data-v-7c8210e5 {
    5% {
        opacity: 0
    }

    5.0001% {
        opacity: 1;
        transform: rotate(0deg)
    }

    12% {
        transform: rotate(-36deg)
    }

    62% {
        transform: rotate(-36deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle2-data-v-7c8210e5 {
    5% {
        opacity: 0
    }

    5.0001% {
        opacity: 1;
        transform: rotate(0deg)
    }

    12% {
        transform: rotate(-36deg)
    }

    62% {
        transform: rotate(-36deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(3) {
    transform: rotate(72deg);
    -webkit-animation: rotateCircle3-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle3-data-v-7c8210e5 2s linear infinite;
    z-index: 7
}

@-webkit-keyframes rotateCircle3-data-v-7c8210e5 {
    10% {
        opacity: 0
    }

    10.0002% {
        opacity: 1;
        transform: rotate(-36deg)
    }

    17% {
        transform: rotate(-72deg)
    }

    67% {
        transform: rotate(-72deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle3-data-v-7c8210e5 {
    10% {
        opacity: 0
    }

    10.0002% {
        opacity: 1;
        transform: rotate(-36deg)
    }

    17% {
        transform: rotate(-72deg)
    }

    67% {
        transform: rotate(-72deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(4) {
    transform: rotate(108deg);
    -webkit-animation: rotateCircle4-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle4-data-v-7c8210e5 2s linear infinite;
    z-index: 6
}

@-webkit-keyframes rotateCircle4-data-v-7c8210e5 {
    15% {
        opacity: 0
    }

    15.0003% {
        opacity: 1;
        transform: rotate(-72deg)
    }

    22% {
        transform: rotate(-108deg)
    }

    72% {
        transform: rotate(-108deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle4-data-v-7c8210e5 {
    15% {
        opacity: 0
    }

    15.0003% {
        opacity: 1;
        transform: rotate(-72deg)
    }

    22% {
        transform: rotate(-108deg)
    }

    72% {
        transform: rotate(-108deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(5) {
    transform: rotate(144deg);
    -webkit-animation: rotateCircle5-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle5-data-v-7c8210e5 2s linear infinite;
    z-index: 5
}

@-webkit-keyframes rotateCircle5-data-v-7c8210e5 {
    20% {
        opacity: 0
    }

    20.0004% {
        opacity: 1;
        transform: rotate(-108deg)
    }

    27% {
        transform: rotate(-144deg)
    }

    77% {
        transform: rotate(-144deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle5-data-v-7c8210e5 {
    20% {
        opacity: 0
    }

    20.0004% {
        opacity: 1;
        transform: rotate(-108deg)
    }

    27% {
        transform: rotate(-144deg)
    }

    77% {
        transform: rotate(-144deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(6) {
    transform: rotate(180deg);
    -webkit-animation: rotateCircle6-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle6-data-v-7c8210e5 2s linear infinite;
    z-index: 4
}

@-webkit-keyframes rotateCircle6-data-v-7c8210e5 {
    25% {
        opacity: 0
    }

    25.0005% {
        opacity: 1;
        transform: rotate(-144deg)
    }

    32% {
        transform: rotate(-180deg)
    }

    82% {
        transform: rotate(-180deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle6-data-v-7c8210e5 {
    25% {
        opacity: 0
    }

    25.0005% {
        opacity: 1;
        transform: rotate(-144deg)
    }

    32% {
        transform: rotate(-180deg)
    }

    82% {
        transform: rotate(-180deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(7) {
    transform: rotate(216deg);
    -webkit-animation: rotateCircle7-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle7-data-v-7c8210e5 2s linear infinite;
    z-index: 3
}

@-webkit-keyframes rotateCircle7-data-v-7c8210e5 {
    30% {
        opacity: 0
    }

    30.0006% {
        opacity: 1;
        transform: rotate(-180deg)
    }

    37% {
        transform: rotate(-216deg)
    }

    87% {
        transform: rotate(-216deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle7-data-v-7c8210e5 {
    30% {
        opacity: 0
    }

    30.0006% {
        opacity: 1;
        transform: rotate(-180deg)
    }

    37% {
        transform: rotate(-216deg)
    }

    87% {
        transform: rotate(-216deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(8) {
    transform: rotate(252deg);
    -webkit-animation: rotateCircle8-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle8-data-v-7c8210e5 2s linear infinite;
    z-index: 2
}

@-webkit-keyframes rotateCircle8-data-v-7c8210e5 {
    35% {
        opacity: 0
    }

    35.0007% {
        opacity: 1;
        transform: rotate(-216deg)
    }

    42% {
        transform: rotate(-252deg)
    }

    92% {
        transform: rotate(-252deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle8-data-v-7c8210e5 {
    35% {
        opacity: 0
    }

    35.0007% {
        opacity: 1;
        transform: rotate(-216deg)
    }

    42% {
        transform: rotate(-252deg)
    }

    92% {
        transform: rotate(-252deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(9) {
    transform: rotate(288deg);
    -webkit-animation: rotateCircle9-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle9-data-v-7c8210e5 2s linear infinite;
    z-index: 1
}

@-webkit-keyframes rotateCircle9-data-v-7c8210e5 {
    40% {
        opacity: 0
    }

    40.0008% {
        opacity: 1;
        transform: rotate(-252deg)
    }

    47% {
        transform: rotate(-288deg)
    }

    97% {
        transform: rotate(-288deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle9-data-v-7c8210e5 {
    40% {
        opacity: 0
    }

    40.0008% {
        opacity: 1;
        transform: rotate(-252deg)
    }

    47% {
        transform: rotate(-288deg)
    }

    97% {
        transform: rotate(-288deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

.preloader div[data-v-7c8210e5]:nth-child(10) {
    transform: rotate(324deg);
    -webkit-animation: rotateCircle10-data-v-7c8210e5 2s linear infinite;
    animation: rotateCircle10-data-v-7c8210e5 2s linear infinite;
    z-index: 0
}

@-webkit-keyframes rotateCircle10-data-v-7c8210e5 {
    45% {
        opacity: 0
    }

    45.0009% {
        opacity: 1;
        transform: rotate(-288deg)
    }

    52% {
        transform: rotate(-324deg)
    }

    102% {
        transform: rotate(-324deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@keyframes rotateCircle10-data-v-7c8210e5 {
    45% {
        opacity: 0
    }

    45.0009% {
        opacity: 1;
        transform: rotate(-288deg)
    }

    52% {
        transform: rotate(-324deg)
    }

    102% {
        transform: rotate(-324deg)
    }

    to {
        transform: rotate(-324deg);
        opacity: 1
    }
}

@-webkit-keyframes animatebottom {
    0% {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes dot {
    50% {
        transform: translateX(96px)
    }
}

@-webkit-keyframes dots {
    50% {
        transform: translateX(-31px)
    }
}

.navigation_bar {
    background: red;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11!important;
    width: 100%;
    box-shadow: 1px -2px 9px -9px #000
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .navigation_bar {
        height:70px!important
    }
}

.links_navigation {
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,.2);
    /* font-size: 2rem!important; */
    font-weight: 300;
    padding-top: .5rem;
    height: 100%!important
}

.links_navigation .router-link-exact-active .link_menu i {
    color: #95378e!important;
    font-weight: 500!important
}

.links_navigation .router-link-exact-active .link_menu a,.links_navigation .router-link-exact-active .link_menu span {
    color: #95378e!important;
    font-weight: 700!important
}

.links_navigation .link_menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.links_navigation .link_menu i {
    /* font-size: 1.75rem; */
    color: rgba(0,0,0,.5)
}

.links_navigation .link_menu a,.links_navigation .link_menu span {
    color: rgba(0,0,0,.5)!important;
    padding: 0;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    /* font-size: 1.25rem!important; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500
}

.links_navigation>.line {
    background: red;
    height: 1px;
    pointer-events: none
}

.links_navigation {
    display: grid;
    grid-template-columns: repeat(var(--items),auto);
    position: relative
}

.links_navigation>.line {
    opacity: 0;
    transition: all .5s;
    position: absolute;
    bottom: 0;
    left: var(--left,calc(100%/var(--items)*(var(--index) - 1)));
    width: var(--width,calc(100%/var(--items)));
    --index: 0
}

.modal-content {
    border-radius: 0!important
}

@media only screen and (max-width: 768px) {
    .modal-content {
        position:fixed!important;
        bottom: 0!important;
        left: 0!important;
        max-height: 85vh!important
    }
}

.modal-content .modal-title {
    font-weight: 600
}

#header-mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99!important;
    width: 100%;
    height: 70px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,.08),0 4px 12px rgba(0,0,0,.08)!important;
    padding-right: 2rem
}

.filter_mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1rem 1rem 0
}

.filter_mobile.nopadding {
    padding: .75rem 1.5rem 1rem 0!important
}

.filter_mobile.nopaddingx {
    padding: .75rem 0 1rem 0!important
}

.filter_mobile .back i {
    /* font-size: 3rem */
}

.filter_mobile .back h5 {
    margin-left: 1rem
}

.filter_mobile .logo_rdg_header {
    display: flex;
    align-items: center
}

.filter_mobile .logo_rdg_header img {
    width: 150px!important
}

.filter_mobile .logo_rdg_header i {
    /* font-size: 2rem; */
    margin-right: 1rem;
    margin-top: 1rem
}

.filter_mobile .page_title {
    display: flex;
    align-items: center
}

.filter_mobile .page_title h5 {
    margin-top: 7px;
    font-weight: 600;
    /* font-size: 1.5rem!important */
}

.filter_mobile .filter_icon i {
    /* font-size: 2.25rem */
}

.filter_mobile .addons {
    /* font-size: 2rem; */
    margin-right: 1.25rem;
    margin-top: 1rem
}

.filter_live {
    position: relative
}

.filter_live .search_live {
    width: 100%!important
}

.filter_live .search__container {
    margin: 0 1rem 0 0
}

.filter_live .search__title {
    /* font-size: 22px; */
    font-weight: 900;
    text-align: center;
    color: #ff8b88
}

.filter_live .search__input {
    width: 100%;
    padding: .75rem 24px;
    background-color: hsla(0,0%,100%,0);
    -webkit-background-color: hsla(0,0%,100%,0);
    transition: transform .25s ease-in-out;
    /* font-size: 12px; */
    line-height: 18px;
    color: #575756;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95%;
    border-radius: 50px;
    border: 1px solid rgba(0,0,0,.1);
    transition: all .25s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d
}

.filter_live .search__input::-moz-placeholder {
    color: color(#575756 a(.8));
    text-transform: uppercase;
    letter-spacing: 1.5px
}

.filter_live .search__input:-ms-input-placeholder {
    color: color(#575756 a(.8));
    text-transform: uppercase;
    letter-spacing: 1.5px
}

.filter_live .search__input::placeholder {
    color: color(#575756 a(.8));
    text-transform: uppercase;
    letter-spacing: 1.5px
}

.filter_live .search__input:focus,.filter_live .search__input:hover {
    padding: .75rem 0;
    outline: 0;
    border: 1px solid hsla(0,0%,100%,0);
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-radius: 0;
    background-position: 100%
}

.filter_live .filter_icon {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6
}

.filter_live .filter_icon i {
    /* font-size: 1.75rem!important; */
    margin-right: .75rem
}

.filter_live_fixed {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: .75rem
}

.filter_live_fixed .filter_icon {
    display: flex;
    align-items: center
}

.filter_live_fixed .filter_icon i {
    /* font-size: 1.75rem!important; */
    margin-right: .75rem
}

.mobile_runevent .card-event {
    margin-top: 1rem
}

.program_name {
    display: inline-block;
    margin-top: 1rem!important;
    font-weight: 600
}

.program_name,.run_event_mobile {
    position: relative
}

.run_event_mobile .right_mobile {
    position: relative!important
}

.run_event_mobile .right_mobile .next_page {
    display: flex!important;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 12.5%;
    right: 5%;
    z-index: 20!important;
    border-radius: 99rem!important;
    padding: 0!important;
    width: 60px;
    height: 60px
}

.run_event_mobile .right_mobile .next_page i {
    /* font-size: 3rem */
}

.run_event_mobile {
    padding-bottom: 7rem!important
}

.hamburger {
    position: absolute;
    right: 30px;
    top: 25px;
    display: flex;
    height: 15px;
    width: 20px;
    z-index: 99!important;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.hamburger__icon {
    display: inline-block;
    height: 1.5px;
    width: 20px;
    background: #000;
    border-radius: 2px
}

.sidebar {
    height: 100vh;
    width: 275px;
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99!important;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    transform: translatex(100%);
    transition: transform .4s ease-in-out;
    box-shadow: 0 1px 34px -22px rgba(0,0,0,.75);
    overflow-y: scroll!important
}

.sidebar__close {
    position: absolute;
    top: 2%;
    right: 2%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translatex(-100%);
    cursor: pointer;
    transition: transform .4s ease-in-out .2s
}

.sidebar__close:after,.sidebar__close:before {
    content: "";
    height: 2px;
    background: #000;
    width: 20px;
    display: block;
    position: absolute
}

.sidebar__close:after {
    transform: rotate(90deg)
}

.sidebar__menu {
    display: flex;
    flex-direction: column;
    margin-top: 10px!important;
    margin-bottom: 20px!important
}

.sidebar__menu .logo_rdg {
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 1rem 0!important
}

.sidebar__menu .logo_rdg img {
    width: 135px!important
}

.sidebar__menu .profile-menu {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 1rem 0
}

.sidebar__menu .profile-menu .img-profile-sidebar {
    position: relative;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 99rem;
    margin-right: 2rem!important
}

.sidebar__menu .profile-menu .img-profile-sidebar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem
}

.sidebar__menu .profile-menu .about-profile {
    display: flex
}

.sidebar__menu .profile-menu .about-profile .name {
    font-weight: 600;
    color: #95378e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px
}

.sidebar__menu .profile-menu .about-profile .email {
    color: rgba(0,0,0,.5)
}

.sidebar__menu .link {
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.sidebar__menu .link,.sidebar__menu .link .group-link {
    padding: 1rem 0;
    display: flex
}

.sidebar__menu .link .group-link .icon-link {
    width: 35px
}

.sidebar__menu .link .group-link .icon-link i {
    color: #95378e;
    font-weight: 700;
    /* font-size: 2rem!important */
}

.sidebar__menu .link .group-link .text-link a {
    font-weight: 500!important;
    /* font-size: 1.5rem!important; */
    color: #000
}

.sidebar__menu .link .group-link .text-link a i {
    /* font-size: 2rem!important; */
    color: rgba(0,0,0,.5)
}

.sidebar__menu .secondary-group-link {
    padding: 1.5rem 0
}

.sidebar__menu .secondary-group-link a {
    font-weight: 500!important;
    color: #000;
    /* font-size: 1.5rem!important */
}

.sidebar__menu .secondary-group-link .router-link-exact-active {
    color: #95378e!important;
    /* font-family: sans-serif */
}

.menu-control {
    display: none;
    z-index: 99!important
}

.menu-control:checked+.sidebar {
    transform: translatex(0)
}

.menu-control:checked+.sidebar .sidebar__close {
    transform: translatex(0) rotate(45deg)
}

.menu-control:checked+.sidebar .sidebar__menu a {
    transform: translatex(0)
}

.modal.bg {
    background: linear-gradient(90deg,#95378e,#0287b4)
}

.icon-modal {
    background: transparent!important;
    border: none!important
}

.icon-modal i {
    color: #000;
    /* font-size: 2rem!important */
}

.icon-modal h5 {
    margin-left: 1rem
}

.addons {
    margin-bottom: .5rem
}

.addons a {
    /* font-size: 1.75rem!important; */
    text-decoration: underline!important;
    color: #000
}

.group_link_row {
    padding: 1rem 0!important
}

.group_link_row .title_link_row {
    /* font-size: 1.75rem!important; */
    font-weight: 600
}

.group_link_row .link_row {
    padding: 1rem 0!important;
    margin: 1rem 0
}

.group_link_row .link_row a {
    /* font-size: 1.5rem!important; */
    color: #95378e
}

.slide {
    clear: both;
    width: 100%;
    height: 0;
    overflow: hidden;
    text-align: left;
    transition: height .4s ease;
    list-style: none;
    margin: 0;
    padding: 0
}

.slide li a {
    color: rgba(0,0,0,.5)!important
}

.slide li {
    padding: 1.1rem 0
}

#ticket {
    position: absolute;
    opacity: 0;
    height: 0
}

#ticket:checked+.slide {
    min-height: 75px
}

#profile {
    position: absolute;
    opacity: 0;
    height: 0
}

#profile:checked+.slide {
    min-height: 90px
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #profile:checked+.slide {
        min-height:100px!important
    }
}

.bg_active {
    background: #fff;
    min-height: 75px;
    padding: 1rem 2rem!important;
    border-top: 1px solid #ebebeb!important
}

.bg_inactive {
    padding: 1rem!important;
    align-items: flex-start
}

.button_circle_fixed {
    position: fixed;
    bottom: 12%;
    right: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg,#dc0a24,#95378e);
    border-radius: 99rem
}

.button_circle_fixed button {
    border: none;
    outline: none;
    background: transparent!important;
    color: #fff
}

.button_circle_fixed button span i {
    /* font-size: 3rem!important */
}

.menu {
    list-style-type: none
}

.menu .share i {
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #fff;
    border-radius: 2px
}

.menu .share:hover.bottom .submenu li:first-child {
    opacity: 1;
    top: 50px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: .08s
}

.menu .share:hover.bottom .submenu li:nth-child(2) {
    opacity: 1;
    top: 100px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: .16s
}

.menu .share:hover.bottom .submenu li:nth-child(3) {
    opacity: 1;
    top: 150px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: .24s
}

.menu .share:hover.bottom .submenu li:nth-child(4) {
    opacity: 1;
    top: 200px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: .32s
}

.menu .share:hover.bottom .submenu li:nth-child(5) {
    opacity: 1;
    top: 250px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: .4s
}

.menu .share:hover.right .submenu li:first-child {
    opacity: 1;
    left: 50px;
    transform: rotate(0deg);
    transition-delay: .08s;
    border-left: 1px dashed #ccc
}

.menu .share:hover.right .submenu li:nth-child(2) {
    opacity: 1;
    left: 100px;
    transform: rotate(0deg);
    transition-delay: .16s;
    border-left: 1px dashed #ccc
}

.menu .share:hover.right .submenu li:nth-child(3) {
    opacity: 1;
    left: 150px;
    transform: rotate(0deg);
    transition-delay: .24s;
    border-left: 1px dashed #ccc
}

.menu .share:hover.right .submenu li:nth-child(4) {
    opacity: 1;
    left: 200px;
    transform: rotate(0deg);
    transition-delay: .32s;
    border-left: 1px dashed #ccc
}

.menu .share:hover.right .submenu li:nth-child(5) {
    opacity: 1;
    left: 250px;
    transform: rotate(0deg);
    transition-delay: .4s;
    border-left: 1px dashed #ccc
}

.menu .submenu {
    list-style-type: none;
    padding: 0;
    margin: 0
}

.menu .submenu li {
    transition: all .5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0
}

.menu .submenu li a {
    color: #212121
}

.menu .submenu li a:hover i.fa {
    color: #fff
}

.menu .submenu li a:hover.facebook i.fa {
    background-color: #3b5999
}

.menu .submenu li a:hover.twitter i.fa {
    background-color: #55acee
}

.menu .submenu li a:hover.googlePlus i.fa {
    background-color: #dd4b39
}

.menu .submenu li a:hover.instagram i.fa {
    background-color: #e4405f
}

.menu .submenu li:first-child {
    transform: rotateX(45deg)
}

.menu .submenu li:nth-child(2) {
    transform: rotateX(90deg)
}

.menu .submenu li:nth-child(3) {
    transform: rotateX(135deg)
}

.menu .submenu li:nth-child(4) {
    transform: rotateX(180deg)
}

.menu.bottomRight {
    bottom: 10px;
    right: 10px
}

.b-form-datepicker {
    border: 0!important
}

.b-form-datepicker .form-control {
    line-height: 2.5!important
}

.name-registrant {
    /* font-size: 3rem!important */
}

.image-mobile-profile {
    position: relative;
    width: 125px;
    height: 125px;
    background: #fff;
    border-radius: 99rem;
    margin-right: 2rem!important
}

.image-mobile-profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem;
    padding: .25rem
}

.content-mobile {
    background: #fff;
    transform: translateY(-35px)
}

html {
    /* font-size: 65% */
}

body {
    /* font-family: Cereal,sans-serif!important */
}

::-moz-selection {
    color: #fff!important;
    background: #95378e!important
}

::selection {
    color: #fff!important;
    background: #95378e!important
}

h1 {
    /* font-size: 4.8rem!important */
}

h2 {
    /* font-size: 3rem!important */
}

h3 {
    /* font-size: 2.5rem!important */
}

@media only screen and (max-width: 768px) {
    h3 {
        /* font-size:2rem!important */
    }
}

h4 {
    /* font-size: 2rem!important */
}

h5 {
    /* font-size: 1.75rem!important */
}

p,span {
    /* font-size: 1.5rem!important */
}

span .label_error {
    /* font-size: 1.25rem!important; */
    /* font-family: Cereal-Light,sans-serif!important */
}

a,label,li,select {
    /* font-size: 1.5rem!important */
}

a {
    text-decoration: none!important;
    cursor: pointer!important
}

button {
    /* font-size: 1.5rem!important */
}

strong {
    font-weight: 600!important
}

input {
    /* font-family: Cereal-Light,sans-serif!important */
}

input::-moz-placeholder {
    /* font-family: Cereal-Light,sans-serif!important */
}

input:-ms-input-placeholder {
    /* font-family: Cereal-Light,sans-serif!important */
}

input::placeholder {
    /* font-family: Cereal-Light,sans-serif!important */
}

.form-control {
    /* font-family: Cereal-Light,sans-serif!important; */
    font-weight: 500!important
}

select,select option {
    color: #000!important
}

@media only screen and (max-width: 768px) {
    html {
        /* font-size:69.5%!important */
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    html {
        /* font-size:80.5%!important */
    }
}

.carousel-inner {
    height: 100vh!important
}

@media only screen and (max-width: 768px) {
    .carousel-inner {
        min-height:100vh
    }
}

.carousel-inner .carousel-caption {
    margin-bottom: 25rem
}

@media only screen and (max-width: 768px) {
    .carousel-inner .carousel-caption {
        margin-bottom:0
    }
}

.owl-stage-outer {
    padding-left: 0!important
}

.about-home {
    background-size: cover!important;
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 89%);
    clip-path: polygon(0 0,100% 0,100% 100%,0 89%);
    position: relative;
    min-height: 90vh
}

@media only screen and (max-width: 768px) {
    .about-home {
        min-height:70vh!important;
        max-height: unset;
        -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 96%);
        clip-path: polygon(0 0,100% 0,100% 100%,0 96%)
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .about-home {
        min-height:30vh!important
    }
}

.about-home .overlay-about {
    position: absolute;
    top: 0;
    left: 0;
    background: #2b108e!important;
    filter: opacity(80%);
    height: 100%;
    width: 100%;
    z-index: -3;
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 89%);
    clip-path: polygon(0 0,100% 0,100% 100%,0 89%)
}

@media only screen and (max-width: 768px) {
    .about-home .overlay-about {
        -webkit-clip-path:polygon(0 0,100% 0,100% 100%,0 96%);
        clip-path: polygon(0 0,100% 0,100% 100%,0 96%)
    }
}

.about-home .content {
    z-index: 99;
    color: #fff
}

@media only screen and (max-width: 768px) {
    .about-home .content .left {
        padding-bottom:3rem
    }
}

.about-home .content .right .image_about {
    width: 400px!important
}

.right-about {
    margin-right: 3rem
}

#run_event .VueCarousel-inner {
    position: relative;
    width: 100%
}

.carousel-control-next .sr-only,.carousel-control-prev .sr-only {
    display: none
}

.carousel-control-next .carousel-control-next-icon,.carousel-control-next .carousel-control-prev-icon,.carousel-control-prev .carousel-control-next-icon,.carousel-control-prev .carousel-control-prev-icon {
    /* font-size: 2rem */
}

.section__desc {
    transform: translateY(-200px)
}

.home .container {
    width: 80%;
    margin: auto
}

.w-50-custom {
    width: 50%!important
}

.title-main-header>* {
    /* font-size: 5.5rem!important; */
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .title-main-header>* {
        /* font-size:3rem!important; */
        text-align: center
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .title-main-header>* {
        /* font-size:4.5rem!important */
    }
}

.title-main-header span {
    /* font-size: 5.5rem!important; */
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .title-main-header span {
        /* font-size:3rem!important; */
        text-align: center
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .title-main-header span {
        /* font-size:4.5rem!important */
    }
}

.title-main-header .gold {
    color: #f7af2f;
    /* font-size: 5.5rem!important */
}

@media only screen and (max-width: 768px) {
    .title-main-header .gold {
        /* font-size:2.5rem!important; */
        text-align: center
    }
}

.title-main-header .purple {
    color: #95378e;
    /* font-size: 5.5rem!important */
}

@media only screen and (max-width: 768px) {
    .title-main-header .purple {
        /* font-size:2.5rem!important; */
        text-align: center
    }
}

.img-fluid {
    filter: brightness(50%)!important
}

.overlay-hero {
    background: #000;
    width: 100%;
    height: 90vh;
    z-index: 1
}

.filter__hero.mobile {
    width: 100%!important;
    justify-content: center;
    position: relative;
    z-index: 999!important
}

.filter__hero.mobile .filter-by {
    width: 100%
}

.filter__hero.mobile .filter-search {
    z-index: 9999!important;
    min-width: 85%!important;
    padding-bottom: 1rem;
    padding: .5rem 0
}

.filter__hero.mobile .filter-search i {
    /* font-size: 2rem; */
    margin-right: 1rem;
    padding-left: 2rem;
    color: #95378e!important
}

.filter__hero.mobile .filter-search .search {
    padding: 0 0 .35rem 0!important
}

#about .img__about {
    position: relative;
    width: 400px;
    height: 400px;
    background: #000;
    border-radius: .5rem
}

#about .img__about img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: .5s;
    border-radius: .5rem
}

#about .text {
    /* font-family: Cereal-Light,sans-serif!important */
}

@media only screen and (max-width: 768px) {
    #about .text {
        margin:1rem 0!important
    }
}

@media only screen and (max-width: 768px) {
    #about .text .desc {
        /* font-size:1.75rem!important */
    }
}

#about .text .sub__title {
    text-transform: capitalize;
    /* font-family: sans-serif!important */
}

#about .text .sub__title span {
    /* font-size: 5rem!important */
}

@media only screen and (max-width: 768px) {
    #about .text .sub__title {
        /* font-size:3rem!important */
    }
}

.sub__title,.sub__title span {
    /* font-family: sans-serif!important */
}

.sub__title span {
    /* font-size: 5rem!important */
}

@media only screen and (max-width: 768px) {
    .sub__title span {
        /* font-size:3rem!important */
    }
}

.sub__title>* {
    /* font-size: 5rem!important; */
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .sub__title>* {
        /* font-size:3rem!important */
    }
}

#why {
    background: #fff;
    min-height: 100vh;
    position: relative!important;
    z-index: 0!important
}
/* 
#why .parent_services {
    background: url(../img/dotted.6d67f601.svg);
    background-repeat: no-repeat;
    background-position: 50%
} */

/* #why .bg-why {
    background: url(../img/dotted.6d67f601.svg);
    background-repeat: no-repeat
} */

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #why .bg-why {
        background-position:2px 30px
    }
}

#why .sub__title {
    margin-top: 2rem;
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    #why .sub__title {
        /* font-size:2.5rem!important */
    }
}

@media only screen and (max-width: 768px) {
    #why {
        min-height:30vh!important
    }
}

.subtitle_sec {
    /* font-size: 1.75rem!important; */
    /* font-family: Cereal-Light,sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .subtitle_sec {
        /* font-size:1.5rem!important */
    }
}

#partner {
    margin-top: 4rem;
    margin-bottom: 3rem;
    min-height: 75vh;
    display: flex;
    width: 100%;
    align-items: center;
    -webkit-clip-path: polygon(0 20%,100% 0,100% 77%,0 100%);
    clip-path: polygon(0 20%,100% 0,100% 77%,0 100%);
    background-size: cover!important;
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    position: relative;
    overflow: hidden
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #partner {
        min-height:60vh
    }
}

#partner .list-partner {
    margin-left: 6rem;
    position: relative
}

#partner .list-partner .previous-partner {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    left: -50px;
    cursor: pointer;
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

#partner .list-partner .previous-partner i {
    /* font-size: 2rem; */
    color: #000
}

#partner .list-partner .next-partner {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: -50px;
    cursor: pointer;
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

#partner .list-partner .next-partner i {
    /* font-size: 2rem; */
    color: #000
}

@media only screen and (max-width: 768px) {
    #partner {
        -webkit-clip-path:polygon(0 8%,100% 0,100% 94%,0 100%);
        clip-path: polygon(0 8%,100% 0,100% 94%,0 100%)
    }
}

#partner .overlay-partner {
    position: absolute;
    top: 0;
    left: 0;
    background: #2b108e!important;
    filter: opacity(80%);
    min-height: 100vh;
    width: 100%;
    z-index: 0;
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 89%);
    clip-path: polygon(0 0,100% 0,100% 100%,0 89%)
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #partner .overlay-partner {
        min-height:70vh
    }
}

#partner .left {
    border-right: 1px solid #e0e0e0;
    padding-right: 50px
}

@media only screen and (max-width: 768px) {
    #partner .left {
        border:none;
        text-align: center;
        padding-right: 0
    }
}

#partner .left p {
    /* font-size: 3rem!important; */
    text-transform: capitalize
}

@media only screen and (max-width: 768px) {
    #partner .left p {
        /* font-size:2.5rem!important; */
        /* font-family: sans-serif */
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #partner .left p {
        /* font-size:3rem!important */
    }
}

#partner .wrapper-logo {
    background: #fff;
    border-radius: 1rem
}

@media only screen and (max-width: 768px) {
    #partner .wrapper-logo {
        height:150px!important
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #partner .wrapper-logo {
        width:225px!important;
        height: 225px!important
    }
}

#partner .content {
    color: #fff;
    z-index: 5;
    width: 100%
}

#partner .owl-item,#partner .wrapper-logo {
    display: flex;
    justify-content: center
}

#partner .wrapper-logo {
    width: 200px;
    height: 200px;
    padding: 1rem;
    align-items: center
}

@media only screen and (max-width: 768px) {
    #partner .wrapper-logo {
        width:200px;
        height: 200px
    }
}

#partner .wrapper-logo img {
    width: 100%
}

.sub__title h2,.sub__title h3,.sub__title h4,.sub__title h5,.sub__title h6 {
    /* font-family: sans-serif!important; */
    /* font-size: 5rem!important */
}

@media only screen and (max-width: 768px) {
    .sub__title h2,.sub__title h3,.sub__title h4,.sub__title h5,.sub__title h6 {
        /* font-size:2.75rem!important */
    }
}

.sub__title h2 p,.sub__title h3 p,.sub__title h4 p,.sub__title h5 p,.sub__title h6 p {
    /* font-family: sans-serif!important; */
    /* font-size: 5rem!important */
}

@media only screen and (max-width: 768px) {
    .sub__title h2 p,.sub__title h3 p,.sub__title h4 p,.sub__title h5 p,.sub__title h6 p {
        /* font-size:2.75rem!important */
    }
}

#run_events {
    position: relative!important;
    z-index: 0!important;
    min-height: 80vh;
    margin: 2rem 0 6rem 0!important
}

#run_events.home_event {
    margin: 3rem 0 0 0!important
}

@media only screen and (max-width: 768px) {
    #run_events.home_event {
        margin:2rem 0 2rem 0!important
    }
}

@media only screen and (max-width: 768px) {
    #run_events {
        min-height:30vh
    }
}

@media only screen and (max-width: 768px) {
    #run_events .row {
        --bs-gutter-x:0!important
    }
}

@media only screen and (max-width: 768px) {
    #run_events {
        margin:.75rem 0!important
    }
}

#run_events .sub__title {
    color: #000;
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    #run_events .sub__title {
        text-align:center
    }
}

#run_events .subtitle_sec {
    text-align: center;
    /* font-family: Cereal-Light,sans-serif!important */
}

#run_events .content .tabs__event .nav-tabs {
    width: 100%
}

#run_events .content .tabs__event .nav-tabs .nav-link.active {
    background: #95378e!important;
    color: #fff
}

#run_events .content .tabs__event .nav-tabs .nav-link {
    color: #000
}

#run_events .desktop-runevent {
    position: relative
}

#run_events .desktop-runevent .next {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 2rem!important; */
    top: 40%;
    right: -20px;
    width: 60px;
    height: 60px;
    z-index: 99;
    border-radius: 99rem;
    background: #fff!important;
    cursor: pointer;
    box-shadow: -2px 1px 70px -5px rgba(0,0,0,.75)
}

#run_events .desktop-runevent .next i {
    /* font-size: 4rem */
}

.tabs li {
    transition: flex-grow 1s ease
}

#testimonials {
    margin: 25px 0 75px 0!important;
    background: #2b108e!important;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10rem 0!important;
    -webkit-clip-path: polygon(0 0,100% 9%,100% 98%,0 88%);
    clip-path: polygon(0 0,100% 9%,100% 98%,0 88%)
}

@media only screen and (max-width: 768px) {
    #testimonials {
        padding:5rem 0 8rem 0!important;
        margin: 25px 0 30px 0!important;
        -webkit-clip-path: polygon(0 0,100% 6%,100% 98%,0 93%);
        clip-path: polygon(0 0,100% 6%,100% 98%,0 93%)
    }
}

#testimonials .previous-partner {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    left: -50px;
    cursor: pointer;
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

#testimonials .previous-partner i {
    /* font-size: 2rem; */
    color: #000
}

#testimonials .next-partner {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: -50px;
    cursor: pointer;
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

#testimonials .next-partner i {
    /* font-size: 2rem; */
    color: #000
}

#testimonials .owl-dots {
    text-align: left!important
}

@media only screen and (max-width: 768px) {
    #testimonials .owl-dots {
        text-align:center!important
    }
}

#testimonials .left {
    display: flex;
    flex-direction: column;
    color: #fff
}

@media only screen and (max-width: 768px) {
    #testimonials .left {
        text-align:center;
        padding: 2rem 0
    }
}

#testimonials .left h2 {
    /* font-size: 4rem!important; */
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    #testimonials .left h2 {
        /* font-size:2.5rem!important */
    }
}

#testimonials .left p {
    /* font-family: Cereal-Light,sans-serif!important */
}

@media only screen and (max-width: 768px) {
    #testimonials .left p {
        /* font-size:1.5rem!important */
    }
}

@media only screen and (max-width: 768px) {
    #testimonials .right {
        position:relative!important;
        top: 0;
        transform: translateY(0)
    }
}

#testimonials .right .card-testimonial {
    background: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 300px;
    max-width: 100%;
    border-radius: 1rem
}

@media only screen and (max-width: 768px) {
    #testimonials .right .card-testimonial {
        min-height:400px;
        width: 100%;
        justify-content: center
    }
}

#testimonials .right .card-testimonial .text .quote {
    /* font-family: Cereal-Light,sans-serif!important */
}

@media only screen and (max-width: 768px) {
    #testimonials .right .card-testimonial .text .quote {
        /* font-size:1.35rem!important */
    }
}

#testimonials .right .card-testimonial .text i {
    /* font-size: 3rem; */
    background: linear-gradient(90deg,#dc0a24,#95378e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

#testimonials .right .card-testimonial .profile {
    display: flex;
    margin-top: 1rem
}

@media only screen and (max-width: 768px) {
    #testimonials .right .card-testimonial .profile .about-runner,#testimonials .right .card-testimonial .profile .img {
        width:200px
    }
}

#testimonials .right .card-testimonial .profile .img {
    position: relative;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 99rem;
    margin-right: 1.5rem!important
}

#testimonials .right .card-testimonial .profile .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem
}

@media only screen and (max-width: 768px) {
    #testimonials .right .card-testimonial .profile .about-runner span {
        /* font-size:1.15rem!important; */
        /* font-family: Cereal-Light,sans-serif!important */
    }
}

#testimonials .right .card-testimonial .profile .about-runner .name {
    font-family: Cereal,sans-serif!important
}

@media only screen and (max-width: 768px) {
    #testimonials .right .card-testimonial .profile .about-runner .name {
        /* font-size:1.5rem!important */
    }
}

.VueCarousel-slide {
    margin: 0 2px 0 2px;
    padding: 2px 2px 2px 2px
}

.VueCarousel-slide img {
    width: 100%
}

.VueCarousel-slide>div {
    padding-left: 15px;
    padding-right: 15px
}

.is__scroll {
    background: #000
}

#gallery {
    margin: 2rem 0 8rem 0!important
}

#gallery .card-columns .card img {
    width: 100%
}

@media only screen and (max-width: 768px) {
    #gallery {
        margin:2rem 0 4rem 0!important
    }

    #gallery .masonry-grid {
        height: auto!important
    }

    #gallery .masonry-grid .masonry-grid-item {
        width: 50%!important;
        min-height: 25px!important;
        float: left!important;
        transform: unset!important;
        position: static!important;
        display: block!important;
        box-sizing: border-box
    }

    #gallery .masonry-grid .masonry-grid-item .card-with-desc {
        width: 100%!important
    }
}

.card-container {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3,minmax(0,1fr));
    align-items: start
}

.column {
    display: grid;
    row-gap: 24px;
    grid-template-columns: minmax(0,1fr)
}

figure {
    overflow: hidden
}

figure img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all .5s
}

figure img:hover {
    cursor: zoom-in;
    transform: scale(1.1);
    filter: brightness(50%)
}

.wrapper_allevent {
    position: relative
}

.wrapper_allevent .image_allevent {
    position: absolute!important;
    top: 0;
    left: 70px;
    z-index: 2;
    width: 200px
}

.wrapper_allevent #allEvent {
    -webkit-clip-path: polygon(0 20%,100% 0,100% 77%,0 100%);
    clip-path: polygon(0 20%,100% 0,100% 77%,0 100%);
    position: relative;
    min-height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50%
}

@media only screen and (max-width: 768px) {
    .wrapper_allevent #allEvent {
        -webkit-clip-path:polygon(0 8%,100% 0,100% 91%,0 100%);
        clip-path: polygon(0 8%,100% 0,100% 91%,0 100%);
        min-height: 50vh!important
    }
}

.wrapper_allevent #allEvent .overlay-allevent {
    position: absolute;
    top: 0;
    left: 0;
    background: #2b108e!important;
    filter: opacity(50%);
    min-height: 100vh;
    width: 100%;
    z-index: 0;
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 89%);
    clip-path: polygon(0 0,100% 0,100% 100%,0 89%)
}

.wrapper_allevent #allEvent .content {
    color: #fff;
    z-index: 5
}

.wrapper_allevent #allEvent h3 {
    color: #fff;
    /* font-size: 4rem!important */
}

@media only screen and (max-width: 768px) {
    .wrapper_allevent #allEvent h3 {
        /* font-size:2rem!important */
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .wrapper_allevent #allEvent h3 {
        /* font-size:2.5rem!important */
    }
}

#news {
    margin-top: 50px
}

.news {
    margin-top: 0!important
}

@media only screen and (max-width: 768px) {
    #footer {
        padding-bottom:75px!important
    }
}

#footer .title-footer {
    /* font-size: 1.5rem */
}

#footer .form-subscribe {
    width: 100%
}

#footer .form-control {
    width: 250px;
    height: 40px;
    border: 2px solid #261362;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    /* font-size: 1.25rem */
}

#footer .input-group-append .btn-subscribe {
    background: #8167e6;
    padding: 0 3rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
}

#footer .input-group-append .btn-subscribe i {
    color: #fff;
    /* font-size: 2rem */
}

#halfBanner {
    min-height: 70vh;
    position: relative;
    top: 0;
    left: 0;
    color: #fff;
    padding: 2rem 0;
    background-size: cover!important;
    background-position: 50%!important;
    background-repeat: no-repeat!important
}

#halfBanner .icon-share i {
    margin: 0!important
}

@media only screen and (max-width: 768px) {
    #halfBanner {
        min-height:60vh
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    #halfBanner {
        min-height:40vh
    }
}

#halfBanner .title-banner {
    /* font-size: 5rem!important; */
    margin-bottom: 1rem;
    /* font-family: sans-serif!important; */
    color: #fff!important
}

@media only screen and (max-width: 768px) {
    #halfBanner .title-banner {
        /* font-size:3rem!important */
    }
}

#halfBanner .breadcrumb,#halfBanner .card-hover .card-hover-img .social-med .social-bullet:hover i,#halfBanner .title,.card-hover .card-hover-img .social-med .social-bullet:hover #halfBanner i {
    z-index: 1
}

#halfBanner .overlay-halfbanner {
    position: absolute;
    top: 0;
    left: 0;
    height: 65vh;
    width: 100%
}

#halfBanner i {
    /* font-size: 2rem!important; */
    margin-right: 1rem!important
}

#halfBanner span {
    /* font-size: 1.5rem!important */
}

#fullBanner {
    min-height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    color: #fff;
    padding: 2rem 0;
    background-size: cover!important;
    background-position: 50%!important;
    background-repeat: no-repeat!important
}

@media only screen and (max-width: 768px) {
    #fullBanner {
        z-index:10;
        min-height: 85vh;
        text-align: center
    }
}

#fullBanner .overlay-fullbanner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50%;
    background: #2b108e!important;
    -webkit-clip-path: polygon(0 0,100% 0,76% 100%,0 100%);
    clip-path: polygon(0 0,100% 0,76% 100%,0 100%);
    filter: opacity(75%)!important;
    z-index: 0
}

@media only screen and (max-width: 768px) {
    #fullBanner .overlay-fullbanner {
        z-index:1;
        background: rgba(43,16,142,.3);
        height: 85vh
    }
}

#fullBanner .content-banner {
    z-index: 3
}

#fullBanner .filter__hero label {
    margin-bottom: -6px!important
}

@media only screen and (max-width: 768px) {
    #fullBanner .filter__hero label {
        /* font-size:1.75rem!important; */
        color: #000!important
    }
}

@media only screen and (max-width: 766px) {
    #fullBanner .filter__hero .filter-by {
        border-radius:99rem!important
    }
}

#fullBanner .filter__hero .filter-by:hover {
    background: #e6e6e6
}

#fullBanner .filter__hero .filter-by img,#fullBanner .filter__hero .filter-by svg {
    width: 20px!important
}

@media only screen and (max-width: 768px) {
    #fullBanner .filter__hero .search {
        border:none!important
    }
}

#fullBanner .filter__hero .filter-search {
    z-index: 1!important;
    box-shadow: -2px 3px 15px -5px rgba(0,0,0,.25)
}

#fullBanner .filter__hero .filter-search button {
    background: #fff;
    border: none
}

#fullBanner .filter__hero .filter-search button i {
    /* font-size: 2rem!important; */
    color: linear-gradient(90deg,#dc0a24,#95378e)!important
}

#fullBanner .filter__hero .search {
    background: transparent!important;
    line-height: 30px!important
}

#fullBanner .filter__hero .search::-moz-placeholder {
    /* font-size: 1rem */
}

#fullBanner .filter__hero .search:-ms-input-placeholder {
    /* font-size: 1rem */
}

#fullBanner .filter__hero .search::placeholder {
    /* font-size: 1rem */
}

#fullBanner .filter__hero .custom-select,.mx-datepicker {
    background: transparent!important
}

.mx-datepicker .mx-input-wrapper .mx-input {
    background: transparent!important;
    /* font-size: 1.5rem!important; */
    padding-left: 5px!important
}

.owl-item:first-child {
    margin-right: -2px
}

@media only screen and (max-width: 768px) {
    .home .container {
        width:92%!important;
        margin: auto
    }
}

.image-about {
    position: relative;
    width: 100%;
    min-height: 550px;
    background: #fff;
    border-radius: 1rem
}

.image-about img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 1rem
}

@media only screen and (max-width: 768px) {
    .image-about {
        min-height:200px
    }
}

#eventlist .filter__hero {
    display: flex
}

#eventlist .filter__hero .filter-by {
    border: 1px solid #e9ecef
}

@media only screen and (max-width: 768px) {
    #eventlist .filter__hero .filter-by {
        padding:.75rem 3rem .75rem 3rem
    }
}

#eventlist .filter__hero .filter-by label {
    margin-bottom: -6px!important
}

@media only screen and (max-width: 768px) {
    #eventlist .filter__hero .filter-by label {
        /* font-size:1.75rem!important; */
        color: #000!important
    }
}

#eventlist .filter__hero .filter-by .search {
    background: transparent
}

#eventlist .filter__hero .filter-by .search::-moz-placeholder {
    /* font-size: 1.35rem */
}

#eventlist .filter__hero .filter-by .search:-ms-input-placeholder {
    /* font-size: 1.35rem */
}

#eventlist .filter__hero .filter-by .search::placeholder {
    /* font-size: 1.35rem */
}

@media only screen and (max-width: 768px) {
    #eventlist .filter__hero .filter-by .search {
        border:none!important
    }
}

#eventlist .filter__hero .filter-by .mx-datepicker {
    border: none!important
}

#eventlist .filter__hero .filter-by i {
    /* font-size: 2rem!important; */
    margin-left: 4rem
}

#eventlist .filter__hero .filter-by i.no-margin {
    margin-left: 0!important
}

#eventlist .filter__hero .filter-by button {
    border: none;
    background: #fff
}

#eventlist .btn-secondary {
    background: #fff!important;
    color: #000!important
}

.form-control.search {
    border: none;
    border-radius: 0!important;
    /* font-size: 2rem!important */
}

@media only screen and (max-width: 768px) {
    .form-control.search::-webkit-input-placeholder {
        /* font-size:1.35rem!important; */
        margin-bottom: 5px
    }
}

.search-modal {
    /* font-size: 1.25rem!important */
}

#run_event {
    margin: 7rem 0 7rem 0!important
}

@media only screen and (max-width: 768px) {
    #run_event {
        margin:0!important;
        overflow: hidden
    }
}

#run_event .right {
    position: sticky!important;
    top: 120px!important
}

#run_event {
    position: relative!important
}

#run_event .right-sticky {
    position: sticky!important;
    top: 0!important;
    right: 0
}

.share {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem
}

.share h5 {
    padding: 2rem;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.share .soc {
    border-right: 1px solid rgba(0,0,0,.1);
    width: 100%;
    padding: 1rem 0
}

.share .soc i {
    /* font-size: 2rem!important */
}

.card-package {
    border-radius: 1rem!important;
    min-height: 180px;
    cursor: pointer;
    border: 0!important;
    box-shadow: 0 2px 8px 0 rgba(53,64,90,.075)
}

@media only screen and (max-width: 768px) {
    .card-package {
        min-height:auto;
        margin: 1rem 0;
        border: 0!important;
        box-shadow: 0 2px 8px 0 rgba(53,64,90,.075)
    }
}

.card-package.active {
    position: relative;
    box-sizing: border-box;
    background: #95378e;
    color: #fff;
    border-radius: 99rem;
    color: #000;
    box-shadow: 0 2px 8px 0 rgba(53,64,90,.075)
}

.card-package.active h5,.card-package.active p,.card-package.active span {
    color: #fff
}

.card-package.active .white-active {
    color: #fff!important
}

.card-package.active .description-package,.card-package.active .package-name {
    border-color: #fff!important
}

.card-package.disabled {
    position: relative;
    box-sizing: border-box;
    color: #fff;
    background: #f8f8f8;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 99rem;
    color: #000
}

.card-package .package-name {
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 1rem 0
}

.card-package .package-name.border-none {
    border-bottom: 0!important
}

.card-package .package-name h5 {
    /* font-size: 1.5rem!important */
}

@media only screen and (max-width: 768px) {
    .card-package .package-name h5 {
        /* font-size:1.5rem!important */
    }
}

.card-package .description-package {
    margin: 1rem 0
}

.card-package .footer-package .main-button {
    border: 1px solid #000!important
}

.check-availability .custom-control {
    position: relative
}

.check-availability .custom-control-input {
    z-index: 2;
    width: 20px;
    height: 20px;
    position: absolute
}

@media only screen and (min-width: 768px) {
    .check-availability .custom-control-input {
        top:30px;
        right: 20px;
        transform: translateX(350px) translateY(10px);
        transform: translateY(-50%)
    }
}

@media only screen and (max-width: 768px) {
    .check-availability .custom-control-input {
        bottom:30px;
        right: 20px
    }
}

.check-availability .custom-control-label {
    width: 100%
}

.bv-no-focus-ring {
    display: flex;
    margin-top: 1rem 0!important;
    justify-content: center
}

.custom-control {
    margin-right: 1rem!important
}

.choose-method {
    padding: .75rem 0!important;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 99rem;
    color: #000;
    display: flex
}

@media only screen and (max-width: 768px) {
    .choose-method {
        padding:.75rem .5rem!important;
        flex-direction: column;
        border: 0!important
    }
}

.choose-method .option {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 6px;
    border-radius: 99rem;
    cursor: pointer;
    padding: 1rem 10px;
    border: 2px solid #d3d3d3;
    transition: all .3s ease
}

@media only screen and (max-width: 768px) {
    .choose-method .option {
        padding:1rem 3px!important;
        justify-content: center;
        margin: .5rem 0
    }
}

.choose-method .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative
}

@media only screen and (max-width: 768px) {
    .choose-method .option .dot {
        margin:0 1rem
    }
}

.choose-method .option .dot:before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all .3s ease
}

.choose-method input[type=radio] {
    display: none
}

.choose-method #option-1:checked:checked~.option-1,.choose-method #option-2:checked:checked~.option-2 {
    border-color: linear-gradient(90deg,#95378e,#0287b4);
    background: linear-gradient(90deg,#95378e,#0287b4)
}

.choose-method #option-1:checked:checked~.option-1 .dot,.choose-method #option-2:checked:checked~.option-2 .dot {
    background: #fff
}

.choose-method #option-1:checked:checked~.option-1 .dot:before,.choose-method #option-2:checked:checked~.option-2 .dot:before {
    opacity: 1;
    transform: scale(1)
}

.choose-method .wrapper .option span {
    /* font-size: 20px; */
    color: grey
}

@media only screen and (max-width: 768px) {
    .choose-method .wrapper .option span {
        /* font-size:.5rem!important */
    }
}

.choose-method #option-1:checked:checked~.option-1 span,.choose-method #option-2:checked:checked~.option-2 span {
    color: #fff
}

@media only screen and (max-width: 768px) {
    .name-package {
        /* font-size:1.75rem!important */
    }
}

.modal-dialog,.modal-header {
    overflow: hidden
}

.modal-header .close {
    background: #fff;
    border: none;
    /* font-size: 3rem!important */
}

.modal-content {
    border-radius: 1.5rem!important
}

.modal-body {
    margin: 1rem 0 2rem 0!important
}

#modal-benefit .check {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6!important;
    border-bottom: 1px solid #dee2e6!important
}

#modal-benefit .check .date {
    color: #495057
}

#modal-benefit .top-sec .name-package {
    /* font-size: 1.35rem */
}

@media only screen and (max-width: 768px) {
    #modal-benefit .top-sec .name-package {
        /* font-size:1.5rem!important */
    }
}

#modal-benefit .benefit {
    display: flex;
    margin: 1rem 0;
    border-bottom: 1px solid rgba(0,0,0,.2)
}

@media only screen and (max-width: 768px) {
    #modal-benefit .benefit {
        display:block
    }
}

#modal-benefit .benefit .included-benefit {
    display: block;
    min-width: 200px!important
}

.image-event {
    position: relative
}

.image-event .icon-download {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: #95378e;
    padding: 1.5rem 1.5rem;
    border-radius: 99rem;
    transition: all .25s ease-in-out
}

.image-event .icon-download:hover {
    background: #5f1f5b
}

.image-event .icon-download span {
    color: #fff
}

@media only screen and (max-width: 768px) {
    #detailPackage {
        margin-top:-20px!important
    }
}

.sec-icon {
    border-bottom: 1px solid #dee2e6;
    padding: 2rem 0
}

.sec-icon.bg-color {
    background: #f6f9ff
}

.sec-icon.mobile-ver {
    display: flex;
    justify-content: space-between;
    position: relative
}

.sec-icon.mobile-ver .top {
    margin-bottom: 0!important
}

.sec-icon.p1-rem {
    padding: 1rem 0!important
}

.sec-icon.mt-0 {
    padding: 0
}

.sec-icon.border-none {
    border: 0
}

.sec-icon.payment {
    border: 0!important;
    box-shadow: 0 2px 8px 0 rgba(53,64,90,.075);
    padding: 1.5rem;
    border-radius: 1rem;
    width: 100%;
    min-height: 30vh
}

@media only screen and (max-width: 768px) {
    .sec-icon.payment {
        margin-top:20px!important;
        width: 100%;
        min-height: 40vh
    }
}

.sec-icon .top {
    display: flex!important;
    align-items: center;
    margin-bottom: 1.5rem!important
}

.sec-icon .top .icon {
    /* font-size: 2.5rem!important; */
    border: 1px solid rgba(0,0,0,.2);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99rem;
    margin-right: 1rem!important
}

@media only screen and (max-width: 768px) {
    .sec-icon .top .icon {
        width:35px;
        height: 35px
    }

    .sec-icon .top .icon i {
        /* font-size: 1.75rem!important */
    }
}

.sec-icon .top h5 {
    text-transform: uppercase;
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .sec-icon .top h5 {
        /* font-size:1.5rem!important */
    }
}

.sec-icon .content p span {
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
    /* font-size: 1.45rem!important */
}

.sec-icon .content-2-column {
    margin: 1rem 0;
    display: flex
}

@media only screen and (min-width: 768px) {
    .sec-icon .content-2-column.custom p:last-child {
        max-width:175px
    }
}

@media only screen and (max-width: 768px) {
    .sec-icon .content-2-column.custom p:last-child {
        max-width:100%
    }
}

.sec-icon .content-2-column.between {
    display: flex;
    justify-content: space-between!important
}

@media only screen and (max-width: 768px) {
    .sec-icon .content-2-column.between {
        display:block
    }
}

.sec-icon .content-2-column.column-direction {
    margin: 2.25rem 0
}

.sec-icon .content-2-column.column-direction>div {
    align-items: start
}

@media only screen and (max-width: 768px) {
    .sec-icon .content-2-column {
        display:block!important
    }

    .sec-icon .content-2-column.column-direction {
        flex-direction: column
    }

    .sec-icon .content-2-column.column-direction>div {
        flex-direction: column;
        align-items: start
    }
}

@media only screen and (max-width: 768px) {
    .sec-icon .content-2-column.mobile-ver {
        display:flex!important;
        justify-content: space-between
    }
}

.sec-icon .content-2-column p:first-child {
    width: 200px!important;
    text-transform: capitalize
}

@media only screen and (max-width: 768px) {
    .sec-icon .content-2-column p:first-child {
        max-width:100%!important
    }
}

.sec-icon .content-2-column p.w-100 {
    width: 100%!important
}

.sec-icon .content-2-column.price {
    display: flex;
    justify-content: space-between
}

.sec-icon .content-2-column.price p:first-child {
    width: 200px!important;
    text-transform: capitalize
}

@media only screen and (max-width: 768px) {
    .sec-icon .content-2-column.price {
        display:block
    }
}

.sec-icon.between {
    display: flex;
    justify-content: space-between!important
}

@media only screen and (max-width: 768px) {
    .sec-icon.between {
        display:block
    }
}

.sec-icon.with-button {
    display: flex;
    padding: 2rem 0 1.5rem 0!important;
    justify-content: space-between;
    align-items: flex-start
}

@media only screen and (max-width: 768px) {
    .sec-icon.with-button {
        flex-direction:column;
        align-items: flex-start;
        margin: 1rem 0
    }
}

.sec-icon.with-button .top {
    width: 70%
}

@media only screen and (max-width: 768px) {
    .sec-icon.with-button .top {
        width:100%
    }
}

.sec-icon.with-button .link-button {
    margin-left: 50px
}

.tabs .tab-content ul li {
    margin: .75rem 0
}

.tabs .tab-content .overview p,.tabs .tab-content .overview p span {
    /* font-size: 1.5rem!important */
}

#about-page {
    margin: 2rem 0 10rem 0
}

@media screen and (max-width: 768px) {
    #about-page {
        text-align:center
    }
}

#about-page .sec-about {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media screen and (max-width: 768px) {
    #about-page .sec-about {
        align-items:center
    }
}

#about-page .sec-about h2 {
    font-weight: 600!important;
    /* font-size: 3.5rem!important */
}

#about-page .sec-about h2 span {
    font-weight: 700!important;
    /* font-size: 4rem!important */
}

@media screen and (max-width: 768px) {
    #about-page .sec-about h2,#about-page .sec-about h2 span {
        /* font-size:2rem!important */
    }
}

#about-page .sec-team {
    margin-top: 2rem
}

#about-page .sec-team .left h2 {
    font-weight: 600!important;
    /* font-size: 3.5rem!important */
}

@media screen and (max-width: 768px) {
    #about-page .sec-team .left h2 {
        /* font-size:2.5rem!important */
    }
}

.card-with-desc {
    background: rgba(0,0,0,.1);
    overflow: hidden
}

.card-with-desc,.card-with-desc .top {
    position: relative
}

.card-with-desc .desc {
    padding: 1rem;
    position: absolute;
    bottom: -220px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 100%;
    color: #fff;
    transition: all .1s ease-in-out;
    flex-direction: column;
    text-align: center
}

.card-with-desc .desc,.card-with-desc .desc .icon {
    border-radius: 99rem;
    display: flex;
    align-items: center
}

.card-with-desc .desc .icon {
    background: hsla(0,0%,100%,.8);
    width: 50px;
    height: 50px;
    justify-content: center;
    margin-bottom: .75rem
}

.card-with-desc .desc .icon i {
    color: #000;
    /* font-size: 2rem */
}

.card-with-desc .desc .tags-card span {
    margin: .5rem
}

.card-with-desc .desc .tags-card span a {
    color: #fff!important;
    font-style: italic
}

.card-with-desc .desc .tags-card span a:hover {
    text-decoration: underline!important
}

.card-with-desc .desc span {
    margin-top: .75rem
}

.card-with-desc:hover .desc {
    bottom: 10%!important
}

.card-with-desc:hover img {
    filter: brightness(50%)
}

.card-hover {
    text-align: center
}

.card-hover .card-hover-img {
    position: relative;
    width: 100%;
    height: 300px;
    background: #8d8484;
    border-radius: 1rem;
    overflow: hidden
}

@media screen and (max-width: 768px) {
    .card-hover .card-hover-img {
        height:200px
    }
}

.card-hover .card-hover-img.gallery {
    width: 100%!important;
    height: 200px!important
}

.card-hover .card-hover-img.gallery img,.card-hover .card-hover-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: .5s;
    border-radius: 1rem;
    transition: all .25s ease-in-out
}

.card-hover .card-hover-img .social-med {
    position: absolute;
    bottom: -220px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    transition: all .25s ease-in-out
}

.card-hover .card-hover-img .social-med .social-bullet {
    background: #95378e;
    margin: 0 1rem;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99rem
}

.card-hover .card-hover-img .social-med .social-bullet i {
    color: #fff!important;
    /* font-size: 2rem!important */
}

.card-hover .card-hover-img .social-med .social-bullet:hover {
    background: #fff
}

.card-hover .card-hover-img:hover .social-med {
    bottom: 20px!important
}

.card-hover .card-hover-img:hover img {
    filter: brightness(50%)
}

.card-hover .name-member {
    font-weight: 700
}

.contact-about {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .25rem 1rem!important;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 99rem;
    margin-top: 16px;
    margin-bottom: 1.5rem!important
}

@media screen and (max-width: 768px) {
    .contact-about {
        padding:.5rem!important;
        width: 150px
    }
}

.contact-about i {
    margin-right: 8px;
    /* font-size: 1.75rem!important */
}

.contact-about span {
    /* font-size: 1.25rem!important */
}

.registered {
    display: flex
}

.registered i {
    /* font-size: 4rem; */
    margin-right: 1rem
}

.registered .title-registered {
    font-weight: 600;
    /* font-size: 1.75rem!important; */
    letter-spacing: 1px
}

.color-gray {
    color: rgba(0,0,0,.5)
}

.img_secondary_banner {
    position: relative;
    width: 100%;
    height: 400px;
    background: #000;
    border-radius: .5rem
}

@media screen and (max-width: 768px) {
    .img_secondary_banner {
        height:300px
    }
}

.img_secondary_banner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: .5s;
    border-radius: .5rem
}

@media screen and (max-width: 768px) {
    .social-med,.social-med .social-bullet {
        display:flex;
        justify-content: center
    }

    .social-med .social-bullet {
        background: #95378e;
        margin: 0 .5rem;
        width: 35px;
        height: 35px;
        align-items: center;
        border-radius: 99rem
    }

    .social-med .social-bullet i {
        color: #fff!important;
        /* font-size: 1.75rem!important */
    }
}

.header-black {
    background: #fff;
    box-shadow: -2px 3px 15px -5px rgba(0,0,0,.25)
}

@media only screen and (max-width: 768px) {
    .header-black {
        padding:.35rem 0
    }
}

.header-black .nav-item {
    margin: 0 1.5rem
}

.header-black .nav-item .nav-link {
    color: #000!important;
    /* font-size: 1.5rem!important; */
    padding: 0!important;
    /* font-family: Cereal-Light,sans-serif!important */
}

.header-white {
    background: transparent;
    border-bottom: 1px solid #7f7f7f
}

.header-white .nav-item {
    margin: 0 1.5rem
}

.header-white .nav-item .nav-link {
    color: #fff!important;
    /* font-size: 1.5rem!important; */
    padding: 0!important;
    /* font-family: Cereal-Light,sans-serif!important */
}

header {
    z-index: 5;
    position: fixed;
    width: 100%
}

header .navbar-brand img {
    width: 70%
}

@media only screen and (max-width: 768px) {
    header .navbar-brand img {
        width:250px!important
    }
}

header .nav-item {
    margin: 0 .75rem
}

header .nav-item .nav-link {
    letter-spacing: 1px
}

.navbar-toggler-icon {
    /* font-size: 2rem!important; */
    color: #fff!important
}

.isn-half-banner {
    position: fixed;
    top: 0;
    left: 0!important
}

.navbar-light .navbar-toggler-icon {
    color: #fff!important
}

.navbar-toggler i {
    color: #fff;
    /* font-size: 3.5rem */
}

@media only screen and (max-width: 768px) {
    .navbar-nav {
        padding-bottom:1rem!important
    }

    .navbar-nav .nav-item {
        margin: 1rem 0!important
    }

    #nav-collapse {
        padding: 2rem 0!important
    }
}

.navbar-toggler {
    border: none!important
}

#footer {
    background: #240046;
    padding: 2rem 0
}

#footer .address {
    display: flex;
    flex-direction: column;
    align-items: end
}

@media screen and (max-width: 768px) {
    #footer .address {
        align-items:start;
        margin-top: 1rem
    }
}

#footer .address p {
    margin: 0
}

#footer i {
    /* font-size: 1.75rem!important */
}

#footer a,#footer h5,#footer p {
    color: #fff
}

#footer a:hover {
    text-decoration: underline!important
}

#footer .socmed {
    display: flex
}

#footer .socmed .list-socmed {
    background: #fff;
    margin-right: 2px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: .75rem
}

#footer .socmed .list-socmed a {
    color: #000;
    text-decoration: none!important
}

.text-black-custom {
    color: #2b2d42!important
}

.block>div {
    margin: 0;
    padding: 0
}

.mt-15 {
    margin-top: 15rem!important
}

.mt-12 {
    margin-top: 12rem!important
}

.mt-10 {
    margin-top: 10rem!important
}

.ml-1rem {
    margin-left: 1rem!important
}

.mr-1rem {
    margin-right: 1rem!important
}

.mt-1rem {
    margin-top: 1rem!important
}

.ml-05rem {
    margin-left: .5rem!important
}

.mr-05rem {
    margin-right: .5rem!important
}

.mt-05rem {
    margin-top: .5rem!important
}

.mb-075rem {
    margin-bottom: .75rem!important
}

.mb-1rem {
    margin-bottom: 1rem!important
}

.mb-2rem {
    margin-bottom: 2rem!important
}

.mt-7 {
    margin-top: 7rem!important
}

#wrapper .mt0-important,.mt0-important {
    margin-top: 0!important
}

.mt-2_5 {
    margin-top: .75rem!important
}

.mt-8 {
    margin-top: 8rem!important
}

.mt-6 {
    margin-top: 6rem!important
}

.mtc-2 {
    margin-top: 2rem
}

.mti-1 {
    margin-top: 1rem!important
}

.ml1rem-important {
    margin-left: 1rem!important
}

.ml05rem-important {
    margin-left: .5rem!important
}

.ml2rem-important {
    margin-left: 2rem!important
}

.ml3rem-important {
    margin-left: 3rem!important
}

.mb-10 {
    margin-bottom: 10rem!important
}

.custom-alert {
    padding: .2rem .65rem;
    border-radius: 5px
}

.custom-alert.alert-warning {
    color: #f6a309;
    background: #fdedce
}

.custom-alert.alert-info {
    color: #0287b4;
    background: #cdf2fe
}

.custom-alert.alert-error {
    color: #b30e21;
    background: #fdced6
}

.color-red {
    color: #dc0a24
}

.w-497 {
    width: 49.75%!important
}

.rounded-1rem {
    border-radius: 1rem
}

.input-relative .icon-input {
    position: absolute;
    /* font-size: 1.75rem!important; */
    cursor: pointer;
    right: 15px;
    top: 50%;
    transform: translateY(-40%)
}

.bg-transparent-white {
    background: hsla(0,0%,100%,.5)
}

#run_event .overview a strong,#run_event .overview h1 strong,#run_event .overview h2 strong,#run_event .overview h3 strong,#run_event .overview h4 strong,#run_event .overview h5 strong,#run_event .overview h6 strong,#run_event .overview li strong,#run_event .overview p strong,#run_event .overview span strong,#run_event .overview strong,#run_event .overview ul strong {
    font-family: Cereal,sans-serif
}

.border-grey {
    border: 1px solid rgba(0,0,0,.1)
}

.border-bottom-section {
    padding-bottom: 2.5rem;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.border-top-1rem {
    padding-top: 1rem!important;
    border-top: 1px solid rgba(0,0,0,.1)!important
}

.border-bottom-1rem {
    padding-bottom: 1rem!important;
    border-bottom: 1px solid rgba(0,0,0,.1)!important
}

.border-bottom-010rem {
    padding-bottom: .1rem;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.card-package.active .description-package.border-bottom-010rem {
    border-color: #bcbcbc
}

.card-package.active .color-main {
    color: #fff!important
}

.card-package.active .box--1 span {
    color: #2b2d42
}

.card-package.active .custom-alert.alert-info {
    color: #0287b4;
    background: #cdf2fe
}

.card-package.active .custom-alert.alert-warning {
    color: #f6a309;
    background: #fdedce
}

.card-hover .card-hover-img .social-med .social-bullet:hover .card-package.active i,.card-package.active .card-hover .card-hover-img .social-med .social-bullet:hover i,.card-package.active .title {
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    color: #fff;
    -webkit-text-decoration-color: #fff!important;
    text-decoration-color: #fff!important
}

.card-package.active .color-gray {
    color: #eaeaea
}

.card-package.active a,.card-package.active p {
    color: #fff!important
}

.text-wrap {
    word-wrap: break-word!important
}

.text-underline {
    text-decoration: underline!important
}

.lineheight-2 {
    line-height: 2
}

.fix-top {
    background: #fff;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
    position: fixed;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%
}

.fix-top i {
    /* font-size: 2rem; */
    cursor: pointer
}

.table tr td,.table tr th {
    /* font-size: 1.5rem!important */
}

.rdg-table {
    background: #34495e;
    color: #fff;
    border-radius: .4em;
    overflow: hidden
}

.rdg-table tr {
    border-color: #46637f
}

.rdg-table td,.rdg-table th {
    margin: .5em 1em;
    /* font-size: 1.5rem!important */
}

@media(min-width: 480px) {
    .rdg-table td,.rdg-table th {
        padding:1em!important
    }
}

.rdg-table td:before,.rdg-table th {
    color: #dd5
}

.text-italic {
    font-style: italic!important
}

.color-main {
    color: #95378e!important
}

.color-main:hover {
    color: #5f1f5b!important
}

.color-white {
    color: #fff!important
}

.color-link {
    color: #0287b4
}

.color-link:hover {
    color: #027297
}

.color-green {
    color: #25a550
}

.color-yellow {
    color: #f7af2f!important
}

.bdrd-075 {
    border-radius: .75rem
}

.bdrd-1 {
    border-radius: 1rem
}

.bg-rdg-purple {
    background: #95378e
}

.bg-rdg-blue {
    background: #0287b4
}

.mr025-important {
    margin-right: .25rem!important
}

.mr05-important {
    margin-right: .5rem!important
}

.color-gray {
    color: #71747d
}

.color-grey {
    color: #495057
}

.text-title {
    /* font-size: 1.75rem!important */
}

.icon-2rem {
    /* font-size: 2.5rem */
}

.light {
    /* font-family: Cereal-Light,sans-serif!important */
}

.normal {
    font-family: Cereal,sans-serif!important
}

.bold {
    /* font-family: sans-serif!important */
}

.bg-active-filter {
    background: #95378e;
    color: #fff!important
}

.height-40px {
    height: 40px
}

.fs-75rem,.fs-75rem span {
    /* font-size: 1.75rem!important */
}

.page-item.active .page-link .sr-only {
    display: none!important
}

.pagination {
    display: flex;
    align-items: center
}

.pagination .page-item {
    padding: 0 .75rem
}

.pagination .page-item button {
    border: none
}

.pagination .page-item.disabled .page-link {
    border-radius: 99rem
}

.pagination .page-item .page-link {
    border-radius: 99rem;
    color: #95378e
}

.pagination .page-item.active .page-link {
    width: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 99rem!important;
    background: #95378e!important;
    color: #fff
}

.pagination .page-item a {
    padding: 1rem;
    /* font-size: 1.5rem!important; */
    color: #000;
    border: 0!important
}

.pagination .page-item i {
    /* font-size: 1.5rem */
}

.fs-3rem {
    /* font-size: 3rem!important */
}

.warning {
    background: #f7af2f!important
}

.custom-button {
    background: linear-gradient(90deg,#95378e,#0287b4);
    color: #fff;
    border: none;
    outline: none;
    padding: .75rem 1rem;
    border-radius: 99rem
}

.main-button {
    padding: 1rem 1.5rem!important;
    border: none!important;
    border-radius: 99rem!important;
    min-width: 100px;
    /* font-family: sans-serif!important; */
    transition: .25s!important;
    cursor: pointer;
    box-shadow: 0 2px 19px -10px #95378e!important;
    text-transform: uppercase!important
}

.main-button.lg {
    padding: 1rem 2.5rem!important
}

.main-button.grey-disable,.main-button .grey-disable {
    background: #e9e9e9;
    color: #616161;
    box-shadow: none!important
}

.main-button.sm {
    padding: .5rem 1rem!important
}

.sticky {
    position: sticky!important;
    top: 0;
    left: 0
}

.main-button-mini {
    padding: .5rem 1.5rem!important;
    border: none!important;
    border-radius: 99rem!important;
    /* font-size: 1rem!important; */
    letter-spacing: 1px!important;
    transition: .25s!important;
    display: flex;
    align-items: center;
    cursor: pointer
}

@media only screen and (max-width: 768px) {
    .main-button-mini {
        /* font-size:1.75rem!important */
    }
}

.main-button-mini.lg {
    padding: 1.5rem 3.5rem!important
}

.main-button-mini .grey-disable {
    background: #bdc3c7;
    color: #000
}

.main-button-mini i {
    /* font-size: 1.5rem; */
    margin-right: .5rem
}

details {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    background: rgba(0,0,0,.05);
    border-left: 5px solid grey
}

details.accordion-payment img {
    width: 50px
}

details.accordion-payment summary {
    justify-content: left;
    align-items: center
}

details {
    margin-top: 15px
}

details.warning {
    background: #dee2e6!important;
    border-left-color: #f7af2f!important
}

details.info {
    border-left-color: #95378e!important
}

details.alert,details.info {
    background: #dee2e6!important
}

details.alert {
    border-left-color: #f7af2f!important
}

details summary {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    font-style: normal;
    padding: 1rem 1rem;
    cursor: pointer
}

details summary,details summary i {
    /* font-size: 1.5rem */
}

details summary::-webkit-details-marker {
    display: none
}

details summary:focus {
    outline: solid 3px var(--highlight)
}

details summary::-moz-selection {
    background-color: var(--highlight)
}

details summary::selection {
    background-color: var(--highlight)
}

details p {
    cursor: default
}

details .fs-15rem,details .fs-15rem span {
    /* font-size: 1.5rem!important */
}

details summary:before {
    cursor: pointer;
    position: absolute;
    display: inline-flex;
    width: .25rem;
    height: 1rem;
    left: 0;
    margin-right: .5rem;
    content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.6066 12H1.3934' stroke='%23202842' stroke-width='1.875' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 1.39343V22.6066' stroke='%23202842' stroke-width='1.875' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
}

details[open] summary:before {
    transform: rotate(45deg);
    content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.6066 12H1.3934' stroke='%23202842' stroke-width='3.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 1.39343V22.6066' stroke='%23202842' stroke-width='3.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
}

.border-bt {
    border-bottom: 1px solid #ededee;
    padding-bottom: 1rem
}

.inputGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    width: 100%;
    position: relative;
    border-radius: 1rem
}

.inputGroup label {
    padding: 11px 37.5px 11px 37.5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color .2s ease-in;
    overflow: hidden;
    border-radius: 1rem;
    border: 1px solid #d2d2d2
}

.inputGroup label:after {
    width: 16px;
    height: 16px;
    content: "";
    border: 2px solid #d1d7dc;
    background-color: #fff;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all .2s ease-in
}

.inputGroup input:checked~label {
    border: 1px solid #95378e;
    color: #95378e
}

.inputGroup input:checked~label:before {
    transform: translate(-50%,-50%) scale3d(80,56,1);
    opacity: 1;
    border-radius: 1rem
}

.inputGroup input:checked~label:after {
    background-color: #95378e;
    border-color: #95378e
}

.inputGroup input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden
}

.inputGroup img {
    width: 50px
}

.form {
    padding: 0 16px;
    max-width: 550px;
    margin: 50px auto;
    /* font-size: 18px; */
    font-weight: 600;
    line-height: 36px
}

.main-button-filter {
    padding: .5rem 1.5rem!important;
    border: none!important;
    border-radius: 99rem!important;
    letter-spacing: 1px!important;
    transition: .25s!important;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 .5rem;
    text-transform: capitalize!important
}

.main-button-filter span {
    /* font-size: 1.5rem!important */
}

@media only screen and (max-width: 768px) {
    .main-button-filter {
        /* font-size:1.75rem!important */
    }
}

.main-button-filter.lg {
    padding: 1.5rem 3.5rem!important
}

.main-button-filter .grey-disable {
    background: #bdc3c7;
    color: #000
}

.main-button-filter i {
    /* font-size: 1.5rem; */
    margin-right: .5rem
}

.link-button {
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: 1.35rem!important; */
    cursor: pointer;
    border-bottom: 1px solid #5f1f5b;
    padding-bottom: .01rem
}

.wrapper-mypurchase {
    border: 1px solid #dee2e6;
    padding: 1.5rem;
    border-radius: 1rem;
    position: relative
}

.container {
    width: 90%!important;
    margin: 0 auto!important
}

@media only screen and (max-width: 768px) {
    .container {
        width:95%!important;
        margin: 0 auto!important
    }
}

.fluid-container {
    width: 90%!important;
    margin: 0 auto!important
}

@media only screen and (max-width: 768px) {
    .fluid-container {
        width:90%!important;
        margin: 0 auto!important
    }
}

.cta-hero {
    border-radius: 0!important;
    border-top-right-radius: 99rem!important;
    border-bottom-right-radius: 99rem!important;
    cursor: pointer
}

.border-linear {
    position: relative;
    box-sizing: border-box;
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #dc0a24!important;
    border-radius: 99rem;
    color: #000;
    cursor: pointer
}

.border-linear:hover {
    color: #000!important;
    box-shadow: 0 2px 19px -10px #95378e
}

.border-linear:focus {
    background: #fff!important
}

.border-linear:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(90deg,#dc0a24,#95378e)
}

.border-linear.btn-secondary {
    background: #fff;
    color: #000!important
}

.border-linear.btn-secondary:hover {
    background: #fff!important
}

.border-white-button {
    border: 1px solid #fff!important;
    background: transparent!important;
    color: #fff
}

.linear-primary {
    background: linear-gradient(90deg,#95378e,#0287b4);
    color: #fff
}

.linear-primary:hover {
    transition: .15s ease-in-out;
    background: linear-gradient(90deg,#5f1f5b,#027297)
}

.linear-primary.disabled {
    filter: brightness(.9);
    cursor: no-drop!important
}

.linear-success {
    background: linear-gradient(90deg,#11998e,#38ef7d);
    color: #fff
}

.linear-success:hover {
    filter: brightness(.85)
}

.not-linear {
    background: #fff;
    color: #000;
    border: 1px solid #b0b0b0
}

.linear-warning {
    background: linear-gradient(90deg,#ffb300,#ff9d00);
    color: #fff
}

.linear-warning:hover {
    filter: brightness(.85)
}

.transparent {
    background: transparent;
    color: #fff;
    border: 1px solid hsla(0,0%,100%,.5)
}

.transparent:hover {
    background: #fff;
    color: #95378e
}

.title-main-header {
    /* font-size: 4rem; */
    max-width: 688px
}

.maxheight_75 {
    height: 75vh!important
}

.maxheight_50 {
    height: 50vh!important
}

.maxheight_65 {
    height: 65vh!important
}

.maxheight_100 {
    height: 100vh!important
}

.minheight_100 {
    min-height: 100vh!important
}

.minheight_75 {
    min-height: 75vh!important
}

.minheight_50 {
    min-height: 50vh!important
}

.minheight_65 {
    min-height: 65vh!important
}

.sec__complete {
    padding: 3rem 0
}

@media only screen and (max-width: 768px) {
    .sec__complete {
        padding:3rem 0
    }
}

a.title {
    text-decoration: underline!important;
    -webkit-text-decoration-color: #000!important;
    text-decoration-color: #000!important;
    border-top: 0!important
}

.card-hover .card-hover-img .social-med .social-bullet:hover i,.title {
    background: linear-gradient(90deg,#95378e,#0287b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
    border-top: 0!important
}

@media only screen and (max-width: 768px) {
    .card-hover .card-hover-img .social-med .social-bullet:hover i,.title {
        /* font-size:1.45rem!important */
    }
}

.sub__title {
    text-transform: capitalize;
    /* font-size: 4.25rem!important */
}

@media only screen and (max-width: 768px) {
    .sub__title {
        /* font-size:3rem!important */
    }
}

.text-purple {
    color: #95378e
}

.card__program {
    width: 100%;
    height: 450px;
    border-radius: .5rem
}

.bullet1 {
    top: 50%;
    left: -100px
}

.bullet1,.bullet2 {
    width: 150px;
    height: 150px;
    position: absolute;
    background: linear-gradient(90deg,#95378e,#0287b4);
    border-radius: 99rem
}

.bullet2 {
    top: 8%;
    right: -20px
}

.bullet2 .subbullet2_1 {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 99rem;
    background: #050507
}

.d-inline {
    display: inline!important
}

#copyvalue {
    /* font-size: 1.5rem!important; */
    border: none;
    font-family: Cereal,sans-serif!important
}

.w-215 {
    min-width: 215px
}

.w-230 {
    min-width: 230px
}

.w-245 {
    min-width: 245px
}

.w-100custom {
    max-width: 100px!important
}

.bg-notfound {
    height: 100vh;
    width: 100%
}

.bg-notfound .not_found h2 {
    letter-spacing: 1px;
    /* font-size: 20rem!important; */
    font-weight: "Cereal-Bold",sans-serif;
    position: relative;
    color: #e7eaf3
}

@media only screen and (max-width: 768px) {
    .bg-notfound .not_found h2 {
        /* font-size:15rem!important */
    }
}

.bg-notfound .not_found h2 .bullet {
    content: "";
    width: 100px;
    height: 120px;
    background: #e7eaf3;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 99rem;
    transform: translateX(-50%) translateY(-50%)
}

.card__event {
    color: #000!important;
    border-radius: .5rem!important
}

.card__event .sec__top .program {
    text-transform: uppercase;
    letter-spacing: 1px
}

.card__event .sec__center {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 1px solid rgba(0,0,0,.15);
    border-bottom: 1px solid rgba(0,0,0,.15)
}

.filter-by {
    background: #fff;
    padding: 1rem 1rem .5rem 2rem;
    position: relative
}

.filter-by.maxwidth {
    max-width: 250px!important
}

.filter-by .text-filter {
    margin-right: 4rem!important;
    text-transform: capitalize
}

.filter-by .text-filter::-moz-placeholder {
    color: rgba(0,0,0,.6)!important
}

.filter-by .text-filter:-ms-input-placeholder {
    color: rgba(0,0,0,.6)!important
}

.filter-by .text-filter::placeholder {
    color: rgba(0,0,0,.6)!important
}

.filter-by .icon-filter {
    margin-right: 1rem
}

.filter-by .icon-filter i {
    /* font-size: 2rem!important */
}

.filter-by.nopadding {
    padding: 0!important
}

.filter-by.max-full {
    max-width: 400px!important
}

.filter-by:first-child {
    border-top-left-radius: 99rem;
    border-bottom-left-radius: 99rem
}

.filter-by:last-child {
    border-top-right-radius: 99rem;
    border-bottom-right-radius: 99rem
}

.filter-by label {
    color: #000!important;
    text-shadow: none!important;
    text-align: left;
    opacity: .5
}

@media only screen and (max-width: 768px) {
    .filter-by label {
        /* font-size:1.5rem!important */
    }
}

.filter-by .v-select,.filter-by select {
    padding: 1rem 2rem 1rem 0!important;
    border: none
}

@media only screen and (max-width: 768px) {
    .filter-by .v-select,.filter-by select {
        /* font-size:1.5rem!important */
    }
}

.filter-by .fc-notborder {
    min-width: 40%!important;
    max-width: 47%!important;
    margin-top: .75rem;
    border: 0!important;
    background: transparent!important
}

.filter-by .fc-divider {
    padding-right: 6%
}

.filter-by .fc-notborder:focus {
    box-shadow: none
}

.filter-by .v-select {
    border: none!important
}

.filter-by i {
    color: #000!important
}

.filter-by b-form-select {
    border: none!important
}

.filter-by .form-control.no-border {
    border: none;
    height: 35px!important;
    outline: none!important;
    background: transparent!important
}

.filter-by .form-control.no-border:focus {
    border: none;
    box-shadow: none!important
}

.filter-by .form-control.border-grey {
    border: 1px solid #dee2e6
}

.filter-by .popup-select {
    display: none
}

.filter-by .popup-select.active {
    display: inherit;
    padding: 1rem;
    position: absolute;
    z-index: 999!important;
    top: 85px;
    left: 20px;
    background: #fff;
    color: #000;
    border-radius: 1rem;
    width: 125%;
    max-height: 225px;
    overflow: auto
}

.filter-by .popup-select.active .list {
    border-bottom: 1px solid rgba(0,0,0,.2);
    width: 100%!important;
    padding: 1rem .5rem
}

.filter-by .popup-select.active .list:last-child {
    border: none;
    margin: 0
}

.filter-by .popup-select.active .list:hover {
    cursor: pointer!important;
    background: #e5e5e5
}

.filter-by .popup-select.active .popup-child {
    position: relative!important
}

.filter-by .popup-select.active .popup-child .icon-close {
    width: 35px;
    height: 35px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    border-radius: 99rem;
    position: absolute;
    top: -15px;
    left: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.filter-by .popup-select.active .popup-child .icon-close i {
    color: #fff!important;
    /* font-size: 1.5rem */
}

.popup-select {
    display: none;
    transition: all .25s ease-in-out
}

.popup-select.active {
    display: inherit;
    padding: 1rem;
    position: absolute;
    z-index: 999!important;
    top: 85px;
    left: 20px;
    background: #fff;
    color: #000;
    border-radius: 1rem;
    width: 125%;
    max-height: 225px;
    overflow: auto
}

.popup-select.active.bottom {
    top: 45px;
    left: -50%;
    transform: translateX(-55%);
    width: 300px;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 3px 7px rgba(0,0,0,.1);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box
}

.popup-select.active .list {
    border-bottom: 1px solid rgba(0,0,0,.2);
    width: 100%!important;
    padding: 1rem .5rem
}

.popup-select.active .list:last-child {
    border: none;
    margin: 0
}

.popup-select.active .list:hover {
    cursor: pointer!important;
    background: #e5e5e5
}

.popup-select.active .popup-child {
    position: relative!important
}

.popup-select.active .popup-child .icon-close {
    width: 35px;
    height: 35px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    border-radius: 99rem;
    position: absolute;
    top: -15px;
    left: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.popup-select.active .popup-child .icon-close i {
    color: #fff!important;
    /* font-size: 1.5rem */
}

.no-border {
    border: 0!important
}

.no-border,.no-border:hover {
    background: transparent!important
}

.icn {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 30px;
    right: 20px;
    z-index: 2;
    background: linear-gradient(90deg,#95378e,#0287b4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99rem
}

.icn i {
    color: #fff;
    /* font-size: 1.75rem */
}

.icn.history {
    top: -15px;
    right: -15px
}

.icn-left {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 30px;
    left: 20px;
    z-index: 2;
    background: linear-gradient(90deg,#95378e,#0287b4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99rem
}

.icn-left i {
    color: #fff;
    /* font-size: 1.75rem */
}

.icn-left.history {
    top: -15px;
    left: -15px
}

.header-black .navbar-nav .nav-item .router-link-exact-active,.relative {
    position: relative
}

.header-black .navbar-nav .nav-item .router-link-exact-active:before {
    content: "";
    position: absolute;
    bottom: -5px;
    border-radius: 99rem;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background: #95378e
}

.header-black .navbar-nav .nav-item .nav-link:hover {
    color: #f7af2f!important
}

.header-white .navbar-nav .nav-item .router-link-exact-active {
    position: relative
}

.header-white .navbar-nav .nav-item .router-link-exact-active:before {
    content: "";
    position: absolute;
    bottom: -5px;
    border-radius: 99rem;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background: #fff
}

.header-white .navbar-nav .nav-item .nav-link:hover {
    color: #f7af2f!important
}

.link .group-link .text-link .router-link-exact-active,.menu_list .link .group-link .text-link .router-link-exact-active {
    color: #95378e!important;
    /* font-family: sans-serif */
}

.mx-datepicker {
    width: 200px!important
}

.bg-why .owl-carousel .owl-stage-outer .owl-stage>:first-child,.parent_services .row>:first-child {
    transform: translateY(100px)!important
}

@media only screen and (max-width: 768px) {
    .bg-why .owl-carousel .owl-stage-outer .owl-stage>:first-child,.parent_services .row>:first-child {
        transform:none!important
    }
}

.bg-why .owl-carousel .owl-stage-outer .owl-stage>:first-child .services .icon,.parent_services .row>:first-child .services .icon {
    background: #f7af2f
}

.bg-why .owl-carousel .owl-stage-outer .owl-stage>:nth-child(2),.parent_services .row>:nth-child(2) {
    transform: translateX(150px) translateY(70px)!important
}

@media only screen and (max-width: 768px) {
    .bg-why .owl-carousel .owl-stage-outer .owl-stage>:nth-child(2),.parent_services .row>:nth-child(2) {
        transform:none!important
    }
}

.bg-why .owl-carousel .owl-stage-outer .owl-stage>:nth-child(2) .services .icon,.parent_services .row>:nth-child(2) .services .icon {
    background: #95378e
}

.bg-why .owl-carousel .owl-stage-outer .owl-stage>:nth-child(3),.parent_services .row>:nth-child(3) {
    transform: translateX(265px) translateY(100px)!important
}

@media only screen and (max-width: 768px) {
    .bg-why .owl-carousel .owl-stage-outer .owl-stage>:nth-child(3),.parent_services .row>:nth-child(3) {
        transform:none!important
    }
}

.bg-why .owl-carousel .owl-stage-outer .owl-stage>:nth-child(3) .services .icon,.parent_services .row>:nth-child(3) .services .icon {
    background: #25a550
}

.services {
    flex-direction: column
}

.services,.services .icon {
    display: flex;
    align-items: center
}

.services .icon {
    width: 125px;
    height: 125px;
    border-radius: 99rem;
    margin-bottom: 2rem;
    justify-content: center
}

.services .icon img {
    color: #fff;
    width: 65px;
    z-index: 8
}

@media only screen and (max-width: 768px) {
    .services .icon img {
        width:65px!important
    }
}

.services .text .title_serv {
    color: #000!important;
    /* font-size: 2rem!important; */
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .services .text .title_serv {
        /* font-size:1.75rem!important */
    }
}

.services .text .desc {
    /* font-family: Cereal-Light,sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .row-mob {
        transform:translateY(50px)!important
    }
}

#testimonials {
    overflow: hidden
}

#partner .owl-stage,#why .owl-stage,.mobile-reviews .owl-stage,.mobile-runevent .owl-stage,.mobile-testimonials .owl-stage {
    display: flex!important
}

#gallery .owl-stage {
    justify-content: center
}

.card_gallery {
    margin: 1rem 0;
    background: #dee2e6;
    padding-bottom: 1rem;
    border-radius: .5rem;
    cursor: pointer
}

.card_gallery img {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.card_gallery p {
    margin: 1rem 0!important
}

@media only screen and (max-width: 768px) {
    .card_gallery p {
        /* font-size:1.35rem!important; */
        padding: .25rem
    }
}

.desktop-runevent .owl-stage {
    display: flex!important
}

.owl-stage {
    padding-left: 0!important
}

@media only screen and (max-width: 768px) {
    .owl-stage {
        padding-left:auto!important
    }
}

.card-event {
    height: 500px!important;
    padding: 3rem;
    position: relative;
    color: #fff;
    width: 100%!important;
    border-radius: 1rem;
    z-index: 0;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: 50%!important
}

.card-event a,.card-event a:active {
    color: #fff
}

.card-event:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    background: #95378e;
    filter: opacity(50%);
    -webkit-clip-path: polygon(0 0,22% 0,49% 100%,0 100%);
    clip-path: polygon(0 0,22% 0,49% 100%,0 100%);
    z-index: -1!important;
    border-radius: 1rem
}

@media only screen and (max-width: 768px) {
    .card-event {
        height:350px!important
    }
}

.card-event.mini {
    height: 400px!important;
    background-size: cover!important;
    background-position: 50%!important
}

.card-event.mini .event-name {
    /* font-size: 2.5rem!important */
}

@media only screen and (max-width: 768px) {
    .card-event.mini .event-name {
        /* font-size:1.25rem!important */
    }
}

.card-event.mini .program .program-name {
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    /* font-size: 1.25rem!important; */
    border-radius: 99rem
}

@media only screen and (max-width: 768px) {
    .card-event.mini .program .program-name {
        /* font-size:1.25rem!important */
    }
}

.card-event.mini .about-event {
    display: flex
}

@media only screen and (max-width: 768px) {
    .card-event.mini .about-event {
        display:block
    }
}

.card-event.mini .about-event .information {
    margin-right: 2rem
}

.card-event.mini .about-event .information i {
    margin-right: 1rem;
    /* font-size: 1.5rem */
}

.card-event.mini .about-event .information h5 {
    /* font-size: 1.5rem!important */
}

@media only screen and (max-width: 768px) {
    .card-event.mini .about-event .information h5 {
        /* font-size:1.25rem!important */
    }
}

.card-event .program .program-name {
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    /* font-size: 1rem!important; */
    border-radius: 99rem
}

@media only screen and (max-width: 768px) {
    .card-event .program .program-name {
        /* font-size:.75rem!important */
    }
}

.card-event .event-name {
    /* font-size: 3.5rem!important; */
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .card-event .event-name {
        /* font-size:2.25rem!important */
    }
}

.card-event .about-event {
    display: flex
}

@media only screen and (max-width: 768px) {
    .card-event .about-event {
        display:block
    }
}

.card-event .about-event .information {
    margin-right: 2rem
}

.card-event .about-event .information i {
    margin-right: 1rem;
    /* font-size: 2rem */
}

.card-event .about-event .information h5 {
    /* font-size: 1.5rem!important */
}

@media only screen and (max-width: 768px) {
    .card-event .about-event .information h5 {
        /* font-size:1.25rem!important */
    }
}

.card-event .next-event {
    position: absolute;
    right: -35px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 75px;
    height: 75px;
    border-radius: 50%
}

.card-event .next-event i {
    /* font-size: 4rem; */
    color: #000
}

.card-event .next-carousel {
    background: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: -20px;
    border-radius: 99rem;
    z-index: 1!important
}

.card-event .next-carousel i {
    /* font-size: 4rem!important; */
    color: #000
}

.color-black {
    color: #000!important
}

.item-connected {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 1rem
}

.item-connected:hover .item-icon {
    background: hsla(0,0%,100%,.1)
}

.item-connected .item-icon {
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: .5rem;
    border-right: 1px solid #fff;
    /* font-size: 2rem; */
    margin-right: 1rem
}

.item-connected span {
    color: #000!important
}

.item-connected span.active:hover {
    color: #95378e
}

.item-connected.facebook .item-icon {
    background: #2265cb
}

.item-connected.facebook .item-icon,.item-connected.twitter .item-icon {
    border-right: 1px solid #fff;
    /* font-size: 2rem; */
    color: #fff;
    margin-right: 1rem
}

.item-connected.twitter .item-icon {
    background: #3fb5ec
}

.item-connected.instagram .item-icon {
    border-right: 1px solid #fff;
    /* font-size: 2rem; */
    color: #fff;
    margin-right: 1rem;
    background: #a9319c
}

.item-connected.pinterest .item-icon {
    background: #f94142
}

.item-connected.pinterest .item-icon,.item-connected.tumblr .item-icon {
    border-right: 1px solid #fff;
    /* font-size: 2rem; */
    color: #fff;
    margin-right: 1rem
}

.item-connected.tumblr .item-icon {
    background: #1d3765
}

.pagination .page-item.disabled span {
    color: #666!important;
    border: 1px solid #dee2ee!important
}

.pointer {
    cursor: pointer!important
}

.articles-normal {
    display: flex;
    min-height: 225px;
    border-bottom: 1px solid #dee2e6
}

@media only screen and (max-width: 768px) {
    .articles-normal {
        display:block;
        margin-bottom: 2rem;
        border-bottom: 1px solid #a7a7a7;
        padding-bottom: 1rem
    }

    .articles-normal .img {
        width: 100%!important
    }

    .articles-normal .img,.articles-normal .img img {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0!important
    }

    .articles-normal .content {
        padding: 1rem 0
    }
}

.articles-normal .img {
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 200px;
    min-height: 200px;
    background: #fff;
    margin-right: 1rem!important;
    border-radius: .75rem
}

.articles-normal .img img {
    border-radius: .75rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.articles-normal .pointer {
    cursor: pointer!important
}

.articles-normal .content-desc {
    margin: 0 0 1rem 0!important
}

.articles-normal .content-desc .type {
    color: #25a550
}

.articles-normal .content-desc .category,.articles-normal .content-desc .type {
    background: transparent!important;
    /* font-size: 1.35rem!important; */
    border-radius: 99rem;
    font-family: Cereal,sans-serif
}

.articles-normal .content-desc .category {
    color: #95378e
}

.articles-normal .content-desc .title-articles {
    margin-top: 1rem;
    color: #000!important;
    /* font-size: 2.25rem!important */
}

@media only screen and (max-width: 768px) {
    .articles-normal .content-desc .title-articles {
        /* font-size:1.75rem!important */
    }
}

.articles-normal .content-desc .title-articles p,.articles-normal .content-desc .title-articles span {
    /* font-size: 2.25rem!important */
}

.articles-normal .content-desc .desc {
    color: rgba(0,0,0,.75)!important
}

.articles-normal .foot span {
    /* font-family: Cereal-Light,sans-serif; */
    /* font-size: 1.35rem!important; */
    color: #a7a7a7
}

.articles-normal .foot span:hover {
    color: #f7af2f
}

.img-cover {
    position: relative;
    top: 0!important;
    left: 0!important;
    height: 150px;
    background: #fff;
    margin-right: 1rem!important
}

.img-cover,.img-cover img {
    width: 100%;
    border-radius: .75rem
}

.img-cover img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.alert i {
    /* font-size: 2rem!important */
}

.alert a {
    color: #95378e!important
}

.image-gall {
    position: relative;
    width: 100%;
    height: 400px;
    background: #000
}

.image-gall img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover!important;
    object-fit: cover!important
}

.border-05rem {
    border: 1px solid #eee;
    padding: .5rem;
    border-radius: .5rem
}

@media only screen and (max-width: 768px) {
    .filter-category {
        width:100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0!important;
        margin-bottom: 1rem
    }
}

@media only screen and (min-width: 768px) {
    .filter-category {
        overflow-x:auto;
        overflow-y: hidden;
        white-space: nowrap;
        width: 520px;
        padding-right: 3rem!important;
        margin: .5rem 0!important
    }
}

.filter-category .transparent-bg {
    background: linear-gradient(270deg,#fff 30%,hsla(0,0%,100%,.1));
    background: -webkit-gradient(to left,#fff 30%,hsla(0,0%,100%,.1) 100%);
    background: -ms-linear-gradient(to left,#fff 30%,hsla(0,0%,100%,.1) 100%);
    z-index: 10;
    position: absolute;
    width: 80px;
    min-height: 47px;
    top: 0;
    right: -10px
}

.filter-category a {
    display: inline-block;
    border: 1px solid rgba(0,0,0,.1);
    padding: .25rem 1rem;
    border-radius: 99rem;
    /* font-family: Cereal-Light,sans-serif; */
    color: #95378e;
    margin-bottom: .75rem;
    margin-right: .5rem!important
}

.filter-category a:hover {
    background: rgba(0,0,0,.1);
    color: #95378e
}

.filter-category a.router-link-active.router-link-exact-active {
    background: #95378e;
    color: #fff
}

.heading-newsarticles {
    position: relative;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 1rem
}

.heading-newsarticles:before {
    content: "";
    position: absolute;
    width: 200px;
    height: 2px;
    background: #f7af2f;
    bottom: -1px;
    left: 0
}

.heading-newsarticles h3 {
    position: relative;
    padding-bottom: 1rem
}

.padding-1 {
    padding-right: .65rem!important;
    padding-left: .65rem!important;
    padding-bottom: 0!important
}

.wrapper-newsarticles .card-article {
    height: 415px!important
}

.wrapper-newsarticles .card-article.mini {
    padding: 2rem 2rem;
    height: 200px!important
}

@media only screen and (max-width: 768px) {
    .wrapper-newsarticles .card-article.mini {
        height:250px!important
    }
}

.card-bg-main {
    background: #f8edf7;
    color: #5f1f5b
}

.card-bg-grey,.card-bg-main {
    border-radius: 1rem;
    padding: 1rem
}

.card-bg-grey {
    background: #f2f2f2
}

.mr1-important {
    margin-right: 1rem!important
}

.card-article {
    height: 523px!important;
    padding: 3rem;
    position: relative;
    color: #fff;
    border-radius: 1rem;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: 50%!important
}

@media only screen and (max-width: 768px) {
    .card-article {
        height:400px!important
    }
}

.card-article .about-event {
    display: flex
}

@media only screen and (max-width: 768px) {
    .card-article .about-event {
        display:block
    }
}

.card-article.mini {
    padding: 2rem 2rem;
    height: 300px!important
}

@media only screen and (max-width: 768px) {
    .card-article.mini {
        width:100%!important;
        height: 300px!important;
        margin-bottom: 1.25rem
    }
}

.card-article.mini .category {
    display: flex;
    flex-wrap: wrap
}

.card-article.mini .category .category-name {
    padding: .5rem .75rem;
    border-radius: 1rem;
    /* font-size: 1.25rem!important; */
    margin-right: .5rem;
    border: 1px solid #fff
}

@media only screen and (max-width: 768px) {
    .card-article.mini .category .category-name {
        /* font-size:1.25rem!important */
    }
}

.card-article.mini .article-title {
    /* font-size: 1.75rem!important; */
    border-radius: 1rem;
    /* font-family: sans-serif!important */
}

.card-article.mini .article-title p,.card-article.mini .article-title span {
    /* font-size: 1.75rem!important */
}

@media only screen and (max-width: 768px) {
    .card-article.mini .article-title p,.card-article.mini .article-title span {
        /* font-size:1.75rem!important */
    }
}

@media only screen and (max-width: 768px) {
    .card-article.mini .article-title {
        /* font-size:1.65rem!important */
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .card-article.mini .article-title,.card-article.mini .article-title p,.card-article.mini .article-title span {
        /* font-size:2.25rem!important */
    }
}

.card-article.mini .about-event {
    display: flex;
    flex-direction: column
}

@media only screen and (max-width: 768px) {
    .card-article.mini .about-event {
        display:block
    }
}

.card-article.mini .about-event .information {
    margin-right: 2rem
}

.card-article.mini .about-event .information i {
    margin-right: 1rem;
    /* font-size: 1.5rem */
}

@media only screen and (max-width: 768px) {
    .card-article.mini .about-event .information i {
        /* font-size:1.75rem!important */
    }
}

.card-article.mini .about-event .information h5 {
    /* font-size: 1.25rem!important */
}

@media only screen and (max-width: 768px) {
    .card-article.mini .about-event .information h5 {
        /* font-size:1.25rem!important */
    }
}

.card-article .category {
    display: flex;
    flex-wrap: wrap
}

.card-article .category .category-name {
    border: 1px solid #fff;
    padding: .5rem .75rem;
    border-radius: 1rem;
    /* font-size: 1.25rem!important; */
    margin-right: .5rem
}

@media only screen and (max-width: 768px) {
    .card-article .category .category-name {
        /* font-size:1.25rem!important */
    }
}

.card-article .category .category-name.featured {
    background: #0287b4!important
}

.card-article .article-title {
    padding-top: 1rem;
    padding-bottom: .25rem;
    /* font-size: 2.5rem!important; */
    border-radius: 1rem;
    /* font-family: sans-serif!important */
}

@media only screen and (max-width: 768px) {
    .card-article .article-title,.card-article .article-title p {
        /* font-size:1.75rem!important */
    }
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .card-article .article-title,.card-article .article-title p,.card-article .article-title span {
        /* font-size:2.25rem!important */
    }
}

.card-article.featured .article .article-title p {
    /* font-size: 2.5rem!important */
}

@media only screen and (max-width: 768px) {
    .card-article.featured .article .article-title p {
        /* font-size:1.65rem!important */
    }
}

.card-article .about-event .information {
    margin-right: 2rem
}

.card-article .about-event .information i {
    margin-right: 1rem;
    /* font-size: 2rem */
}

.card-article .about-event .information h5 {
    /* font-size: 1.15rem!important */
}

.EventDetail {
    overflow: hidden!important;
    position: relative
}

.nav-tabs .nav-link.active {
    border-color: #a89999!important
}

.nav-tabs {
    border: 1px solid rgba(0,0,0,.1)!important;
    border-radius: 1rem
}

@media screen and (max-width: 768px) {
    .nav-tabs {
        border-radius:0
    }
}

.nav-tabs .nav-item {
    min-width: 20px!important;
    border-right: 1px solid rgba(0,0,0,.1)!important
}

.nav-tabs .nav-item:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-right: none!important
}

.nav-tabs .nav-item:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.nav-tabs .nav-item .nav-link {
    padding: 1.25rem 0!important;
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000!important;
    text-transform: uppercase
}

@media screen and (max-width: 768px) {
    .nav-tabs .nav-item .nav-link {
        /* font-size:1.25rem!important; */
        min-height: 45px!important
    }
}

.nav-tabs .nav-item .nav-link:hover {
    color: hsla(0,0%,100%,.5)
}

.nav-tabs .nav-item .nav-link.active {
    background: #95378e;
    color: #fff!important
}

.without-height .tabs {
    min-height: 30vh!important
}

.border-bottom-sec {
    border-bottom: 1px solid rgba(0,0,0,.1)
}

#box-angle {
    width: 12rem;
    height: 13.5rem!important;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
    white-space: normal
}

#box-angle .ext {
    text-transform: uppercase;
    letter-spacing: 1px
}

#box-angle .file-name {
    width: 80%;
    text-align: center;
    word-wrap: break-word
}

#box-angle .file-name span {
    /* font-size: 1rem!important */
}

#box-angle.xls:after,#box-angle.xlsx:after {
    border-radius: .4em;
    border-width: 1.35em;
    border-right-color: #25a550;
    border-top-color: #25a550
}

#box-angle.pdf:after {
    border-radius: .4em;
    border-width: 1.35em;
    border-right-color: #95378e;
    border-top-color: #95378e
}

#box-angle.doc:after,#box-angle.docx:after {
    border-radius: .4em;
    border-width: 1.35em;
    border-right-color: #0287b4;
    border-top-color: #0287b4
}

#box-angle:after,#box-angle:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid
}

#box-angle:before {
    border-width: 1.5em;
    border-right-color: #fff;
    border-top-color: #fff
}

#box-angle:after {
    border-radius: .4em;
    border-width: 1.35em;
    border-right-color: #0c0;
    border-top-color: #0c0
}

.transparent-bg {
    background: linear-gradient(270deg,#fff 30%,hsla(0,0%,100%,.1));
    background: -webkit-gradient(to left,#fff 30%,hsla(0,0%,100%,.1) 100%);
    background: -ms-linear-gradient(to left,#fff 30%,hsla(0,0%,100%,.1) 100%);
    z-index: 10;
    position: absolute;
    width: 80px;
    min-height: 47px;
    top: 0;
    right: 0
}

.tabs {
    overflow: hidden!important;
    min-height: 100vh!important
}

@media screen and (max-width: 768px) {
    .tabs {
        min-height:50vh!important
    }
}

.tabs.no-padding .tab-content {
    width: 100%!important
}

.tabs .nav {
    flex-wrap: inherit!important;
    overflow: hidden
}

@media screen and (max-width: 768px) {
    .tabs .nav.nav-tabs {
        width:100%;
        overflow-x: auto!important
    }
}

@media screen and (max-width: 768px) {
    .tabs .nav.nav-tabs .nav-item {
        min-width:175px!important
    }

    .tabs .nav.nav-tabs .nav-item .nav-lnk {
        /* font-size: 1rem!important */
    }
}

@media screen and (max-width: 768px) {
    .tabs .tab-content {
        width:90%;
        margin: auto
    }
}

.tabs .tab-content p span {
    /* font-size: 1.75rem!important */
}

.tabs .tab-content details p span {
    /* font-size: 1.25rem!important */
}

.tabs .tab-content .content-tab {
    border-top: 1px solid rgba(0,0,0,.2);
    padding: 2rem 0!important;
    display: flex;
    align-items: flex-start
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab {
        display:block
    }
}

.tabs .tab-content .content-tab.b-none {
    border-top: none;
    padding: 1rem 0!important
}

.tabs .tab-content .content-tab img {
    width: 100%
}

.tabs .tab-content .content-tab .content-ck span {
    /* font-size: 1.5rem */
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab .content-ck span {
        /* font-size:1.5rem!important */
    }
}

.tabs .tab-content .content-tab p:first-child {
    min-width: 300px
}

.tabs .tab-content .content-tab p:first-child span {
    /* font-size: 3rem */
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab p:first-child span {
        /* font-size:2rem!important */
    }
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab p:first-child {
        width:100%!important
    }
}

.tabs .tab-content .content-tab .content-ck p span {
    /* font-size: 1.5rem!important */
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab .content-ck p span {
        /* font-size:1.5rem!important */
    }
}

.tabs .tab-content .content-tab.partner {
    flex-direction: column!important
}

.tabs .tab-content .content-tab.partner img {
    width: 200px
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab.partner img {
        width:200px!important
    }
}

.tabs .tab-content .content-tab .img-landmark {
    width: 50%
}

@media screen and (max-width: 768px) {
    .tabs .tab-content .content-tab .img-landmark {
        width:100%
    }
}

.tabs .content {
    margin: 3rem 0
}

.title-important {
    color: #95378e!important
}

.item-event {
    padding: 2rem 0
}

.item-event.b-none {
    border-bottom: none!important
}

.item-event i,.item-event span {
    /* font-size: 1.5rem!important; */
    color: #495057
}

.item-event i {
    /* font-size: 1.75rem!important; */
    margin-right: 1rem!important;
    color: #95378e!important
}

.item-event span {
    /* font-size: 1.25rem!important */
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .item-event span {
        /* font-size:1.5rem!important */
    }
}

.item-event.excluded span {
    /* font-size: 1.35rem!important */
}

.breadcrumb-item+.breadcrumb-item:before {
    color: #fff!important
}

.modal-header {
    width: 100%!important
}

@media screen and (max-width: 768px) {
    .modal-header {
        width:100%!important
    }
}

.input-relative {
    position: relative
}

.input-relative .clean {
    position: absolute;
    /* font-size: 2rem; */
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer
}

#modal-guest a {
    color: #95378e!important
}

#modal-guest .main-button {
    color: #fff!important
}

a.main-button:hover {
    color: #fff
}

.form-control {
    height: 40px!important;
    /* font-size: 1.45rem!important; */
    border-radius: .75rem!important
}

@media only screen and (max-width: 768px) {
    .form-control {
        /* font-size:1.5rem!important */
    }
}

.form-control.text-area {
    border-radius: .5rem!important;
    height: 100px!important
}

.mobile-datepicker {
    border: none!important
}

.b-calendar-header .form-control {
    display: none
}

.b-calendar .b-calendar-grid-caption {
    background: #fff;
    transform: translateY(-70px) translateX(-5px)
}

.width-desktop {
    width: 800px!important
}

.badge {
    color: #000;
    border-radius: 1rem;
    /* font-size: 1.25rem!important */
}

.badge.small {
    padding: .5rem 1rem!important;
    border-radius: .5rem
}

.badge.red {
    background: #fee7e9;
    color: #dc0a24
}

.badge.green {
    background: #eafaf0;
    color: #25a550
}

.badge.yellow {
    background: #fef5e6;
    color: #f7af2f
}

.badge.blue {
    background: #e6f8ff;
    color: #0287b4
}

.badge a {
    color: #fff;
    text-decoration: underline!important
}

.badge a:hover {
    color: hsla(0,0%,100%,.9)
}

.info-payment {
    color: #000;
    padding: .5rem .75rem;
    border-radius: .75rem
}

.info-payment.small {
    padding: .5rem 1rem!important;
    border-radius: .5rem
}

.info-payment.red {
    background: #fee7e9;
    color: #dc0a24
}

.info-payment.green {
    background: #eafaf0;
    color: #25a550
}

.info-payment.yellow {
    background: #fef5e6;
    color: #f7af2f
}

.info-payment.blue {
    background: #e6f8ff;
    color: #0287b4
}

.info-payment a {
    color: #fff;
    text-decoration: underline!important
}

.info-payment a:hover {
    color: hsla(0,0%,100%,.9)
}

.max-width120px {
    max-width: 120px
}

.copy_button {
    background: #fff!important;
    color: #f7af2f!important;
    padding: 0!important;
    border: none!important;
    outline: 0!important;
    margin-left: 1rem;
    margin-top: -3px!important
}

.copy_button:focus {
    border: 0!important;
    outline: 0!important
}

.owl-nav {
    display: none
}

.owl-dots {
    margin-top: 2rem!important
}

.v-step-item {
    margin: 3rem 0!important
}

.mx-input {
    border: 0!important;
    box-shadow: none!important;
    color: #000!important
}

.mx-input::-moz-placeholder {
    color: #000
}

.mx-input:-ms-input-placeholder {
    color: #000
}

.mx-input::placeholder {
    color: #000
}

@media only screen and (max-width: 768px) {
    .mx-input::-moz-placeholder {
        /* font-size:1.5rem!important */
    }

    .mx-input:-ms-input-placeholder {
        /* font-size: 1.5rem!important */
    }

    .mx-input::placeholder {
        /* font-size: 1.5rem!important */
    }
}

.mx-datepicker {
    width: 70%
}

.summary-payment {
    border: 1px solid rgba(0,0,0,.2);
    padding: 1rem;
    border-radius: 1.5rem
}

.info-login {
    background: #f2f2f2;
    padding: .5rem 1rem;
    margin-bottom: 2rem
}

.info-login,.info-login .icon {
    display: flex;
    align-items: center;
    border-radius: 99rem
}

.info-login .icon {
    width: 42px;
    height: 42px;
    background: linear-gradient(90deg,#95378e,#0287b4);
    justify-content: center;
    margin-right: 1rem
}

.info-login .icon i {
    /* font-size: 2rem!important; */
    color: #fff
}

.steps {
    list-style: none;
    padding: 0
}

.steps li {
    display: flex;
    color: #333
}

.steps.payment h5,.steps h5 {
    /* font-size: 1.5rem!important */
}

.steps h5 {
    position: relative;
    width: 175px;
    padding: 0 1.5rem;
    color: #000
}

.steps h5:after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ccc;
    width: 2rem;
    height: 2rem
}

.steps .span-before {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative;
    width: 100%!important;
    /* font-size: 1.25rem */
}

.steps .span-before:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px solid #ccc
}

.steps p strong {
    display: inline
}

.steps p strong,.steps strong {
    font-weight: "Cereal-Bold",sans-serif!important
}

.steps strong {
    display: block
}

@media only screen and (max-width: 768px) {
    .steps strong {
        /* font-size:1.5rem!important; */
        margin-bottom: .5rem
    }
}

.steps {
    margin: 1em;
    width: 100%
}

.visibility-hidden {
    visibility: hidden
}

.modal-header {
    position: relative!important;
    width: 100%
}

#modal-refund .desc {
    color: rgba(0,0,0,.5)
}

.filter-sec {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem;
    position: sticky;
    padding: .75rem 1rem 2rem 1rem!important;
    top: 100px
}

.filter-sec .filter-by {
    padding-left: 0!important;
    padding-right: 0!important
}

.filter-sec .submit>div {
    margin-right: .75rem!important
}

.filter-tags a {
    color: #000
}

.filter-tags .item-tag {
    margin: 0 1rem
}

.filter-tags .item-tag.active {
    border-bottom: 2px solid #95378e
}

.filter-tags .item-tag p {
    /* font-size: 1.35rem!important */
}

#wrapper {
    margin: 5rem 0
}

.card-review {
    border: 1px solid rgba(0,0,0,.1);
    margin: 2rem 0;
    border-radius: 1rem
}

.card-review.mini {
    border-bottom: none!important
}

.card-review .review-product {
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

@media only screen and (max-width: 768px) {
    .card-review .review-product {
        flex-direction:column
    }
}

.card-review .review-product .left-sec {
    padding: 1rem
}

@media only screen and (max-width: 768px) {
    .card-review .review-product .left-sec {
        display:flex;
        justify-content: center
    }
}

.card-review .review-product .left-sec .img {
    position: relative;
    width: 225px;
    height: 200px;
    background: #fff;
    border-radius: 1rem;
    margin-right: 1.5rem!important
}

.card-review .review-product .left-sec .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: unset;
    object-fit: unset;
    border-radius: 1rem
}

.card-review .review-product .right-sec {
    padding: 1rem
}

.card-review .review-product .right-sec .stars {
    display: flex;
    align-items: center;
    margin-bottom: 1rem
}

.card-review .review-product .right-sec .stars * {
    margin-right: 1rem
}

.card-review .review-product .right-sec .stars .rate i {
    /* font-size: 2rem */
}

.card-review .review-product .right-sec .stars .rate i.active {
    color: #f7af2f
}

.card-review .review-product .right-sec .stars .rate i.inactive {
    color: rgba(0,0,0,.2)
}

.card-review .review-product .right-sec .stars span {
    /* font-size: 1.24rem */
}

.card-review .review-product .right-sec .brand,.card-review .review-product .right-sec .type {
    /* font-size: 1.25rem!important */
}

.card-review .review-product .right-sec .type {
    /* font-size: 1.5rem!important */
}

.card-review .user-profile {
    padding: 1rem;
    display: flex
}

.card-review .user-profile .left-sec .img {
    position: relative;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 99rem;
    margin-right: 1.5rem!important
}

.card-review .user-profile .left-sec .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem
}

.card-review .user-profile .right-sec .name-user {
    text-transform: capitalize
}

.card-review .user-profile-mini {
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 1rem;
    display: flex;
    margin-bottom: 1rem
}

.card-review .user-profile-mini .left-sec .img {
    position: relative;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 99rem
}

.card-review .user-profile-mini .left-sec .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem
}

.card-review .user-profile-mini .right-sec {
    margin-left: -20px
}

.card-review .user-profile-mini .right-sec .name-user {
    text-transform: capitalize
}

.card-review.product {
    padding: 1rem
}

.card-review.product .review-product {
    flex-direction: column!important;
    border: none
}

@media only screen and (max-width: 768px) {
    .card-review.product .left-sec {
        display:flex;
        justify-content: center
    }
}

.card-review.product .left-sec .img {
    position: relative;
    width: 100%;
    height: 175px;
    background: #fff;
    border-radius: 1rem;
    margin-right: 1.5rem!important
}

@media only screen and (max-width: 768px) {
    .card-review.product .left-sec .img {
        width:100%;
        height: 200px
    }
}

.card-review.product .left-sec .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: unset;
    object-fit: unset;
    border-radius: 1rem
}

.card-review.product .right-sec .type {
    /* font-size: 1.25rem!important */
}

.card-review.product.mini {
    display: flex;
    flex-direction: row!important
}

.search {
    padding: 0 .75rem!important;
    color: #000!important
}

.search::-moz-placeholder {
    color: rgba(0,0,0,.3)!important
}

.search:-ms-input-placeholder {
    color: rgba(0,0,0,.3)!important
}

.search::placeholder {
    color: rgba(0,0,0,.3)!important
}

.button-search {
    padding: .75rem 1rem;
    border-radius: 99rem;
    border: none;
    border: 1px solid linear-gradient(90deg,#dc0a24,#95378e)
}

textarea {
    height: 200px!important;
    width: 100%;
    border-radius: 1rem;
    border: none;
    outline: none;
    border: 1px solid rgba(0,0,0,.1)!important;
    /* font-size: 1.25rem!important; */
    padding: 1rem!important
}

.wrapper-not-banner {
    margin: 12rem 0!important
}

@media only screen and (max-width: 768px) {
    .wrapper-not-banner {
        margin:0!important
    }
}

.wrapper-not-banner.wrapper-private {
    margin: 6rem 0!important
}

@media only screen and (max-width: 768px) {
    .wrapper-not-banner.wrapper-private {
        overflow:hidden;
        margin: 0!important
    }
}

.wrapper-not-banner.transaction {
    margin: 0 0 10rem 0!important
}

.wrapper-notbanner {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.title-notbanner {
    text-transform: capitalize;
    /* font-size: 3.35rem!important */
}

@media only screen and (max-width: 768px) {
    .title-notbanner {
        /* font-size:2.75rem!important */
    }
}

.title-notbanner p {
    /* font-size: 3.25rem!important; */
    margin: 0!important;
    /* font-family: sans-serif */
}

@media only screen and (max-width: 768px) {
    .title-notbanner p {
        /* font-size:2.25rem!important */
    }
}

.breadcrumb-notbanner .breadcrumb-item {
    /* font-size: 1.15rem!important */
}

.breadcrumb-notbanner .breadcrumb-item a {
    /* font-size: 1.15rem!important; */
    color: #95378e
}

.search__container {
    margin: 0 1rem
}

.search__title {
    /* font-size: 22px; */
    text-align: center;
    color: #ff8b88
}

.search__input {
    width: 100%;
    padding: .75rem 24px;
    transition: transform .25s ease-in-out;
    /* font-size: 14px; */
    line-height: 18px;
    color: #575756;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95%;
    border-radius: 50px;
    border: 1px solid #e5e5e5;
    transition: all .25s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d
}

.search__input::-moz-placeholder {
    color: color(#575756 a(.8));
    text-transform: uppercase;
    letter-spacing: 1.5px;
    width: 85%
}

.search__input:-ms-input-placeholder {
    color: color(#575756 a(.8));
    text-transform: uppercase;
    letter-spacing: 1.5px;
    width: 85%
}

.search__input::placeholder {
    color: color(#575756 a(.8));
    text-transform: uppercase;
    letter-spacing: 1.5px;
    width: 85%
}

.filter-by .custom-select {
    outline: 0!important;
    /* font-size: 1.5rem!important; */
    width: 100%;
    height: 40px;
    padding: 0 1rem;
    position: relative;
    text-indent: 5px;
    /* font-family: Cereal-Light,sans-serif; */
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: 95% 52.5%
}

@media only screen and (max-width: 768px) {
    .filter-by .custom-select {
        height:50px
    }
}

.custom-select {
    /* font-size: 1.35rem!important; */
    background-position: calc(100% - 30px) 14px,calc(100% - 20px) 14px,100% 0
}

.detail-review {
    display: flex
}

.detail-review .item {
    margin-right: 1rem
}

select {
    -webkit-appearance: none!important
}

.select-opt {
    border-radius: 99rem!important
}

.choose {
    padding: .75rem 0!important;
    position: relative;
    box-sizing: border-box;
    color: #000;
    display: flex
}

.choose:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: 99rem
}

.choose .option {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 10px;
    border-radius: 99rem;
    cursor: pointer;
    padding: 1rem 10px;
    border: 2px solid #d3d3d3;
    transition: all .3s ease
}

.choose .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
    margin-right: .75rem!important
}

.choose .option .dot:before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: linear-gradient(90deg,#dc0a24,#95378e);
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all .3s ease
}

.choose input[type=radio] {
    display: none
}

.choose #option-1:checked:checked~.option-1,.choose #option-2:checked:checked~.option-2 {
    border-color: linear-gradient(90deg,#dc0a24,#95378e);
    background: linear-gradient(90deg,#dc0a24,#95378e)
}

.choose #option-1:checked:checked~.option-1 .dot,.choose #option-2:checked:checked~.option-2 .dot {
    background: #fff
}

.choose #option-1:checked:checked~.option-1 .dot:before,.choose #option-2:checked:checked~.option-2 .dot:before {
    opacity: 1;
    transform: scale(1)
}

.choose #option-3:checked:checked~.option-3,.choose #option-4:checked:checked~.option-4 {
    border-color: linear-gradient(90deg,#dc0a24,#95378e);
    background: linear-gradient(90deg,#dc0a24,#95378e)
}

.choose #option-3:checked:checked~.option-3 .dot,.choose #option-4:checked:checked~.option-4 .dot {
    background: #fff
}

.choose #option-3:checked:checked~.option-3 .dot:before,.choose #option-4:checked:checked~.option-4 .dot:before {
    opacity: 1;
    transform: scale(1)
}

.choose .wrapper .option span {
    /* font-size: 1rem; */
    color: grey
}

.choose #option-1:checked:checked~.option-1 span,.choose #option-2:checked:checked~.option-2 span {
    color: #fff
}

.title-letter {
    letter-spacing: .25rem;
    text-transform: uppercase
}

.sec-modal {
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 2rem!important;
    padding-bottom: 1rem
}

.sec-modal .title-sec-modal {
    color: #000!important;
    text-transform: uppercase;
    letter-spacing: 2px
}

.sec-modal .img-modal {
    margin: .75rem 0
}

.background-full {
    background: #f5f5f5;
    padding: 2rem 0 4rem 0
}

.background-full .top-sec .title-background-full {
    color: #000!important;
    font-style: italic
}

.background-full .content-sec {
    margin-top: 2rem
}

.modal-dialog-scrollable #modal-emergency-contact___BV_modal_content_ .modal-body {
    overflow-y: visible!important
}

.modal-dialog-scrollable #modal-emergency-contact___BV_modal_content_.modal-content {
    overflow: visible!important
}

.vs__dropdown-menu {
    z-index: 99!important
}

.tags a {
    border: 1px solid #95378e;
    padding: .25rem 1rem;
    border-radius: 99rem;
    transition: all .25s ease-in-out;
    /* font-family: Cereal-Light,sans-serif; */
    color: #000
}

.tags a:hover {
    border: 1px solid #95378e
}

.tags .tag-link {
    margin-left: 1.25rem
}

.card-articles-v2 {
    height: 250px;
    color: #fff;
    position: relative;
    overflow: visible;
    border-radius: 1rem;
    margin-bottom: 2rem
}

.card-articles-v2 .overlay-title {
    position: absolute;
    bottom: -10px;
    left: 10px;
    width: 100%;
    background: rgba(0,0,0,.6);
    padding: 1rem 1.5rem;
    border-radius: 1rem
}

.card-articles-v2 .overlay-title.mini h5 a {
    /* font-size: 1.75rem!important */
}

.card-articles-v2 h5 a {
    /* font-size: 2rem!important; */
    font-style: italic;
    color: #fff!important
}

.card-articles-v2 h5 a:hover {
    text-decoration: underline!important
}

.card-articles-v2 p {
    position: absolute;
    top: -30px;
    text-transform: uppercase
}

.card-articles-v2 p a {
    color: #fff
}

.mbc-0 {
    margin-bottom: 0!important
}

.contact {
    display: flex
}

.contact i {
    /* font-size: 2rem; */
    margin-right: 1rem
}

.card-partner-sm {
    width: 52.5px;
    height: 52.5px;
    margin: .5rem .5rem .5rem 0!important;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 1rem
}

.card-partner-sm img {
    width: 100%
}

@media only screen and (max-width: 768px) {
    .card-partner-sm {
        width:100%;
        min-height: 70px
    }
}

.card-program {
    min-height: 150px;
    border-radius: 1rem;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    background-position: 50%!important;
    background-size: cover!important;
    background-repeat: no-repeat!important
}

.card-program .program {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px
}

.card-program .desc {
    color: #fff
}

.text-gray-2 {
    color: rgba(0,0,0,.2)!important
}

.text-main {
    color: #2b108e;
    cursor: pointer
}

.text-main:hover {
    color: #95378e
}

.heading-search span *,.heading-search span p {
    margin-bottom: .5rem
}

.text-black {
    color: #000!important
}

.vs__dropdown-toggle {
    border-radius: 99rem!important;
    height: 40px!important;
    padding-left: .5rem!important
}

.vs__search {
    /* font-size: 1.45rem!important */
}

.text-c-small {
    padding: .25rem 1rem;
    border-radius: 99rem;
    color: #fff
}

.bg-green {
    background: #25a550
}

@media only screen and (max-width: 768px) {
    .form-sec span {
        /* font-size:1.25rem!important */
    }
}

.page-title {
    display: flex
}

.page-title .page-title-icon {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99rem;
    background: linear-gradient(90deg,#dc0a24,#95378e)
}

@media only screen and (max-width: 768px) {
    .page-title .page-title-icon {
        width:50px;
        height: 50px
    }
}

.page-title .page-title-icon i {
    /* font-size: 3rem; */
    color: #fff
}

@media only screen and (max-width: 768px) {
    .page-title .page-title-icon i {
        /* font-size:2rem */
    }
}

.page-title .content {
    width: calc(100% - 60px)!important
}

.btn.disabled {
    opacity: 1!important
}

.pad-sec {
    padding: 30px 0;
    margin: 30px 0
}

.rad-label {
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 14px 0;
    margin: 10px 0;
    cursor: pointer;
    transition: .3s
}

.rad-label:focus-within,.rad-label:hover {
    background: hsla(0,0%,80%,.14)
}

.rad-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1
}

.rad-design {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background: linear-gradient(to right bottom,#40fcab,#406ffc);
    position: relative
}

.rad-design:before {
    content: "";
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: #e6e6e6;
    transform: scale(1.1);
    transition: .3s
}

.rad-input:checked+.rad-design:before {
    transform: scale(0)
}

.rad-text {
    margin-left: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    /* font-size: 1rem; */
    transition: .3s
}

.rad-text i {
    /* font-size: 1.5rem; */
    color: #f7af2f!important
}

.rad-input:checked~.rad-text {
    color: #666
}

.soc button {
    border: none;
    background: transparent
}

.soc button span i {
    color: #000!important
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 15px
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 1.25rem!important
}

@media(max-width: 768px) {
    .stepper-item {
        /* font-size:12px; */
        align-items: flex-start!important
    }
}

.stepper-item:before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 15px;
    left: -100%;
    z-index: 0
}

@media(max-width: 768px) {
    .stepper-item:before {
        left:-100%
    }
}

.stepper-item:after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 0
}

@media(max-width: 768px) {
    .stepper-item:after {
        left:0
    }
}

.stepper-item .step-name {
    text-align: center;
    /* font-size: 1.5rem */
}

.stepper-item .step-counter {
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    /* font-size: 1.5rem */
}

.stepper-item.active {
    font-weight: 700
}

.stepper-item.completed .step-counter {
    background: #25a550;
    color: #fff!important
}

.stepper-item.waiting .step-counter {
    background: #fff9e6;
    color: #ffc107!important;
    border: 1px solid #ffc107
}

.stepper-item.verifying .step-counter {
    background: #e6f2ff;
    color: #007bff!important;
    border: 1px solid #007bff
}

.stepper-item.canceled .step-counter {
    background: #fbe9eb;
    color: #dc3545!important;
    border: 1px solid #dc3545
}

.stepper-item.disable .step-counter {
    background-color: #ccc;
    color: #666
}

.stepper-item.completed:after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 15px;
    left: 20%;
    z-index: 3
}

.stepper-item:first-child:before,.stepper-item:last-child:after {
    content: none
}

.highlight_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    border-radius: 99rem;
    border: 1px solid rgba(0,0,0,.1);
    color: #000!important
}

.highlight_link.nav {
    border: 0!important;
    padding: 1.25rem 0;
    flex-wrap: nowrap!important
}

.highlight_link.nav.no-icon {
    padding: 0 .35rem
}

.highlight_link .sec_instruction {
    display: flex
}

.highlight_link .sec_instruction .note {
    /* font-size: 1.45rem!important; */
    color: #6c757d;
    /* font-family: Cereal-Light,sans-serif */
}

.highlight_link .sec_instruction .icon {
    margin-right: 1.15rem;
    border-radius: 99rem
}

.highlight_link .sec_instruction .icon i {
    color: #95378e
}

.highlight_link i {
    /* font-size: 1.75rem */
}

.highlight_link span {
    /* font-size: 1.5rem!important */
}

.title_mobile {
    padding-bottom: 1rem;
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.title_mobile:before {
    content: "";
    position: absolute;
    background: #f7af2f;
    width: 75px;
    height: 2px;
    bottom: 0;
    border-radius: 99rem;
    left: 0
}

.card_transaction {
    border: 1px solid rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem 0;
    position: relative
}

.card_transaction .desc-card-transaction .first {
    width: 90%
}

.card_transaction.primary {
    background: linear-gradient(90deg,#dc0a24,#95378e);
    color: #fff;
    margin: 0 0 1rem 0!important
}

.card_transaction .event_name {
    /* font-size: 1.75rem!important */
}

.card_transaction i {
    width: 2rem!important
}

.card_transaction .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.card_transaction .info .status {
    max-width: 75%;
    background: linear-gradient(90deg,#dc0a24,#95378e);
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 1rem;
    /* font-size: 1.25rem!important */
}

.sticky_10 {
    top: 10%
}

.sticky_10,.sticky_15 {
    position: sticky!important
}

.sticky_15 {
    top: 15%
}

.sticky_20 {
    position: sticky!important;
    top: 20%
}

.card-transaction-2 {
    background: #fff;
    margin-bottom: 3rem;
    border-radius: .5rem;
    position: relative;
    border: 1px solid #ececec
}

.card-transaction-2 .right {
    max-width: 300px;
    min-width: auto
}

.card-transaction-2:before {
    width: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    background: #95378e;
    height: 2px
}

.card-transaction-2 .order_id {
    background: #95378e;
    padding: .25rem 1rem;
    border-radius: .5rem;
    position: relative;
    top: -7px;
    left: 10px;
    color: #fff
}

.card-transaction-2 .card-top {
    padding: .5rem 1rem .25rem 1rem;
    display: flex
}

.card-transaction-2 .card-top .status-trans {
    padding: .25rem .5rem;
    border-radius: .5rem
}

.card-transaction-2 .card-top .status-trans.orange {
    background: #fef5e7;
    color: #f7af2f!important
}

.card-transaction-2 .card-top .status-trans.green {
    background: #eafaf0;
    color: #25a550!important
}

.card-transaction-2 .card-top .status-trans.blue {
    background: #e6f8ff;
    color: #0287b4!important
}

.card-transaction-2 .card-top .status-trans.red {
    background: #fee7e9;
    color: #dc0a24!important
}

.card-transaction-2 .card-top .status-trans.gray {
    background: #dee2e6;
    color: #495057
}

.card-transaction-2 .card-cont {
    padding: .15rem 1rem 0 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #000!important
}

.card-transaction-2 .card-cont .first {
    width: 95%
}

.card-transaction-2 .card-cont i {
    /* font-family: sans-serif */
}

.card-transaction-2 .card-cont .second {
    width: 5%;
    display: flex;
    justify-content: center
}

.card-transaction-2 .card-foot {
    padding: .15rem 1rem 1rem 1rem
}

.status-trans {
    padding: .25rem .5rem;
    border-radius: .5rem
}

.status-trans.orange {
    background: #fef5e7;
    color: #f7af2f!important
}

.status-trans.green {
    background: #eafaf0;
    color: #25a550!important
}

.status-trans.blue {
    background: #e6f8ff;
    color: #0287b4!important
}

.status-trans.red {
    background: #fee7e9;
    color: #dc0a24!important
}

.history .nav-tabs .nav-item {
    width: 100%
}

.wi-m {
    /* font-size: 2rem!important */
}

.bg-grey {
    background: #f2f2f2
}

.bg-lightgrey {
    background: #f8f9fa!important
}

.img_modal_preview {
    position: relative;
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 1rem;
    margin-right: 1.5rem!important
}

@media only screen and (max-width: 768px) {
    .img_modal_preview {
        width:75px;
        height: 50px
    }
}

.img_modal_preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 1rem
}

.text-gray {
    color: #8d99ae
}

.border-05 {
    border: 1px solid #f2f2f2;
    padding: .5rem
}

.cookiesContent {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: none;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 70px
}

.cookiesContent button.close {
    width: 30px;
    /* font-size: 20px; */
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px
}

.cookiesContent img {
    width: 82px;
    margin-bottom: 15px
}

.cookiesContent p {
    margin-bottom: 40px;
    /* font-size: 18px */
}

.cookiesContent button.accept {
    background-color: #ed6755;
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    /* font-size: 16px; */
    color: #fff;
    box-shadow: 0 6px 18px -5px #ed6755
}

.cookiesContent:target {
    opacity: 1;
    display: block
}

.cookiesContent:target .modal-body {
    opacity: 1;
    transform: translateY(1)
}

.img-info-modal {
    width: 100%
}

.coupon_ticket {
    width: 75%;
    min-height: 180px;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,.2);
    background: #95378e;
    padding: 2.25rem 2rem!important;
    position: relative
}

@media only screen and (max-width: 768px) {
    .coupon_ticket {
        width:100%
    }
}

.coupon_ticket .vertical {
    border-left: 5px dotted #000;
    height: 100px;
    position: absolute;
    left: 40%
}

.coupon_ticket .content h4 {
    /* font-size: 1.5rem!important; */
    margin-left: -20px;
    color: #e9ecef;
    margin-left: 1rem
}

.coupon_ticket .content h4 span {
    /* font-size: 1.35rem!important */
}

.coupon_ticket .content h5 {
    /* font-size: 18px; */
    margin-left: -20px;
    color: #fff;
    margin-left: 1rem;
    text-transform: uppercase
}

.coupon_ticket .content p {
    /* font-size: 16px; */
    color: #fff;
    margin-left: 1rem
}

.coupon_ticket .copy-button {
    margin: 12px 0 -5px 0;
    height: 45px;
    border-radius: 4px;
    padding: 0 5px;
    background: #fff;
    border: 1px solid #e1e1e1
}

.coupon_ticket .copy-button input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    /* font-size: 1.35rem!important */
}

.coupon_ticket .co-img {
    background: #fff;
    border-radius: 99rem;
    padding: .25rem
}

.coupon_ticket .co-img img {
    width: 50px!important;
    height: 50px!important
}

.copy-button,.main {
    display: flex;
    padding: 0 10px;
    align-items: center
}

.coupon_ticket:after {
    right: -20px
}

.coupon_ticket:after,.coupon_ticket:before {
    position: absolute;
    content: "";
    height: 40px;
    border-radius: 40px;
    z-index: 1;
    top: 70px;
    background-color: #fff;
    width: 40px
}

.coupon_ticket:before {
    left: -20px
}

.title_with_before {
    position: relative;
    padding: 1rem 0
}

.title_with_before:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -20px;
    width: 100px;
    height: 5px;
    border-radius: 100px!important;
    background: linear-gradient(90deg,#dc0a24,#95378e)
}

.date-picker input {
    display: block!important;
    width: 100%!important;
    color: #495057!important;
    background-color: #fff!important;
    background-clip: padding-box!important;
    border: 1px solid #ced4da!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    padding: .25rem .5rem!important;
    /* font-size: .875rem!important; */
    line-height: 1.5!important;
    border-radius: .2rem!important
}

.animate-bottom {
    position: relative;
    -webkit-animation: animatebottom .4s;
    animation: animatebottom .4s
}

@keyframes animatebottom {
    0% {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

.modal .tabs ul li {
    margin: 0!important
}

.modal ol li,.modal ul li {
    margin: 1rem 0
}

.masonry-item .Item .Content p {
    text-align: center
}

.menu_list.private .group-link {
    display: flex;
    align-items: center
}

.menu_list .group-link {
    display: flex;
    /* font-size: 1.25rem!important; */
    padding: .5rem 0
}

.menu_list .group-link .icon-link {
    width: 12.5%!important
}

.menu_list .group-link .icon-link i {
    /* font-size: 2rem; */
    color: rgba(0,0,0,.5)
}

.menu_list .group-link a {
    color: rgba(0,0,0,.5)!important;
    padding: 0;
    text-align: center;
    text-decoration: none;
    transition: all .5s;
    /* font-size: 1.45rem!important; */
    /* font-family: Cereal-Light,sans-serif; */
    display: flex;
    align-items: center;
    justify-content: center
}

.underline {
    text-decoration: underline!important
}

.cont_right_sec {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem;
    padding: 1rem
}

.cont_right_sec .top-section .breadcrumb {
    /* font-size: 1rem!important */
}

.navigation-d {
    background: #fff;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10!important;
    width: 100%;
    box-shadow: 1px -2px 9px -9px #000
}

.navigation-d ul {
    display: flex;
    width: 400px
}

.navigation-d ul li {
    list-style: none;
    position: relative;
    width: 85px;
    height: 60px;
    z-index: 2
}

.navigation-d ul li a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center
}

.navigation-d ul li a .icon {
    position: relative;
    display: block;
    line-height: 65px;
    transition: .5s;
    color: #222327
}

.navigation-d ul li a .icon i {
    /* font-size: 2rem!important */
}

.navigation-d ul li a .text {
    position: absolute;
    background: linear-gradient(90deg,#dc0a24,#95378e);
    color: #fff;
    padding: 2px 7px;
    border-radius: 12px;
    /* font-size: 1.25rem!important; */
    letter-spacing: .05em;
    transition: .5s;
    opacity: 0;
    transform: translateY(15px)
}

.navigation-d ul li a.router-link-exact-active .icon {
    transform: translateY(-32px);
    color: #dc0a24
}

.navigation-d ul li a.router-link-exact-active .text {
    transform: translateY(-4px);
    opacity: 1
}

.navigation-d ul li a.router-link-exact-active:first-child~.indicator {
    transform: translateX(0)
}

.navigation-d ul .indicator {
    position: absolute;
    top: -50%;
    width: 85px;
    height: 85px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    box-shadow: 1px -2px 9px -9px #000
}

.navigation-d ul .indicator:before {
    left: -23.5px;
    box-shadow: 15px 20px #fff
}

.navigation-d ul .indicator:after,.navigation-d ul .indicator:before {
    content: "";
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 50%
}

.navigation-d ul .indicator:after {
    right: -23.5px;
    box-shadow: -15px 20px #fff
}

.navigation-d ul li:first-child a.router-link-exact-active~.indicator {
    transform: translateX(0)
}

.navigation-d ul li:nth-child(2) a.router-link-exact-active~.indicator {
    transform: translateX(85px)
}

.navigation-d ul li:nth-child(3) a.router-link-exact-active~.indicator {
    transform: translateX(255px)
}

.legal p,.legal span {
    line-height: 3rem!important
}

@media only screen and (max-width: 768px) {
    .legal p,.legal span {
        line-height:2.5rem!important
    }
}

.legal h3 span {
    /* font-size: 2.5rem!important */
}

.legal h3 span strong {
    font-family: Cereal,sans-serif
}

.legal h4 span {
    /* font-size: 2rem!important */
}

.legal h4 span strong,.legal p a span {
    font-family: Cereal,sans-serif
}

.legal p a span {
    color: #95378e!important
}

.image-hotel {
    position: relative;
    width: 300px;
    height: 150px;
    background: #fff;
    border-radius: .25rem;
    margin: 1rem 1rem
}

@media only screen and (max-width: 768px) {
    .image-hotel {
        width:90%
    }
}

.image-hotel img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: .25rem
}

.image-addons {
    position: relative;
    width: 75px;
    height: 75px;
    background: #fff;
    border-radius: 99rem;
    margin-right: 1rem;
    border: 2px solid #95378e
}

.image-addons img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem
}

@media only screen and (max-width: 768px) {
    .image-addons {
        width:50px!important;
        height: 50px!important
    }
}

.mr075-important {
    margin-right: .75rem!important
}

.ml075-important {
    margin-left: .75rem!important
}

.ml1-important {
    margin-left: 1rem!important
}

.list_addons {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding: .5rem 1rem;
    background: #dee2e6;
    border-radius: 99rem;
    margin-bottom: 1rem
}

@media only screen and (max-width: 768px) {
    .list_addons {
        border-radius:1rem!important;
        padding: 1rem!important
    }
}

.list_addons input[type=checkbox] {
    display: none;
    z-index: 2
}

.list_addons input[type=checkbox]+label {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 1em;
    background: #fff;
    z-index: 8;
    transition: .25s ease-in-out
}

.list_addons input[type=checkbox]:checked+label {
    background: linear-gradient(90deg,#dc0a24,#95378e);
    z-index: 8
}

.list_addons label {
    border-radius: 99rem;
    z-index: 8
}

@media only screen and (max-width: 768px) {
    .list_addons p,.list_addons span {
        /* font-size:1.35rem!important; */
        margin-right: .5rem!important
    }
}

.pre {
    background: #eee;
    padding: 1rem;
    border-radius: 5px
}

.quantity-toggle input {
    border-top: 2px solid #adb5bd!important;
    border-bottom: 2px solid #adb5bd!important;
    /* font-size: 1.5rem */
}

.quantity-toggle button {
    border: 2px solid #adb5bd!important;
    padding: .5rem
}

pre {
    background: #eee;
    padding: 1rem;
    border-radius: 99rem
}

.quantity-toggle {
    display: flex
}

.quantity-toggle input {
    border: 0;
    border-top: 2px solid #e9e9e9!important;
    border-bottom: 2px solid #e9e9e9!important;
    width: 2.5rem;
    text-align: center;
    padding: 0 .5rem
}

.quantity-toggle button {
    border: 2px solid #e9e9e9!important;
    padding: .5 .1rem;
    background: #f5f5f5;
    color: #888;
    /* font-size: 1rem; */
    cursor: pointer
}

.quantity-toggle button.decrement {
    border-top-left-radius: 90rem;
    border-bottom-left-radius: 90rem
}

.quantity-toggle button.increment {
    border-top-right-radius: 90rem;
    border-bottom-right-radius: 90rem
}

.nav-tabs .nav-item .nav-link {
    padding: 1rem 1rem!important
}

@media only screen and (min-width: 768px) {
    .titlepage-background {
        height:200px
    }
}

@media only screen and (max-width: 768px) {
    .titlepage-background {
        padding:2rem 0 2rem 0;
        min-height: 300px
    }
}

.titlepage-background.grey {
    background: #dee2ee
}

.titlepage-background.purple {
    background: #95378e
}

.normal .custom-select,.normal label {
    /* font-family: Cereal-Light,sans-serif */
}

.normal .custom-select {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0,0,0,.2)!important;
    padding: 0 1rem;
    position: relative;
    border-radius: 99rem;
    text-indent: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: 95% 52.5%
}

.mandatory,.mandatory label {
    position: relative
}

.mandatory label {
    display: inline!important
}

.mandatory label:before {
    position: absolute;
    top: 0;
    right: -10px;
    content: "*";
    color: red
}

details p span {
    /* font-size: 1rem!important */
}

.breadcrumb .breadcrumb-item,.breadcrumb .breadcrumb-item a {
    color: #fff
}

.breadcrumb .breadcrumb-item.text-black a {
    color: #000
}

.breadcrumb-item+.breadcrumb-item:before {
    color: #fff
}

.breadcrumb .breadcrumb-item {
    /* font-size: 1.35rem!important; */
    text-decoration: underline
}

.breadcrumb .breadcrumb-item a {
    /* font-size: 1.35rem!important */
}

.breadcrumb .breadcrumb-item.active {
    text-decoration: none;
    color: #f4f4f4!important;
    min-width: 400px
}

@media only screen and (max-width: 768px) {
    .breadcrumb .breadcrumb-item.active {
        min-width:auto
    }
}

.breadcrumb .breadcrumb-item.active p {
    width: 100%
}

.breadcrumb.black .breadcrumb-item+.breadcrumb-item:before {
    color: #000!important
}

.breadcrumb.black .breadcrumb-item {
    text-decoration: underline
}

.breadcrumb.black .breadcrumb-item a {
    color: #95378e!important
}

.breadcrumb.black .breadcrumb-item.active {
    text-decoration: none;
    color: #1e1e1e!important
}

.breadcrumb.black .breadcrumb-item.active p {
    color: #1e1e1e!important
}

@media only screen and (max-width: 768px) {
    .breadcrumb {
        /* font-size:1.15rem!important */
    }
}

.overview p span {
    word-spacing: 3px
}

.overview p h2 strong,.overview p h3 strong,.overview p h4 strong,.overview p h5 strong,.overview p h6 strong,.overview p p strong {
    font-weight: "Cereal-Bold",sans-serif
}

header .nav-item .nav-link {
    padding: 0!important
}

.twt i {
    color: #33c2ff!important
}

.fb i {
    color: #2e5eaa!important
}

.wa i {
    color: #70e000!important
}

.breadcrumb-item+.breadcrumb-item:before {
    color: #000
}

.icon-search {
    /* font-size: 2rem */
}

.search-sec {
    cursor: pointer;
    margin: 0 1rem 0 .75rem
}

.search-sec.router-link-exact-active .icon-search {
    color: #95378e!important;
    /* font-family: sans-serif */
}

.search-sec.white {
    color: #fff
}

.search-sec.black {
    color: #000
}

.search-sec:last-child {
    margin: 0 1.5rem 0 .75rem
}

.popup-search {
    display: none;
    background: rgba(0,0,0,.5);
    z-index: 999;
    opacity: 0;
    transition: all 1s ease-in-out
}

.popup-search .content {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1rem 0
}

@media only screen and (max-width: 768px) {
    .popup-search .content {
        max-height:50vh
    }
}

.popup-search .card {
    background-color: #fff;
    padding: 15px;
    border: none;
    border-radius: 1rem;
    max-height: 90vh
}

@media only screen and (max-width: 768px) {
    .popup-search .card {
        max-height:85vh!important
    }
}

.popup-search span {
    /* font-size: 1.65rem!important */
}

.popup-search span.fs-1rem {
    /* font-size: 1.25rem!important */
}

.popup-search span p {
    color: #2b108e!important;
    cursor: pointer
}

.popup-search span p:hover {
    color: #95378e
}

.popup-search span p span {
    color: #2b108e!important;
    cursor: pointer;
    /* font-size: 1.65rem!important */
}

.popup-search span p span:hover {
    color: #95378e
}

.popup-search span p i {
    color: #2b108e!important;
    /* font-size: 1.65rem!important; */
    cursor: pointer
}

.popup-search span p i:hover {
    color: #95378e
}

.popup-search .label_error {
    /* font-size: 1.25rem!important */
}

.popup-search .input-box {
    position: relative
}

.popup-search .input-box i {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    display: inline;
    cursor: pointer
}

.popup-search .form-control {
    height: 50px;
    background-color: hsla(0,0%,93.3%,.4117647058823529)
}

.popup-search .form-control:focus {
    background-color: hsla(0,0%,93.3%,.4117647058823529);
    box-shadow: none;
    border-color: #eee
}

.popup-search .list {
    padding-top: 3px;
    padding-bottom: 10px;
    display: flex;
    align-items: center
}

.popup-search .border-bottom {
    border-bottom: 2px solid #eee
}

.popup-search .list i {
    /* font-size: 19px; */
    color: red
}

.popup-search .list small {
    color: #dedddd
}

.popup-search.active {
    display: inherit;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 1s ease-in-out
}

.popup-search.active .card {
    margin-top: 3rem!important
}

@media only screen and (max-width: 768px) {
    .popup-search.active .card {
        margin-top:1rem!important
    }
}

.popup-search .close-popup {
    width: 50px;
    height: 50px;
    background: #fff;
    /* font-size: 2.25rem; */
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

.imagePreviewWrapper {
    width: 100%;
    height: 250px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: 50%
}

input[type=file] {
    display: none
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 1rem 2rem;
    cursor: pointer;
    width: 100%;
    /* font-size: 1.5rem!important */
}

.custom-file-upload i {
    /* font-size: 3rem!important */
}

.button button {
    padding: 1rem 1.5rem;
    border-radius: 99rem;
    border: none;
    outline: none
}

.button.primary_icon button {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg,#95378e,#0287b4);
    color: #fff
}

.button.primary_icon button span {
    margin-right: 1rem
}

.button.primary_icon button .icon {
    line-height: 25px;
    width: 25px;
    height: 25px;
    border-radius: 99rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.button.primary_icon button .icon i {
    color: #000
}

.button.primary_icon button.full {
    justify-content: center
}

.help {
    background: #f2f2f2;
    width: 100%;
    border-radius: 1rem;
    margin: 1rem 0;
    padding: .75rem 2rem;
    font-weight: 600
}

.vs__selected,span.vs__selected {
    overflow: hidden!important;
    white-space: nowrap!important;
    text-overflow: ellipsis!important
}

.box_gallery {
    height: 234px;
    min-width: 275px;
    max-width: 275px;
    background: #95378e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative
}

.box_gallery a {
    color: #fff!important
}

@media only screen and (max-width: 768px) {
    .box_gallery {
        min-width:50%;
        max-width: 50%;
        height: 150px
    }

    .box_gallery.nomax-width {
        max-width: 100%
    }

    .box_gallery .content_box h4 {
        word-wrap: break-word;
        /* font-size: 1.75rem!important */
    }
}

.box_gallery img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.box_gallery .title_box {
    color: #fff;
    letter-spacing: 1px
}

.box_gallery.image {
    position: relative
}

.box_gallery.image,.box_gallery.image .icon-preview {
    display: flex;
    justify-content: center;
    align-items: center
}

.box_gallery.image .icon-preview {
    z-index: 2;
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 99rem;
    opacity: 0
}

.box_gallery.image .icon-preview i {
    /* font-size: 2rem */
}

.box_gallery.image .title-preview {
    z-index: 2;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    text-align: center
}

.box_gallery.image .title-preview p {
    color: #fff
}

@media only screen and (max-width: 768px) {
    .box_gallery.image .title-preview p {
        /* font-size:1.15rem!important */
    }
}

.box_gallery.image:hover {
    cursor: pointer
}

.box_gallery.image:hover .icon-preview,.box_gallery.image:hover .title-preview {
    opacity: 1
}

.box_gallery.image:hover:before {
    position: absolute;
    content: "";
    background: linear-gradient(90deg,#dc0a24,#95378e);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    filter: opacity(80%)
}

.content-html h1,.content-html h2,.content-html h3,.content-html h4,.content-html h5,.content-html h6,.content-html li,.content-html ol,.content-html p,.content-html ul {
    line-height: 3rem;
    color: #000
}

.content-html p a {
    color: #95378e!important;
    text-decoration: underline!important;
    line-height: 3rem;
    color: #000
}

.content-html p a span {
    color: #95378e!important
}

.content-html p i {
    line-height: 3rem;
    color: #000
}

.content-html p i a,.content-html p i a i,.content-html p i a span {
    color: #95378e!important;
    text-decoration: underline!important;
    line-height: 3rem;
    color: #000
}

.content-html h1 {
    /* font-size: 4.5rem!important */
}

.content-html h1 span {
    /* font-size: 3rem!important */
}

.content-html h1 span strong,.content-html h1 strong {
    /* font-family: sans-serif */
}

.content-html h2,.content-html h2 span {
    /* font-size: 3rem!important */
}

.content-html h2 span strong,.content-html h2 strong {
    /* font-family: sans-serif */
}

.content-html h3,.content-html h3 span {
    /* font-size: 2.5rem!important */
}

.content-html h3 span strong,.content-html h3 strong {
    /* font-family: sans-serif */
}

.content-html h4,.content-html h4 span {
    /* font-size: 2.25rem!important */
}

.content-html h4 span strong,.content-html h4 strong {
    /* font-family: sans-serif */
}

.content-html dl,.content-html ol,.content-html ul {
    margin-left: 1.5rem;
    line-height: 2.5rem
}

.content-html dl span strong,.content-html ol span strong,.content-html ul span strong {
    /* font-family: sans-serif */
}

@media only screen and (max-width: 768px) {
    .vc-popover-content,.vc-popover-content-wrapper,.vc-popover-content .vc-container {
        width:100%!important
    }
}

.wrapper_newsarticles {
    display: flex
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .wrapper_newsarticles {
        display:inherit
    }
}

.wrapper_newsarticles .feature {
    width: 50%
}

.wrapper_newsarticles .feature .card-feature {
    width: 100%;
    height: 550px;
    position: relative
}

.wrapper_newsarticles .feature .card-feature img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.wrapper_newsarticles .feature .card-feature .title-feature,.wrapper_newsarticles .feature .card-feature .title-feature * {
    /* font-size: 3rem!important */
}

.wrapper_newsarticles .feature .card-feature .content {
    position: relative;
    z-index: 2!important;
    display: flex;
    height: 100%;
    padding: 2rem;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff
}

.wrapper_newsarticles .feature .card-feature:before {
    position: absolute;
    content: "";
    width: 500px;
    height: 100%;
    background: #95378e;
    filter: opacity(50%);
    top: 0;
    z-index: 2;
    left: 0;
    -webkit-clip-path: polygon(0 0,22% 0,49% 100%,0 100%);
    clip-path: polygon(0 0,22% 0,49% 100%,0 100%)
}

.wrapper_newsarticles .feature .card-feature:after {
    position: absolute;
    content: "";
    background: linear-gradient(0deg,rgba(0,0,0,.9),rgba(0,0,0,.9));
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    -webkit-mask-size: 300px 200px;
    -webkit-mask-image: -webkit-gradient(linear,center top,center bottom,color-stop(0,transparent),color-stop(1,#000))
}

.wrapper_newsarticles .mostviewed {
    width: 50%
}

@media only screen and (min-device-width: 768px)and (max-device-width:1024px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:1) {
    .wrapper_newsarticles .mostviewed {
        width:100%
    }
}

.wrapper_newsarticles .mostviewed .card-newsarticles {
    display: flex;
    align-items: center
}

.wrapper_newsarticles .mostviewed .card-newsarticles .icon {
    margin-right: 1rem
}

.wrapper_newsarticles .mostviewed .card-newsarticles .icon i {
    margin-right: .5rem
}

.wrapper_newsarticles .mostviewed .card-newsarticles .img {
    width: 50%;
    height: 275px;
    position: relative
}

.wrapper_newsarticles .mostviewed .card-newsarticles .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    z-index: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.wrapper_newsarticles .mostviewed .card-newsarticles .img:before {
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    background: #95378e;
    filter: opacity(50%);
    top: 0;
    z-index: 2;
    left: 0;
    -webkit-clip-path: polygon(0 0,22% 0,49% 100%,0 100%);
    clip-path: polygon(0 0,22% 0,49% 100%,0 100%)
}

.wrapper_newsarticles .mostviewed .card-newsarticles .text {
    width: 50%;
    padding: 1rem 2rem;
    color: #fff!important
}

.wrapper_newsarticles .mostviewed .card-newsarticles .text .title-card,.wrapper_newsarticles .mostviewed .card-newsarticles .text .title-card * {
    /* font-size: 1.75rem!important */
}

.wrapper_newsarticles .bg-red {
    background: #dc0a24
}

.wrapper_newsarticles .bg-purple {
    background: #95378e
}

.wrapper_newsarticles .icon {
    display: flex;
    align-items: center;
    /* font-family: Cereal-Light,sans-serif!important */
}

.wrapper_newsarticles .icon i {
    /* font-size: 2rem */
}

.event-det {
    border: 1px solid #dee2e6;
    border-radius: 1rem
}

.border-r-1 {
    border-right: 1px solid #dee2e6
}

@media only screen and (max-width: 768px) {
    .border-r-1 {
        border:none
    }
}

.position-sticky {
    position: sticky!important;
    top: 0!important
}

.alternative__heading-text-1 {
    margin-bottom: 24px
}

.alternative__heading-text-1 h2 {
    /* font-size: 32px!important; */
    font-weight: 700
}

@media only screen and (max-width: 768px) {
    .alternative__heading-text-1 h2 {
        word-wrap:break-word;
        /* font-size: 26px!important */
    }
}

.over-scroll {
    max-height: 80vh;
    overflow: scroll!important
}

@media only screen and (max-width: 768px) {
    .over-scroll.none-mobile {
        overflow:visible!important
    }
}

.minheight_20 {
    min-height: 20vh!important
}

.no-focus-outline input:focus-visible {
    outline: none
}

.card-hotel {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem
}

.card-hotel.no-border {
    border-bottom: 0!important;
    margin-bottom: 0;
    padding-bottom: 0
}

@media only screen and (max-width: 768px) {
    .card-hotel {
        flex-direction:column
    }
}

.card-hotel .image {
    position: relative;
    width: 300px;
    height: 200px;
    background: #fff;
    border-radius: 1rem;
    margin-right: 1.25rem
}

@media only screen and (max-width: 768px) {
    .card-hotel .image {
        margin-bottom:1rem;
        width: 100%
    }
}

.card-hotel .image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    -o-object-fit: cover;
    object-fit: cover
}

.card-hotel .desc {
    width: 100%!important
}

.border-grey {
    border: 1px solid #cdd0d3!important;
    border-radius: 1rem
}

hr,hr.border-grey {
    border: 1px solid #cdd0d3!important
}

.text-wrap {
    width: 95%;
    word-wrap: break-word
}

.form-control::-moz-placeholder {
    color: rgba(0,0,0,.3)!important
}

.form-control:-ms-input-placeholder {
    color: rgba(0,0,0,.3)!important
}

.form-control::placeholder {
    color: rgba(0,0,0,.3)!important
}

.group-text.direction-row {
    display: flex;
    flex-direction: row
}

.group-text.direction-row .title-group {
    margin-right: .5rem
}

.group-text .title-group {
    color: #adb5bd;
    /* font-family: Cereal-Light,sans-serif */
}

.live-halfbanner {
    color: #fff;
    border-radius: 99rem;
    display: flex;
    align-items: center;
    z-index: 99;
    max-width: 135px
}

@media only screen and (max-width: 768px) {
    .live-halfbanner {
        max-width:135px
    }
}

.live-halfbanner .num {
    margin-right: .75rem
}

.time-picker .dropdown .select-list .active {
    background: #95378e!important
}

.live-viewers {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background: #f7af2f;
    box-shadow: 0 4px 8px 0 rgba(27,27,27,.2),0 16px 24px 0 rgba(27,27,27,.2);
    -webkit-box-shadow: 0 4px 8px 0 rgba(27,27,27,.2),0 16px 24px 0 rgba(27,27,27,.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(27,27,27,.2),0 16px 24px 0 rgba(27,27,27,.2);
    color: #fff;
    padding: .5rem 1rem;
    border-radius: 99rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 99;
    min-width: 125px
}

@media only screen and (max-width: 768px) {
    .live-viewers {
        bottom:70px;
        left: 10px
    }
}

.live-viewers i {
    /* font-size: 2rem */
}

.live-viewers .photo-viewers {
    display: flex
}

.live-viewers .photo-viewers .bullet-img {
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 99rem;
    margin-right: -1.25rem;
    border: 1px solid #fff
}

.live-viewers .photo-viewers .bullet-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem
}

.input-date-range {
    border: none!important;
    border-bottom: 1px solid rgba(0,0,0,.1)!important;
    border-radius: 0!important;
    padding: 0 0 1rem 1rem!important
}

.input-date-range::-moz-placeholder {
    color: #000!important
}

.input-date-range:-ms-input-placeholder {
    color: #000!important
}

.input-date-range::placeholder {
    color: #000!important
}

.block {
    width: 100%;
    padding: 1rem;
    z-index: 10;
    background-color: #f8f9fa;
    border-radius: 1rem!important;
    border: 1px solid #d2d2d2;
    overflow: hidden
}

.block.sm {
    padding: .25rem .25rem
}

.block label {
    display: flex;
    justify-content: space-between;
    width: 100%
}

@media screen and (max-width: 599px) {
    .block {
        max-width:100%
    }
}

.block>div {
    display: block;
    position: relative;
    padding: 0 0 0 0;
    color: #000
}

.block>div:not(:last-of-type) {
    margin: 0 0 10px
}

.block>div input+label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    transition: all .25s ease-in-out .5s,color .25s ease-in-out .5s;
    color: #000
}

.block>div input~div {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .5s ease-in-out .2s,opacity .25s ease-in-out .25s,padding 0s ease-in-out 0s;
    width: calc(100% + 35px);
    margin-left: -35px
}

.block>div input~div p {
    padding-top: 1rem;
    margin-left: 4rem;
    border-top: 1px solid hsla(0,0%,50.2%,.25)
}

.block>div input:checked+label {
    transition: background-color 0s ease-in-out 0s;
    color: #000
}

.block>div input:checked~div {
    display: block;
    opacity: 1;
    visibility: visible;
    max-height: 200px;
    transition: all .5s ease-in-out .2s,opacity .25s ease-in-out .5s,padding 0s ease-in-out 0s
}

.accordion-item {
    background-color: #f8f9fa;
    border-radius: 1rem!important;
    border: 1px solid #d2d2d2
}

.accordion-link {
    /* font-size: 1.6rem; */
    text-decoration: none;
    background-color: #f8f9fa;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}

.accordion-link .question-title {
    font-weight: 500;
    /* font-size: 20px; */
    color: #000
}

.accordion-link i {
    color: #000;
    padding: .5rem
}

.fs2rem {
    /* font-size: 2rem!important */
}

.fs3rem {
    /* font-size: 3rem!important */
}

.accordion-link ul {
    display: flex;
    align-items: flex-end;
    list-style-type: none;
    margin-left: 25px
}

.primary-border {
    border: 1px solid rgba(0,0,0,.1)
}

.primary-border.radius {
    border-radius: 1rem
}

.accordion-link li {
    /* font-size: 10px; */
    padding: 0 0 1px 5px
}

.accordion-link div {
    display: flex
}

.accordion-link .ri-arrow-down-line {
    display: none
}

.answer {
    max-height: 0;
    overflow: hidden;
    position: relative;
    background-color: #f8f9fa;
    transition: max-height .65s;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem
}

.answer p {
    /* font-size: 15px; */
    padding: 0 2rem
}

.accordion-item:target .answer {
    max-height: 20rem
}

.accordion-item:target .accordion-link .ri-arrow-right-line {
    display: none
}

.accordion-item:target .accordion-link .ri-arrow-down-line {
    display: block
}

.content-ck p strong,.content-ck strong {
    /* font-family: sans-serif */
}

.content-ck a,.content-ck a span,.content-ck p a,.content-ck p a span,.content-ck span a {
    color: #95378e!important;
    text-decoration: underline!important
}

@media only screen and (max-width: 768px) {
    .content-ck a span {
        /* font-size:1.5rem!important */
    }
}

.owl-carousel {
    touch-action: manipulation!important
}

.fs-1rem,.fs-1rem span {
    /* font-size: 1.35rem!important */
}

.fs-sm,.fs-sm span {
    /* font-size: 1.25rem!important */
}

.fs-l,.fs-l span {
    /* font-size: 2.25rem!important */
}

.fs-2rem,.fs-2rem p,.fs-2rem span {
    /* font-size: 2rem!important */
}

@media only screen and (max-width: 768px) {
    .fs-2rem p,.fs-2rem span {
        /* font-size:1.75rem!important */
    }
}

.button-custom {
    background: #fff;
    border: none;
    min-width: 40px;
    border-radius: 99rem
}

.button-custom i {
    /* font-size: 2rem; */
    color: #fff
}

.button-custom.success {
    background: #2fa155
}

.button-custom.danger {
    background: #e56a75
}

.ellipsis {
    width: 100%;
    word-wrap: break-word
}

@media only screen and (max-width: 768px) {
    .ellipsis {
        width:300px
    }
}

.view-mobile {
    background: #fff;
    transform: translateY(-80px);
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    padding-top: .1rem
}

.simple-card {
    border-radius: .75rem;
    padding: 1rem
}

@media only screen and (min-width: 768px) {
    .simple-card {
        border:0!important;
        box-shadow: 0 2px 8px 0 rgba(53,64,90,.1)
    }
}

@media only screen and (max-width: 768px) {
    .simple-card {
        box-shadow:0 2px 8px 0 rgba(53,64,90,.1)
    }
}

.simple-card .top {
    display: flex
}

.simple-card .top .img {
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 50px;
    height: 50px;
    background: #fff;
    margin-right: 1rem!important;
    border-radius: .75rem
}

.simple-card .top .img img {
    border-radius: .75rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.simple-card .top .text {
    width: 80%
}

.simple-card.box-shadow-none {
    box-shadow: none;
    padding: 0
}

.simple-card.box-shadow-none .top .img {
    width: 75px;
    height: 75px
}

.form-group.d-flex div {
    display: flex
}

.form-group.d-flex.flex-column {
    flex-direction: column
}

.form-group .custom-radio label {
    margin-left: .5rem
}

.form-group .custom-radio .custom-control-input {
    width: 25px;
    height: 25px;
    position: relative
}

.green-icon {
    background: #25a550;
    color: #fff;
    font-weight: 700;
    padding: .25rem;
    border-radius: 99rem
}

.alert-custom {
    padding: .25rem .75rem;
    border-radius: .5rem
}

.alert-custom.purple {
    background: #f8ecf7;
    color: #95378e
}

.alert-custom.red {
    background: #fee7e9;
    color: #dc0a24
}

.alert-custom.green {
    background: #eafaf0;
    color: #25a550
}

.alert-custom.blue {
    background: #e6f8ff;
    color: #0287b4
}

.alert-custom.yellow {
    background: #fef5e6;
    color: #f7af2f
}

.alert-custom.gray {
    background: #f0f1f4;
    color: #636883
}

.iframe {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    z-index: 998
}

.iframe.bg-modal-black {
    width: 100%;
    background: rgba(0,0,0,.5)!important;
    display: flex;
    justify-content: center;
    align-items: center
}

.iframe iframe {
    border-radius: 1rem
}

.iframe .close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #95378e;
    border: 1px solid #fff;
    padding: .5rem;
    width: 40px;
    height: 40px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

.iframe .close i {
    /* font-size: 2rem!important; */
    color: #fff
}

.paid-icon {
    position: absolute;
    bottom: 0;
    right: 0
}

.box-border {
    border: 1px solid #dee2ee;
    padding: 1rem;
    border-radius: 1rem
}

.box-feature .icon {
    background: #f7af2f;
    width: 75px;
    height: 75px;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center
}

.box-feature .icon img {
    width: 50px
}

.card-event-2 {
    background: #dee2ee;
    display: flex!important;
    height: 325px;
    justify-content: space-between;
    border-radius: 1rem;
    position: relative
}

@media only screen and (max-width: 768px) {
    .card-event-2 {
        height:400px
    }
}

.card-event-2 .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 2
}

.card-event-2 .ribbon:after,.card-event-2 .ribbon:before {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #d8c838
}

.card-event-2 .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #f1df40;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #2b2d42;
    text-align: center
}

@media only screen and (max-width: 768px) {
    .card-event-2 .ribbon span {
        padding:10px 0!important
    }
}

.card-event-2 .ribbon-top-right {
    top: -10px;
    right: -10px
}

.card-event-2 .ribbon-top-right:after,.card-event-2 .ribbon-top-right:before {
    border-top-color: transparent;
    border-right-color: transparent
}

.card-event-2 .ribbon-top-right:before {
    top: 0;
    left: 0
}

.card-event-2 .ribbon-top-right:after {
    bottom: 0;
    right: 0
}

.card-event-2 .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg)
}

.card-event-2 .main-content {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1rem 2rem
}

@media only screen and (max-width: 768px) {
    .card-event-2 .main-content {
        padding:1rem 0 1rem 1rem;
        width: 60%
    }
}

@media only screen and (max-width: 768px) {
    .card-event-2 .main-content .title-comp {
        /* font-size:2.25rem!important */
    }
}

@media only screen and (max-width: 768px) {
    .card-event-2 .main-content .sub-title-comp {
        /* font-size:1.5rem!important */
    }
}

.card-event-2 .main-content .program a {
    padding: .25rem 1rem;
    border-radius: 99rem;
    color: #fff
}

@media only screen and (max-width: 768px) {
    .card-event-2 .main-content .program a {
        /* font-size:1.25rem!important */
    }
}

.card-event-2 .main-content .about-event .information .icon {
    width: 30px;
    height: 30px;
    border-radius: 99rem;
    background: #f7af2f;
    display: flex;
    justify-content: center;
    align-items: center
}

.card-event-2 .main-content .about-event .information .icon i {
    color: #2b2d42;
    /* font-size: 2rem */
}

.card-event-2 .main-content .about-event .information h5 {
    width: 75%;
    color: #2b2d42;
    margin-left: 1rem
}

@media only screen and (max-width: 768px) {
    .card-event-2 .main-content .about-event .information h5 {
        /* font-size:1.25rem!important */
    }
}

.card-event-2 .img-card-event {
    -webkit-clip-path: polygon(34% 0,100% 0,100% 100%,0 100%);
    clip-path: polygon(34% 0,100% 0,100% 100%,0 100%);
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 45%;
    height: 100%;
    background: #fff;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
}

@media only screen and (max-width: 768px) {
    .card-event-2 .img-card-event {
        width:40%
    }
}

.card-event-2 .img-card-event img {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.page-download .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 50vh;
    background: #2b2d42
}

.page-download .main-header {
    height: 37.5vh;
    color: #fff
}

.fixed-header {
    position: relative;
    width: 100%;
    background: #f1df40;
    z-index: 99
}

.fixed-header .icon-close {
    position: absolute;
    top: 50%;
    right: 75px;
    font-weight: 700;
    transform: translateY(-50%)
}

.title-before {
    position: relative
}

.title-before:before {
    position: absolute;
    content: "";
    bottom: -5px;
    width: 35%;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    background: #f7af2f
}

.input-with-select div:first-child {
    display: flex!important
}

.input-with-select div:first-child .v-select {
    width: 100px;
    background: #f8f9fd;
    border-radius: 0!important;
    border-top-left-radius: 99rem!important;
    border-bottom-left-radius: 99rem!important
}

@media only screen and (max-width: 768px) {
    .input-with-select div:first-child .v-select {
        width:30%
    }
}

@media only screen and (max-width: 768px) {
    .input-with-select div:first-child .v-select .vs__dropdown-menu {
        display:none!important
    }
}

.input-with-select div:first-child .v-select .vs__dropdown-toggle {
    width: 100%;
    border-radius: 0!important;
    border-top-left-radius: 99rem!important;
    border-bottom-left-radius: 99rem!important;
    border-right: 0
}

.input-with-select div:first-child .v-select .vs__dropdown-toggle .vs__selected-options {
    margin-top: 3px
}

.input-with-select div:first-child .v-select .vs__dropdown-toggle .vs__search {
    position: absolute;
    top: 0
}

.input-with-select div:first-child .v-select input {
    width: 100%
}

.input-with-select div:first-child .form-control {
    border-radius: 0!important;
    border-top-right-radius: 99rem!important;
    border-bottom-right-radius: 99rem!important
}

.radio-with-card.custom-control {
    border: 1px solid rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 1rem
}

.item-left-right-between {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0,0,0,.07)
}

.fade-in {
    -webkit-animation: fadeIn 1.5s;
    animation: fadeIn 1.5s
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.h-100vh {
    height: 100vh!important
}

.h-25vh {
    height: 25vh!important
}

.h-50vh {
    height: 50vh!important
}

.h-75vh {
    height: 75vh!important
}

.gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: #fff;
    filter: contrast(20)
}

.gooey .dot {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 12px;
    left: 15px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
    transform: translateX(0);
    -webkit-animation: dot 2.8s infinite;
    animation: dot 2.8s infinite
}

.gooey .dots {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    -webkit-animation: dots 2.8s infinite;
    animation: dots 2.8s infinite
}

.gooey .dots span {
    display: block;
    float: left;
    width: 12px;
    height: 12px;
    margin-left: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%
}

@keyframes dot {
    50% {
        transform: translateX(96px)
    }
}

@keyframes dots {
    50% {
        transform: translateX(-31px)
    }
}

.outline-none {
    outline: none!important
}

#calendar {
    margin: 5rem 0
}

.list-calendar {
    margin-bottom: 4rem
}

.list-calendar .month-name {
    border-bottom: 1px solid rgba(0,0,0,.2);
    padding-bottom: 2rem;
    font-weight: 600
}

.list-calendar .no-data {
    margin: 2rem 0
}

.card-calendar {
    background: #f3f3f3;
    margin: 2rem 0;
    display: flex;
    min-height: 135px;
    border-radius: 1rem;
    overflow: hidden
}

.card-calendar.private {
    min-height: 175px!important
}

@media screen and (max-width: 768px) {
    .card-calendar.private {
        flex-direction:column
    }
}

.card-calendar.private .wrapper-partner {
    padding: 0 2rem;
    height: 175px;
    display: flex;
    justify-content: center!important;
    align-items: center!important
}

@media screen and (max-width: 768px) {
    .card-calendar.private .wrapper-partner {
        height:75px;
        justify-content: start!important;
        padding: 0 1rem
    }
}

.card-calendar.private .wrapper-partner .partner {
    transform: translateX(10px);
    position: relative;
    width: 150px;
    height: 100px;
    margin-right: 1.5rem!important
}

@media screen and (max-width: 768px) {
    .card-calendar.private .wrapper-partner .partner {
        width:100px;
        height: 50px
    }
}

.card-calendar.private .wrapper-partner .partner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: inherit;
    object-fit: inherit
}

.card-calendar .partner {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center
}

.card-calendar .partner img {
    width: 85px
}

.card-calendar .event {
    width: 100%;
    padding: 2rem 2rem!important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.card-calendar .event .left {
    color: #fff;
    width: 100%
}

.card-calendar .event .left h5 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px
}

@media screen and (max-width: 768px) {
    .card-calendar .event .left h5 {
        /* font-size:1.75rem!important */
    }
}

.card-calendar .event .left .date {
    margin: 1rem 0;
    max-width: 200px;
    display: flex;
    justify-content: space-between
}

.card-calendar .event .left .date .day {
    text-transform: uppercase
}

.card-calendar .event .left .date .datefull {
    text-transform: capitalize
}

.detail-product .card-product {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.detail-product .card-product .img {
    position: relative;
    width: 100%;
    height: 275px;
    background: #fff;
    border-radius: 1rem;
    margin-right: 1.5rem!important
}

.detail-product .card-product .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: unset;
    object-fit: unset;
    border-radius: 1rem
}

.detail-product .card-product .share-socmed {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.detail-product .card-product .share-socmed span {
    text-transform: uppercase;
    /* font-size: 1.5rem */
}

.detail-product .card-product .share-socmed .right {
    display: flex
}

.detail-product .card-product .share-socmed .right a {
    margin: 0 .5rem;
    color: rgba(0,0,0,.5);
    border: 1px solid rgba(0,0,0,.2);
    padding: .25rem .75rem;
    border-radius: 99rem
}

.detail-product .card-product .share-socmed .right a i {
    /* font-size: 1.75rem */
}

.detail-product .card-product .share-socmed .right>div {
    display: flex
}

.detail-product .card-product .right .brand {
    font-weight: 700
}

.detail-product .card-product .right .type {
    font-weight: 400;
    margin-top: .25rem
}

@media only screen and (max-width: 768px) {
    .detail-product .card-product .right .about-product {
        margin-bottom:1.75rem
    }
}

.detail-product .card-product .right .about-product .title-product p {
    letter-spacing: 1px;
    font-weight: 600;
    color: #000!important;
    text-transform: uppercase
}

.detail-product .card-product .right .about-product .desc {
    display: flex;
    align-items: center
}

.detail-product .card-product .right .about-product .desc .stars {
    margin-right: 1rem
}

.detail-product .card-product .right .about-product .desc .stars i {
    color: #f7af2f!important;
    /* font-size: 3rem */
}

.detail-product .card-product .right .about-product .option {
    display: flex;
    align-items: center;
    /* font-size: 1.25rem; */
    margin-right: 1rem
}

.detail-product .card-product .right .about-product .option>* {
    margin-right: .25rem
}

.detail-product .card-product .right .about-product .option .yes i {
    color: #95378e!important
}

.detail-product .card-product .right .about-product .option .no i {
    color: #dc0a24!important
}

.detail-product .card-product .right .about-brand {
    margin: 2rem 0;
    padding: 1rem;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem
}

.detail-product .card-product .right .about-brand .top {
    display: flex;
    justify-content: space-between
}

.detail-product .card-product .right .about-brand .brand-detail {
    display: flex;
    margin: 1rem 0;
    align-items: center
}

.detail-product .card-product .right .about-brand .brand-detail .left {
    margin-right: 1rem
}

.detail-product .card-product .right .about-brand .brand-detail .left img {
    width: 45px;
    height: 45px
}

.detail-product .card-product .right .about-brand .brand-detail .right {
    display: flex;
    flex-direction: column;
    /* font-size: 1.15rem */
}

.detail-product .card-product .right .about-brand .brand-detail .right .brand {
    /* font-size: 1.15rem */
}

.detail-product .card-product .right .about-brand .brand-detail .right>* {
    margin: .5rem 0
}

.community .card-calendar {
    margin: 0!important;
    min-height: 225px
}

.community .card-calendar .date {
    max-width: 250px!important
}

.community .card-calendar .date .province {
    font-weight: 600
}

.tags {
    display: flex;
    flex-wrap: wrap
}

.tags .tag-link {
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(0,0,0,.5);
    font-style: italic
}

@media only screen and (max-width: 768px) {
    .tags .tag-link {
        /* font-size:1.5rem!important */
    }
}

.tags .tag-link.active {
    color: #95378e;
    border-bottom: 2px solid #95378e
}

.tags .tag-link:hover {
    text-decoration: underline!important
}

.img-banner-articles {
    position: relative;
    width: 100%;
    height: 400px;
    background: #000
}

.img-banner-articles img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.profile-articles {
    display: flex;
    align-items: center
}

.profile-articles .img-user-mini {
    margin: 1rem 0;
    position: relative;
    width: 50px;
    height: 50px;
    background: #000;
    border-radius: 99rem;
    margin-right: 1rem
}

.profile-articles .img-user-mini img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: .5s;
    border-radius: 99rem
}

.profile-articles p {
    margin-top: .5rem
}

.profile-articles p .name {
    font-family: Cereal,sans-serif
}

.profile-articles p .date-publish {
    color: #a7a7a7
}

.ban-share .share-soc {
    display: flex
}

.ban-share .share-soc .icon-share {
    width: 40px!important;
    height: 40px!important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-bottom: 1rem;
    border-radius: 99rem
}

.ban-share .share-soc .icon-share i {
    /* font-size: 2rem; */
    width: 100%;
    color: #fff
}

.wrapper-notbanner .share-soc {
    display: flex
}

@media only screen and (min-width: 768px) {
    .wrapper-notbanner .share-soc {
        align-items:center
    }

    .wrapper-notbanner .share-soc span {
        margin-right: 1rem
    }
}

@media only screen and (max-width: 768px) {
    .wrapper-notbanner .share-soc {
        flex-direction:column
    }
}

.wrapper-notbanner .share-soc .icon-share {
    width: 35px;
    height: 35px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.wrapper-notbanner .share-soc .icon-share i {
    /* font-size: 2rem; */
    color: #fff
}

.bg-blue {
    background: #3b5897
}

.bg-blue-twitter {
    background: #07abee
}

.bg-purple-instagram {
    background: #95378e
}

.bg-red-pinterest {
    background: #dc0a24
}

.bg-darkblue-tumblr {
    background: #001a35
}

.bg-green {
    background: #24d366
}

.source {
    /* font-size: 1.5rem!important */
}

.point-article {
    margin-bottom: 10rem!important
}

.point-article .content-article {
    border-bottom: 1px solid rgba(0,0,0,.2);
    padding-bottom: 2rem;
    color: #555;
    font-weight: 400;
    /* font-family: Cereal-Light,sans-serif!important */
}

.point-article .content-article img {
    max-width: 100%!important
}

.point-article .content-article h1,.point-article .content-article h2,.point-article .content-article h3,.point-article .content-article h4,.point-article .content-article h5,.point-article .content-article h6,.point-article .content-article li,.point-article .content-article ol,.point-article .content-article ul {
    line-height: 3rem!important;
    color: #000
}

.point-article .content-article p img,.point-article .content-article p span img {
    width: 100%!important
}

.point-article .content-article p,.point-article .content-article span {
    line-height: 3rem!important;
    color: #000
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p,.point-article .content-article span {
        line-height:2.5rem!important
    }
}

.point-article .content-article p span,.point-article .content-article span span {
    line-height: 3rem!important
}

.point-article .content-article p span strong,.point-article .content-article span span strong {
    /* font-family: sans-serif */
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p span,.point-article .content-article span span {
        line-height:2.5rem!important
    }
}

.point-article .content-article p strong,.point-article .content-article span strong {
    /* font-family: sans-serif */
}

.point-article .content-article p img,.point-article .content-article span img {
    max-width: 100%!important
}

.point-article .content-article p a,.point-article .content-article span a {
    color: #f7af2f!important;
    text-decoration: underline!important;
    line-height: 3rem!important;
    color: #000
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p a,.point-article .content-article span a {
        line-height:2.5rem!important
    }
}

.point-article .content-article p a strong,.point-article .content-article span a strong {
    /* font-family: sans-serif */
}

.point-article .content-article p a span,.point-article .content-article span a span {
    color: #f7af2f!important
}

.point-article .content-article p a span strong,.point-article .content-article span a span strong {
    /* font-family: sans-serif */
}

.point-article .content-article p i,.point-article .content-article span i {
    line-height: 3rem!important;
    color: #000
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p i,.point-article .content-article span i {
        line-height:2.5rem!important
    }
}

.point-article .content-article p i a,.point-article .content-article span i a {
    color: #f7af2f!important;
    text-decoration: underline!important;
    line-height: 3rem!important;
    color: #000
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p i a,.point-article .content-article span i a {
        line-height:2.5rem!important
    }
}

.point-article .content-article p i a span,.point-article .content-article span i a span {
    color: #f7af2f!important;
    text-decoration: underline!important;
    line-height: 3rem!important;
    color: #000
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p i a span,.point-article .content-article span i a span {
        line-height:2.5rem!important
    }
}

.point-article .content-article p i a i,.point-article .content-article span i a i {
    color: #f7af2f!important;
    text-decoration: underline!important;
    line-height: 3rem!important;
    color: #000
}

@media only screen and (max-width: 768px) {
    .point-article .content-article p i a i,.point-article .content-article span i a i {
        line-height:2.5rem!important
    }
}

.point-article .content-article h1 {
    /* font-size: 4.5rem!important */
}

.point-article .content-article h1 span {
    /* font-size: 3rem!important */
}

.point-article .content-article h1 span strong,.point-article .content-article h1 strong {
    /* font-family: sans-serif */
}

.point-article .content-article h2,.point-article .content-article h2 span {
    /* font-size: 3rem!important */
}

.point-article .content-article h2 span strong,.point-article .content-article h2 strong {
    /* font-family: sans-serif */
}

.point-article .content-article h3,.point-article .content-article h3 span {
    /* font-size: 2.5rem!important */
}

.point-article .content-article h3 span strong,.point-article .content-article h3 strong {
    /* font-family: sans-serif */
}

.point-article .content-article h4,.point-article .content-article h4 span {
    /* font-size: 2.25rem!important */
}

.point-article .content-article h4 span strong,.point-article .content-article h4 strong {
    /* font-family: sans-serif */
}

.point-article .content-article dl,.point-article .content-article ol,.point-article .content-article ul {
    margin-left: 1.5rem;
    line-height: 2.5rem
}

.point-article .content-article dl span strong,.point-article .content-article ol span strong,.point-article .content-article ul span strong {
    /* font-family: sans-serif */
}

.point-article .right {
    position: sticky!important;
    top: 0!important
}

.wrapper_news {
    width: 100%!important;
    margin-bottom: 6rem;
    height: 60vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px
}

.wrapper_news .most {
    height: 70vh
}

.wrapper_news .most .card_news {
    height: 100%
}

.wrapper_news .most .card_news .featured {
    border-radius: .75rem
}

.wrapper_news .most .card_news .featured .category {
    margin-bottom: 1rem
}

.wrapper_news .most .card_news .featured .category .featured {
    border: 1px solid #fff
}

.wrapper_news .most .card_news .featured .category a {
    padding: .25rem .35rem;
    /* font-size: 1.35rem!important; */
    margin-right: .35rem;
    /* font-family: Cereal-Light,sans-serif */
}

.wrapper_news .most .card_news .featured .article_title {
    /* font-size: 1.5rem!important; */
    margin: 0
}

.wrapper_news .most .card_news .featured .article_title p,.wrapper_news .most .card_news .featured .article_title span {
    /* font-size: 1.5rem!important; */
    color: #fff!important
}

.wrapper_news .most .card_news .featured .article_description,.wrapper_news .most .card_news .featured .article_description span,.wrapper_news .most .card_news .featured .article_description span p {
    /* font-size: 1.25rem!important; */
    color: #dee2e6!important;
    /* font-family: Cereal-Light,sans-serif */
}

.wrapper_news .most .card_news .featured .information span {
    /* font-size: 1.25rem!important; */
    color: #dee2e6!important
}

.wrapper_news .featured {
    height: 100%;
    padding: 1.25rem;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: 50%!important;
    border-radius: .75rem
}

.wrapper_news .featured .category {
    margin-bottom: 1rem
}

.wrapper_news .featured .category .featured {
    border: 1px solid #fff
}

.wrapper_news .featured .category a {
    padding: .25rem .35rem;
    /* font-size: 1.35rem!important; */
    margin-right: .35rem;
    /* font-family: Cereal-Light,sans-serif */
}

.wrapper_news .featured .article_title {
    /* font-size: 2.5rem!important; */
    margin: 0
}

.wrapper_news .featured .article_title p,.wrapper_news .featured .article_title span {
    /* font-size: 2.5rem!important; */
    color: #fff!important
}

.wrapper_news .featured .article_description,.wrapper_news .featured .article_description span,.wrapper_news .featured .article_description span p {
    /* font-size: 1.35rem!important; */
    color: #dee2e6!important;
    /* font-family: Cereal-Light,sans-serif */
}

.wrapper_news .featured .information span {
    /* font-size: 1.35rem!important; */
    color: #dee2e6!important
}

.wrapper_news .most {
    height: 100%
}

.wrapper_news .most .card_news {
    height: 55%
}

.news-highlight .img-news {
    width: 100%;
    height: 500px;
    position: relative;
    border-radius: 1rem
}

@media only screen and (max-width: 768px) {
    .news-highlight .img-news {
        height:300px
    }
}

.news-highlight .img-news img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover!important;
    object-fit: cover!important;
    border-radius: 1rem
}

.news-highlight .title-news * {
    /* font-size: 4rem!important; */
    font-weight: 600;
    margin: 1.25rem 0;
    text-decoration: none;
    color: #2b2d42
}

@media only screen and (min-width: 768px) {
    .news-highlight .title-news * {
        line-height:5rem
    }
}

@media only screen and (max-width: 768px) {
    .news-highlight .title-news * {
        /* font-size:2.25rem!important */
    }
}

@media only screen and (max-width: 768px) {
    .register {
        padding:3rem 0 5rem 0!important
    }
}

#wrapper-auth {
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: 50%!important
}

.auth-wrapper {
    position: relative;
    min-height: 100vh!important;
    margin: 0!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 6rem;
    z-index: 2!important
}

.auth-wrapper.regist {
    min-height: 110vh!important;
    padding-top: 8rem
}

.auth-wrapper .left {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem
}

.auth-wrapper .left .title-auth {
    text-align: center
}

.auth-wrapper .left .title-auth h1 {
    font-weight: 700;
    /* font-size: 3rem!important */
}

@media only screen and (max-width: 768px) {
    .auth-wrapper .left .title-auth h1 {
        /* font-size:2.25rem!important */
    }
}

.auth-wrapper .left .title-auth p {
    color: rgba(0,0,0,.6)
}

.auth-wrapper .left .form-auth {
    margin-top: 3rem
}

.auth-wrapper .left .form-auth .submit-form {
    margin-top: 3rem;
    display: flex;
    justify-content: center
}

.auth-wrapper .left .form-auth .submit-form>div {
    margin-right: 1rem
}

.auth-wrapper .left .form-group {
    margin-bottom: 1.25rem
}

.auth-wrapper .left .form-group label {
    margin: 1rem 0
}

.auth-wrapper .left .form-group .form-control {
    border-radius: 99rem!important;
    padding: 0 2rem
}

.auth-wrapper .left .forgot {
    /* font-size: 1.5rem!important */
}

.auth-wrapper .left .radio-button {
    margin-right: 1rem!important
}

input.form-control {
    border-radius: 99rem!important;
    padding: 0 1.5rem!important
}

.container-fluid {
    width: 95%!important;
    margin: auto
}

.img-profile {
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 99rem
}

.img-profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem;
    border: 5px solid #fff
}

.img-profile .change-profile {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: #95378e;
    /* font-size: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99rem
}

.img-profile .change-profile i {
    color: #fff
}

.mainheader-private {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem;
    height: 350px;
    overflow: hidden
}

@media screen and (max-width: 768px) {
    .mainheader-private {
        height:210px!important;
        border-radius: 0;
        padding-bottom: 1rem
    }
}

.mainheader-private .top-img {
    position: relative;
    width: 100%;
    height: 200px;
    background: #fff;
    margin-right: 1.5rem!important;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem
}

@media screen and (max-width: 768px) {
    .mainheader-private .top-img {
        height:100px
    }
}

.mainheader-private .top-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem
}

.mainheader-private .detail-user {
    transform: translateX(50px) translateY(20px)
}

@media screen and (max-width: 768px) {
    .mainheader-private .detail-user {
        transform:translateX(30%) translateY(12%)
    }
}

.mainheader-private .detail-user .name {
    font-weight: 700;
    /* font-size: 1.75rem!important */
}

.mainheader-private .detail-user .email {
    color: rgba(0,0,0,.5)
}

.mainheader-private .detail-user .isVerified {
    display: flex
}

.mainheader-private .detail-user .isVerified p {
    margin-right: .5rem!important
}

.mainheader-private .detail-user .isVerified .unverified-text {
    color: #dc0a24
}

.mainheader-private .detail-user .isVerified i {
    /* font-size: 1.5rem; */
    font-weight: 700;
    display: inline;
    padding: .5rem;
    border-radius: 99rem;
    color: #fff
}

.mainheader-private .detail-user .isVerified i.verified {
    background: #25a550
}

.mainheader-private .detail-user .isVerified i.unverified {
    background: rgba(0,0,0,.2)
}

.nav-private {
    margin-top: 2rem!important;
    padding: 1rem;
    position: sticky!important;
    top: 0;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem
}

@media screen and (max-width: 768px) {
    .nav-private {
        border-radius:0
    }
}

.nav-private .link {
    margin-bottom: 1.25rem;
    margin-left: 1rem;
    padding: .5rem
}

.nav-private .link a {
    color: #000
}

.nav-private .link .active {
    padding: .5rem 1rem;
    background: linear-gradient(90deg,#dc0a24,#95378e);
    color: #fff;
    border-radius: .5rem
}

.nav-private .nav-item .router-link-exact-active {
    color: #95378e!important;
    border-bottom: 2px solid #95378e
}

.nav-private .nav-link {
    color: #000!important;
    margin-right: 1.25rem
}

.nav-private #nav-collapse-private {
    display: flex;
    justify-content: space-between
}

@media screen and (max-width: 768px) {
    .nav-private #nav-collapse-private {
        flex-direction:column;
        align-items: start!important;
        padding: 1rem
    }
}

.content-private {
    margin-top: 2rem!important;
    padding: 2rem 2rem 2rem 2rem;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 1rem;
    min-height: 350px
}

@media screen and (max-width: 768px) {
    .content-private {
        border-radius:0;
        margin-top: 0!important;
        border: none
    }
}

.content-private .header-content-private {
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 1rem
}

.content-private .header-content-private .title-content {
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase
}

@media screen and (max-width: 768px) {
    .content-private .header-content-private .title-content {
        /* font-size:2rem!important */
    }
}

.content-private .content {
    margin-top: 2rem
}

.content-private .menu-higlight {
    display: flex;
    align-items: center;
    margin: .5rem 0
}

@media screen and (max-width: 768px) {
    .content-private .menu-higlight {
        padding:1rem 0
    }
}

.content-private .menu-higlight i {
    /* font-size: 2rem; */
    margin-right: 1rem
}

.content-private .menu-higlight span {
    /* font-size: 1.25rem */
}

.content-private .menu-higlight span a {
    color: #000
}

@media screen and (max-width: 768px) {
    .content-private .menu-higlight span a {
        /* font-size:1.5rem!important */
    }
}

.content-private .img-profile {
    position: relative;
    width: 200px;
    height: 200px;
    background: #fff;
    border-radius: 99rem;
    margin-right: 2rem!important
}

.content-private .img-profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 99rem;
    border: 5px solid #fff
}

.content-private .img-profile i {
    position: absolute;
    /* font-size: 2rem!important; */
    padding: .75rem;
    background: #fff;
    border-radius: 99rem;
    bottom: 15px;
    right: 15px
}

.content-private .form-control {
    border-radius: 99rem!important;
    padding: .5rem 2rem
}

.content-private .input-group .form-control {
    padding: .5rem 2rem
}

.content-private .custom-select {
    width: 100%;
    border: 1px solid rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 99rem!important
}

.input-group .form-control {
    border-radius: 0!important;
    padding: .5rem 2rem;
    border-top-right-radius: 99rem!important;
    border-bottom-right-radius: 99rem!important
}

.input-group .input-group-text {
    height: 100%!important
}

.input-group .input-group-text:first-child {
    border-top-left-radius: 99rem;
    border-bottom-left-radius: 99rem
}

.info-setup {
    padding: .5rem 1rem;
    margin-bottom: 2rem;
    border-radius: .5rem;
    display: flex;
    align-items: center
}

.info-setup i {
    /* font-size: 3rem; */
    color: #fff;
    margin-right: 1rem
}

.info-setup.warning {
    background: #f7af2f
}

.info-setup p {
    color: #fff;
    font-weight: 600
}

.info-setup span {
    text-decoration: underline
}

.info-setup span a {
    color: #fff
}

.sec-form {
    margin: 1.5rem 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 2rem
}

.sec-form.no-padding {
    padding: 0!important
}

.sec-form.no-border {
    border: 0!important
}

.sec-form .title-form {
    font-weight: 600;
    text-transform: uppercase
}

.wrapper-notbanner.private {
    border: none!important
}

.navbar-toggler i {
    color: #000!important
}

.card_list {
    display: flex;
    width: 100%!important;
    margin-bottom: 1rem!important;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 1rem 0
}

.card_list .logo_partner {
    margin-right: 1.5rem!important
}

.card_list .logo_partner img {
    width: 75px
}

.card_list .about_event {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

@media screen and (max-width: 768px) {
    .card_list .about_event {
        display:block
    }
}

.card_list .about_event .name_event {
    font-weight: 700;
    /* font-size: 1.75rem!important */
}

.card_list .about_event .date,.card_list .about_event .distance,.card_list .about_event .program {
    color: #495057
}

.card_list .about_event .date {
    margin-right: 2rem
}

.filter .form-control {
    border: 0!important
}

.left_sec {
    border: 1px solid rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 1rem;
    position: sticky!important
}

.left_sec #wave {
    position: relative;
    height: 70px;
    width: 600px;
    background: #e0efe3
}

.left_sec #wave:before {
    width: 340px;
    height: 80px;
    background-color: #fff;
    right: -5px;
    top: 40px
}

.left_sec #wave:after,.left_sec #wave:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%
}

.left_sec #wave:after {
    width: 300px;
    height: 70px;
    background-color: #e0efe3;
    left: 0;
    top: 27px
}

#app {
    overflow: hidden
}

</style>
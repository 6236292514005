<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="row">
            <div class="col-md-6">
              <div class="b-grey-black d-flex align-items-center px-4 py-4 bdrd-12px">
                <div class="w-25">
                  <img :src="`${baseUrl}/assets/images/static-icon/review-icon.png`" alt="" class="w-100" />
                </div>
                <div class="mx-3 w-100">
                  <div>
                    <p class="mb-0">Total Review</p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span class="bold ls-0">{{ (myReviewContentData || {}).total_reviewed_race }} Race Reviewed</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-4 mb-4">
              <b-tabs class="capsul">
                <b-tab title="To Be Reviewed" active>
                  <div class="row" v-if="((myReviewContentData || {}).to_be_reviewed_race || []).length > 0">
                    <div class="col-md-12 mb-4" v-for="(v,k) in (myReviewContentData || {}).to_be_reviewed_race" :key="k">
                      <div class="card-registered-event b-grey-black px-4 py-4 bdrd-12px">
                        <div class="d-flex justify-content-between bb-1px-grey pb-4">
                          <div class="d-flex align-items-center">
                            <picture class="img-small-fr">
                              <source
                                :src="uploader(v.trv_preview_img)"
                                type="image/webp"
                              />
                              <img
                                :src="uploader(v.trv_preview_fallback_img)"
                                :alt="v.trv_preview_alt_img"
                                class="img-small-fr"
                              />
                            </picture>
                            <div class="mx-3">
                              <p class="bold text-dark fs-125em mb-0">{{ v.trv_event_name }}</p>
                              <span class="grey-color regular">{{ v.trv_itinerary_name }}, {{ v.trv_package_name }} Package</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="pt-2 d-flex"
                          :class="isOnlyMobile() ? 'flex-column' : 'justify-content-between align-items-end'"
                        >
                          <div class="d-flex flex-column">
                            <p class="mb-0 text-dark fs-125em semibold">{{ v.trv_full_name }}</p>
                            <span class="grey-color regular">Order Date: {{ formatDateWithHour(v.trv_order_date) }}</span>
                          </div>
                          <div class="d-flex flex-column">
                            <div class="d-flex mt-2">
                              <button
                                @click="
                                  $router.push({ name: 'FormReview', params: { pageSlug: v.trv_transaction_id } })
                                "
                                class="btn btn-primary mb-0 text-white medium d-flex align-self-start mt-3 align-items-center"
                                :class="isMobile() ? 'w-100 justify-content-center' : ''"
                              >
                                Write a Review
                                <i class="fa fa-pencil-alt ml-2"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-12 d-flex flex-column align-items-center bt-1px-grey pt-4">
                      <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-25 mb-3" />
                      <h5 class="ls-0">No Event To Be Reviewed Yet</h5>
                      <router-link :to="{ name: 'RunningEvents' }" class="btn btn-primary mt-3"
                        >Join an Event</router-link
                      >
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Reviewed">
                  <div class="row" v-if="((myReviewContentData || {}).reviewed_race || []).length > 0">
                    <div class="col-md-12 mb-4" v-for="(v,k) in (myReviewContentData || {}).reviewed_race" :key="k">
                      <div class="card-registered-event b-grey-black px-4 py-4 bdrd-12px">
                        <div class="d-flex justify-content-between bb-1px-grey pb-4">
                          <div class="d-flex align-items-center">
                            <picture class="img-small-fr">
                              <source
                                :src="uploader(v.trv_preview_img)"
                                type="image/webp"
                              />
                              <img
                                :src="uploader(v.trv_preview_fallback_img)"
                                :alt="v.trv_preview_alt_img"
                                class="img-small-fr"
                              />
                            </picture>
                            <div class="mx-3">
                              <p class="bold text-dark fs-125em mb-0">{{ v.trv_event_name }}</p>
                              <span class="grey-color regular">{{ v.trv_itinerary_name }}, {{ v.trv_package_name }} Package</span>
                            </div>
                          </div>
                          <div>
                            <div class="d-flex" v-for="">
                              <i class="fa fa-star yellow-color px-1" v-for="n in v.trv_overall_ratings" :key="n"></i>
                              <i class="fa fa-star light-grey-color px-1" v-for="n1 in greyStarTotal(v.trv_overall_ratings)" :key="n1"></i>
                              <span class="ml-2 grey-color regular">{{ v.trv_overall_ratings }}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="pt-2 d-flex"
                          :class="isOnlyMobile() ? 'flex-column' : 'justify-content-between align-items-end'"
                        >
                          <div class="d-flex flex-column">
                            <p class="mb-0 text-dark fs-125em semibold">{{ v.trv_full_name }}</p>
                            <span class="grey-color regular">Order Date: {{ formatDateWithHour(v.trv_order_date) }}</span>
                          </div>
                          <div class="d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-between mt-4">
                              <router-link
                                :to="{ name: 'FormReview', params: { pageSlug: v.trv_transaction_id } }"
                                class="btn btn-primary mb-0 text-white medium d-flex align-self-start align-items-center"
                              >
                                Edit my review
                                <i class="fa fa-pencil-alt ml-2"></i>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-12 d-flex flex-column align-items-center bt-1px-grey pt-4">
                      <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-25 mb-3" />
                      <h5 class="ls-0">No Review Yet</h5>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <PPHelpCenter/>
          </div>
        </div>
      </div>
    </template>

    <template #page-title>
      <div class="d-flex flex-column justify-content-center">
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">My Reviews</h2>
      </div>
    </template>

    <template #content-mobile>
      <div class="row">
        <div class="col-md-6">
          <div class="b-grey-black d-flex align-items-center mt-3 px-4 py-4 bdrd-12px">
            <div class="w-25">
              <img :src="`${baseUrl}/assets/images/static-icon/review-icon.png`" alt="" class="w-100" />
            </div>
            <div class="mx-3 w-100">
              <div>
                <p class="mb-0">Total Review</p>
              </div>
              <div class="d-flex justify-content-between">
                <span class="bold ls-0">{{ (myReviewContentData || {}).total_reviewed_race }} Race Reviewed</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3 mb-4">
          <b-tabs class="capsul">
            <b-tab title="To Be Reviewed" active>
              <div class="row" v-if="((myReviewContentData || {}).to_be_reviewed_race || []).length > 0">
                <div class="col-md-12 mb-4" v-for="(v,k) in (myReviewContentData || {}).to_be_reviewed_race" :key="k">
                  <div class="card-registered-event b-grey-black px-4 py-4 bdrd-12px">
                    <div class="d-flex justify-content-between bb-1px-grey pb-4">
                      <div class="d-flex align-items-center">
                        <picture class="img-small-fr">
                          <source
                            :src="uploader(v.trv_preview_img)"
                            type="image/webp"
                          />
                          <img
                            :src="uploader(v.trv_preview_fallback_img)"
                            :alt="v.trv_preview_alt_img"
                            class="img-small-fr"
                          />
                        </picture>
                        <div class="mx-3">
                          <p class="bold text-dark fs-125em mb-0">{{ v.trv_event_name }}</p>
                          <span class="grey-color regular">{{ v.trv_itinerary_name }}, {{ v.trv_package_name }} Package</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="pt-2 d-flex"
                      :class="isOnlyMobile() ? 'flex-column' : 'justify-content-between align-items-end'"
                    >
                      <div class="d-flex flex-column">
                        <p class="mb-0 text-dark fs-125em semibold">{{ v.trv_full_name }}</p>
                        <span class="grey-color regular">Order Date: {{ formatDateWithHour(v.trv_order_date) }}</span>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="d-flex mt-2">
                          <router-link
                            :to="{ name: 'FormReview', params: { pageSlug: v.trv_transaction_id } }"
                            class="btn btn-primary mb-0 text-white medium d-flex align-self-start mt-3 align-items-center"
                            :class="isMobile() ? 'w-100 justify-content-center' : ''"
                          >
                            Write a Review
                            <i class="fa fa-pencil-alt ml-2"></i>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-12 d-flex flex-column align-items-center bt-1px-grey pt-4">
                  <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-45 mb-3" />
                  <h5 class="ls-0">No Review Yet</h5>
                </div>
              </div>
            </b-tab>
            <b-tab title="Reviewed">
              <div class="row" v-if="((myReviewContentData || {}).reviewed_race || []).length > 0">
                <div class="col-md-12 mb-4" v-for="(v,k) in (myReviewContentData || {}).reviewed_race" :key="k">
                  <div class="card-registered-event b-grey-black px-4 py-4 bdrd-12px">
                    <div class="d-flex justify-content-between bb-1px-grey pb-4">
                      <div class="d-flex align-items-center">
                        <picture class="img-small-fr">
                          <source
                            :src="uploader(v.trv_preview_img)"
                            type="image/webp"
                          />
                          <img
                            :src="uploader(v.trv_preview_fallback_img)"
                            :alt="v.trv_preview_alt_img"
                            class="img-small-fr"
                          />
                        </picture>
                        <div class="mx-3">
                          <p class="bold text-dark fs-125em mb-0">{{ v.trv_event_name }}</p>
                          <span class="grey-color regular">{{ v.trv_itinerary_name }}, {{ v.trv_package_name }} Package</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="pt-2 d-flex"
                      :class="isOnlyMobile() ? 'flex-column' : 'justify-content-between align-items-end'"
                    >
                      <div class="d-flex flex-column">
                        <p class="mb-0 text-dark fs-125em semibold">{{ v.trv_full_name }}</p>
                        <span class="grey-color regular">Order Date: {{ formatDateWithHour(v.trv_order_date) }}</span>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="d-flex align-items-center justify-content-between mt-4">
                          <div class="d-flex">
                            <i class="fa fa-star yellow-color" v-for="n in v.trv_overall_ratings" :key="n"></i>
                            <i class="fa fa-star light-grey-color" v-for="n1 in greyStarTotal(v.trv_overall_ratings)" :key="n1"></i>
                          </div>
                          <router-link
                            :to="{ name: 'FormReview', params: { pageSlug: v.trv_transaction_id } }"
                            class="mb-0 text-link medium d-flex align-self-start align-items-center"
                          >
                            Edit my review
                            <i class="fa fa-pencil-alt ml-2"></i>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-12 d-flex flex-column align-items-center bt-1px-grey pt-4">
                  <img :src="`${baseUrl}/assets/images/vector/empty-vect.svg`" class="w-45 mb-3" />
                  <h5 class="ls-0">No Review Yet</h5>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </template>
  </LayoutPrivatePage>
</template>

<script>
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import PPHelpCenter from '@/components/PPHelpCenter'
import Global from '@/libs/Global'

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

export default {
  extends: Global,
  name: 'MyReviews',
  components: {
    LayoutPrivatePage,
    PPHelpCenter
  },
  computed: {
    ...mapFields("useMyReviewStore", [
      "myReviewContentData"
    ])
  },
  created() {
    this.getMyReviewContentData()
    this.$store.commit('SET_STATIC_SEO', {
      title: 'My Reviews'
    })
  },
  methods: {
    ...mapActions("useMyReviewStore", [
      "getMyReviewContentData"
    ]),

    greyStarTotal(ratings){
      return 5 - ratings;
    }
  }
}
</script>

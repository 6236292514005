<template>
  <!-- <div> -->
  <header
    id="header"
    data-transparent="true"
    data-fullwidth="true"
    class="submenu-light"
    :class="[!isOpenMenu && isMobile() && isPrivatePage ? 'sticky-non-active' : '']"
  >
    <div class="header-inner border-bottom-1px">
      <div class="container">
        <!--Logo-->
        <div id="logo" :class="!isMobile() ? 'border-right-1px' : ''">
          <router-link :to="{ name: 'Home' }" class="mr-5">
            <picture v-if="isOpenMenu">
              <source
                media="(min-width: 992px)"
                :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo.webp`"
                type="image/webp"
              />
              <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo.webp`" type="image/webp" />
              <img
                class="brand-logo"
                v-if="isOnlyMobile()"
                :src="`${baseUrl}/assets/images/rundeglobe_logo.png`"
                alt="RunDeGlobe Logo"
                width="142"
                :height="!isMobile() ? '43' : '50'"
              />
              <img
                class="brand-logo"
                v-else
                :src="`${baseUrl}/assets/images/rundeglobe@2x_logo.png`"
                alt="RunDeGlobe Logo"
                width="175"
                height="53"
              />
            </picture>
            <picture v-else>
              <source
                media="(min-width: 992px)"
                :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                type="image/webp"
              />
              <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
              <img
                class="brand-logo"
                v-if="isOnlyMobile()"
                :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                alt="RunDeGlobe Logo"
                width="142"
                height="43"
              />
              <img
                class="brand-logo"
                v-else
                :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                alt="RunDeGlobe Logo"
                width="175"
                height="53"
              />
            </picture>
          </router-link>
        </div>
        <!--End: Logo-->
        <!-- Search -->
        <div id="search" class="text-start">
          <template v-if="!isMobile()">
            <a id="btn-search-close" href="#" class="btn-search-close" aria-label="Close search form" @click="openSearchSug"
              ><i class="icon-x"></i
            ></a>
            <div class="container">
              <div class="row justify-content-center">
                <div class="wrapper-search-suggestion w-75">
                  <div class="col-md-12">
                    <div>
                      <form class="search-form" @submit.prevent="doSearch">
                        <input
                          class="form-control fs-125em"
                          name="q"
                          v-model="inputSearch"
                          type="search"
                          placeholder="Search for running events, blogs, or keywords..."
                        />
                        <span class="text-muted">Start typing & press "Enter"</span>
                      </form>
                    </div>
                  </div>
                  <template v-if="(resultBlog || []).length == 0 && (resultRunEvents || []).length == 0">
                    <div class="col-md-12 mt-4">
                      <div>
                        <h5 class="semibold grey-color ls-0">I'm searching for...</h5>
                        <div class="tags mt-3">
                          <a :href="$router.resolve({name: 'RunningEvents'}).href">Running Events</a>
                          <a :href="$router.resolve({name: 'Blog'}).href">Blog</a>
                          <a :href="$router.resolve({name: 'HowWeWork'}).href">How We Work</a>
                          <a :href="$router.resolve({name: 'Gallery'}).href">Gallery</a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-md-12 mt-4">
                      <div>
                        <h5 class="semibold grey-color ls-0">Showing results for "{{ inputSearch }}"</h5>
                        <div>
                          <div v-for="(v, k) in resultRunEvents" class="blog-pp d-flex d-flex mt-3 bb-1px-grey pb-4">
                          <div class="img-src">
                            <picture>
                              <source :srcset="uploader(v.image)" type="image/webp">
                              <img :src="uploader(v.fallback_image)" :alt="v.alt_image" />
                            </picture>
                          </div>
                          <div class="caption-blog w-75">
                            <h5 class="bold ls-0 mb-0 fs-115em mt-3 mb-2">{{ v.name }}</h5>
                            <div class="d-flex flex-wrap mb-1">
                              <!-- <div class="d-flex align-items-center my-2">
                                <i class="fa fa-ticket-alt gamma-color fs-1em"></i
                                ><a href=""><span class="mx-2 gamma-color semibold">30 Slots Left</span></a>
                              </div> -->
                              <div class="d-flex align-items-center mx-2 my-2">
                                <i class="fa fa-calendar gamma-color fs-1em"></i
                                ><a href="#"><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(v.race_date) }}</span></a>
                              </div>
                              <div class="d-flex align-items-center my-2">
                                <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                                <span class="mx-2 gamma-color semibold">{{ v.program }}, {{ v.country }}</span>
                              </div>
                            </div>
                            <span class="grey-color regular"
                              >{{ v.excerpt }}</span
                            ><br />
                            <a :href="$router.resolve({name: 'RunningEventDetails', params: {pageSlug: v.slug}}).href" class="btn btn-primary bold mt-3">Read More</a>
                          </div>
                        </div>
                        </div>
                        <div>
                          <div v-for="(v, k) in resultBlog" class="blog-pp d-flex d-flex mt-3 bb-1px-grey pb-4">
                            <div class="img-src">
                              <picture>
                                <source :srcset="uploader(v.image)" type="image/webp">
                                <img :src="uploader(v.fallback_image)" :alt="v.alt_image" />
                              </picture>
                            </div>
                            <div class="caption-blog w-75">
                              <span class="grey-color fs-085">By {{ v.author_name }} | {{ formatDateWithoutWeekday(v.published_date) }}</span>
                              <h5 class="bold ls-0 mb-0 fs-115em mt-3 mb-2">{{ v.title }}</h5>
                              <span class="grey-color regular"
                                >{{ v.excerpt }}</span
                              ><br />
                              <a :href="$router.resolve({name: 'BlogDetail', params: {pageSlug: v.slug}}).href" class="btn btn-primary bold mt-3">Read More</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="isLoading">
                    <div class="col-md-12 text-center">
                      <PulseLoader :loading="true" color="#333" size="14px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="wrapper-search-suggestion w-100 mx-3">
              <form ref="SuggestionSearchForm" @submit.prevent>
                <div class="row justify-content-center">
                  <div class="col-9 col-md-10 mr-md-3">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="inputSearch"
                        class="form-control main-control bg-white b-grey-black w-100"
                        placeholder="e.g. Search for RunDeGlobe"
                        @input="doSearch"
                      />
                    </div>
                  </div>
                  <div class="col-3 col-md pb-2 d-flex align-items-center" @click="openSearchSug">
                    <span class="text-link">Close</span>
                  </div>
                  <template v-if="(resultBlog || []).length == 0 && (resultRunEvents || []).length == 0">
                    <div class="col-md-12 mt-2">
                      <div>
                        <h5 class="semibold grey-color ls-0">I'm searching for...</h5>
                        <div data-v-786576c6="" class="tags mt-3">
                          <a :href="$router.resolve({name: 'RunningEvents'}).href">Running Events</a>
                          <a :href="$router.resolve({name: 'Blog'}).href">Blog</a>
                          <a :href="$router.resolve({name: 'HowWeWork'}).href">How We Work</a>
                          <a :href="$router.resolve({name: 'Gallery'}).href">Gallery</a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-md-12 mt-2">
                      <div>
                        <h5 class="semibold grey-color ls-0">Showing results for "{{ inputSearch }}"</h5>
                        <div>
                          <div v-for="(v, k) in resultRunEvents" class="card-search-suggestion d-flex mt-4 bb-1px-grey pb-4">
                            <div class="img-src img-search-result mr-3">
                              <picture>
                                <source :srcset="uploader(v.image)" type="image/webp">
                                <img :src="uploader(v.fallback_image)" :alt="v.alt_image" />
                              </picture>
                            </div>
                            <div class="caption-blog w-75">
                              <h5 class="bold ls-0 mb-0 fs-115em">{{ v.name }}</h5>
                              <div class="d-flex flex-wrap mb-1 mt-2">
                                <span class="medium fs-085em grey-color">Running Event - {{ v.program }}</span>
                              </div>
                              <a :href="$router.resolve({name: 'RunningEventDetails', params: {pageSlug: v.slug}}).href" class="text-link bold mt-4">Read More</a>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div v-for="(v, k) in resultBlog" class="card-search-suggestion d-flex mt-4 bb-1px-grey pb-4">
                            <div class="img-src img-search-result mr-3">
                              <picture>
                                <source :srcset="uploader(v.image)" type="image/webp">
                                <img :src="uploader(v.fallback_image)" :alt="v.alt_image" />
                              </picture>
                            </div>
                            <div class="caption-blog w-75">
                              <h5 class="bold ls-0 mb-0 fs-115em">{{ v.title }}</h5>
                              <div class="d-flex flex-wrap mb-1 mt-2">
                                <span class="medium fs-085em grey-color">Blog - {{ v.category }}</span>
                              </div>
                              <a :href="$router.resolve({name: 'BlogDetail', params: {pageSlug: v.slug}}).href" class="text-link bold mt-4">Read More</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="isLoading">
                    <div class="col-md-12 text-center">
                      <PulseLoader :loading="true" color="#333" size="14px" />
                    </div>
                  </template>
                </div>
              </form>
            </div>
          </template>
        </div>
        <!-- end: search -->
        <!--Header Extras-->
        <div class="header-extras ml-3" v-if="isDesktop()">
          <ul class="d-flex align-items-center">
            <li>
              <!-- <router-link v-if="!authenticated && !isPrivatePage" id="mainCta" :to="{ name: 'Register' }" class="btn extrabold"
                >Sign In Now</router-link
              > -->
              <a :href="`${baseUrlAthletes}/oauth/authorize?client_id=${athletesClientId}&response_type=code&redirect_uri=${baseUrl}/oauth/cb/athletescallback`" v-if="!authenticated && !isPrivatePage" id="mainCta" class="btn extrabold"
                >Sign In Now</a
              >
              <router-link v-else :to="{ name: 'Dashboard' }" class="btn rounded-circle extrabold p-1">
                <picture>
                  <source :srcset="uploader()" type="image/webp">
                  <img
                    :src="`${baseUrl}/assets/images/profile.png`"
                    alt="Profile User"
                    class="img-profile-header"
                  />
                </picture>
              </router-link>
            </li>
          </ul>
        </div>

        <!--Navigation Resposnive Trigger-->
        <div id="mainMenu-trigger" class="d-flex align-items-center">
          <template v-if="childPrivatePage.includes($route.name)">
            <a class="lines-button x mx-3" href="#" @click="$refs['modal-help'].show()">
              <i class="icon-help-circle help-icon fs-15em mb-2">
                <span>Help</span>
              </i>
            </a>
            <a class="lines-button x" href="#" @click="$router.push({ name: 'Notification' })">
              <i class="icon-bell fs-15em mb-2 me-3 position-relative">
                <div v-if="dashboard_unread_updates > 0" class="small-bullet-number"></div>
              </i>
            </a>
            <a class="lines-button x" href="#" @click="openMenuModal"><span class="lines"></span></a>
          </template>
          <template v-else>
            <a v-if="!authenticated" :href="`${baseUrlAthletes}/oauth/authorize?client_id=${athletesClientId}&response_type=code&redirect_uri=${baseUrl}/oauth/cb/athletescallback`" class="btn btn-header-cust extrabold"
              >Sign In</a
            >
            <a id="btn-search" class="mx-3" href="#">
              <i
                class="icon-search fs-15em mt-4"
                :class="isOpenMenu ? 'text-dark' : 'text-white'"
                @click="openSearchSug"
              ></i
            ></a>
            <a href="#" class="lines-button x" @click="openMenuModal"><span class="lines"></span></a>
          </template>
        </div>
        <!--end: Navigation Resposnive Trigger-->
        <!--Navigation-->
        <div id="mainMenu">
          <div class="container">
            <div class="d-flex w-auto">
              <nav
                class="menu-nav d-flex justify-content-center float-left border-right-1px px-2"
                :class="!isMobile() ? 'w-94' : ''"
                v-if="!isPrivatePage && !isDocument"
              >
                <ul>
                  <li>
                    <router-link
                      :to="{ name: 'RunningEvents' }"
                      class="extrabold nav__item mt-3"
                      :class="isMobile() ? '' : 'fs-085em'"
                      >Run With Us</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'Blog' }"
                      class="extrabold nav__item mt-3"
                      :class="isMobile() ? '' : 'fs-085em'"
                      >Blog</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'RunnerSays' }"
                      class="extrabold nav__item mt-3"
                      :class="isMobile() ? '' : 'fs-085em'"
                      >Runner Says</router-link
                    >
                  </li>
                  <li class="dropdown" :class="isMobile() ? 'hover-active' : ''">
                    <a
                      href="javascript:;"
                      class="extrabold nav__item mt-3 dropdown-parent"
                      :class="isMobile() ? '' : 'fs-085em'"
                      >Learn About RunDeGlobe <i class="icon-chevron-down fs-1em mt-4"></i
                    ></a>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link :to="{ name: 'About' }" class="extrabold text-uppercase"
                          >Learn About Us</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'HowWeWork' }" class="extrabold text-uppercase"
                          >How We Work</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'Destination' }" class="extrabold text-uppercase"
                          >Where We Go</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'Gallery' }" class="extrabold text-uppercase"
                          >Browse Our Gallery</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'BookingAndPrices' }" class="extrabold text-uppercase"
                          >See Booking & Prices</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'FAQ' }"
                          class="extrabold text-uppercase"
                          >Browse Our FAQ</router-link
                        >
                      </li>
                      <li v-if="showWorkWithUs()">
                        <router-link :to="{ name: 'ContactUs' }" class="extrabold text-uppercase"
                          >Contact Us</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <template v-if="showWorkWithUs()">
                    <li class="dropdown" :class="isMobile() ? 'hover-active' : ''">
                      <a href="javascript:;" class="extrabold mt-3 dropdown-parent" :class="isMobile() ? '' : 'fs-085em'"
                        >Work With Us <i class="icon-chevron-down fs-1em mt-4"></i
                      ></a>
                      <ul class="dropdown-menu">
                        <li v-if="setting.tour_guide === 'Y'">
                          <router-link :to="{ name: 'WWULPTourGuide' }" class="extrabold text-uppercase"
                            >Apply As A Tour Guide</router-link
                          >
                        </li>
                        <li v-if="setting.hotel === 'Y'"> 
                          <router-link :to="{ name: 'WWULPHotels' }" class="extrabold text-uppercase"
                            >Become Hotel Partner</router-link
                          >
                        </li>
                        <li v-if="setting.join_the_team === 'Y'">
                          <router-link :to="{ name: 'WWULPJoinTheTeam' }" class="extrabold text-uppercase"
                            >Join The Team</router-link
                          >
                        </li>
                      </ul>
                    </li>
                  </template>
                  <template v-else>
                    <li>
                      <router-link
                        :to="{ name: 'ContactUs' }"
                        class="extrabold nav__item mt-3"
                        :class="isMobile() ? '' : 'fs-085em'"
                        >Contact Us</router-link
                      >
                    </li>
                  </template>
                </ul>
              </nav>
              <nav
                class="menu-nav d-flex justify-content-end float-left border-right-1px px-2"
                :class="!isMobile() ? 'w-94' : ''"
                v-else-if="isPrivatePage && !isDocument"
              >
                <ul>
                  <li>
                    <button
                      @click="$router.push({ name: 'Home' })"
                      class="btn btn-secondary-black text-dark extrabold mr-2 px-4 py-3"
                      :class="isMobile() ? '' : 'fs-085em'"
                    >
                      Go To Main Site
                    </button>
                  </li>
                  <li>
                    <a :href="`${baseUrlAthletes}/user/profile-information`" 
                      class="btn btn-secondary-black text-dark extrabold mr-2 px-4 py-3"
                      :class="isMobile() ? '' : 'fs-085em'"
                    >
                      My Account
                    </a>
                  </li>
                  <li class="relative">
                    <a class="lines-button x" href="#" @click="showNotification">
                      <i class="icon-bell fs-15em mb-2 pt-3 mr-3 position-relative">
                        <div v-if="dashboard_unread_updates > 0" class="small-bullet-number pt"></div>
                      </i>
                    </a>
                    <div id="dropdown-notification" class="dropdown-notification pt-4">
                      <div class="row m-0">
                        <div class="col-md-12 px-0">
                          <b-tabs class="capsul two-tab">
                            <b-tab active>
                              <template #title>
                                <div class="wrap-tab d-flex justify-content-center align-items-center">
                                  <span>Updates</span>
                                  <div v-if="dashboard_unread_updates > 0" class="bullet-number ml-1">
                                    <span>{{ dashboard_unread_updates }}</span>
                                  </div>
                                </div>
                              </template>
                              <template v-if="updates.length > 0">
                                <div class="wrapper-notification-list mt-3">
                                  <div
                                    v-for="(v, k) in updates" 
                                    :key="'info-list-'+k"
                                    class="card-notification pointer bt-1px-grey"
                                    @click="
                                      $router.push({
                                        name: 'DetailNotification',
                                        params: { pageSlug: v.slug },
                                      })
                                    "
                                  >
                                    <div class="card-notification-list px-4 py-4" :class="v.is_read == null ? 'new' : ''">
                                      <picture>
                                        <source :srcset="uploader(v.image)" type="image/webp">
                                        <img :src="uploader(v.fallback_image)" :alt="v.title" class="w-100 mb-3" />
                                      </picture>
                                      <p class="bold text-dark mb-1 fs-1em">
                                        {{ v.title }}
                                      </p>
                                      <span v-html="v.description" class="regular grey-color"></span>
                                      <p class="mt-3 mb-0">Promo - {{ formatDateWithoutWeekday(v.published_date) }}</p>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="relative p-4 mt-3 mb-1">
                                  <div class="initial-content d-flex flex-column align-items-center">
                                    <div class="wrapper-illustration w-50 d-flex justify-content-center">
                                      <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="goal" class="w-25" />
                                    </div>
                                    <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No Update Yet</h2>
                                    <p class="text-center">Stay connected for more interesting update</p>
                                  </div>
                                </div>
                              </template>
                            </b-tab>
                          </b-tabs>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>

              <nav
                class="menu-nav d-flex justify-content-end float-left border-right-1px px-2"
                :class="!isMobile() ? '4' : ''"
                v-else-if="isDocument"
              >
                <ul>
                  <li>
                    <button
                      @click="$router.push({ name: 'Home' })"
                      class="btn btn-secondary-black text-dark extrabold mr-2 px-4 py-3"
                      :class="isMobile() ? '' : 'fs-085em'"
                    >
                      Go To Main Site
                    </button>
                  </li>
                  <slot name="button-custom-document"></slot>
                </ul>
              </nav>

              <nav v-if="!isMobile()" class="w-6 d-flex justify-content-center align-items-center border-right-1px">
                <a id="btn-search" href="#">
                  <i
                    class="icon-search fs-125em mt-4"
                    :class="isOpenMenu ? 'text-dark' : 'text-white'"
                    @click="openSearchSug"
                  ></i
                ></a>
              </nav>
            </div>
          </div>
        </div>
        <!--end: Navigation-->
      </div>
    </div>
    <AppModal :withPageTitle="true" ref="modal-menu" :type="'n'">
      <template #title-modal>
        <h5 class="mb-0">Menu</h5>
      </template>
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="modal-menu d-flex flex-column">
              <router-link v-if="authenticated && !($route.meta || {}).isPrivatePage" :to="{ name: 'Dashboard' }" class="btn btn-outline-main extrabold"
                >Go to Dashboard</router-link
              >
              <router-link v-if="authenticated && ($route.meta || {}).isPrivatePage" :to="{ name: 'Home' }" class="btn btn-outline-main extrabold"
                >Go to Main Site</router-link
              >
              <!-- <router-link v-else-if="authenticated && ($route.meta || {}).isPrivatePage" :to="{ name: 'Home' }" class="btn btn-outline-main extrabold"
                >Go to Main Site</router-link
              > -->
              <a
                v-if="authenticated"
                :href="`${baseUrlAthletes}/user/profile-information`" 
                class="btn btn-secondary-black text-dark extrabold ml-0 mt-2"
              >
                My Account
              </a>
              <div class="d-flex justify-content-between align-items-center h-50px">
                <router-link :to="{ name: 'Home' }" class="medium text-dark-custom" exact>Home</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px">
                <router-link :to="{ name: 'RunningEvents' }" class="medium text-dark-custom">Run With Us</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px">
                <router-link :to="{ name: 'Blog' }" class="medium text-dark-custom">Blog</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px">
                <router-link :to="{ name: 'RunnerSays' }" class="medium text-dark-custom">Runner Says</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px">
                <span class="medium">Learn About RunDeGlobe</span>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'About' }" class="medium text-dark-custom">Learn About Us</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'HowWeWork' }" class="medium text-dark-custom">How We Work</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'Destination' }" class="medium text-dark-custom">Where We Go</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'Gallery' }" class="medium text-dark-custom">Browse Our Gallery</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'BookingAndPrices' }" class="medium text-dark-custom"
                  >See Booking and Prices</router-link
                >
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'FAQ'}" class="medium text-dark-custom"
                  >Browse Our FAQ</router-link
                >
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'ContactUs' }" class="medium text-dark-custom">Contact Us</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div v-if="showWorkWithUs()" class="d-flex justify-content-between align-items-center h-50px">
                <span class="medium">Work With Us</span>
              </div>
              <div v-if="setting.tour_guide === 'Y'" class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'WWULPTourGuide' }" class="medium text-dark-custom"
                  >Apply As A Tour Guide</router-link
                >
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div v-if="setting.hotel === 'Y'" class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'WWULPHotels' }" class="medium text-dark-custom"
                  >Become Hotel Partner</router-link
                >
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div v-if="setting.join_the_team === 'Y'" class="d-flex justify-content-between align-items-center h-50px pl-4">
                <router-link :to="{ name: 'WWULPJoinTheTeam' }" class="medium text-dark-custom">Join The Team</router-link>
                <i class="icon-chevron-right fs-125em"></i>
              </div>
              <div v-if="!authenticated" class="d-flex justify-content-between align-items-center h-50px mt-3">
                <a :href="`${baseUrlAthletes}/oauth/authorize?client_id=${athletesClientId}&response_type=code&redirect_uri=${baseUrl}/oauth/cb/athletescallback`" class="btn extrabold">Sign Up</a>
              </div>
              <div v-else class="d-flex justify-content-between align-items-center h-50px mt-3">
                <a @click.prevent="$refs['modal-logout'].show()" href="javascript:;" class="btn extrabold">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
    <AppModal :withPageTitle="false" ref="modal-logout" :type="'md'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/logout-1.svg`" alt="Confirmation" class="w-35-cust" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Confirm log out?</p>
              <span class="regular fs-1em">Are you sure you want to end this session?</span>
              <div class="d-flex">
                <button
                  @click="$refs['modal-logout'].hide()"
                  class="btn btn-outline-main ml-0 semibold d-flex text-center justify-content-center mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  No, Stay Here
                </button>
                <button
                  @click="doLogout"
                  class="btn btn-primary semibold d-flex text-center justify-content-center mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Yes, Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
    <AppModal :withPageTitle="false" ref="modal-help" :type="'sm'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/active-support-vector.svg`" alt="Support" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Help Center</p>
            </div>
            <div class="wrap-help-nav mb-3">
              <router-link :to="{ name: 'FAQ' }" class="text-dark-custom">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="wrap-text pr-4">
                    <span class="bold d-block">FAQ</span>
                    <p class="d-block">Don't know where to turn? Our FAQ section is here to help with any questions.</p>
                  </div>
                  <div>
                    <i class="icon-chevron-right fs-125em"></i>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="wrap-help-nav mb-3">
              <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-dark-custom">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="wrap-text pr-4">
                    <span class="bold d-block">Chat Whatsapp</span>
                    <p class="d-block">Get in touch with us via WhatsApp for fast and easy communication.</p>
                  </div>
                  <div>
                    <i class="icon-chevron-right fs-125em"></i>
                  </div>
                </div>
              </a>
            </div>

            <div class="wrap-help-nav mb-3">
              <router-link :to="{ name: 'ContactUs' }" class="text-dark-custom">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="wrap-text pr-4">
                    <span class="bold d-block">Send a Message</span>
                    <p class="d-block">Send us a message via our contact form and we'll respond as soon as possible.</p>
                  </div>
                  <div>
                    <i class="icon-chevron-right fs-125em"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </header>
  <!-- </div> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Global from '../libs/Global.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import AppModal from './AppModal.vue'

export default {
  extends: Global,
  name: 'Header',
  props: {
    isOpenMenuProps: {
      type: Boolean,
      default: false,
    },
    isPrivatePage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppModal,
    PulseLoader,
  },
  data() {
    return {
      timeoutId: null,
      isTyping: false,
      isLoading: false,
      isOpenMenu: false,
      scrollPosition: null,
      isStickyHeader: false,
      childPrivatePage: [
        'Dashboard',
        'MyInformation',
        'MyReviews',
        'FormReview',
        'MyWishlist',
        'MyPurchases',
        'MyPurchasesFilter',
        'Notification',
        'ProfileInformation',
        'AccountInformation',
        'ChangeEmail',
        'ChangePassword',
        'MailingAddress',
        'EmergencyContact',
        'MyDocuments',
        'PromoDetails',
        'DetailNotification',
      ],
    }
  },
  computed: {
    ...mapFields(['baseUrl', 'baseUrlAthletes', 'athletesClientId', 'inputSearch', 'resultBlog', 'resultRunEvents', 'user', 'channels', 'dashboard_unread_updates', 'setting']),
    ...mapFields('home', ['homeContent']),
    ...mapGetters(['authenticated']),
    ...mapFields('usePrivatePageStore', ['updates', 'notifications']),
    isDocument() {
      if (this.$route.meta.isDocument) {
        return true
      }
      return false
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
    this.updateScroll()

    const el = document.querySelectorAll('.nav__item, .dropdown-menu li a')

    for (const value of el) {
      const href = value.getAttribute('href').split('/')[1] || value.getAttribute('href')
      const path = this.$route.path.split('/')[1] || this.$route.path

      if (href.indexOf(path) > -1) {
        if (value.closest('li.dropdown') == null) {
          value.classList.add('router-link-active')
          value.classList.add('router-link-exact-active')
          return null
        }

        value.closest('li.dropdown').querySelector('.dropdown-parent').classList.add('active')
      }
    }
  },
  created() {
    this.getChannels()
    if (!this.isPrivatePage) {
      this.getMenuSettings()
    }

    if (this.isPrivatePage && (!this.isOnlyMobile() || (this.$route.meta || {}).isNotificationPage)) {
      this.getNotificationList()
    }
    
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions(['doSearchNewsArticles', 'doSearchRunEvents', 'getChannels', 'getMenuSettings']),
    ...mapActions('home', ['getHomeContent']),
    ...mapActions('usePrivatePageStore', ['getNotificationList']),

    showWorkWithUs() {
      const setting = this.setting
      const isHide = setting.tour_guide == 'N' && setting.hotel == 'N' && setting.join_the_team == 'N'

      if (isHide) {
        return false
      }

      return true
    },

    doLogout(){
      this.$store.commit('logoutUserToken')
      if(!this.authenticated) {
        this.$router.push({ name: 'Login' })
      }
    },

    updateScroll() {
      this.scrollPosition = window.scrollY
      const header = document.querySelector('#header')

      if (header !== null) {
        if (this.scrollPosition > 40 || this.isOpenMenuProps == true) {
          header.classList.add('header-sticky')
          header.classList.add('sticky-active')
          header.classList.remove('dark')
          this.isOpenMenu = true
        } else {
          header.classList.remove('header-sticky')
          header.classList.remove('sticky-active')
          header.classList.add('dark')
          this.isOpenMenu = false
        }
      }
    },

    openSearchSug() {
      const bodyEl = document.querySelector('body')

      bodyEl.classList.toggle('search-open')

      this.clearInputSearch()
    },

    openMenuModal() {
      this.$refs['modal-menu'].show()
    },

    showNotification() {
      const dropdownNotificationEl = document.getElementById('dropdown-notification')
      dropdownNotificationEl.classList.toggle('active')
    },


    doDebounce(callback, wait) {
      clearTimeout(this.timeoutId)

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    },

    doSearch() {
      if (this.inputSearch.length < 3) {
        return
      }

      this.isTyping = true
      this.isLoading = true
      this.doDebounce(async () => {
        this.isTyping = false

        setTimeout(() => {
          this.doSearchRunEvents()
        }, 100)
  
        setTimeout(() => {
          this.doSearchNewsArticles(() => {
            this.isLoading = false
          })
        }, 100)
      }, 500)
    },
    clearInputSearch(){
      this.$set(this, 'inputSearch', null)
      this.$store.commit("CLEAR_SUGGESTION_SEARCH")
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-nav {
  width: 60%;
}

@media (max-width: 991px) {
  .header-extras {
    display: none;
  }
}

#mainCta {
  @media only screen and (max-width: 1222px) {
    display: none;
  }
}

.modal-menu {
  a {
    &.router-link-exact-active,
    &.router-link-active {
      color: #95378e;
    }
  }
}

#search {
  display: block !important;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-top: 100px;
  padding-bottom: 50px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 10px;
  }

  .card-search-suggestion {
    .img-src {
      width: 50px;
      height: 50px;
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.search-form {
  margin: 0 !important;
  input {
    &:focus {
      box-shadow: inset 0 -1px 0 #ddd;
    }
  }
}

#mainMenu nav > ul > li > a.router-link-active,
#mainMenu nav > ul > li .dropdown-menu > li > a.router-link-active,
#mainMenu nav > ul > li .dropdown-parent.active,
.dark #mainMenu nav > ul > li:hover > a.router-link-active,
.dark #mainMenu nav > ul > li:hover > a.active {
  color: #95378e;
}

#mainMenu nav > ul > li > a.router-link-active::before,
#mainMenu nav > ul > li .dropdown-parent.active::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 2px;
  background: #95378e;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

#mainMenu nav > ul > li .dropdown-parent.active::before {
  left: calc(50% - 10px);
}

.img-profile-header {
  width: 43px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.help-icon {
  position: relative;
  span {
    position: absolute;
    font-size: 0.5em !important;
    top: -7px;
    left: -22.5px;
    background: #159f91;
    padding: 0.25em;
    border-radius: 4px;
    font-family: 'Metropolis-SemiBold' !important;
    color: #fff !important;
  }
}

#dropdown-notification {
  position: absolute;
  top: 50px;
  right: 0;
  width: 500px;
  height: 400px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: none;

  &.active {
    display: block;
  }

  .capsul {
    .wrap-tab {
      height: 20px;
    }
  }

  .wrapper-notification-list {
    height: 325px;
    overflow-y: scroll;
    line-height: 1.25;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .card-notification-list {
      &:last-child {
        border-bottom: 0 !important;
      }
    }
  }
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <section class="general-form-section" :class="isTablet() ? 'h-auto' : ''">
        <div class="row no-gutters">
          <div class="col-lg-7" :class="isMobile() ? 'order-5' : ''">
            <div
              class="left-section without-justify-content"
              :class="
                !isMobile()
                  ? 'ov-scroll scroll-bar-hidden p-r-100 p-l-100'
                  : isTablet()
                  ? 'd-block h-auto'
                  : 'mt-5 pt-0 px-3'
              "
            >
              <ValidationObserver v-slot="{ handleSubmit }" ref="JoinTheTeamForm">
                <b-form ref="JoinTheTeamFormElement" @submit.prevent="handleSubmit(submitWWUJoinTheTeamForm)">
                  <div>
                    <h4 class="extrabold">Complete the following data</h4>
                    <div class="mt-3 mb-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Email Address</label>
                            <b-badge
                              variant="info"
                              class="help-badge custom-badge-info ml-3"
                              v-b-tooltip.hover.right="'We need an email so we can contact you'"
                              id="email-tooltip"
                              pill
                              @click.prevent
                            >
                              <b-icon icon="question" class="question-icon" font-scale="1"></b-icon>
                            </b-badge>
                            <input
                              type="email"
                              v-model="inputForm.input_email_address"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@example.com"
                            />
                            <VValidate name="Email Address" v-model="inputForm.input_email_address" :rules="{required: true, email: true}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Title</label>
                            <div class="d-flex mt-3">
                              <b-form-radio name="title" value="Mr" v-model="inputForm.input_title">Mr</b-form-radio>
                              <b-form-radio name="title" class="ml-3" value="Mrs" v-model="inputForm.input_title">Mrs</b-form-radio>
                              <b-form-radio name="title" class="ml-3" value="Ms" v-model="inputForm.input_title">Ms</b-form-radio>
                            </div>
                            <VValidate name="Title" v-model="inputForm.input_title" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">First & Middle Name</label>
                            <input
                              type="text"
                              v-model="inputForm.input_first_name"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                            />
                            <VValidate name="First Name" v-model="inputForm.input_first_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="required bold">Last Name</label>
                            <input
                              type="text"
                              v-model="inputForm.input_last_name"
                              class="form-control main-control"
                              placeholder="e.g. Grahita"
                            />
                            <VValidate name="Last Name" v-model="inputForm.input_last_name" :rules="{required: true, min:3, max:96, regex: /^([A-Za-z' ]+)$/}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Phone Number</label>
                            <b-form-input
                              type="tel"
                              v-model="inputForm.input_phone_number"
                              class="form-control main-control"
                              placeholder="Phone Number"
                              :formatter="(v) => v.replace(/[^0-9\+\-() ]/, '')"
                            />
                            <VValidate name="Phone Number" v-model="inputForm.input_phone_number" :rules="{min:5, max:16}" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="" class="bold">Country</label>
                            <v-select
                              :options="citizenshipData"
                              placeholder="-- Select Country --"
                              :reduce="(v) => v.citizenship_id"
                              v-model="inputForm.input_country"
                              id="co2ctzship"
                              label="citizenship_name"
                              class="mt-3"
                            ></v-select>
                            <VValidate name="Country" v-model="inputForm.input_country" :rules="{required: true}" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">City</label>
                            <input
                              type="text"
                              v-model="inputForm.input_city"
                              class="form-control main-control"
                              placeholder="e.g. Jakarta"
                            />
                            <VValidate name="City" v-model="inputForm.input_city" :rules="{required: true, min: 3}" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="required bold">Upload the Resume file</label>
                            <ValidationProvider name="Resume" rules="required" ref="fileResume" v-slot="{ validate, errors }">
                              <input
                                type="file"
                                ref="fileResume"
                                class="form-control main-control"
                                @change="pickFile($event, 'fileresume', 'fileResume')"
                                accept=".pdf"
                              />
                              <b-form-text> Format accepted: .pdf </b-form-text>
                              <b-form-text text-variant="danger">{{ errors[0] }}</b-form-text>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="required bold">Personal Background</label>
                            <textarea
                              type="text"
                              class="form-control main-control text-area"
                              v-model="inputForm.input_personal_background"
                              placeholder="Let us know about you as a person, your background, education, etc"
                            ></textarea>
                            <VValidate name="Personal Background" v-model="inputForm.input_personal_background" :rules="{required: true, min: 10}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center">
                    <button type="submit" class="btn btn-slg btn-primary bold w-100">Submit</button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
          <div class="col-lg-5 join-the-team-hero">
            <div
              class="right-section h-100 background-hero-img"
              :style="sideBackground"
              :class="isMobile() ? '' : 'h-100'"
            >
              <div class="bg-overlay" data-style="13"></div>
              <div>
                <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                  <div>
                    <router-link :to="{ name: 'Home' }" :class="!isTablet() ? 'mr-5' : ''">
                      <picture>
                        <source
                          media="(min-width: 992px)"
                          :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                          type="image/webp"
                        />
                        <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                        <img
                          class="brand-logo"
                          v-if="isOnlyMobile()"
                          :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="142"
                          height="43"
                        />
                        <img
                          class="brand-logo"
                          v-else
                          :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="175"
                          height="53"
                        />
                      </picture>
                    </router-link>
                  </div>
                  <div class="mt-5">
                    <div class="label-custom green d-inline">
                      <span class="black">Work With Us</span>
                    </div>
                    <div class="title-content mt-4">
                      <h2 class="text-white">Become A Member</h2>
                      <p style="color: #F7AF2F">Of Our Team</p>
                    </div>
                    <span class="text-white bold d-block mt-4">We are always looking for passionate and motivated individuals to join our team. As a member, you will have the opportunity to work on exciting projects, and grow your skills and career</span>
                  </div>
                  <div class="mt-5">
                    <router-link :to="{ name: 'WWULPJoinTheTeam' }" class="text-white text-underline bold"
                      >Back to Previous Page</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>

    <AppAlert
      ref="modal-error"
      vref="modal-error"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
      }"
      icon-size="w-50"
      :title="messageErrTitle"
      :description="messageErrDescription"
      :button="{
        main: {
          text: 'Ok',
        },
      }"
    />

    <AppModal :withPageTitle="false" ref="confirm-data-modal" :type="'n'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img
                :src="`${baseUrl}/assets/images/vector/confirm-vect.svg`"
                alt="Confirmation"
                class="w-25"
              />
              <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
              <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
              <ul>
                <li>Email: {{ inputForm.input_email_address || 'abraham.damar@gmail.com' }}</li>
              </ul>
              <div class="d-flex">
                <button type="button" class="btn btn-outline-main ml-0 bold" @click="onChangeData">Edit data</button>
                <button type="button" class="btn btn-primary bold" @click="onConfirmData">Confirm data</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
// @ is an alias to /src
import StepProgress from 'vue-step-progress'
import Global from '../libs/Global.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from "vuex"

import 'vue-step-progress/dist/main.css'

import AppModal from '../components/AppModal.vue'
import AppAlert from '@/components/AppAlert'

import Gen from '@/libs/Gen'

import axios from 'axios'

export default {
  extends: Global,
  name: 'WWUJoinTheTeam',
  components: {
    'step-progress': StepProgress,
    AppModal,
    AppAlert,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.isSubmitting) {
      if (confirm('Are you sure you want to leave? Your changes may not be saved.')) {
        this.isDirty = false
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  data() {
    return {
      isWebPSupport: false,
      isLoaded: false,
      isSubmitting: false,
      isDirty: false,
      messageErrTitle: '',
      messageErrDescription: '',
      mySteps: ['1', '2', '3'],
      currentStep: 0,
      isSelectedCertified: 'N',
      tabsOverflow: 0,
      conf: {
        rule_type: 'pdf',
        max_image_size: 5000000,
      },
      fileType: null,
      fileData: {
        fileresume: null,
      },
    }
  },
  computed: {
    ...mapFields("useWWUJoinTheTeamStore", [
      "inputForm",
      "WWUJoinTheTeamData"
    ]),
    ...mapFields(['channels']),
    ...mapFields('profile', ['citizenshipData']),
    isLastStep() {
      return this.currentStep === this.mySteps.length - 1
    },
    sideBackground() {
      let src = ''
      
      if (this.isWebPSupport) {
        src = this.uploader(((this.WWUJoinTheTeamData || {}).content || {}).banner_img_section)
      } else {
        src = this.uploader(((this.WWUJoinTheTeamData || {}).content || {}).banner_fallback_img_section)
      }

      if (src == '') {
        return null
      }

      return `background: url("${src}") center center / cover;`
    },
  },
  created() {
    this.getDataContent()
    this.getChannels()
    this.getCitizenship()
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions("useWWUJoinTheTeamStore", [
      "getDataContent",
      "applyJoinTheTeam"
    ]),
    ...mapActions(['getChannels']),
    ...mapActions('profile', ['getCitizenship']),

    async submitWWUJoinTheTeamForm() {
      this.isSubmitting = true
      const resumeFile = await this.uploadFormFile('fileresume')
      this.inputForm.input_resume = ((resumeFile || {}).data || {}).pathfile

      this.applyJoinTheTeam({
        form: this.inputForm,
        callback: (success, res) => {
          this.$store.state.loading.screen = false

          if(!success) {
            return
          }

          this.$router.push({ name: 'ConfirmWWUJoinTheTeam', params: { pageSlug: res.data.apply_id } })
          this.isSubmitting = false
        }
      })
    },

    async pickFile(e, dataToBeBound, id) {
      this.$refs[id].validate(e)
      
      if (e.target.value === '') {
        return
      }

      const input = this.$refs[id]
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }

      this.fileData[dataToBeBound] = e.target.files[0]

      this.conf.fileType = (this.fileData[dataToBeBound] || {}).type

      this.fileType = ((this.fileData[dataToBeBound] || {}).name || '')
        .substr(((this.fileData[dataToBeBound] || {}).name || '').lastIndexOf('.') + 1 || '')
        .toLowerCase()
    },

    uploadFormFile(dataToBeBound) {
      // Validation
      this.error = false

      if (this.fileData[dataToBeBound] === null) {
        this.messageErrTitle = `File is required.`
        this.$refs['modal-error'].show()
        return null
      }

      if (this.conf.rule_type.indexOf(this.fileType) == -1) {
        this.error = `File type must be ${this.conf.rule_type} type.`
      }

      if (this.fileData[dataToBeBound] !== null) {
        if (this.fileData[dataToBeBound].size > this.conf.max_image_size) {
          this.$store.state.loading.screen = false
          return alert('File too big (> 5MB)')
        }
        if (this.error) {
          return alert(this.error)
        }

        const path = 'join_the_team'

        const formData = new FormData()

        formData.set('file', this.fileData[dataToBeBound])

        formData.append('path', path)

        const query = {
          pageType: path,
        }

        return axios.post(`${process.env.VUE_APP_API_URL}/api/app/ajax/upload?${Gen.objectToQuery(query)}`, formData, {
          headers: {
            'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
            'X-Frame-Options': 'sameorigin',
            'X-XSS-Protection': '1; mode=block',
            'X-Content-Type-Option': 'nosniff',
            'Content-Security-Policy': "script-src 'self'",
            'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
          },
        })
      }
    },

    onChangeData() {
      this.$refs['confirm-data-modal'].hide()
    },

    onConfirmData() {
      this.$router.push({ name: 'ConfirmWWUJoinTheTeam', params: { pageSlug: 'RDG-01230307' } })
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
  watch: {
    'inputForm': {
      handler() {
        if (this.isLoaded) {
          this.isDirty = true
        }

        this.isLoaded = true
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.form-select.main-control {
  border-radius: 2em;
  min-height: 45px !important;
  margin-top: 1em;
  font-size: 1em !important;
  font-family: 'Metropolis-Semibold' !important;
}
.form-select.main-control::placeholder {
  color: #cccccc !important;
  opacity: 1;
  font-family: 'Metropolis-Semibold' !important;
}
</style>

<template>
  <section class="bt-1px-grey bb-1px-grey">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-6 d-flex justify-content-center"
          :class="isMobile ? 'order-2 mt-4 d-flex justify-content-center' : ''"
        >
          <div class="img-about-home">
            <picture>
              <source :srcset="uploader(content.image)" type="image/webp">
              <img :src="uploader(content.fallback_image)" :alt="content.alt_image" />
            </picture>
          </div>
        </div>
        <div class="col-lg-5" :class="isOnlyMobile ? 'text-center' : 'text-start'">
          <div :class="isOnlyMobile ? 'd-flex flex-column align-items-center mt-5' : ''">
            <span v-if="content.sub_title !== undefined" class="sub-title-section justify-content-sm-center justify-content-lg-start">
              <div class="line-sub-title"></div>
              {{ content.sub_title }}
            </span>
            <h2 class="title-section-left" :class="isMobile ? 'mt-2' : 'mt-3'">{{ content.title }}</h2>
            <p class="regular grey-color" :class="!isLargeDevice ? 'mb-0' : ''">
              {{ content.description }}
            </p>
            <a v-if="isLargeDevice" :href="$router.resolve({name: 'About'}).href" class="btn btn-outline black">{{ content.button }}</a>
          </div>
        </div>
        <div v-if="!isLargeDevice" class="col-md-12 order-3 text-center">
          <a :href="$router.resolve({name: 'About'}).href" class="btn btn-outline black mt-4">{{ content.button }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutAtom',
  props: {
    isMobile: {
      type: Boolean,
    },
    isOnlyMobile: {
      type: Boolean,
    },
    isLargeDevice: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    content: {
      type: Object,
    },
    uploader: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
.img-about-home {
  position: relative;
  width: 500px;
  height: 400px;
  background: #fff;
  border-radius: 24px;
  // margin-right: 1.5rem !important;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 200px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }
}
</style>

<template>
  <div id="app">
    <LoadingScreen v-if="loading.screen" />
    <router-view />
    <Footer v-if="$route.meta.isFooter" />
    <template v-if="isMobile()">
      <BottomNavigation v-if="$route.meta.isBottomNavigation" />
    </template>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import LoadingScreen from './components/LoadingScreen.vue'
import Footer from './components/Footer.vue'
import BottomNavigation from './components/BottomNavigation.vue'
import Global from './libs/Global.vue'

export default {
  extends: Global,
  name: 'App',
  components: {
    LoadingScreen,
    Footer,
    BottomNavigation,
  },
  computed: {
    ...mapFields(['baseUrl', 'loading', 'staticseo']),
  },
  methods: {
    ...mapActions(['watchMeta']),
  },
  metaInfo() {
    return {
      title: (this.staticseo || {}).title,
      titleTemplate: (this.staticseo || {}).title,
      meta: [{ name: 'description', content: (this.staticseo || {}).description }],
      htmlAttrs: {
        lang: 'en',
      },
    }
  },
  watch: {
    '$route': {
      handler(o) {
        if (o.meta.noWatchMeta === undefined) {
          this.watchMeta(this.$route.path)
        }
      }
    }
  },
}
</script>

<style lang="scss">
@import '../public/assets/css/plugins.css';
@import '../public/assets/css/style.css';
.btn-app {
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    bottom: 0.25em;
    right: 0.25em;
  }
  @media screen and (max-width: 768px) {
    bottom: 2em;
    right: 0.25em;
  }
}
</style>

<template>
  <div class="home">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="`${(paymentData || {}).order_id} - Invoice`"
      :paginate-elements-by-height="2500"
      :pdf-quality="2"
      pdf-format="a4"
      :html-to-pdf-options="{
        filename: `${(paymentData || {}).order_id} - Invoice`,
        margin: [0, 0, 0.35, 0],
        image: {
          type: 'jpeg',
          quality: 1,
        },
        pagebreak: {
          mode: ['css', 'legacy'],
          avoid: '.page-breaker',
        },
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          allowTaint: true,
          useCORS: true,
        },
        jsPDF: {
          format: 'letter',
          orientation: 'portrait',
        },
      }"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      ref="documentInvoice"
    >
      <section slot="pdf-content" class="py-0 my-0" style="width: 100%">
        <section class="report-info py-0">
          <section class="pdf-item py-0 my-0" style="position: relative; height: 100%">
            <div class="page-pdf-with-footer">
              <div class="container p-l-60 p-r-60">
                <div class="row">
                  <div class="col-md-12 bb-1px-grey pb-3">
                    <div class="d-flex justify-content-between">
                      <img :src="`${baseUrl}/assets/images/logo/black_rdg.png`" style="width: 250px; height: 75px" alt="">
                      <img :src="`${baseUrl}/assets/images/header-template.png`" style="width:100px" alt="">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="text-center py-3">
                      <h5 class="extrabold text-decoration-underline mb-0">INVOICE</h5>
                      <span>NO: BM{{ trxYear }}/{{ (paymentData || {}).transaction_id}}/{{ numberOfInstallment }}</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <p class="mb-0">Attention: </p>
                    <span>{{ (paymentData || {}).transaction_name }}</span>
                  </div>
                  <div class="col-md-12 mt-3">
                    <p class="mb-0 text-black">Race: {{ (paymentData || {}).event_name }} </p>
                    <p class="mb-0 text-black">Race Date: {{ formatDateWithoutWeekday((paymentData || {}).race_date) }}</p>
                  </div>
                  <div class="col-md-12">
                    <table class="table table-bordered mx-0 px-0 mt-4">
                      <thead class="bg-info-table">
                        <tr>
                          <th scope="col extrabold">No</th>
                          <th scope="col extrabold">Description</th>
                          <th scope="col extrabold">Sub Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>
                            <div>
                              <p class="text-black extrabold mb-0">{{ (paymentData || {}).event_name }}</p>
                              <p class="mb-0">Package: {{ (paymentData || {}).package_name }}</p>
                              <p class="mb-0">Hotel Name: {{ (paymentData || {}).hotel_name }}</p>
                              <p class="mb-0">Check in Date: {{ formatDateWithoutWeekday((paymentData || {}).hotel_check_in_date) }}</p>
                              <p class="mb-0">Check out Date: {{ formatDateWithoutWeekday((paymentData || {}).hotel_check_out_date) }}</p>
                              <p class="mb-0"># of Nights: 3 (three) Night</p>
                              <p class="mb-0">Max Number in Room: {{ (paymentData || {}).hotel_max_person }} adults</p>
                              <p class="mb-0"># of Race Entry in package: {{ (paymentData || {}).race_entry_person }} entries</p>
                              <p class="mb-0">Total Package Price is Rp.{{ numFormatter((paymentData || {}).price) }} (million)</p>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex justify-content-between">
                              <span>IDR</span>
                              <span>{{ formatFullPrice((paymentData || {}).booking_price) }}</span>
                            </div>
                          </td>
                        </tr>
                        <template>
                          <tr class="bg-info-table">
                            <th scope="row"></th>
                            <td class="bold">PACKAGE PRICE</td>
                            <td class="bold">
                              <div class="d-flex justify-content-between">
                                <span>IDR</span>
                                <span>{{ formatFullPrice((paymentData || {}).booking_price) }}</span>
                              </div>
                            </td>
                          </tr>
                          <tr class="bg-info-table">
                            <th scope="row"></th>
                            <td class="bold">DISCOUNT</td>
                            <td class="bold">
                              <div class="d-flex justify-content-between">
                                <span>IDR</span>
                                <span>{{ formatFullPrice(parseInt((paymentData || {}).price) - parseInt((paymentData || {}).total_amount)) }}</span>
                              </div>
                            </td>
                          </tr>
                          <tr class="bg-info-table">
                            <th scope="row"></th>
                            <td class="bold">FINAL PRICE</td>
                            <td class="bold">
                              <div class="d-flex justify-content-between">
                                <span>IDR</span>
                                <span v-if="(paymentData || {}).transaction_type == 'F'">{{ formatFullPrice((paymentData || {}).full_payment_price) }}</span>
                                <span v-if="(paymentData || {}).transaction_type == 'B'">{{ formatFullPrice((paymentData || {}).booking_price) }}</span>
                              </div>
                            </td>
                          </tr>
                          <template v-for="(v, k) in paymentData.installments" >
                            <tr class="bg-info-table">
                              <th scope="row"></th>
                              <!-- <td class="bold">DOWN PAYMENT DUE</td> -->
                              <template v-if="v.active == 'Y' && v.payment_status == 'Pending'">
                                <td class="bold text-uppercase">{{ paymentData.transaction_type == 'F' ? 'Final' : ordinal(v.num_of_payment) }} Payment</td>
                              </template>
                              <template v-if="v.payment_status == 'Success'">
                                <td class="bold text-uppercase">{{ paymentData.transaction_type == 'F' ? 'Final' : ordinal(v.num_of_payment) }} Payment Received On {{ formatDateWithoutWeekday(v.payment_last_date)}}</td>
                              </template>
                              <td class="bold">
                                <div class="d-flex justify-content-between">
                                  <span>IDR</span>
                                  <span>{{ formatFullPrice(v.amount)}}</span>
                                </div>
                              </td>
                            </tr>
                          </template>
                          <tr>
                              <th scope="row"></th>
                              <td class="bold">TOTAL CURRENT PAYMENT</td>
                              <td class="bold">
                                <div class="d-flex justify-content-between">
                                  <span>IDR</span>
                                  <span>{{ formatFullPrice((paymentData || {}).current_amount) }}</span>
                                </div>
                              </td>
                            </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-12 d-flex justify-content-end">
                    <span>### {{ formatAmountInWords((paymentData || {}).current_amount) }} ###</span>
                  </div>
                  <div class="col-md-12" style="margin-top: 250px">
                    <p class="mb-0 text-black bold">Terms and Conditions:</p>
                    <div v-html="(paymentData || {}).terms_conditions"></div>
                  </div>
                  <div class="col-md-12 mt-5">
                    <p class="mb-0 semibold text-black">Jakarta, 16 August 2023</p>
                    <div class="my-5 py-3">

                    </div>
                    <p class="mb-0 semibold text-decoration-underline text-black">RunDeGlobe</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </vue-html2pdf>
    <div class="body-inner">
      <section class="overflow-visible" :class="isModalStrip() ? 'p-t-100' : 'pt-0'">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-short-breadcrumb d-flex align-items-end">
                <a @click="$router.push({
                  name: 'Checkout', 
                  params: {pageSlug: paymentData.event_slug},
                  query: {itinerary: paymentData.itinerary_slug, package: paymentData.package_slug, po: paymentData.transaction_type == 'B' ? 'partial-payment' : 'one-time-payment'}
                })">
                  <i class="icon-chevron-left fs-125em pointer pb-2 mb-1"></i>
                </a>
                <h3 class="mb-1 bold ls-0 mx-2" :class="isOnlyMobile() && isModalStrip() ? '' : 'mt-5 pt-5'">{{ paymentData.sub_title_1 }}</h3>
              </div>
            </div>
          </div>
          <div class="row" :class="isTablet() ? 'mt-4' : ''">
            <div
              id="modalStripWarning"
              class="modal-strip modal-top py-3 modal-active"
              :class="[isOnlyMobile() ? 'px-0' : '', paymentData.due_text_bg]"
              v-if="isModalStrip()"
            >
              <div class="container">
                <div class="row gx-2 justify-content-between align-items-center">
                  <div class="col-12">
                    <div class="bold d-flex justify-content-between" :class="isMobile() ? 'flex-column align-items-start' : 'align-items-center'">
                      {{ paymentData.due_text }}
                      <button type="button" @click="exportInvoiceToPDF" class="btn btn-outline white extrabold" :class="isMobile() ? 'mt-3' : ''">Export Invoice to PDF</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7" :class="!isMobile() ? 'my-4' : isTablet() ? 'pt-3' : 'mt-4'">
              <div v-for="(v1, k1) in paymentData.payment_categories" :key="k1" :class="!isTablet() ? 'mt-3' : ''">
                <h5 class="bold ls-0 fs-125em">{{ v1.name }}</h5>
                <span class="grey-color regular"
                  >{{ v1.description }}</span
                >
                <div class="row mt-3">
                  <div class="col-auto" v-for="(v2, k2) in paymentChannelsById(v1.id)" :key="k2">
                    <picture>
                      <source :srcset="uploader(v2.image)" type="image/webp">
                      <img
                        :src="uploader(v2.fallback_image)"
                        :alt="v2.alt_image"
                        class="align-self-start img-fluid payment_channel"
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <template v-if="!isOnlyMobile()">
                <a
                  :href="`${paymentData.payment_link}`"
                  class="btn btn-slg btn-primary bold w-100 mt-5"
                  :disabled="paymentData.payment_link == ''"
                >
                  Pay Now
                </a>
              </template>
            </div>
            <div class="col-md-5" :class="!isMobile() ? 'p-l-20 mt-4' : !isTablet() ? 'mt-4' : ''">
              <div
                class="b-grey-black px-3 py-3 bdrd-12px"
                :class="[
                  'sticky-70px',
                  !isTablet() ? 'my-4' : isMobile() ? 'b-grey-black px-3 py-3' : '',
                ]"
              >
                <div class="small-short-event d-flex bb-1px-grey pb-4">
                  <div class="img">
                    <picture>
                      <source :srcset="uploader(paymentData.preview_image)" type="image/webp">
                      <img
                        :src="uploader(paymentData.preview_fallback_image)"
                        :alt="paymentData.preview_alt_image"
                      />
                    </picture>
                  </div>
                  <div class="mx-3">
                    <img :src="`${baseUrl}/assets/images/rundeglobe_logo.png`" alt="Logo" class="w-50" />
                    <p class="bold ls-0 mt-2 mb-0 text-dark-custom">{{ paymentData.event_name }}</p>
                    <span class="regular grey-color">{{ paymentData.itinerary_name }}, {{ paymentData.package_name }}</span>
                  </div>
                </div>
                <div class="bb-1px-grey" :class="!isMobile() ? 'py-3' : 'pb-3'">
                  <h5 class="semibold ls-0">{{ paymentData.sub_title_2}}

                  </h5>
                  <div class="mt-3" :class="!isMobile() ? '' : 'pb-3'">
                    <div class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                      <span class="text-dark regular">1 Slot Package</span>
                      <span class="text-dark bold">IDR {{ numFormatter(paymentData.price) }}</span>
                    </div>

                    <div v-if="discount > 0" class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                      <span class="text-dark regular">One Time Payment Discount</span>
                      <span class="text-dark bold">-IDR {{ numFormatter(discount) }}</span>
                    </div>

                    <div class="content-2-column between" :class="!isMobile() ? '' : 'flex-column'">
                      <span class="text-dark regular bold">Total</span>
                      <span class="text-dark bold">IDR {{ numFormatter(discount > 0 ? paymentData.full_payment_price : paymentData.price) }}</span>
                    </div>
                  </div>
                </div>
                <div :class="!isMobile() ? 'pt-3' : 'bb-1px-grey pb-3'">
                  <div>
                    <div 
                      v-for="(v, k) in paymentData.installments" 
                      :key="k" 
                      class="content-2-column between" 
                      :class="!isMobile() ? '' : 'flex-column'"
                    >
                      <div class="d-flex align-items-center">
                        <span class="text-dark regular">{{ paymentData.transaction_type == 'F' ? 'One Time' : ordinal(v.num_of_payment) }} Payment</span>
                        <div v-if="v.active == 'Y' && v.payment_status == 'Pending'" class="label-custom d-inline mx-2" :class="paymentData.active_payment_bg">
                          <span class="black fs-085em align-self-start">{{ paymentData.active_payment_text }}</span>
                        </div>
                        <div v-if="v.payment_status == 'Success'" class="label-custom d-inline mx-2" :class="paymentData.paid_payment_bg">
                          <span class="black fs-085em align-self-start">{{ paymentData.paid_payment_text }}</span>
                        </div>
                      </div>
                      <span class="text-dark bold">IDR {{ numFormatter(v.amount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="isOnlyMobile()">
              <div class="col">
                <a
                  class="btn btn-slg btn-primary bold w-100"
                  :href="`${paymentData.payment_link}`"
                  :disabled="paymentData.payment_link == ''"
                >
                  Pay Now
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-outline-main ml-0 w-100"
                  @click.prevent="onConfirmPayment"
                  >I have already paid</a>
              </div>
            </template>
          </div>
        </div>
      </section>
    </div>

    <div class="iframe bg-modal-black" v-if="isSnapOy">
      <div class="close pointer" @click="closeSnapOy">
        <i class="icon-x text-white"></i>
      </div>
      <iframe
        v-if="oyPaymentLink !== ''"
        :width="!isMobile() ? 400 : 375"
        :height="!isMobile() ? 800 : 675"
        :src="oyPaymentLink"
        title="OY! Indonesia Payment Link"
      >
        <i class="icon-x"></i>
      </iframe>
    </div>

    <AppModal :withPageTitle="false" ref="modal-payment-confirmation" :type="'sm'">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="d-flex justify-content-center align-items-center mb-3">
              <img :src="`${baseUrl}/assets/images/static-icon/warning.png`" alt="Alert" class="w-25" />
            </div>
            <span class="regular">Please confirm your chosen payment method before proceeding</span>
            <button class="btn btn-slg btn-primary mt-4 mb-0 bold w-100" @click="confirmPaymentMethod">
              Yes, Confirm
            </button>
          </div>
        </div>
      </template>
    </AppModal>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VueHtml2pdf from 'vue-html2pdf'
import AppModal from '../components/AppModal.vue'
import Global from '../libs/Global.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

export default {
  extends: Global,
  name: 'PaymentDetails',
  components: {
    AppModal,
    VueHtml2pdf,
  },
  data() {
    return {
      oyPaymentLink: '',
      isPaymentMethod: null,
      selectedPaymentMethod: 'N',
      paymentStatus: 'W',
      previewImage: null,
      fileproof: null,
      fileType: null,
      conf: {
        rule_type: 'jpeg,jpg,png',
        max_image_size: 5000000,
      },
      isSnapOy: false,
      oyredirect: false,
      pageNumber: 1,
      ones: ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
             'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'],
      tens: ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'],
      thousand: ['', 'Thousand', 'Million', 'Billion']
    }
  },
  created() {
    this.getPaymentData({
      path: ((this.$route || {}).params || {}).pageSlug,
      callback: (res) => {
        this.oyPaymentLink = res.data.payment_link
      }
    })

    this.getChannels()

    this.$store.commit('SET_STATIC_SEO', {
      title: 'Payment Details',
    })
  },
  computed: {
    ...mapFields('usePaymentPageStore', ['paymentData']),
    ...mapFields(['channels']),
    discount() {
      return this.paymentData.price - this.paymentData.total_amount
    },
    trxYear() {
      let transactionDate = this.paymentData.transaction_date

      if(transactionDate) {
        const year = new Date(transactionDate)
        let yearDate = year.getFullYear()
        return yearDate.toString().substring(2)
      }
      return ''
    },

    numberOfInstallment() {
      const installments = this.paymentData.installments
      const currentOrderId = this.paymentData.order_id

      const installment = installments.find(item => item.order_id === currentOrderId);

      if(installment) {
        const paymentNumber = installment.num_of_payment;
        const formattedNumber = paymentNumber.toString().padStart(3, '0');

        return formattedNumber;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('usePaymentPageStore', ['getPaymentData', 'getPaymentStatus']),
    ...mapActions(['getChannels']),
    async closeSnapOy() {
      this.isSnapOy = false
    },
    formatFullPrice(price) {
      return price.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
    },

    convertToWords(num) {
        if (num === 0) return 'Zero';

        let words = '';
        for (let i = 0; i < this.thousand.length; i++) {
            if (num % 1000 !== 0) {
                words = this.convert(num % 1000) + this.thousand[i] + ' ' + words;
            }
            num = Math.floor(num / 1000);
        }
        return words.trim();
    },

    convert(num) {
        if (num === 0) return '';
        else if (num < 20) return this.ones[num] + ' ';
        else if (num < 100) return this.tens[Math.floor(num / 10)] + ' ' + this.convert(num % 10);
        else return this.ones[Math.floor(num / 100)] + ' Hundred ' + this.convert(num % 100);
    },

    formatAmountInWords(amount) {
        const formattedAmount = this.convertToWords(amount);
        return formattedAmount.charAt(0).toUpperCase() + formattedAmount.slice(1) + ' Rupiah';
    },

    onConfirmPayment() {
      //  name: 'ConfirmPayment', params: {pageSlug: paymentData.order_id} 
      this.$store.state.loading.screen = true
      setTimeout(() => {
        this.getPaymentStatus({
          path: this.paymentData.order_id,
          callback: (res) => {
            if (res.data.payment_status === 'Success') {
              return this.$router.push({name: 'ConfirmPayment', params: {pageSlug: this.paymentData.order_id}})
            }
  
            return this.$router.push({name: 'MyPurchases'})
          }
        });
      }, 1000)
    },
    numFormatter(num) {
      return Math.abs(Number(num)) >= 1.0e+9

      ? Math.abs(Number(num)) / 1.0e+9 + "B"
      
      : Math.abs(Number(num)) >= 1.0e+6

      ? Math.abs(Number(num)) / 1.0e+6 + "M"

      : Math.abs(Number(num)) >= 1.0e+3

      ? Math.abs(Number(num)) / 1.0e+3 + "K"

      : Math.abs(Number(num));
    },
    isModalStrip() {
      return this.paymentData.due_text !== ''
    },
    ordinal(number) {
      let ordinalNumber = '';

      switch(number) {
        case 1:
          ordinalNumber = 'First';
          break;

        case 2:
          ordinalNumber = 'Second';
          break;

        case 3:
          ordinalNumber = 'Third';
          break;

        case 4:
          ordinalNumber = 'Fourth';
          break;

        case 5:
          ordinalNumber = 'Fifth';
          break;

        case 6:
          ordinalNumber = 'Sixth';
          break;

        case 7:
          ordinalNumber = 'Seventh';
          break;

        case 8:
          ordinalNumber = 'Eighth';
          break;

        case 9:
          ordinalNumber = 'Ninth';
          break;

        case 10:
          ordinalNumber = 'Tenth';
          break;

        default:
          ordinalNumber = number;
          break;
      }

      return ordinalNumber;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'code',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

      return formatter.format(parseInt(price))
    },
    paymentChannelsById(categoryId) {
      return (this.paymentData.payment_channels || []).filter(p => p.group_id === categoryId);
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },

    scrollToTopSection() {
      const el = document.querySelector('.body-inner')

      if (el) {
        const elPosition = el.getBoundingClientRect().top
        const offsetPosition = elPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition - 0,
          behavior: 'smooth',
        })
      }
    },

    nextToPay() {
      if (this.oyredirect) {
        window.location.href = this.paymentData.payment_link
        return
      }
      const body = document.querySelector('body')
      const html = document.querySelector('html')
      body.classList.add('modal-open')
      html.classList.add('modal-open')

      setTimeout(() => {
        const closebtn = document.querySelector('.close.pointer')

        closebtn.addEventListener('click', () => {
          const body = document.querySelector('body')
          const html = document.querySelector('html')

          body.classList.remove('modal-open')
          html.classList.remove('modal-open')
        })
      }, 300)

      this.isSnapOy = true
    },

    confirmPaymentMethod() {
      this.$refs['modal-payment-confirmation'].close()
      this.$store.state.loading.screen = true

      setTimeout(() => {
        this.$store.state.loading.screen = false
        this.selectedPaymentMethod = 'Y'
        this.scrollToTopSection()
      }, 5000)
    },

    sendProofOfTransfer() {
      this.paymentStatus = 'WC'
      this.$refs['modal-proofofpayment'].close()
      this.$store.state.loading.screen = true

      setTimeout(() => {
        this.$store.state.loading.screen = false
        this.$refs['modal-submit-proof'].show()
      }, 5000)
    },

    exportInvoiceToPDF() {
      this.$refs.documentInvoice.generatePdf()
    },
    exportReceiptToPDF() {
      this.$refs.documentReceipt.generatePdf()
    },
  },
}
</script>

<style lang="scss" scoped>
html.modal-open,
body.modal-open {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.sticky-20px {
  position: sticky;
  top: 20px;
  z-index: 4;
}
.sticky-70px {
  position: sticky;
  top: 70px;
  z-index: 4;
}

.small-short-event {
  .img {
    position: relative;
    width: 100px;
    height: 90px;
    border-radius: 12px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}

.payment_channel {
  max-height: 40px;
}
.iframe{
  padding-bottom: 100px !important;
}

</style>

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http, httpAthletes } from '../../config/http'
import store from '../index'
import router from '../../router/index'

export default {
  namespaced: true,

  state: {},

  getters: {
    getField,
  },

  mutations: {
    updateField,
  },

  actions: {
    async getLoginContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/login`, {
          headers: Gen.apiHeader(),
        })
        
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },

    
    async doLoginWithAthletes({rootState}, respData) {
      try {
        let reqProfilAthletes = await httpAthletes.get("/athlete/get-profile", {
          headers: Gen.apiHeaderAuthAthletes(respData.access_token),
        })
        const respProfilAthletes = reqProfilAthletes.data.data
        respData.user = respProfilAthletes

        const resp = await Gen.apiRest('/oauth/login', { data: Object.assign({}, respData) }, 'POST')

        store.commit('updateUserToken', (((resp || {}).data || {}).data || {}).token)

        store.dispatch('watchAuth')

        router.push({name: 'Dashboard'})
      } catch (err) {
        console.log(err)
      }
    },
  },
}

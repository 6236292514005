<template>
  <div class="mobile-version-view py-5">
    <section class="mt-0 pt-4 bb-1px-grey pb-4 overflow-visible">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="img">
              <picture>
                <source :src="uploader(((runEventDetails || {}).event || {}).banner_image)" type="image/webp" />
                <img
                  :src="uploader(((runEventDetails || {}).event || {}).banner_fallback_image)"
                  :alt="((runEventDetails || {}).event || {}).banner_alt_image"
                  class="w-100 bdrd-24px"
                />
              </picture>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <div>
              <h4 class="extrabold" :class="!isMobile() ? 'fs-275em' : 'fs-225em'">
                {{ ((runEventDetails || {}).event || {}).name }}
              </h4>
              <p>
                {{ ((runEventDetails || {}).event || {}).excerpt }}
              </p>
              <span
                >By
                <a
                  :href="((runEventDetails || {}).event || {}).partner_url"
                  target="_blank"
                  class="text-link semibold text-decoration-none"
                  >{{ ((runEventDetails || {}).event || {}).partner }}</a
                ></span
              >
              <template v-if="((runEventDetails || {}).event || {}).status === 'E'">
                <div class="row pt-4 pb-3">
                  <div class="col-md-4">
                    <div class="item-detail-text-icon d-flex align-items-start">
                      <div class="icon d-flex justify-content-center align-items-center">
                        <i class="fa fa-ticket-alt gamma-color fs-1em"></i>
                      </div>
                      <div class="mx-2 w-75">
                        <span class="mt-2 gamma-color semibold">{{ titleSlotsAvailable }}</span
                        ><br />
                        <span class="regular grey-color">{{ descriptionSlotsAvailable }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="item-detail-text-icon d-flex align-items-start my-3">
                      <div class="icon d-flex justify-content-center align-items-center">
                        <i class="fa fa-calendar gamma-color fs-1em"></i>
                      </div>
                      <div class="mx-2 w-75">
                        <span class="mt-2 gamma-color semibold">{{
                          formatDateWithoutWeekday(((runEventDetails || {}).event || {}).race_date)
                        }}</span
                        ><br />
                        <span class="regular grey-color"
                          >{{ formatDate(((runEventDetails || {}).event || {}).race_date) }},
                          {{ formatTime(((runEventDetails || {}).event || {}).race_time) }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="item-detail-text-icon d-flex align-items-start">
                      <div class="icon d-flex justify-content-center align-items-center">
                        <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                      </div>
                      <div class="mx-2 w-75">
                        <span class="mt-2 gamma-color semibold"
                          >{{ ((runEventDetails || {}).event || {}).program_name }},
                          {{ ((runEventDetails || {}).event || {}).program_country }}</span
                        ><br />
                        <span class="regular grey-color">{{
                          ((runEventDetails || {}).event || {}).program_race_village
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-0 pt-0 overflow-visible">
      <div>
        <div class="tabs link">
          <ul class="nav nav-tabs sticky-top-mobile p-l-50 p-r-50" v-scroll-spy-active v-scroll-spy-link>
            <li class="text-center nav-item-v-scroll pb-3 pl-2">
              <a>
                <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                  <span class="extrabold">Overview</span>
                </div>
              </a>
            </li>
            <template v-if="((runEventDetails || {}).event || {}).status === 'E'">
              <li class="text-center nav-item-v-scroll pb-3">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold">Package</span>
                  </div>
                </a>
              </li>
              <li class="text-center nav-item-v-scroll pb-3">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold">Hotel</span>
                  </div>
                </a>
              </li>
              <li class="text-center nav-item-v-scroll pb-3">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold text-no-wrap">Important Information</span>
                  </div>
                </a>
              </li>
              <li class="text-center nav-item-v-scroll pb-3">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold">Gallery</span>
                  </div>
                </a>
              </li>
              <li class="text-center nav-item-v-scroll pb-3">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold">FAQS</span>
                  </div>
                </a>
              </li>
            </template>
            <li class="text-center nav-item-v-scroll pb-3" v-if="((runEventDetails || {}).event || {}).status === 'U'">
              <a>
                <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                  <span class="extrabold text-no-wrap">Announcement List</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="container">
            <div v-scroll-spy="{ offset: 120 }">
              <div class="bb-1px-grey pb-5">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <span class="extrabold">#1</span>
                    <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">About The Event</h2>
                    <div class="grey-color mt-3" v-html="((runEventDetails || {}).event || {}).race_overview"></div>
                  </div>
                </div>
              </div>
              <template v-if="((runEventDetails || {}).event || {}).status === 'E'">
                <div class="important-information bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#2</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Important Information</h2>
                      <div class="my-4">
                        <div class="mt-5">
                          <span class="sub-title-section mb-2">
                            <div class="line-sub-title"></div>
                            Important Notes
                          </span>
                          <div class="my-4">
                            <div class="row">
                              <div
                                class="col-md-3 my-2"
                                v-for="(v, k) in ((runEventDetails || {}).event || {}).important_dates"
                              >
                                <div class="item-detail-text-icon d-flex align-items-start">
                                  <div class="icon d-flex justify-content-center align-items-center">
                                    <i class="icon-map-pin gamma-color fs-1em"></i>
                                  </div>
                                  <div class="mx-2 w-75">
                                    <span class="mt-2 gamma-color semibold">{{ v.title }}</span
                                    ><br />
                                    <div class="regular grey-color" v-html="v.description"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-5">
                          <span class="sub-title-section mb-2">
                            <div class="line-sub-title"></div>
                            Important Location
                          </span>
                          <div class="my-4">
                            <div class="row">
                              <div
                                class="col-md-3 my-2"
                                v-for="(v, k) in ((runEventDetails || {}).event || {}).important_locations"
                              >
                                <div class="item-detail-text-icon d-flex align-items-start">
                                  <div class="icon d-flex justify-content-center align-items-center">
                                    <i class="icon-map-pin gamma-color fs-1em"></i>
                                  </div>
                                  <div class="mx-2 w-75">
                                    <span class="mt-2 gamma-color semibold">{{ v.name }}</span
                                    ><br />
                                    <a
                                      v-for="(v1, k1) in v.descriptions"
                                      :href="v1.url"
                                      target="_blank"
                                      class="regular grey-color text-underline pointer"
                                      >{{ v1.value }}</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="package-catalog bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#3</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Package Catalog</h2>
                      <div class="my-4" v-for="(v, k) in ((runEventDetails || {}).event || {}).pricings" :key="k">
                        <h5 class="bold">
                          {{ v.event_itinerary_name }} - {{ v.event_itinerary_title }} -
                          {{ definePackageRemainingSlotsText(v.stock, v.prices) }}
                        </h5>
                        <div class="row">
                          <div v-for="(v1, k1) in v.prices" :key="k1" class="col-md-12">
                            <template
                              v-if="
                                v1.package_total_runners <= v.stock &&
                                !v1.is_closed &&
                                ((runEventDetails || {}).event || {}).status === 'E'
                              "
                            >
                              <div class="bg-white mt-3 price-box bdrd-24px py-4 px-4">
                                <div class="top d-flex justify-content-between mb-3 align-items-center">
                                  <div>
                                    <h5 class="mb-0 bold">{{ v1.package_name }}</h5>
                                  </div>
                                  <div>
                                    <a @click="detailPackage(v1, v)" class="text-link alpha-color pointer"
                                      >See Details</a
                                    >
                                  </div>
                                </div>
                                <div class="mb-0 bb-1px-grey">
                                  <div class="item-detail-text-icon d-flex align-items-center">
                                    <div class="icon d-flex justify-content-center align-items-center">
                                      <i class="icon-credit-card gamma-color fs-1em"></i>
                                    </div>
                                    <div class="mx-2 mb-3 w-75">
                                      <span
                                        class="gamma-color semibold"
                                        v-if="!v1.is_one_time_payment_only && !v1.no_partial"
                                        >Partial and One Time Payment Available</span
                                      >
                                      <span class="gamma-color semibold" v-else>One Time Payment Available</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="bb-1px-grey pb-3">
                                  <span class="regular grey-color">
                                    {{ v1.package_description }}
                                  </span>
                                </div>
                                <div :id="`open-${k}-${k1}`" class="bb-1px-grey py-4 d-none">
                                  <div class="row">
                                    <div class="col-md-8 br-grey-black p-r-10">
                                      <span class="sub-title-section fs-125em">
                                        <div class="line-sub-title"></div>
                                        Timeline of Payment
                                      </span>
                                      <ul class="timeline" v-if="typeOfTransaction == 'partial-payment'">
                                        <li
                                          class="timeline-item"
                                          v-for="(installment, indexInstallment) in v1.installments"
                                          :key="indexInstallment"
                                        >
                                          <div class="timeline-icon">{{ indexInstallment + 1 }}</div>
                                          <h5 class="bold">{{ ordinal(indexInstallment + 1) }} Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(installment.value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(installment.expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                      <ul class="timeline" v-if="typeOfTransaction == 'one-time-payment'">
                                        <li class="timeline-item">
                                          <div class="timeline-icon">1</div>
                                          <h5 class="bold">One Time Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(v1.special_value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(((v1 || {}).installments[0] || {}).expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group m-t-5">
                                        <label for="" class="required bold">Select Type of Transaction</label>
                                        <div class="mt-3">
                                          <b-form-radio
                                            v-if="!v1.no_partial"
                                            v-model="typeOfTransaction"
                                            :name="`po-${k}-${k1}-partial`"
                                            value="partial-payment"
                                            >Partial Payment</b-form-radio
                                          >
                                          <b-form-radio
                                            v-model="typeOfTransaction"
                                            class="my-2"
                                            :name="`po-${k}-${k1}-one`"
                                            value="one-time-payment"
                                            >One Time Payment</b-form-radio
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="pt-3 d-flex justify-content-between align-items-center">
                                  <div v-if="typeOfTransaction === 'one-time-payment'">
                                    <span class="bold alpha-color"
                                      ><b class="text-line-through">IDR {{ numFormatter(v1.value) }}</b> to IDR
                                      {{ numFormatter(v1.special_value) }}/ Runner</span
                                    >
                                  </div>
                                  <div v-else>
                                    <span class="bold alpha-color"
                                      ><b>IDR {{ numFormatter(v1.value) }}</b
                                      >/ Runner</span
                                    >
                                  </div>
                                  <div class="d-flex mt-3 flex-column">
                                    <button
                                      @click="
                                        choosePackage(k, k1, v.event_itinerary_slug, v1.package_slug, v1.no_partial)
                                      "
                                      class="btn btn-slg btn-primary bold mb-0"
                                    >
                                      Choose
                                    </button>
                                    <button
                                      @click="cancelChoosePackage(k, k1)"
                                      class="btn btn-slg btn-outline-main ml-0 bold mb-0 d-none ml-0 mt-"
                                      :id="`btn-open-${k}-${k1}`"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template
                              v-if="
                                v1.package_total_runners > v.stock ||
                                v1.is_closed ||
                                ((runEventDetails || {}).event || {}).status !== 'E'
                              "
                            >
                              <div class="bg-grey mt-3 price-box bdrd-24px py-4 px-4">
                                <div class="top d-flex justify-content-between mb-3 align-items-center">
                                  <div>
                                    <h5 class="mb-0 bold">{{ v1.package_name }}</h5>
                                  </div>
                                  <div>
                                    <a @click="detailPackage(v1, v)" class="text-link alpha-color pointer"
                                      >See Details</a
                                    >
                                  </div>
                                </div>
                                <div class="mb-0 bb-1px-grey">
                                  <div class="item-detail-text-icon d-flex align-items-center">
                                    <div class="icon d-flex justify-content-center align-items-center">
                                      <i class="icon-credit-card gamma-color fs-1em"></i>
                                    </div>
                                    <div class="mx-2 mb-3 w-75">
                                      <span
                                        class="gamma-color semibold"
                                        v-if="!v1.is_one_time_payment_only && !v1.no_partial"
                                        >Partial and One Time Payment Available</span
                                      >
                                      <span class="gamma-color semibold" v-else>One Time Payment Available</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="bb-1px-grey pb-3">
                                  <span class="regular grey-color">
                                    {{ v1.package_description }}
                                  </span>
                                </div>
                                <div :id="`open-${k}-${k1}`" class="bb-1px-grey py-4 d-none">
                                  <div class="row">
                                    <div class="col-md-8 br-grey-black p-r-10">
                                      <span class="sub-title-section fs-125em">
                                        <div class="line-sub-title"></div>
                                        Timeline of Payment
                                      </span>
                                      <ul class="timeline" v-if="typeOfTransaction == 'partial-payment'">
                                        <li
                                          class="timeline-item"
                                          v-for="(installment, indexInstallment) in v1.installments"
                                          :key="indexInstallment"
                                        >
                                          <div class="timeline-icon">{{ indexInstallment + 1 }}</div>
                                          <h5 class="bold">{{ ordinal(indexInstallment + 1) }} Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(installment.value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(installment.expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                      <ul class="timeline" v-if="typeOfTransaction == 'one-time-payment'">
                                        <li class="timeline-item">
                                          <div class="timeline-icon">1</div>
                                          <h5 class="bold">One Time Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(v1.special_value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(((v1 || {}).installments[0] || {}).expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group m-t-5">
                                        <label for="" class="required bold">Select Type of Transaction</label>
                                        <div class="mt-3">
                                          <b-form-radio
                                            v-if="!v1.no_partial"
                                            v-model="typeOfTransaction"
                                            :name="`po-${k}-${k1}-partial`"
                                            value="partial-payment"
                                            >Partial Payment</b-form-radio
                                          >
                                          <b-form-radio
                                            v-model="typeOfTransaction"
                                            class="my-2"
                                            :name="`po-${k}-${k1}-one`"
                                            value="one-time-payment"
                                            >One Time Payment</b-form-radio
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="pt-3 d-flex justify-content-between align-items-center">
                                  <div v-if="typeOfTransaction === 'one-time-payment'">
                                    <span class="bold alpha-color"
                                      ><b class="text-line-through">IDR {{ numFormatter(v1.value) }}</b> to IDR
                                      {{ numFormatter(v1.special_value) }}/ Runner</span
                                    >
                                  </div>
                                  <div v-else>
                                    <span class="bold alpha-color"
                                      ><b>IDR {{ numFormatter(v1.value) }}</b
                                      >/ Runner</span
                                    >
                                  </div>
                                  <div class="d-flex mt-3 flex-column">
                                    <button disabled class="btn btn-slg btn-primary bold mb-0">Choose</button>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#4</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Hotel</h2>
                      <div class="my-3">
                        <div class="d-flex justify-content-between" v-for="(v, k) in allHotels" :key="k">
                          <div>
                            <h5 class="alpha-color bold">{{ v.event_hotel_name }}</h5>
                            <span class="grey-color regular">{{ v.event_hotel_address }}</span>
                          </div>
                          <a class="alpha-color pointer text-link" @click="openHotel(v)">See Details</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#5</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Gallery</h2>
                      <div class="my-4">
                        <div class="row" v-if="(((runEventDetails || {}).gallery || {}).data || {}).length > 0">
                          <div
                            :class="isOnlyMobile() ? 'col-6' : 'col-4'"
                            v-for="(v, k) in ((runEventDetails || {}).gallery || {}).data"
                          >
                            <div class="card-hover small bdrd-24px overflow-hidden mb-3">
                              <picture>
                                <source :srcset="uploader(v.image)" type="image/webp" />
                                <img :src="uploader(v.fallback_image)" alt="" class="bdrd-24px" />
                              </picture>
                              <div class="detail">
                                <a :href="uploader(v.image)" data-fancybox="gallery2">
                                  <div class="social-bullet">
                                    <i class="icon-eye"></i>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-6">
                            <h6 class="semibold ls-0">Gallery is not available right now</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#6</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">FAQs</h2>
                      <div class="my-4">
                        <div class="accordion-menu">
                          <ul class="ul-accordion">
                            <li class="li-accordion" v-for="(v, k) in (runEventDetails || {}).faqs" :key="k">
                              <input type="checkbox" checked />
                              <i class="arrow"></i>
                              <h2 class="bold">{{ v.faq_question }}</h2>
                              <p>
                                {{ v.faq_answer }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="bb-1px-grey py-5" v-if="((runEventDetails || {}).event || {}).status === 'U'">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <span class="extrabold">#4</span>
                    <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Announcement List</h2>
                    <div class="my-4">
                      <div class="row">
                        <div class="col">
                          <p class="regular">
                            Join our Announcement List today and embark on a journey that combines your love for running
                            with the excitement of exploring vibrant cities. Prepare to lace up your running shoes, set
                            new personal records, and create memories that will last a lifetime.
                          </p>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-md-3">
                          <a
                            :href="isDev ? 'http://localhost:8082' : baseUrl + '/world'"
                            class="btn btn-slg btn-primary bold w-100"
                            v-if="isBtnGetSlotNow && ((runEventDetails || {}).event || {}).status === 'U'"
                          >
                            Join Announcement List
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    uploader: {
      type: Function,
    },
    runEventDetails: {
      type: Object,
    },
    isMobile: {
      type: Function,
    },
    isBtnGetSlotNow: {
      type: Boolean,
    },
    isDev: {
      type: Boolean,
    },
    titleSlotsAvailable: {
      type: [Number, String],
    },
    descriptionSlotsAvailable: {
      type: [Number, String],
    },
    formatDateWithoutWeekday: {
      type: Function,
    },
    formatDate: {
      type: Function,
    },
    formatTime: {
      type: Function,
    },
    definePackageRemainingSlotsText: {
      type: Function,
    },
    typeOfTransaction: {
      type: String,
    },
    ordinal: {
      type: Function,
    },
    numFormatter: {
      type: Function,
    },
    formatDateWithHour: {
      type: Function,
    },
    allHotels: {
      type: Array,
    },
    choosePackage: {
      type: Function,
    },
  },
}
</script>

<style>
</style>
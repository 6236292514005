import Vue from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Scrollspy from 'vue2-scrollspy'
import { VueStars } from 'vue-stars'
import VueSocialSharing from 'vue-social-sharing'

import VueMeta from 'vue-meta'
import vSelect from 'vue-select'
import VCalendar from 'v-calendar'
import store from './store'
import router from './router'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css'

import '@/libs/alias'
import '@/libs/vee-validate'

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
})

Vue.config.productionTip = false
Vue.component('v-select', vSelect)
Vue.component('vue-stars', VueStars)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Scrollspy)
Vue.use(VCalendar)
Vue.use(VueSocialSharing)

store.dispatch("watchAuth").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
})
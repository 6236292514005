<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>

      <template v-if="!isMobile()">
        <section class="mt-0 pt-5 overflow-visible">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-5 p-r-30 mt-4">
                <div class="mb-4">
                  <router-link
                    :to="{ name: 'Dashboard' }"
                    class="d-flex align-items-center"
                  >
                    <i class="icon-chevron-left fs-125em text-dark mb-1"></i>
                    <h5 class="bold ls-0 fs-1em ml-2 mb-0 text-dark">Back</h5>
                  </router-link>
                </div>
                <picture>
                  <source :srcset="uploader((update || {}).image)" type="image/webp">
                  <img :src="uploader((update || {}).fallback_image)" :alt="(update || {}).title" class="w-100 mt-2 mb-3" />
                </picture>
                <h5 class="bold text-dark ls-0">
                  {{ (update || {}).title }}
                </h5>
                <span class="regular"
                  >{{ (update || {}).description }}</span
                >
              </div>
              <div class="col-md-4 mt-4">
                <div class="sticky-100px">
                  <div class="b-grey-black px-4 py-4 bdrd-12px">
                    <div class="d-flex mb-3">
                      <i class="icon-help-circle"></i>
                      <h5 class="bold ml-2">Help Center</h5>
                    </div>
                    <div class="wrap-help-nav mb-3">
                      <router-link :to="{ name: 'FAQ' }" class="text-dark-custom">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="wrap-text pr-4">
                            <span class="bold d-block">FAQ</span>
                            <p class="d-block">
                              Don't know where to turn? Our FAQ section is here to help with any questions.
                            </p>
                          </div>
                          <div>
                            <i class="icon-chevron-right fs-125em"></i>
                          </div>
                        </div>
                      </router-link>
                    </div>

                    <div class="wrap-help-nav mb-3">
                      <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="text-dark-custom">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="wrap-text pr-4">
                            <span class="bold d-block">Chat Whatsapp</span>
                            <p class="d-block">Get in touch with us via WhatsApp for fast and easy communication.</p>
                          </div>
                          <div>
                            <i class="icon-chevron-right fs-125em"></i>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div class="wrap-help-nav mb-3">
                      <router-link :to="{ name: 'ContactUs' }" class="text-dark-custom">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="wrap-text pr-4">
                            <span class="bold d-block">Send a Message</span>
                            <p class="d-block">
                              Send us a message via our contact form and we'll respond as soon as possible.
                            </p>
                          </div>
                          <div>
                            <i class="icon-chevron-right fs-125em"></i>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <div class="mobile-version-view py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12 mt-5">
                <div class="d-flex align-items-center mb-4">
                  <router-link
                    :to="{ name: 'Notification' }"
                  >
                    <i class="icon-chevron-left fs-125em text-dark"></i>
                  </router-link>
                  <h5 class="bold ls-0 fs-1em ml-2 mb-0">Back</h5>
                </div>
              </div>
              <div class="col-md-12">
                <picture>
                  <source :srcset="uploader((update || {}).image)" type="image/webp">
                  <img :src="uploader((update || {}).fallback_image)" :alt="(update || {}).title" class="w-100 mb-3" />
                </picture>
                <p class="bold text-dark ls-0">
                  {{ (update || {}).title }}
                </p>
                <span class="regular"
                  >{{ (update || {}).description }}</span
                >
              </div>
              <div class="col-md-4 mt-4">
                <div class="b-grey-black px-4 py-4 bdrd-12px">
                  <div class="d-flex mb-3">
                    <i class="icon-help-circle"></i>
                    <h5 class="bold ml-2">Help Center</h5>
                  </div>
                  <!-- <span><a href="" class="text-link">Browse Our FAQs</a> or <a href="" class="text-link">Contact Us</a></span> -->
                  <div class="d-flex justify-content-between align-items-center mt-2">
                    <div class="pr-4">
                      <router-link :to="{ name: 'FAQ' }" class="bold text-dark-custom">FAQ</router-link>
                      <p>Don't know where to turn? Our FAQ section is here to help with any questions.</p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-2">
                    <div class="pr-4">
                      <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="bold text-dark-custom"
                        >Chat Whatsapp</a
                      >
                      <p>Get in touch with us via WhatsApp for fast and easy communication.</p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="pr-4">
                      <router-link :to="{ name: 'ContactUs' }" class="bold text-dark-custom"
                        >Send a Message</router-link
                      >
                      <p>Send us a message via our contact form and we'll respond as soon as possible.</p>
                    </div>
                    <div>
                      <i class="icon-chevron-right fs-125em"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row justify-content-end">
                  <div class="col-md-6 d-flex justify-content-end">
                    <router-link
                      :to="{
                        name: 'Notification',
                      }"
                      class="btn btn-outline-main ml-0 semibold mx-2 d-flex align-self-start mt-3 align-items-center"
                      >Back</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  extends: Global,
  name: 'NotificationDetails',
  components: {
    Header,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields(['channels']),
    ...mapFields('usePrivatePageStore', ['update']),
  },
  created() {
    this.getNotificationDetails((this.$route.params || {}).pageSlug)

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions(['getChannels']),
    ...mapActions('usePrivatePageStore', ['getNotificationDetails']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<template>
  <div class="home">
    <template>
      <CompletingData 
        :verifying-email="isVerifyingEmail"
        :changing-email="isChangingEmail"
        :verifying-email-success="isVerifyingEmailSuccess"
        :verifying-phone-input="isVerifyingPhoneInput"
        :verifying-phone-methods="isVerifyingPhoneMethods"
        :verifying-phone-code="isVerifyingPhoneCode"
        :verifying-phone-success="isVerifyingPhoneSuccess"
        :filling-citizenship="isFillingCitizenship"
        :filling-gender="isFillingGender"
        :filling-birthday="isFillingBirthday"
        :filling-age="isFillingAge"
        :under-age="isUnderAge"
        :filling-avatar="isFillingAvatar"
        :filling-basic-success="isFillingBasicSuccess"
        :v-format-date="formatDate"
        @verify-email="v => onVerifyEmail(v)"
        @verify-change-email="onVerifyChangeEmail"
        @verify-change-phone="onVerifyChangePhone"
        @email-verified="onEmailVerified"
        @send-new-code-email="onSendNewCodeEmail"
        @send-new-code-phone="onSendNewCodePhone"
        @send-phone-verification-code="v => onSendPhoneVerificationCode(v)"
        @cancel-verify-change-email="onCancelVerifyChangeEmail"
        @verify-phone="v => onVerifyPhone(v)"
        @phone-verified="onPhoneVerified"
        @citizenship-filled="v => onCitizenshipFilled(v)"
        @gender-filled="v => onGenderFilled(v)"
        @birthday-filled="v => onBirthdayFilled(v)"
        @age-filled="onAgeFilled"
        @back-birthday="onBackBirthday"
        @under-age-redirect="handleUnderAgeRedirect"
        @avatar-filled="v => onAvatarFilled(v)"
        @avatar-skipped="onAvatarSkipped"
        @survey-completed="onSurveyCompleted"
        :out-form-data="formData"
        @confirm-input-number="v => onConfirmInputNumber(v)"
        :email-alert-danger="showDangerAlertEmail"
        :email-alert-success="showSuccessAlertEmail"
        :is-show-alert-email="isShowingAlertEmail"
        :phone-alert-danger="showDangerAlertPhone"
        :phone-alert-success="showSuccessAlertPhone"
        :is-show-alert-phone="isShowingAlertPhone"
        :err-message-text="errMessage"
        @register="changeEmail => doLogin(changeEmail)"
      />
    </template>

    <template v-if="!completingProcess()">
      <div class="body-inner">
        <section class="authentication-section" :class="isTablet() ? 'h-auto' : ''">
          <div class="row no-gutters">
            <div :class="isMobile() ? 'order-5 col-sm-12' : 'col-7'">
              <div
                class="left-section"
                :class="!isMobile() ? 'h-100 p-100' : isTablet() ? 'd-block h-auto' : 'mt-5 pt-0 px-4 h-auto'"
              >
                <h4 class="extrabold">Sign In</h4>
                <div class="mt-3 mb-4">
                  <ValidationObserver ref="LoginForm" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(doLogin())">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="" class="required bold">Email Address</label>
                            <input
                              tabindex="1"
                              type="text"
                              class="form-control main-control"
                              placeholder="Email address"
                              v-model="formData.email"
                              autocomplete="username"
                            />
                            <VValidate name="Email Address" v-model="formData.email" rules="required|email" />
                          </div>
                          <div class="form-group mt-3">
                            <div class="d-flex justify-content-between align-items-center">
                              <label for="" class="required bold">Password</label>
                              <router-link :to="{ name: 'ForgotPassword' }" class="text-link bold"
                                >Forgot Password?</router-link
                              >
                            </div>
                            <b-input-group class="position-relative">
                              <input
                                id="password"
                                tabindex="2"
                                type="password"
                                class="form-control main-control"
                                placeholder="Password"
                                v-model="formData.password"
                                autocomplete="current-password"
                              />
                              <template #append>
                                <b-icon
                                  v-if="!showPassword"
                                  icon="eye-slash"
                                  font-scale="1"
                                  class="position-absolute view-password"
                                  @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                ></b-icon>
                                <b-icon
                                  v-else
                                  icon="eye"
                                  font-scale="1"
                                  class="position-absolute view-password main-color"
                                  @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                ></b-icon>
                              </template>
                            </b-input-group>
                            <VValidate name="Password" v-model="formData.password" rules="required" />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12">
                          <div
                            class="d-flex justify-content-between align-items-center"
                            :class="isMobile() ? 'flex-column' : ''"
                          >
                            <button
                              type="submit"
                              class="btn btn-slg btn-primary bold"
                              :class="isMobile() ? 'w-100 mb-4' : ''"
                            >
                              Sign In
                            </button>
                            <span class="medium grey-color d-flex" :class="isTablet() ? 'align-self-start' : ''"
                              >Don't have an account yet?
                              <router-link
                                :to="{ name: 'Register' }"
                                class="bold text-link d-flex align-items-center ml-1"
                                >Sign Up</router-link
                              ></span
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div
              class="col-lg-5"
              :class="!isMobile() ? 'col-sm-12' : isTablet() ? 'login-hero w-100 col-sm-12' : 'order-1 col-sm-5'"
            >
              <div
                class="right-section"
                :style="`
                background-image: url('${uploader('hero/signin-hero.jpg')}');
                background-position: center;
                background-size: cover;
              `"
                :class="isMobile() ? '' : 'h-100'"
              >
                <div class="bg-overlay" data-style="13"></div>
                <div>
                  <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                    <div>
                      <router-link :to="{ name: 'Home' }">
                        <picture>
                          <source
                            media="(min-width: 992px)"
                            :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                            type="image/webp"
                          />
                          <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                          <img
                            class="brand-logo"
                            v-if="isOnlyMobile()"
                            :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                            alt="RunDeGlobe Logo"
                            width="142"
                            height="43"
                          />
                          <img
                            class="brand-logo"
                            v-else
                            :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                            alt="RunDeGlobe Logo"
                            width="175"
                            height="53"
                          />
                        </picture>
                      </router-link>
                    </div>
                    <div :class="isTablet() ? 'mt-5' : ''">
                      <div class="title-content">
                        <h2 class="text-white">Sign In to</h2>
                        <p style="color: #f7af2f">Run With Us</p>
                      </div>
                      <span class="text-white bold" :class="isTablet() ? 'd-block mt-2' : ''"
                        >Sign in with your data that you have entered during your account registration</span
                      >
                    </div>
                    <div :class="isTablet() ? 'mt-5' : ''">
                      <router-link :to="{ name: 'Home' }" class="text-white text-underline bold"
                        >Back to Home</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="menu-toggle" @click="openMenuToggle">
        <span class="fa fa-comments"></span>
      </div>
      <div class="menu-round">
        <div class="btn-app">
          <a
            :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(
              /\D/g,
              ''
            )}&text&type=phone_number`"
            target="_blank"
            class="fab fa-whatsapp text-white p-3"
          >
          </a>
        </div>
        <div class="btn-app">
          <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
            <i class="fa fa-envelope-square"></i>
          </a>
        </div>
      </div>
    </template>

    <AppAlert
      ref="modal-error"
      vref="modal-error"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
      }"
      icon-size="w-50"
      :title="messageErrTitle"
      :description="messageErrDescription"
      :button="{
        main: {
          text: 'Ok',
        },
      }"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthSharedVariables from '@/libs/AuthSharedVariables.vue'
import CompletingData from '@/views/atoms/register/CompletingData.vue'

export default {
  extends: AuthSharedVariables,
  components: {
    CompletingData,
  },
  name: 'Login',

  created() {
    this.getChannels()
    this.getLoginContent()
  },
}
</script>

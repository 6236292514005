import { getField, updateField } from 'vuex-map-fields'
import { http } from '@/config/http'
import Gen from '@/libs/Gen'
import router from "../../router/index"

export default {
  namespaced: true,
  state: {
    checkoutData: {
      event: {
        package_total_runners: null,
      },
      benefit_categories: [],
      benefit_items: [],
      gender_options: [],
    },
    checkoutContent: {
      sub_title: '',
      runner_information_1: {
        title: '',
        description: '',
      },
      runner_information_2: {
        title: '',
        description: '',
      },
      room_setup: {
        title: '',
        description: '',
      },
      overview: {
        title: '',
        description: '',
      },
      bedType: [],
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_CHECKOUT_DATA(state, data) {
      state.checkoutData = data.data
      state.checkoutContent = data.content
    },
  },

  actions: {
    async getEventData({ commit }, data) {
      const { slug, itinerary, pkg, callback = false } = data

      const token = Gen.getCookie("userAuthRdg")

      try {
        const res = await http.get(`/running-events/${slug}/detail`, {
          headers: Gen.apiHeaderAuth(token),
          params: {
            itinerary,
            pkg
          }
        })
        
        if (callback) {
          callback(res.data.data, res.data.content)
        }

        commit("SET_CHECKOUT_DATA", res.data)
      } catch (err) {
        if (err.response.status === 404) {
          return router.push({name: 'NotFound'})
        }
        return err
      }
    },

    async postTransactionPayload({rootState}, data) {
      const { payload, callback = false } = data
      
      try {
        const token = Gen.getCookie('userAuthRdg')

        const resp = await Gen.apiRest('/transactions', { 
          data: Object.assign({}, payload),
          headers: { ...Gen.apiHeaderAuth(token) },
        }, 'POST')

        if (resp.status === 201) {
          // dispatch("watchAuth", null, { root: true })

          if(resp.data.is_not_redirect == 'Y') {
            router.push({
              name: 'PaymentDetails',
              params: {
                pageSlug: resp.data.order_id,
              },
            })
          } else {
            router.push({
              name: 'transactionDetails',
              params: {
                pageSlug: resp.data.transaction_id
              }
            })
          }
        }
      } catch (error) {
        return error
      } finally {
        rootState.loading.screen = false
      }
    },

    async checkSecondRunner({ commit }, data) {
      const { email, callback = false } = data
      const token = Gen.getCookie('userAuthRdg')

      try {
        const res = await http.get('/second-runner/status', {
          headers: Gen.apiHeaderAuth(token),
          params: {
            email
          }
        })

        if (callback !== false) {
          callback(true, res)
        }
      } catch (error) {
        if (callback !== false) {
          callback(false, error)
        }
        return error
      }
    }
  },
}

<template>
  <div :id="vModalId" class="modal-window" :class="showModal ? 'active' : ''" v-if="withPageTitle">
    <div :class="type" class="wrapper-window">
      <div class="title-modal" v-if="!noHeader">
        <div class="text">
          <slot name="title-modal"></slot>
        </div>
        <a
          title="Close"
          href="javascript:;"
          25em="modal-close"
          v-if="customClose"
          @click.prevent="$emit('clicked', $event.target.value)"
        >
          <i class="icon-x"></i>
        </a>
        <a title="Close" href="javascript:;" class="modal-close" v-if="disableClose" @click="returnClose">
          <i class="icon-x"></i>
        </a>
        <a
          title="Close"
          href="javascript:;"
          class="modal-close"
          v-else-if="!disableClose && !customClose"
          @click="close"
        >
          <i class="icon-x"></i>
        </a>
      </div>
      <div class="second-window">
        <div
          :class="
            (!isCustomContent
              ? 'content px-2 pb-0 mb-0'
              : isTablet()
              ? 'custom-content tablet-custom-content'
              : 'custom-content') + (isFooter ? ' mb-5' : '')
          "
        >
          <div class="content-modal">
            <slot name="content-modal"></slot>
          </div>
        </div>
      </div>
      <div class="footer-window" v-if="isFooter">
        <div class="content mb-0">
          <slot name="footer-modal"></slot>
        </div>
      </div>
    </div>
  </div>
  <div :id="vModalId" class="modal-window not-title" :class="showModal ? 'active' : ''" v-else>
    <div :class="type" class="wrapper-window">
      <div class="title-modal">
        <h4 class="visibility-hidden">*</h4>
        <a title="Close" href="javascript:;" class="modal-close" v-if="disableClose" @click="returnClose">
          <i class="icon-x"></i>
        </a>
        <a title="Close" href="javascript:;" class="modal-close" v-else @click="close">
          <i class="icon-x"></i>
        </a>
      </div>
      <div class="second-window">
        <div class="content mt-0 pr-0 pl-0 pb-0 mb-0">
          <div class="content-modal">
            <slot name="content-modal"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AppModal',
  data() {
    return {
      showModal: false,
    }
  },
  props: {
    vModalId: {
      type: String,
      default: '',
    },
    withPageTitle: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'n',
    },
    isFooter: {
      type: Boolean,
      default: false,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    customClose: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    isCustomContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields(['closeModal']),
  },
  methods: {
    isTablet() {
      if (/iPad/i.test(navigator.userAgent)) {
        return true
      }
      return false
    },
    show() {
      this.showModal = true
    },

    close(e) {
      this.showModal = false
      if (this.closeModal) {
        this.$set(this, 'closeModal', false)
      }
      this.$emit('close', true)
    },

    hide() {
      this.showModal = false
      if (this.closeModal) {
        this.$set(this, 'closeModal', false)
      }
    },

    returnClose() {},
  },
}
</script>

<style lang="scss" scoped>
.wrapper-window {
  position: relative;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
}

.title-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  z-index: 99;
  padding: 1em 0 !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  top: 0;
  left: 0;
  background: #fff !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 4rem !important;
  * {
    font-size: 1.15em !important;
  }
  .text {
    h4 {
      p {
        font-size: 1.15em !important;
      }
    }
  }
}
.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
  line-height: 1.5 !important;

  @media only screen and (max-width: 768px) {
    bottom: 0;
    min-height: 300px !important;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    min-height: 400px !important;
  }

  &.not-title {
    .second-window {
      padding-top: 0 !important;
      .content {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .title-modal {
      border: none !important;
    }
  }

  .footer-window {
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 0;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 1rem 2rem;
    overflow: hidden;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
  .wrapper-window {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 100%;
    max-height: 90vh !important;
    @media only screen and (max-width: 768px) {
      box-shadow: -2px 3px 15px -5px rgba(0, 0, 0, 0.25);
      bottom: 0;
      left: 0;
      transform: translate(0);
      max-height: 90vh !important;
      overflow: auto;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      top: 40% !important;
    }

    @media only screen and (min-width: 768px) {
      top: 50%;
      border-radius: 1rem;
    }
    .second-window {
      padding: 2em;
      .content {
        // margin-top: 5rem;
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 1rem;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
        @media only screen and (max-width: 768px) {
          max-height: 70vh;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
          max-height: 45vh !important;
        }
      }
      .custom-content:not(.tablet-custom-content) {
        // max-height: 70vh;
        overflow-y: hidden;
        overflow-x: hidden;
        padding-bottom: 1rem;
        @media only screen and (max-width: 768px) {
          max-height: 70vh;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
          max-height: 45vh !important;
        }
      }
      @media only screen and (max-width: 768px) {
        padding: 1em 1em;
        // top: inherit;
        bottom: 0;
        left: 0;
        transform: translate(0);
        max-height: 80vh;
        overflow: auto;
        // border-top-left-radius: 3rem;
        border-top-right-radius: 3rem;
      }
    }
  }

  .sm {
    width: 300px;
    max-height: 90vh;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .sm {
    width: 300px;
    max-height: 90vh;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .n {
    width: 500px;
    max-height: 90vh;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .md {
    width: 450px;
    max-height: 90vh;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .lg {
    width: 800px;
    max-height: 90vh;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .xl {
    width: 1140px;
    max-height: 90vh;
    overflow: scroll;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  header {
    font-weight: bold;
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  text-decoration: none;

  i {
    font-size: 1.15em !important;
  }
  &:hover {
    color: black;
  }
}
</style>

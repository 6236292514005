import { getField, updateField } from 'vuex-map-fields'
import { http } from '@/config/http'
import Gen from '@/libs/Gen'

export default {
  namespaced: true,
  state: {
    paymentData: {
      payment_link: '',
      installments: [],
      payment_categories: [],
      payment_channels: [],
      active_payment_text: '',
      active_payment_bg: '',
      paid_payment_text: '',
      paid_payment_bg: '',
      due_text: '',
      due_text_bg: '',
      sub_title_1: '',
      sub_title_2: '',
      email: '',
      whatsapp: '',
      instagram: '',
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_PAYMENT_DETAILS(state, data = {}) {
      state.paymentData = data.data
    },
  },

  actions: {
    async getPaymentDetails({ state, commit, rootState }, param = {}) {
      const { path } = param

      try {
        if (JSON.stringify(param) === "{}") {
          return
        }

        rootState.loading.screen = true

        const token = Gen.getCookie("userAuthRdg")

        const res = await http.get(`/confirmation/payment/${path}`, {
          headers: {
            ...Gen.apiHeaderAuth(token),
          },
        })

        commit("SET_PAYMENT_DETAILS", res.data)

      } catch (err) {
        if ((err.response || {}).status === 400) {
          router.push({ name: "Home" })
        }
        if ((err.response || {}).status === 404) {
          router.push({ name: "404" })
        }
        if ((err.response || {}).status === 301) {
          router.push({ name: err.response.data.redirect_to, params: {pageSlug: err.response.data.trx_id} })
        }
        if ((err.response || {}).status === 401) {
          router.push({
            name: "Login",
            query: { ref: router.currentRoute.fullPath },
          })
        }
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    helpCenterContent: [],
    unread_notifications: 0,
    notifications: [],
    unread_updates: 0,
    updates: [],
    notification: {},
    update: {},
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_HELP_CENTER_CONTENT(state, data) {
      state.helpCenterContent = data
    },
    SET_NOTIFICATION_LIST(state, data) {
      state.unread_notifications = data.unread_notifications
      state.notifications = data.notifications
      state.unread_updates = data.unread_updates
      state.updates = data.updates
    },
    SET_NOTIFICATION_DETAILS(state, data) {
      state.notification = data.notification
      state.update = data.update
    },
  },

  actions: {
    async getHelpCenterContent({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const token = Gen.getCookie('userAuthRdg')
        
        const res = await http.get(`/private-pages/atom/help-center`, {
          headers: Gen.apiHeaderAuth(token)
        })

        commit('SET_HELP_CENTER_CONTENT', res.data.content)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
    async getNotificationList({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const token = Gen.getCookie('userAuthRdg')
        
        const res = await http.get(`/private-pages/atom/notification-list`, {
          headers: Gen.apiHeaderAuth(token)
        })

        commit('SET_NOTIFICATION_LIST', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
    async getNotificationDetails({ commit, rootState }, slug) {
      try {
        rootState.loading.screen = true

        const token = Gen.getCookie('userAuthRdg')
        
        const res = await http.get(`/private-pages/atom/notification-list/${slug}`, {
          headers: Gen.apiHeaderAuth(token)
        })

        commit('SET_NOTIFICATION_DETAILS', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

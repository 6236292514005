<template>
  <section class="mt-0 pt-5 overflow-visible">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div>
            <h4 class="extrabold" :class="!isMobile() ? 'fs-275em' : 'fs-225em'">
              {{ ((runEventDetails || {}).event || {}).name }}
            </h4>
            <p class="w-75 grey-color">
              {{ ((runEventDetails || {}).event || {}).excerpt }}
            </p>
            <span
              >By
              <a
                :href="((runEventDetails || {}).event || {}).partner_url"
                target="_blank"
                class="text-link semibold text-decoration-none"
                >{{ ((runEventDetails || {}).event || {}).partner }}</a
              ></span
            >
            <template v-if="((runEventDetails || {}).event || {}).status === 'E'">
              <div class="row py-5">
                <div class="col-md-4">
                  <div class="item-detail-text-icon d-flex align-items-start">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-ticket-alt gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold">{{ titleSlotsAvailable }}</span
                      ><br />
                      <span class="regular grey-color">{{ descriptionSlotsAvailable }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="item-detail-text-icon d-flex align-items-start mx-2">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-calendar gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold">{{
                        formatDateWithoutWeekday(((runEventDetails || {}).event || {}).race_date)
                      }}</span
                      ><br />
                      <span class="regular grey-color"
                        >{{ formatDate(((runEventDetails || {}).event || {}).race_date) }},
                        {{ formatTime(((runEventDetails || {}).event || {}).race_time) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="item-detail-text-icon d-flex align-items-start">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                    </div>
                    <div class="mx-2 w-75">
                      <span class="mt-2 gamma-color semibold"
                        >{{ ((runEventDetails || {}).event || {}).program_name }},
                        {{ ((runEventDetails || {}).event || {}).program_country }}</span
                      ><br />
                      <span class="regular grey-color">{{
                        ((runEventDetails || {}).event || {}).program_race_village
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="img">
            <picture>
              <source :src="uploader(((runEventDetails || {}).event || {}).banner_image)" type="image/webp" />
              <img
                :src="uploader(((runEventDetails || {}).event || {}).banner_fallback_image)"
                :alt="((runEventDetails || {}).event || {}).banner_alt_image"
                class="w-100 bdrd-24px"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-md-9 br-1px br-grey-black p-r-30 bg-white-custom">
          <div class="tab-left sticky-80px bg-white-custom bb-1px-grey">
            <ul class="component-sticky-main runningevent d-flex" v-scroll-spy-active v-scroll-spy-link>
              <li class="mr-3">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold">Overview</span>
                  </div>
                </a>
              </li>
              <template v-if="((runEventDetails || {}).event || {}).status === 'E'">
                <li class="mr-3">
                  <a>
                    <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                      <span class="extrabold">Important Info</span>
                    </div>
                  </a>
                </li>
                <li class="mr-3">
                  <a>
                    <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                      <span class="extrabold">Hotel</span>
                    </div>
                  </a>
                </li>
                <li class="mr-3" v-if="((runEventDetails || {}).event || {}).status === 'E'">
                  <a>
                    <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                      <span class="extrabold">Package Catalog</span>
                    </div>
                  </a>
                </li>
                <li class="mr-3">
                  <a>
                    <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                      <span class="extrabold">Gallery</span>
                    </div>
                  </a>
                </li>
                <li class="mr-3" v-if="((runEventDetails || {}).event || {}).status === 'E'">
                  <a>
                    <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                      <span class="extrabold">FAQS</span>
                    </div>
                  </a>
                </li>
              </template>
              <li class="mr-3" v-if="((runEventDetails || {}).event || {}).status === 'U'">
                <a>
                  <div class="card-icon-title small d-flex align-items-center mt-4 mb-2 mx-3 pointer">
                    <span class="extrabold">Announcement List</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="pb-5">
            <div v-scroll-spy="{ offset: 140 }">
              <div class="bb-1px-grey py-5">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <span class="extrabold">#1</span>
                    <h2 class="alpha-color black mb-5" :class="isMobile() ? 'mt-2' : ''">Overview</h2>
                    <div class="grey-color mt-3" v-html="((runEventDetails || {}).event || {}).race_overview"></div>
                  </div>
                </div>
              </div>
              <template v-if="((runEventDetails || {}).event || {}).status === 'E'">
                <div class="important-information bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#2</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Important Information</h2>
                      <div class="my-4">
                        <div>
                          <span class="sub-title-section mb-2">
                            <div class="line-sub-title"></div>
                            Important Notes
                          </span>
                          <div class="my-4">
                            <div class="row">
                              <div
                                class="col-md-12"
                                v-for="(v, k) in ((runEventDetails || {}).event || {}).important_dates"
                              >
                                <div class="item-detail-text-icon d-flex align-items-start">
                                  <div class="icon d-flex justify-content-center align-items-center">
                                    <i class="icon-info gamma-color fs-1em"></i>
                                  </div>
                                  <div class="mx-2 w-100">
                                    <span class="mt-2 gamma-color semibold">{{ v.title }}</span
                                    ><br />
                                    <div class="regular grey-color" v-html="v.description"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span class="sub-title-section mb-2">
                            <div class="line-sub-title"></div>
                            Important Location
                          </span>
                          <div class="my-4">
                            <div class="row">
                              <div
                                class="col-md-3"
                                v-for="(v, k) in ((runEventDetails || {}).event || {}).important_locations"
                              >
                                <div class="item-detail-text-icon d-flex align-items-start">
                                  <div class="icon d-flex justify-content-center align-items-center">
                                    <i class="icon-info gamma-color fs-1em"></i>
                                  </div>
                                  <div class="mx-2 w-75">
                                    <span class="mt-2 gamma-color semibold">{{ v.name }}</span
                                    ><br />
                                    <a
                                      v-for="(v1, k1) in v.descriptions"
                                      :href="v1.url"
                                      target="_blank"
                                      class="d-block regular grey-color text-underline pointer"
                                      >{{ v1.value }}</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#3</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Hotel</h2>
                      <div class="my-3">
                        <div class="d-flex justify-content-between mb-2" v-if="allHotels[0] !== undefined">
                          <div>
                            <h5 class="alpha-color bold">{{ (allHotels[0] || {}).event_hotel_name }}</h5>
                            <span class="grey-color regular">{{ (allHotels[0] || {}).event_hotel_address }}</span>
                          </div>
                          <a class="alpha-color pointer text-link" @click="openHotel(allHotels[0])">See Details</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="package-catalog bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#4</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Package Catalog</h2>
                      <div class="my-4" v-for="(v, k) in ((runEventDetails || {}).event || {}).pricings" :key="k">
                        <div class="d-flex justify-content-between">
                          <h5 class="bold">
                            {{ v.event_itinerary_name }} - {{ v.event_itinerary_title }} -
                            {{ definePackageRemainingSlotsText(v.stock, v.prices) }}
                          </h5>
                          <a
                            class="alpha-color pointer text-link"
                            @click="openItineraries(v.event_itinerary_descriptions)"
                            >See Itineraries</a
                          >
                        </div>
                        <div class="row">
                          <div class="col-md-12" v-for="(v1, k1) in v.prices" :key="k1">
                            <template
                              v-if="
                                v1.package_total_runners <= v.stock &&
                                !v1.is_closed &&
                                ((runEventDetails || {}).event || {}).status === 'E'
                              "
                            >
                              <div class="bg-white mt-3 price-box bdrd-24px py-4 px-4">
                                <div class="top d-flex justify-content-between mb-3 align-items-center">
                                  <div>
                                    <h5 class="mb-0 bold">{{ v1.package_name }}</h5>
                                  </div>
                                  <div>
                                    <a @click="detailPackage(v1, v)" class="text-link alpha-color pointer"
                                      >See Details</a
                                    >
                                  </div>
                                </div>
                                <div class="mb-0 bb-1px-grey">
                                  <div class="item-detail-text-icon d-flex align-items-center">
                                    <div class="icon d-flex justify-content-center align-items-center">
                                      <i class="icon-credit-card gamma-color fs-1em"></i>
                                    </div>
                                    <div class="mx-2 mb-3 w-75">
                                      <span
                                        class="gamma-color semibold"
                                        v-if="!v1.is_one_time_payment_only && !v1.no_partial"
                                        >Partial and One Time Payment Available</span
                                      >
                                      <span class="gamma-color semibold" v-else>One Time Payment Available</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="bb-1px-grey py-3">
                                  <span class="regular grey-color">
                                    {{ v1.package_description }}
                                  </span>
                                </div>
                                <div :id="`open-${k}-${k1}`" class="bb-1px-grey pt-4 d-none">
                                  <div class="row">
                                    <div class="col-md-8 br-grey-black p-r-10">
                                      <span class="sub-title-section fs-125em">
                                        <div class="line-sub-title"></div>
                                        Timeline of Payment
                                      </span>
                                      <ul class="timeline" v-if="localTypeOfTransaction == 'partial-payment'">
                                        <li
                                          class="timeline-item"
                                          v-for="(installment, indexInstallment) in v1.installments"
                                          :key="indexInstallment"
                                        >
                                          <div class="timeline-icon">{{ indexInstallment + 1 }}</div>
                                          <h5 class="bold">{{ ordinal(indexInstallment + 1) }} Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(installment.value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(installment.expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                      <ul class="timeline" v-if="localTypeOfTransaction == 'one-time-payment'">
                                        <li class="timeline-item">
                                          <div class="timeline-icon">1</div>
                                          <h5 class="bold">One Time Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(v1.special_value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(((v1 || {}).installments[0] || {}).expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group m-t-70">
                                        <label for="" class="required bold">Select Type of Transaction</label>
                                        <div class="mt-3">
                                          <b-form-radio
                                            v-if="!v1.no_partial"
                                            v-model="localTypeOfTransaction"
                                            :name="`po-${k}-${k1}-partial`"
                                            value="partial-payment"
                                            >Partial Payment</b-form-radio
                                          >
                                          <b-form-radio
                                            v-model="localTypeOfTransaction"
                                            class="my-2"
                                            :name="`po-${k}-${k1}-one`"
                                            value="one-time-payment"
                                            >One Time Payment</b-form-radio
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="pt-3 d-flex justify-content-between align-items-center">
                                  <div v-if="localTypeOfTransaction === 'one-time-payment'">
                                    <span class="bold alpha-color"
                                      ><b class="text-line-through">IDR {{ numFormatter(v1.value) }}</b> to IDR
                                      {{ numFormatter(v1.special_value) }}/ Runner</span
                                    >
                                  </div>
                                  <div v-else>
                                    <span class="bold alpha-color"
                                      ><b>IDR {{ numFormatter(v1.value) }}</b
                                      >/ Runner</span
                                    >
                                  </div>
                                  <div class="d-flex">
                                    <button
                                      @click="
                                        choosePackage(k, k1, v.event_itinerary_slug, v1.package_slug, v1.no_partial, localTypeOfTransaction)
                                      "
                                      class="btn btn-slg btn-primary bold mb-0"
                                    >
                                      Choose
                                    </button>
                                    <button
                                      @click="cancelChoosePackage(k, k1)"
                                      class="btn btn-slg btn-outline-main ml-0 bold mb-0 d-none ml-0 ml-2"
                                      :id="`btn-open-${k}-${k1}`"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template
                              v-else-if="
                                v1.package_total_runners > v.stock ||
                                v1.is_closed ||
                                ((runEventDetails || {}).event || {}).status !== 'E'
                              "
                            >
                              <div class="bg-grey mt-3 price-box bdrd-24px py-4 px-4">
                                <div class="top d-flex justify-content-between mb-3 align-items-center">
                                  <div>
                                    <h5 class="mb-0 bold">{{ v1.package_name }}</h5>
                                  </div>
                                  <div>
                                    <a @click="detailPackage(v1, v)" class="text-link alpha-color pointer"
                                      >See Details</a
                                    >
                                  </div>
                                </div>
                                <div class="mb-0 bb-1px-grey">
                                  <div class="item-detail-text-icon d-flex align-items-center">
                                    <div class="icon d-flex justify-content-center align-items-center">
                                      <i class="icon-credit-card gamma-color fs-1em"></i>
                                    </div>
                                    <div class="mx-2 mb-3 w-75">
                                      <span
                                        class="gamma-color semibold"
                                        v-if="!v1.is_one_time_payment_only && !v1.no_partial"
                                        >Partial and One Time Payment Available</span
                                      >
                                      <span class="gamma-color semibold" v-else>One Time Payment Available</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="bb-1px-grey py-3">
                                  <span class="regular grey-color">
                                    {{ v1.package_description }}
                                  </span>
                                </div>
                                <div :id="`open-${k}-${k1}`" class="bb-1px-grey pt-4 d-none">
                                  <div class="row">
                                    <div class="col-md-8 br-grey-black p-r-10">
                                      <span class="sub-title-section fs-125em">
                                        <div class="line-sub-title"></div>
                                        Timeline of Payment
                                      </span>
                                      <ul class="timeline" v-if="localTypeOfTransaction == 'partial-payment'">
                                        <li
                                          class="timeline-item"
                                          v-for="(installment, indexInstallment) in v1.installments"
                                          :key="indexInstallment"
                                        >
                                          <div class="timeline-icon">{{ indexInstallment + 1 }}</div>
                                          <h5 class="bold">{{ ordinal(indexInstallment + 1) }} Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(installment.value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(installment.expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                      <ul class="timeline" v-if="localTypeOfTransaction == 'one-time-payment'">
                                        <li class="timeline-item">
                                          <div class="timeline-icon">1</div>
                                          <h5 class="bold">One Time Payment</h5>
                                          <div class="timeline-item-date bold alpha-color fs-1em mb-3">
                                            IDR {{ numFormatter(v1.special_value) }}
                                          </div>
                                          <p>
                                            Payment starts from today, due date on
                                            {{ formatDateWithHour(((v1 || {}).installments[0] || {}).expiry) }}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group m-t-70">
                                        <label for="" class="required bold">Select Type of Transaction</label>
                                        <div class="mt-3">
                                          <b-form-radio
                                            v-if="!v1.no_partial"
                                            v-model="localTypeOfTransaction"
                                            :name="`po-${k}-${k1}-partial`"
                                            value="partial-payment"
                                            >Partial Payment</b-form-radio
                                          >
                                          <b-form-radio
                                            v-model="localTypeOfTransaction"
                                            class="my-2"
                                            :name="`po-${k}-${k1}-one`"
                                            value="one-time-payment"
                                            >One Time Payment</b-form-radio
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="pt-3 d-flex justify-content-between align-items-center">
                                  <div v-if="localTypeOfTransaction === 'one-time-payment'">
                                    <span class="bold alpha-color"
                                      ><b class="text-line-through">IDR {{ numFormatter(v1.value) }}</b> to IDR
                                      {{ numFormatter(v1.special_value) }}/ Runner</span
                                    >
                                  </div>
                                  <div v-else>
                                    <span class="bold alpha-color"
                                      ><b>IDR {{ numFormatter(v1.value) }}</b
                                      >/ Runner</span
                                    >
                                  </div>
                                  <div class="d-flex">
                                    <button class="btn btn-slg btn-primary bold mb-0" disabled>Choose</button>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bb-1px-grey py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#5</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Gallery</h2>
                      <div class="my-4">
                        <div class="row" v-if="(((runEventDetails || {}).gallery || {}).data || {}).length > 0">
                          <div class="col-md-3" v-for="(v, k) in ((runEventDetails || {}).gallery || {}).data">
                            <div class="card-hover small bdrd-24px overflow-hidden mb-3">
                              <picture>
                                <source :srcset="uploader(v.image)" type="image/webp" />
                                <img :src="uploader(v.fallback_image)" :alt="'Image-' + k" class="bdrd-24px" />
                              </picture>
                              <div class="detail">
                                <a :href="uploader(v.image)" data-fancybox="gallery2">
                                  <div class="social-bullet">
                                    <i class="icon-eye"></i>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-6">
                            <h6 class="semibold ls-0">Gallery is not available right now</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-5">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <span class="extrabold">#6</span>
                      <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">FAQs</h2>
                      <div class="my-4">
                        <div class="accordion-menu">
                          <ul class="ul-accordion">
                            <li class="li-accordion" v-for="(v, k) in (runEventDetails || {}).faqs" :key="k">
                              <input type="checkbox" checked />
                              <i class="arrow"></i>
                              <h2 class="bold">{{ v.faq_question }}</h2>
                              <p>
                                {{ v.faq_answer }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="bb-1px-grey py-5" v-if="((runEventDetails || {}).event || {}).status === 'U'">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <span class="extrabold">#4</span>
                    <h2 class="alpha-color black mb-1" :class="isMobile() ? 'mt-2' : ''">Announcement List</h2>
                    <div class="my-4">
                      <div class="row">
                        <div class="col-12">
                          <p class="regular">
                            Join our Announcement List today and embark on a journey that combines your love for running
                            with the excitement of exploring vibrant cities. Prepare to lace up your running shoes, set
                            new personal records, and create memories that will last a lifetime.
                          </p>
                        </div>
                        <div class="col-12">
                          <a
                            class="btn btn-slg btn-primary bold"
                            @click="openAnnouncementList"
                            v-if="isBtnGetSlotNow && ((runEventDetails || {}).event || {}).status === 'U'"
                          >
                            Join Announcement List
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 p-l-20">
          <div class="sticky-100px">
            <template v-if="isBtnGetSlotNow && ((runEventDetails || {}).event || {}).status === 'E'">
              <div class="mt-3">
                <div class="bg-white mt-3 price-box bdrd-24px py-4 px-4">
                  <h5 class="bold">Non Refundable</h5>
                  <span class="regular"
                    >This ticket reservation cannot be canceled. If you do not show up for your run later, you will not
                    get a refund.</span
                  >
                </div>
              </div>
              <div class="mt-3">
                <button class="btn btn-slg btn-primary bold w-100" @click="scrollToPackage">Get Slot Now</button>
              </div>
            </template>
            <template v-else>
              <div class="mt-3">
                <a
                  class="btn btn-slg btn-primary bold w-100"
                  @click="openAnnouncementList"
                  v-if="isBtnGetSlotNow && ((runEventDetails || {}).event || {}).status === 'U'"
                >
                  Join Announcement List
                </a>
              </div>
            </template>
            <div class="d-flex align-items-start mt-3">
              <div class="widget-title mt-1 semibold">Share:</div>
              <div class="d-flex ml-4">
                <ShareNetwork
                  network="facebook"
                  :url="baseUrl + $route.fullPath"
                  :title="((runEventDetails || {}).event || {}).name || ''"
                  :description="((runEventDetails || {}).event || {}).excerpt"
                >
                  <div
                    class="comp-social pointer bdrd-full d-flex justify-content-center align-items-center bg-facebook"
                  >
                    <i class="icon-facebook text-white"></i>
                  </div>
                </ShareNetwork>

                <ShareNetwork
                  network="twitter"
                  :url="baseUrl + $route.fullPath"
                  :title="((runEventDetails || {}).event || {}).name || ''"
                  :description="((runEventDetails || {}).event || {}).excerpt"
                >
                  <div
                    class="comp-social pointer bdrd-full d-flex justify-content-center align-items-center mx-2 bg-twitter"
                  >
                    <i class="icon-twitter text-white"></i>
                  </div>
                </ShareNetwork>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      localTypeOfTransaction: this.typeOfTransaction,
    };
  },
  props: {
    openHotel: {
      type: Function,
    },
    openAnnouncementList: {
      type: Function,
    },
    uploader: {
      type: Function,
    },
    runEventDetails: {
      type: Object,
    },
    isMobile: {
      type: Function,
    },
    isBtnGetSlotNow: {
      type: Boolean,
    },
    isDev: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    titleSlotsAvailable: {
      type: [Number, String],
    },
    descriptionSlotsAvailable: {
      type: [Number, String],
    },
    formatDateWithoutWeekday: {
      type: Function,
    },
    formatDate: {
      type: Function,
    },
    formatTime: {
      type: Function,
    },
    definePackageRemainingSlotsText: {
      type: Function,
    },
    typeOfTransaction: {
      type: String,
    },
    ordinal: {
      type: Function,
    },
    numFormatter: {
      type: Function,
    },
    formatDateWithHour: {
      type: Function,
    },
    allHotels: {
      type: Array,
    },
    scrollToPackage: {
      type: Function,
    },
    choosePackage: {
      type: Function,
    },
  }
}
</script>

<style>
</style>
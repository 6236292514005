<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <section class="pt-5 overflow-visible">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-9" :class="!isMobile() ? 'br-1px br-grey-black p-r-30' : ''">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="label-custom green d-inline">
                    <span class="black">{{ destinationContent.destination.sub_title }}</span>
                  </div>
                  <h2 class="black mt-2 fs-275em">{{ destinationDetails.destination.name }}</h2>
                </div>
                <div v-if="!isMobile()">
                  <div class="d-flex align-items-start">
                    <div class="widget-title mt-1 semibold">{{ destinationContent.destination.share }}</div>
                    <div class="d-flex ml-4">
                      <ShareNetwork
                        network="facebook"
                        :url="baseUrl + $route.fullPath"
                        :title="destinationDetails.destination.short_description"
                      >
                        <div
                          class="comp-social pointer bdrd-full d-flex justify-content-center align-items-center bg-facebook"
                        >
                          <i class="icon-facebook text-white"></i>
                        </div>
                      </ShareNetwork>

                      <ShareNetwork
                        network="twitter"
                        :url="baseUrl + $route.fullPath"
                        :title="destinationDetails.destination.short_description"
                      >
                        <div
                          class="comp-social pointer bdrd-full d-flex justify-content-center align-items-center mx-2 bg-twitter"
                        >
                          <i class="icon-twitter text-white"></i>
                        </div>
                      </ShareNetwork>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="mt-3 bb-1px-grey p-b-20" v-if="isMobile()">
                  <div class="d-flex align-items-start">
                    <div class="widget-title mt-1 semibold">{{ destinationContent.destination.share }}</div>
                    <div class="d-flex ml-4">
                      <ShareNetwork
                        network="facebook"
                        :url="baseUrl + $route.fullPath"
                        :title="destinationDetails.destination.short_description"
                      >
                        <div
                          class="comp-social pointer bdrd-full d-flex justify-content-center align-items-center bg-facebook"
                        >
                          <i class="icon-facebook text-white"></i>
                        </div>
                      </ShareNetwork>

                      <ShareNetwork
                        network="twitter"
                        :url="baseUrl + $route.fullPath"
                        :title="destinationDetails.destination.short_description"
                      >
                        <div
                          class="comp-social pointer bdrd-full d-flex justify-content-center align-items-center mx-2 bg-twitter"
                        >
                          <i class="icon-twitter text-white"></i>
                        </div>
                      </ShareNetwork>
                    </div>
                  </div>
                </div>
                <div
                  v-if="(destinationDetails.destination || {}).banner_image !== undefined"
                  class="img-cover-full h-350px bdrd-24px mt-4"
                >
                  <picture>
                    <source :srcset="uploader((destinationDetails.destination || {}).banner_image)" type="image/webp" />
                    <img
                      :src="uploader((destinationDetails.destination || {}).banner_fallback_image)"
                      :alt="(destinationDetails.destination || {}).banner_alt_image"
                    />
                  </picture>
                </div>
              </div>
              <div class="text-dark mt-3" v-html="(destinationDetails.destination || {}).description"></div>
              <div v-if="!isMobile()">
                <div class="wrap_destination_content" v-scroll-spy="{ offset: 150 }">
                  <div class="bb-1px-grey py-3" v-if="(destinationDetails.destination.geography || {}) !== null">
                    <h3 class="bold">Geography</h3>
                    <div class="text-dark" v-html="destinationDetails.destination.geography">
                    </div>
                  </div>
                  <div class="bb-1px-grey py-3" v-if="destinationDetails.destination.culture !== null">
                    <h3 class="bold">People and Culture</h3>
                    <div class="text-dark" v-html="destinationDetails.destination.culture"></div>
                  </div>
                  <div class="bb-1px-grey py-3" v-if="destinationDetails.destination.nature !== null">
                    <h3 class="bold">Nature</h3>
                    <div class="text-dark" v-html="destinationDetails.destination.nature"></div>
                  </div>
                  <div class="bb-1px-grey py-3">
                    <div>
                      <template v-if="destinationDetails.destination.accommodation !== null">
                        <h3 class="bold">Accommodation</h3>
                        <div
                          class="text-dark"
                          v-html="destinationDetails.destination.accommodation"
                        ></div>
                      </template>
                      <template v-if="destinationDetails.destination.transportation !== null">
                        <h3 class="bold">Transportation</h3>
                        <div
                          class="text-dark"
                          v-html="destinationDetails.destination.transportation"
                        ></div>
                      </template>
                    </div>
                  </div>
                  <div class="py-3" v-if="(destinationDetails.running_events.data || []).length > 0">
                    <h3 class="bold">{{ (destinationDetails.running_events || {}).title }}</h3>
                    <div class="row mt-4 mb-5">
                      <div v-for="(v, k) in destinationDetails.running_events.data" :key="k" class="col-12">
                        <div class="widget card-run-event d-flex p-cb p-0" :class="v.status !== 'E' ? 'grey' : ''">
                          <div
                            class="ribbon ribbon-top-left"
                            :class="defineEventRegistrationRibbon(v.status)"
                          >
                            <span class="bold text-uppercase text-white">{{
                              defineEventRegistrationStatus(v.status)
                            }}</span>
                          </div>
                          <div class="left">
                            <picture>
                              <source :srcset="uploader(v.preview_image)" type="image/webp" />
                              <img
                                :src="uploader(v.preview_fallback_image)"
                                :alt="v.preview_alt_image"
                              />
                            </picture>
                          </div>
                          <div class="right d-flex flex-column justify-content-center p-r-1">
                            <div class="bt-1px-black">
                              <h3 class="bold">{{ v.name }}</h3>
                              <div class="d-flex bt-1px-black pb-2">
                                <div class="d-flex align-items-center mr-2">
                                  <i class="fa fa-calendar gamma-color fs-1em"></i
                                  ><a href=""
                                    ><span class="mx-2 gamma-color semibold">{{
                                      formatDateWithoutWeekday(v.race_date)
                                    }}</span></a
                                  >
                                </div>
                                <div class="d-flex align-items-center">
                                  <i class="fa fa-location-arrow gamma-color fs-1em"></i
                                  ><a href=""
                                    ><span class="mx-2 gamma-color semibold"
                                      >{{ v.program_name }}, {{ v.program_country }}</span
                                    ></a
                                  >
                                </div>
                              </div>
                              <p class="mt-2 mb-1 pr-2">
                                {{ v.excerpt }}
                              </p>
                            </div>
                            <div class="mt-3 d-flex justify-content-start">
                              <a
                                :href="
                                  $router.resolve({ name: 'RunningEventDetails', params: { pageSlug: v.slug } }).href
                                "
                                class="btn btn-primary semibold d-flex align-items-center"
                              >
                                {{ ((destinationContent || {}).running_events || {}).detailscta }}
                                <i class="icon-arrow-right bold fs-125em ml-1"></i
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="tabs link">
                  <div class="container px-0 wrap_destination_content">
                    <div v-scroll-spy="{ offset: 150 }">
                      <div class="bb-1px-grey py-3" v-if="(destinationDetails.destination.geography || {}) !== null">
                        <h3 class="bold">Geography</h3>
                        <div
                          class="text-dark"
                          v-html="((destinationDetails || {}).destination || {}).geography"
                        ></div>
                      </div>
                      <div class="bb-1px-grey py-3" v-if="((destinationDetails || {}).destination || {}).culture !== null">
                        <h3 class="bold">People and Culture</h3>
                        <div
                          class="text-dark"
                          v-html="((destinationDetails || {}).destination || {}).culture"
                        ></div>
                      </div>
                      <div class="bb-1px-grey py-3" v-if="((destinationDetails || {}).destination || {}).nature !== null">
                        <h3 class="bold">Nature</h3>
                        <div class="text-dark" v-html="((destinationDetails || {}).destination || {}).nature"></div>
                      </div>
                      <div class="bb-1px-grey py-3" v-if="((destinationDetails || {}).destination || {}).accommodation !== null">
                        <h3 class="bold">Accommodation</h3>
                        <div
                          class="text-dark"
                          v-html="((destinationDetails || {}).destination || {}).accommodation"
                        ></div>
                      </div>
                      <div class="bb-1px-grey py-3" v-if="((destinationDetails || {}).destination || {}).transportation !== null">
                        <h3 class="bold">Transportation</h3>
                        <div
                          class="text-dark"
                          v-html="((destinationDetails || {}).destination || {}).transportation"
                        ></div>
                      </div>
                      <div class="py-3" v-if="(((destinationDetails || {}).running_events || {}).data || []).length > 0">
                        <h3 class="bold">{{ (destinationDetails.running_events || {}).title }}</h3>
                        <div class="row mt-4 mb-5">
                          <div v-for="(v, k) in (destinationDetails || {}).running_events.data" :key="k" class="col-12">
                            <div class="widget card-run-event d-flex p-cb p-0" :class="v.status !== 'E' ? 'grey' : ''">
                              <div
                                class="ribbon ribbon-top-left"
                                :class="defineEventRegistrationRibbon(v.status)"
                              >
                                <span class="bold text-uppercase text-white">{{
                                  defineEventRegistrationRibbon(v.status)
                                }}</span>
                              </div>
                              <div class="left">
                                <picture>
                                  <source :srcset="uploader(v.preview_image)" type="image/webp" />
                                  <img :src="uploader(v.preview_fallback_image)" :alt="v.preview_alt_image" />
                                </picture>
                              </div>
                              <div class="right d-flex flex-column justify-content-center p-r-1">
                                <div class="bt-1px-black">
                                  <h3 class="bold">{{ v.name }}</h3>
                                  <div class="d-flex flex-wrap bt-1px-black pb-2">
                                    <div class="d-flex align-items-center my-2 mr-2">
                                      <i class="fa fa-calendar gamma-color fs-1em"></i>
                                      <a href=""
                                        ><span class="mx-2 gamma-color semibold">{{ formatDateWithoutWeekday(v.race_date) }}</span></a
                                      >
                                    </div>
                                    <div class="d-flex align-items-center my-2">
                                      <i class="fa fa-location-arrow gamma-color fs-1em"></i>
                                      <a href=""
                                        ><span class="mx-2 gamma-color semibold"
                                          >{{ v.program_name }}, {{ v.program_country }}</span
                                        ></a
                                      >
                                    </div>
                                  </div>
                                  <p class="mt-3 mb-3 pr-2">
                                    {{ v.excerpt }}
                                  </p>
                                </div>
                                <div class="mt-3 d-flex justify-content-start">
                                  <a
                                    :href="
                                      $router.resolve({ name: 'RunningEventDetails', params: { pageSlug: v.slug } })
                                        .href
                                    "
                                    class="btn btn-primary semibold d-flex align-items-center"
                                  >
                                    {{ ((destinationContent || {}).running_events || {}).detailscta }}
                                    <i class="icon-arrow-right bold fs-125em ml-1"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="((destinationDetails || {}).other_destinations || []).length > 0" class="col-lg-3 py-3" :class="!isMobile() ? 'p-l-30' : ''">
              <div>
                <h3 class="bold">{{ (destinationContent.destination || {}).other }}</h3>
                <template v-if="isDesktop() || isOnlyMobile()">
                  <div class="row mt-3">
                    <OtherDestinationsAtom
                      v-for="(v, k) in (destinationDetails || {}).other_destinations"
                      :key="k"
                      :country="v.name"
                      :image="v.image"
                      :fallback-image="v.fallback_image"
                      :slug="v.slug"
                      :uploader="uploader"
                      col="col-md-12"
                    />
                  </div>
                </template>
                <template v-if="isTablet() && ((destinationDetails || {}).other_destinations || []).length > 0">
                  <div class="row mt-3">
                    <OtherDestinationsAtom
                      v-for="(v, k) in (destinationDetails || {}).other_destinations"
                      :key="k"
                      :country="v.name"
                      :image="v.image"
                      :fallback-image="v.fallback_image"
                      :slug="v.slug"
                      :uploader="uploader"
                      col="col-4"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'

import OtherDestinationsAtom from '@/views/atoms/destination/OtherDestinationsAtom.vue'

export default {
  extends: Global,
  name: 'DestinationDetail',
  components: {
    carousel,
    Header,
    OtherDestinationsAtom,
  },
  data() {
    return {
      isWebPSupport: false,
    }
  },
  computed: {
    ...mapFields(['baseUrl', 'channels']),
    ...mapFields('useDestinationStore', ['destinationDetails', 'destinationContent']),

    breadcrumbItems() {
      return [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Destinations',
          to: { name: 'Destination' },
        },
        {
          text: 'Germany',
          active: true,
        },
      ]
    },
  },
  created() {
    this.getDestinationDetails(this.$route.params.pageSlug)

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {
    ...mapActions('useDestinationStore', ['getDestinationDetails']),
    ...mapActions(['getChannels']),
    defineEventRegistrationStatus(value) {
      switch (value) {
        case 'U':
          return ((this.destinationContent || {}).running_events || {}).future
          break;
        
        case 'E':
          return ((this.destinationContent || {}).running_events || {}).present
          break;

        case 'C':
          return ((this.destinationContent || {}).running_events || {}).past
          break;

        default:
          return ''
      }
    },
    defineEventRegistrationRibbon(value) {
      switch (value) {
        case 'U':
          return 'yellow'
          break;
        
        case 'E':
          return 'green'
          break;

        case 'C':
          return 'blue'
          break;

        default:
          return ''
      }
    },
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-simple-bg {
  width: 100%;
  min-height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    height: 150px;
    h2 {
      font-size: 18px;
    }
  }
  &.no-height {
    @media only screen and (max-width: 768px) {
      height: auto !important;
    }
  }
  &.small {
    height: 100px;
    min-height: 100px;
    &.mh-200 {
      min-height: 200px !important;
    }
  }
  &.medium {
    height: 150px;
    min-height: 150px;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    transition: all 0.25s ease-in-out;
  }
  &:hover {
    img {
      filter: brightness(80%);
    }
  }
}

.img-cover-full {
  position: relative;
  width: 100%;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.h-350px {
  height: 350px;
}

.mw-150 {
  min-width: 150px;
}

.mw-120 {
  min-width: 120px;
}
</style>

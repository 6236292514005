<template>
  <LayoutConfirmationPage>
    <template #content-layout>
      <div class="col-md-12 bb-1px-grey pb-4 d-flex flex-column align-items-center">
        <img :src="`${baseUrl}/assets/images/vector/notfound404-vect.svg`" alt="Not found" class="w-50" />
        <h5 class="bold ls-0 mt-3">Ooops! Page Not Found</h5>
      </div>
      <div class="col-md-12 text-center py-4">
        <span class="regular">This page doesn't exist or was removed! We suggest you back to home</span>
      </div>
      <div class="col-md-12 text-center pb-4">
        <router-link :to="{ name: 'Home' }" class="btn btn-primary">Back to Home</router-link>
      </div>
    </template>
  </LayoutConfirmationPage>
</template>

<script>
// @ is an alias to /src
import LayoutConfirmationPage from '@/layouts/LayoutConfirmationPage'

import { mapFields } from 'vuex-map-fields'
import Global from '../libs/Global.vue'

export default {
  extends: Global,
  name: 'NotFound',
  components: {
    LayoutConfirmationPage,
  },
  data() {
    return {
      isWebPSupport: false,
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Running Events',
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapFields('home', ['homeContent']),
  },
  created() {
    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })
  },
  methods: {},
}
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6",class:_vm.isMobile ? 'text-center' : ''},[_c('div',{class:_vm.isOnlyMobile ? 'text-center d-flex flex-column align-items-center' : ''},[_c('span',{staticClass:"sub-title-section",class:_vm.isMobile ? 'justify-content-center' : ''},[_c('div',{staticClass:"line-sub-title"}),_vm._v(" "+_vm._s(_vm.content.sub_title)+" ")]),_c('h2',{staticClass:"title-section-left mt-3"},[_vm._v(_vm._s(_vm.content.title))])])]),(!_vm.isMobile)?_c('div',{staticClass:"col-md-6 d-flex justify-content-end align-items-end"},[_c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-outline black",attrs:{"href":_vm.$router.resolve({name: 'Blog'}).href}},[_vm._v(_vm._s(_vm.content.button))])])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"blog-home mt-5"},[(_vm.data.length > 0)?_c('carousel',{attrs:{"items":_vm.isMobile ? 1 : 2,"nav":false,"stagePadding":0,"margin":20,"autoplay":""}},[_vm._l((_vm.data),function(v,k){return [_c('a',{key:'blog-'+k,attrs:{"href":_vm.$router.resolve({
                    name: 'BlogDetail',
                    params: {
                      pageSlug: v.slug,
                    },
                  }).href}},[_c('div',{staticClass:"article-home"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('picture',[_c('source',{attrs:{"srcset":_vm.uploader(v.image),"type":"image/webp"}}),_c('img',{attrs:{"src":_vm.uploader(v.fallback_image),"alt":v.alt_image,"width":"196","height":"175"}})])]),_c('div',{staticClass:"right pr-2 d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"grey-color medium fs-085em"},[_vm._v(_vm._s(_vm.formatDate(v.published_date)))]),_c('p',{staticClass:"fs-125em bold text-dark"},[_vm._v(" "+_vm._s(v.title)+" ")]),_c('span',{staticClass:"grey-color medium fs-085em"},[_vm._v("By "),_c('a',{attrs:{"href":_vm.$router.resolve({
                                name: 'BlogAuthor',
                                params: {
                                  pageSlug: v.author_slug,
                                },
                              }).href},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(v.author_name))])])])])]),_c('div',{staticClass:"p-3"},[_c('span',{staticClass:"grey-color regular"},[_vm._v(_vm._s(v.excerpt))])])])])]}),(_vm.data.length > 0)?_c('template',{slot:"next"},[_c('div',{staticClass:"arrow-carousel-component right pointer"},[_c('i',{staticClass:"icon-arrow-right"})])]):_vm._e(),(_vm.data.length > 0)?_c('template',{slot:"prev"},[_c('div',{staticClass:"arrow-carousel-component left pointer"},[_c('i',{staticClass:"icon-arrow-left"})])]):_vm._e()],2):_vm._e()],1)]),(_vm.isMobile)?_c('div',{staticClass:"col-md-6 text-center"},[_c('a',{staticClass:"btn btn-outline black mt-4",attrs:{"href":_vm.$router.resolve({name: 'Blog'}).href}},[_vm._v(_vm._s(_vm.content.button))])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
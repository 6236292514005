import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    myReviewContentData: [],
    myReviewQuestionData: [],
    formReviewStatic: {
      erv_testimonial: "",
      erv_overall_ratings: 0,
      erv_is_interview: ""
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_MY_REVIEW_CONTENT_DATA(state,data) {
      state.myReviewContentData = data
    },
    SET_MY_REVIEW_QUESTION_DATA(state,data) {
      state.myReviewQuestionData = data
    },
    SET_FORM_REVIEW_STATIC_DATA(state,data) {
      state.formReviewStatic = data
    }
  },

  actions: {
    async getMyReviewContentData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/private-pages/my-reviews/data`, {
          headers: Gen.apiHeaderAuth(),
        })

        commit('SET_MY_REVIEW_CONTENT_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
    async getMyReviewQuestionData({ commit, rootState }, transaction_id) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/private-pages/my-reviews/data/${transaction_id}`, {
          headers: Gen.apiHeaderAuth(),
        })

        if((res.data || {}).is_reviewed) {
          commit('SET_FORM_REVIEW_STATIC_DATA', res.data.question_static)
        }

        commit('SET_MY_REVIEW_QUESTION_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
    async doSubmitFormReview({ state, rootState, commit }, data) {
      const { form, transactionId, callback = false } = data

      const inputReview = {
        input_question: form,
        input_question_static: state.formReviewStatic,
      }

      try {
        rootState.loading.screen = true

        const res = await Gen.apiRest(
          `/private-pages/my-reviews/add/${transactionId}`,
          {
            data: { ...inputReview },
            headers: { ...Gen.apiHeaderAuth(rootState.userToken) },
          },
          'POST'
        )

        if (callback) {
          callback(true, res)
        }
      } catch (err) {
        if (callback) {
          callback(false)
        }

        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

<template>
  <div id="highlightsLg" class="custom-content relative-index-0" v-if="!isMobile">
    <div class="container">
      <div class="row content">
        <div class="col-md-4">
          <div class="content-card pr-2 text-white">
            <div class="content-card-slider d-flex align-items-center mb-3">
              <picture>
                <source :srcset="uploader(((data || [])[0] || {}).image)" type="image/webp">
                <img
                  :src="uploader(((data || [])[0] || {}).fallback_image)"
                  :alt="((data || [])[0] || {}).alt_image"
                  class="img-content-card-slider"
                />
              </picture>
              <h4 class="mx-2 black lh-125">{{ ((data || [])[0] || {}).title }}</h4>
            </div>
            <span class="semibold fs-1m">{{ ((data || [])[0] || {}).description }}</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="content-card pr-2 text-white">
            <div class="content-card-slider d-flex align-items-center mb-3">
              <picture>
                <source :srcset="uploader(((data || [])[1] || {}).image)" type="image/webp">
                <img
                  :src="uploader(((data || [])[1] || {}).fallback_image)"
                  :alt="((data || [])[1] || {}).alt_image"
                  class="img-content-card-slider"
                />
              </picture>
              <h4 class="mx-2 black lh-125">{{ ((data || [])[1] || {}).title }}</h4>
            </div>
            <span class="semibold fs-1m">{{ ((data || [])[1] || {}).description }}</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="content-card pr-2 text-white">
            <div class="content-card-slider d-flex align-items-center mb-3">
              <picture>
                <source :srcset="uploader(((data || [])[2] || {}).image)" type="image/webp">
                <img
                  :src="uploader(((data || [])[2] || {}).fallback_image)"
                  :alt="((data || [])[2] || {}).alt_image"
                  class="img-content-card-slider"
                />
              </picture>
              <h4 class="mx-2 black lh-125">{{ ((data || [])[2] || {}).title }}</h4>
            </div>
            <span class="semibold fs-1m">{{ ((data || [])[2] || {}).description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightsDesktopAtom',
  props: {
    isMobile: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    data: {
      type: Array,
    },
    uploader: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
#highlightsLg {
  @media only screen and (max-width: 991.98px) {
    display: none;
  }
}
#slider {
  position: relative;
  template {
    display: inline !important;
  }
  .arrow-carousel-slide {
    position: absolute;
    top: 50%;
    border: 1px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 99rem;
    z-index: 2;

    i {
      color: #fff;
      font-size: 2em;
    }

    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
}
</style>

<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <h3 class="semibold ls-0">
            {{ content.title }}
          </h3>
          <router-link :to="{ name: 'Register' }" class="btn highlight-yellow extrabold mt-3">{{ content.button }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RunningEventsCtaAtom',
  props: {
    content: {
      type: Object,
    },
  },
}
</script>

<style></style>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  data() {
    return {
      isOpenMenu: false,
      showCtaInHeader: false,
      isExtraSmallDevice: false,
      isLargeDevice: false,
      deviceType: '',
      timeoutId: null,
    }
  },
  created() {
    const screenWidth = window.innerWidth

    if (screenWidth < 546) {
      this.isExtraSmallDevice = true
    }

    if (screenWidth < 768) {
      this.deviceType = 'mobile'
    } else if (screenWidth < 1025) {
      this.deviceType = 'tablet'

      if (screenWidth >= 992) {
        this.isLargeDevice = true
      }
    } else {
      this.deviceType = 'desktop'

      this.isLargeDevice = true
    }
  },
  computed: {
    ...mapFields(['apiUrl', 'baseUrl']),
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        const usd = date.split('/')

        const formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1])

        return formatted.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1

      // kalo formatnya 2022-01-01 15:00:00 di ubah jadi 2022-01-01T15:00:00
      const d = !isParsed ? date.replace(/ /g, 'T') : date

      return new Date(d).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },

    formatTime(time) {
      let date = new Date(parseInt(time));
      return date.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute:'2-digit'
      });
    },

    formatDateWithoutWeekday(date) {
      if (!date) {
        return
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        const usd = date.split('/')

        const formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1])

        return formatted.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1

      const d = !isParsed ? date.replace(/ /g, 'T') : date

      return new Date(d).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },

    formatDateWithHour(date) {
      if (!date) {
        return;
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        let usd = date.split('/');
        
        let formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1]);

        return formatted.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1;

      const d = !isParsed ? date.replace(/ /g, 'T') : date;

      return new Date(d).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },

    isMobile() {
      if (this.deviceType == 'mobile' || this.deviceType == 'tablet') {
        return true
      }
      return false
    },

    isTablet() {
      if (this.deviceType == 'tablet') {
        return true
      }
      return false
    },

    isOnlyMobile() {
      if (this.deviceType == 'mobile') {
        return true
      }
      return false
    },

    isDesktop() {
      if (this.deviceType == 'desktop') {
        return true
      }
      return false
    },

    uploader(filename) {
      if (!filename) {
        return ''
      }
      return `${this.apiUrl}/uploader/${filename}`
    },

    openMenu() {
      const body = document.querySelector('body')
      const header = document.querySelector('#header')
      const mainMenu = document.querySelector('#mainMenu')
      const headerInner = document.querySelector('.header-inner')

      if (body.classList.contains('mainMenu-open')) {
        body.classList.remove('mainMenu-open')
        // header.classList.add('dark')
        mainMenu.classList.remove('menu-animate')
        mainMenu.style.minHeight = '0'
        headerInner.style.position = null
        headerInner.style.top = null
        headerInner.style.left = null
        if (header.classList.contains('header-sticky')) {
          this.isOpenMenu = true
        } else {
          this.isOpenMenu = false
        }
      } else {
        body.classList.add('mainMenu-open')
        header.classList.remove('dark')
        mainMenu.classList.add('menu-animate')
        mainMenu.style.minHeight = '600px'
        headerInner.style.position = 'fixed'
        headerInner.style.top = '0'
        headerInner.style.left = '0'
        this.isOpenMenu = true
      }
    },

    //   check_webp_feature:
    //   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
    //   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
    check_webp_feature(feature, callback) {
      const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha:
          'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
          'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
      }
      const img = new Image()
      img.onload = function () {
        const result = img.width > 0 && img.height > 0
        callback(feature, result)
      }
      img.onerror = function () {
        callback(feature, false)
      }
      img.src = `data:image/webp;base64,${kTestImages[feature]}`
    },

    dismissAlert(refEl) {
      this.$refs[refEl].style.setProperty('display', 'none', 'important')
    },

    showModal(refEl) {
      this.$refs[refEl].show()

      const body = document.querySelector('body')
      const html = document.querySelector('html')
      body.classList.add('modal-open')
      html.classList.add('modal-open')
    },

    hideModal(refEl) {
      this.$refs[refEl].hide()

      const body = document.querySelector('body')
      const html = document.querySelector('html')

      body.classList.remove('modal-open')
      html.classList.remove('modal-open')
    },

    numFormatter(num) {
      return Math.abs(Number(num)) >= 1.0e+9

      ? Math.abs(Number(num)) / 1.0e+9 + "B"
      
      : Math.abs(Number(num)) >= 1.0e+6

      ? Math.abs(Number(num)) / 1.0e+6 + "M"

      : Math.abs(Number(num)) >= 1.0e+3

      ? Math.abs(Number(num)) / 1.0e+3 + "K"

      : Math.abs(Number(num));
    },
    debounce(callback, wait) {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        callback()
      }, wait)
    }
  },
}
</script>

<template>
  <div v-if="isMobile">
    <div class="mobile-custom-content">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="mobile-content-card d-flex justify-content-between text-white">
              <div class="content-card-slider mb-3 mr-4">
                <h4 class="black">{{ (data[0] || {}).title }}</h4>
                <span class="light">{{ (data[0] || {}).description }}</span>
              </div>
              <picture>
                <source :srcset="uploader((data[0] || {}).image)" type="image/webp">
                <img
                  :src="uploader((data[0] || {}).fallback_image)"
                  :alt="(data[0] || {}).alt_image"
                  class="align-self-center img-content-card-slider"
                />
              </picture>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="mobile-content-card d-flex justify-content-between text-white">
              <div class="content-card-slider mb-3 mr-4">
                <h4 class="black">{{ (data[1] || {}).title }}</h4>
                <span class="light">{{ (data[1] || {}).description }}</span>
              </div>
              <picture>
                <source :srcset="uploader((data[1] || {}).image)" type="image/webp">
                <img
                  :src="uploader((data[1] || {}).fallback_image)"
                  :alt="(data[1] || {}).alt_image"
                  class="align-self-center img-content-card-slider"
                />
              </picture>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="mobile-content-card d-flex justify-content-between text-white">
              <div class="content-card-slider mb-3 mr-4">
                <h4 class="black">{{ (data[2] || {}).title }}</h4>
                <span class="light">{{ (data[2] || {}).description }}</span>
              </div>
              <picture>
                <source :srcset="uploader((data[2] || {}).image)" type="image/webp">
                <img
                  :src="uploader((data[2] || {}).fallback_image)"
                  :alt="(data[2] || {}).alt_image"
                  class="align-self-center img-content-card-slider"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightsMobileAtom',
  props: {
    isMobile: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    data: {
      type: Array,
      default: function() {
        return []
      },
    },
    uploader: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
#slider {
  position: relative;
  template {
    display: inline !important;
  }
  .arrow-carousel-slide {
    position: absolute;
    top: 50%;
    border: 1px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 99rem;
    z-index: 2;

    i {
      color: #fff;
      font-size: 2em;
    }

    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
}
</style>

<template>
  <LayoutPrivatePage>
    <template #main-content>
      <div class="row my-5">
        <div class="col-md-8 p-r-30">
          <div class="overview">
            <div class="row">
              <template v-if="isUnfinishedRegistration()">
                <div class="col-md-12 p-b-20 mt-3">
                  <div class="relative b-grey-black bdrd-12px">
                    <div class="alert-mobile white px-3 py-3 bdrd-12px mt-4">
                      <p class="text-dark fs-115em bold">{{ unfinishedOrder.event_name }} - {{ unfinishedOrder.itinerary_name }} - {{ unfinishedOrder.package_name }}</p>
                      <div class="stepper-wrapper mt-1">
                        <div v-for="(v, k) in unfinishedOrderHistorical" :key="'uoh-'+k" class="stepper-item" :class="defineUnfinishedStatus(v.payment_status, v.active)">
                          <div class="step-counter bold">{{ k + 1 }}</div>
                          <span class="regular">{{ ordinal(v.num_of_payment) }} Payment</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <template v-if="unfinishedOrder.status !== 'P'">
                            <a
                              :href="
                                $router.resolve({
                                  name: 'PaymentDetails',
                                  params: { pageSlug: (findActiveUnfinishedHistorical('order_id')) },
                                }).href
                              "
                              class="btn d-flex align-items-center justify-content-center btn-primary bold mt-3"
                            >
                              Continue {{ ordinal(findActiveUnfinishedHistorical('num_of_payment')) }} Payment
                              <i class="icon-arrow-right fs-1em ml-2"></i>
                            </a>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- <template v-if="myInformation.profile === 0 || myInformation.mailing === 0 || myInformation.network === 0 || myInformation.medical === 0">
                <div class="col-md-12 pt-4 pb-2">
                  <div class="row mb-4">
                    <p class="mb-0">My Information</p>
                    <h5 class="semibold fs-125em ls-0 mb-3">Complete my profile</h5>
                    <div class="wrap_profile_progress">
                      <b-progress
                        height="1.5rem"
                        :max="100"
                        class="mb-3"
                      >
                        <b-progress-bar :value="defineProfileProgress()" :label="`${(defineProfileProgress())}%`"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <CompleteProfile :my-information="myInformation" :user="user" />
                      <hr />
                      <CompleteMailing :my-information="myInformation" :user="user" />
                      <hr />
                      <CompleteNetwork :my-information="myInformation" :user="user" />
                      <hr />
                      <CompleteMedical :my-information="myInformation" :user="user" />
                      <hr />
                    </div>
                  </div>
                </div>
              </template> -->
              <div class="col-md-12 py-4">
                <div class="d-flex justify-content-between align-items-end">
                  <div>
                    <p class="mb-0">My Purchases</p>
                    <h5 class="semibold fs-125em ls-0">Your Next Run</h5>
                  </div>
                  <div v-if="(recentOrders || []).length > 0" class="mb-2">
                    <router-link :to="{name: 'MyPurchases'}" class="text-link">See More</router-link>
                  </div>
                </div>
                <template v-if="(recentOrders || []).length > 0">
                  <div class="relative card-private-page mt-3">
                    <RecentOrder 
                      v-for="(v, k) in recentOrders"
                      :key="'recentorder-'+k"
                      :transactionId="v.transaction_id"
                      :price="numFormatter(v.price)"
                      :image="uploader(v.image)"
                      :fallbackImage="uploader(v.fallback_image)"
                      :altImage="v.alt_image"
                      :eventName="v.event_name"
                      :raceDate="formatDateWithoutWeekday(v.race_date)"
                      :distance="v.distance"
                      :slug="v.transaction_id"
                      :historical="recentOrdersHistorical.filter(i => i.transaction_id === v.transaction_id)"
                      :transaction-type="v.transaction_type"
                      :base-status="v.base_status"
                      :num-of-payment="ordinal(v.num_of_payment)"
                      :is-allow="v.is_allow"
                      :is-mobile="isMobile()"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-12">
                      <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                        <div class="initial-content d-flex flex-column align-items-center">
                          <div class="wrapper-illustration w-50 d-flex justify-content-center">
                            <img :src="`${baseUrl}${((dashboardContent || {}).nextRun || {}).initial_image}`" alt="" class="w-50" />
                          </div>
                          <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">{{ ((dashboardContent || {}).nextRun || {}).initial_title }}</h2>
                          <span>{{ ((dashboardContent || {}).nextRun || {}).initial_description }}</span>
                          <router-link :to="{ name: 'RunningEvents' }" class="btn btn-primary mt-3"
                            >{{ ((dashboardContent || {}).nextRun || {}).initial_cta }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="JSON.stringify(recentToBeReviewed) !== '{}' && recentToBeReviewed !== null" class="col-md-12 mt-3">
                <div class="row justify-content-between align-items-end mb-3">
                  <div class="col">
                    <p class="mb-0">My Reviews</p>
                    <h5 class="semibold fs-125em ls-0">Write a Review</h5>
                  </div>
                  <div class="col-auto mb-2">
                    <router-link :to="{name: 'MyPurchases'}" class="text-link">See More</router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-4">
                    <div class="card-registered-event b-grey-black px-4 py-4 bdrd-12px">
                      <div class="d-flex justify-content-between bb-1px-grey pb-4">
                        <div class="d-flex align-items-center">
                          <picture class="img-small-fr">
                            <source
                              :src="uploader((recentToBeReviewed || {}).trv_preview_img)"
                              type="image/webp"
                            />
                            <img
                              :src="uploader((recentToBeReviewed || {}).trv_preview_fallback_img)"
                              :alt="(recentToBeReviewed || {}).trv_preview_alt_img"
                              class="img-small-fr"
                            />
                          </picture>
                          <div class="mx-3">
                            <p class="bold text-dark fs-125em mb-0">{{ (recentToBeReviewed || {}).trv_event_name }}</p>
                            <span class="grey-color regular">{{ (recentToBeReviewed || {}).trv_itinerary_name }}, {{ (recentToBeReviewed || {}).trv_package_name }} Package</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="pt-2 d-flex"
                        :class="isOnlyMobile() ? 'flex-column' : 'justify-content-between align-items-end'"
                      >
                        <div class="d-flex flex-column">
                          <p class="mb-0 text-dark fs-125em semibold">{{ (recentToBeReviewed || {}).trv_full_name }}</p>
                          <span class="grey-color regular">Order Date: {{ formatDateWithHour((recentToBeReviewed || {}).trv_order_date) }}</span>
                        </div>
                        <div class="d-flex flex-column">
                          <div class="d-flex mt-2">
                            <button
                              @click="
                                $router.push({ name: 'FormReview', params: { pageSlug: (recentToBeReviewed || {}).trv_transaction_id } })
                              "
                              class="btn btn-primary mb-0 text-white medium d-flex align-self-start mt-3 align-items-center"
                              :class="isMobile() ? 'w-100 justify-content-center' : ''"
                            >
                              Write a Review
                              <i class="fa fa-pencil-alt ml-2"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="sticky-100px">
            <template v-if="(promo || []).length > 0">
              <div class="col-md-12 pb-4 px-0">
                <p class="mb-0">Promo</p>
                <h5 class="semibold fs-125em ls-0">Designed to help our runner save!</h5>
                <div class="relative mt-4">
                  <carousel :items="1" :nav="false">
                    <div v-for="(v, k) in promo" :key="'pr-'+k" class="wrap-promo-item">
                      <img @click="$router.push({name:'PromoDetails', params:{pageSlug: v.slug}})" :src="uploader(v.fallback_image)" :alt="'Promo '+k" />
                    </div>
                    <template slot="next" v-if="(promo || []).length > 1">
                      <div class="arrow-carousel-component with-indicator private-page right pointer">
                        <i class="icon-arrow-right"></i>
                      </div>
                    </template>
                    <template slot="prev" v-if="(promo || []).length > 1">
                      <div class="arrow-carousel-component with-indicator private-page left pointer">
                        <i class="icon-arrow-left"></i>
                      </div>
                    </template>
                  </carousel>
                </div>
              </div>
            </template>
            <PPHelpCenter/>
          </div>
        </div>
      </div>
    </template>
    <template #page-title>
      <div class="d-flex flex-column justify-content-center">
        <h2 class="black fs-175em text-white fs-125em mb-1 ls-0" :class="!isMobile() ? 'w-75' : ''">
          Hi, {{ (user || {}).registrant_full_name }}
        </h2>
        <span class="text-grey regular pr-2">
          Welcome to dashboard. You can easily access and manage your personal information, view your purchase history
          through a secure user dashboard.
        </span>
        <template v-if="isUnfinishedRegistration()">
          <div class="relative">
            <div class="alert-mobile white px-3 py-3 bdrd-12px mt-4">
              <p class="text-dark fs-115em bold">{{ unfinishedOrder.event_name }} - {{ unfinishedOrder.itinerary_name }} - {{ unfinishedOrder.package_name }}</p>
              <div class="stepper-wrapper mt-1">
                <div 
                  v-for="(v, k) in unfinishedOrderHistorical" :key="'uohm-'+k" 
                  class="stepper-item" :class="defineUnfinishedStatus(v.payment_status, v.active)" 
                  :title="ordinal(v.num_of_payment)"
                >
                  <div class="step-counter bold">{{ k + 1 }}</div>
                  <span class="regular">{{ ordinal(v.num_of_payment) }} Payment</span>
                </div>
              </div>
              <a
                :href="
                  $router.resolve({ name: 'PaymentDetails', params: { pageSlug: findActiveUnfinishedHistorical('order_id') } }).href
                "
                class="btn d-flex align-items-center justify-content-center btn-primary bold mt-3"
              >
                Continue {{ ordinal(findActiveUnfinishedHistorical('num_of_payment')) }} Payment
                <i class="icon-arrow-right fs-1em ml-2"></i>
              </a>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #content-mobile>
      <div class="overview">
        <div class="row">
          <!-- <template v-if="myInformation.profile === 0 || myInformation.mailing === 0 || myInformation.network === 0 || myInformation.network === 0">
            <div class="col-md-12 pt-4 pb-2">
              <div class="row mb-4">
                <p class="mb-0">My Information</p>
                <h5 class="semibold fs-125em ls-0">Complete my profile</h5>
                <div class="wrap_profile_progress">
                  <b-progress
                    height="1.5rem"
                    :max="100"
                    class="mb-3"
                  >
                    <b-progress-bar :value="defineProfileProgress()" :label="`${(defineProfileProgress()).toFixed(2)}%`"></b-progress-bar>
                  </b-progress>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <CompleteProfile :my-information="myInformation" :user="user" />
                  <hr />
                  <CompleteMailing :my-information="myInformation" :user="user" />
                  <hr />
                  <CompleteNetwork :my-information="myInformation" :user="user" />
                  <hr />
                  <CompleteMedical :my-information="myInformation" :user="user" />
                  <hr />
                </div>
              </div>
            </div>
          </template> -->
          <div class="col-md-12 pb-4 pt-3">
            <div>
              <div>
                <p class="mb-0">My Purchases</p>
                <h5 class="semibold ls-0">Your Next Run</h5>
              </div>
            </div>
            <template v-if="(recentOrders || []).length > 0">
              <RecentOrder 
                v-for="(v, k) in recentOrders"
                :key="'recentorder-'+k"
                :transactionId="v.transaction_id"
                :price="numFormatter(v.price)"
                :image="uploader(v.image)"
                :fallbackImage="uploader(v.fallback_image)"
                :altImage="v.alt_image"
                :eventName="v.event_name"
                :raceDate="formatDateWithoutWeekday(v.race_date)"
                :distance="v.distance"
                :slug="v.transaction_id"
                :historical="recentOrdersHistorical.filter(i => i.transaction_id === v.transaction_id)"
                :transaction-type="v.transaction_type"
                :base-status="v.base_status"
                :num-of-payment="ordinal(v.num_of_payment)"
                :is-allow="v.is_allow"
                :is-mobile="isMobile()"
              />
              <div class="text-center mt-4">
                <router-link :to="{ name: 'MyPurchases' }" class="text-link">See More </router-link>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12">
                  <div class="relative card-private-page p-4 bdrd-12px mt-3 mb-5">
                    <div class="initial-content d-flex flex-column align-items-center">
                      <div class="wrapper-illustration w-50 d-flex justify-content-center">
                        <img :src="`${baseUrl}${((dashboardContent || {}).nextRun || {}).initial_image}`" alt="" class="w-25" />
                      </div>
                      <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">{{ ((dashboardContent || {}).nextRun || {}).initial_title }}</h2>
                      <span>{{ ((dashboardContent || {}).nextRun || {}).initial_description }}</span>
                      <router-link :to="{ name: 'RunningEvents' }" class="btn btn-primary mt-3"
                        >{{ ((dashboardContent || {}).nextRun || {}).initial_cta }}</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="JSON.stringify(recentToBeReviewed) !== '{}' && recentToBeReviewed !== null" class="col-md-12 mt-4">
            <div class="row mb-3">
              <p class="mb-0">My Reviews</p>
              <h5 class="semibold ls-0">Write a Review</h5>
            </div>
            <div class="row">
              <div class="col-md-12 mb-4">
                <div class="card-registered-event b-grey-black px-4 py-4 bdrd-12px">
                  <div class="d-flex justify-content-between bb-1px-grey pb-4">
                    <div class="d-flex align-items-center">
                      <picture class="img-small-fr">
                        <source
                          :src="uploader((recentToBeReviewed || {}).trv_preview_img)"
                          type="image/webp"
                        />
                        <img
                          :src="uploader((recentToBeReviewed || {}).trv_preview_fallback_img)"
                          :alt="(recentToBeReviewed || {}).trv_preview_alt_img"
                          class="img-small-fr"
                        />
                      </picture>
                      <div class="mx-3">
                        <p class="bold text-dark fs-125em mb-0">{{ (recentToBeReviewed || {}).trv_event_name }}</p>
                        <span class="grey-color regular">{{ (recentToBeReviewed || {}).trv_itinerary_name }}, {{ (recentToBeReviewed || {}).trv_package_name }} Package</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="pt-2 d-flex"
                    :class="isOnlyMobile() ? 'flex-column' : 'justify-content-between align-items-end'"
                  >
                    <div class="d-flex flex-column">
                      <p class="mb-0 text-dark fs-125em semibold">{{ (recentToBeReviewed || {}).trv_full_name }}</p>
                      <span class="grey-color regular">Order Date: {{ formatDateWithHour((recentToBeReviewed || {}).trv_order_date) }}</span>
                    </div>
                    <div class="d-flex flex-column">
                      <div class="d-flex mt-2">
                        <router-link
                          :to="{ name: 'FormReview', params: { pageSlug: (recentToBeReviewed || {}).trv_transaction_id } }"
                          class="btn btn-primary mb-0 text-white medium d-flex align-self-start mt-3 align-items-center"
                          :class="isMobile() ? 'w-100 justify-content-center' : ''"
                        >
                          Write a Review
                          <i class="fa fa-pencil-alt ml-2"></i>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="(promo || []).length > 0">
            <div class="col-md-12 py-4">
              <p class="mb-0">Promo</p>
              <h5 class="semibold ls-0">Designed to help our runner save!</h5>
              <div class="relative mt-4">
                <carousel :items="1" :nav="false">
                  <template v-for="(v, k) in promo">
                    <div :key="'prom-'+k" class="wrap-promo-item">
                      <img @click="$router.push({name:'PromoDetails', params:{pageSlug: v.slug}})" :src="uploader(v.fallback_image)" :alt="'Promo-'+k" />
                    </div>
                  </template>
                  <template slot="next" v-if="(promo || []).length > 1">
                    <div class="arrow-carousel-component with-indicator private-page right pointer">
                      <i class="icon-arrow-right"></i>
                    </div>
                  </template>
                  <template slot="prev" v-if="(promo || []).length > 1">
                    <div class="arrow-carousel-component with-indicator private-page left pointer">
                      <i class="icon-arrow-left"></i>
                    </div>
                  </template>
                </carousel>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template #custom-layout>
      <AppModal :withPageTitle="true" ref="goal-detail-modal" :type="'md'" @close="onCloseGoalDetail">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Goal Detail</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-4">
              <p>{{ defineGoalType(inputLogActivity.type) }}</p>
              <p class="semibold fs-1em">{{ goalDetail.total }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <p>Current</p>
              <p class="semibold fs-1em">{{ goalDetail.current }}</p>
            </div>
            <div class="col-4">
              <p>To Go</p>
              <p class="semibold fs-1em">{{ goalDetail.remaining }}</p>
            </div>
          </div>
          <div class="row pb-5">
            <div class="col-12 px-0">
              <hr />
            </div>
            <div class="col-12">
              <div class="row justify-content-between align-items-center" :class="showAddLogInput ? 'mb-0' : 'mb-3'">
                <div class="col-4">
                  <p class="mb-0">Logs</p>
                </div>
                <div class="col-5">
                  <a href="javascript:;" class="text-right alpha-color d-block text-link" @click="onShowAddInput"
                    >Add New Activity</a
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <ValidationObserver v-if="showAddLogInput" v-slot="{ handleSubmit }" ref="FormAddLog">
                <form @submit.prevent="handleSubmit(submitLog)">
                  <div class="row align-items-center gx-2 mt-3">
                    <div class="col-4">
                      <div class="form-group mb-0">
                        <v-date-picker
                          full-width
                          :popover="{ visibility: 'click', placement: 'top', positionFixed: true, modifiers: [{ name: 'offset', options: { offset: [10, -100] } }] }"
                          v-model="inputLogActivity.date"
                          class="mt-3"
                          :masks="{ input: 'DD/MM/YYYY' }"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="d-flex flex-column justify-center items-center">
                              <input
                                v-if="inputEvents"
                                :value="inputValue"
                                placeholder="DD/MM/YYYY"
                                v-on="inputEvents"
                                :onkeydown="false"
                                class="form-control main-control mt-0 px-3 w-32 focus:outline-none focus:border-indigo-300"
                              />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                    </div>
                    <template v-if="inputLogActivity.type == 'D'">
                      <div class="col-4 wrap_input_goal">
                        <div class="position-relative">
                          <input type="text" v-model="inputLogActivity.value" class="regular form-control goal_input" />
                          <span class="regular goal_input_helper">km</span>
                        </div>
                      </div>
                    </template>
                    <template v-if="inputLogActivity.type == 'T'">
                      <div class="col-5">
                        <div class="row gx-0">
                          <div class="col-6 wrap_input_goal position-relative">
                            <input id="hr" type="text" v-model="inputLogActivity.value" class="regular form-control group-item left goal_input" />
                            <label for="hr" class="regular goal_input_helper">hr</label>
                          </div>
                          <div class="col-6 wrap_input_goal position-relative">
                            <input id="min" type="text" v-model="inputLogActivity.value2" class="regular form-control group-item right goal_input" />
                            <label for="min" class="regular goal_input_helper">min</label>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="inputLogActivity.type == 'E'">
                      <div class="col-4 wrap_input_goal position-relative">
                        <input type="text" v-model="inputLogActivity.value" class="regular form-control goal_input" />
                        <span class="regular goal_input_helper">m</span>
                      </div>
                    </template>
                    <div class="col-3">
                      <div class="form-group add-remove mb-0">
                        <div class="wrap_cta">
                          <div @click="submitLog" class="primary-button pointer mw-100">
                            <span>Save</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <VValidate
                        name="Date"
                        v-model="
                          inputLogActivity.date
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <template v-if="logsByType(inputLogActivity.type).length > 0">
              <div v-for="(v, k) in logsByType(inputLogActivity.type)" :key="'log-'+k" class="col-12 mb-3">
                <template v-if="editFormId != v.id">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <p class="mb-0 semibold fs-1em">{{ formatDayJs(v.date, 'D/MM/YYYY') }}</p>
                    </div>
                    <div class="col-4">
                      <p v-if="v.type == 'D'" class="mb-0 semibold fs-1em">{{ v.value }} km</p>
                      <p v-if="v.type == 'T'" class="mb-0 semibold fs-1em">{{ v.value || 0 }}h {{ v.value2 || 0 }}m</p>
                      <p v-if="v.type == 'E'" class="mb-0 semibold fs-1em">{{ v.value }}m</p>
                    </div>
                    <div class="col-4">
                      <div class="wrap_cta">
                        <div class="row gx-0 align-items-center">
                          <!-- <div class="col-auto">
                            <div class="form-group add-remove mb-0">
                              <div @click="onEditLog(v)" class="edit-button pointer mr-1">
                                <i class="icon-edit text-white"></i>
                              </div>
                            </div>
                          </div> -->
                          <div class="col-auto">
                            <div class="form-group add-remove mb-0">
                              <div @click="onDeleteLog(v, k)" class="remove-button pointer">
                                <i class="icon-trash-2 text-white"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <ValidationObserver v-slot="{ handleSubmit }" ref="FormAddLog">
                    <form @submit.prevent="handleSubmit(submitLog)">
                      <div class="row align-items-center gx-2">
                        <div class="col-4">
                          <div class="form-group mb-0">
                            <v-date-picker
                              full-width
                              :popover="{ visibility: 'click', placement: 'top', positionFixed: true, modifiers: [{ name: 'offset', options: { offset: [10, -100] } }] }"
                              v-model="editLogActivity.date"
                              class="mt-3"
                              :masks="{ input: 'DD/MM/YYYY' }"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <div class="d-flex flex-column justify-center items-center">
                                  <input
                                    v-if="inputEvents"
                                    :value="inputValue"
                                    placeholder="DD/MM/YYYY"
                                    v-on="inputEvents"
                                    :onkeydown="false"
                                    class="form-control main-control mt-0 px-3 w-32 focus:outline-none focus:border-indigo-300"
                                  />
                                  <VValidate
                                    name="Date"
                                    v-model="
                                      editLogActivity.date
                                    "
                                    :rules="{ required: true }"
                                  />
                                </div>
                              </template>
                            </v-date-picker>
                          </div>
                        </div>
                        <template v-if="editLogActivity.type == 'D'">
                          <div class="col-4 wrap_input_goal">
                            <div class="position-relative">
                              <input type="text" v-model="editLogActivity.value" class="regular form-control goal_input h-100" />
                              <span class="regular goal_input_helper">km</span>
                            </div>
                          </div>
                        </template>
                        <template v-if="editLogActivity.type == 'T'">
                          <div class="col-5">
                            <div class="row gx-0">
                              <div class="col-6 wrap_input_goal position-relative">
                                <input id="hr" type="text" v-model="editLogActivity.value" class="regular form-control h-45px group-item left goal_input" />
                                <label for="hr" class="regular goal_input_helper">hr</label>
                              </div>
                              <div class="col-6 wrap_input_goal position-relative">
                                <input id="min" type="text" v-model="editLogActivity.value2" class="regular form-control h-45px group-item right goal_input" />
                                <label for="min" class="regular goal_input_helper">min</label>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="editLogActivity.type == 'E'">
                          <div class="col-4 wrap_input_goal position-relative">
                            <input type="text" v-model="editLogActivity.value" class="regular form-control goal_input" />
                            <span class="regular goal_input_helper">m</span>
                          </div>
                        </template>
                        <div class="col-4">
                          <div class="wrap_cta">
                            <div class="row gx-0 align-items-center">
                              <div class="col-auto">
                                <div class="form-group add-remove mb-0">
                                  <div @click="onUpdateLog" class="primary-button small pointer mr-1">
                                    <i class="icon-check text-white"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto">
                                <div class="form-group add-remove mb-0">
                                  <div @click="onCancelEditLog" class="outline_button pointer">
                                    <i class="icon-x outline"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="col-12">
                <div class="relative card-private-page p-4 bdrd-12px mt-1 mb-1">
                  <div class="initial-content d-flex flex-column align-items-center">
                    <div class="wrapper-illustration w-50 d-flex justify-content-center">
                      <img :src="`${baseUrl}/assets/images/static-icon/empty-vect.svg`" alt="goal" class="w-25" />
                    </div>
                    <h2 class="text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">No activity yet</h2>
                    <span class="text-center">You can submit new progress by adding new activity</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="false" ref="modal-welcome" :type="'sm'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/welcome.svg`" alt="Welcome" class="w-100" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">
                  Thank you for signing in for the first time.
                </p>
                <span class="regular fs-1em"
                  >We're excited to have you as a part of our community. Your account is now activated and ready for you
                  to explore.</span
                >
                <button
                  @click="closeModalWelcome"
                  class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
      <AppModal :withPageTitle="false" ref="modal-success-notify" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/reminder-vect.svg`" alt="Reminder" class="w-50" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Set notify successfully</p>
                <span class="regular fs-1em"
                  >Thank you for your interest in the event. We will notify you at a later date if registration becomes
                  available</span
                >
                <button
                  @click="hideModal('modal-success-notify')"
                  class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
      <AppModal :withPageTitle="true" ref="modal-goal" :type="'n'" @close="onCloseModalGoal">
        <template #title-modal>
          <h4 class="bold ls-0 mb-0">Your goals in {{ goalList.long }}</h4>
        </template>
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column">
                <div class="row">
                  <label class="d-block mb-2">Type</label>
                  <div class="form-group mb-2 add-remove" v-for="(v1, k1) in goalList.goals" :key="k1">
                    <template v-if="!isExtraSmallDevice">
                      <div class="row gx-2">
                        <div class="col-4" :id="'wrap_goal_type_'+k1">
                          <select v-model="v1.type" @change="setSelectedGoalType(v1.type, k1, 'wrap_goal_type_'+k1)" name="goalType" id="goalType" class="form-select goal_select">
                            <option value="D" v-if="uniqueGoalType('D', k1)">Distance</option>
                            <option value="T" v-if="uniqueGoalType('T', k1)">Time</option>
                            <option value="E" v-if="uniqueGoalType('E', k1)">Elevation</option>
                          </select>
                        </div>
                        <template v-if="v1.type == 'D'">
                          <div class="col-4 wrap_input_goal position-relative">
                            <input type="text" v-model="v1.value" class="regular form-control goal_input h-100" />
                            <span class="regular goal_input_helper">km</span>
                          </div>
                        </template>
                        <template v-if="v1.type == 'T'">
                          <div class="col-5">
                            <div class="row gx-0">
                              <div class="col-6 wrap_input_goal position-relative">
                                <input type="text" v-model="v1.value" class="regular form-control group-item left goal_input h-45px" />
                                <span class="regular goal_input_helper">hr</span>
                              </div>
                              <div class="col-6 wrap_input_goal position-relative">
                                <input type="text" v-model="v1.value2" class="regular form-control group-item right goal_input h-45px" />
                                <span class="regular goal_input_helper">min</span>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="v1.type == 'E'">
                          <div class="col-4 wrap_input_goal position-relative">
                            <input type="text" v-model="v1.value" class="regular form-control goal_input" />
                            <span class="regular goal_input_helper">m</span>
                          </div>
                        </template>
                        <div class="col-3">
                          <div class="row gx-2 align-items-center">
                            <div class="col-6">
                              <div class="add-button pointer" @click="addGoalItem(k1)">
                                <i class="icon-plus"></i>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="remove-button pointer" @click="removeGoalItem(k1)">
                                <i class="icon-trash"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="row gx-2">
                        <div class="col-12 mb-3" :id="'wrap_goal_type_'+k1">
                          <select v-model="v1.type" @change="setSelectedGoalType(v1.type, k1, 'wrap_goal_type_'+k1)" name="goalType" id="goalType" class="form-select goal_select">
                            <option value="D" v-if="uniqueGoalType('D', k1)">Distance</option>
                            <option value="T" v-if="uniqueGoalType('T', k1)">Time</option>
                            <option value="E" v-if="uniqueGoalType('E', k1)">Elevation</option>
                          </select>
                        </div>
                        <template v-if="v1.type == 'D'">
                          <div class="col-12 mb-3 wrap_input_goal position-relative">
                            <input type="text" v-model="v1.value" class="regular form-control goal_input h-100" />
                            <span class="regular goal_input_helper">km</span>
                          </div>
                        </template>
                        <template v-if="v1.type == 'T'">
                          <div class="col-12 mb-3">
                            <div class="row gx-0">
                              <div class="col-6 wrap_input_goal position-relative">
                                <input type="text" v-model="v1.value" class="regular form-control group-item left goal_input h-45px" />
                                <span class="regular goal_input_helper">hr</span>
                              </div>
                              <div class="col-6 wrap_input_goal position-relative">
                                <input type="text" v-model="v1.value2" class="regular form-control group-item right goal_input h-45px" />
                                <span class="regular goal_input_helper">min</span>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="v1.type == 'E'">
                          <div class="col-12 col-sm-4 wrap_input_goal position-relative">
                            <input type="text" v-model="v1.value" class="regular form-control goal_input" />
                            <span class="regular goal_input_helper">m</span>
                          </div>
                        </template>
                        <div class="col-12">
                          <div class="row gx-2 align-items-center justify-content-end">
                            <div class="col-2">
                              <div class="add-button pointer" @click="addGoalItem(k1)">
                                <i class="icon-plus"></i>
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="remove-button pointer" @click="removeGoalItem(k1)">
                                <i class="icon-trash"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <b-form-group class="d-flex align-items-center">
                      <b-form-checkbox v-model="goalList.isEmptyGoal" value="Y" unchecked-value="N">
                        No goal?
                        <b-form-text>Sometimes we need time to rest, and prepare for a better performance.</b-form-text>
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>

                <button
                  class="btn btn-primary semibold d-flex text-center justify-content-center w-100 mt-4 align-items-center"
                  :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
                  @click="submitGoal"
                >
                  Save My Goals
                </button>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
      <AppAlert
        ref="modal-success-savegoals"
        vref="modal-success-savegoals"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Goal has been successfully saved"
        description="You've set yourself up for success at RunDeGlobe by setting a great goal - keep up the good work!"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

       <AppAlert
        ref="modal-success-add-log"
        vref="modal-success-add-log"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Activity has been successfully saved"
        description="keep tracking your targets, make sure you get the benefits"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppAlert
        ref="modal-success-update-log"
        vref="modal-success-update-log"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Activity has been successfully saved"
        description="keep tracking your targets, make sure you get the benefits"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppAlert
        ref="modal-success-delete-log"
        vref="modal-success-delete-log"
        type="success"
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`,
        }"
        title="Activity has been successfully deleted"
        description="keep tracking your targets, make sure you get the benefits"
        :button="{
          main: {
            text: 'Ok',
          },
        }"
      />

      <AppAlert 
        ref="modal-confirmation-delete-log" 
        vref="modal-confirmation-delete-log" 
        type="warning"
        title="Delete this activity?" 
        :source="{
          fallbackSrc: `${baseUrl}/assets/images/vector/confirmed-vect.svg`
        }"
        description="You won't be able to recover this activity."
         :button="{
          main: {
            text: 'Yes, Delete',
            handle: onConfirmDeleteLog
          },
          secondary: {
            text: 'Cancel'
          }
        }" 
      />

      <AppModal :withPageTitle="false" ref="modal-confirm-delete" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/option-vect.svg`" alt="Delete" class="w-25" />
                <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Delete the purchase?</p>
                <span class="regular fs-1em">You won't be able to recover this data.</span>
                <div class="col-md-12 d-flex justify-content-center mt-4">
                  <button type="button" @click="hideModal('modal-confirm-delete')" class="btn btn-outline-main ml-0">
                    Cancel
                  </button>
                  <button
                    type="button"
                    @click="hideModal('modal-confirm-delete')"
                    class="btn btn-primary"
                    :class="isOnlyMobile() ? 'w-100' : ''"
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
    </template>
  </LayoutPrivatePage>
</template>

<script>
// @ is an alias to /src
import Global from '../libs/Global.vue'
import LayoutPrivatePage from '@/layouts/LayoutPrivatePage'
import AppModal from '@/components/AppModal.vue'
import AppAlert from '@/components/AppAlert'
import PPHelpCenter from '@/components/PPHelpCenter'
import dayjs from 'dayjs'

import carousel from 'vue-owl-carousel'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import RecentOrder from '@/views/atoms/dashboard/RecentOrder.vue'
import CompleteProfile from '@/views/atoms/dashboard/CompleteProfile.vue'
import CompleteMailing from '@/views/atoms/dashboard/CompleteMailing.vue'
import CompleteNetwork from '@/views/atoms/dashboard/CompleteNetwork.vue'
import CompleteMedical from '@/views/atoms/dashboard/CompleteMedical.vue'
import Gen from '@/libs/Gen'


export default {
  extends: Global,
  name: 'Dashboard',
  components: {
    LayoutPrivatePage,
    AppModal,
    AppAlert,
    VueTimepicker,
    carousel,
    RecentOrder,
    PPHelpCenter,
    CompleteProfile,
    CompleteMailing,
    CompleteNetwork,
    CompleteMedical,
  },
  data() {
    return {
      deleteId: '',
      deleteIndex: '',
      editFormId: '',
      showAddLogInput: false,
      goalsPrevState: [],
      goalDetail: {
        index: '',
        current: '',
        remaining: '',
        total: '',
      },
      inputLogActivity: {
        id: '',
        type: '',
        date: new Date(),
        month: '',
        year: '',
        value: '0',
        value2: '0',
      },
      editLogActivity: {
        id: '',
        type: '',
        date: new Date(),
        month: '',
        year: '',
        value: '0',
        value2: '0',
      },
      isWebPSupport: false,
      isMounted: false,
      logValue: 0,
      months: [
        {
          "id": 1,
          "name": "Jan",
          "long": "January",
        },
        {
          "id": 2,
          "name": "Feb",
          "long": "February",
        },
        {
          "id": 3,
          "name": "Mar",
          "long": "March",
        },
        {
          "id": 4,
          "name": "Apr",
          "long": "April",
        },
        {
          "id": 5,
          "name": "May",
          "long": "May",
        },
        {
          "id": 6,
          "name": "Jun",
          "long": "June",
        },
        {
          "id": 7,
          "name": "Jul",
          "long": "July",
        },
        {
          "id": 8,
          "name": "Aug",
          "long": "August",
        },
        {
          "id": 9,
          "name": "Sep",
          "long": "September",
        },
        {
          "id": 10,
          "name": "Oct",
          "long": "October",
        },
        {
          "id": 11,
          "name": "Nov",
          "long": "November",
        },
        {
          "id": 12,
          "name": "Dec",
          "long": "December",
        },
      ],
      breadcrumbItems: [
        {
          text: 'Home',
          to: { name: 'Home' },
        },
        {
          text: 'Running Events',
          active: true,
        },
      ],
      activityPos: {
        top: 0,
        left: 0,
        x: 0,
        y: 0,
      },
      isGrabbing: false,
    }
  },

  watch: {
    '$route.query.redirectFrom': {
      handler() {
        this.showModalWelcome()
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions('dashboard', ['getDashboardData']),

    defineProfileProgress() {
      let progress = 0

      progress += this.user.registrant_first_name !== null ? 1 : 0
      progress += this.user.registrant_last_name !== null ? 1 : 0
      progress += this.user.registrant_phone !== null ? 1 : 0
      progress += this.user.registrant_citizenship !== null ? 1 : 0
      progress += this.user.registrant_gender !== null ? 1 : 0
      progress += this.user.registrant_birth_date !== null ? 1 : 0
      progress += this.user.registrant_passportno !== null ? 1 : 0
      progress += this.user.registrant_passportexp !== null ? 1 : 0
      progress += this.user.registrant_country !== null ? 1 : 0
      progress += this.user.registrant_address_1 !== null ? 1 : 0
      progress += this.user.registrant_state !== null ? 1 : 0
      progress += this.user.registrant_city !== null ? 1 : 0
      progress += this.user.registrant_district !== null ? 1 : 0
      progress += this.user.registrant_subdistrict !== null ? 1 : 0
      progress += this.user.registrant_zip_code !== null ? 1 : 0
      progress += this.myInformation.network
      progress += this.myInformation.medical

      return parseInt((progress / 16) * 100)
    },

    defineUnfinishedStatus(status, active) {
      if (status == 'Success') {
        return 'Success'
      }

      if (active == 'Y') {
        return 'waiting'
      }

      return ''
    },

    isUnfinishedRegistration() {
      return this.unfinishedOrder !== null 
      && JSON.stringify(this.unfinishedOrder) !== '{}'
      && this.unfinishedOrderHistorical !== null
      && JSON.stringify(this.unfinishedOrderHistorical) !== '{}'
    },

    findActiveUnfinishedHistorical(key) {
      if (!Array.isArray(this.unfinishedOrderHistorical)) {
        return null
      }
      return ((this.unfinishedOrderHistorical || []).find(o => o.active === 'Y') || {})[key]
    },

    onShowAddInput() {
      const newInput = {
        ...this.inputLogActivity,
        id: '',
        type: this.inputLogActivity.type,
        date: new Date(),
        value: '0',
        value2: '0',
      }

      this.inputLogActivity = newInput

      this.showAddLogInput = !this.showAddLogInput
    },

    onDeleteLog(v) {
      this.deleteId = v.id
      this.$refs['modal-confirmation-delete-log'].show()
    },

    async onConfirmDeleteLog() {
      const id = this.deleteId

      const data = this.goals[this.goalDetail.index]
      const logs = this.logsByType(this.inputLogActivity.type)
      const i = logs.find(l => l.id === id)

      const intValue = this.intVal(i.value)
      const intValue2 = this.intVal(i.value2)
      const floatValue = this.floatVal(i.value)

      this.$store.state.loading.screen = true

      if (i.type == 'D') {
        const current = this.getCurrentByType(i.type) - floatValue
        const remaining = data.value - current
        this.goalDetail.current = `${current} km`
        this.goalDetail.remaining = `${remaining} km to go`
      } else if (i.type == 'T') {
        const current = this.getCurrentByType(i.type) - this.getTimeMinutes(intValue, intValue2)
        const remaining = this.getTimeMinutes(data.value, data.value2) - current
        this.goalDetail.current = `${this.getSplitTime(current)}`
        this.goalDetail.remaining = `${this.getSplitTime(remaining)} to go`
      } else if (i.type == 'E') {
        const current = this.getCurrentByType(i.type) - floatValue
        const remaining = data.value - current
        this.goalDetail.current = `${current} m`
        this.goalDetail.remaining = `${remaining} m to go`
      }

      this.$store.commit('dashboard/DELETE_LOG_ITEM', id)

      try {
        const token = Gen.getCookie('userAuthRdg')

        const resp = await Gen.apiRest(`/private-pages/log/${this.deleteId}`, { 
          headers: Gen.apiHeaderAuth(token)
        }, 'DELETE')
  
        if (resp.status == 200) {
          this.$store.state.loading.screen = false
          this.$refs['modal-confirmation-delete-log'].hide()
          this.$refs['modal-success-delete-log'].show()
        }
      } catch (error) {
        this.$store.state.loading.screen = false
      }
    },

    onEditLog(v) {
      this.editLogActivity = {
        ...this.inputLogActivity,
        id: v.id,
        date: v.date,
        value: v.value,
        value2: v.value2
      }
      this.editFormId = v.id
    },

    onCancelEditLog() {
      this.editLogActivity = {
        id: '',
        type: '',
        date: new Date(),
        month: '',
        year: '',
        value: '0',
        value2: '0',
      }
      this.editFormId = ''
    },

    async onUpdateLog() {
      let i = this.editLogActivity
      
      const intValue = this.intVal(i.value)
      const intValue2 = this.intVal(i.value2)
      const floatValue = this.floatVal(i.value)
      const floatValue2 = this.floatVal(i.value2)

      if (['0', '', null].indexOf(i.value) > -1 && ['0', '', null].indexOf(i.value2) > -1) {
        return null
      }

      this.$store.state.loading.screen = true

      const data = this.goals[this.goalDetail.index]

      if (i.type == 'D') {
        i.value = floatValue
        const current = this.getCurrentByType(i.type) + floatValue
        const remaining = data.value - current
        this.goalDetail.current = `${current} km`
        this.goalDetail.remaining = `${remaining} km to go`

        this.$store.commit("dashboard/UPDATE_LOG_ITEM", {
          id: i.id,
          date: i.date,
          type: i.type,
          value: floatValue,
          value2: '0'
        })
      } else if (i.type == 'T') {
        i.value = intValue
        i.value2 = intValue2
        const current = this.getCurrentByType(i.type) + this.getTimeMinutes(intValue, intValue2)
        const remaining = this.getTimeMinutes(data.value, data.value2) - current
        this.goalDetail.current = `${this.getSplitTime(current)}`
        this.goalDetail.remaining = `${this.getSplitTime(remaining)} to go`

        this.$store.commit("dashboard/UPDATE_LOG_ITEM", {
          id: i.id,
          date: i.date,
          type: i.type,
          value: intValue,
          value2: intValue
        })
      } else if (i.type == 'E') {
        i.value = floatValue
        const current = this.getCurrentByType(i.type) + floatValue
        const remaining = data.value - current
        this.goalDetail.current = `${current} m`
        this.goalDetail.remaining = `${remaining} m to go`

        this.$store.commit("dashboard/UPDATE_LOG_ITEM", {
          id: i.id,
          date: i.date,
          type: i.type,
          value: floatValue,
          value2: '0'
        })
      }

      const token = Gen.getCookie("userAuthRdg")

      try {
        const resp = await Gen.apiRest(`/private-pages/log/${i.id}`, { 
          data: { ...i },
          headers: Gen.apiHeaderAuth(token)
        }, 'PUT')
  
        if (resp.status == 200) {
          this.$store.state.loading.screen = false
          this.editLogActivity.date = new Date()
          this.editLogActivity.value = '0'
          this.editLogActivity.value2 = '0'
          this.editFormId = ''
          this.$refs['modal-success-add-log'].show()
        }
      } catch (error) {
        this.$store.state.loading.screen = false
      }
    },

    intVal(value) {
      return parseInt(value) || 0
    },

    floatVal(value) {
      return parseFloat(value.toString().replace(',', '.')) || 0
    },

    getTimeMinutes(value, value2) {
      return ((this.intVal(value)) * 60) + this.intVal(value2)
    },

    remainingValue(value, current = 0, type) {
      let remaining = value - current

      if (remaining < 0) {
        remaining = 0
      }
      
      if (type == 'D') {
        return `${remaining.toFixed(2)} km to go`
      } else if (type == 'T') {
        return `${this.getSplitTime(remaining)} to go`
      } else {
        return `${remaining.toFixed(2)} m to go`
      }
    },

    getSplitTime(time) {
      // time = total minute
      const hour = this.intVal(time / 60)
      const minute = hour > 0 ? time - (hour * 60) : time
      return `${hour}h ${minute}m`
    },

    getCurrentByType(type) {
      let data = []
      if (type == 'T') {
        data = this.logsByType(type)
          .filter(d => d.type == type)
          .map(d => {
            return (d.value * 60) + d.value2
          })
        return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      } else {
        data = this.logsByType(type)
          .filter(d => d.type == type)
          .map(d => this.floatVal(d.value))
          return this.floatVal(data.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
      }
    },

    formatDayJs(date, format) {
      return dayjs(date).format(format)
    },

    async submitLog() {
      let i = this.inputLogActivity
      
      const intValue = this.intVal(i.value)
      const intValue2 = this.intVal(i.value2)
      const floatValue = this.floatVal(i.value)

      if (['0', '', null].indexOf(i.value) > -1 && ['0', '', null].indexOf(i.value2) > -1) {
        return null
      }

      this.$store.state.loading.screen = true

      const data = this.goals[this.goalDetail.index]

      if (i.type == 'D') {
        i.value = floatValue
        const current = this.floatVal(this.getCurrentByType(i.type)) + floatValue
        const remaining = this.floatVal(data.value) - current
        this.goalDetail.current = `${current} km`
        this.goalDetail.remaining = `${remaining} km to go`
      } else if (i.type == 'T') {
        i.value = intValue
        i.value2 = intValue2
        const current = this.getCurrentByType(i.type) + this.getTimeMinutes(intValue, intValue2)
        const remaining = this.getTimeMinutes(data.value, data.value2) - current
        this.goalDetail.current = `${this.getSplitTime(current)}`
        this.goalDetail.remaining = `${this.getSplitTime(remaining)} to go`
      } else if (i.type == 'E') {
        i.value = floatValue
        const current = this.floatVal(this.getCurrentByType(i.type)) + floatValue
        const remaining = this.floatVal(data.value) - current
        this.goalDetail.current = `${current} m`
        this.goalDetail.remaining = `${remaining} m to go`
      }

      const token = Gen.getCookie("userAuthRdg")

      try {
        const resp = await Gen.apiRest('/private-pages/logs', { 
          data: { ...i },
          headers: Gen.apiHeaderAuth(token)
        }, 'POST')
  
        if (resp.status == 201) {
          this.$store.state.loading.screen = false
          this.inputLogActivity.date = new Date()
          this.inputLogActivity.value = '0'
          this.inputLogActivity.value2 = '0'
          this.$refs['modal-success-add-log'].show()

          const log = resp.data.data
          this.$store.commit("dashboard/ADD_LOG_ITEM", {
            id: log.id,
            date: log.date,
            type: log.type,
            value: log.value,
            value2: log.value2
          })

          this.getDashboardData()
        }
      } catch (error) {
        this.$store.state.loading.screen = false
      }
    },

    logsByType(type) {
      return this.logs.filter(l => l.type == type)
    },

    defineGoalType(type) {
      switch (type) {
        case 'D':
          return 'Distance'
          break;

        case 'T':
          return 'Time'
          break;

        case 'E':
          return 'Elevation'
          break;
      
        default:
          return ''
          break;
      }
    },

    onShowGoal() {
      this.goalsPrevState = [...this.goalList.goals]
      this.$refs['modal-goal'].show()

      const body = document.querySelector('body')
      const html = document.querySelector('html')
      body.classList.add('modal-open')
      html.classList.add('modal-open')
    },

    uniqueGoalType(type, index = 0) {
      if (this.goalList.goals.findIndex(g => g.type == type) == -1) {
        return true
      }

      return this.goalList.goals.findIndex(g => g.type == type) == index
    },
    
    setGoalDetailValue(type, value, value2) {
      const current = this.getCurrentByType(type)

      if (type == 'T') {
        this.goalDetail.total = `${value}h ${value2}m`
        this.goalDetail.current = this.getSplitTime(current)
        this.goalDetail.remaining = this.remainingValue(
          this.getTimeMinutes(value, value2),
          current,
          type
        )

      } else if (type == 'D') {
        this.goalDetail.total = `${value} km`
        this.goalDetail.current = `${current} km`

        this.goalDetail.remaining = this.remainingValue(
          value,
          current,
          type
        )
      } else if (type == 'E') {
        this.goalDetail.total = `${value} m`
        this.goalDetail.current = `${current} m`

        this.goalDetail.remaining = this.remainingValue(
          value,
          current,
          type
        )
      }
    },

    onShowGoalDetail(v, k) {
      this.inputLogActivity.type = v.type
      this.inputLogActivity.month = this.goalList.month
      this.inputLogActivity.year = this.goalList.year

      this.goalDetail.index = k

      this.setGoalDetailValue(v.type, v.value, v.value2)

      this.$refs['goal-detail-modal'].show()

      const body = document.querySelector('body')
      const html = document.querySelector('html')
      body.classList.add('modal-open')
      html.classList.add('modal-open')
    },

    onCloseGoalDetail() {
      const body = document.querySelector('body')
      const html = document.querySelector('html')

      body.classList.remove('modal-open')
      html.classList.remove('modal-open')
    },

    goalByMonth(id) {
      return this.goals.filter(g => g.month == id)
    },

    setSelectedGoalType(val, k1, id) {
      if (val == 'T') {
        (this.goalList.goals[k1] || {}).value = '0'
        (this.goalList.goals[k1] || {}).value2 = '0'
        const el = document.getElementById(id)
        if (!this.isExtraSmallDevice) {
          el.classList.value = 'col_2'
        }
        return null
      } else if (val == 'D') {
        (this.goalList.goals[k1] || {}).value = '0.0'
        (this.goalList.goals[k1] || {}).value2 = null
      } else if (val == 'E') {
        (this.goalList.goals[k1] || {}).value = '0'
        (this.goalList.goals[k1] || {}).value2 = null
      }
      
      const el = document.getElementById(id)
      el.classList.value = 'col-4'
    },

    isCheckedGoal(value) {
      return this.goalList.find(g => g.value === value) !== undefined
    },

    onCloseModalGoal() {
      this.goalList.goals = this.goalsPrevState
      const body = document.querySelector('body')
      const html = document.querySelector('html')

      body.classList.remove('modal-open')
      html.classList.remove('modal-open')
    },

    marathonWaitingList(eventName) {
      const data = this.waitingList.map(w => w.marathons)

      return data.find(name => {
        return name == eventName
      }) !== undefined
    },

    async onNotifyUpcomingEvent(eventName) {
      try {
        const token = Gen.getCookie("userAuthRdg")

        this.waitingList.push({marathons: [eventName]})
        
        const resp = await Gen.apiRest('/waiting-list', { data: { 
          email: (this.user || {}).registrant_email_address,
          flname: (this.user || {}).registrant_full_name,
          phone: (this.user || {}).registrant_dial_code + (this.user || {}).registrant_phone,
          marathons: [eventName],
          other: '',
        }, headers: { ...Gen.apiHeaderAuth(token) } }, 'POST', 'json', true)

        if (resp.status == 201) {
          $refs['modal-success-notify'].show()
        }
      } catch (err) {
        return err
      }
    },

    async onRemoveNotify(eventName) {
      try {
        const token = Gen.getCookie("userAuthRdg")

        const index = (this.waitingList || []).findIndex(d => {
          return d.marathons[0] == eventName
        })

        if (index !== -1) {
          this.waitingList.splice(index, 1)
        }
        
        const resp = await Gen.apiRest('/waiting-list/remove', { data: { 
          email: (this.user || {}).registrant_email_address,
          flname: (this.user || {}).registrant_full_name,
          phone: (this.user || {}).registrant_dial_code + (this.user || {}).registrant_phone,
          marathons: [eventName],
          other: '',
        }, headers: { ...Gen.apiHeaderAuth(token) } }, 'POST', 'json', true)
      } catch (err) {
        return err
      }
    },

    stockBySlug(slug) {
      const data = (this.remainingStock || [])
        .filter(s => s.slug === slug)
        .map(s => s.stock);

      return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },

    uploaderPromo(filename) {
      if (!filename) {
        return null
      }

      return `${this.apiUrl}/uploader/${filename}`
    },

    ordinal(number) {
      let ordinalNumber = '';

      switch(number) {
        case 1:
          ordinalNumber = number + 'st';
          break;

        case 2:
          ordinalNumber = number + 'nd';
          break;

        case 3:
          ordinalNumber = number + 'rd';
          break;

        default:
          ordinalNumber = number + 'th';
          break;
      }

      return ordinalNumber;
    },

    startingPriceBySlug(slug) {
      const data = this.startingPrice
        .filter(p => p.slug === slug)
        .map(p => p.value)

      return Math.min(...data)
    },

    activityDetailHandler(slug) {
      if (this.isGrabbing) {
        return null
      }

      this.$router.push({ name: 'RDGMastery', params: { pageSlug: slug }, query: { isDone: 'n' } })
    },
    mouseDownHandler(e) {
      const ele = document.getElementById('wrap-activities')

      this.activityPos = {
        // The current scroll
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      }

      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.addEventListener('mouseup', this.mouseUpHandler)

      // Change the cursor and prevent user from selecting the text
      // ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none'
    },
    mouseMoveHandler(e) {
      this.isGrabbing = true

      const ele = document.getElementById('wrap-activities')

      // How far the mouse has been moved
      const dx = e.clientX - this.activityPos.x
      const dy = e.clientY - this.activityPos.y

      // Scroll the element
      ele.scrollTop = this.activityPos.top - dy
      ele.scrollLeft = this.activityPos.left - dx
    },
    mouseUpHandler(e) {
      setTimeout(() => {
        this.isGrabbing = false
      }, 300)

      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)

      const ele = document.getElementById('wrap-activities')

      // ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select')
    },
    toggleWishlist(e) {
      const selectedEl = e.target
      const changeEl = e.target.closest('.wrap-wish-cta').querySelector('.d-none')

      selectedEl.classList.add('d-none')
      changeEl.classList.remove('d-none')
    },
    showModalWelcome() {
      if (this.$route.query.redirectFrom == 'register') {
        const that = this
        window.setTimeout(() => {
          that.$refs['modal-welcome'].show()
        }, 300)
      }
    },

    closeModalWelcome() {
      this.$router.push({ name: 'Dashboard' })
      this.$refs['modal-welcome'].hide()
    },

    addGoalItem(k1) {
      const selected = this.goalList.goals.map(g => g.type)

      if (selected.indexOf('D') == -1) {
        this.$store.commit('dashboard/ADD_GOAL_ITEM', {
          value: '0',
          value2: '0',
          type: 'D',
        })
      } else if (selected.indexOf('T') == -1) {
        this.$store.commit('dashboard/ADD_GOAL_ITEM', {
          value: '0',
          value2: '0',
          type: 'T',
        })
        
        this.$nextTick(() => {
          const el = document.getElementById('wrap_goal_type_'+(k1+1))

          if (el !== null && !this.isExtraSmallDevice) {
            el.classList.value = 'col_2'
          }
        })
      } else if (selected.indexOf('E') == -1) {
        this.$store.commit('dashboard/ADD_GOAL_ITEM', {
          value: '0',
          value2: '0',
          type: 'E',
        })
      }
    },

    removeGoalItem(indexGoalItem) {
      this.$store.commit('dashboard/REMOVE_GOAL_ITEM', indexGoalItem)
      if ((this.goalList.goals || []).length === 0) {
        this.addGoalItem(0)
      }
    },

    async submitGoal() {
      const goalList = {...this.goalList}
      const goals = goalList.goals.filter(g => {
        if (g.type == 'T') {
          if (['0', '', null].indexOf(g.value) > -1 && ['0', '', null].indexOf(g.value2) > -1) {
            return false
          }

          return true
        }

        return ['0', '', null].indexOf(g.value) == -1
      })
      
      if (goals.length === 0) {
        return null
      }

      this.$store.state.loading.screen = true

      goalList.goals = goals

      const token = Gen.getCookie("userAuthRdg")

      try {
        const resp = await Gen.apiRest('/private-pages/goals', { 
          data: { ...goalList },
          headers: Gen.apiHeaderAuth(token)
        }, 'POST')
  
        if (resp.status == 201) {
          this.$refs['modal-success-savegoals'].show()
          this.$refs['modal-goal'].hide()
          this.onCloseModalGoal()
  
          document.getElementById('wrap_goal_type_0').classList.value = 'col-4'
  
          this.getDashboardData()
        }
      } catch (error) {
        this.$store.state.loading.screen = false
      }
    },
  },

  computed: {
    ...mapFields(['user', 'baseUrl']),
    ...mapFields('home', ['homeContent']),
    ...mapFields('dashboard', [
      'recentToBeReviewed',
      'targetedBlog',
      'recentOrders',
      'recentOrdersHistorical',
      'unfinishedOrder',
      'unfinishedOrderHistorical',
      'ongoingEvents',
      'startingPrice',
      'remainingStock',
      'waitingList',
      'upcomingEvents',
      'promo',
      'registrant',
      'goalList',
      'goals',
      'logs',
      'myInformation',
      'dashboardContent',
    ])
  },
  created() {
    this.getDashboardData()

    this.check_webp_feature('lossless', (feature, result) => {
      this.isWebPSupport = result
    })

    this.$store.commit('SET_STATIC_SEO', {
      title: 'Dashboard'
    })
  },
  mounted() {
    this.isMounted = true
  }
}
</script>

<style lang="scss" scoped>
.month-mobile {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  box-shadow: -1px 19px 66px -44px rgba(255, 255, 255, 0.75);
  &::-webkit-scrollbar {
    width: 25%;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin-left: 135px;
    margin-right: 135px;
    @media only screen and (min-width: 768px) {
      margin-left: 0;
      margin-right: 600px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #f7af2f !important;
  }
  .item-month {
    text-align: center;
    span {
      width: 50px;
      @media only screen and (min-width: 768px) {
        width: 65px;
      }
    }
    &.active {
      .value {
        position: relative;
        margin-top: 5px;
        &::before {
          position: absolute;
          box-shadow: -1px -1px 55px -8px rgba(247, 175, 47, 0.75) !important;
          -webkit-box-shadow: -1px -1px 55px -8px rgba(247, 175, 47, 0.75);
          -moz-box-shadow: -1px -1px 55px -8px rgba(247, 175, 47, 0.75);
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          width: 20px;
          height: 20px;
          background: #f7af2f;
          border-radius: 50%;
          z-index: -1;
        }
      }
    }
  }
}

.bg-checklist {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  .icon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: #f7af2f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.goal_input {
  padding-right: 36px !important;
}

.goal_select, .goal_input:not(.group-item) {
  border-radius: 12px !important;
}

.goal_input_helper {
  position: absolute;
  right: 14px;
  top: calc(50% - 12px);
}

.col_3 {
  flex: 0 0 auto;
  width: 29.333333%;
}

.col_2 {
  flex: 0 0 auto;
  width: 26.333333%;
}
</style>

import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    aboutData: {
      hero: {
        title: '',
      },
      content: {
        section_one: {
          title: '',
        },
        section_two: {
          title: '',
        },
        mission: {
          title: '',
        },
        values: {
          title: '',
          descriptions: [],
        },
      },
      our_team: {
        title: '',
        sub_title: '',
      },
      our_team_member: [],
    },
    breadCrumbAbout: [
      {
        text: 'Home',
        to: { name: 'Home' },
      },
      {
        text: 'About Us',
        active: true,
      },
    ],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_ABOUT_DATA(state, data) {
      state.aboutData = data
    },
  },

  actions: {
    async getAboutData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const res = await http.get(`/public-pages/about`, {
          headers: Gen.apiHeader(),
        })

        commit('SET_ABOUT_DATA', res.data)
        rootState.staticseo = res.data.staticseo
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}


<template>
  <div class="home">
    <template>
      <CompletingData 
        :verifying-email="isVerifyingEmail"
        :changing-email="isChangingEmail"
        :verifying-email-success="isVerifyingEmailSuccess"
        :verifying-phone-input="isVerifyingPhoneInput"
        :verifying-phone-methods="isVerifyingPhoneMethods"
        :verifying-phone-code="isVerifyingPhoneCode"
        :verifying-phone-success="isVerifyingPhoneSuccess"
        :filling-citizenship="isFillingCitizenship"
        :filling-gender="isFillingGender"
        :filling-birthday="isFillingBirthday"
        :filling-age="isFillingAge"
        :under-age="isUnderAge"
        :filling-avatar="isFillingAvatar"
        :filling-basic-success="isFillingBasicSuccess"
        :v-format-date="formatDate"
        @verify-email="v => onVerifyEmail(v)"
        @verify-change-email="onVerifyChangeEmail"
        @verify-change-phone="onVerifyChangePhone"
        @email-verified="onEmailVerified"
        @send-new-code-email="onSendNewCodeEmail"
        @send-new-code-phone="onSendNewCodePhone"
        @send-phone-verification-code="v => onSendPhoneVerificationCode(v)"
        @cancel-verify-change-email="onConfirmCancelVerifyChangeEmail"
        @verify-phone="v => onVerifyPhone(v)"
        @phone-verified="onPhoneVerified"
        @citizenship-filled="v => onCitizenshipFilled(v)"
        @gender-filled="v => onGenderFilled(v)"
        @birthday-filled="v => onBirthdayFilled(v)"
        @age-filled="onAgeFilled"
        @back-birthday="onBackBirthday"
        @under-age-redirect="handleUnderAgeRedirect"
        @avatar-filled="v => onAvatarFilled(v)"
        @avatar-skipped="onAvatarSkipped"
        @survey-completed="onSurveyCompleted"
        :outFormData="formData"
        @confirm-input-number="v => onConfirmInputNumber(v)"
        :email-alert-danger="showDangerAlertEmail"
        :email-alert-success="showSuccessAlertEmail"
        :is-show-alert-email="isShowingAlertEmail"
        :phone-alert-danger="showDangerAlertPhone"
        :phone-alert-success="showSuccessAlertPhone"
        :is-show-alert-phone="isShowingAlertPhone"
        :err-message-text="errMessage"
        @register="changeEmail => doRegister(changeEmail)"
      />
    </template>

    <template v-if="!completingProcess()">
      <div class="body-inner">
        <section class="authentication-section" :class="isTablet() ? 'h-auto' : ''">
          <div class="row no-gutters">
            <div :class="isMobile() ? 'col-lg-12 order-5' : 'col-lg-7'">
              <div
                class="left-section"
                :class="!isMobile() ? 'h-100 p-100' : isTablet() ? 'd-block h-auto' : 'mt-5 pt-0 px-4 h-auto'"
              >
                <h4 class="extrabold" :class="isOnlyMobile() ? 'text-center' : ''">Sign Up</h4>
                <div class="mt-5 mb-4">
                  <ValidationObserver ref="RegisterForm" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onRegister)">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="firstname" class="required bold">First &amp; Middle Name</label>
                            <input
                              id="firstname"
                              type="text"
                              class="form-control main-control"
                              placeholder="e.g. Abraham Damar"
                              v-model="formData.first_name"
                            />
                            <VValidate
                              name="First &amp; Middle Name"
                              v-model="formData.first_name"
                              :rules="{ required: true, min: 3, max: 96, regex: /^([A-Za-z' ]+)$/ }"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname" class="required bold">Last Name</label>
                            <template v-if="formData.is_last_name == 'Y'">
                              <input
                                id="lastname"
                                type="text"
                                class="form-control main-control"
                                placeholder="e.g. Grahita"
                                v-model="formData.last_name"
                              />
                              <VValidate
                                name="Last Name"
                                v-model="formData.last_name"
                                :rules="{ required: true, min: 3, max: 96, regex: /^([A-Za-z' ]+)$/ }"
                              />
                            </template>
                            <template v-else>
                              <input
                                type="text"
                                class="form-control main-control"
                                placeholder="e.g. Grahita"
                                :value="formData.first_name"
                                disabled
                              />
                            </template>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <b-form-checkbox
                              tabindex="3"
                              id="is_lastname"
                              v-model="formData.is_last_name"
                              name="is_lastname"
                              value="N"
                              unchecked-value="Y"
                              @click.native="checkIsLastName(formData.is_last_name)"
                            >
                              <span class="normal">This person doesn't have family name / last name</span>
                            </b-form-checkbox>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="email" class="required bold">Email Address</label>
                            <b-badge
                              variant="info"
                              class="help-badge custom-badge-info ml-3"
                              v-b-tooltip.hover.right="'We need an email so we can contact you'"
                              id="email-tooltip"
                              pill
                              @click.prevent
                            >
                              <b-icon icon="question" class="question-icon" font-scale="1"></b-icon>
                            </b-badge>
                            <input
                              id="email"
                              type="email"
                              class="form-control main-control"
                              placeholder="e.g. abraham.damar@example.com"
                              v-model="formData.email"
                              autocomplete="username"
                            />
                            <VValidate name="Email Address" v-model="formData.email" rules="required|email" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="password" class="required bold">Password</label>
                            <b-input-group class="position-relative">
                              <input
                                id="password"
                                type="password"
                                class="form-control main-control"
                                placeholder="Password"
                                v-model="formData.password"
                                autocomplete="new-password"
                              />
                              <template #append>
                                <b-icon
                                  v-if="!showPassword"
                                  icon="eye-slash"
                                  font-scale="1"
                                  class="position-absolute view-password"
                                  @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                ></b-icon>
                                <b-icon
                                  v-else
                                  icon="eye"
                                  font-scale="1"
                                  class="position-absolute view-password main-color"
                                  @click="toggleShowPassword(showPassword, 'showPassword', 'password')"
                                ></b-icon>
                              </template>
                            </b-input-group>
                            <VValidate name="Password" v-model="formData.password" rules="required|min:8" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="confirmPassword" class="required bold">Confirm Password</label>
                            <b-input-group>
                              <input
                                id="confirmPassword"
                                type="password"
                                class="form-control main-control"
                                placeholder="Re-type password"
                                v-model="formData.confirmPassword"
                                autocomplete="new-password"
                              />
                              <template #append>
                                <b-icon
                                  v-if="!showConfirmPassword"
                                  icon="eye-slash"
                                  font-scale="1"
                                  class="position-absolute view-password"
                                  @click="
                                    toggleShowPassword(showConfirmPassword, 'showConfirmPassword', 'confirmPassword')
                                  "
                                ></b-icon>
                                <b-icon
                                  v-else
                                  icon="eye"
                                  font-scale="1"
                                  class="position-absolute view-password main-color"
                                  @click="
                                    toggleShowPassword(showConfirmPassword, 'showConfirmPassword', 'confirmPassword')
                                  "
                                ></b-icon>
                              </template>
                            </b-input-group>
                            <VValidate
                              name="Confirm Password"
                              v-model="formData.confirmPassword"
                              rules="required|confirmed:Password"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div
                            class="d-flex justify-content-between align-items-center"
                            :class="isMobile() ? 'flex-column' : ''"
                          >
                            <button
                              type="submit"
                              class="btn btn-slg btn-primary bold"
                              :class="isMobile() ? 'w-100 mb-4' : ''"
                            >
                              Continue
                            </button>
                            <span class="medium grey-color d-flex" :class="isTablet() ? 'align-self-start' : ''"
                              >Already a member?
                              <router-link :to="{ name: 'Login' }" class="bold text-link d-flex align-items-center ml-1"
                                >Sign In</router-link
                              ></span
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
                <div>
                  <div class="mt-4" :class="isOnlyMobile() ? 'text-center w-100' : ''">
                    <span @click="showLegalModal" class="grey-color"
                      >By registering, I agree to the
                      <a href="javascript:;" @click="showLegalModal" class="text-underline grey-color bold"
                        >Terms & Conditions</a
                      >
                      and
                      <a href="javascript:;" @click="showLegalModal" class="text-underline grey-color bold"
                        >Privacy Policy</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div :class="isTablet() ? 'col-lg-12 register-hero' : 'col-lg-5'">
              <div
                class="right-section h-100"
                :style="`
                  background-image: url('${uploader('hero/signup-hero.jpg')}');
                  background-position: center;
                  background-size: cover;
                `"
                :class="isMobile() ? '' : 'h-100'"
              >
                <div class="bg-overlay" data-style="13"></div>
                <div>
                  <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                    <div>
                      <router-link :to="{ name: 'Home' }">
                        <picture>
                          <source
                            media="(min-width: 992px)"
                            :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                            type="image/webp"
                          />
                          <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                          <img
                            class="brand-logo"
                            v-if="isOnlyMobile()"
                            :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                            alt="RunDeGlobe Logo"
                            width="142"
                            height="43"
                          />
                          <img
                            class="brand-logo"
                            v-else
                            :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                            alt="RunDeGlobe Logo"
                            width="175"
                            height="53"
                          />
                        </picture>
                      </router-link>
                    </div>
                    <div :class="isTablet() ? 'mt-5' : ''">
                      <div class="title-content">
                        <h2 class="text-white">Get Started,</h2>
                        <p style="color: #f7af2f">Create Your Personal Best</p>
                      </div>
                      <span class="text-white bold" :class="isTablet() ? 'd-block mt-2' : ''"
                        >Fill in the form to create your RunDeGlobe account and get some privileges in RunDeGlobe:</span
                      >
                      <div class="mt-4">
                        <div class="extras-check mb-2">
                          <div>
                            <i class="fa fa-check text-white fs-125em"></i>
                          </div>
                          <div>
                            <span class="text-white extrabold">Dashboard</span>
                          </div>
                        </div>
                        <div class="extras-check mb-2">
                          <div>
                            <i class="fa fa-check text-white fs-125em"></i>
                          </div>
                          <div>
                            <span class="text-white extrabold">Event Registration</span>
                          </div>
                        </div>
                        <div class="extras-check mb-2">
                          <div>
                            <i class="fa fa-check text-white fs-125em"></i>
                          </div>
                          <div>
                            <span class="text-white extrabold">Keep Your Transaction History</span>
                          </div>
                        </div>

                        <div class="extras-check mb-2">
                          <div>
                            <i class="fa fa-check text-white fs-125em"></i>
                          </div>
                          <div>
                            <span class="text-white extrabold">My Documents</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="isTablet() ? 'mt-5' : ''">
                      <router-link :to="{ name: 'Home' }" class="text-white text-underline bold"
                        >Back to Home</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="menu-toggle" @click="openMenuToggle">
        <span class="fa fa-comments"></span>
      </div>
      <div class="menu-round">
        <div class="btn-app">
          <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
        </div>
        <div class="btn-app">
          <div class="fa fa-envelope-square" @click="$router.push({ name: 'ContactUs' })"></div>
        </div>
      </div>

      <AppModal :noHeader="true" :isCustomContent="true" :type="'lg'" ref="legal-modal">
        <template #content-modal>
          <b-tabs
            class="legal-tabs"
            :fill="true"
            ref="LegalTabs"
            v-model="legalStep"
            v-on:activate-tab="handleActivatedTermsTab"
          >
            <b-tab :title="privacy.title" ref="PrivacyTab">
              <div
                class="mt-3 light legal scrolling-content"
                v-html="privacy.description"
                @scroll="(e) => onContentScrolled(e)"
              ></div>
            </b-tab>
            <b-tab :title="terms.title" ref="TermsTab">
              <div
                class="mt-3 light legal scrolling-content"
                v-html="terms.description"
                @scroll="(e) => onContentScrolled(e)"
              ></div>
            </b-tab>
          </b-tabs>
          <b-progress :value="scrollProgress" height="5px"></b-progress>
          <div class="d-block">
            <div class="d-flex justify-content-between px-4 py-3 bottom-progress">
              <div class="progress-text">
                <span class="d-block">Learn</span>
                <span>{{ scrollProgress }}%</span>
              </div>
              <button
                v-if="isLocked"
                type="disabled"
                class="btn btn-slg btn-primary bold"
                :class="!isMobile() ? '' : isTablet() ? '' : 'mb-4'"
              >
                I Agree
              </button>
              <button
                v-else
                class="btn btn-slg btn-primary bold"
                :class="!isMobile() ? '' : isTablet() ? '' : 'mb-4'"
                @click="legalNextStep"
              >
                I Agree
              </button>
            </div>
          </div>
        </template>
      </AppModal>

      <AppModal :withPageTitle="false" ref="confirm-email-modal" :type="'n'">
        <template #content-modal>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column align-items-center text-center">
                <img :src="`${baseUrl}/assets/images/vector/confirm-vect.svg`" alt="Data Confirmation" class="w-25" />
                <p class="regular text-dark fs-125em bold mb-1 ls-0 mt-4 lh-125">Data Confirmation</p>
                <span class="regular grey-color fs-1em">Please make sure the data below is correct</span>
                <ul>
                  <li>Email address: {{ formData.email }}</li>
                </ul>
                <p class="regular">
                  A verification code will be sent to your email, make sure the inbox is not full and you can receive the
                  code
                </p>
                <div class="d-flex">
                  <button type="button" class="btn btn-outline-main ml-0 bold" @click="onChangeEmail">Change email</button>
                  <button type="button" class="btn btn-primary bold" @click.prevent="doRegister()">Confirm data</button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AppModal>
    </template>


    <AppAlert
      ref="modal-error"
      vref="modal-error"
      type="success"
      :source="{
        fallbackSrc: `${baseUrl}/assets/images/static-icon/warning-alert.png`,
      }"
      icon-size="w-50"
      :title="messageErrTitle"
      :description="messageErrDescription"
      :button="{
        main: {
          text: 'Ok',
        },
      }"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthSharedVariables from '@/libs/AuthSharedVariables.vue'
import CompletingData from '@/views/atoms/register/CompletingData.vue'

export default {
  extends: AuthSharedVariables,
  components: {
    CompletingData,
  },
  name: 'Register',
  created() {
    this.getChannels()
    this.getRegisterContent()
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.isUnderAge) {
      if (confirm('Are you sure you want to leave? Your changes may not be saved.')) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>

<style>
body {
  min-height: -webkit-fit-content !important;
}

#vs1__combobox {
  min-width: 120px;
}

.vs--single.vs--loading .vs__selected,
.vs--single.vs--open .vs__selected {
  position: static !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border-bottom-1px {
  border-bottom: 1px solid #d9d9d9;
}

#citizenshipfill {
  min-width: 150px;
}
</style>

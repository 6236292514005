<template>
  <div class="home">
    <div class="body-inner">
      <section class="authentication-section" :class="isTablet() ? 'h-auto' : ''">
        <div class="row no-gutters">
          <div :class="isMobile() ? 'order-5 col-sm-12 forgot-password-form' : 'col-lg-7'">
            <div
              class="left-section h-auto"
              :class="!isMobile() ? 'h-100 p-100' : isTablet() ? 'd-block h-auto' : 'mt-5 pt-0 px-4 h-auto'"
            >
              <h4 class="extrabold">Forgot Password</h4>
              <div class="mt-5 mb-4">
                <ValidationObserver ref="ForgotPasswordForm" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(doForgotPassword)">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="" class="required bold">Email Address</label>
                          <input
                            type="email"
                            class="form-control main-control"
                            placeholder="e.g. abraham.damar@example.com"
                            v-model="formData.email"
                          />
                          <VValidate name="Email Address" v-model="formData.email" rules="required|email" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="d-flex justify-content-between align-items-center"
                          :class="isMobile() ? 'flex-column mb-5' : ''"
                        >
                          <button
                            class="btn btn-slg btn-primary bold"
                            :class="isMobile() ? 'w-100 mb-4' : ''"
                          >
                            Send Instructions
                          </button>
                          <span class="medium grey-color d-flex"
                            ><router-link :to="{ name: 'Login' }" class="bold text-link d-flex align-items-center ml-1"
                              >I've remembered my password</router-link
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <div :class="isMobile() ? 'order-1 col-sm-12 forgot-password-hero' : 'col-lg-5'">
            <div
              class="right-section h-100 mh-100"
              :style="`
                background-image: url('${uploader('hero/forgotpassword-hero.jpg')}');
                background-position: center;
                background-size: cover;
              `"
              :class="isMobile() ? '' : 'h-100'"
            >
              <div class="bg-overlay" data-style="13"></div>
              <div>
                <div class="content" :class="isTablet() ? 'd-block h-auto mb-0' : ''">
                  <div>
                    <router-link :to="{ name: 'Home' }">
                      <picture>
                        <source
                          media="(min-width: 992px)"
                          :srcset="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.webp`"
                          type="image/webp"
                        />
                        <source :srcset="`${baseUrl}/assets/images/rundeglobe_logo_white.webp`" type="image/webp" />
                        <img
                          class="brand-logo"
                          v-if="isOnlyMobile()"
                          :src="`${baseUrl}/assets/images/rundeglobe_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="142"
                          height="43"
                        />
                        <img
                          class="brand-logo"
                          v-else
                          :src="`${baseUrl}/assets/images/rundeglobe@2x_logo_white.png`"
                          alt="RunDeGlobe Logo"
                          width="175"
                          height="53"
                        />
                      </picture>
                    </router-link>
                  </div>
                  <div :class="isTablet() ? 'mt-5' : ''">
                    <div class="title-content">
                      <h2 class="text-white">Recover</h2>
                      <p style="color: #f7af2f">Your Password</p>
                    </div>
                    <span class="text-white bold"
                      >Enter your registered email, We will send you an email with instructions on how to reset your
                      password.</span
                    >
                  </div>
                  <div :class="isTablet() ? 'mt-5' : ''">
                    <router-link :to="{ name: 'Home' }" class="text-white text-underline bold"
                      >Back to Home</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <AppModal :withPageTitle="false" ref="modal-email-sent" :type="'sm'" @close="$router.push({name: 'Login'}).catch(() => {})">
      <template #content-modal>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column align-items-center text-center">
              <img :src="`${baseUrl}/assets/images/vector/main-sent.svg`" alt="Sent" class="w-50" />
              <p class="regular text-dark fs-125em bold ls-0 mt-4 lh-125">Password reset email sent!</p>
              <span class="regular fs-1em"
                >We've sent a password reset email to the email address registered. If you don't receive the email,
                please check your spam folder or reach out to our support team for assistance.</span
              >
              <button
                @click="closeModalSentEmail"
                class="btn btn-primary semibold d-flex text-center justify-content-center w-50 mt-3 align-items-center"
                :class="isOnlyMobile() ? 'w-100 justify-content-center' : ''"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </template>
    </AppModal>

    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppModal from '@/components/AppModal'
import Global from '../libs/Global.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

import Gen from '@/libs/Gen'

export default {
  extends: Global,
  name: 'ForgotPassword',
  components: {
    AppModal,
  },
  data() {
    return {
      formData: {
        email: '',
      }
    }
  },
  computed: {
    ...mapFields(['channels']),
  },
  created() {
    this.getChannels()
  },
  methods: {
    ...mapActions(['getChannels']),
    async doForgotPassword() {
      try {
        this.$store.state.loading.screen = true
        const resp = await Gen.apiRest(
          '/forgot-password', 
          {data: Object.assign({}, this.formData)}, 
          "POST"
        )

        if (resp.data.message) {
          this.$store.state.loading.screen = false
          this.$refs['modal-email-sent'].show()
        }
      } catch (error) {
        this.$store.state.loading.screen = false
        return error
      }
    },

    closeModalSentEmail() {
      this.$refs['modal-email-sent'].hide()
      this.$router.push({name: 'Login'}).catch(() => {})
    },

    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style scoped>
.forgot-password-hero {
  max-height: 50vh;
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation: landscape) {
  .forgot-password-hero {
    max-height: 87vh;
  }
}
</style>

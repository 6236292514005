<template>
  <div class="b-grey-black px-4 py-4 bdrd-12px">
    <div class="d-flex mb-3">
      <i class="icon-help-circle"></i>
      <h5 class="bold ml-2">Help Center</h5>
    </div>
    <div class="wrap-help-nav mb-3" v-if="(helpCenterContent[0] || {}).is_active == 'Y'">
      <router-link :to="{ name: 'FAQ' }" class="text-dark-custom">
        <div class="d-flex justify-content-between align-items-center">
          <div class="wrap-text pr-4">
            <span class="bold d-block">{{ (helpCenterContent[0] || {}).title }}</span>
            <p class="d-block">
              {{ (helpCenterContent[0] || {}).description }}
            </p>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em"></i>
          </div>
        </div>
      </router-link>
    </div>

    <div class="wrap-help-nav mb-3" v-if="(helpCenterContent[1] || {}).is_active == 'Y'">
      <a :href="(helpCenterContent[1] || {}).url" target="_blank" class="text-dark-custom">
        <div class="d-flex justify-content-between align-items-center">
          <div class="wrap-text pr-4">
            <span class="bold d-block">{{ (helpCenterContent[1] || {}).title }}</span>
            <p class="d-block">{{ (helpCenterContent[1] || {}).description }}</p>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em"></i>
          </div>
        </div>
      </a>
    </div>

    <div class="wrap-help-nav mb-3" v-if="(helpCenterContent[2] || {}).is_active == 'Y'">
      <router-link :to="{ name: 'ContactUs' }" class="text-dark-custom">
        <div class="d-flex justify-content-between align-items-center">
          <div class="wrap-text pr-4">
            <span class="bold d-block">{{ (helpCenterContent[2] || {}).title }}</span>
            <p class="d-block">
              {{ (helpCenterContent[2] || {}).description }}
            </p>
          </div>
          <div>
            <i class="icon-chevron-right fs-125em"></i>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

export default{
  name: "PPHelpCenter",
  computed: {
    ...mapFields("usePrivatePageStore", [
      "helpCenterContent"
    ])
  },
  created(){
    this.getHelpCenterContent()
  },
  methods: {
    ...mapActions('usePrivatePageStore', [
      'getHelpCenterContent'
    ])
  }
}

</script>
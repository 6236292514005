import { getField, updateField } from 'vuex-map-fields'
import Gen from '@/libs/Gen'
import { http } from '../../config/http'

export default {
  namespaced: true,

  state: {
    recentToBeReviewed: {},
    targetedBlog: [],
    recentOrders: [],
    recentOrdersHistorical: [],
    unfinishedOrder: {},
    unfinishedOrderHistorical: {},
    ongoingEvents: [],
    startingPrice: [],
    remainingStock: [],
    waitingList: [],
    upcomingEvents: [],
    promo: [],
    goals: [],
    logs: [],
    registrant: {
      is_experienced: '',
      completion_state: '',
    },
    goalList: {
      goals: [],
    },
    dashboard_unread_updates: 0,
    myInformation: {
      profile: 0,
      mailing: 0,
      network: 0,
      medical: 0,
    },
    dashboardContent: {
      nextRun: {},
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    SET_DASHBOARD_DATA(state, data) {
      state.myInformation = data.myInformation
      state.dashboardContent = data.dashboardContent
      state.recentToBeReviewed = data.toBeReviewedRace
      state.targetedBlog = data.targetedBlog
      state.recentOrders = data.recentOrders
      state.recentOrdersHistorical = data.recentOrdersHistorical
      state.ongoingEvents = data.ongoingEvents
      state.startingPrice = data.startingPrice
      state.remainingStock = data.remainingStock
      state.waitingList = data.waitingList
      state.registrant.is_experienced = data.registrant.is_experienced
      state.registrant.completion_state = data.registrant.completion_state
      state.upcomingEvents = data.upcomingEvents
      state.goalList = data.goalList
      state.promo = data.promo
      state.goals = data.goals
      state.logs = data.logs
      state.unfinishedOrder = data.unfinishedOrder
      state.unfinishedOrderHistorical = data.unfinishedOrderHistorical
    },
    ADD_LOG_ITEM(state, data) {
      (state.logs || []).push(data)
    },
    UPDATE_LOG_ITEM(state, data) {
      const index = state.logs.findIndex(l => l.id === data.id)

      if (index !== -1) {
        state.logs[index] = data
      }
    },
    DELETE_LOG_ITEM(state, id) {
      const index = state.logs.findIndex(l => l.id === id)
      
      if (index !== -1) {
        state.logs.splice(index, 1)
      }
    },
    ADD_GOAL_ITEM(state, data) {
      (state.goalList.goals || []).push(data)
    },
    REMOVE_GOAL_ITEM(state, index) {
      (state.goalList.goals || []).splice(index, 1)
    },
  },

  actions: {
    async getDashboardData({ commit, rootState }) {
      try {
        rootState.loading.screen = true

        const token = Gen.getCookie("userAuthRdg")

        const res = await http.get(`/private-pages/dashboard`, {
          headers: Gen.apiHeaderAuth(token),
        })

        commit('SET_DASHBOARD_DATA', res.data)
      } catch (err) {
        return err
      } finally {
        rootState.loading.screen = false
      }
    },
  },
}

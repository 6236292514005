<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6" :class="isMobile ? 'text-center' : ''">
          <div :class="isOnlyMobile ? 'text-center d-flex flex-column align-items-center' : ''">
            <span class="sub-title-section" :class="isMobile ? 'justify-content-center' : ''">
              <div class="line-sub-title"></div>
              {{ content.sub_title }}
            </span>
            <h2 class="title-section-left mt-3">{{ content.title }}</h2>
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-end align-items-end" v-if="!isMobile">
          <div class="mt-4">
            <a :href="$router.resolve({name: 'Blog'}).href" class="btn btn-outline black">{{ content.button }}</a>
          </div>
        </div>
        <div class="col-md-12">
          <div class="blog-home mt-5">
            <carousel v-if="data.length > 0" :items="isMobile ? 1 : 2" :nav="false" :stagePadding="0" :margin="20" autoplay>
              <template v-for="(v, k) in data">
                <a
                  :key="'blog-'+k"
                  :href="
                    $router.resolve({
                      name: 'BlogDetail',
                      params: {
                        pageSlug: v.slug,
                      },
                    }).href
                  "
                >
                  <div class="article-home">
                    <div class="top">
                      <div class="left">
                        <picture>
                          <source
                            :srcset="uploader(v.image)"
                            type="image/webp"
                          />
                          <img
                            :src="uploader(v.fallback_image)"
                            :alt="v.alt_image"
                            width="196"
                            height="175"
                          />
                        </picture>
                      </div>
                      <div class="right pr-2 d-flex align-items-center">
                        <div>
                          <span class="grey-color medium fs-085em">{{ formatDate(v.published_date) }}</span>
                          <p class="fs-125em bold text-dark">
                            {{ v.title }}
                          </p>
                          <span class="grey-color medium fs-085em"
                            >By
                            <a
                              @click.stop
                              :href="
                                $router.resolve({
                                  name: 'BlogAuthor',
                                  params: {
                                    pageSlug: v.author_slug,
                                  },
                                }).href
                              "
                              >{{ v.author_name }}</a
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="p-3">
                      <span class="grey-color regular"
                        >{{ v.excerpt }}</span
                      >
                    </div>
                  </div>
                </a>
              </template>

              <template slot="next" v-if="data.length > 0">
                <div class="arrow-carousel-component right pointer">
                  <i class="icon-arrow-right"></i>
                </div>
              </template>
              <template slot="prev" v-if="data.length > 0">
                <div class="arrow-carousel-component left pointer">
                  <i class="icon-arrow-left"></i>
                </div>
              </template>
            </carousel>
          </div>
        </div>
        <div class="col-md-6 text-center" v-if="isMobile">
          <a :href="$router.resolve({name: 'Blog'}).href" class="btn btn-outline black mt-4">{{ content.button }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'BlogAtom',
  components: {
    carousel,
  },
  props: {
    isMobile: {
      type: Boolean,
    },
    isOnlyMobile: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
    },
    content: {
      type: Object,
    },
    data: {
      type: Array,
    },
    uploader: {
      type: Function,
    },
    formatDate: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
.blog-home {
  position: relative;
  .arrow-carousel-component {
    &.left {
      left: -20px;
    }
    &.right {
      right: -20px;
    }
  }
}

.article-home {
  position: relative;
  border-radius: 24px;
  min-height: 275px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .top {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .right {
      width: 500px;
    }
    .left {
      position: relative;
      // width: 100%;
      width: 300px !important;
      height: 175px;
      background: #fff;
      border-top-left-radius: 24px;
      margin-right: 1.5rem !important;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 24px;
      }
    }
  }
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <Header :isOpenMenuProps="true" />
        <section
          id="page-title"
          :style="`background: url('${uploader(((WWUHotelData || {}).hero || {}).image)}');`"
          class="background-hero-img"
        >
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="page-title d-flex flex-column align-items-center" :class="isOnlyMobile() ? 'py-5' : ''">
              <div class="label-custom green">
                <span class="black">{{ ((WWUHotelData || {}).hero || {}).sub_title }}</span>
              </div>
              <h2 class="black text-medium text-white mt-3" :class="!isMobile() ? 'w-75' : ''">{{ ((WWUHotelData || {}).hero || {}).title }}</h2>
              <span class="semibold text-white" :class="!isMobile() ? 'w-50' : ''"
                >{{ ((WWUHotelData || {}).hero || {}).description }}</span
              >
            </div>
          </div>
        </section>
      </div>

      <template v-if="!isMobile()">
        <div class="bg-grey py-4 d-flex align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </template>

      <section class="content-section paragraph pt-3" v-if="!isMobile()">
        <div class="container">
          <div class="row mt-4 align-items-center">
            <div class="col-md-6" :class="!isMobile() ? 'px-5 py-5' : 'pt-4 pb-4 order-5'">
              <div class="wrap_content left_paragraph">
                <h3 class="bold">{{ ((WWUHotelData || {}).content || {}).title_section }}</h3>
                <p class="mt-4">
                  {{ ((WWUHotelData || {}).content || {}).description_section }}
                </p>
                <button class="btn btn-slg btn-primary bold" @click="$router.push({ name: 'WWUHotels' })" v-if="((WWUHotelData || {}).content || {}).is_active == 'Y'">
                  {{ ((WWUHotelData || {}).content || {}).text_cta }}
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="wrap_img bdrd-24px overflow-hidden">
                <picture>
                  <source
                    :src="uploader(((WWUHotelData || {}).content || {}).img_section)"
                    type="image/webp"
                  />
                  <img
                    :src="uploader(((WWUHotelData || {}).content || {}).fallback_img_section)"
                    :alt="((WWUHotelData || {}).content || {}).alt_img_section"
                    width="600"
                    height="400"
                    class="w-100"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>

      <template v-else>
        <div class="mobile-version-view height-auto py-5">
          <div class="container">
            <div class="row mt-0 align-items-center" :class="isOnlyMobile() ? 'text-center' : ''">
              <div class="col-md-6" :class="!isMobile() ? 'px-5 py-5' : isTablet() ? 'pt-4 pb-4' : 'pt-4 pb-4 order-5'">
                <div class="wrap_content left_paragraph">
                  <h3 class="bold">{{ ((WWUHotelData || {}).content || {}).title_section }}</h3>
                  <p class="mt-4">
                    {{ ((WWUHotelData || {}).content || {}).description_section }}
                  </p>
                  <button class="btn btn-slg btn-primary bold" @click="$router.push({ name: 'WWUHotels' })" v-if="((WWUHotelData || {}).content || {}).is_active == 'Y'">
                    {{ ((WWUHotelData || {}).content || {}).text_cta }}
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="wrap_img bdrd-24px overflow-hidden">
                  <picture>
                    <source
                      :src="uploader(((WWUHotelData || {}).content || {}).img_section)"
                      type="image/webp"
                    />
                    <img
                      :src="uploader(((WWUHotelData || {}).content || {}).fallback_img_section)"
                      :alt="((WWUHotelData || {}).content || {}).alt_img_section"
                      width="600"
                      height="400"
                      class="w-100"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://wa.me/${(WWUHotelData || {}).whatsapp_contact}`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <div class="fa fa-envelope-square" @click="$router.push({ name: 'ContactUs' })"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Global from '../libs/Global.vue'
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Header from '../components/Header.vue'

export default {
  extends: Global,
  name: 'WWULPHotels',
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Home',
          href: this.$router.resolve({ name: 'Home' }).href,
        },
        {
          text: 'Become Hotel Partner',
          active: true,
        },
      ],
      openMenuToggle() {
        const menuToggle = document.querySelector('.menu-toggle')
        const menuRound = document.querySelector('.menu-round')

        menuToggle.classList.toggle('open')
        menuRound.classList.toggle('open')
      },
    }
  },
  computed: {
    ...mapFields("useWWUHotelStore", [
      "WWUHotelData"
    ])
  },
  created() {
    this.getDataContent()
  },
  methods: {
    ...mapActions("useWWUHotelStore", [
      "getDataContent"
    ])
  }
}
</script>

<style scoped>
.wrap_input_uploader {
  margin-top: 1.15em;
}
.wrap_input_uploader i {
  font-size: 20px;
}

.wrap_img {
  max-width: 540px;
  max-height: 360px;
}

.wrap_img img {
  height: auto;
  aspect-ratio: auto;
}
</style>

<template>
  <div class="home">
    <div class="body-inner">
      <div>
        <!-- Header -->
        <Header :isOpenMenuProps="true" />
      </div>
      <div class="bg-grey-blue py-4 d-flex align-items-center" v-if="!isMobile()">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <b-breadcrumb :items="breadCrumbPrivacyPolicy"></b-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!isMobile()">
        <section class="mt-0 pt-5 overflow-visible">
          <div class="container">
            <div class="row align-items-end justify-content-center bb-1px-grey pb-3">
              <div class="col-md-10 d-flex flex-column align-items-center">
                <div class="label-custom green d-inline">
                  <span class="black">Privacy Policy</span>
                </div>
                <h2 class="black mt-2 fs-275em">Our Privacy Policy</h2>
                <p class="medium grey-color">
                  Last Updated: {{ formatDateWithoutWeekday((privacyPolicyData.data || {}).updated_date) }}
                </p>
              </div>
            </div>
            <div class="row justify-content-center pt-5">
              <div class="col-md-10">
                <div class="paragraphs_style text-dark" v-html="(privacyPolicyData.data || {}).data"></div>
              </div>
            </div>
          </div>
        </section>
      </template>

      <template v-else>
        <div class="mobile-version-view py-5">
          <div class="bg-grey-blue py-4 mt-3 d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <b-breadcrumb :items="breadCrumbPrivacyPolicy"></b-breadcrumb>
                </div>
              </div>
            </div>
          </div>
          <section class="mt-0 pt-5 bb-1px-grey pb-4 overflow-visible">
            <div class="container">
              <div class="row align-items-end">
                <div class="col-md-8">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <div class="label-custom green d-inline">
                        <span class="black">Privacy Policy</span>
                      </div>
                      <h2 class="black mt-2 fs-275em">Our Privacy Policy</h2>
                      <span class="medium grey-color"
                        >Last Updated: {{ formatDateWithoutWeekday((privacyPolicyData.data || {}).updated_date) }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-0 pt-0">
            <div class="container">
              <div class="row justify-content-center pt-4">
                <div class="col-md-12">
                  <div class="paragraphs_style text-dark" v-html="(privacyPolicyData.data || {}).data"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </div>
    <div class="menu-toggle" @click="openMenuToggle">
      <span class="fa fa-comments"></span>
    </div>
    <div class="menu-round">
      <div class="btn-app">
        <a :href="`https://api.whatsapp.com/send/?phone=${(channels.contact.mobile_phone || '').replaceAll(/\D/g, '')}&text&type=phone_number`" target="_blank" class="fab fa-whatsapp text-white p-3"> </a>
      </div>
      <div class="btn-app">
        <a :href="$router.resolve({ name: 'ContactUs' }).href" class="text-white">
          <i class="fa fa-envelope-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Global from '../libs/Global.vue'

import Header from '../components/Header.vue'

export default {
  extends: Global,
  name: 'PrivacyPolicy',
  components: {
    Header,
  },
  computed: {
    ...mapFields('useLegalStore', ['privacyPolicyData', 'breadCrumbPrivacyPolicy']),
    ...mapFields(['channels']),
  },
  created() {
    this.getPrivacyPolicyData()
  },
  methods: {
    ...mapActions('useLegalStore', ['getPrivacyPolicyData']),
    ...mapActions(['getChannels']),
    openMenuToggle() {
      const menuToggle = document.querySelector('.menu-toggle')
      const menuRound = document.querySelector('.menu-round')

      menuToggle.classList.toggle('open')
      menuRound.classList.toggle('open')
    },
  },
}
</script>

<style lang="scss" scoped>
.paragraphs_style {
  font-family: 'Metropolis-Regular' !important;

  ul {
    margin-left: 1em;
  }

  strong {
    font-family: 'Metropolis-Bold' !important;
    * {
      font-family: 'Metropolis-Bold' !important;
    }
  }
}
</style>

<template>
  <section class="pb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8" :class="isMobile ? 'text-center d-flex flex-column align-items-center' : ''">
          <span class="sub-title-section" :class="isMobile ? 'justify-content-center' : ''">
            <div class="line-sub-title"></div>
            {{ content.sub_title }}
          </span>
          <h2 class="title-section-left" :class="isMobile ? 'mt-2' : 'mt-3'">{{ content.title }}</h2>
        </div>
        <div class="col-md-4 d-flex justify-content-end align-items-end" v-if="!isMobile">
          <div class="mt-4">
            <a :href="$router.resolve({name: 'Gallery'}).href" class="btn btn-outline black">{{ content.button }}</a>
          </div>
        </div>
        <div class="col-md-12">
          <div v-if="data.length > 0" class="masonry mt-5" :class="isMobile ? 'justify-content-center' : ''">
            <template v-for="(v, k) in data">
              <div :key="'gallery-'+k" class="masonry-brick card-hover overflow-hidden" :class="isMobile ? 'bdrd-12px' : 'bdrd-24px'">
                <template v-if="isMobile">
                  <picture>
                    <source :srcset="uploader(v.gallery_img_small)" type="image/webp" />
                    <img :src="uploader(v.gallery_fallback_img_small)" :alt="v.gallery_alt_img" />
                  </picture>
                </template>
                <template v-else>
                  <picture>
                    <source :srcset="uploader(v.gallery_img)" type="image/webp" />
                    <img :src="uploader(v.gallery_fallback_img)" :alt="v.gallery_alt_img" />
                  </picture>
                </template>
                <div class="detail">
                  <a
                    :href="uploader(isWebpSupport ? v.gallery_img : v.gallery_fallback_img)"
                    data-fancybox="gallery"
                    :data-caption="v.title"
                  >
                    <div class="social-bullet">
                      <i class="icon-eye"></i>
                    </div>
                  </a>
                  <p class="text-white bold mt-2" :class="isMobile ? 'd-none' : ''">
                    {{
                      v.title
                    }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="col-6 text-center">
          <a v-if="isMobile" :href="$router.resolve({name: 'Gallery'}).href" class="btn btn-outline black mt-4">{{ content.button }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GalleryAtom',
  props: {
    isMobile: {
      type: Boolean,
    },
    truncateText: {
      type: Function,
    },
    content: {
      type: Object,
    },
    data: {
      type: Array,
    },
    uploader: {
      type: Function,
    },
    isWebpSupport: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.masonry {
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px; /* Adjustment for the gutter */
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.masonry-brick {
  flex: auto;
  height: 250px !important;
  min-width: 300px !important;
  margin: 0 8px 8px 0; /* Some gutter */
  position: relative;
  overflow: hidden;
  @media only screen and (min-width: 768px) {
    margin: 8px 5px;
  }
  @media only screen and (max-width: 768px) {
    margin: 8px 3px;
    height: 100px !important;
    min-width: 100px !important;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .detail {
      bottom: 50% !important;
      transform: translateY(50%) translateX(-50%);
    }
    img {
      filter: brightness(50%);
    }
  }
}

.masonry-brick:nth-child(4n + 1) {
  width: 250px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
}
.masonry-brick:nth-child(4n + 1):nth-child(4n + 2) {
  width: 325px;
}
.masonry-brick:nth-child(4n + 1):nth-child(4n + 3) {
  width: 180px;
}
.masonry-brick:nth-child(4n + 1):nth-child(4n + 4) {
  width: 380px;
}
</style>
